import {PaymentController} from "../../../controllers";
import { CustomButton, SubmissionAlert, InputTextLabel, DropDownIcon, Header, Option, OrderDetails, LoadingSpinner } from "../../../components";
import { createTheme, ThemeProvider, Box, Tooltip, Typography, Modal, Grid } from '@mui/material';
import { AiOutlineFileExcel } from "react-icons/ai";
import { MaterialReactTable } from 'material-react-table';
import { FiAlertTriangle } from "react-icons/fi";
import Select, { CSSObjectWithLabel } from "react-select";
import Pagination from '@mui/material/Pagination';
import moment from 'moment-jalaali';
import { AiOutlineDesktop, AiFillCreditCard } from "react-icons/ai";
import { connect } from "react-redux";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {TfiFilter} from "react-icons/tfi";
import { numberWithCommas, exportToExcel } from "../../../../core/utils";
import { paymentColumns } from "../../../../core/column";
class Payment extends PaymentController {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getPaymentList();
  }
  render() {
    const { search, toggleConfirmModal, allOption, loading, openBillModal, openDetailModal, page , magnifiedImage,
      toggleRejectModal, showFilters, rowCount,rowCountList, detailData, dreiverId, currentItem, paymentListData } = this.state;
      const { siteList , loadingSiteList} = this.props;
    const theme = createTheme({
      typography: {
        fontFamily:
          'Vazir'
      }
    });
    return (
      <HelmetProvider>
      <ThemeProvider theme={theme}>
        <div className="payment">
          <Helmet>
                <title> لیست پرداخت‌ها</title>
            </Helmet>
          <Header title="لیست پرداخت‌ها"/>
          <Modal
                  open={openBillModal}
                  onClose={this.handleCloseBill}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    overflowY:"scroll",
                    width: {
                      xxs: "90%",
                      xs: "90%",
                      sm: "90%",
                      md: "80%",
                      lg: "60%",
                      xl: "60%"
                    },
                    maxHeight: '90%',
                    height:"fit-content",
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p:2
                  }}>
                    <Typography sx={{margin:"10px"}} id="modal-modal-title" variant="subtitle1">
                      فیش واریزی  
                    </Typography>
                    <Grid item sx={{display:"flex", justifyContent:"center"}}>
                    <img className="magnified-img" src={magnifiedImage} alt="..."/>

                    </Grid>
                    {currentItem?.status?.name === 2 &&
                    <div className="bill-action">
                            <CustomButton
                              myStyle="btn-confirm"
                              loading={false}
                              onClick={() =>
                                this.setState({ toggleConfirmModal: true })
                              }
                              title="تایید"
                            />
                            <CustomButton
                              myStyle="btn-reject"
                              loading={false}
                              onClick={() => this.setState({ toggleRejectModal: true })}
                              title="ردکردن"
                            />
                          </div>
                      }
                  </Box>
          </Modal>
          <Modal
                  open={openDetailModal}
                  onClose={this.handleCloseDetailModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    overflowY:"scroll",
                    width: {
                      xxs: "90%",
                      xs: "90%",
                      sm: "90%",
                      md: "80%",
                      lg: "70%",
                      xl: "70%"
                    },
                    height: '70%',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                  }}>
                    <Typography id="modal-modal-title" variant="subtitle1">
                        جزییات محصولات  
                    </Typography>
                    {detailData === null ? <LoadingSpinner /> : 
                    <>
                    {detailData?.order_products?.length === 0 ?
                           <div className="error-text">داده ای برای نمایش وجود ندارد!</div>
                            :
                    <OrderDetails selectedOrder={detailData} />
                      }
                      </>
                       }                   
                  </Box>
          </Modal>
            <div className="custom-card mb-3">
            {showFilters && 
              <div className="filter-select-container mb-2 pe-3">
                <div className="filter-options-container">
                <div className="column-50">
                <Select
                placeholder="سایت"
                noOptionsMessage={() => 'این مورد وجود ندارد!'}
                isClearable
                loadingMessage={() => 'در حال بارگذاری  ...'}
                isLoading={ loadingSiteList}
                styles={{
                  control: (baseStyles):CSSObjectWithLabel => ({
                    ...baseStyles,
                    boxShadow:"rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",
                    borderWidth: "1px",
                    backgroundColor: "#fff",
                    display: "flex",
                    flexDirection: "row-reverse",
                    textAlign: "right",
                    borderRadius: "4px",
                    outline: "unset",
                  }) as CSSObjectWithLabel,
                  }}
                  components={{ Option }}
                  menuPortalTarget={document.body}
                  closeMenuOnSelect={true}
                  value={dreiverId}
                  isMulti={true}
                  getOptionLabel={(option: any) => option?.value?.persian_name}
                  getOptionValue={(option: any) => option?.value?.name}
                  options={[ allOption, ...siteList]}
                  onChange={this.handleChange}
                />                 
                </div>
                  <div className="column-50">
                    <InputTextLabel
                      type={"text"}
                      value={search}
                      onChange={(event) => this.handleSearch(event)}
                      placeholder="جستجو..."
                    /> 
                  </div>
                </div>
              </div>
            }
              <MaterialReactTable
                muiTablePaperProps={{
                sx: { m: '1.5rem', border:"1px solid #e5e5e5" },
                }}
                displayColumnDefOptions={{
                  'mrt-row-expand': {
                    muiTableHeadCellProps: {
                      align: 'center',
                    },
                  },
                }}
                muiTableHeadCellProps={{
                  sx: {
                    border: "1px solid #e5e5e5",
                    height: "fit-content",
                    textAlign:"center",
                    justifyContent:"center",
                    
                  },
                }}
                enableRowNumbers={true}
                rowNumberMode="static"
                columns={paymentColumns}
                data={paymentListData && paymentListData?.data?.length > 0 &&
                  paymentListData?.data?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null).map((item: any, index: number) => {
                    return ({
                      item: item,
                      row:index + 1,
                      driver:<img className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo} alt=""/> ,
                      code: item.id,
                      name: item.profile?.full_name,
                      price: numberWithCommas(item.price),
                      orderNumber:<span onClick={()=> this.handleOpenDetailModal(item)}>{item.order_id}</span> ,
                      date:item.date && item.date ,
                      paymentStatus: item?.status.name === 1 || item?.status.name === 2 ? (item?.type.name === 1 ? <Tooltip title={`${item?.type?.value}`}><span><AiOutlineDesktop className="icon-blue" /></span></Tooltip>
                      :item?.type.name === 2 ? <Tooltip title={`${item?.type?.value}`}><span><AiFillCreditCard className="icon-orange" /></span></Tooltip>
                      : <Tooltip title={`${item?.type.value}`}><span>{`${item?.type.value}`}</span></Tooltip>)
                      : <span>نامعلوم</span>,
                      status: item.status?.value
                    }
                    );
                  }
                  )}
                  localization={{ noRecordsToDisplay:"داده‌ای برای نمایش وجود ندارد!" }}
                  state={{ isLoading: loading, showProgressBars: loading }}
                  enablePagination={false}
                  renderBottomToolbarCustomActions={({table})=>{
                    return (
                      <div className="custom-pagination w-100">
                       <Pagination  page={page} count={paymentListData?.total ? Math.ceil(paymentListData?.total / rowCount) : 1} onChange={this.handleChangePage} />
                      <DropDownIcon
                      value={rowCount}
                      optionList={rowCountList}
                      title={""}
                      onChange={(event) => { this.handlePaginationSelect(event)}
                    }
                    />
                      </div>
                    );
                  }}
                  muiTableBodyRowProps={( {row, staticRowIndex} ) => ({
                    sx: {
                      height:"fit-content",
                      backgroundColor: row?.original?.item?.status?.name === 1 ? "#e0ffde" : row?.original?.item?.status?.name === 2 ? "#fff9b2" : (staticRowIndex % 2 === 0) ? '#f3f3f3'  :  "",
                    }
                  })}
                muiTableProps={{
                  sx: {
                    border: "1px solid #e5e5e5",
                  },
                }}
                muiTableBodyCellProps={{
                  sx: {
                    border: "1px solid #e5e5e5",
                    textAlign:"center"

                  },
                }}
                positionExpandColumn={'first'}
                enableGlobalFilter={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableHiding={false}
                renderTopToolbarCustomActions={() => (
                  <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap', width:"100%", justifyContent:"space-between" }} >
                    <CustomButton
                      loading={false}
                      onClick={() => exportToExcel(paymentListData?.data, "Excel Export")}
                      title="اکسل"
                      myStyle="excel-btn"
                      icon={<AiOutlineFileExcel />}
                    />
                    <Tooltip title="نمایش فیلترها"><span> <TfiFilter className='filter-icon' onClick={() => this.setState(prevState => ({
                      showFilters: !prevState.showFilters}))}/></span></Tooltip>
                    <div className='custom-pagination'>
                    <Pagination  page={page} count={paymentListData?.total ? Math.ceil(paymentListData?.total / rowCount) : 1} onChange={this.handleChangePage} />
                        <DropDownIcon
                        value={rowCount}
                        optionList={rowCountList}
                        title={""}
                        onChange={(event) => { this.handlePaginationSelect(event) }
                      }
                      />
                    </div>
                  </Box>
                )}
                enableColumnActions={false}
                renderDetailPanel={({ row }) => (
                  <div className="detail-container">
                        { row.original.item?.file &&

                    <div className="detail-col" >
                      <p className="detail-row">
                        <img className="bill-img" src={row?.original?.item?.file} alt="..." onClick={()=> this.magnifyImage(row.original.item)}/>
                      </p>
                    </div>
                    }
                    <div className="detail-col">
                      <div className="detail-row">
                        {row.original.item?.status?.name === 1 &&
                          <>
                            تایید شده در تاریخ {moment(row.original.item?.verify_date).format("HH:mm:ss jYYYY/jM/jD")|| ""} توسط  {row.original.item.updated_by?.acceptor_name || "-"}                      </>
                        }
                        {row.original.item?.status?.name === 2 &&
                          <>
                            در انتظار تایید پرداخت
                          </>
                        }
                        {row.original.item?.status?.name === 3 &&
                          <>
                            برکشت زده شده
                          </>
                        }
                      </div>
                    </div>


                    {toggleConfirmModal &&
                      <SubmissionAlert isOpen={toggleConfirmModal} title="اخطار!"
                        setClose={(open: boolean) => this.setState({ toggleConfirmModal: open })}
                        onClick={() => this.handleConfirm(row.original?.item)} icon={<FiAlertTriangle color="orange" fontSize="50" />}
                        details="آیا از تایید پرداخت با این شماره پیگیری مطمئن هستید؟" />
                    }
                    {toggleRejectModal &&
                      <SubmissionAlert isOpen={toggleRejectModal} title="اخطار!" icon={<FiAlertTriangle color="orange" fontSize="50" />}
                        details="آیا از رد کردن این شماره پیگیری مطمئن هستید؟"
                        setClose={(open: boolean) => this.setState({ toggleRejectModal: open })}
                        onClick={() => this.handleReject(row.original?.item)} />

                    }
                  </div>
                )}
                sortDescFirst={true}
                enableSorting={true}
                initialState={{
                  sorting: [
                      {
                        id: 'date',
                        desc: true,

                      },
                  ],
                }}
              /> 
            </div>
        </div>
      </ThemeProvider>
      </HelmetProvider>
    )
  }
}
const mapStateToProps = (dispatch:any) => {
  return {
    dispatch,
    siteList:dispatch.general.siteList,
    loadingSiteList:dispatch.general.loadingSiteList,
    userData:dispatch.general.userData,
  }
};

export default connect(mapStateToProps)(Payment);
