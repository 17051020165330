import {
  InputHTMLAttributes,
  ReactElement,
  useCallback,
} from "react";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  value?: any;
  title: string;
  placeholder?: string;
  inBox?: ReactElement | string;
  errortext?: string;
  optionList:any;
  disabled?:boolean;
  myStyle?: string;
  inputStyle?:string;
}

const DropdownIcon = ({
  inBox,
  value,
  onChange,
  placeholder,
  errortext,
  optionList,
  disabled,
  myStyle,
  inputStyle,
}: Props): ReactElement => {
  const handleOnChange = useCallback(
    (event: any) => {
      onChange?.(event);
    },
    [onChange]
  );
  return (
    <div className={`dropdown-icon ${inputStyle}`}>
      {placeholder && 
      <label className="dropdown-icon-label">{placeholder}</label>
      }
      <div className="dropdown-icon-container">
        {inBox &&
          <div className="in-box">{inBox}</div>
        }
        <div className="form-group select-container ">
          <select disabled={disabled ? true : false } value={value} onChange={handleOnChange} className={`form-control ${myStyle}`} id="exampleFormControlSelect1">
              {optionList && optionList.map((item : any , index : number) => {
                    return (
                      <option aria-label={item.name} key={index} value={item.value && item.value}>{item.name || item}</option>
                      )
                    })}
          </select>
        </div>
      </div>
      {errortext &&
        <span className="dropdown-icon-error">
          {errortext}
        </span>
      }
    </div>
  );
}
export default DropdownIcon;
