import { CustomButton, LableIcon, VitrinCard, LoadingSpinner, Header, Option } from "../../../components";
import { BsStars } from "react-icons/bs";
import Select, { CSSObjectWithLabel, components } from "react-select";
import { connect } from "react-redux";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { VitrinController } from "../../../controllers";


class Vitrin extends VitrinController {
    componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const DropdownIndicator = (props: any) => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator
            style={{ color: "red" }}
            {...props}
          ></components.DropdownIndicator>
        )
      );
    };
    const {specialSaleList,
      specialSaleLoading,
      specialSaleProductList,
      specialSaleId,
      bestList,
      bestId,
      bestProductList,
      productLoading,
      db,
      bestLoading,
      offerList,
      offerId,
      offerProductList,
      offerLoading,
      offerProductLoading,
      bestProductLoading,
      selectionList,
      selectionId,
      selectionProductList, 
      selectionProductLoading,
      selectionLoading,
      toolsList,
      toolsId,
      toolsProductList,
      toolsLoading,
      toolsProductLoading,
      emptyDataSpecialText,
      emptyDataBestSellText,
      emptyDataOfferText,
      emptyDataSelectedText,
      emptyDataToolsText,
    } = this.state;
    const { siteList , loadingSiteList} = this.props;

    return (
      <HelmetProvider>
      <div className="vitrin">
        <Helmet>
                <title>ویترین</title>
            </Helmet>
            <Header title="ویترین"/>
        <div className="custom-table">
        <Select
                className="col-select w-50 mb-2"
                styles={{
                  control: (baseStyles: any, state: any):CSSObjectWithLabel => ({
                    ...baseStyles,
                    boxShadow:"rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",
                    borderWidth: "1px",
                    backgroundColor: "#fff",
                    display: "flex",
                    flexDirection: "row-reverse",
                    textAlign: "right",
                    borderRadius: "4px",
                    height:"35px",
                    minHeight:"20px",
                    outline: "unset",
                    width: "98%"
                  }) as CSSObjectWithLabel,
                }}
                onChange={(choice) => this.driverSelected(choice)}
                components={{ Option }}
                value={db}
                isMulti={false}
                closeMenuOnSelect={true}
                noOptionsMessage={() => 'این مورد وجود ندارد!'}
                menuPortalTarget={document.body}
                options={siteList}
                getOptionLabel={(option: any) => option?.value?.persian_name}
                getOptionValue={(option: any) => option?.value?.name}
                placeholder="سایت"
                loadingMessage={() => 'در حال بارگذاری  ...'}
                isLoading={loadingSiteList}
              />
          <LableIcon title="فروش ویژه" icon={<BsStars />} />
         
            <div className="d-flex w-100">
              <Select
                className="col-select w-50"
                styles={{
                  control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                    ...baseStyles,
                    boxShadow: state.isFocused
                      ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                      : "",
                    borderWidth: "1px",
                    backgroundColor: `${db && Object.keys(db).length > 0  ? "#fff" : "#f7f8fa"}`,
                    display: "flex",
                    flexDirection: "row-reverse",
                    textAlign: "right",
                    borderRadius: "4px",
                    height:"35px",
                    minHeight:"20px",
                    outline: "unset",
                    width: "98%"
                  }) as CSSObjectWithLabel,
                }}
                onInputChange={(choice: any) => this.handleSearch(choice,"special")}
                onChange={(choice: any) => this.handleSelectSpecialSale(choice)}
                components={{ DropdownIndicator }}
                value={specialSaleId}
                isMulti={false}
                closeMenuOnSelect={false}
                isSearchable={true}
                menuPortalTarget={document.body}
                options={specialSaleProductList}
                noOptionsMessage={() => 'این مورد وجود ندارد!'}
                getOptionLabel={(option: any) => option.product_name}
                getOptionValue={(option: any) => option.id}
                placeholder="نام محصول را وارد کنید."
                loadingMessage={() => 'در حال بارگذاری  ...'}
                isLoading={productLoading}
                isDisabled={db && Object.keys(db).length > 0  ? false : true}

              />
              
            </div>
            {specialSaleLoading ? (
              <LoadingSpinner />
            ) : (
              <>
            <div className="special-sell">
              {specialSaleList && specialSaleList?.length > 0 && specialSaleList?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null).map((item: any, index: number) => {
                return (
                  <VitrinCard details={item?.product_name} code={item?.id}

                    setClose={(open: boolean) => this.setState({ toggleModal: open })}
                    onClick={() => this.handleDeleteSpecialSaleProduct(item, index)}
                    img={item?.thumbnail && item?.thumbnail}
                  />
                );
              }
              )}
              {emptyDataSpecialText && <span className="error-text">داده‌ای برای نمایش وجود ندارد!</span>}
            </div>
              </>
              )}
          <LableIcon title="پرفروش‌ترین" icon={<BsStars />} />
            <div className="d-flex w-100 p-2">
              <Select
                className="col-select w-50"
                styles={{
                  control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                    ...baseStyles,
                    boxShadow: state.isFocused
                      ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                      : "",
                    borderWidth: "1px",
                    backgroundColor: `${db && Object.keys(db).length > 0  ? "#fff" : "#f7f8fa"}`,
                    display: "flex",
                    flexDirection: "row-reverse",
                    textAlign: "right",
                    borderRadius: "4px",
                    height:"35px",
                    minHeight:"20px",
                    outline: "unset",
                    width: "98%"
                  }) as CSSObjectWithLabel,
                }}
                onInputChange={(choice: any) => this.handleSearch(choice,"best")}
                onChange={(choice: any) => this.handleSelectbest(choice)}
                components={{ DropdownIndicator }}
                value={bestId}
                isMulti={false}
                closeMenuOnSelect={false}
                isSearchable={true}
                menuPortalTarget={document.body}
                options={bestProductList}
                noOptionsMessage={() => 'این مورد وجود ندارد!'}
                getOptionLabel={(option: any) => option.product_name}
                getOptionValue={(option: any) => option.id}
                placeholder="نام محصول را وارد کنید."
                loadingMessage={() => 'در حال بارگذاری  ...'}
                isLoading={bestProductLoading}
                isDisabled={db && Object.keys(db).length > 0  ? false : true}

              />
              
            </div>
            {bestLoading ? (
              <LoadingSpinner />
            ) : (
              <>
            <div className="special-sell">
              {bestList && bestList?.length > 0 && bestList?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null).map((item: any, index: number) => {
                return (
                  <VitrinCard details={item?.product_name} code={item?.id}

                    setClose={(open: boolean) => this.setState({ toggleModal: open })}
                    onClick={() => this.handleDeleteBestProduct(item, index)}
                    img={item.thumbnail && item.thumbnail}
                  />
                );
              }
              )}
              {emptyDataBestSellText && <span className="error-text">داده‌ای برای نمایش وجود ندارد!</span>}
               </div>
              </>
              )}            
           
          <LableIcon title="پیشنهادهای لحظه‌ای" icon={<BsStars />} />
            <div className="d-flex w-100 p-2">
              <Select
                className="col-select w-50"
                styles={{
                  control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                    ...baseStyles,
                    boxShadow: state.isFocused
                      ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                      : "",
                    borderWidth: "1px",
                    backgroundColor: `${db && Object.keys(db).length > 0  ? "#fff" : "#f7f8fa"}`,
                    display: "flex",
                    flexDirection: "row-reverse",
                    textAlign: "right",
                    borderRadius: "4px",
                    height:"35px",
                    minHeight:"20px",
                    outline: "unset",
                    width: "98%"
                  }) as CSSObjectWithLabel,
                }}
                onInputChange={(choice) => this.handleSearch(choice,"offer")}
                onChange={(choice) => this.handleSelectOffer(choice)}
                components={{ DropdownIndicator }}
                value={offerId}
                isMulti={false}
                closeMenuOnSelect={false}
                isSearchable={true}
                options={offerProductList}
                menuPortalTarget={document.body}
                noOptionsMessage={() => 'این مورد وجود ندارد!'}
                getOptionLabel={(option: any) => option.product_name}
                getOptionValue={(option: any) => option.id}
                placeholder="نام محصول را وارد کنید."
                loadingMessage={() => 'در حال بارگذاری  ...'}
                isLoading={offerProductLoading}
                isDisabled={db && Object.keys(db).length > 0  ? false : true}

              />
              
            </div>
            {offerLoading ? (
              <LoadingSpinner />
            ) : (
              <>
            <div className="special-sell">
              {offerList && offerList?.length > 0 && offerList?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null).map((item: any, index: number) => {
                return (
                  <VitrinCard details={item?.product_name} code={item?.id}

                    setClose={(open: boolean) => this.setState({ toggleModal: open })}
                    onClick={() => this.handleDeleteOfferProduct(item, index)}
                    img={item?.thumbnail && item?.thumbnail}
                  />
                );
              }
              )}
              {emptyDataOfferText && <span className="error-text">داده‌ای برای نمایش وجود ندارد!</span>}
               </div>
               
              </>
              )}
           
          
          <LableIcon title="محصولات منتخب" icon={<BsStars />} />
         
            <div className="d-flex w-100 p-2">
              <Select
                className="col-select w-50"
                styles={{
                  control: (baseStyles, state):CSSObjectWithLabel => ({
                    ...baseStyles,
                    boxShadow: state.isFocused
                      ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                      : "",
                    borderWidth: "1px",
                    backgroundColor: `${db && Object.keys(db).length > 0  ? "#fff" : "#f7f8fa"}`,
                    display: "flex",
                    flexDirection: "row-reverse",
                    textAlign: "right",
                    borderRadius: "4px",
                    height:"35px",
                    minHeight:"20px", 
                    outline: "unset",
                    width: "98%"
                  }) as CSSObjectWithLabel,
                }}
                onInputChange={(choice) => this.handleSearch(choice,"selection")}
                onChange={(choice) => this.handleSelectSelection(choice)}
                components={{ DropdownIndicator }}
                value={selectionId}
                isMulti={false}
                closeMenuOnSelect={false}
                isSearchable={true}
                options={selectionProductList}
                menuPortalTarget={document.body}
                noOptionsMessage={() => 'این مورد وجود ندارد!'}
                getOptionLabel={(option: any) => option.product_name}
                getOptionValue={(option: any) => option.id}
                placeholder="نام محصول را وارد کنید."
                loadingMessage={() => 'در حال بارگذاری  ...'}
                isLoading={selectionProductLoading}
                isDisabled={db && Object.keys(db).length > 0  ? false : true}

              />
              
            </div>
            {selectionLoading ? (
              <LoadingSpinner />
            ) : (
              <>
            <div className="special-sell">
              {selectionList && selectionList?.length > 0 && selectionList?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null).map((item: any, index: number) => {
                return (
                  <VitrinCard details={item?.product_name} code={item?.id}

                    setClose={(open: boolean) => this.setState({ toggleModal: open })}
                    onClick={() => this.handleDeleteSelectionProduct(item, index)}
                    img={item.thumbnail && item?.thumbnail}
                  />
                );
              }
              )}
              {emptyDataSelectedText && <span className="error-text">داده‌ای برای نمایش وجود ندارد!</span>}
             </div>
              </>
              )}
            
          
          <LableIcon title=" ابزارآلات و تجهیزات جانبی شبکه" icon={<BsStars />} />
         
            <div className="d-flex w-100 p-2">
              <Select
                className="col-select w-50"
                styles={{
                  control: (baseStyles, state):CSSObjectWithLabel => ({
                    ...baseStyles,
                    boxShadow: state.isFocused
                      ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                      : "",
                    borderWidth: "1px",
                    backgroundColor: `${db && Object.keys(db).length > 0  ? "#fff" : "#f7f8fa"}`,
                    display: "flex",
                    flexDirection: "row-reverse",
                    textAlign: "right",
                    borderRadius: "4px",
                    height:"35px",
                    minHeight:"20px",
                    outline: "unset",
                    width: "98%"
                  }) as CSSObjectWithLabel,
                }}
                onInputChange={(choice) => this.handleSearch(choice,"tools")}
                onChange={(choice) => this.handleSelectTools(choice)}
                components={{ DropdownIndicator }}
                value={toolsId}
                isMulti={false}
                closeMenuOnSelect={false}
                isSearchable={true}
                options={toolsProductList}
                menuPortalTarget={document.body}
                noOptionsMessage={() => 'این مورد وجود ندارد!'}
                getOptionLabel={(option: any) => option.product_name}
                getOptionValue={(option: any) => option.id}
                placeholder="نام محصول را وارد کنید."
                loadingMessage={() => 'در حال بارگذاری  ...'}
                isLoading={toolsProductLoading}
                isDisabled={db && Object.keys(db).length > 0  ? false : true}

              />
              
            </div>
            {toolsLoading ? (
              <LoadingSpinner />
            ) : (
              <>
            <div className="special-sell">
              {toolsList && toolsList?.length > 0 && toolsList?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null).map((item: any, index: number) => {
                return (
                  <VitrinCard details={item?.product_name} code={item?.id}

                    setClose={(open: boolean) => this.setState({ toggleModal: open })}
                    onClick={() => this.handleDeleteToolsProduct(item, index)}
                    img={item.thumbnail && item?.thumbnail}
                  />
                );
              }
              )}
              {emptyDataToolsText && <span className="error-text">داده‌ای برای نمایش وجود ندارد!</span>}
               </div>
              </>
              )}
             <div className="btn-container">
              <CustomButton
                myStyle="submit-btn"
                loading={specialSaleLoading}
                onClick={() => this.handleSubmit()}
                title="ثبت نهایی"
              />
            </div>
        </div>
      </div>
      </HelmetProvider>
    )
  }
}
const mapStateToProps = (dispatch:any) => {
  return {
    dispatch,
    siteList:dispatch.general.siteList,
    loadingSiteList:dispatch.general.loadingSiteList,
  }
};
export default connect(mapStateToProps)(Vitrin);