import Select, { CSSObjectWithLabel } from "react-select";
import { DragDrop, Header, SeoCard , SubmissionAlert, EditableCard, CustomButton, BadgeRow, InputTextLabel } from "../../../components";
import { FiAlertTriangle } from "react-icons/fi";
import { TextAreaLabel, InputIcon, NoteCard} from "../../../components";
import { Box, createTheme, Modal, Rating, ThemeProvider, Tooltip, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import {ProductUpdateController} from "../../../controllers";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { Editor } from '@tinymce/tinymce-react';
import { connect } from "react-redux";
import { BsPlusCircle } from "react-icons/bs";
import {Logo} from "../../../../assets";
import { AiFillLock } from "react-icons/ai";
import CreatableSelect from 'react-select/creatable';
import moment from "moment-jalaali";
import { Link } from "react-router-dom";
import { HiPlus } from "react-icons/hi2";

class ProductUpdate extends ProductUpdateController {

  componentDidMount() {
   window.scrollTo(0, 0);
   this.getProductDetails();
   this.getBrandSearch();
   this.getCategorySearch();
   this.getTagSearch();
   this.getNotesSearch();
   }
  render() {    
    

    const { userData, loadingSiteList } = this.props;
    const {
      toggleTab,
      toggleMediaTab, 
      confirmEditName,   
    toggleAlert,
    atoumaticSave,
    persianName,
    englishName,
    productName,
    seoTitle,
    seoDescription,
    canonicalUrl,
    routeName,
    weight,
    category,
    productURL,
    categoryList,
    brandId,
    brandList,
    tag,
    tagList,
    tagLoading,
    linkedProductId,
    linkedProductList,
    editorShortInfoText,
    editorGeneralInfoText,
    pros,
    prosList,
    cons,
    consList,
    seoCardList,
  noteCardsList,
  notCardSelectList,
  noteCardSelectId,
  errorsList,
  togglePasswordModal,
  password,
  variableProductId,
  logsList,
  toggleLogListModal,
     
    } = this.state;
    const theme = createTheme({
      typography: {
        fontFamily: "Vazir",
      },
    });
    const formatOptionLabel = (option: { __isNew__: any; message: any; }, { context }: any) => {
      return context === "menu" && option.__isNew__ ? (
        <>
          {option.message}
          <br />
          <small>اضافه‌نمودن</small>
        </>
      ) : (
        option.message
      );
    };

    return (
      <ThemeProvider theme={theme}>
        <div className="edit-product">
          <Helmet>
            <title>ویرایش محصول</title>
          </Helmet>
          <Header title="ویرایش محصول"/>
          <div className="custom-card">
            <div className="sites-tab-conteainer">
            <Modal
                      open={togglePasswordModal}
                      onClose={() => this.setState({togglePasswordModal: false})}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                     <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        width: {
                          xxs: "90%",
                          xs: "90%",
                          sm: "90%",
                          md: "80%",
                          lg: "70%",
                          xl: "70%"
                        },
                        height: 'max-content',
                        display: 'block'
                      }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                             واردکردن رمز محلی                           
                        </Typography>
                        <div className="">
                  <InputTextLabel
                    type={"text"}
                    value={password}
                    onChange={(event) => this.setState({password :event.currentTarget.value})}
                    placeholder="رمز محلی"
                  />
                </div>
                <CustomButton
                          myStyle="btn bg-light-green "
                          onClick={()=>this.confirmPassword()}
                          title="تایید"
                    />

               

                      </Box>
                  </Modal>
                  <Modal
                      open={toggleLogListModal}
                      onClose={() => this.setState({toggleLogListModal: false})}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                     <Box     sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    overflow: "scroll",
                    width: {
                      xxs: "50%",
                      xs: "50%",
                      sm: "50%",
                      md: "60%",
                      lg: "50%",
                      xl: "50%",
                    },
                    height: "60%",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                  }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                             مشخصات ویرایش‌دهندگان                         
                        </Typography>
                        <div className="table-responsive">
                <table  className="table">
                  <thead>
                    <tr className="log-list-column">
                      <th>نام و نام خانوادگی</th>
                      <th>تاریخ</th>
                    </tr>
                  </thead>
                  <tbody >
                    {logsList.length > 0 && logsList.map((rowsData: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td>
                            {rowsData.profile}
                          </td>
                          <td>
                           {moment(rowsData.created_at).format("HH:mm:ss jYYYY/jM/jD ")}
                          </td>
                        </tr>
                      );
                    })
                    }
                  </tbody>
                </table>
                </div>
                      </Box>
                  </Modal>
          </div>
          <div className="static-content">
          <div className="side-btn-container">
          <Link to={productURL} target="_blank">
          <span>نمایش محصول</span>
                        </Link>
          
          <CustomButton
                          myStyle="btn bg-purple "
                          onClick={()=>this.handleupdateValidations()}
                          title="به‌روزرسانی محصول"
                    />
             <CustomButton
                          myStyle="btn bg-light-green "
                          onClick={()=>this.handleCopyProduct()}
                          title="کپی محصول"
                    />
            </div>
            <label>ذخیره اتوماتیک</label>
            <div className="scrollable-container">
              { atoumaticSave && atoumaticSave.length > 0 && atoumaticSave.map((item:any, index:number) =>{
              return(

                <BadgeRow content={item?.date} badgeTtile={index + 1}/>
              )

              })}

            </div>
            
            <label>یادداشت‌های محصول</label>
            <CreatableSelect
              isClearable
              onChange={(choice, action) => this.handleChangeCreatableSelect(choice, action)}
              onInputChange={(input, action) => this.handleInputChangeCreatableSelect(input, action)}
              options={notCardSelectList}
              onKeyDown={this.handleKeyPressNoteCardSelect}
              value={noteCardSelectId}
              getOptionValue={(option: any) => option.id}
              getOptionLabel={(option: any) =>
                option.message
              }
              loadingMessage={() => 'در حال بارگذاری  ...'}
              formatOptionLabel={formatOptionLabel}
              onCreateOption={(input) => this.handleCreateNote(input)}
              placeholder={"یادداشت را وارد کنید."}
              // formatCreateLabel={(value) => `اضافه‌نمودن: ${value}`}


        />
            <div className="scrollable-container">
                { noteCardsList?.length > 0 && noteCardsList?.map((item:any, index:number) =>{
                  return(
                    <NoteCard date={item?.updated_at ? moment(item?.updated_at).format("HH:mm:ss jYYYY/jM/jD") : moment(item?.created_at).format("HH:mm:ss jYYYY/jM/jD")} user={item?.profile ? item?.profile : userData?.profile?.full_name} index={index}
                     value={item?.message ? item?.message : item} handleChange={(event: any)=>this.handleChangeNoteCard(item?.id, event, index)}
                     handleDelete={()=>this.handleDeleteNoteCard(index, item?.id)} />
                  )
                })}
            </div>
            <label>آخرین تغییر</label>
              {logsList?.length > 0 && (
                <div className="log">
                <span>{logsList[logsList?.length-1].profile}</span>
                <span>{moment(logsList[logsList?.length-1].created_at).format("HH:mm:ss jYYYY/jM/jD")}</span>
                <Tooltip title="مشاهده بیشتر"><span>
                  <HiPlus className="icon-gray" onClick={()=>this.setState({toggleLogListModal: true})}/></span>
                </Tooltip>
                </div>

              )}
          </div>
          
          <div className="dynamic-content">
          <div className="column-30 mb-1 d-flex align-items-center">
                      <ToggleButtonGroup
                        value={toggleTab}
                        exclusive
                        onChange={this.handleToggleTabs}
                        aria-label="text alignment"
                        sx={{marginRight:"5px", borderLeft: "1px solid #ebebeb"}}
                        >
                        <ToggleButton sx={{border:"0px"}} value={0} aria-label="left aligned">
                        توضیحات
                        </ToggleButton>
                        <ToggleButton value={1} aria-label="centered">
                            رسانه
                        </ToggleButton>
                        <ToggleButton value={2} aria-label="right aligned">
                        ویژگی
                        </ToggleButton>
                        <ToggleButton value={3} aria-label="right aligned">
                        سئو
                        </ToggleButton>
                        <ToggleButton value={4} aria-label="right aligned">
                        توضیحات کوتاه
                        </ToggleButton>
                        <ToggleButton value={5} aria-label="right aligned">
                        توضیحات کلی
                        </ToggleButton>
      
                      </ToggleButtonGroup>
          </div>
          <div >
                    {errorsList && errorsList?.map((obj: any, index:number) =>
                    <span className="input-icon-error" key={index}> {obj.message} </span>)}
                  </div>
          { toggleTab === 0 ?
                <div className="filter-select-container">
                  <div className="filter-options-container">
                <div className="column-50">
                <TextAreaLabel
                    value={persianName}
                    onChange={(event:any) =>
                      this.setState({
                        persianName: event.currentTarget.value
                      })
                  }                    
                  placeholder="نام فارسی "
                  />
                </div>
                <div className="column-50">
                         <TextAreaLabel
                          value={englishName}
                          onChange={(event:any) =>
                            this.setState({
                              englishName: event.currentTarget.value
                            })
                          }                    
                          placeholder="نام انگلیسی"
                  />
                </div>
                 <div className="column-70">      
                {toggleAlert &&
                    <SubmissionAlert
                        isOpen={toggleAlert}
                        title="اخطار!"
                        setClose={(open: boolean) => this.setState({
                          toggleAlert: open
                        })}
                        onClick={() => this.setState({ confirmEditName: true, toggleAlert: false })} 
          

                                icon={<FiAlertTriangle color="orange" fontSize="50" />}
                                details=" آیا از تغییر نامک مطمئن هستید؟"
                              />
            }
                <InputIcon
                          type={"text"}
                          value={ productName}
                          title={"نامک"}
                          inBox={"نامک"}
                          onChange={(event) =>
                            this.setState({
                              productName: event.currentTarget.value
                               
                            })
                          }
                          disabled={!confirmEditName}
                          onClick={(e:any)=>this.handleAlert(e)}
                          icon={<AiFillLock className="icon-green"/>}
                        />
                </div>
                
                <div className="column-30">
                <InputIcon
                          type={"text"}
                          value={ weight ? weight : "ثبت نشده"}
                          title={"وزن به گرم"}
                          inBox={"وزن به گرم"}
                          onChange={(event) =>
                            this.setState({
                              weight: event.currentTarget.value
                            })
                          }
                        />
                </div>
                
                <div className="w-100">
                  <label>دسته‌بندی‌ها</label>
                <Select
                      styles={{
                        control: (baseStyles, state):CSSObjectWithLabel => ({
                          ...baseStyles,
                          boxShadow:"rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",
                          borderWidth: "1px",
                          backgroundColor: "#fff",
                          display: "flex",
                          flexDirection: "row-reverse",
                          textAlign: "right",
                          borderRadius: "4px",
                          height:"42px",
                         
                          outline: "unset",
                          marginBottom:"20px",
                        }) as CSSObjectWithLabel,
                      }}
                      onChange={(choice) => this.categorySelected(choice)}
                      value={category}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      noOptionsMessage={() => 'این مورد وجود ندارد!'}
                      menuPortalTarget={document.body}
                      options={categoryList }
                      getOptionLabel={(option: any) =>
                        option.persian_name
                      }
                      onInputChange={(choice: any) => this.handleCategorySearch(choice)}
                      getOptionValue={(option: any) => option.id}
                      placeholder="دسته‌بندی"
                      isClearable={false}
                      loadingMessage={() => 'در حال بارگذاری  ...'}
                      isLoading={loadingSiteList}
                    />
                </div>
                
                <div className="column-50">
                  <label>برندها</label>
                <Select
                                styles={{
                                  control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                                    ...baseStyles,
                                    boxShadow: state.isFocused
                                      ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                      : "",
                                    borderWidth: "1px",
                                    backgroundColor: "#fff",
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    textAlign: "right",
                                    borderRadius: "4px",
                                    minHeight: "20px",
                                   
                                    outline: "unset",
                                    marginBottom: "10px",
                                  }) as CSSObjectWithLabel,
                                }}
                                onChange={(choice: any) =>
                                  this.brandSelected(choice)
                                }
                                onInputChange={(choice: any) => this.handleBrandSearch(choice)}
                                value={brandId}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                options={brandList}
                                noOptionsMessage={() => "این مورد وجود ندارد!"}
                                getOptionLabel={(option: any) =>
                                  option?.persian_name
                                }
                                getOptionValue={(option: any) => option?.id}
                                placeholder="همه برندها "
                                isClearable
                                loadingMessage={() => 'در حال بارگذاری  ...'}
                                />
                </div>
                
                <div className="column-50">
                  <label>برچسب‌ها</label>
                <Select
                            styles={{
                              control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                                ...baseStyles,
                                boxShadow: state.isFocused
                                  ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                  : "",
                                borderWidth: "1px",
                                backgroundColor:"#fff",
                                display: "flex",
                                flexDirection: "row-reverse",
                                textAlign: "right",
                                borderRadius: "4px",
                                minHeight: "20px",
                               
                                outline: "unset",
                                marginBottom: "10px",
                              }) as CSSObjectWithLabel,
                            }}
                            onChange={(choice: any) =>
                              this.tagSelected(choice)
                            }
                            onInputChange={(choice: any) => this.handleTagSearch(choice)}
                            value={tag}
                            isMulti={true}
                            noOptionsMessage={() => "این مورد وجود ندارد!"}
                            closeMenuOnSelect={true}
                            options={tagList}
                            getOptionLabel={(option: any) =>
                              option.persian_name
                            }
                            isLoading={tagLoading}
                            getOptionValue={(option: any) => option.id}
                            placeholder="برچسب"
                            isClearable
                            loadingMessage={() => 'در حال بارگذاری  ...'}

                            />
                </div>
               
                <div className="column-50">
                <Select
                            styles={{
                              control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                                ...baseStyles,
                                boxShadow: state.isFocused
                                  ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                  : "",
                                borderWidth: "1px",
                                backgroundColor:"#fff",
                                display: "flex",
                                flexDirection: "row-reverse",
                                textAlign: "right",
                                borderRadius: "4px",
                                minHeight: "20px",
                               
                                outline: "unset",
                                marginBottom: "10px",
                              }) as CSSObjectWithLabel,
                            }}
                            onChange={(choice: any) =>
                              this.linkedProductSelected(choice)
                            }
                            onInputChange={(choice: any) => this.handleLinkedProductsSearch(choice)}
                            value={linkedProductId}
                            isMulti={true}
                            noOptionsMessage={() => "این مورد وجود ندارد!"}
                            closeMenuOnSelect={true}
                            options={linkedProductList}
                            getOptionLabel={(option: any) =>
                              option?.product_name
                            }
                            loadingMessage={() => 'در حال بارگذاری  ...'}

                            getOptionValue={(option: any) => option?.id}
                            placeholder="محصول مرتبط را نتخاب کنید"
                            isClearable
                            />
                </div>
                
                <div className="column-50">
                          <Select
                            styles={{
                              control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                                ...baseStyles,
                                boxShadow: state.isFocused
                                  ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                  : "",
                                borderWidth: "1px",
                                backgroundColor:"#fff",
                                display: "flex",
                                flexDirection: "row-reverse",
                                textAlign: "right",
                                borderRadius: "4px",
                                minHeight: "20px",
                               
                                outline: "unset",
                                marginBottom: "10px",
                              }) as CSSObjectWithLabel,
                            }}
                            onChange={(choice: any) =>
                              this.variableProductsSelect(choice)
                            }
                            onInputChange={(choice: any) => this.handleVariableProductsSearch(choice)}
                            loadingMessage={() => 'در حال بارگذاری  ...'}

                            value={variableProductId}
                            isMulti={true}
                            noOptionsMessage={() => "این مورد وجود ندارد!"}
                            closeMenuOnSelect={true}
                            options={linkedProductList}
                            getOptionLabel={(option: any) =>
                              option?.product_name
                            }
                            getOptionValue={(option: any) => option?.id}
                            placeholder="محصول متغیر را نتخاب کنید"
                            isClearable
                            />
                </div>
                
                <div className="column-50">
                <TextAreaLabel
                          onKeyDown={this.handleKeyPress}
                          value={pros}
                          onChange={(event: { target: { value: any; }; }) => this.setState({pros: event.target.value})}
                          
                          placeholder="نقاط قوت محصول"
                        />
                <BsPlusCircle className="icon-blue" onClick={this.handleSubmitPros}/>
                <ul>
                {prosList?.length > 0 && prosList.map((item:any, index:number) =>{
                  return(
                    <EditableCard  index={index} value={item} handleChange={(event: any)=>this.handleChangePros(event, index)}
                    handleCancel={()=>this.handleDeletePros(index)} />
                  )
                })}
                </ul>
               
                </div>
                
                <div className="column-50">
                <TextAreaLabel
                          onKeyDown={this.handleKeyPressCons}
                          value={cons}
                          onChange={(event: { target: { value: any; }; }) => this.setState({cons: event.target.value})}
                          placeholder="نقاط ضعف محصول"
                        />
                <BsPlusCircle 
                className="icon-blue" onClick={this.handleSubmitCons}/>
                
                <ul>
                {consList?.length > 0 && consList.map((item:any, index:number) =>{
                  return(
                    <EditableCard  index={index} value={item} 
                    handleChange={(event: any)=>this.handleChangeCons(event, index)}
                    handleCancel={()=>this.handleDeleteCons(index)} />
                  )
                })}
                </ul>
                </div>
                  </div>
                
                </div> : toggleTab === 1 ? 
                <>
                <div className="media">
                    <ToggleButtonGroup
                        value={toggleMediaTab}
                        exclusive
                        onChange={this.handleToggleMediaTabs}
                        aria-label="text alignment"
                        sx={{borderLeft: "1px solid #ebebeb"}}
                        >
                        <ToggleButton sx={{border:"0px"}} value={0} aria-label="left aligned">
                        تصاویر
                        </ToggleButton>
                        <ToggleButton value={1} aria-label="centered">
                            ویدیوها
                        </ToggleButton>
                        <ToggleButton value={2} aria-label="right aligned">
                        فایل‌ها
                        </ToggleButton>     
                      </ToggleButtonGroup>
                      </div> 
                      {toggleMediaTab === 0 ? 
                      
                  <DragDrop type="image" setContainers={(argOne: any, argTwo: any, argThree: any) =>
                        
                        this.changeImage(argOne, argTwo, argThree)
                      }
                      />
                      
                 : toggleMediaTab === 1 ?  
                <DragDrop type="video" setContainers={(argOne: any, argTwo: any, argThree: any) =>
                  this.changeVideo(argOne, argTwo, argThree)
                      }/>

                 : toggleMediaTab === 2 ? <DragDrop type="file" setContainers={(argOne: any, argTwo: any, argThree: any) =>
                  this.changeFile(argOne, argTwo, argThree)
                      }/> 
                      : ""}
                    <div className="seo-card-preview">
                    {seoCardList?.length > 0 && seoCardList.map((item:any, index:number) =>{
                    return(
                    <SeoCard changeAlt={(event: any) => this.changeAlt(event, index)} handleSubmit={this.handleSubmitSeoCard} 
                    img={Logo} alt={item?.alt} greenIconTooltip="تصویر شاخص" redIconTooltip="حذف فایل" handleDelete={()=>this.handleDeleteSeoCard(index)}/>
                        )
                })}
                    </div>
                </>: toggleTab === 2 ? <div></div> : toggleTab === 3 
                ? <div className="seo">
                  <label>عنوان سئو</label>
                   <TextAreaLabel
                            value={seoTitle}
                            onChange={(event: { currentTarget: { value: any; }; }) =>
                              this.setState({seoTitle: event.currentTarget.value
                                })
                          }                    
                          placeholder={"عنوان سئو"}
                          inputContainerStyle={ (seoTitle?.length < 60 && seoTitle?.length >= 50)  ? "border-green" :
                          (seoTitle?.length < 80 && seoTitle?.length >= 60) ?  "border-orange" : (seoTitle?.length >= 80) ? "border-red" : "border-green"}
                        
                  />
                  <label>توضیحات سئو</label>
                   <TextAreaLabel
                            value={seoDescription}
                                         
                          placeholder={"توضیحات سئو"}
                          onChange={(event: { currentTarget: { value: any; }; }) =>
                            
                            this.setState({seoDescription: event.currentTarget.value})

                        }
                        inputContainerStyle={ (seoDescription?.length < 160 && seoDescription?.length >= 120)  ? "border-green" :
                        (seoDescription?.length < 180 && seoDescription.length >= 160) ?  "border-orange" : (seoDescription?.length >= 180) ? "border-red" : "border-green"}
                        />
                        <label>آدرس URL Canonical</label>
                        <TextAreaLabel
                            value={canonicalUrl ? canonicalUrl : "ثبت نشده"}
                                         
                          placeholder={"آدرس URL Canonical"}
                          onChange={(event: { currentTarget: { value: any; }; }) =>
                          
                            this.setState({canonicalUrl: event.currentTarget.value})

                        }
                        
                        />
                  <div className="google-preview">
                  <div className="details">
                      پیش‌نمایش گوگل

                      <span className="name">

                      {routeName}/product
                      </span>
                      <span className="seo-title">
                      {seoTitle}
                      </span>
                      <span className="seo-desc">
                      {seoDescription}
                        </span>
                      
                  </div>
                  <div className="rate">
                    <Rating name="read-only" value={3} readOnly />
                  </div>
                      
                  </div>

                </div>
                 : toggleTab === 4 ?
                 <Editor
                 apiKey="bnl71iiv8jm79p88zww9qa0rvj9u4jz5zywkym0jj16uvli4"
                 initialValue={editorShortInfoText}
                 init={{
                   toolbar: 'undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                   plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
                   editimage_cors_hosts: ['picsum.photos'],
                   menubar: 'file edit view insert format tools table help',
                   toolbar_sticky: true,
                   autosave_ask_before_unload: true,
                   autosave_interval: '30s',
                   autosave_prefix: '{path}{query}-{id}-',
                   autosave_restore_when_empty: false,
                   autosave_retention: '2m',
                   image_advtab: true,
                   importcss_append: true,
                   directionality : 'rtl',
                   language:"fa"
 
                 }}
                 
                 onChange={this.handleEditorChangeShortInfo}
                 /> 
                 : toggleTab === 5 ?
                 <Editor
                 apiKey="bnl71iiv8jm79p88zww9qa0rvj9u4jz5zywkym0jj16uvli4"
                 initialValue={editorGeneralInfoText}
                 
                 init={{
                   toolbar: 'undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                   plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
                   editimage_cors_hosts: ['picsum.photos'],
                   menubar: 'file edit view insert format tools table help',
                   toolbar_sticky: true,
                   autosave_ask_before_unload: true,
                   autosave_interval: '30s',
                   autosave_prefix: '{path}{query}-{id}-',
                   autosave_restore_when_empty: false,
                   autosave_retention: '2m',
                   image_advtab: true,
                   importcss_append: true,
                   directionality : 'rtl',
                   language:"fa"
 
                 }}
                 
                 onChange={this.handleEditorChangeGeneralInfo} 
                 />
                 : ""}
         
          </div>
          </div>
          </div>
          </ThemeProvider>
    )
}
}
const mapStateToProps = (dispatch: any) => {
  return {
    dispatch,
    siteList: dispatch.general.siteList,
    loadingSiteList: dispatch.general.loadingSiteList,
    userData: dispatch.general.userData,
  };
};
export default connect(mapStateToProps)(ProductUpdate)
