import { Component, createRef } from "react";
import { fixNumbers } from "../../../core/utils";
import { call_api, parseJSON } from "../../../core/service";
import { AppConstants } from "../../../core/constants";
import { toast } from "react-toastify";
import * as yup from "yup";
import moment from "moment-jalaali";
interface IProps {
  dispatch?: any;
  siteList?: any;
  loadingSiteList?: any;
}
interface IState {
  loading?: boolean;
  openCreateFactorModal: boolean;
  openPreOrderProductModal: boolean;
  activeCheckBoxIdOne: boolean;
  sendingStatus: string;
  invoicesListData: any;
  currentItem: any;
  firstName: string;
  lastName: string;
  recieverName: string;
  recieverPhone: string;
  mobile: string;
  errorsList?: any;
  page: number;
  usersAddressList: Array<any>;
  date: string;
  address: string;
  toggleAddressPage: boolean;
  userId: number;
  addressMessage: string;
  dbName: string;
  addressLoading: boolean;
  dreiverId: any;
  phone: string;
  whatsapp: string;
  openNewUserModal: boolean;
  profileListData: any;
  userSelected: any;
  loadingUpdateProfile: boolean;
  userLoading?: boolean;
  userDreiverId: any;
  addressError: boolean;
  rowCount: any;
  rowCountList: any;
  paginate: number;
  dateValue: any;
  pageStatus?: boolean;
  brandLoading: boolean;
  allOption: any;
  showFilters: boolean;
  purchaseStatus: string | null;
  converted: string | null;
  finalyDate: any;
  modalLoading:boolean,
  openEditAdress:boolean,
  editAddressData:any,
  openEditAddressAlert:boolean,
  step:number,
  addressData:any;

}

class PreFactorController extends Component<IProps, IState> {
  markerRef: React.RefObject<any>;
  tableInstanceRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    this.markerRef = createRef();
    this.tableInstanceRef = createRef()
    this.state = {
      loading: false,
      openCreateFactorModal: false,
      openPreOrderProductModal: false,
      activeCheckBoxIdOne: false,
      sendingStatus: "send",
      invoicesListData: [],
      currentItem: {},
      firstName: "",
      lastName: "",
      mobile: "",
      errorsList: [],
      page: 1,
      usersAddressList: [],
      date: moment(new Date().setDate(new Date().getDate() + 1)).format(
        "jYYYY/jM/jD"
      ),
      address: "",
      recieverPhone: "",
      recieverName: "",
      toggleAddressPage: false,
      userId: 1,
      addressMessage: "",
      dbName: "",
      addressLoading: false,
      dreiverId: [],
      phone: "",
      whatsapp: "",
      openNewUserModal: false,
      userSelected: null,
      profileListData: [],
      loadingUpdateProfile: false,
      userLoading: false,
      userDreiverId: null,
      addressError: false,
      rowCount: 100,
      rowCountList: [10, 25, 50, 100],
      paginate: 0,
      pageStatus: false,
      dateValue: [],
      brandLoading: false,
      allOption: {
        id: 0,
        value: {
          persian_name: "همه",
          name: "*",
        },
      },
      showFilters: false,
      purchaseStatus: null,
      converted: null,
      finalyDate:moment(new Date().setDate(new Date().getDate() + 1)).format("jYYYY/jM/jD"),
      modalLoading:false,
      openEditAdress:false,
      editAddressData:null,
      openEditAddressAlert:false,
      step:0,
      addressData:{title:"", province:{id: 8, name:"تهران"}, city:{id:117, name:"تهران"}, region:{id:101, name:"", }, postal_code:"", address_description:"", full_address:""},


    };
  }

  dateTimeOut: string | number | NodeJS.Timeout | undefined;
  handleChange = (value: any) => {
    this.setState({
      dateValue: value,
    });
    if (value?.length === 2) {
      this.setState({page:1})
      clearTimeout(this.dateTimeOut);
      this.dateTimeOut = setTimeout(() => {
        this.getInvoicesList();
      }, 800);
    }
  };
  handleSetStep = (step: number) => {
    this.setState({ step: step })
  };
  clearDateTimeOut: string | number | NodeJS.Timeout | undefined;
  handleClearDate = () => {
    this.setState({
      dateValue: [],
    });
    this.setState({page:1})
    clearTimeout(this.clearDateTimeOut);
    this.clearDateTimeOut = setTimeout(() => {
      this.getInvoicesList();
    }, 800);
  };
  paginationSelectTimeOut: string | number | NodeJS.Timeout | undefined;
  handlePaginationSelect = (event: any) => {
    this.setState({
      rowCount: event.currentTarget.value,
      page:1
    });
    clearTimeout(this.paginationSelectTimeOut);
    this.paginationSelectTimeOut = setTimeout(() => {
      this.getInvoicesList();
    }, 800);
  };
  handleSelect = (selected: any) => {};
  searchUserSelectedTimeout: string | number | NodeJS.Timeout | undefined;

  userSelected = (choice: any) => {
    this.setState({
      userSelected: choice,
      recieverName: choice?.profile?.full_name || "",
      recieverPhone: choice?.mobile || "" || "",
      page: 1
    });
    this.searchUserSelectedTimeout = setTimeout(() => {
      this.getInvoicesList();
    }, 1000);
  };
  handleCloseNewUserModal = () => {
    this.setState({ openNewUserModal: false });
  };
  searchUserTimeout: string | number | NodeJS.Timeout | undefined;
  handleSearchUser = (choice: string) => {
    clearTimeout(this.searchUserTimeout);
    if (choice.length > 2)
      this.searchUserTimeout = setTimeout(() => {
        this.getProfilesList(choice);
      }, 1000);
  };
  async getProfilesList(search?: string) {
    const { dreiverId } = this.state;
    const driversSelectedList = [];
    for (var i = 0; i < dreiverId.length; i++) {
      driversSelectedList.push(dreiverId[i]?.value?.name);
    }
    this.setState({ userLoading: true });
    let parameters = {
      search: search ? search.toString() : null,
      db_name: dreiverId?.length > 0 ? driversSelectedList.toString() : null,
    };
    let url = new URL(
      `${AppConstants.base_url_api}seller/users-all-no-paginate`
    );

    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value);
      }
    }
    call_api({
      address_api: url.search
        ? `${AppConstants.base_url_api}seller/users-all-no-paginate${url.search}`
        : `${AppConstants.base_url_api}seller/users-all-no-paginate`,
      method_api: "GET",
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({ userLoading: false });
          if (j.data) {
            this.setState({ profileListData: j?.data });
          }
        } else if (status === 401) {
          this.setState({ userLoading: false });
          localStorage.clear();
        } else {
          this.setState({ userLoading: false });
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({ userLoading: false });
      });
  }
  userDdriverSelected = (choice: any) => {
    this.setState({ userDreiverId: choice });
  };
  async createNewUser() {
    const mobileRegExp = /^09\d{9}$/;
    const phoneRegExp = /^\d{5,12}$/;
    const justPersian = /^[\u0600-\u06FF\s]+$/gi;
    const { mobile, firstName, userDreiverId, lastName, phone, whatsapp } = this.state;
    const updateProfileSchema = yup.object({
      userDreiverId: yup.object().required(" .سایت الزامی می باشد "),
      firstName: yup
        .string()
        .required(" .نام الزامی می باشد ")
        .max(32, ".نام بیشتر از 32 رقم نمی باشد")
        .min(3, " .نام کمتر از 3 رقم نمی باشد")
        .matches(justPersian, " .لطفا از زبان فارسی استفاده کنید  "),
      lastName: yup
        .string()
        .required(" .نام خانوادگی الزامی می باشد ")
        .max(32, ".نام خانوادگی بیشتر از 32 رقم نمی باشد")
        .min(3, "  .نام خانوادگی کمتر از 3 رقم نمی باشد")
        .matches(justPersian, " .لطفا از زبان فارسی استفاده کنید  "),
      mobile: yup
        .string()
        .required(" .شماره همراه الزامی می باشد  ")
        .matches(mobileRegExp, ".فرمت وارد شده صحیح نمی باشد"),
      phone: yup
        .string()
        .notRequired()
        .matches(phoneRegExp, "فرمت وارد شده صحیح نمی باشد").transform((value) => (!!value ? value : null)),
      whatsapp: yup
        .string()
        .notRequired()
        .matches(mobileRegExp, "فرمت وارد شده صحیح نمی باشد ")
        .nullable()
        .transform((value) => (!!value ? value : null)),
    });

    try {
      await updateProfileSchema.validate(
        { firstName, lastName, userDreiverId, mobile, phone, whatsapp },
        { abortEarly: false }
      );
      this.setState({ loadingUpdateProfile: true });
      const data = new FormData();
      data.append("name", firstName);
      data.append("family", lastName);
      data.append("mobile", mobile);
      phone && data.append("phone", phone);
      data.append("whatsapp", whatsapp);
      data.append("db_name", userDreiverId?.value?.name);
      call_api({
        address_api: `${AppConstants.base_url_api}seller/users-all`,
        method_api: "POST",
        body: data,
        file: true,
      })
        .then(parseJSON)
        .then(([status, j]) => {
          this.setState({ errorsList: [] });
          if (status === 200) {
            toast.success(j?.message);
            this.getProfilesList();
            this.userSelected(j?.data);
            this.setState({
              openNewUserModal: false,
              firstName: "",
              lastName: "",
              mobile: "",
              phone: "",
              whatsapp: "",
              loadingUpdateProfile: false,
            });
          } else if (status === 401) {
            this.setState({ loadingUpdateProfile: false });
            toast.error(j?.message);
            localStorage.clear();
          } else {
            console.warn(status, j);
            this.setState({ loadingUpdateProfile: false });
            toast.error(j?.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (err: any) {
      this.setState({ loadingUpdateProfile: false, errorsList: err.inner });
    }
  }
  paginationTimeOut: string | number | NodeJS.Timeout | undefined; 
  handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ page: value })
    clearTimeout(this.paginationTimeOut);
    this.paginationTimeOut = setTimeout(() => {
      this.getInvoicesList()
    }, 800);
  }
  handleNewAddress = () => {
    this.setState({ toggleAddressPage: true });
  };
  handleDate = (value: any) => {
    this.setState({date: value, finalyDate : fixNumbers(value?.format("YYYY/MM/DD"))})
  };
  handleAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ address: (event.target as HTMLInputElement).value });
  };
  handleCloseCreateFactorModal = () => {
    this.setState({ openCreateFactorModal: false });
  };
  handleClosePreOrderProductModal = () => {
    this.setState({
      openPreOrderProductModal: false,
      toggleAddressPage: false,
      addressData:{title:"", province:{id: 8, name:"تهران"}, city:{id:117, name:"تهران"}, region:{id:101, name:"", }, postal_code:"", address_description:"", full_address:""},
      errorsList: [],
      currentItem: "",
      addressError:false
    });
  };
  async handleDownloadPDF(item: any) {
    call_api({
      address_api: `${AppConstants.base_url_api}seller/pre-factors/pdf/${item?.item?.id}?db_name=${item?.item?.db_name}`,
      method_api: "GET",
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          window.open(j?.link?.PreFactorLink);
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  activeCheckBox = () => {
    this.setState({ activeCheckBoxIdOne: !this.state.activeCheckBoxIdOne });
  };

  handleSendingStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ sendingStatus: (event.target as HTMLInputElement).value });
  };
  async createFactor() {
    
    const mobileRegExp = /^09\d{9}$/;
    const { recieverName, recieverPhone, step, sendingStatus, address, firstName, lastName,
      mobile, finalyDate, currentItem, dbName} = this.state;
    const prefactorInfo = yup.object({
      address: yup.string().when("sendingStatus", {
        is: (val: any) => val === "send",
        then: (schema) => schema.required("انتخاب آدرس الزامی است."),
      }),
      firstName: yup.string().required("نام الزامی می باشد."),
      lastName: yup.string().required("خانوادگی الزامی می‌باشد."),
      mobile: yup
        .string()
        .required("موبایل الزامی است")
        .matches(mobileRegExp, "فرمت وارد شده صحیح نمی باشد"),
      recieverPhone: yup
        .string()
        .required(" شماره همراه الزامی می باشد  ")
        .matches(mobileRegExp, "فرمت وارد شده صحیح نمی باشد"),
      recieverName: yup
        .string()
        .required(" نام و نام خانوادگی الزامی می باشد "),
    });

    try {
      await prefactorInfo.validate(
        { recieverName, recieverPhone, firstName, lastName, mobile, address },
        { abortEarly: false }
      );
      const data = new FormData();
      data.append("user_id", currentItem?.user_id.toString());
      data.append("db_name", dbName);
      data.append("receiver_name", recieverName);
      data.append("receiver_phone", recieverPhone);
      data.append(
        "sending_at",
        moment(finalyDate, "jYYYY/jM/jD").format("YYYY-MM-DD")
      );
      data.append("received_at",  step === 0 ? "10-13" : step === 1 ? "13-16" : "16-19");
      data.append("prefactor_id",currentItem?.id);
      data.append("products", JSON.stringify(currentItem?.preFactorProducts));
      data.append("address_id", sendingStatus === "send" ? address : "0");
      data.append("self_receive", sendingStatus === "send" ? "0" : "1");
      data.append("name", firstName);
      data.append("family", lastName);
      data.append("mobile", mobile);
      if ((sendingStatus === "send" && address) || sendingStatus !== "send") {
        call_api({
          address_api: `${AppConstants.base_url_api}seller/convert-prefactor-to-factor`,
          method_api: "POST",
          body: data,
          file: true,
        })
          .then(parseJSON)
          .then(([status, j]) => {
            this.setState({ errorsList: [] });
            if (status === 200) {
              toast.success(j?.message);
              this.setState({
                openPreOrderProductModal: false,
                address: "",
                recieverName: "",
                recieverPhone: "",
                currentItem:"",
              });
              this.getInvoicesList();
            } else if (status === 401) {
              toast.error(j?.message);
            } else {
              toast.error(j?.message);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.setState({ addressError: true });
      }
    } catch (err: any) {
      this.setState({ errorsList: err.inner });
    }
  }

  purchaseStatusTimeOut: string | number | NodeJS.Timeout | undefined;
  handlePurchaseStatus = (status: string) => {
    this.setState({ purchaseStatus: status, converted: null, page:1 });
    clearTimeout(this.purchaseStatusTimeOut);
    this.purchaseStatusTimeOut = setTimeout(() => {
      this.getInvoicesList();
    }, 1000);
  };
  convertedTimeOut: string | number | NodeJS.Timeout | undefined;
  handleConvertedStatus = (status: string) => {
    this.setState({ purchaseStatus: null, converted: status, page:1 });
    clearTimeout(this.convertedTimeOut);
    this.convertedTimeOut = setTimeout(() => {
      this.getInvoicesList();
    }, 1000);
  };
  async getInvoicesList() {
    this.setState({ loading: true });
    const {
      purchaseStatus,
      converted,
      dreiverId,
      userSelected,
      rowCount,
      dateValue,
      page
    } = this.state;
    const driversSelectedList = [];
    for (var i = 0; i < dreiverId.length; i++) {
      driversSelectedList.push(dreiverId[i]?.value?.name);
    }
    let parameters = {
      page: page ? page.toString() : null,
      db_name: dreiverId?.length > 0 ? driversSelectedList.toString() : null,
      search: userSelected ? userSelected.id.toString() : null,
      paginate: rowCount,
      purchase_status: purchaseStatus ? purchaseStatus.toString() : null,
      is_converted: converted,
      start_at:
        dateValue.length === 2
          ? moment(
              fixNumbers(dateValue[0]?.format("YYYY-MM-DD")),
              "jYYYY/jMM/jDD"
            ).format("YYYY-MM-DD")
          : null,
      end_at:
        dateValue.length === 2
          ? moment(
              fixNumbers(dateValue[1]?.format("YYYY-MM-DD")),
              "jYYYY/jMM/jDD"
            ).format("YYYY-MM-DD")
          : null,
    };
    let url = new URL(`${AppConstants.base_url_api}seller/pre-factors`);
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value);
      }
    }
    call_api({
      address_api: url.search
        ? `${AppConstants.base_url_api}seller/pre-factors${url.search}`
        : `${AppConstants.base_url_api}seller/pre-factors`,
      method_api: "GET",
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({ invoicesListData: j?.data, loading: false });
        } else if (status === 401) {
          this.setState({ loading: false });
          localStorage.clear();
        } else {
          this.setState({ loading: false });
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async getUsersAddress(userId?: number) {
    this.setState({
      addressLoading: true,
    });
    call_api({
      address_api: `${AppConstants.base_url_api}seller/address-user-all/${userId}`,
      method_api: "GET",
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          
          if(j?.data)
          {

            this.setState({
              usersAddressList: j.data,
              addressMessage: j?.message,
              addressLoading: false,
              address:j.data[j?.data.length - 1]?.id
            });          }
          else{
            this.setState({addressMessage:j.message, addressLoading: false, address:"", usersAddressList:[] })
          }
         
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  handleOpenCreateFactorModal = (row: any) => {
    this.setState({ openCreateFactorModal: true, modalLoading: true });
    call_api({
      address_api:`${AppConstants.base_url_api}seller/pre-factors/${row.item?.id_select}`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {            
          this.setState({
            currentItem: j.data
            , modalLoading:false
          })
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  driverTimeout: string | number | NodeJS.Timeout | undefined;
  driverSelected = (selected: any, index?: any): any => {
    this.setState({page:1})
    if (index?.option?.id === 0) {
      this.setState({
        dreiverId: this.props.siteList,
      });
    } else
      this.setState({
        dreiverId: selected,page:1, profileListData:[], userSelected:null
      });
    clearTimeout(this.driverTimeout);
    this.driverTimeout = setTimeout(() => {
      this.getInvoicesList();
    }, 1000);
  };
  handleOpenPreOrderProductModal = (row: any) => {
    this.getUsersAddress(row.item?.user?.id_select);
    this.setState({
      openPreOrderProductModal: true,
      modalLoading:true
    });
    call_api({
      address_api:`${AppConstants.base_url_api}seller/pre-factors/${row.item?.id_select}`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {            
          this.setState({
            currentItem: j.data,
            recieverName: `${j?.data?.name} ${j?.data?.family}`,
            recieverPhone: j?.data?.mobile,
            mobile: j?.data?.mobile,
            lastName: j?.data?.family,
            firstName: j?.data?.name,
            userId: j?.data?.user_id,
            dbName: j?.data?.db_name,
            modalLoading:false
          })
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  searchLocation = () => {};
  async addAddress(address:any){
    const { currentItem } = this.state;
    this.setState({addressData: address})
    const addressInfo = yup.object().shape({
      address:yup.object().shape({
      city: yup.object().required("شهر الزامی است."),
      full_address:yup.string().required("آدرس دقیق الزامی است."),
      province:yup.object().required("استان الزامی است."),
      title:yup.string().required("عنوان آدرس الزامی است."),
      postal_code: yup.string().notRequired().max(10,"کدپستی باید شامل ۱۰ عدد باشد.").min(10,"کدپستی باید شامل ۱۰ عدد باشد.").nullable().transform((value) => !!value ? value : null),
    })
  })   
  try{
   await addressInfo.validate({address}, { abortEarly: false });
    call_api({
      address_api: `${AppConstants.base_url_api}seller/address-all`,
      method_api: "POST",
      body: JSON.stringify({
        user_id: currentItem?.user_id.toString(),
        label: address.title,
        db_name: currentItem?.db_name,
        province_id: address.province?.id.toString(),
        address: address.full_address,
        city_id : address.city?.id.toString(),
        desc: address.address_description,
        postal_code: address.postal_code,
        region: address.region?.name
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        this.setState({ errorsList: [] })
        if (status === 200) {
          toast.success(j?.message);
          this.setState({toggleAddressPage: false, addressData:{title:"", province:{id: 8, name:"تهران"}, 
          city:{id:117, name:"تهران"}, region:{id:101, name:"", }, postal_code:"", address_description:"",
           full_address:""},
        });
            this.getUsersAddress()

        } else if (status === 401) {
          toast.error(j?.errors);
          localStorage.clear();
        } else {
          
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error("catch api",error);
      });
   }
    catch(err: any) 
        { this.setState({ errorsList: err.inner })}
}
  handleChangePageStatus = (event: any) => {
    this.setState({ pageStatus: event.target.checked });
  };
  handleOpenEditAddressModal = (data:any) =>{
    this.setState({openEditAdress: true, editAddressData: data});
      let parameters = {
       db_name: data?.db_name,
      };
      let url = new URL(
        `${AppConstants.base_url_api}seller/address-all/${data.id}`
      );
  
      for (const [key, value] of Object.entries(parameters)) {
        if (value) {
          url.searchParams.append(key, value);
        }
      }
      call_api({
        address_api: url.search
          ? `${AppConstants.base_url_api}seller/address-all/${data.id}${url.search}`
          : `${AppConstants.base_url_api}seller/address-all/${data.id}`,
        method_api: "GET",
      })      
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({ addressData:{title:j?.data?.label, province:{id: j?.data?.province_id?.id, name: j?.data?.province_id?.name}, city:{id:j?.data?.city_id?.id, name:j?.data?.city_id?.name}, 
          region:{id:101, name:j?.data?.region, }, postal_code:j?.data?.postal_code, address_description:j?.data?.desc, full_address:j?.data?.address},
        })
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
    handleCloseAddressEditModal = () => {
    this.setState({ openEditAdress: false })
  }
  handleEdit = (data:any)=>{
    this.setState({openEditAddressAlert: true, addressData: data})
  }
  async handleConfirmEdition() {
    const { editAddressData, addressData, userSelected } = this.state;
    const addressInfo = yup.object().shape({
      addressData:yup.object().shape({
      city: yup.object().required("شهر الزامی است."),
      full_address:yup.string().required("آدرس دقیق الزامی است."),
      province:yup.object().required("استان الزامی است."),
      title:yup.string().required("عنوان آدرس الزامی است."),
      postal_code: yup.string().notRequired().max(10,"کدپستی باید شامل ۱۰ عدد باشد.").min(10,"کدپستی باید شامل ۱۰ عدد باشد.").nullable().transform((value) => !!value ? value : null),
    })
  })   
  try{
   await addressInfo.validate({addressData}, { abortEarly: false });
    call_api({
      address_api: `${AppConstants.base_url_api}seller/address-all/${editAddressData?.id}`,
      method_api: "PATCH",
      body: JSON.stringify({
        user_id: userSelected?.id.toString(),
        label: addressData.title,
        db_name: editAddressData?.db_name,
        province_id: addressData.province?.id.toString(),
        address: addressData.full_address,
        city_id : addressData.city?.id.toString(),
        desc: addressData.address_description,
        postal_code: addressData.postal_code,
        region: addressData.region?.name
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        this.setState({ errorsList: [] })
        if (status === 200) {
          toast.success(j?.message);
          this.setState({openEditAdress: false, openEditAddressAlert: false,
            addressData:{title:"", province:{id: 8, name:"تهران"}, city:{id:117, name:"تهران"}, region:{id:101, name:"", }, postal_code:"", address_description:"", full_address:""},
          });
            this.getUsersAddress(userSelected?.id_select)

        } else if (status === 401) {
          toast.error(j?.errors);
          localStorage.clear();
        } else {
          
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error("catch api",error);
      });
   }
    catch(err: any) 
        { this.setState({ errorsList: err.inner })}
  }
}
export default PreFactorController;
