import { connect } from "react-redux";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import {
  ReactElement,
} from "react";
interface Props {
  allowedRoles: any;
    auth?: any;
  }
  const RequireAuth = ({allowedRoles, auth}:Props):ReactElement => {
    const isLogin = localStorage.getItem("token");

    return <>{
      auth?.roles?.map((el: any) =>  allowedRoles === el.name)[0]
            ? <Outlet />
                : isLogin === null ? <Navigate to="/login" /> :<Navigate to="/404" replace />
                
    }</>;
  }
export default RequireAuth;
