import { Component } from "react";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { toast } from 'react-toastify';
import { AppConstants } from '../../../core/constants';
import { call_api, parseJSON } from "../../../core/service";
import { selectClasses } from "@mui/material";
interface IProps { 
  dispatch?:any,
  siteList?:any,
  loadingSiteList?:any,
 }

interface IState {
    loading?: boolean,
    search: string,
    openSingleModal: boolean,
    openMultipleModal: boolean,
    relatedProductsLis: any,
    currentItem: any,
    page: number,
    dreiverId: any,
    rowCount:any,
    rowCountList:any,
    paginate: number,
    showFilters:boolean,
    

}

 class ProductsRelationsController extends Component<IProps, IState> {
  state: IState = {
    loading: false,
    search: "",
    openSingleModal: false,
    openMultipleModal: false,
    relatedProductsLis: [],
    currentItem: {},
    page: 1,
      dreiverId: [],
      rowCount:100,
      rowCountList:[10, 25 , 50 , 100],
      paginate: 0,
      showFilters:false,



  }
  paginationSelectTimeOut: string | number | NodeJS.Timeout | undefined; 
  handlePaginationSelect=(event:any)=>{
    this.setState({
      rowCount: event.currentTarget.value,
      page:1
    })
    clearTimeout(this.paginationSelectTimeOut);
    this.paginationSelectTimeOut = setTimeout(() => {
      this.getRelatedProducts()
    }, 800);
    
  }
  paginationTimeOut: string | number | NodeJS.Timeout | undefined; 
  handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ page: value })
    clearTimeout(this.paginationTimeOut);
    this.paginationTimeOut = setTimeout(() => {
      this.getRelatedProducts()
    }, 800);
  }

  driverTimeout: string | number | NodeJS.Timeout | undefined;
  driverSelected = (choice: any) => {
    this.setState({ dreiverId: choice, page: 1 })
    clearTimeout(this.driverTimeout);
    this.driverTimeout = setTimeout(() => {
      this.getRelatedProducts()
    }, 1000);
  }

  filterTimeout: string | number | NodeJS.Timeout | undefined;
  handleSearch = (event: string) => {
    this.setState({ search: event })
    clearTimeout(this.filterTimeout);
    this.filterTimeout = setTimeout(() => {
      this.getRelatedProducts()
    }, 1000);
  };
  async getRelatedProducts() {
    const { search , dreiverId,rowCount , page} = this.state;
    this.setState({ loading: true })
    let parameters = {
      search: search ? search : null,
      db_name: dreiverId?.value?.name ,
      page: page ? page.toString() : null,
      paginate: rowCount,

    }
    let url = new URL(`${AppConstants.base_url_api}seller/product/products-sync`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}seller/product/products-sync${url.search}` : `${AppConstants.base_url_api}seller/product/products-sync`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          if(j.data)
          {

            this.setState({
              relatedProductsLis: j.data
            })
          }
          this.setState({ loading: false })
        } else if (status === 401) {
          this.setState({ loading: false })
          localStorage.clear();
        } else {
          this.setState({ loading: false })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  handleOpenSingle = (event: any) => {
    this.setState({ currentItem: event })
    this.setState({
      openSingleModal:true
    })
  }
  handleOpenMultiple = (event: any) => {
    this.setState({ currentItem: event })
    this.setState({
      openMultipleModal:true
    })
  }
  async handleDeleteSingle() {
    const { currentItem } = this.state;
    call_api({
      address_api: `${AppConstants.base_url_api}seller/product/unsync`,
      method_api: "POST",
      body: JSON.stringify({
        id_select: currentItem.id_select
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
            openSingleModal: false
          })
          this.getRelatedProducts();
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  async handleDeleteMultiple() {
    const { currentItem } = this.state;
    call_api({
      address_api: `${AppConstants.base_url_api}seller/product/unsync-multiple-all`,
      method_api: "POST",
      body: JSON.stringify({
        mId: currentItem.mId.toString()
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
            openMultipleModal: false
          })
          this.getRelatedProducts();
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  /////////////////////////////////////////////////////////////////////////////////



}
export default ProductsRelationsController;