import React, { useState, useEffect } from 'react';
import {
    HTMLAttributes,
    ReactElement,    
  } from "react";
import {AiOutlineVideoCamera, AiOutlineFile} from "react-icons/ai";
import { RxCross2 } from 'react-icons/rx';
interface Props extends HTMLAttributes<HTMLElement> {
    type:string,
    setContainers?:any,

  }

function FileUpload({ type, setContainers}: Props): ReactElement {
    const [imagesList, setImagesList] = useState<any>([]);
    const [selectedImage, setSelectedImage] = useState<any>([]);
    // const [previewImage, setPreviewImage] = useState();
   
    const [videosList, setVideosList] = useState<any>([]);
    const [selectedVideo, setSelectedVideo] = useState<any>([]);
    // const [previewVideo, setPreviewVideo] = useState();

    const [fileslist, setFilesList] = useState<any>([]);
    const [selectedFile, setSelectedFile] = useState<any>([]);

    const handleDrop = (event: any) => {
        event.preventDefault();
        var fileType = event.dataTransfer.files[0].type;
        var imageTypes = ['image/png', 'image/jpeg', 'image/webp'];
       
        const { files } = event.dataTransfer;
        if((imagesList.some((i:any)=> i.name === event.dataTransfer?.files[0]?.name  )))
        {
            alert("عکس انتخابی تکراری است!")
        }
        else if(selectedImage.some((i:any)=> i.name === event.dataTransfer?.files[0]?.name))
        {
            alert("عکس انتخابی تکراری است!")

        }
       else if( !imageTypes.includes(fileType)){
            alert("فرمت فایل درست نیست!")
        }
         else {
            setImagesList([...imagesList, files[0]]);
         }
      };
    const onSelectImage = (e: any) => {
       
        if((imagesList.find((i:any)=>  i.name === e.target?.files[0]?.name )
        ))
        {
            alert("عکس انتخابی تکراری است!")
        }
       else if (selectedImage.find((i:any)=> i.name === e.target?.files[0]?.name))
        {
            alert("عکس انتخابی تکراری است!")

        }
        else {
            setSelectedImage([...selectedImage, e.target?.files[0]]);
            
        }
        // setContainers([...imagesList, ...selectedImage], [...videosList, ...selectedVideo], [...fileslist, ...selectedFile])
    }
    
    const handleVideoUpload = (event:any) => {
        event.preventDefault();

        var temp = ".mp4"||".mkv"||".mov";
        
        if(!event.dataTransfer?.files[0].name.includes(temp)){
            
            alert("فایل آپلودشده ویدئو نیست!")
          }
          else if((videosList.some((i:any)=> i.name === event.dataTransfer?.files[0]?.name 
          || selectedVideo.some((i:any)=> i.name === event.target.files[0]?.name)
          ))){
                alert("ویدیوی انتخابی تکراری است!")
          }
          else{
            setVideosList([...videosList,event.dataTransfer?.files[0]]);
            // setContainers([...imagesList, ...selectedImage], [...videosList, ...selectedVideo], [...fileslist, ...selectedFile])

            
          }

      }
    const onSelectVideoUpload = (e:any) =>{
          if((videosList.some((i:any)=> i.name === e.target.files[0]?.name 
        || selectedVideo.some((i:any)=> i.name === e.target.files[0]?.name)
        )))
        {
            alert("ویدیوی انتخابی تکراری است!")
        }
        else {
            setVideosList([...videosList, e.target.files[0]]);
            // setContainers([...imagesList, ...selectedImage], [...videosList, ...selectedVideo], [...fileslist, ...selectedFile])

         }
    }

    const handleFileUpload = (event:any)=>{
        event.preventDefault();
        var temp = ".pdf"||".xlsx";
        if((fileslist.some((i:any)=> i.name === event.dataTransfer?.files[0]?.name 
          || selectedFile.some((i:any)=> i.name === event.target.files[0]?.name)
          ))){
                alert("فایل انتخابی تکراری است!")
          }
          else if(!event.dataTransfer?.files[0].name.includes(temp)){
            
            alert("فرمت فایل درست نیست!")
          }
          else{
            setFilesList([...fileslist,event.dataTransfer?.files[0]]); 
            // setContainers([...imagesList, ...selectedImage], [...videosList, ...selectedVideo],[...fileslist, ...selectedFile])
           
          }
    }
    const onSelectFileUpload = (e:any) =>{
        var temp = ".pdf"||".xlsx";
        if((fileslist.some((i:any)=> i.name === e.target.files[0]?.name 
    || selectedFile.some((i:any)=> i.name === e.target.files[0]?.name)
    )))
    {
        alert("فایل انتخابی تکراری است!")
    }
    else if(!e.target?.files[0].name.includes(temp)){
            
        alert("فرمت فایل درست نیست!")
      }
    else {
        setFilesList([...fileslist, e.target.files[0]]);
        // setContainers([...imagesList, ...selectedImage], [...videosList, ...selectedVideo], [...fileslist, ...selectedFile])

    }
    }
    useEffect(() => {
        setContainers([...imagesList, ...selectedImage], [...videosList, ...selectedVideo], fileslist)

    }, [selectedImage, imagesList, videosList, fileslist, selectedFile, selectedVideo])
    const handleDragOver = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
    }

    const handleDragStart = (event: { dataTransfer: { setData: (arg0: string, arg1: any) => void; }; target: { id: any; }; }) => {
        event.dataTransfer.setData("text/plain", event.target.id)
    }
    const handleDelete = (index:any, type:string) =>{
        if(type === "image upload")
        {
            const list = [...imagesList]
            list.splice(index,1)
            setImagesList(list)
        }
        if(type === "image select")
        {
            const list = [...selectedImage]
            list.splice(index,1)
            setSelectedImage(list)
        }
        if(type === "video upload")
        {
            const list = [...videosList]
            list.splice(index,1)
            setVideosList(list)
        }
        if(type === "video select")
        {
            const list = [...selectedVideo]
            list.splice(index,1)
            setSelectedVideo(list)
        }
        if(type === "file upload")
        {
            const list = [...fileslist]
            list.splice(index,1)
            setFilesList(list)
        }
        if(type === "file select")
        {
            const list = [...selectedFile]
            list.splice(index,1)
            setSelectedFile(list)
        }
        // setContainers([...imagesList, ...selectedImage], [...videosList, ...selectedVideo], [...fileslist, ...selectedFile])
    }
    return (  
            <div className="drag-drop">
                 <div className='count'>
                        <div className='item'>
                            <span>تعداد تصاویر</span>
                            <span>{imagesList.length + selectedImage.length}</span>

                        </div>
                        <div className='item'>
                            <span>تعداد ویدیوها</span>
                            <span>{videosList.length + selectedVideo.length}</span>

                        </div>
                        <div className='item'>
                            <span>تعداد فایل‌ها</span>
                            <span>{fileslist.length + selectedFile.length}</span>

                        </div>
                    </div>
          
                    <p>فایل‌ها را در کادر رها کنید.</p>
                   
                    {type === "image" ? 
                    <div className='upload-area'>
                    <input hidden type='file' id="file" accept="image/*" onChange={(e:any)=>onSelectImage(e)} />
                    <label  htmlFor="file" className="upload-lable">
                      
                      یا انتخاب کنید.
                      </label>
                    <div className="file-upload-area" onDragStart={(e:any)=>handleDragStart(e)} onDragOver={handleDragOver} onDrop={(e:any)=>handleDrop(e)} >

                        <div className="files-container" >
                             { selectedImage && selectedImage?.map ((item:any, index:number)=>{
                            return(
                                <div className="item">
                                <RxCross2 className='icon-blue'  onClick={() => handleDelete(index, "image select")} />
                                   <img key={index} alt="" src={URL.createObjectURL(item)}/>
                                    <span className='file-name'>
                                        {item?.name}
                                        

                                    </span>
                                </div>
                            )
                        }) }
                                {imagesList?.map((item:any, index: number) => {return(
                                    <div key={index} className="item">
                                        <RxCross2 className='icon-blue'  onClick={() => handleDelete(index, "image upload")} />
                                        <img alt="" src={URL.createObjectURL(item)} />

                                        <span className='file-name'>
                                            {item.name}

                                        </span>
                                    </div>
                                )})}
                        </div>

                    </div>
                    </div>
                    : type === "video" ?
                         <div className="upload-area " >
                         <input
                            type="file"
                            id="file"
                            hidden
                            accept="video/*"
                            onChange={(event) => onSelectVideoUpload(event)}
                        />
                         <label  htmlFor="file" className="upload-lable">
                      
                      یا انتخاب کنید.
                      </label>
                         <div className="file-upload-area" onDragStart={(e:any)=>handleDragStart(e)}
                          onDragOver={handleDragOver} onDrop={(e:any)=>handleVideoUpload(e)} >
                        <div className="files-container" >
                        {selectedVideo && 
                        selectedVideo?.map ((item:any, index:number)=>{
                            return(
                                <div className="item">
                                    <RxCross2 className='icon-blue'  onClick={() => handleDelete(index, "video select")} />
                                    <img key={index} src={`${URL.createObjectURL(item)}`} alt=""/>
                                    <span className='file-name'>
                                        {item.name}
                                    </span>
                                </div>
                            )
                        
                        }
                        )}
                                {videosList?.map((item:any, index: number) => {return(
                                    <div className="item">

                                    <RxCross2 className='icon-blue'  onClick={() => handleDelete(index, "video upload")} />
                                        <AiOutlineVideoCamera />
                                        <span className='file-name'>
                                            {item?.name}
                                        </span>
                                    </div> 
                                )})}
                            </div>
                    </div>
                    
                    </div> : type === "file" ? <div className='upload-area'>
                    <input
                            type="file"
                            id="file"
                            hidden
                            onChange={(event) => onSelectFileUpload(event)}
                        />
                         <label  htmlFor="file" className="upload-lable">
                      
                      یا انتخاب کنید.
                      </label>
                         <div className="file-upload-area" onDragStart={(e:any)=>handleDragStart(e)} onDragOver={handleDragOver} onDrop={(e:any)=>handleFileUpload(e)} >
                        <div className="files-container" >
                        { selectedFile && selectedFile?.map ((item:any, index:number)=>{
                            return(
                                <div className="item">
                                <RxCross2 className='icon-blue'  onClick={() => handleDelete(index, "file select")} />
                                   <AiOutlineFile/>
                                    <span className='file-name'>
                                        {item?.name}
                                    </span>
                                </div>
                            )
                        }) }
                           {fileslist?.map((item:any, index: number) => {return(
                                    <div key={index} className="item">
                                        <RxCross2 className='icon-blue'  onClick={() => handleDelete(index, "file upload")} />
                                        <AiOutlineFile/>
                                        <span className='file-name'>
                                            {item?.name}
                                        </span>
                                    </div>
                                )})}
                        </div>
                        </div>
                    </div> :""}
                </div>            
    )
}

export default FileUpload