import { DropDownIcon, CustomButton, Header, OrderDetails, LoadingSpinner,
   InputTextLabel, SubmissionAlert, Option } from "../../../components";
import { AiFillCreditCard, AiOutlineEdit } from "react-icons/ai";
import { BsLink45Deg } from "react-icons/bs";
import { createTheme, ThemeProvider } from '@mui/material';
import Modal from '@mui/material/Modal';
import {orderColumns} from "../../../../core/column"
import DatePicker from "react-multi-date-picker"
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import { AiOutlineFileExcel } from "react-icons/ai";
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import { MaterialReactTable } from 'material-react-table';
import { AiOutlineDesktop } from "react-icons/ai";
import { orderStatus , numberWithCommas, exportToExcel} from "../../../../core/utils";
import Tooltip from '@mui/material/Tooltip';
import { AiOutlineFilePdf } from "react-icons/ai";
import { FiAlertTriangle } from "react-icons/fi";
import Select from "react-select";
import {HiOutlineDocumentMagnifyingGlass} from "react-icons/hi2";
import { connect } from "react-redux";
import {TfiFilter} from "react-icons/tfi";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import { OrdersController } from "../../../controllers";
import { Link } from "react-router-dom";
class Orders extends OrdersController {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getOrdersList();

  }
  render() {
    const { openLinkModal, showFilters, openOrderModal ,loading, paymentStatus, orderTab,
      selectedOrderStatusDropDown,dateValue, allOption, page, total,rowCount,rowCountList,
      orderListData,dreiverId , currentItem, toggleConfirmModal, link, openBillModal, billData,
      toggleBillConfirmModal, toggleRejectModal } = this.state;
      const { siteList , loadingSiteList} = this.props;
    const theme = createTheme({
      typography: {
        fontFamily:
          'Vazir'
      }
    });
    return (
      <HelmetProvider>
      <ThemeProvider theme={theme}>
        <div className="orders">
          <Helmet>
                <title>سفارشات</title>
            </Helmet>
          <Header title="سفارشات"/>
            <div className="custom-card">
            {toggleBillConfirmModal &&
                      <SubmissionAlert isOpen={toggleBillConfirmModal} title="اخطار!"
                        setClose={(open: boolean) => this.setState({ toggleBillConfirmModal: open })}
                        onClick={() => this.handleConfirm(billData)} icon={<FiAlertTriangle color="orange" fontSize="50" />}
                        details={`آیا از تاییدکردن شماره پرداخت ${billData?.id} اطمینان دارید؟`} />
            }
             {toggleRejectModal &&
              <SubmissionAlert isOpen={toggleRejectModal} title="اخطار!" icon={<FiAlertTriangle color="orange" fontSize="50" />}
                details={`آیا از ردکردن شماره پرداخت ${billData?.id} اطمینان دارید؟`}
                setClose={(open: boolean) => this.setState({ toggleRejectModal: open })}
                onClick={() => this.handleReject(billData)} />
              }
              {showFilters && 
              <div className="filter-select-container pe-3">
                    <div className="filter-options-container">
                      <div className="column-30 two-section bottom-14">
                <CustomButton
                        loading={false}
                        myStyle="new-order-btn"
                        title="ثبت سفارش"
                        route={"/ordersubmit"}
                      />
                <Select
                  className="profile-select"
                        styles={{
                          control: (baseStyles):any => ({
                            ...baseStyles,
                            boxShadow:"rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",
                            borderWidth: "1px",
                            backgroundColor: "#fff",
                            display: "flex",
                            flexDirection: "row-reverse",
                            textAlign: "right",
                            borderRadius: "4px",
                            outline: "unset",
                          }),
                          menuPortal: (base):any => ({ ...base, zIndex: 9999 })
                        }}
                        onChange={(choice) => this.driverSelected(choice)}
                        components={{ Option }}
                        value={dreiverId}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        noOptionsMessage={() => 'این مورد وجود ندارد!'}
                        menuPortalTarget={document.body}
                        options={[ allOption, ...siteList]}
                        getOptionLabel={(option: any) => option?.value?.persian_name}
                        getOptionValue={(option: any) => option?.value?.name}
                        placeholder="سایت"
                        isClearable={true}
                        loadingMessage={() => 'در حال بارگذاری  ...'}
                        isLoading={loadingSiteList}
                      />
                      </div> 
                      <div className="column-30 padd-t-10"> 
                      <InputTextLabel
                        type={"text"}
                        onChange={(event) => this.handleSearch(event.currentTarget.value)}
                        className="search-input"
                        placeholder="جستجو..."
                      />                   
                      </div>
                      <div className="column-30 padd-t-10">
                <DatePicker 
                  className="rmdp-prime"
                  inputClass="date-picker"
                  editable={false}
                  range 
                  value={dateValue}
                  dateSeparator=" تا " 
                  maxDate={new Date()}
                  onChange={(date:any) => {
                    this.handleChangeDate(date);
                  }}
                  placeholder="انتخاب بازه زمانی"
                  calendar={persian}
                  locale={persian_fa}
                >
                    <button
                    style={{ margin: "5px 0" }}
                    onClick={() => this.handleClear()}
                    >
                    لغو انتخاب 
                    </button>
                </DatePicker>
                </div>
                  </div>
                </div>
                }
                <Modal
                  open={openOrderModal}
                  onClose={this.handleCloseOrderModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    overflowY:"scroll",
                    fontSize:"13px",
                    width: {
                      xxs: "90%",
                      xs: "90%",
                      sm: "90%",
                      md: "80%",
                      lg: "70%",
                      xl: "70%"
                    },
                    height: '70%',
                  }}>
                  
                    {currentItem === null ? <LoadingSpinner /> : 
                    <Grid>
                    <Box id="modal-modal-title" sx={{ display: "flex", justifyContent: "space-between", alignItems:"center"}}>
                      <Typography variant="subtitle1">
                        سفارش
                      </Typography>
                      <Grid>                      <img className="modal-logo-img" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === currentItem?.db_name)[0]?.value?.logo} alt="..." />                     
</Grid>
                      <AiOutlineFilePdf className="icon-red" onClick={()=>this.handleDownloadPDF(currentItem)} />
                    </Box>
                    <OrderDetails selectedOrder={currentItem} dropDownOnchange={(event: any) =>
                         this.handleChangeModalOrderStatus(event)} hasDropDown dropDownStatus={selectedOrderStatusDropDown}/>
                    <Grid container sx={{ marginTop: "15px", display: "flex", justifyContent: "space-between"}}>
                      <Grid item={true} xs={12} sm={12} md={6} sx={{ paddingLeft: "15px" }}>
                      </Grid>
                    </Grid>
                    </Grid>
                    }
                  </Box>
                </Modal>
                <Modal
                  open={openLinkModal}
                  onClose={this.handleCloseLinkModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize:"14px",
                    width: {
                      xxs: "90%",
                      xs: "90%",
                      sm: "90%",
                      md: "70%",
                      lg: "60%",
                      xl: "60%"
                    },
                    height: 'max-content',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                  }}>
                    <Typography id="modal-modal-title" variant="subtitle1">
                      لینک کوتاه پرداخت مستقیم
                    </Typography>
                    <Typography sx={{ textAlign: "center", margin: "30px" }}>
                      {link}
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                      <CustomButton
                        myStyle="send-sms-btn"
                        loading={false}
                        title="ارسال پیامک برای کاربر سفارش"
                        onClick={()=>this.handleSendSms()}
                      />
                      <CustomButton
                        myStyle="copy-btn"
                        loading={false}
                        onClick={this.handleCopy}
                        title="کپی در کلیپ بورد"
                      />
                    </Box>
                  </Box>
                </Modal>
                <Modal
                  open={openBillModal}
                  onClose={this.handleCloseBill}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    overflowY:"scroll",
                    width: {
                      xxs: "90%",
                      xs: "90%",
                      sm: "90%",
                      md: "80%",
                      lg: "60%",
                      xl: "60%"
                    },
                    maxHeight: '90%',
                    height:"fit-content",
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p:2
                  }}>
                    <Typography sx={{margin:"10px"}} id="modal-modal-title" variant="subtitle1">
                      فیش واریزی  
                    </Typography>
                    {billData === null ? <LoadingSpinner /> :
                    <Grid >
                    <Grid item sx={{display:"flex", justifyContent:"center"}}>
                    <img className="magnified-img" src={billData?.json_result?.track_image_url.startsWith("/") ? billData?.site_url + billData?.json_result?.track_image_url
                    : billData?.site_url + "/" + billData?.json_result?.track_image_url } alt="..."/>

                    </Grid>
                    {billData?.status?.name === 2 &&
                      <div className="bill-action">
                            <CustomButton
                              myStyle="btn-confirm"
                              loading={false}
                              onClick={() =>
                                this.setState({ toggleBillConfirmModal: true })
                              }
                              title="تایید"
                            />
                            <CustomButton
                              myStyle="btn-reject"
                              loading={false}
                              onClick={() => this.setState({ toggleRejectModal: true })}
                              title="ردکردن"
                            />
                      </div>
                      }
                    </Grid>
                    }
                  </Box>
                </Modal>
                {toggleConfirmModal && 
                          <SubmissionAlert isOpen={toggleConfirmModal} title="اخطار!" icon={<FiAlertTriangle color="orange" fontSize="50" />}
                            details={`آیا از تغییر وضعیت سفارش با شناسه ${currentItem?.id} با نام خریدار ${currentItem?.owner} به حالت ${orderStatus.filter((i:any)=> i.value.toString() === selectedOrderStatusDropDown)[0]?.name} اطمینان دارید؟`}
                            setClose={(open: boolean) => this.setState({ toggleConfirmModal: open, selectedOrderStatusDropDown: currentItem.order_status?.name })}
                            onClick={() => this.handleFinalConfirm()} />
                        }
                <MaterialReactTable
                muiTablePaperProps={{
                  sx: { mr: '1.5rem',ml: '1.5rem', border:"1px solid #e5e5e5", mt:'1.5rem' },
                }}
                positionActionsColumn={'last'}
                  columns={orderColumns}
                  data={orderListData && orderListData?.length > 0 && orderListData?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null).map((item: any, index: number) => {
                    return ({
                      item: item,
                      id:item?.id,
                      created_at: item.created_at && item.created_at,
                      driver: <img className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo} alt="..." />,
                      buyer: item?.user?.full_name,
                      finalPrice: numberWithCommas(item?.finalPrice),
                      pay: item?.payment_status?.name === 1 || item?.payment_status?.name === 2 ? ( item?.payment_type.name === 1 ? <Tooltip title={`${item?.payment_type?.value}`}><span><AiOutlineDesktop className="icon-blue" /></span></Tooltip>
                        :item?.payment_type.name === 2 ? <Tooltip title={`${item?.payment_type?.value}`}><span><AiFillCreditCard onClick={() => item?.payment_status.name === 2 || item?.payment_status.name === 1 ? this.getBillImage(item) : {}}  className="icon-orange" /></span></Tooltip>
                        :<Tooltip title={`${item?.payment_type.value}`}><span>{`${item?.payment_type.value}`}</span></Tooltip>)
                        : <span>نامعلوم</span>,
                      status: 
                        <DropDownIcon
                        value={item?.order_status?.name }
                        title={"وضعیت سفارش"}
                        myStyle="drop-down"
                        type={"text"}
                        optionList={orderStatus.filter((i:any)=>item?.payment_status?.name === 1 ?  i.value !== 0 : {})}
                        onChange={(event) =>
                          this.handleChangeDropDown(event, item, index, false)
                        }
                      />               
                      ,
                      paymentStatus:item?.payment_status?.value && item?.payment_status?.value,
                      creator: item?.registered_by,
                    }
                    );
                  }
                  )}
                  enableRowVirtualization
                  rowVirtualizerProps={{ overscan: 20 }} 
                  rowVirtualizerInstanceRef={this.tableInstanceRef} 
                  enableRowNumbers={true}
                  rowNumberMode="static"
                  enablePagination={false}
                  muiTableBodyRowProps={( {row,staticRowIndex} ) => ({
                    sx:{
                      height:"40px",
                      backgroundColor: (staticRowIndex % 2 === 0 ) ? '#f3f3f3'  : "",
                  }})}
                  renderBottomToolbarCustomActions={({table})=>{
                    return(
                      <div className='custom-pagination w-100'>
                <Pagination  page={page} count={total ? Math.ceil(total / rowCount) : 1} onChange={this.handleChangePage} />
                <DropDownIcon
                    value={rowCount}
                    optionList={rowCountList}
                    title={""}
                    onChange={(event) =>{ this.handlePaginationSelect(event) }
                  }
                  />
                  </div>
                  )
                  }}        
                  displayColumnDefOptions={{
                    'mrt-row-expand': {
                      muiTableHeadCellProps: {
                        align: 'center',
                      },
                    },
                  }}
                  state={{ isLoading: loading, showProgressBars: loading }}
                  positionExpandColumn={'last'}
                  enableGlobalFilter={false}
                  enableColumnFilters={false}
                  enableDensityToggle={false}
                  enableFullScreenToggle={false}
                  muiTableProps={{
                    sx: {
                      border: "1px solid #e5e5e5",
                    },
                  }}
                  muiTableHeadCellProps={{
                    sx: {
                      border: "1px solid #e5e5e5",
                      textAlign:"center"
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      border: "1px solid #e5e5e5",
                      textAlign:"center",
                      justifyContent:"center",
                    },
                  }}
                  enableHiding={false}
                  renderTopToolbarCustomActions={({ table }) => (
                    <div className="d-flex w-100 flex-column">
                    <Box
                      sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap', width:"100%", justifyContent:"space-between" }}
                    >
                      <CustomButton
                        loading={false}
                        myStyle="excel-btn"
                        onClick={() => exportToExcel(orderListData && orderListData, "Excel Export")}
                        title="اکسل"
                        icon={<AiOutlineFileExcel />}
                      />
                    <Tooltip title="نمایش فیلترها"><span> <TfiFilter className='filter-icon' onClick={() => this.setState(prevState => ({
                                    showFilters: !prevState.showFilters
                                  }))
                                }/></span></Tooltip>
                    <div className='custom-pagination'>

                    <Pagination  page={page} count={total ? Math.ceil(total / rowCount) : 1} onChange={this.handleChangePage} />
                    <DropDownIcon
                      value={rowCount}
                      optionList={rowCountList}
                      title={""}
                      onChange={(event) =>{ this.handlePaginationSelect(event) }
                    }
                    />
                  </div>
                    </Box>
                    <div className="filter-tab ">
                    <div className={`item ${(orderTab === null && paymentStatus === null) && "is-active" }`} onClick={()=> this.allOrder()} >
                          همه سفارشات
                        </div>  
                      {orderStatus.map((item:any, index:number) => {
                        return(
                          <div key={index} className={`item ${(orderTab === index) && "is-active" }`} onClick={()=>this.handleOrderStatus(index)}>
                            {item?.name}                      
                          </div>
                        )
                      })}
                    </div>
                    </div>
                  )}
                  enableColumnActions={false}
                  sortDescFirst={false}
                  enableSorting={true}
                  initialState={{
                    sorting: [
                      {
                        id: "created_at",
                        desc: true
                      }
                    ],
                  }}
                  enableRowActions
                  localization={{
                    actions: 'عملیات',
                    noRecordsToDisplay:"داده‌ای برای نمایش وجود ندارد!"
                  }}
                  renderRowActions={({ row }) => [
                    <div key={row.id}>
                    {loading === false &&
                    <div className='detail-container'>
                      <Tooltip title="گرفتن لینک"><span><BsLink45Deg className='icon-blue' onClick={() => this.handleOpenModal(row?.original)} /></span></Tooltip>
                      <Tooltip title="مشاهده سفارش"><span><HiOutlineDocumentMagnifyingGlass className="icon-navy-blue" onClick={() => this.handleOpenOrderModal(row)}/></span></Tooltip> 
                      {row?.original?.item?.order_status.name !== 4 &&
                      <Link to={`/order-update/${row.original?.item?.id_select}`} target="_blank">
                        <Tooltip title="ویرایش"><span><AiOutlineEdit className='icon-gray' /></span></Tooltip>
                      </Link> 
                      }
                    </div>
                    }
                  </div>
                  ]}
                />
            </div>
        </div>
      </ThemeProvider>
      </HelmetProvider>
    )
  }
}
const mapStateToProps = (dispatch:any) => {
  return {
    dispatch,
    siteList:dispatch.general.siteList,
    loadingSiteList:dispatch.general.loadingSiteList,
  }
};
export default connect(mapStateToProps)(Orders);
