import {
  FETCH_TAG_SELECTED ,
  FETCH_BRAND_SELECTED, 
  FETCH_CATEGORY_SELECTED
} from "../type";

export function tagData(details:any) {
  return {
    type: FETCH_TAG_SELECTED,
    payload: details,
  };
}

export function brandData(details:any) {
  return {
    type: FETCH_BRAND_SELECTED,
    payload: details,
  };
}

export function categoryData(details:any) {
  return {
    type: FETCH_CATEGORY_SELECTED,
    payload: details,
  };
}




