import {UpdateAdminUserController} from "../../../controllers";
import { BsFillTrashFill, BsPeople } from "react-icons/bs";
import { CustomButton, DropDownIcon, LoadingSpinner, LableIcon, Header, InputIcon } from "../../../components";
import { BsInstagram, BsWhatsapp } from "react-icons/bs";
import { FaTelegramPlane, FaUpload } from "react-icons/fa";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Helmet } from "react-helmet";
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import { Avatar } from "@mui/material";
class UpdateAdminUser extends UpdateAdminUserController {
  componentDidMount() {
      this.getUserDetails();
      this.getRolesList();
    window.scrollTo(0, 0);
  }
  render() {
  const {emailCheck, banUser, dateValue , profileImage  ,  availebleProfileImage , companyName ,localPassword, roleList ,countList,automaticSave , password, errorsList, userRole, gender, instagram,loading,firstName,lastName,mobile,email,phone,whatsapp,telegram,loadingUpdateProfile} = this.state;
    return (
      <div className="profile">
        <Helmet>
                <title>پروفایل کاربری   </title>
            </Helmet>
          <Header title="پروفایل کاربری "/>
        <div className="">
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="profile-card">
                <>
                  <div className="row">
                    <div className="col-md-2 pb-3 d-flex align-items-center col-sm-12  pe-2">
                      {profileImage ? (
                        <div className="image-upload-card">
                          <div
                          className="action"
                            onClick={() => this.deleteNewImage()}
                          >
                        <BsFillTrashFill  className="icon-red"  />
                          </div>
                          <Avatar
                            sx={{ height: "110px",
                            width: "110px",
                            display: "inline-block" }}
                            alt={"profile"}
                            src={profileImage} />
                          {/* <img alt="" src={profileImage} className="img" /> */}
                        </div>
                      ) : availebleProfileImage ? (
                        <div className="image-upload-card">
                          <div className="action">
                            <input
                            name=""
                            type="file" id="file"
                            hidden
                            accept="image/*"
                            onChange={(event) =>
                              this.handleProfileImageUpload(event)
                            }
                          />
                            <label  htmlFor="file" className="upload-lable mx-1">
                          <FaUpload fill="icon-green"/>
                          </label>
                        <BsFillTrashFill fill="icon-red" onClick={() => this.handleDeleteProfileImage()}/>
                          </div>
                          <Avatar
                                sx={{ height: "110px",
                                width: "110px", margin:"27px 0px",
                                display: "inline-block" }}
                                alt={"profile"}
                                src={availebleProfileImage} />
                          {/* <img alt="" src={availebleProfileImage} className="img" /> */}
                        </div>
                      ) :  <div className="upload-container">
                      <div className="upload-input">
                        <input
                            name=""
                            type="file"
                            id="file"
                            hidden
                            accept="image/*"
                            onChange={(event) =>
                              this.handleProfileImageUpload(event)
                            }
                          />
                        <label htmlFor="file" className="upload-lable">                    
                          <FaUpload className="icon-green"/>
                          <span className="mx-2">عکس پروفایل</span>
                        </label>
                      </div>
                      <span className="input-icon-error">
                        {this.state?.errorsList?.picture}
                      </span>
                    </div>}
                    </div>
                    <div className="col-lg-5 d-flex align-items-center col-md-12">
                      <InputIcon
                        value={firstName}
                        title={"نام "}
                        inBox={"نام  "}
                        type={"text"}
                        name="firstName"

                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "firstName")[0]?.message}
                        onChange={(event) =>
                          this.setState({
                            firstName: event.currentTarget.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-lg-5 d-flex align-items-center col-md-12 pe-2">
                      <InputIcon
                        value={lastName}
                        title={"نام خانوادگی  "}
                        inBox={"نام خانوادگی  "}
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "lastName")[0]?.message}
                        type={"text"}
                        name="lastName"
                        onChange={(event) =>
                          this.setState({
                            lastName: event.currentTarget.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4 col-sm-12 pe-3">
                      <InputIcon
                        value={mobile}
                        title={"شماره همراه "}
                        inBox={"شماره همراه"}
                        type={"text"}
                        disabled={true}
                        onChange={(event) =>
                          this.setState({
                            mobile: event.currentTarget.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4 col-sm-12 pe-3">
                      <InputIcon
                        value={email}
                        title={"ایمیل"}
                        inBox={"ایمیل"}
                        type={"text"}
                        name="email"
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "email")[0]?.message}
                        onChange={(event) =>
                          this.setState({ email: event.currentTarget.value })
                        }
                      />
                    </div>
                    <div className="col-md-4 col-sm-12 pe-3">
                    <DatePicker 
                      className="rmdp-prime"
                      inputClass="date-picker"
                      editable={false}
                      value={dateValue}
                      maxDate={new Date()}
                      onChange={(date:any) => {
                        this.handleChange(date);
                      }}
                      placeholder=" تاریخ تولد "
                      calendar={persian}
                      locale={persian_fa}
                    />
                    </div>                 
                    <div className="col-md-4 col-sm-12 pe-3">
                      <InputIcon
                        value={localPassword}
                        title={"رمز محلی "}
                        inBox={"رمز محلی "}
                        type={"number"}
                        name="localPassword"
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "localPassword")[0]?.message}
                        onChange={(event) =>
                          this.setState({ localPassword: event.currentTarget.value })
                        }
                      />
                    </div>                                    
                    <div className="col-md-4 col-sm-12 pe-3">
                      <InputIcon
                        value={password}
                        title={"رمز عبور "}
                        inBox={"رمز عبور  "}
                        type={"password"}
                        name="password"
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "password")[0]?.message}
                        onChange={(event) =>
                          this.setState({
                            password: event.currentTarget.value,
                          })
                        }
                      />
                    </div>                   
                    <div className="col-md-4 col-sm-12 pe-3">
                    <DropDownIcon
                      optionList={countList}
                      value={automaticSave}
                      title="ذخیره اتوماتیک پست"
                      inBox="ذخیره اتوماتیک پست"
                      type={"text"}
                      name={"automaticSave"}
                      myStyle="w-100"
                      errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "automaticSave")[0]?.message}
                      onChange={(event) =>
                        this.setState({
                          automaticSave: event.currentTarget.value,
                        })
                      }
                     />
                    </div>

                    <div className="col-md-4 col-sm-12 pe-3">
                       <DropDownIcon
                        optionList={roleList}
                        value={userRole}
                        title={"نقش کاربر "}
                        inBox={" نقش کاربر"}
                        type={"text"}
                        myStyle="w-100"
                        name={"userRole"}
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "userRole")[0]?.message}
                        onChange={(event) =>
                          this.setState({
                            userRole: event.currentTarget.value,
                          })
                        }
                />
                    </div>  
                    <div className="col-md-2 d-flex justify-content-center col-sm-12 pe-3">
                    <FormControlLabel sx={{direction:"ltr"}} control={<Switch
                        checked={gender}
                        onChange={(event:any)=> this.setState({ gender: event.target.checked }) }
                        inputProps={{ 'aria-label': 'controlled' }}
                     />} 
                     label="زن" />
                    </div>  
                    <div className="col-md-2 d-flex justify-content-start col-sm-12 pe-3">
                    <FormControlLabel sx={{direction:"ltr"}} control={<Switch
                        checked={emailCheck}
                        onChange={this.handleEmailChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                     />} 
                     label="احراز ایمیل" />
                    </div>
                    <div className="col-md-3 d-flex justify-content-center col-sm-12 pe-3">
                    <FormControlLabel sx={{direction:"ltr"}} control={<Switch
                        checked={banUser}
                        onChange={this.handleBanUserChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                     />} 
                     label="مسدودکردن کاربر" />
                    </div>   
                    </div>                    

                  <LableIcon title="سایر اطلاعات" icon={<BsPeople />}/>
                  <hr className="profile-hr" />
                  <div className="row">
                    <div className="col-md-4 col-sm-12 pe-3">
                      <InputIcon
                        value={phone}
                        title={"تلفن "}
                        inBox={"تلفن "}
                        type={"text"}
                        name="phone"
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "phone")[0]?.message}
                        onChange={(event) =>
                          this.setState({ phone: event.currentTarget.value })
                        }
                      />
                    </div>
                    <div className="col-md-4 col-sm-12 pe-3">
                      <InputIcon
                        value={companyName}
                        title={"نام شرکت "}
                        name="companyName"
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "companyName")[0]?.message}
                        inBox={"نام شرکت "}
                        type={"text"}
                        onChange={(event) =>
                          this.setState({
                            companyName: event.currentTarget.value,
                          })
                        }
                      />
                    </div>                      
                    <div className="col-md-4 col-sm-12 pe-3">
                      <InputIcon
                        value={telegram}
                        title={"تلگرام "}
                        name="telegram"
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "telegram")[0]?.message}
                        inBox={<FaTelegramPlane/>}
                        type={"text"}
                        onChange={(event) =>
                          this.setState({
                            telegram: event.currentTarget.value,
                          })
                        }
                      />
                    </div>  
                    <div className="col-md-6 col-sm-12 pe-3">
                      <InputIcon
                        value={whatsapp}
                        title={"واتس اپ "}
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "whatsapp")[0]?.message}
                        name="whatsapp"
                        inBox={<BsWhatsapp/>}
                        type={"text"}
                        onChange={(event) =>
                          this.setState({
                            whatsapp: event.currentTarget.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 pe-3">
                      <InputIcon
                        value={instagram}
                        title={"اینستاگرام   "}
                        inBox={<BsInstagram/>}
                        type={"text"}
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "instagram")[0]?.message}
                        name="instagram"
                        onChange={(event) =>
                          this.setState({
                            instagram: event.currentTarget.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </>
                <div className="btn-container">
                  <CustomButton
                    myStyle="submit-btn"
                    loading={loadingUpdateProfile}
                    onClick={() => this.updatetUser()}
                    title="بروز رسانی اطلاعات"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default UpdateAdminUser;
