import { Component } from 'react';
import { AppConstants } from '../../../core/constants';
import { call_api, parseJSON } from '../../../core/service';
import { toast } from 'react-toastify';
interface IProps {
  dispatch?:any,
  siteList?:any,
  loadingSiteList?:any,
}
interface IState {
  toggleModal: boolean,
  db: any
  productLoading: boolean,
  specialSaleId: Array<any>,
  specialSaleProductList: any
  specialSaleSelected: any;
  specialSaleIds: Array<number>;
  specialSaleLoading: boolean
  specialSaleList: Array<any>,

  bestList: Array<any>,
  bestId: Array<any>,
  bestProductList: any
  bestProductLoading: boolean,
  bestSelected: any;
  bestIds: Array<number>;
  bestLoading: boolean

  offerList: Array<any>,
  offerId: Array<any>,
  offerProductList: any
  offerProductLoading: boolean,
  offerSelected: any;
  offerIds: Array<number>;
  offerLoading: boolean

  selectionList: Array<any>,
  selectionId: Array<any>,
  selectionProductList: any
  selectionProductLoading: boolean,
  selectionSelected: any;
  selectionIds: Array<number>;
  selectionLoading: boolean

  toolsList: Array<any>,
  toolsId: Array<any>,
  toolsProductList: any
  toolsProductLoading: boolean,
  toolsSelected: any;
  toolsIds: Array<number>;
  toolsLoading: boolean;
  emptyDataSpecialText:boolean,
  emptyDataBestSellText:boolean,
  emptyDataOfferText:boolean,
  emptyDataSelectedText:boolean,
  emptyDataToolsText:boolean,
}

 class VitrinController extends Component<IProps, IState> {
  state: IState = {
    db: null,
    toggleModal: false,
    productLoading: false,
    emptyDataSpecialText:false,
    emptyDataBestSellText:false,
    emptyDataOfferText:false,
    emptyDataSelectedText:false,
    emptyDataToolsText:false,


    specialSaleId: [],
    specialSaleList: [],
    specialSaleProductList: [],
    specialSaleSelected: [],
    specialSaleIds: [],
    specialSaleLoading: false,
    
    
    bestId: [],
    bestList: [],
    bestProductList: [],
    bestSelected: [],
    bestIds: [],
    bestLoading: false,
    bestProductLoading: false,


    offerList: [],
    offerId: [],
    offerProductList: [],
    offerSelected: [],
    offerIds: [],
    offerLoading: false,
    offerProductLoading: false,
    

    selectionList: [],
    selectionId: [],
    selectionProductList: [],
    selectionSelected: [],
    selectionIds: [],
    selectionLoading: false,
    selectionProductLoading: false,
    
    toolsList: [],
    toolsId: [],
    toolsProductList: [],
    toolsSelected: [],
    toolsIds: [],
    toolsLoading: false,
    toolsProductLoading: false,
  }
  // special sale 
  async getSaleProductList(search?:string,item?:string) {
    if(item === "special"){
      this.setState({
         productLoading: true
      })
    }
    if(item === "best"){
      this.setState({
         bestProductLoading: true
      })
    }
    if(item === "offer"){
      this.setState({
        offerProductLoading: true
      })
    }
    if(item === "selection"){
      this.setState({
       selectionProductLoading: true
      })
    }
    if(item === "tools"){
      this.setState({
        toolsProductLoading: true
      })
    }
    const { db } = this.state;
    let parameters = {
      db_name: db?.value?.name,
      search: search ? search: null,
    }
    let url = new URL(`${AppConstants.base_url_api}seller/product/search`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}seller/product/search${url.search}&has_tumbnail=1` : `${AppConstants.base_url_api}seller/product/search&has_tumbnail=1`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(async ([status, j]) => {
        if (status === 200) {
          if(j.data){
            if(item === "special"){
              this.setState({
                specialSaleProductList: j.data , productLoading: false
              })
            }

            if(item === "best"){
              this.setState({
                bestProductList: j.data , bestProductLoading: false
              })
            }


            if(item === "offer"){
              this.setState({
                offerProductList: j.data , offerProductLoading: false
              })
            }

            if(item === "selection"){
              this.setState({
                selectionProductList: j.data , selectionProductLoading: false
              })
            }

            if(item === "tools"){
              this.setState({
                toolsProductList: j.data , toolsProductLoading: false
              })
            }
          } 
        } else if (status === 401) {
          this.setState({ productLoading: false , bestProductLoading: false , offerProductLoading: false , selectionProductLoading: false , toolsProductLoading: false})
          localStorage.clear();
        } else {
          this.setState({ productLoading: false , bestProductLoading: false , offerProductLoading: false , selectionProductLoading: false , toolsProductLoading: false})
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  handleSelectSpecialSale = (choice: any) => {
    const {specialSaleList} = this.state;
    var found = false;
    for( var i = 0; i < specialSaleList.length ; i++){
      if(specialSaleList[i]?.id === choice?.id){
          found = true;
          break;
      }
    }
    if(!found){
      this.setState({ specialSaleId: choice })
        const selectedList = [...specialSaleList];
        selectedList.push(choice);
        this.setState({ specialSaleList: selectedList });
    }
    this.setState({emptyDataSpecialText:false})
  }

  searchTimeout: string | number | NodeJS.Timeout | undefined;
  handleSearch = (choice: any,item:string) => { 
    clearTimeout(this.searchTimeout);
    if(choice.length > 2)
    this.searchTimeout = setTimeout(() => {
      this.getSaleProductList(choice,item)
    }, 1000);
  }
  async handleDeleteSpecialSaleProduct(item: any, index: number) {
    const { specialSaleIds, specialSaleList } = this.state;
    if (specialSaleIds.includes(item?.id)) {

      call_api({
        address_api: `${AppConstants.base_url_api}seller/vitrin/${item?.id}`,
        method_api: "DELETE",
        body: JSON.stringify({
          db_name: item.db_name,
          field: "vitrin",
          item: "special"
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            this.setState({ toggleModal: false })
            toast.success("با موفقیت حذف شد ");

            const newSelectedList = [...specialSaleList];
            newSelectedList.splice(index, 1);
            this.setState({ specialSaleList: newSelectedList });

          } else if (status === 401) {
            localStorage.clear();

          } else {
            toast.error(j?.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      const newSelectedList = [...specialSaleList];
      newSelectedList.splice(index, 1);
      this.setState({ specialSaleList: newSelectedList , toggleModal: false });
    }
  };
  async getBestSellingList() {
    const { db } = this.state;
    if (db) {
      this.setState({ specialSaleLoading: true })
      let parameters = {
        db_name: db?.value?.name,
        limit: 10,
        field: "vitrin",
        item: "special"
      }

      let url = new URL(`${AppConstants.base_url_api}seller/vitrin`)
      for (const [key, value] of Object.entries(parameters)) {
        if (value) {
          url.searchParams.append(key, value)
        }
      }
      call_api({
        address_api: url.search ? `${AppConstants.base_url_api}seller/vitrin${url.search}` : `${AppConstants.base_url_api}seller/vitrin`,
        method_api: "GET"
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            if (j.data) {
              this.setState({
                specialSaleList: j.data, specialSaleLoading: false, emptyDataSpecialText:false
              })
            } else {
              this.setState({
                specialSaleList: [], specialSaleLoading: false, emptyDataSpecialText:true
              })
            }
           
            const specialSaleIds = [];
            for (var i = 0; i < j.data.length; i++) {
              specialSaleIds.push(j.data[i]?.id)
            }
            this.setState({ specialSaleIds: specialSaleIds })
          } else if (status === 401) {
            this.setState({ specialSaleLoading: false })
            localStorage.clear();
          } else {
            this.setState({ specialSaleLoading: false })
            toast.error(j?.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    else {
      toast.error("لطفا یک سایت را انتخاب کنید")
    }
  };
  async handleSubmit() {
    const { db, specialSaleList,bestList,toolsList,offerList , selectionList} = this.state;

    if (db) {
      const saleSelected = [];
      for (var a = 0; a < specialSaleList.length; a++) {
        saleSelected.push(specialSaleList[a]?.id)
      }
      const bestSelected = [];
      for (var b = 0; b < bestList.length; b++) {
        bestSelected.push(bestList[b]?.id)
      }
      const offerSelected = [];
      for (var c = 0; c < offerList.length; c++) {
        offerSelected.push(offerList[c]?.id)
      }
      const selectionSelected = [];
      for (var d = 0; d < selectionList.length; d++) {
        selectionSelected.push(selectionList[d]?.id)
      }
      const toolsSelected = [];
      for (var e = 0; e < toolsList.length; e++) {
        toolsSelected.push(toolsList[e]?.id)
      }
      this.setState({ specialSaleLoading: true })
      call_api({
        address_api: `${AppConstants.base_url_api}seller/vitrin`,
        method_api: "POST",
        body: JSON.stringify({
          db_name: db?.value?.name,
          field: "vitrin", 
          vitrin:JSON.stringify({special:saleSelected,best:bestSelected,offer:offerSelected,selection:selectionSelected,tools:toolsSelected})
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            this.getBestSellingList();
            this.getBestList()
            this.getOfferList()
            this.getSelectionList()
            this.getToolsList()
            this.setState({ specialSaleLoading: false, toggleModal: false, toolsId:[], selectionId:[], offerId:[], bestId:[],
              specialSaleId:[]  })
            toast.success("با موفقیت انجام شد ");
          } else if (status === 401) {
            localStorage.clear();
            this.setState({ specialSaleLoading: false })

          } else {
            toast.error(j?.message);
            this.setState({ specialSaleLoading: false })
          }
        })
        .catch((error) => {
          console.error(error);
          this.setState({ specialSaleLoading: false })
        });
    } else {
      toast.error("لطفا یک سایت را انتخاب کنید")
    }
  };
 

  //best
  async getBestProductList(search?:string) {
    this.setState({ bestProductLoading: true })
    const { db } = this.state;
    let parameters = {
      db_name: db?.value?.name,
      search: search ? search: null,
    }
    let url = new URL(`${AppConstants.base_url_api}seller/product/search`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}seller/product/search${url.search}` : `${AppConstants.base_url_api}seller/product/search`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(async ([status, j]) => {
        if (status === 200) {

          if(j.data){
            this.setState({
              bestProductList: j.data,
            })
          }
          
          this.setState({
            bestProductLoading: false
          })
        } else if (status === 401) {
          this.setState({ bestProductLoading: false })
          localStorage.clear();
        } else {
          this.setState({ bestProductLoading: false })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  handleSelectbest = (choice: any) => {
    const {bestList} = this.state;
    var found = false;
    for( var i = 0; i < bestList.length ; i++){
      if(bestList[i]?.id === choice?.id){
          found = true;
          break;
      }
    }
    if(!found){
      this.setState({ bestId: choice })
      const selectedList = [...bestList];
      selectedList.push(choice);
      this.setState({ bestList: selectedList });
    }
    this.setState({emptyDataBestSellText:false})
  }
  async handleDeleteBestProduct(item: any, index: number) {
    const { bestIds, bestList } = this.state;
    if (bestIds.includes(item?.id)) {

      call_api({
        address_api: `${AppConstants.base_url_api}seller/vitrin/${item?.id}`,
        method_api: "DELETE",
        body: JSON.stringify({
          db_name: item.db_name,
          field: "vitrin",
          item: "best"
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            this.setState({ toggleModal: false })
            toast.success("با موفقیت حذف شد ");

            const newSelectedList = [...bestList];
            newSelectedList.splice(index, 1);
            this.setState({ bestList: newSelectedList });

          } else if (status === 401) {
            localStorage.clear();

          } else {
            toast.error(j?.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      const newSelectedList = [...bestList];
      newSelectedList.splice(index, 1);
      this.setState({ bestList: newSelectedList , toggleModal: false });
    }
  };
  async getBestList() {
    const { db } = this.state;
    if (db) {
      this.setState({ bestLoading: true })
      let parameters = {
        db_name: db?.value?.name,
        limit: 10,
        field: "vitrin",
        item: "best"
      }

      let url = new URL(`${AppConstants.base_url_api}seller/vitrin`)
      for (const [key, value] of Object.entries(parameters)) {
        if (value) {
          url.searchParams.append(key, value)
        }
      }
      call_api({
        address_api: url.search ? `${AppConstants.base_url_api}seller/vitrin${url.search}` : `${AppConstants.base_url_api}seller/vitrin`,
        method_api: "GET"
      })
        .then(parseJSON)
        .then(([status, j]) => {

          if (status === 200) {
            if (j.data) {
              this.setState({
                bestList: j.data, emptyDataBestSellText:false
              })
            } else {
              this.setState({
                bestList: [], emptyDataBestSellText:true
              })
            }
            this.setState({
               bestLoading:false
            })
            const specialSaleIds = [];
            for (var i = 0; i < j.data.length; i++) {
              specialSaleIds.push(j.data[i]?.id)
            }
            this.setState({ bestIds: specialSaleIds ,  bestLoading: false})
          } else if (status === 401) {
            this.setState({ bestLoading: false })
            localStorage.clear();
          } else {
            this.setState({ bestLoading: false })
            toast.error(j?.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    else {
      toast.error("لطفا یک سایت را انتخاب کنید")
    }
  };



  //offer
  async getOfferProductList(search?:string) {
    this.setState({ offerProductLoading: true })
    const { db } = this.state;
    let parameters = {
      db_name: db?.value?.name,
      search: search ? search: null,
    }
    let url = new URL(`${AppConstants.base_url_api}seller/product/search`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}seller/product/search${url.search}` : `${AppConstants.base_url_api}seller/product/search`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(async ([status, j]) => {
        if (status === 200) {

          if(j.data){
            this.setState({
             
              offerProductList: j.data, emptyDataBestSellText:false,  offerProductLoading: false
              
            })
          }
          else {
            this.setState({
             
              offerProductList: [], emptyDataBestSellText:true, offerProductLoading: false
              
            })
          }
      
        } else if (status === 401) {
          this.setState({ offerProductLoading: false })
          localStorage.clear();
        } else {
          this.setState({ offerProductLoading: false })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  handleSelectOffer = (choice: any) => {
    const {offerList} = this.state;
    var found = false;
    for( var i = 0; i < offerList.length ; i++){
      if(offerList[i]?.id === choice?.id){
          found = true;
          break;
      }
    }
    if(!found){
      this.setState({ offerId: choice })
    const selectedList = [...offerList];
    selectedList.push(choice);
    this.setState({ offerList: selectedList });
    }
    this.setState({emptyDataOfferText:false})

  }

  async handleDeleteOfferProduct(item: any, index: number) {
    const { offerIds, offerList } = this.state;
    if (offerIds.includes(item?.id)) {

      call_api({
        address_api: `${AppConstants.base_url_api}seller/vitrin/${item?.id}`,
        method_api: "DELETE",
        body: JSON.stringify({
          db_name: item.db_name,
          field: "vitrin",
          item: "offer"
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            this.setState({ toggleModal: false })
            toast.success("با موفقیت حذف شد ");

            const newSelectedList = [...offerList];
            newSelectedList.splice(index, 1);
            this.setState({ offerList: newSelectedList });

          } else if (status === 401) {
            localStorage.clear();

          } else {
            toast.error(j?.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      const newSelectedList = [...offerList];
      newSelectedList.splice(index, 1);
      this.setState({ offerList: newSelectedList , toggleModal: false });
    }
  };
  async getOfferList() {
    const { db } = this.state;
    if (db) {
      this.setState({ offerLoading: true })
      let parameters = {
        db_name: db?.value?.name,
        limit: 10,
        field: "vitrin",
        item: "offer"
      }

      let url = new URL(`${AppConstants.base_url_api}seller/vitrin`)
      for (const [key, value] of Object.entries(parameters)) {
        if (value) {
          url.searchParams.append(key, value)
        }
      }
      call_api({
        address_api: url.search ? `${AppConstants.base_url_api}seller/vitrin${url.search}` : `${AppConstants.base_url_api}seller/vitrin`,
        method_api: "GET"
      })
        .then(parseJSON)
        .then(([status, j]) => {

          if (status === 200) {
            if (j.data) {
              this.setState({
                offerList: j.data, offerLoading: false, emptyDataOfferText:false
              })
            } else {
              this.setState({
                offerList: [], offerLoading: false, emptyDataOfferText:true
              })
            }
            const specialSaleIds = [];
            for (var i = 0; i < j.data.length; i++) {
              specialSaleIds.push(j.data[i]?.id)
            }
            this.setState({ offerIds: specialSaleIds ,  offerLoading: false })
          } else if (status === 401) {
            this.setState({ offerLoading: false })
            localStorage.clear();
          } else {
            this.setState({ offerLoading: false })
            toast.error(j?.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    else {
      toast.error("لطفا یک سایت را انتخاب کنید")
    }
  };


  //selection
  async getSelectionProductList(search?:string) {
    this.setState({ selectionProductLoading: true })
    const { db } = this.state;
    let parameters = {
      db_name: db?.value?.name,
      search: search ? search: null,
    }
    let url = new URL(`${AppConstants.base_url_api}seller/product/search`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}seller/product/search${url.search}` : `${AppConstants.base_url_api}seller/product/search`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(async ([status, j]) => {
        if (status === 200) {

          if(j.data){
            this.setState({
             
              selectionProductList: j.data, selectionProductLoading: false
             
            })
          }
          else {
            this.setState({          
              selectionProductList: [], selectionProductLoading: false
            })
          }
          
        } else if (status === 401) {
          this.setState({ selectionProductLoading: false })
          localStorage.clear();
        } else {
          this.setState({ selectionProductLoading: false })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  handleSelectSelection = (choice: any) => {
    const {selectionList} = this.state;
    var found = false;
    for( var i = 0; i < selectionList.length ; i++){
      if(selectionList[i]?.id === choice?.id){
          found = true;
          break;
      }
    }
    if(!found){
      this.setState({ selectionId: choice })
    const selectedList = [...selectionList];
    selectedList.push(choice);
    this.setState({ selectionList: selectedList });
    }
    this.setState({emptyDataSelectedText:false})
  }
  async handleDeleteSelectionProduct(item: any, index: number) {
    const { selectionId, selectionList } = this.state;
    if (selectionId.includes(item?.id)) {

      call_api({
        address_api: `${AppConstants.base_url_api}seller/vitrin/${item?.id}`,
        method_api: "DELETE",
        body: JSON.stringify({
          db_name: item.db_name,
          field: "vitrin",
          item: "selection"
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            this.setState({ toggleModal: false })
            toast.success("با موفقیت حذف شد ");

            const newSelectedList = [...selectionList];
            newSelectedList.splice(index, 1);
            this.setState({ selectionList: newSelectedList });

          } else if (status === 401) {
            localStorage.clear();

          } else {
            toast.error(j?.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      const newSelectedList = [...selectionList];
      newSelectedList.splice(index, 1);
      this.setState({ selectionList: newSelectedList , toggleModal: false });
    }
  };
  async getSelectionList() {
    const { db } = this.state;
    if (db) {
      this.setState({ selectionLoading: true })
      let parameters = {
        db_name: db?.value?.name,
        limit: 10,
        field: "vitrin",
        item: "selection"
      }

      let url = new URL(`${AppConstants.base_url_api}seller/vitrin`)
      for (const [key, value] of Object.entries(parameters)) {
        if (value) {
          url.searchParams.append(key, value)
        }
      }
      call_api({
        address_api: url.search ? `${AppConstants.base_url_api}seller/vitrin${url.search}` : `${AppConstants.base_url_api}seller/vitrin`,
        method_api: "GET"
      })
        .then(parseJSON)
        .then(([status, j]) => {

          if (status === 200) {
            if (j.data) {
              this.setState({
                selectionList: j.data, selectionLoading: false, emptyDataSelectedText:false
              })
            } else {
              this.setState({
                selectionList: [], selectionLoading: false, emptyDataSelectedText:true
              })
            }
            this.setState({
            })
            const specialSaleIds = [];
            for (var i = 0; i < j.data.length; i++) {
              specialSaleIds.push(j.data[i]?.id)
            }
            this.setState({ selectionIds: specialSaleIds })
            this.setState({
              selectionLoading: false
            })
          } else if (status === 401) {
            this.setState({ selectionLoading: false })
            localStorage.clear();
          } else {
            this.setState({ selectionLoading: false })
            toast.error(j?.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    else {
      toast.error("لطفا یک سایت را انتخاب کنید")
    }
  };




  //tools
  async getToolsProductList(search?:string) {
    this.setState({ toolsProductLoading: true })
    const { db } = this.state;
    let parameters = {
      db_name: db?.value?.name,
      search: search ? search: null,
    }
    let url = new URL(`${AppConstants.base_url_api}seller/product/search`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}seller/product/search${url.search}` : `${AppConstants.base_url_api}seller/product/search`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(async ([status, j]) => {
        if (status === 200) {

          if(j.data){
            this.setState({
              
              toolsProductList: j.data,
             
            })
          }
          
          this.setState({
            toolsProductLoading: false
          })
        } else if (status === 401) {
          this.setState({ toolsProductLoading: false })
          localStorage.clear();
        } else {
          this.setState({ toolsProductLoading: false })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  handleSelectTools = (choice: any) => {
    const {toolsList} = this.state;
    var found = false;
    for( var i = 0; i < toolsList.length ; i++){
      if(toolsList[i]?.id === choice?.id){
          found = true;
          break;
      }
    }
    if(!found){
      this.setState({ toolsId: choice })
    const selectedList = [...toolsList];
    selectedList.push(choice);
    this.setState({ toolsList: selectedList });
    }
    this.setState({emptyDataToolsText:false})
  }
  async handleDeleteToolsProduct(item: any, index: number) {
    const { toolsId, toolsList } = this.state;
    if (toolsId.includes(item?.id)) {

      call_api({
        address_api: `${AppConstants.base_url_api}seller/vitrin/${item?.id}`,
        method_api: "DELETE",
        body: JSON.stringify({
          db_name: item.db_name,
          field: "vitrin",
          item: "tools"
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            this.setState({ toggleModal: false })
            toast.success("با موفقیت حذف شد ");

            const newSelectedList = [...toolsList];
            newSelectedList.splice(index, 1);
            this.setState({ toolsList: newSelectedList });

          } else if (status === 401) {
            localStorage.clear();

          } else {
            toast.error(j?.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      const newSelectedList = [...toolsList];
      newSelectedList.splice(index, 1);
      this.setState({ toolsList: newSelectedList , toggleModal: false  });
    }
  };
  async getToolsList() {
    const { db } = this.state;
    if (db) {
      this.setState({ toolsLoading: true })
      let parameters = {
        db_name: db?.value?.name,
        limit: 10,
        field: "vitrin",
        item: "tools"
      }

      let url = new URL(`${AppConstants.base_url_api}seller/vitrin`)
      for (const [key, value] of Object.entries(parameters)) {
        if (value) {
          url.searchParams.append(key, value)
        }
      }
      call_api({
        address_api: url.search ? `${AppConstants.base_url_api}seller/vitrin${url.search}` : `${AppConstants.base_url_api}seller/vitrin`,
        method_api: "GET"
      })
        .then(parseJSON)
        .then(([status, j]) => {

          if (status === 200) {
            if (j.data) {
              this.setState({
                toolsList: j.data, toolsLoading: false, emptyDataToolsText: false
              })
            } else {
              this.setState({
                toolsList: [], toolsLoading: false, emptyDataToolsText: true
              })
            }
            const specialSaleIds = [];
            for (var i = 0; i < j.data.length; i++) {
              specialSaleIds.push(j.data[i]?.id)
            }
            this.setState({ toolsIds: specialSaleIds ,  toolsLoading: false })
          } else if (status === 401) {
            this.setState({ toolsLoading: false })
            localStorage.clear();
          } else {
            this.setState({ toolsLoading: false })
            toast.error(j?.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    else {
      toast.error("لطفا یک سایت را انتخاب کنید")
    }
  };
  //db


  driverTimeout: string | number | NodeJS.Timeout | undefined;
  driverSelected = (choice: any) => {
    this.setState({ db: choice })
    clearTimeout(this.driverTimeout);
    this.driverTimeout = setTimeout(() => {
      this.getBestSellingList()
      this.getBestList()
      this.getOfferList()
      this.getSelectionList()
      this.getToolsList()

      this.setState({ specialSaleIds: [],specialSaleSelected:[],specialSaleProductList:[], specialSaleId:[]})
      this.setState({ bestIds: [],bestSelected:[],bestProductList:[], bestId:[]})
      this.setState({ offerIds: [],offerSelected:[],offerProductList:[], offerId:[]})
      this.setState({ selectionIds: [],selectionSelected:[],selectionProductList:[], selectionId:[]})
      this.setState({ toolsIds: [],toolsSelected:[],toolsProductList:[], toolsId:[]})


      // this.getSaleProductList()

      // this.getBestProductList()
  
      // this.getOfferProductList()
  
      // this.getSelectionProductList()
  
      // this.getToolsProductList()

    }, 1000);
  }

}


export default VitrinController;