import { Component } from 'react';
import { call_api, parseJSON } from "../../../core/service";
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { AppConstants } from '../../../core/constants';
import { totalOrderCount, totalOrderDiscount, totoalOrderFinalPrice, totalOrdersPrice } from "../../../core/utils";

interface IProps {
  dispatch?:any,
  siteList?:any,
  loadingSiteList?:any,
}

interface IState {
  loading?: boolean,
  userLoading?: boolean,
  search: string,
  rows: Array<any>,
  userSelected: any,
  productListData: any,
  profileListData: any,
  isCreating: boolean,
  driversId: any,
  openNewUserModal: boolean,
  mobile: string,
  firstName: string,
  lastName: string,
  phone: string,
  whatsapp: string,
  errorsList?: any,
  profileImage?: any,
  profileImageUpload?: any,
  loadingUpdateProfile: boolean,
  currentItem: any,
  productModal:boolean,
  total:number
  rowCount:any,
  rowCountList:any,
  page: number
}
 class CreatePreFactorController extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      userLoading: false,
        search: "",
        rows: [],
        userSelected: null,
        productListData: [],
        profileListData: [],
        isCreating: false,
        driversId: null,
        openNewUserModal: false,
        mobile: "",
        firstName: "",
        lastName: "",
        phone: "",
        whatsapp: "",
        errorsList: [],
        profileImage: "",
        profileImageUpload: "",
        loadingUpdateProfile: false,
        currentItem: null, 
    productModal:false,
    total:0,
    rowCount:100,
    rowCountList:[10, 25 , 50 , 100],
    page: 1,
  }
  }
 
  handleDeleteProfileImage = () => {
    this.setState({
      profileImage: "", profileImageUpload: ""
    })

  }
  handleCloseNewUserModal = () => {
    this.setState({ openNewUserModal: false, errorsList:[] })
  }
  async getProductList() {
    const {search,driversId, rowCount, page} =this.state;
    this.setState({ loading: true, productListData: [] })
    let parameters = {
      search: search ? search : null,
      page: page ? page.toString() : null,
      db_name: driversId && Object.keys(driversId).length > 0 ? driversId?.value?.name : null,
      paginate: rowCount,
      
    }
    let url = new URL(`${AppConstants.base_url_api}seller/products`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    this.setState({ loading: true })
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}seller/products${url.search}` : `${AppConstants.base_url_api}seller/products`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          if(j.data){

            this.setState({
              productListData: j.data?.data, loading: false , total: j.data?.total
            })
          }
          this.setState({ loading: false })
        } else if (status === 401) {
          this.setState({ loading: false })
          localStorage.clear();
        } else {
          this.setState({ loading: false })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  async createNewUser() {
      const mobileRegExp = /^09\d{9}$/;
      const phoneRegExp = /^\d{5,12}$/;
      const justPersian = /^[\u0600-\u06FF\s]+$/gi;
      const { mobile, firstName,driversId , lastName, phone, whatsapp, } = this.state;
      const updateProfileSchema = yup.object({
      driversId: yup.object().required(" سایت الزامی می باشد "),
      firstName: yup.string().required(" نام الزامی می باشد ").max(32, 'نام بیشتر از 32 رقم نمی باشد').min(3, ' نام کمتر از 3 رقم نمی باشد').matches(justPersian,  ' لطفا از زبان فارسی استفاده کنید  '),
      lastName : yup.string().required(" نام خانوادگی الزامی می باشد ").max(32, 'نام خانوادگی بیشتر از 32 رقم نمی باشد').min(3, '  نام خانوادگی کمتر از 3 رقم نمی باشد').matches(justPersian,  ' لطفا از زبان فارسی استفاده کنید  '),
      mobile: yup.string().required(" شماره همراه الزامی می باشد  ").matches(mobileRegExp, 'فرمت وارد شده صحیح نمی باشد'),
      phone: yup.string().required("تلفن الزامی است").matches(phoneRegExp, 'فرمت وارد شده صحیح نمی باشد'),
      whatsapp: yup.string().notRequired().matches(mobileRegExp,  'فرمت وارد شده صحیح نمی باشد ').nullable().transform((value) => !!value ? value : null),

      });
  
      try {
  
        await updateProfileSchema.validate({ firstName, lastName,driversId, mobile, phone, whatsapp, }, { abortEarly: false });
        this.setState({ loadingUpdateProfile: true })
        const data = new FormData()
        data.append('name', firstName)
        data.append('family', lastName)
        data.append('mobile', mobile)
        phone && data.append('phone', phone)
        data.append('whatsapp', whatsapp)
        data.append('db_name', driversId.value?.name)

        // if (availebleProfileImage === "") {
        //   data.append('avatar', profileImageUpload)
        // }
        call_api({
          address_api: `${AppConstants.base_url_api}seller/users-all`,
          method_api: "POST",
          body: data,
          file: true,
        })
          .then(parseJSON)
          .then(([status, j]) => {
            this.setState({ errorsList: [] })
            if (status === 200) {
              toast.success(j?.message);
              this.getProfilesList();
              this.userSelected(j?.data)
              this.setState({openNewUserModal:false, firstName:"",lastName:"",mobile:"",phone:"",whatsapp:"", loadingUpdateProfile: false })
            } else if (status === 401) {
              this.setState({ loadingUpdateProfile: false })
              toast.error(j?.message);
              localStorage.clear();
            } else {
              console.warn(status, j);
              this.setState({ loadingUpdateProfile: false })
              toast.error(j?.message);
            }
          })
          .catch((error) => {
            console.error(error);
          });
  
      } catch (err: any) {
        this.setState({ loadingUpdateProfile: false, errorsList: err.inner  })
      }
    
  }
  async handleProfileImageUpload(event: any) {
    let news_image_file = null;
    news_image_file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(news_image_file);
    reader.onloadend = () => {
      this.setState({ profileImage: reader.result });
    }
    if (news_image_file) {
      this.setState({ profileImageUpload: news_image_file });
    }
  }
  async getProfilesList(search?:string) {
    this.setState({ userLoading: true })
    let parameters = {
      search: search ? search.toString(): null,
      db_name:this.state.driversId?.value?.name,
    }
    let url = new URL(`${AppConstants.base_url_api}seller/users-all-no-paginate`)

    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}seller/users-all-no-paginate${url.search}` : `${AppConstants.base_url_api}seller/users-all-no-paginate`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
           userLoading: false 
          })
          if(j.data){
            this.setState({
              profileListData: j.data
            })
          }
        } else if (status === 401) {
          this.setState({ userLoading: false })
          localStorage.clear();
        } else {
          this.setState({ userLoading: false })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  searchTimeout: string | number | NodeJS.Timeout | undefined;
    handleSearch = (event: any) => {
      this.setState({ search: event.currentTarget.value, page: 1 })
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.getProductList()
      }, 1000);
    };

    handleAdd = (data: any) => {
      const {driversId} = this.state;
      if(driversId === null)
      {
        toast.error("لطفا یک سایت را انتخاب کنید.")
      }
      else{
        const { rows } = this.state;
        var found = false;
        const updateConversationInfo = [...this.state.rows]
        for (var i = 0; i < rows.length; i++) {
          if (rows[i].product_id === data?.id) {
            updateConversationInfo.splice(i,1)
          this.setState({ rows: updateConversationInfo });
            // toast.error("این محصول در لیست محصولات انتخابی شما وجود دارد ")
            found = true;
            break;
          }
        }
        if (!found) {
          const obj = {
            product_id: data.id,
            product_name: data.product_name,
            count: data.count || 1,
            price: data.price_by_discount.price,
            discount: data.price_by_discount.discount,
            finalPrice: data.price_by_discount.finalPrice,
            error: ""
          }
          updateConversationInfo.push(obj);
          this.setState({ rows: updateConversationInfo });
        }
      }
    }
  tableRowRemove = (index: number) => {
    const dataRow = [...this.state.rows];
    dataRow.splice(index, 1);
    this.setState({ rows: dataRow });
  };

  onValUpdate = (i: number, event: any) => {

    const { name, value } = event.target;
    const data = [...this.state.rows];
    var newValue = value.replace(
        /,/g,
        ""
      );
      const re = /^[0-9\b]+$/;
      if (newValue === "" || re.test(newValue)) {
        data[i].error = "" 
        let countItem:number =  Number(data[i]?.count);
  
        if(name === "discount" ){
          data[i][name] = newValue;
          if(Number(newValue) < Number(data[i]["price"]))
          {
            data[i].error = ""
          }
          else{

            data[i].error += "تخفیف نمی‌تواند بزرگ‌تر یا مساوی با قیمت باشد"
          }
        } 
        else if(name === "price"){
          
            if(Number(newValue) > Number(data[i]["discount"] ))
            {
              data[i][name] = newValue;
              data[i].error = ""
            }
            else{
              data[i].error = ""  

              if(Number(data[i]?.discount) < 99)
              {              
                data[i][name] = newValue;
              }
              else
            { 
              data[i][name] = newValue;
              data[i].error += "قیمت نمی‌تواند کوچک‌تر از تخفیف باشد"
                
            }
            }
          }
        else if(name === "count"){
          if(Number(newValue) > 0){

            countItem = Number(newValue) 
            data[i][name] = newValue;

          }else{
            countItem = 1
            data[i][name] = 1
          }
        }
        if (Number(data[i]?.discount) > 99) {
            data[i]["finalPrice"] = (Number(data[i]?.price) -  Number(data[i]?.discount)) * countItem ; 
          } 
        else {
            data[i]["finalPrice"] = (Number(data[i]?.price) * countItem) - Number(Number(Number(data[i]?.price * Number(data[i]?.discount)) / 100) * countItem)  ;
            
          }
      }
    this.setState({ rows: data });

  };


  // onValUpdate = (i: number, event: any) => {
  //   const { name, value } = event.target;
  //   const data = [...this.state.rows];
  //   var newValue = value.replace(
  //       /,/g,
  //       ""
  //     );
  //     const re = /^[0-9\b]+$/;
  //     if (newValue === "" || re.test(newValue)) {
  //       if(name === "discount" ){
  //         if(Number(newValue) < Number(data[i]["price"]))
  //         {
  //           data[i][name] = newValue;
  //         }
  //       }else{
  //         data[i][name] = newValue;
  //       }
  //     }
  //   let countItem ;
  //   if(Number(data[i]["count"]) > 0){
    
    
  //     countItem = Number(data[i]["count"]) 
  //     data[i][name] = newValue;
  //   }
  //   else {
  //     countItem =1;
  //     data[i]["count"] = "1"
  //   }
  //   if (Number(data[i]?.discount) > 99) {
  //     data[i]["finalPrice"] = (Number(data[i]?.price) -  Number(data[i]?.discount)) * countItem ; 
  //   } else {
  //     data[i]["finalPrice"] = (Number(data[i]?.price) *countItem) - Number(Number(Number(data[i]?.price * Number(data[i]?.discount)) / 100) * countItem)  ;
      
  //   }
  //   this.setState({ rows: data });
  // };
  userSelected = (choice: any) => {
      this.setState({ userSelected: choice })
  }

  searchUserTimeout: string | number | NodeJS.Timeout | undefined;
  handleSearchUser = (choice: string) => { 
    clearTimeout(this.searchUserTimeout);
    if(choice.length > 2)
    this.searchUserTimeout = setTimeout(() => {
      this.getProfilesList(choice)
    }, 1000);
  }
  handleSubmitFactor = () => {
    const { userSelected, rows, driversId } = this.state;
    
     if (userSelected === null || Object.keys(userSelected).length === 0) {
      toast.error("هیچ کاربری انتخاب یا ثبت نام نکرده اید.")

    } else if (rows.length === 0) {
      toast.error("هیچ محصولی در لیست سفارش وارد نشده")
    } else if (Object.keys(driversId).length === 0) {
      toast.error("هیچ سایتی وارد نشده!")
    } else {
      this.createPrefactor()
    }
  }

  driverTimeout: string | number | NodeJS.Timeout | undefined;
  driverSelected = (choice?:any) : any=> {
    this.setState({ driversId: choice ,  productListData : [] , currentItem : null, page:1, rowCount: 100 })
    clearTimeout(this.driverTimeout);
    this.driverTimeout = setTimeout(() => {
      this.getProductList()
    }, 1000);
  }
  async createPrefactor() {
    let errorsLists = [];
    const { userSelected, rows, driversId } = this.state;
    for(var i = 0; i< rows.length; i++){
          if(rows[i].error && rows[i].error !== "")
          {
            errorsLists.push(rows[i].error)
          }
    }
    if(errorsLists.length === 0){

    if(this.state.rows.length > 0){
      this.setState({ isCreating: true })
    const prefactorValidation = yup.object({
      userSelected: yup.object().required("انتخاب کاربر الزامی می باشد "),
      driversId: yup.object().required("انتخاب سایت الزامی است."),
      
    });
    try {
    await prefactorValidation.validate({ userSelected,driversId}, { abortEarly: false });

    const productSelected = []
    for (var i = 0; i < rows.length; i++) {
      const obj = {
        product_id: rows[i].product_id,
        count: rows[i].count,
        price:rows[i].price,
        discount:rows[i].discount
      }
      productSelected.push(obj);
    }
    call_api({
      address_api: `${AppConstants.base_url_api}seller/pre-factors`,
      method_api: "POST",
      body: JSON.stringify({
        products: JSON.stringify(productSelected),
        name: userSelected?.profile?.name,
        family: userSelected?.profile?.family,
        mobile: userSelected?.mobile,
        db_name: driversId?.value?.name,
        user_id: userSelected?.id,
        totalPrice : totalOrdersPrice(rows),
        totalDiscount : totalOrderDiscount(rows),
        totalCount : totalOrderCount(rows),
        finalPrice : totoalOrderFinalPrice(rows),

      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          toast.success(j?.message);
          this.setState({ isCreating: false ,
             userSelected: {} ,
             driversId: [] ,
           search: "", 
           rows: [],
           errorsList:[]

          })
          window.location.href = "/prefactor";
        } else if (status === 401) {
          this.setState({ isCreating: false })
          toast.error(j?.error?.message);
          localStorage.clear();
        } else {
          console.warn(status, j);
          this.setState({ isCreating: false })
          toast.error(j?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }
    catch (err: any) {
      this.setState({ isCreating: false, errorsList: err.inner  })
    }
  }else{
    toast.error("حداقل یک محصول را انتخاب کنید");
  }
}
else {
  toast.error("لطفاً به مقادیر قیمت یا تخفیف دقت نمایید!")
}
  };
  openProductModal = () => {
    this.setState({ productModal: true })
  }
  handleCloseProductModal = () => {
    this.setState({ productModal: false })
  }
  paginationTimeOut: string | number | NodeJS.Timeout | undefined; 
  handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ page: value })
    clearTimeout(this.paginationTimeOut);
    this.paginationTimeOut = setTimeout(() => {
      this.getProductList()
    }, 800);
  };
  paginationSelectTimeOut: string | number | NodeJS.Timeout | undefined; 
  handlePaginationSelect=(event:any)=>{
    this.setState({
      rowCount: event.currentTarget.value,  page:1
    })
    clearTimeout(this.paginationSelectTimeOut);
    this.paginationSelectTimeOut = setTimeout(() => {
      this.getProductList()
    }, 800);
    
  }
}
export default CreatePreFactorController;