import { Component, createRef } from "react";
import { toast } from "react-toastify";
import { call_api, parseJSON } from "../../../core/service";
import { AppConstants } from "../../../core/constants";
import * as yup from 'yup';
import { fetchSiteList, userData } from "../../../Redux/services/generalService";
import { FETCH_USER_DATA } from "../../../Redux/type";

interface IProps {
  dispatch?:any,
  siteList?:any,
  loadingSiteList?:any,
  userData?:any
 }

interface IState {
  password: string;
  phone: string;
  login: boolean;
  error: boolean;
  loading: boolean;
  activeCheckBox: boolean;
  status: number;
  otpCode: string;
  errorsList?: any,
  resend: boolean;
  minutes: number,
  seconds: number,
  showPassword:boolean,
}

 class LoginController extends Component<IProps, IState> {
  myInterval: any;

  constructor(props: any) {
    super(props);
    this.myInterval = createRef()
    this.state = {
      password: "",
      phone: "",
      login: false,
      error: false,
      loading: false,
      activeCheckBox: false,
      status: 0,
      otpCode: "",
      errorsList: [],
      resend: false,
      minutes: 2,
      seconds: 0,
      showPassword:false,


    };
  }

  async handleLogin() {
    const { phone } = this.state;
    const mobileRegExp = /^09\d{9}$/
    const loginSchema = yup.object({
      phone: yup.string().required(" شماره تماس الزامی می باشد  ").matches(mobileRegExp, 'فرمت وارد شده صحیح نمی باشد'),
    });
    try {
      this.setState({ loading: true })
      await loginSchema.validate({ phone }, { abortEarly: false });
      call_api({
        address_api: `${AppConstants.base_url_api}login`,
        method_api: "POST",
        body: JSON.stringify({
          mobile: phone,
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          this.setState({ errorsList: [] })
          if (status === 200) {
            this.setState({ status: 1 })
            this.setState({ loading: false })
            if (j.data.password_is_changed === 1) {
              this.setState({ status: 1 })
            } else {
              this.setState({ status: 2 })
              this.handleTimer()

            }
            // window.localStorage.setItem('token', j.object.access)
            // window.location.href = "/#/home"
            this.setState({ loading: false })
          } else if (status === 401) {
            this.setState({ loading: false })
            toast.error(j?.error?.message);
            localStorage.clear();
          } else {
            console.warn(status, j);
            this.setState({ loading: false })
            toast.error(j?.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });

    } catch (err: any) {
      this.setState({ loading: false })
      this.setState({ errorsList: err.inner })
    }
  }
  handleChangeStatus = () => {
    this.setState({ status: 2 })
    this.handleTimer()
  }

  handleKeyPressDown = (event: { key: string; }) => {
    const {status} = this.state;
    if (event.key === 'Enter') {
      if(status === 0 )
      {
        this.handleLogin()
      }  
      else {this.handleSendPassword()}
       
  };
}
  handleOtpdKeyPressDown  = (event: { key: string; }) => {
    const {resend} = this.state;
    if (event.key === 'Enter') {
      if(resend)
      { this.handleResendCode()} 
      else this.otpLogin()
       }
  };
  async handleSendPassword() {

    const { password, phone } = this.state;
    const otpRegisterSchema = yup.object({
      password: yup.string().required(" رمز ورود الزامی می باشد  ").min(8, 'کد ورود کمتر از 8 رقم نمی باشد').max(255, 'کد ورود بیشتر از 255 رقم نمی باشد'),
    });
    try {
      this.setState({ loading: true })
      await otpRegisterSchema.validate({ password }, { abortEarly: false });
      call_api({
        address_api: `${AppConstants.base_url_api}login-password`,
        method_api: "POST",
        body: JSON.stringify({
          password: password,
          password_confirmation: password,
          mobile: phone
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          this.setState({ errorsList: [] })
          if (j?.status === 200) {
            this.setState({ loading: false })
            window.localStorage.setItem('token', j.data.token)
            window.localStorage.setItem('userId', j.data?.user?.id)
            this.fetch()
            clearTimeout(this.fetchUserDataTimeOut);
            this.fetchUserDataTimeOut = setTimeout(() => {
              window.location.href = `/`;
              toast.success(j?.message)
              }, 800);
            this.setState({ password: "" })
          } else if (j?.status === 401) {
            this.setState({ loading: false })
            toast.error(j?.message);
            localStorage.clear();
          } else {
            console.warn(status, j);
            this.setState({ loading: false })
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
          this.setState({ loading: false })

        });
    } catch (err: any) {
      this.setState({ loading: false })
      this.setState({ errorsList: err.inner })
    }
  }
  fetch = ()=>{
    call_api({
      address_api: `${AppConstants.base_url_api}seller/users/${window.localStorage.getItem('userId')}`,
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {  
          this.props.dispatch({
            type: FETCH_USER_DATA,
            payload: j?.data,});
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  fetchUserDataTimeOut: string | number | NodeJS.Timeout | undefined;     
  async otpLogin() {
    const { otpCode, phone } = this.state;
    const otpRegisterSchema = yup.object({
      otpCode: yup.string().required(" کد ورود الزامی می باشد  ").min(4, 'کد ورود کمتر از 6 رقم نمی باشد').max(6, 'کد ورود بیشتر از 6 رقم نمی باشد'),
    });
    try {
      this.setState({ loading: true })
      await otpRegisterSchema.validate({ otpCode }, { abortEarly: false });
      call_api({
        address_api: `${AppConstants.base_url_api}login/otp`,
        method_api: "POST",
        body: JSON.stringify({
          otp: otpCode,
          mobile: phone,
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (j.status === 200) {
            window.localStorage.setItem('token', j.data.token)
            window.localStorage.setItem('userId', j.data?.user?.id)
            this.setState({ errorsList: [] })
            this.fetch()
            clearTimeout(this.fetchUserDataTimeOut);
            this.fetchUserDataTimeOut = setTimeout(() => {
              window.location.href = `/`;
              toast.success(j?.message)
              }, 800);
            this.setState({ loading: false })
          } else if (j.status === 401) {
            this.setState({ loading: false, errorsList: [] })
            toast.error(j?.message);
            localStorage.clear();
          } else {
            this.setState({ loading: false, errorsList: [] })
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (err: any) {
      this.setState({ loading: false })
      this.setState({ errorsList: err.inner })
    }
  }
  handleTimer = () => {
    clearInterval( this.myInterval.current);
     this.myInterval.current = setInterval(() => {
      if (this.state.seconds > 0) {
        this.setState({ seconds: this.state.seconds - 1 })
      }
      if (this.state.seconds === 0) {
        if (this.state.minutes === 0) {
          clearInterval( this.myInterval.current)
          this.setState({ resend: true })
        } else {
          this.setState({ minutes: this.state.minutes - 1 })
          this.setState({ seconds: 59 })
        }
      }
    }, 1000)
    return () => {
      clearInterval( this.myInterval.current);
    };
  }
  handleResendCode = () => {
    this.setState({ minutes: 2 })
    this.setState({ seconds: 0 })
    this.setState({ resend: false })
    this.handleTimer()
    this.handleChangeForgetPassword()
  }
  async handleChangeForgetPassword() {
    const { phone } = this.state;
    call_api({
      address_api: `${AppConstants.base_url_api}forget-password`,
      method_api: "POST",
      body: JSON.stringify({
        mobile: phone,
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.handleChangeStatus();
        } else if (status === 403) {
          this.setState({ loading: false })
          toast.error(j?.message);
          localStorage.clear();
        } else {
          this.setState({ loading: false })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  } 
}
export default LoginController;
