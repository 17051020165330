import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import Select, { CSSObjectWithLabel } from "react-select";
import { alertButtonsState, numberWithCommas } from "../../../../core/utils";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BsCheckSquare } from "react-icons/bs";
import { AiOutlineCloseCircle, AiOutlineEdit, AiOutlinePlusCircle} from "react-icons/ai";
import { RadioSquare, SubmissionAlert, Header , CustomButton, DropDownIcon,InputIcon, Option, LoadingSpinner } from "../../../components";
import { FiAlertTriangle } from "react-icons/fi";
import { InputTextLabel } from "../../../components/input_text_label";
import { AccordionDetails, AccordionSummary, createTheme, MenuItem, Slider, ThemeProvider } from "@mui/material";
import { Link } from "react-router-dom";
import { FaBan } from "react-icons/fa";
import { BiShowAlt, BiUnlink, BiCheckCircle, BiRecycle } from "react-icons/bi";
import Pagination from "@mui/material/Pagination";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { TfiFilter } from "react-icons/tfi";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { PriceListController } from "../../../controllers";
import { toast } from "react-toastify";
import Accordion from '@mui/material/Accordion';
import { IoIosArrowUp } from "react-icons/io";

class PriceList extends PriceListController {
  componentDidMount() {
    this.getCategoryList();
    this.getBrandList();
    this.getPriceList();
    this.getTagList();
    window.scrollTo(0, 0);
  }
  render() {
    type ListOfData = {
      item: any;
      select: any;
      uuid: string;
      driver: string;
      code: string;
      name: string;
      price: any;
      offPrice: any;
    };
    const { siteList, loadingSiteList } = this.props;
    const {
      priceReduction, showSyncFilter, syncStatus, allOption, showFilters, category, errorsList,
      rowCountList, priceIncrease, rangeSliderNumbers, dreiverId, brandId, brandList, categoryList,
      statusList, loading, toggleModal, priceListData, total, editModal, modalDriverId, sites,
      editionSearch, modalLoading, rows, modalPage, productListData, modalRowCount, showRows,
      editModaltotalRow, modalRowCountList, tag, currentItem, openMenuUrl, statusId, tagList,
      tagLoading, rowCount, search, submitLoading, groupChangeOptions, groupDiscount, selectedList,
      groupPrice, inventory, openGroupOperationModal, groupChangeSelectedOption, openLinkModal,
      relationshipProducts, syncStatus2, confirmUnlink, unlinkedItem, page, cellBg, sitesListLoading,
      groupOpConfirm, selectAllToggle, coloredRows, rowData
    } = this.state;
    const theme = createTheme({
      typography: {
        fontFamily: "Vazir",
      },
    });
    const columns = [
      {
        accessorKey: "id",
        header: "#",
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        size: 1,
      },
      {
        accessorKey: "select",
        header: <RadioSquare 
        title={""}
        onClick={()=> this.handleSelectAll()}
        isActive={
          selectAllToggle
            ? true
            : false
        }
      />,
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor:"pointer"
            }}
          
          >

            {cell.getValue<string>()}
          </div>
        ),
        size: 1,
        enableSorting:false
      },
      {
        accessorKey: "link",
        header: "مرتبط‌سازی",
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        size: 1,
        enableSorting:false
      },
      {
        accessorKey: "driver",
        header: "سایت",
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap:"wrap"
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        size: 20,
        enableSorting:false
      },
      {
        accessorKey: "name",
        header: "نام محصول",
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "right",
              lineHeight: "1.5rem",
              height: "3rem",
              overflow: "hidden",
              maxWidth: "565px"
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        enableSorting:false,
      },
      {
        accessorKey: "price",
        header: "قیمت",
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
           
            {cell.getValue<string>()}
          </div>

        ),
        size:10
      },
      {
        accessorKey: "offPrice",
        header: "تخفیف",
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        size:10,
        enableSorting:false
      },
    ] as MRT_ColumnDef<ListOfData>[];
    const modalColumns =
    [
      {
        accessorKey: "select",
        header: "انتخاب",
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        size: 10,
      },
      {
        accessorKey: 'driver',
        header: 'سایت',
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: 'center',
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        size:10
      },
      {
        accessorKey: 'name',
        header: 'نام محصول',
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: 'flex-start',
              wordBreak: "break-word",
              padding:"10px"

            }}
          >
            {cell.getValue<string>()}
          </div>
        )
      },

      {
        accessorKey: 'price',
        header: 'قیمت',
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            {cell.getValue<string>()}
          </div>
        )
      },
      {
        accessorKey: 'percent',
        header: 'تخفیف',
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        size:10
      },
      {
        accessorKey: 'finalPrice',
        header: 'قیمت نهایی',
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            {cell.getValue<string>()}
          </div>
        )
      },
    ] as MRT_ColumnDef<ListOfData>[];
    return (
      <HelmetProvider>
      <ThemeProvider theme={theme}>
        <div className="price-list">
          <Helmet>
            <title>لیست قیمت </title>
          </Helmet>
          <Header title="لیست قیمت"/>
          <div className="custom-card">             
              <div>
                 {groupOpConfirm.buttonState === "do-sell" &&
                  <SubmissionAlert
                  isOpen={groupOpConfirm.status}
                  title="اخطار!"
                  setClose={(open: boolean) =>
                  this.setState({
                    groupOpConfirm: {
                    status: open,
                    buttonState: "",
                   },
                  })
                  }
                  onClick={() => Number(syncStatus) === 1 ?  this.handleChangeMultipleStatusSync("do_sell") :
                  this.handleChangeMultipleStatus("do_sell")}
                  icon={<FiAlertTriangle color="orange" fontSize="50" />}
                  details={"آیا از قابل فروش‌کردن محصول/محصولات با شناسه‌/های زیر اطمینان دارید؟"}
                  array={selectedList}
                  detailIsArray
                  />
                }
                 {groupOpConfirm.buttonState === "dont-sell" &&
                  <SubmissionAlert
                  isOpen={groupOpConfirm.status}
                  title="اخطار!"
                  setClose={(open: boolean) =>
                  this.setState({
                    groupOpConfirm: {
                    status: open,
                    buttonState: "",
                   },
                  })
                  }
                  onClick={() =>  Number(syncStatus) === 1 ? this.handleChangeMultipleStatusSync("dont_sell") : 
                  this.handleChangeMultipleStatus("dont_sell")}
                  icon={<FiAlertTriangle color="orange" fontSize="50" />}
                  details={"آیا از غیر قابل فروش‌کردن محصول/محصولات با شناسه‌/های زیر اطمینان دارید؟"}
                  array={selectedList}
                  detailIsArray
                  />
                }
                 {groupOpConfirm.buttonState === "no-link" &&
                  <SubmissionAlert
                  isOpen={groupOpConfirm.status}
                  title="اخطار!"
                  setClose={(open: boolean) =>
                  this.setState({
                    groupOpConfirm: {
                    status: open,
                    buttonState: "",
                   },
                  })
                  }
                  onClick={() => this.handleChangeMultipleLinkSync()}
                  icon={<FiAlertTriangle color="orange" fontSize="50" />}
                  details={"آیا از غیر مرتبط‌کردن محصول/محصولات با شناسه‌/های زیر اطمینان دارید؟"}
                  array={selectedList}
                  detailIsArray
                  />
                }

                {confirmUnlink && <SubmissionAlert
                      isOpen={confirmUnlink}
                      title="اخطار غیرمرتبط‌کردن!"
                      setClose={(open: boolean) =>
                        this.handleCloseConfirmLinkModal(open)
                      }
                      onClick={() => this.confirmUnlinkSingle()}
                      icon={<FiAlertTriangle color="orange" fontSize="50" />}
                      details={`آیا از غیرمرتبط‌ کردن شناسه ${unlinkedItem[unlinkedItem.length - 1]?.id} با نام زیر اطمینان دارید؟`}
                      extraInfoFirstRow={siteList && siteList?.filter((obj: any) => obj?.value?.name === unlinkedItem[unlinkedItem.length - 1]?.db_name)[0]?.value?.logo}
                      extraInfoSecondRow={unlinkedItem[unlinkedItem.length - 1]?.db_name}
                      extraInfoDetail={unlinkedItem[unlinkedItem.length - 1]?.product_name}
                      />}
                {toggleModal.status === true &&
                  toggleModal.buttonClick === alertButtonsState.DELETE && (
                    <SubmissionAlert
                      isOpen={toggleModal.status}
                      title="اخطار!"
                      setClose={(open: boolean) =>
                        this.setState({
                          toggleModal: {
                            status: open,
                            buttonClick: alertButtonsState.DELETE,
                          },
                        })
                      }
                      onClick={() => Number(syncStatus) === 1 ? this.handleDeleteSingleSync() : this.handleDeleteSingle()}
                      icon={<FiAlertTriangle color="orange" fontSize="50" />}
                      details={`آیا از حذف محصول با نام ${rowData?.product_name} با شناسه ${rowData?.id}  اطمینان دارید؟`}
                    />
                  )}
                {toggleModal.status === true &&
                  toggleModal.buttonClick ===
                    alertButtonsState.NOTCONFIRMED && (
                    <SubmissionAlert
                      isOpen={toggleModal.status}
                      title="اخطار!"
                      icon={<FiAlertTriangle color="orange" fontSize="50" />}
                      details={`آیا از عدم‌تایید محصول با نام ${rowData?.product_name} با شناسه  ${rowData?.id} اطمینان دارید؟`}
                      setClose={(open: boolean) =>
                        this.setState({
                          toggleModal: {
                            status: open,
                            buttonClick: alertButtonsState.NOTCONFIRMED,
                          },
                        })
                      }
                      onClick={() => Number(syncStatus) === 1 ? this.handleConfirmSingleSync() : this.handleConfirmSingle()}
                    />
                  )}
                    {toggleModal.status === true &&
                  toggleModal.buttonClick ===
                    alertButtonsState.CONFIRMED && (
                    <SubmissionAlert
                      isOpen={toggleModal.status}
                      title="اخطار!"
                      icon={<FiAlertTriangle color="orange" fontSize="50" />}
                      details={`آیا از تایید محصول با نام ${rowData?.product_name} با شناسه ${rowData?.id} اطمینان دارید؟`}
                      setClose={(open: boolean) =>
                        this.setState({
                          toggleModal: {
                            status: open,
                            buttonClick: alertButtonsState.CONFIRMED,
                          },
                        })
                      }
                      onClick={() => Number(syncStatus) === 1 ? this.handleConfirmSingleSync() : this.handleConfirmSingle()}
                    />
                  )}
                {toggleModal.status === true &&
                  toggleModal.buttonClick === alertButtonsState.NOSELLING && (
                    <SubmissionAlert
                      isOpen={toggleModal.status}
                      title="اخطار!"
                      setClose={(open: boolean) =>
                        this.setState({
                          toggleModal: {
                            status: open,
                            buttonClick: alertButtonsState.NOSELLING,
                          },
                        })
                      }
                      onClick={() => Number(syncStatus) === 1 ? this.handleChangeStatusSingleSync() : this.handleChangeStatusSingle()}
                      icon={<FiAlertTriangle color="orange" fontSize="50" />}
                      details={`آیا از غیرقابل فروش‌کردن محصول با نام ${rowData?.product_name} با شناسه ${rowData?.id} اطمینان دارید؟`}
                    />
                  )}
                   {toggleModal.status === true &&
                  toggleModal.buttonClick === alertButtonsState.SELLING && (
                    <SubmissionAlert
                      isOpen={toggleModal.status}
                      title="اخطار!"
                      setClose={(open: boolean) =>
                        this.setState({
                          toggleModal: {
                            status: open,
                            buttonClick: alertButtonsState.SELLING,
                          },
                        })
                      }
                      onClick={() => Number(syncStatus) === 1 ? this.handleChangeStatusSingleSync() : this.handleChangeStatusSingle()}
                      icon={<FiAlertTriangle color="orange" fontSize="50" />}
                      details={`آیا از قابل فروش‌کردن محصول با نام ${rowData?.product_name}با شناسه ${rowData?.id} اطمینان دارید؟`}
                    />
                  )}
                {toggleModal.status === true &&
                  toggleModal.buttonClick === alertButtonsState.RECYCLE && (
                    <SubmissionAlert
                      isOpen={toggleModal.status}
                      title="اخطار!"
                      setClose={(open: boolean) =>
                        this.setState({
                          toggleModal: {
                            status: open,
                            buttonClick: alertButtonsState.RECYCLE,
                          },
                        })
                      }
                      onClick={() => Number(syncStatus) === 1 ? this.handleRecycleSubmitSync() : this.handleRecycleSubmit()}
                      icon={<FiAlertTriangle color="orange" fontSize="50" />}
                      details={`آیا از بازیابی محصول با نام ${rowData?.product_name} با شناسه ${rowData?.id} اطمینان دارید؟`}
                    />
                  )}

                  {toggleModal.status === true && toggleModal.buttonClick === alertButtonsState.UNLINK &&

                    <SubmissionAlert isOpen={toggleModal.status} title="اخطار!" setClose={(open: boolean) => this.setState({ toggleModal: { status: open, buttonClick: alertButtonsState.UNLINK } })} onClick={() => this.handleUnLinkSingleSync()} icon={<FiAlertTriangle color="orange" fontSize="50" />}
                    details={`آیا از غیرمرتبط‌کردن محصول با نام ${rowData?.product_name} با شناسه ${rowData?.id} اطمینان دارید؟`}
                    />
                    }
              </div>
              <Modal
                open={openMenuUrl}
                onClose={()=>this.setState({openMenuUrl:false})}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    overflow: "scroll",
                    fontSize:"14px",
                    width: {
                      xxs: "90%",
                      xs: "90%",
                      sm: "90%",
                      md: "80%",
                      lg: "70%",
                      xl: "70%",
                    },
                    height: "max-content",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  <Typography
                    id="modal-modal-title"
                    variant="subtitle1"
                  >
                    انتخاب سایت
                  </Typography>
                  <Grid container>
                    <div className="table-responsive w-100">
                      <table className="table p-5 text-center">
                        <thead>
                          <tr>
                          {currentItem?.relationship_products && currentItem.relationship_products.length > 0 && currentItem?.relationship_products.map((item:any, index:number) =>{
                        
                        return(
                          <th>
                            <img className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo} alt="" />
                            <MenuItem sx={{display:"flex", justifyContent:"center"}} key={index} onClick={()=>this.setState({openMenuUrl:false})}>
                                <Link  target="_blank" to={item?.product_url}>{item?.db_name}</Link>
                                </MenuItem>
                          </th>
                        )
                        
                      })}
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </Grid>
                </Box>
              </Modal> 
              <Modal
                open={openGroupOperationModal}
                onClose={this.handleCloseGroupOperationModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  className="modal-box"
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    overflowY: "scroll",
                    fontSize:"14px",
                    width: {
                      xxs: "90%",
                      xs: "90%",
                      sm: "90%",
                      md: "80%",
                      lg: "70%",
                      xl: "70%",
                    },
                    height: "fit-content",
                    display: "block",
                  }}
                >
                  <Typography id="modal-modal-title" variant="subtitle1">
                    عملیات گروهی
                  </Typography>
                  <Grid container  sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}>
                    <Grid
                      item={true}
                      xs={12}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Grid item sx={{ color: "red", margin: "20px" }}>
                        توجه در صورت عدم نیاز به هر یک از فیلدهای زیر حتما آن را
                        خالی بگذارید.
                      </Grid>
                    </Grid>
                    <Grid item={true} xs={12}>
                      <DropDownIcon
                        value={groupChangeSelectedOption}
                        title={"نوع تغییر گروهی"}
                        placeholder={"نوع تغییر گروهی را انتخاب نمایید"}
                        type={"text"}
                        optionList={groupChangeOptions}
                        onChange={(event) =>
                          this.setState({
                            groupChangeSelectedOption: event.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid container>
                      {groupChangeSelectedOption === "تغییر ثابت" && (
                        <Grid container>
                          <Grid
                            item={true}
                            lg={4}
                            md={12}
                            xs={12}
                            sx={{ paddingLeft: "10px", paddingTop:"10px" }}
                          >
                            <InputIcon
                              placeholder={"تغییر موجودی"}
                              type={"number"}
                              value={inventory}
                              onChange={(event) =>
                                this.setState({
                                  inventory: event.currentTarget.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid
                            item={true}
                            lg={4}
                            md={12}
                            xs={12}
                            sx={{ paddingLeft: "10px", paddingTop:"10px" }}
                          >
                            <InputIcon
                              placeholder={"تغییر قیمت"}
                              type={"text"}
                              value={numberWithCommas(groupPrice)}
                              onChange={(event) => {
                                var value = event.target.value.replace(
                                  /,/g,
                                  ""
                                );
                                const re = /^[0-9\b]+$/;
                                if (value === "" || re.test(value)) {
                                  this.setState({ groupPrice: value });
                                }
                              }}
                            />
                          </Grid>
                          <Grid
                            item={true}
                            lg={4}
                            md={12}
                            xs={12}
                            sx={{ paddingLeft: "10px", paddingTop:"10px" }}
                          >
                            <InputIcon
                              placeholder={"تغییر تخفیف"}
                              type={"text"}
                              value={numberWithCommas(groupDiscount)}
                              onChange={(event) => {
                                var value = event.target.value.replace(
                                  /,/g,
                                  ""
                                );
                                const re = /^[0-9\b]+$/;
                                if (value === "" || re.test(value)) {
                                  this.setState({ groupDiscount: value });
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {groupChangeSelectedOption === "تغییر درصدی افزایشی" && (
                        <Grid container>
                          <Grid
                            item={true}
                            className="d-flex flex-column"
                            xs={12}
                          >
                            <label className="change-price">
                              تغییر قیمت به درصد ( فقط عدد وارد شود)
                            </label>
                            <InputIcon
                              inBox={"تغییر قیمت"}
                              type={"text"}
                              errortext={
                                errorsList &&
                                errorsList?.filter(
                                  (obj: any) => obj.path === "priceIncrease"
                                )[0]?.message
                              }
                              name="priceIncrease"
                              value={priceIncrease}
                              onChange={(event) => this.priceIncrease(event)}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {groupChangeSelectedOption === "تغییر درصدی کاهشی" && (
                        <Grid container>
                          <Grid
                            item={true}
                            className="d-flex flex-column"
                            xs={12}
                          >
                            <label>
                              تغییر قیمت به درصد ( فقط عدد وارد شود)
                            </label>
                            <InputIcon
                              inBox={"تغییر قیمت"}
                              type={"text"}
                              errortext={
                                errorsList &&
                                errorsList?.filter(
                                  (obj: any) => obj.path === "priceReduction"
                                )[0]?.message
                              }
                              name="priceReduction"
                              value={priceReduction}
                              onChange={(event) => this.priceReduction(event)}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexWrap:"wrap"
                      }}
                    >
                      <Grid item={true} lg={2} md={12} xs={12}>
                        <CustomButton
                          myStyle="bg-light-green price-list-btn"
                          icon={<AiOutlinePlusCircle />}
                          loading={false}
                          title="قابل فروش"
                          onClick={() => this.setState({groupOpConfirm: {status: true, buttonState:"do-sell"}})
                          }
                        />
                      </Grid>
                      <Grid item={true} lg={2} md={12} xs={12}>
                        <CustomButton
                          myStyle="bg-red price-list-btn"
                          icon={<FaBan />}
                          loading={false}
                          title="غیر قابل فروش"
                          onClick={() =>
                            this.setState({groupOpConfirm: {status: true, buttonState:"dont-sell"}})
                          }
                        />
                      </Grid>
                      {Number(syncStatus) === 1  && 
                      <Grid item={true} lg={2} md={12} xs={12}>
                        <CustomButton
                          myStyle="bg-orange price-list-btn"
                          icon={<FaBan />}
                          loading={false}
                          title="غیر مرتبط کردن "
                          onClick={() => this.setState({groupOpConfirm: {status: true, buttonState:"no-link"}})
                          }
                        />
                      </Grid>
                      }
                    </Grid>
                  </Grid>
                  <CustomButton
                    myStyle="final-submission-btn"
                    title="ثبت نهایی "
                    loading={submitLoading}
                    onClick={() =>  this.handleGroupOperation()}
                  />
                </Box>
              </Modal>
              <Modal
                  open={openLinkModal}
                  onClose={this.handleCloseLinkModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    overflowY:"scroll",
                    fontSize:"14px",
                    width: {
                      xxs: "90%",
                      xs: "90%",
                      sm: "90%",
                      md: "80%",
                      lg: "70%",
                      xl: "70%"
                    },
                    height: 'max-content',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                  }}>
                    <Box id="modal-modal-title" sx={{ display: "flex", justifyContent: "space-between"}}>
                    <Typography id="modal-modal-title" variant="subtitle1">
                     محصولات مرتبط با شناسه {relationshipProducts[0]?.id}
                    </Typography>
                    {relationshipProducts.length < siteList.length ?
                      <Tooltip title="ویرایش لیست"><span><AiOutlineEdit className='icon-green' onClick={()=> this.editRelationsProduct()} />
                        </span>
                      </Tooltip>
                      :""}
                    </Box>
                    {sitesListLoading ? <LoadingSpinner /> :
                      <>
                        <table className="table text-center">
                          <thead>
                            <tr>
                            <th scope="col">عملیات</th>
                              <th scope="col">سایت</th>
                              <th scope="col">نام</th>
                              <th scope="col">شناسه</th>
                              <th scope="col">قیمت</th>
                              <th scope="col">تخفیف</th>
                            </tr>
                          </thead>
                          <tbody>
                          
                          {relationshipProducts?.map((item: any, index: number) => {
                        return (
                          <tr key={index}>
                              <td><div className="d-flex justify-content-between">
                              {siteList[0].value?.name === item?.db_name  ? "" :
                              <>
                                {Number(syncStatus) === 1 &&   <RadioSquare
                            title={""}
                            onClick={() => this.handleSelectedSiteModal(index)}
                            isActive={item?.site_select === true ? true : false}
                          /> 
                                }
                                </>
                          }
                          {siteList[0].value?.name === item?.db_name ? "" : <Tooltip title="غیر مرتبط کردن "><span> <BiUnlink onClick={() => this.handleUnLinkSingle(item)} className='icon-red'/></span></Tooltip>}
                          </div>
                          </td>
                              <td><img className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo} alt=""/></td>
                              <td> 
                                <span>
                                  <Link to={item?.product_url} target="_blank">{item?.product_name}</Link>
                                </span>
                              </td>
                              <td>{item?.id}</td>
                              <td >
                                <input
                                  name="price"
                                  value={numberWithCommas(item?.price)}
                                  type={"text"}
                                  className="form-control dir-ltr"
                                  onBlur={(event) => this.blurRelationshipProductsPrice(event , item , index)}
                                  onChange={(event)=> this.changeRelationshipProductsPrice(event , item , index)}
                                />
                              </td>
                                <td >
                                  <input
                                    type={"text"}
                                    className="form-control dir-ltr"
                                    name="discount"
                                    value={numberWithCommas(item?.discount)}
                                    onBlur={(event) => this.blurRelationshipProductsDiscount(event , item , index)}
                                    onChange={(event) => this.changeRelationshipProductsDiscount(event , item , index)}
                              />
                                </td>
                            </tr>
                        );
                      }
                          )}
                          
                          </tbody>
                        </table>
                        {unlinkedItem.length > 0 &&
                          <>
                          <span>محصولات غیرمرتبط‌شده</span>
                          <table className="table text-center">
                          <thead>
                            <tr>
                              <th scope="col">سایت</th>
                              <th scope="col">نام</th>
                              <th scope="col">شناسه</th>
                              <th scope="col">قیمت</th>
                              <th scope="col">تخفیف</th>
                            </tr>
                          </thead>
                          <tbody>
                            {unlinkedItem.map((item:any, index:number)=>{
                              return(
                                <tr key={index}>
                                <td><img className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo} alt=""/></td>
                                <td> 
                                  <span>
                                    <Link to={item?.product_url} target="_blank">{item?.product_name}</Link>
                                  </span>
                                </td>
                                <td>{item?.id}</td>
                                <td >
                                  <input
                                    name="price"
                                    value={numberWithCommas(item?.price)}
                                    type={"text"}
                                    className="form-control dir-ltr"
                                    disabled

                                  />
                                </td>
                                  <td >
                                    <input
                                      type={"text"}
                                      className="form-control dir-ltr"
                                      name="discount"
                                      value={numberWithCommas(item?.discount)}
                                      disabled
                                />
                                  </td>
                              </tr>
                              )
                            })}

                          </tbody>
                          </table>
                          </>
                        }
                      </>
                        }
                  </Box>
              </Modal>
              <Modal
                  open={editModal}
                  onClose={this.handleCloseEditModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    overflowY:"scroll",
                    fontSize:"14px",
                    width: {
                      xxs: "90%",
                      xs: "90%",
                      sm: "90%",
                      md: "80%",
                      lg: "70%",
                      xl: "70%"
                    },
                    height: 'fit-content',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                  }}>
                    <Typography id="modal-modal-title" variant="subtitle1">
                       ویرایش محصولات مرتبط
                    </Typography>
                    
                    <div className="filter-select-container mb-2 pe-3">
                      <div className="filter-options-container">
                        <div className="column-50">
                        <Select
                          className="profile-select"
                                styles={{
                                  control: (baseStyles, state):CSSObjectWithLabel => ({
                                    ...baseStyles,
                                    boxShadow:"rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",
                                    borderWidth: "1px",
                                    backgroundColor: "#fff",
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    textAlign: "right",
                                    borderRadius: "4px",
                                    height:"35px",
                                    minHeight:"20px",
                                    outline: "unset",
                                  }) as CSSObjectWithLabel,
                                }}
                                onChange={(choice) => this.modalDriverSelected(choice)}
                                components={{ Option }}
                                value={modalDriverId && Object.keys(modalDriverId).length > 0 ? modalDriverId : sites[0]}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                noOptionsMessage={() => 'این مورد وجود ندارد!'}
                                options={sites}
                                getOptionLabel={(option: any) => option?.value?.persian_name}
                                getOptionValue={(option: any) => option?.value?.name}
                                placeholder="سایت"
                                isClearable={false}
                                loadingMessage={() => 'در حال بارگذاری  ...'}
                        />
                        </div>
                        <div className="column-50">
                        <InputTextLabel
                          type={"text"}
                          myStyle="search-input"
                          value={editionSearch}
                          onChange={(event) => this.handleSearchEditModal(event.currentTarget.value)}
                          placeholder="جستجو..."
                        />
                        </div>
                      </div>
                    </div>
                    {modalLoading ? <LoadingSpinner /> :
                    <>
                    <MaterialReactTable
                muiTablePaperProps={{
                  sx: { mr: '1.5rem',ml: '1.5rem', border:"1px solid #e5e5e5"},
                }}
                muiTableBodyRowProps={({ row, staticRowIndex }) => ({
                  sx: {
                    height: "fit-content",
                    backgroundColor: rows?.id && rows?.id === row.original?.item?.id
                      ? "#d1ecfb"
                      : (staticRowIndex % 2 === 0 ) ? '#f3f3f3'  : "",
                  },
                })}
                  columns={modalColumns}
                  data={productListData && productListData?.length > 0 && productListData?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null).map((item: any, index: number) => {
                    return ({
                      item: item,
                      select: (
                        <div className="cursor-pointer" onClick={() => this.handleAdd(item)}>
                          <RadioSquare
                            title={""}
                            onClick={() => this.handleAdd(item)}
                            isActive={
                              rows?.id &&  rows?.id === productListData[index]?.id
                                ? true
                                : false
                            }
                          />
                          <div>{item.id}</div>
                        </div>
                      ),
                      driver:<img className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo} alt=""/> ,
                      name:  <Tooltip title={item.product_name}>
                      <span style={{  whiteSpace: "nowrap", overflow:"hidden",
                      textOverflow:"ellipsis", width:"230px"}}>
                      {item.product_name}
                      </span>
                    </Tooltip>,
                      price: numberWithCommas(item?.price_by_discount?.price),
                      percent: item?.price_by_discount?.type === "percent" ? `% ${item?.price_by_discount?.discount}` : numberWithCommas(item?.price_by_discount?.discount),
                      finalPrice: numberWithCommas(item?.price_by_discount?.finalPrice),
                    }
                    );
                  }
                  )}
                  renderTopToolbarCustomActions={({ table }) => {
                    return (
                          <CustomButton
                            myStyle=""
                            loading={false}
                            onClick={this.addNewRelatedProduct}
                            title="افزودن"
                          />
                    );
                  }}
                  muiTableProps={{
                    sx: {
                      border: "1px solid #e5e5e5",
                    },
                  }}
                  muiTableHeadCellProps={{
                    sx: {
                      border: "1px solid #e5e5e5",
                      justifyContent:"center",
                      textAlign:"center",
                      position:"sticky",
                      zIndex:1
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      border: "1px solid #e5e5e5",
                      textAlign:"center",
                      justifyContent:"center"

                    },
                  }}
                  muiTopToolbarProps={{
                    sx:{
                      zIndex:0
                    }
                  }}
                  muiTableContainerProps={{
                    sx:{
                      maxHeight:"500px"
                    }
                  }}
                  enableRowNumbers
                  rowNumberMode="static"
                  positionPagination="bottom"
                  state={{isLoading:modalLoading, showProgressBars:modalLoading}}
                  enablePagination={false}
                  renderBottomToolbarCustomActions={({table})=>{
                  return (
                    <div className="custom-pagination w-100">
                      <Pagination
                        page={modalPage}
                        count={Math.ceil(editModaltotalRow / modalRowCount)}
                        onChange={this.handleChangeModalPage}/>
                      <DropDownIcon
                        value={modalRowCount}
                        optionList={modalRowCountList}
                        title={""}
                        onChange={(event) => {
                         this.handleModalPaginationSelect(event);}}
                          />
                    </div>
                  );
                }}
                positionExpandColumn={'last'}
                enableGlobalFilter={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableHiding={false}
                enableColumnActions={false}
                  initialState={{
                    sorting: [
                      {
                        id: 'select',
                        desc: true,
                      },
                      {
                        id: "price",
                        desc: true
                      },
                       
                    ],
                  }}
                  localization={{
                    noRecordsToDisplay:"داده‌ای برای نمایش وجود ندارد!"
                  }}
                  positionActionsColumn={'first'}
                />
                <div className="selected-products-container">
                  {showRows?.length > 0 &&
                  <>
                  <span> محصولات زیر را اضافه کرده‌اید:</span>
                   <ul >
                    {showRows.map((item:any) =>{
                      return(
                        <li>
                         <img className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo} alt="" /> {item.id} {item.product_name}
                        </li>
                      )
                    })}
                    </ul>
                    </>}
                </div>
                </>
              }
                  </Box>
              </Modal>
              <MaterialReactTable
              memoMode="rows"
              state={{ isLoading: loading, showAlertBanner:false }}
                muiTablePaperProps={{
                  sx: {
                    mr: "0.5rem",
                    border: "1px solid #e5e5e5",
                    mt:"18px",
                    position:"fixed",
                    width:"98.5%"
                  },
                }}
                muiTableBodyRowProps={({ row, staticRowIndex }) => ({
                  sx: {
                    height:"60px",
                    backgroundColor: selectedList.find(
                      (c: { id: any }) => c.id === row.original?.item?.id
                    )
                      ? "#d1ecfb"
                      : (staticRowIndex % 2 === 0 ) ? '#f3f3f3'  : "",
                  },
                })}
                muiTableContainerProps={({table}) =>({
                  style:{
                    maxHeight:"800px",
                    height: table.getState().isFullScreen ? "100vh" :"570px",
                    
                  },
                })}
                muiTableHeadProps={{
                  sx:{
                    zIndex:2
                  }
                }}

                columns={columns}
                data={ 
                  priceListData &&
                  priceListData?.length > 0 &&
                  priceListData
                    ?.filter(
                      (v: string | null | undefined) =>
                        v !== "" && v !== undefined && v !== null
                    )
                    .map((item: any, index: number) => {
                      return {
                        item: item,
                        id: <span style={{backgroundColor: coloredRows.find(
                          (c: { id: any }) => c.id === item?.id) ? cellBg : index % 2 === 0 ? "#f3f3f3" : "#ffffff", padding:"20px 53px"}}>{index + 1}</span> ,
                        select: 
                          <div className="cursor-pointer" onClick={() => this.handleSelect(index, item)} >
                            <RadioSquare
                              key={item?.id}
                              title={""}
                              onClick={() => this.handleSelect(index, item)}
                              isActive={
                                selectedList.find(
                                  (c: { id: any }) => c.id === item?.id
                                )
                                  ? true
                                  : false
                              }
                            />
                          <div>{item?.id}</div>
                          </div>,
                        link: item.mId === null ?  <AiOutlineCloseCircle
                          className="icon-red cursor-default" 
                          
                        /> : <BsCheckSquare
                        className="icon-green"
                        onClick={() => this.handleOpenLinkModal(item,index)}
                      /> ,
                      driver: item?.relationship_products?.length > 0 ? item?.relationship_products?.map((item2: any, index2: number) => {
                        return (
                          <img key={index2} src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item2?.db_name)[0]?.value?.logo} className={`p-1 ${item2?.site_select ? "cursor-pointer logo" :'unselected-site-logo logo'}` }alt="" 
                          onClick={(event)=> Number(syncStatus) === 1 ?  this.handleSelectedSite(item , index , index2) : null} />
                          );
                        }
                        ) : <img className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo} alt="" />,
                        name: <Tooltip title={item.product_name}>
                        <span style={{  whiteSpace: "nowrap", overflow:"hidden",
                        textOverflow:"ellipsis", padding:"10px"}}>

                        {item.product_name}
                        </span>
                      </Tooltip> ,
                        price: ( 
                            <input
                              type="text"
                              name="price"
                              value={numberWithCommas(item.price)}
                              className="form-control dir-ltr"
                              onBlur={(event:any) =>
                                this.handleBlurPrice(event, item, index)
                              }
                              onChange={(event:any) => this.handleChangePrice(event, item, index) } 
                            />
                        ),
                        offPrice: (
                          <input
                            type="text"
                            name="discount"
                            value={numberWithCommas(item.discount)}
                            className="form-control dir-ltr"
                            onBlur={(event:any) =>
                              this.handleBlurOffPrice(event, item, index)
                            }
                            onChange={(event:any) => this.handleChangeOffPrice(event, item, index) } 
                          />
                        ),
                      };
                    })
                }
                positionExpandColumn={"last"}
                enableGlobalFilter={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableFullScreenToggle={true}
                rowVirtualizerProps={{ overscan: 20 }} 
                rowVirtualizerInstanceRef={this.tableInstanceRef} 
                enableStickyHeader 
                enableHiding={false}
                renderTopToolbarCustomActions={({ table }) => {
                  return (
                    <>
                    
                      <div className="group-op-container">
                        <div className="first-row">
                          <div className="filter-select-container pe-3">
                          <div className="filter-options-container">
                            {showFilters && (
                              <>
                                <div className="column-30">
                                  <Select
                                    styles={{
                                      control: (baseStyles: any, state: any):any => ({
                                        ...baseStyles,
                                        boxShadow:"rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",
                                        borderWidth: "1px",
                                        backgroundColor: "#fff",
                                        display: "flex",
                                        flexDirection: "row-reverse",
                                        textAlign: "right",
                                        borderRadius: "4px",
                                        outline: "unset",                                       
                                      }),
                                      menuPortal: (base):any => ({ ...base, zIndex: 9999 })
                                      
                                    }}
                                    onChange={this.driverSelected}
                                    components={{ Option }}                          
                                    value={dreiverId}
                                    isMulti={true}
                                    closeMenuOnSelect={true}
                                    noOptionsMessage={() => "این مورد وجود ندارد!"}
                                    menuPortalTarget={document.body}
                                    options={[ allOption, ...siteList]}
                                    getOptionLabel={(option: any) =>
                                      option?.value?.persian_name
                                    }
                                    getOptionValue={(option: any) => option?.value?.name}
                                    placeholder={showSyncFilter === true  ? "سایت" :  "محصولات لینک شده"}
                                    isClearable
                                    loadingMessage={() => "در حال بارگذاری  ..."}
                                    isLoading={loadingSiteList}
                                  />
                                </div>
                                
                                <div className="column-30">
                                  <InputTextLabel
                                    type={"text"}
                                    myStyle="search-input"
                                    value={search}
                                    onChange={(event) => this.handleSearch(event)}
                                    placeholder="جستجو..."
                                  />
                                </div>
                                <div className="column-30">
                                  <Accordion sx={{position:"fixed", width:"29%", marginBottom:{md:"20px", sm:"20px"}, zIndex:"9", border:"1px solid #c9c6c6", boxShadow:"none"}}>
                                    <AccordionSummary
                                      expandIcon={<IoIosArrowUp />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                      sx={{minHeight:"20px", height: { lg:"33px", md:"28px", sm:"20px", xs:"20px"}}}
                                    >
                                      <Typography sx={{fontSize:"14px", color:"#c9c6c6"}}>فیلترها</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <Select
                                                                      styles={{
                                                                        control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                                                                          ...baseStyles,
                                                                          boxShadow: state.isFocused
                                                                            ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                                                            : "",
                                                                          borderWidth: "1px",
                                                                          backgroundColor: "#fff",
                                                                          display: "flex",
                                                                          flexDirection: "row-reverse",
                                                                          textAlign: "right",
                                                                          borderRadius: "4px",
                                                                          minHeight: "20px",
                                                                          
                                                                          outline: "unset",
                                                                          marginBottom: "10px",
                                                                        }) as CSSObjectWithLabel,
                                                                        menuPortal: (base):any => ({ ...base, zIndex: 9999 })
                                                                      }}
                                                                      onChange={(choice: any) => this.brandSelected(choice)}
                                                                      value={brandId}
                                                                      closeMenuOnSelect={true}
                                                                      options={brandList}
                                                                      noOptionsMessage={() => "این مورد وجود ندارد!"}
                                                                      getOptionLabel={(option: any) => option?.persian_name }
                                                                      getOptionValue={(option: any) => option?.id}
                                                                      placeholder="همه برندها "
                                                                      isClearable
                                                                      isMulti={Number(syncStatus) === 1 ? false : true}
                                                                    />
                                                                    <Select
                                                                      styles={{
                                                                        control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                                                                          ...baseStyles,
                                                                          boxShadow: state.isFocused
                                                                            ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                                                            : "",
                                                                          borderWidth: "1px",
                                                                          backgroundColor: "#fff",
                                                                          display: "flex",
                                                                          flexDirection: "row-reverse",
                                                                          textAlign: "right",
                                                                          borderRadius: "4px",
                                                                          height:"35px",
                                                                          minHeight:"20px",    
                                                                          outline: "unset",
                                                                          marginBottom: "10px",
                                                                        }) as CSSObjectWithLabel,
                                                                        menuPortal: (base):any => ({ ...base, zIndex: 9999 })
                                                                      }}
                                                                      onChange={(choice: any) => this.categorySelected(choice)}
                                                                      value={category}
                                                                      isMulti={Number(syncStatus) === 1 ? false : true}
                                                                      noOptionsMessage={() => "این مورد وجود ندارد!"}
                                                                      closeMenuOnSelect={true}
                                                                      options={categoryList}
                                                                      getOptionLabel={(option: any) => option.persian_name }
                                                                      getOptionValue={(option: any) => option.id}
                                                                      placeholder="همه دسته بندی‌ها"
                                                                      isClearable
                                                                    />
                                                                    <Select
                                                                      styles={{
                                                                        control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                                                                          ...baseStyles,
                                                                          boxShadow: state.isFocused
                                                                            ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                                                            : "",
                                                                          borderWidth: "1px",
                                                                          backgroundColor: "#fff",
                                                                          display: "flex",
                                                                          flexDirection: "row-reverse",
                                                                          textAlign: "right",
                                                                          borderRadius: "4px",
                                                                          height:"35px",
                                                                          minHeight:"20px",                                   
                                                                          outline: "unset",
                                                                          marginBottom: "10px",
                                                                        }) as CSSObjectWithLabel,
                                                                        menuPortal: (base):any => ({ ...base, zIndex: 9999 })

                                                                      }}
                                                                      onChange={(choice: any) => this.statusSelected(choice)}
                                                                      value={statusId}
                                                                      isMulti={Number(syncStatus) === 1 ? false : true}
                                                                      closeMenuOnSelect={true}
                                                                      noOptionsMessage={() => "این مورد وجود ندارد!"}
                                                                      options={statusList}
                                                                      getOptionLabel={(option: any) => option.title}
                                                                      getOptionValue={(option: any) => option.value}
                                                                      placeholder="وضعیت"
                                                                      isClearable
                                                                    />
                                                                    <Select
                                                                  styles={{
                                                                    control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                                                                      ...baseStyles,
                                                                      boxShadow: state.isFocused
                                                                        ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                                                        : "",
                                                                      borderWidth: "1px",
                                                                      backgroundColor:"#fff",
                                                                      display: "flex",
                                                                      flexDirection: "row-reverse",
                                                                      textAlign: "right",
                                                                      borderRadius: "4px",
                                                                      height:"35px",
                                                                      minHeight:"20px",                               
                                                                      outline: "unset",
                                                                      marginBottom: "10px",
                                                                    }) as CSSObjectWithLabel,
                                                                    menuPortal: (base):any => ({ ...base, zIndex: 9999 })

                                                                  }}
                                                                  onChange={(choice: any) => this.tagSelected(choice)}
                                                                  onInputChange={(choice: any) => this.handleTagSearch(choice)}
                                                                  value={tag}
                                                                  isMulti={Number(syncStatus) === 1 ? false : true}
                                                                  noOptionsMessage={() => "این مورد وجود ندارد!"}
                                                                  closeMenuOnSelect={true}
                                                                  options={tagList}
                                                                  getOptionLabel={(option: any) => option.persian_name }
                                                                  isLoading={tagLoading}
                                                                  getOptionValue={(option: any) => option.id}
                                                                  placeholder="برچسب"
                                                                  isClearable
                                                                  />
                                    </AccordionDetails>
                                  </Accordion>                                                                
                                </div>
                                <div className="column-30 pt-3">
                                  <span> قیمت بین {`${numberWithCommas(rangeSliderNumbers[0])}تومان`} و {`${numberWithCommas(rangeSliderNumbers[1])}تومان`} 
                                  </span>
                                  <Slider
                                    getAriaLabel={() => 'Minimum distance'}
                                    value={rangeSliderNumbers}
                                    onChange={this.handleChangeRangeSlider}
                                    valueLabelDisplay="off"
                                    step={1000}
                                    min={0}
                                    max={1000000000}/>
                              </div>
                                {showSyncFilter && 
                                  <div className="column-30 mb-1 d-flex align-items-center">
                                    <ToggleButtonGroup
                                      value={syncStatus2}
                                      exclusive
                                      onChange={this.handlesyncProducts}
                                      aria-label="text alignment"
                                      sx={{borderLeft: "1px solid #ebebeb"}}
                                    >
                                      <ToggleButton sx={{borderRadius:"0px"}} value="0" aria-label="left aligned">
                                        لینک نشده
                                      </ToggleButton>
                                      <ToggleButton value="2" aria-label="centered">
                                        همه
                                      </ToggleButton>
                                      <ToggleButton value="1" aria-label="right aligned">
                                        لینک شده
                                      </ToggleButton>
                                    </ToggleButtonGroup>
                                  </div>
                                }
                              </>
                            )}
                          </div>
                          </div>
                        </div>
                        <div className="second-row">
                        <CustomButton
                          myStyle="group-op-btn"
                          loading={false}
                          onClick={this.operationalGroup}
                          title="عملیات گروهی "
                        />
                         <Tooltip title="نمایش فیلترها">
                            <span>
                              <TfiFilter
                                className="filter-icon"
                                onClick={() => this.setState(prevState => ({
                                  showFilters: !prevState.showFilters
                                }))
                              }
                              />
                            </span>
                        </Tooltip>
                       
                        <div className="custom-pagination">
                        <Pagination
                         page={page}
                          count={Math.ceil(total / rowCount)}
                          onChange={this.handleChangePage}
                        />
                        <DropDownIcon
                          value={rowCount}
                          optionList={rowCountList}
                          title={""}
                          onChange={(event) => {
                            this.handlePaginationSelect(event);
                          }}
                        />
                      </div>
                        </div>
                        
                      </div>
                    </>
                  );
                }}
                enablePagination={false}
                renderBottomToolbarCustomActions={({table})=>{
                  return (
                    <div className="custom-pagination w-100">
                      <Pagination
                       page={page}
                        count={Math.ceil(total / rowCount)}
                        onChange={this.handleChangePage}
                      />
                      <DropDownIcon
                        value={rowCount}
                        optionList={rowCountList}
                        title={""}
                        onChange={(event) => {
                          this.handlePaginationSelect(event);
                        }}
                      />
                    </div>
                  );
                }}
                enableColumnActions={false}
                initialState={{
                  sorting: [
                    {
                      id: "id",
                      desc: true
                    }
                  ],
                }}
                enableRowActions
                localization={{
                  actions: "عملیات",
                  noRecordsToDisplay: "داده‌ای برای نمایش وجود ندارد!",
                }}
                muiTableProps={{
                  sx: {
                    border: "1px solid #e5e5e5",
                  },
                }}
                muiTableHeadCellProps={{
                  sx: {
                    border: "1px solid #e5e5e5",
                    height: "fit-content",
                    textAlign:"center",
                    justifyContent:"center",
                  },
                }}
                muiTopToolbarProps={{
                  sx:{
                    zIndex:10
                  }
                }}
                muiTableBodyCellProps={{
                  sx: {
                    border: "1px solid #e5e5e5",
                    textAlign:"center",
                    justifyContent:"center",
                  },
                }}          
                positionPagination="bottom"
                positionActionsColumn={"last"}
                enableRowVirtualization
                tableInstanceRef={this.tableInstanceRef}
                renderRowActions={({ row, table }) => [
                  <div key={row?.id}>
                  {loading === false && 
                  <div  className="detail-container">
                    {this.siteSelectedCount(row.original.item) ?
                    <>
                    {row.original?.item?.dont_sell?.name === 0 ? (
                      <Tooltip title="غیرقابل فروش کردن">
                        <span>
                          <AiOutlineCloseCircle
                            className="icon-red"
                            onClick={ ()=> this.handleModal(row.original,alertButtonsState.NOSELLING) }
                          />
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip title="قابل فروش کردن">
                        <span>
                          <AiOutlineCloseCircle
                            className="icon-green"
                            onClick={()=> this.handleModal(row.original,alertButtonsState.SELLING) }
                          />
                        </span>
                      </Tooltip>
                    )}
                    {Number(syncStatus) === 1 && 
                      <Tooltip title="غیر مرتبط کردن "><span> <BiUnlink
                      className="icon-navy-blue"
                      onClick={ ()=> this.handleModal(row.original,alertButtonsState.UNLINK) } /></span></Tooltip>
                    }
                </>
                    :
                    <>
                    {row.original?.item?.deleted_at ? (
                      <Tooltip title="بازیابی کردن">
                        <span>
                          <BiRecycle
                            className= "icon-gray"
                            onClick={() =>  toast.error("حداقل یک سایت را انتخاب کنید")}
                          />
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip title="حذف کردن">
                        <span>
                          <RiDeleteBin6Line
                            className= "icon-gray"
                            onClick={() =>  toast.error("حداقل یک سایت را انتخاب کنید")}
                          />
                        </span>
                      </Tooltip>
                    )}
                    {row.original?.item?.confirmed === 0 ? (
                      <Tooltip title="تایید">
                        <span>
                          <BiCheckCircle
                            className= "icon-gray"
                            onClick={() => toast.error("حداقل یک سایت را انتخاب کنید")}
                          />
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip title="عدم تایید">
                        <span>
                          <BiCheckCircle
                            className= "icon-gray"
                            onClick={() =>toast.error("حداقل یک سایت را انتخاب کنید")}
                          />
                        </span>
                      </Tooltip>
                    )}
                    {row.original?.item?.dont_sell?.name === 0 ? (
                      <Tooltip title="غیرقابل فروش کردن">
                        <span>
                          <AiOutlineCloseCircle
                            className= "icon-gray"
                            onClick={() =>  toast.error("حداقل یک سایت را انتخاب کنید")}
                          />
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip title="قابل فروش کردن">
                        <span>
                          <AiOutlineCloseCircle
                            className= "icon-gray"
                            onClick={() =>toast.error("حداقل یک سایت را انتخاب کنید")}
                          />
                        </span>
                      </Tooltip>
                    )}
                    {Number(syncStatus) === 1 && 
                      <Tooltip title="غیر مرتبط کردن "><span> <BiUnlink
                      className= "icon-gray"
                      onClick={() =>toast.error("حداقل یک سایت را انتخاب کنید")} /></span></Tooltip>
                    }
                  </>
                  }
                  {Number(syncStatus) === 1 ?
                  <Tooltip title="نمایش">
                  <span onClick={(event:any)=> this.setState({openMenuUrl:true, currentItem:row?.original?.item})}>
                      <BiShowAlt className="icon-gray" />
                  </span>
                  </Tooltip>
                        :
                        <Link target="_blank" to={row.original.item?.product_url}> 
                        <BiShowAlt className="icon-gray" />
                       </Link>   
                }
                  </div>
                }
                  </div>
                ]}
              />
          </div>
        </div>
      </ThemeProvider>
      </HelmetProvider>
    );
  }
}
const mapStateToProps = (dispatch: any) => {
  return {
    dispatch,
    siteList: dispatch.general.siteList,
    loadingSiteList: dispatch.general.loadingSiteList,
  };
};
export default connect(mapStateToProps)(PriceList);
