import {numberWithCommas, orderStatus } from "../../core/utils";
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import moment from 'moment-jalaali';
import DropdownIcon from "./dropdown_icon";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface Props {
    selectedOrder:any;
    dropDownOnchange?:any;
    hasDropDown?:boolean;
    dropDownStatus?:string;
}
const OrderDetails = ({selectedOrder, dropDownOnchange, hasDropDown, dropDownStatus  }: Props) => {
const [selectedOrderStatusDropDown, setSelectedOrderStatusDropDown] = useState(dropDownStatus);
const handleChangeModalOrderStatus = (event:any) =>{
    setSelectedOrderStatusDropDown(event.target.value)
    dropDownOnchange(event)
}
useEffect(()=>{
    setSelectedOrderStatusDropDown(dropDownStatus)

},[dropDownStatus])
return(
    <>
                    <Grid container sx={{
                      display: "flex", justifyContent:"space-between"
                      , border: "1px solid #d3d0d0", padding:"10px", borderRadius:"8px"
                    }}>
                        <Box sx={{display:"flex"}}>
                          شناسه سفارش: &nbsp; <Typography sx={{color:"#6a6973", fontSize:"13px"}}>{selectedOrder?.id}</Typography>
                        </Box >
                        
                          <Box sx={{display:"flex"}}>
                          وضعیت: &nbsp; <Typography sx={{color:"#6a6973",fontSize:"13px"}}>{selectedOrder?.order_status?.value}  ({selectedOrder?.payment_type?.value})</Typography>
                        </Box >
                      
                        <Box sx={{display:"flex"}}>
                          تاریخ سفارش: &nbsp; <Typography sx={{color:"#6a6973",fontSize:"13px"}}>{moment(selectedOrder?.created_at).format("HH:mm:ss jYYYY/jM/jD")}</Typography>
                        </Box >
                    </Grid>
                    <Grid container sx={{
                      marginTop: "15px", display: "flex", justifyContent: "space-between", padding:"10px"
                      , border: "1px solid #d3d0d0", borderRadius:"8px"
                    }}>
                      <Box sx={{display:"flex"}}>
                      گیرنده: &nbsp; <Typography sx={{color:"#6a6973",fontSize:"13px"}}>{selectedOrder?.shipping && selectedOrder?.shipping?.receiver_name}</Typography>
                      </Box>
                      <Box sx={{display:"flex"}}>
                          موبایل گیرنده: &nbsp; <Typography sx={{color:"#6a6973",fontSize:"13px"}}>{selectedOrder?.user && selectedOrder?.shipping?.receiver_phone}</Typography>
                      </Box>
                      <Box sx={{display:"flex"}}>
                           نوع ارسال: &nbsp; <Typography sx={{color:"#6a6973",fontSize:"13px"}}>{selectedOrder?.shipping_type && selectedOrder?.shipping_type?.value}</Typography>
                      </Box>
                        </Grid>
                        <Grid container sx={{
                      display: "flex", justifyContent:"space-between"
                      , border: "1px solid #d3d0d0", padding:"10px", marginTop: "15px", borderRadius:"8px", backgroundColor:"#f3f3f3"
                    }}>
                        <Box sx={{display:"flex"}}>
                          وضعیت ارسال: &nbsp; <Typography sx={{color:"#6a6973",fontSize:"13px"}}>{selectedOrder?.shipping ? selectedOrder?.shipping?.status?.value : ""}</Typography>
                        </Box >
                        
                          <Box sx={{display:"flex"}}>
                         زمان ارسال: &nbsp; <Typography sx={{color:"#6a6973",fontSize:"13px"}}>{moment(selectedOrder?.shipping?.send_date).format("jYYYY/jM/jD")}</Typography>
                        </Box >
                      
                        <Box sx={{display:"flex"}}>
                        بازه ارسال: &nbsp; <Typography sx={{color:"#6a6973",fontSize:"13px"}}>{selectedOrder?.shipping?.send_period}</Typography> 
                        </Box >
                    </Grid>
                    <Grid container sx={{
                      marginTop: "15px", display: "flex", justifyContent: "space-between", padding:"10px"
                      , border: "1px solid #d3d0d0", borderRadius:"8px"
                    }}>
                        <Box sx={{display:"flex"}}>
                      کد پستی: &nbsp; <Typography sx={{color:"#6a6973",fontSize:"13px"}}>{(selectedOrder?.shipping && selectedOrder?.shipping?.postalcode ) ? selectedOrder?.shipping?.postalcode : "ثبت‌نشده" }</Typography>
                        </Box>
                        <Box sx={{display:"flex"}}>
                      {selectedOrder?.shipping?.self_receive === 0 ?
                        <Box sx={{display:"flex"}}>                       
                      آدرس: &nbsp; <Typography sx={{color:"#6a6973",fontSize:"13px"}}>{selectedOrder?.address.filter((i:any)=> i?.id === selectedOrder.shipping?.address_id)[0]?.address}</Typography>
                        </Box>
                                                                                      
                        :
                        <span> دریافت حضوری</span>
                                                  
                        }
                        </Box>
                      </Grid>

                      
                    <Grid container sx={{ marginTop: "15px", display: "flex", justifyContent: "space-between", border: "1px solid #d3d0d0", borderRadius:"8px"  }}>
                    <div className="table-responsive w-100">
                      <table className="modal-table table p-5 text-center">
                        <thead>
                          <tr >
                            <th scope="col">ردیف</th>
                            <th scope="col">شناسه</th>
                            <th scope="col">محصول</th>
                            <th scope="col">تعداد</th>
                            <th scope="col">قیمت</th>
                            <th scope="col">تخفیف</th>
                            <th scope="col">مبلغ کل</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedOrder && selectedOrder?.order_products?.length > 0 && selectedOrder?.order_products?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null).map((item: any, index: number) => {
                            return (

                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item?.product_id}</td>
                                <td><span>
                                    <Link to={item?.product?.product_url} target="_blank">{item?.product_name}</Link>
                                  </span></td>
                                <td>{item.count}</td>
                                <td>{numberWithCommas( item?.price_by_discount?.price) }</td>
                                <td>{Number(item?.price_by_discount?.discount) < 99 ? `${numberWithCommas( item?.price_by_discount?.discount)}%`
                                :`${numberWithCommas( item?.price_by_discount?.discount)} تومان ` }</td>
                                <td>{ numberWithCommas(item?.price_by_discount?.finalPrice)}</td>
                              </tr>
                            );

                          }
                          )}
                          <tr className="table-warning">
                            <td colSpan={3} >کل</td>
                            <td>{selectedOrder?.totalCount}</td>
                            <td>{numberWithCommas(selectedOrder?.totalPrice)}</td>
                            <td>{numberWithCommas(selectedOrder?.totalDiscount)}</td>
                            <td className="table-danger">{numberWithCommas(selectedOrder?.totalPriceWithDiscount)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    </Grid>
                    <Grid container sx={{ marginTop: "15px", display: "flex", justifyContent: "space-between"}}>
                    {hasDropDown && 
                      <Grid item={true} xs={12} sm={12} md={6} sx={{ paddingLeft: "15px" }}>
                      <DropdownIcon
                          value={selectedOrderStatusDropDown}
                          title={"وضعیت سفارش"}
                          inBox={"وضعیت سفارش"}
                          type={"text"}
                          optionList={orderStatus.filter((i:any)=>selectedOrder?.payment_status?.name === 1 ?  i.value !== 0 : {})}
                          myStyle="w-h-100"
                          onChange={(event) => handleChangeModalOrderStatus(event)}
                        />
                          </Grid>
                       }
                      <Grid item={true} xs={12} sm={12} md={hasDropDown ? 6 : 12} sx={{ padding: "15px", border: "1px solid #d3d0d0", borderRadius:"8px"   }}>

                      <div className="table-responsive w-100">

                        <table className="modal-table table text-center">
                          <tbody>
                            <>
                              <tr>
                                <th className="disabled-text">مبلغ کل</th>
                                <td> {numberWithCommas(selectedOrder?.totalPrice)}</td>
                              </tr>
                              <tr>
                                <th className="disabled-text">تخفیف</th>
                                <td>{numberWithCommas(selectedOrder?.totalDiscount)}</td>
                              </tr>
                              <tr>
                                <th className="disabled-text">مبلغ حمل و نقل</th>
                                <td>{numberWithCommas(selectedOrder?.shipping?.price)}</td>
                              </tr>
                              <tr>
                                <th className="disabled-text">تخفیف کلی روی فاکتور</th>
                                <td>{numberWithCommas(selectedOrder?.extra_discount)}</td>
                              </tr>
                              <tr className="table-success">
                                <th >مبلغ نهایی</th>
                                <td className="colored-text">{numberWithCommas(selectedOrder?.finalPrice)}</td>
                              </tr>

                            </>
                          </tbody>
                        </table>
                        </div>
                        </Grid>
                     
                    </Grid>
                    </>
)
}
export default OrderDetails;