import { Component } from "react";
import { call_api, parseJSON } from "../../../core/service";
import { toast } from "react-toastify";
import { AppConstants } from "../../../core/constants";
import * as yup from 'yup'

interface IProps {
  dispatch?: any;
  siteList?: any;
  loadingSiteList?: any;
  userData?: any;
}
interface IState {
  loading?: boolean;

  errorsList: any;
  enamadCode: string;
  organizationCode: string;
  newsletterTitle: string;
  placeholder: string;
  footerTitle: string;
  footerDescription: string;
  address: string;
  phone: string;
  copyRight: string;
  search: string;
  dLogo: any;
  mLogo: any;
  toggleModal:any;
  dLogoUpload:string;
  mLogoUpload:string;
  availebledLogo:string;
  dataList: any;
}

class AppearanceSettingsController extends Component<IProps, IState> {
  state: IState = {
    loading: false,
    errorsList: [],
    enamadCode: "",
    organizationCode: "",
    newsletterTitle: "",
    placeholder: "",
    footerTitle: "",
    footerDescription: "",
    address: "",
    phone: "",
    copyRight: "",
    search: "",
    dLogo: "",
    mLogo: "",
    dataList: [],
    toggleModal:{status: false, index:null},
    dLogoUpload:"",
    mLogoUpload:"",
    availebledLogo:"",
  };
  async getDate() {
    let parameters = {
      db_name: window.location.pathname.split("/")[3],
    };
    let url = new URL(`${AppConstants.base_url_api}admin/setting/outward`);
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value);
      }
    }
    call_api({
      address_api: url.search
        ? `${AppConstants.base_url_api}admin/setting/outward${url.search}`
        : `${AppConstants.base_url_api}admin/setting/outward`,
      method_api: "GET",
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
            dataList: j.data[0],
            enamadCode: j.data[0]?.value?.enamad || "",
            organizationCode: j.data[0]?.value?.samandehi || "",
            newsletterTitle: j.data[0]?.value?.newsletter_title || "",
            placeholder: j.data[0]?.value?.newsletter_placeholder|| "",
            footerTitle: j.data[0]?.value?.h1_text || "",
            footerDescription: j.data[0]?.value?.footer_description || "",
            address: j.data[0]?.value?.footer_address || "",
            phone: j.data[0]?.value?.footer_phones || "",
            copyRight: j.data[0]?.value?.footer_copyright || "",
            search: j.data[0]?.value?.header_search_placeholder || "",
            dLogo: j.data[0]?.value?.dlogo ,
            mLogo: j.data[0]?.value?.mlogo,
          });
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async update() {
    const {
      dataList,
      enamadCode,
      organizationCode,
      newsletterTitle,
      placeholder,
      footerTitle,
      footerDescription,
      address,
      phone,
      copyRight,
      search,
      dLogoUpload,
      mLogoUpload
    } = this.state;


    const redirectsSchema = yup.object({
      copyRight:yup.string().max(255, ' نباید بیشتر از 255 کاراکتر باشد'),
      search:yup.string().max(255,' نباید بیشتر از 255 کاراکتر باشد'),
      newsletterTitle:yup.string().max(255,' نباید بیشتر از 255 کاراکتر باشد'),
      footerTitle:yup.string().max(255,' نباید بیشتر از 255 کاراکتر باشد'),
      placeholder:yup.string(),
      organizationCode:yup.string(),
      enamadCode:yup.string(),
      phone:yup.string(),
      address:yup.string(),
      footerDescription:yup.string(),

  });
  try {
      
    await redirectsSchema.validate({ enamadCode,organizationCode,placeholder ,newsletterTitle , footerTitle , footerDescription,address , phone , copyRight , search}, { abortEarly: false });


    const data = new FormData()
    data.append('enamad', enamadCode)
    data.append('samandehi', organizationCode)
    data.append('newsletter_title', newsletterTitle)
    data.append('newsletter_placeholder', placeholder)
    data.append('h1_text', footerTitle)
    data.append('footer_description', footerDescription)
    data.append('footer_address', address)
    data.append('footer_phones', phone)
    data.append('footer_copyright', copyRight)
    data.append('header_search_placeholder',search)
    // if (availebleProfileImage === "") {
      dLogoUpload &&  data.append('dlogo', dLogoUpload)
    // }
    // if (availebleProfileImage === "") {
      mLogoUpload && data.append('mlogo', mLogoUpload)
    // }
    call_api({
      address_api: `${AppConstants.base_url_api}admin/setting/outward/${dataList.id_select}`,
      method_api: "POST",
      body: data,
      file: true,
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          toast.success("با موفقیت انجام شد ");
        this.setState({ errorsList: [] })      
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }
      catch (err: any) {
        this.setState({ errorsList: err.inner })      
      }
  }
  handleDeleteImage = (index:string) => {
    this.setState({
      toggleModal: {
        status:true,
        index:index
      }
    })
  }
  async uploadDLogo(event: any) {
  let news_image_file = null;
  news_image_file = event.target.files[0];
  const reader = new FileReader();
  reader.readAsDataURL(news_image_file);
  reader.onloadend = () => {
    this.setState({ dLogo: reader.result });
  }
  if (news_image_file) {
    this.setState({ dLogoUpload: news_image_file });
  }
  this.setState({ availebledLogo: "" });
  }
  async uploadMLogo(event: any) {
    let news_image_file = null;
    news_image_file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(news_image_file);
    reader.onloadend = () => {
      this.setState({ mLogo: reader.result });
    }
    if (news_image_file) {
      this.setState({ mLogoUpload: news_image_file });
    }
    this.setState({ availebledLogo: "" });
    }

    deleteMLogo = () => {
        this.setState({
          mLogo: "",
          mLogoUpload: "",
          toggleModal:{status: false, index:null}
        })
    }

    deleteDLogo = () => {
        this.setState({
          dLogo: "",
          dLogoUpload:"",
          toggleModal:{status: false, index:null}
        })
    }
}

export default AppearanceSettingsController;