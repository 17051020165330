import { Component } from 'react';
import { call_api, parseJSON } from "../../../core/service";
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { AppConstants } from '../../../core/constants';
import { totalOrderCount, totalOrderDiscount, totoalOrderFinalPrice, totalOrdersPrice } from "../../../core/utils";

interface IProps {
  dispatch?:any,
  siteList?:any,
  loadingSiteList?:any,
}

interface IState {
  loading?: boolean,
  userLoading?: boolean,
  search: string,
  rows: Array<any>,
  userSelected: any,
  productListData: any,
  profileListData: any,
  isCreating: boolean,
  driversId: any,
  errorsList?: any,
  profileImage?: any,
  profileImageUpload?: any,
  currentItem: any,
  productModal:boolean,
  total:number
  rowCount:any,
  rowCountList:any,
  page: number,
  openNewUserModal:boolean,
}
 class UpdatePreFactorController extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      userLoading: false,
        search: "",
        rows: [],
        userSelected: null,
        productListData: [],
        profileListData: [],
        isCreating: false,
        driversId: null,
        errorsList: [],
        profileImage: "",
        profileImageUpload: "",
        currentItem: null, 
        productModal:false,
        total:0,
        rowCount:100,
        rowCountList:[10, 25 , 50 , 100],
        page: 1,
        openNewUserModal:false,
  }
  }
 
  handleDeleteProfileImage = () => {
    this.setState({
      profileImage: "", profileImageUpload: ""
    })

  }

  async getProductList(dbName?:string) {
    const {search,driversId, rowCount, page} =this.state;
    this.setState({ loading: true, productListData: [] })
    let parameters = {
      search: search ? search : null,
      page: page ? page.toString() : null,
      db_name: dbName ? dbName : driversId?.value?.name,
      paginate: rowCount,
      
    }
    let url = new URL(`${AppConstants.base_url_api}seller/products`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    this.setState({ loading: true })
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}seller/products${url.search}` : `${AppConstants.base_url_api}seller/products`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          if(j.data){

            this.setState({
              productListData: j.data?.data, loading: false , total: j.data?.total
            })
          }
          this.setState({ loading: false })
        } else if (status === 401) {
          this.setState({ loading: false })
          localStorage.clear();
        } else {
          this.setState({ loading: false })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
 
  async handleProfileImageUpload(event: any) {
    let news_image_file = null;
    news_image_file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(news_image_file);
    reader.onloadend = () => {
      this.setState({ profileImage: reader.result });
    }
    if (news_image_file) {
      this.setState({ profileImageUpload: news_image_file });
    }
  }
  async getProfilesList(search?:string,dbName?:string) {
    this.setState({ userLoading: true })
    let parameters = {
      search: search ? search.toString(): null,
      db_name:dbName?dbName:this.state.driversId?.value?.name,
    }
    let url = new URL(`${AppConstants.base_url_api}seller/users-all-no-paginate`)

    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}seller/users-all-no-paginate${url.search}` : `${AppConstants.base_url_api}seller/users-all-no-paginate`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
           userLoading: false 
          })
          if(j.data){
            this.setState({
              profileListData: j.data
            })
            this.setState({ userSelected: j.data[0] })
          }
        } else if (status === 401) {
          this.setState({ userLoading: false })
          localStorage.clear();
        } else {
          this.setState({ userLoading: false })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  searchTimeout: string | number | NodeJS.Timeout | undefined;
    handleSearch = (event: any) => {
      this.setState({ search: event.currentTarget.value, page: 1 })
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.getProductList()
      }, 1000);
    };

    handleAdd = (data: any) => {
      const {driversId} = this.state;
      if(driversId === null)
      {
        toast.error("لطفا یک سایت را انتخاب کنید.")
      }
      else{
        const { rows } = this.state;
        var found = false;
        const updateConversationInfo = [...this.state.rows]
        for (var i = 0; i < rows.length; i++) {
          if (rows[i].product_id === data?.id) {
          updateConversationInfo.splice(i,1)
          this.setState({ rows: updateConversationInfo });
            // toast.error("این محصول در لیست محصولات انتخابی شما وجود دارد ")
            found = true;
            break;
          }
        }
        if (!found) {
          const obj = {
            product_name: data.product_name,
            product_id: data.id,
            count: data.count || 1,
            price: data.price_by_discount.price,
            discount: data.price_by_discount.discount,
            finalPrice: data.price_by_discount.finalPrice,
            error:""
          }
          updateConversationInfo.push(obj);
          this.setState({ rows: updateConversationInfo });
        }
      }
    }
  tableRowRemove = (index: number) => {
    const dataRow = [...this.state.rows];
    dataRow.splice(index, 1);
    this.setState({ rows: dataRow });
  };
  onValUpdate = (i: number, event: any) => {

    const { name, value } = event.target;
    const data = [...this.state.rows];
    var newValue = value.replace(
        /,/g,
        ""
      );
      const re = /^[0-9\b]+$/;
      if (newValue === "" || re.test(newValue)) {
        data[i].error = "" 
        let countItem:number =  Number(data[i]?.count);
  
        if(name === "discount" ){
          data[i][name] = newValue;
          if(Number(newValue) < Number(data[i]["price"]))
          {
            data[i].error = ""
          }
          else{

            data[i].error += "تخفیف نمی‌تواند بزرگ‌تر یا مساوی با قیمت باشد"
          }
        } 
        else if(name === "price"){
          
            if(Number(newValue) > Number(data[i]["discount"] ))
            {
              data[i][name] = newValue;
              data[i].error = ""
            }
            else{
              data[i].error = ""  

              if(Number(data[i]?.discount) < 99)
              {              
                data[i][name] = newValue;
              }
              else
            { 
              data[i][name] = newValue;
              data[i].error += "قیمت نمی‌تواند کوچک‌تر یا مساوی با تخفیف باشد"
                
            }
            }
          }
        else if(name === "count"){
          if(Number(newValue) > 0){

            countItem = Number(newValue) 
            data[i][name] = newValue;

          }else{
            countItem = 1
            data[i][name] = 1
          }
        }
        if (Number(data[i]?.discount) > 99) {
            data[i]["finalPrice"] = (Number(data[i]?.price) -  Number(data[i]?.discount)) * countItem ; 
          } 
        else {
            data[i]["finalPrice"] = (Number(data[i]?.price) * countItem) - Number(Number(Number(data[i]?.price * Number(data[i]?.discount)) / 100) * countItem)  ;
            
          }
      }
    this.setState({ rows: data });

  };
  userSelected = (choice: any) => {
      this.setState({ userSelected: choice })
  }

  searchUserTimeout: string | number | NodeJS.Timeout | undefined;
  handleSearchUser = (choice: string) => { 
    clearTimeout(this.searchUserTimeout);
    if(choice.length > 2)
    this.searchUserTimeout = setTimeout(() => {
      this.getProfilesList(choice)
    }, 1000);
  }
  handleSubmitFactor = () => {
    const { userSelected, rows, driversId } = this.state;
    
     if (userSelected === null || Object.keys(userSelected).length === 0) {
      toast.error("هیچ کاربری انتخاب یا ثبت نام نکرده اید.")

    } else if (rows.length === 0) {
      toast.error("هیچ محصولی در لیست سفارش وارد نشده")
    } else if (Object.keys(driversId).length === 0) {
      toast.error("هیچ سایتی وارد نشده!")
    } else {
      this.createPrefactor()
    }
  }

  async createPrefactor() {
    let errorsLists = [];
    const { userSelected, rows, driversId } = this.state;
    for(var i = 0; i< rows.length; i++){
          if(rows[i].error && rows[i].error !== "")
          {
            errorsLists.push(rows[i].error)
          }
    }
    if(errorsLists.length === 0){
    if(this.state.rows.length > 0){
      this.setState({ isCreating: true })
    const prefactorValidation = yup.object({
      userSelected: yup.object().required("انتخاب کاربر الزامی می باشد "),
      driversId: yup.object().required("انتخاب سایت الزامی است."),
    });
    try {
    await prefactorValidation.validate({ userSelected,driversId}, { abortEarly: false });

    const productSelected = []
    for (var i = 0; i < rows.length; i++) {
      const obj = {
        product_id: rows[i].product_id,
        count: rows[i].count,
        price:rows[i].price,
        discount:rows[i].discount
      }
      productSelected.push(obj);
    }
    call_api({
      address_api: `${AppConstants.base_url_api}seller/pre-factors/${window.location.pathname.split("/")[2]}`,
      method_api: "PUT",
      body: JSON.stringify({
        products: JSON.stringify(productSelected),
        name: userSelected?.profile?.name,
        family: userSelected?.profile?.family,
        mobile: userSelected?.mobile,
        user_id: userSelected?.id,
        totalPrice : totalOrdersPrice(rows),
        totalDiscount : totalOrderDiscount(rows),
        totalCount : totalOrderCount(rows),
        finalPrice : totoalOrderFinalPrice(rows),

      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 201) {
          toast.success(j?.message);
          this.setState({ isCreating: false ,
             userSelected: {} ,
             driversId: [] ,
           search: "", 
           rows: [],
           errorsList:[]

          })
          window.location.href = "/prefactor";
        } else if (status === 401) {
          this.setState({ isCreating: false })
          toast.error(j?.error?.message);
          localStorage.clear();
        } else {
          console.warn(status, j);
          this.setState({ isCreating: false })
          toast.error(j?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }
    catch (err: any) {
      this.setState({ isCreating: false, errorsList: err.inner  })
    }
  }else{
    toast.error("حداقل یک محصول را انتخاب کنید");
  }
}
else {
  toast.error("لطفاً به مقادیر قیمت یا تخفیف دقت نمایید!")
}
  };
  openProductModal = () => {
    this.setState({ productModal: true })
  }
  handleCloseProductModal = () => {
    this.setState({ productModal: false })
  }
  paginationTimeOut: string | number | NodeJS.Timeout | undefined; 
  handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ page: value })
    clearTimeout(this.paginationTimeOut);
    this.paginationTimeOut = setTimeout(() => {
      this.getProductList()
    }, 800);
  };
  paginationSelectTimeOut: string | number | NodeJS.Timeout | undefined; 
  handlePaginationSelect=(event:any)=>{
    this.setState({
      rowCount: event.currentTarget.value,  page:1
    })
    clearTimeout(this.paginationSelectTimeOut);
    this.paginationSelectTimeOut = setTimeout(() => {
      this.getProductList()
    }, 800);
    
  }

 getPrefactor=()=> {

    call_api({
      address_api: `${AppConstants.base_url_api}seller/pre-factors/${window.location.pathname.split("/")[2]}`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.getProfilesList(j?.data?.mobile,j?.data?.db_name)
          this.setState({ driversId:this.props.siteList?.filter((obj: any) => obj?.value?.name === j?.data?.db_name)[0] })
          this.getProductList(j?.data?.db_name)
          

          const updateConversationInfo = [...this.state.rows]
          for (var i = 0; i < j?.data?.preFactorProducts.length; i++) {
        
            const found = updateConversationInfo.some(el => el.product_id ===  j.data.preFactorProducts[i].product_id);
              if (!found) {
              const obj = {
                product_id: j.data.preFactorProducts[i]?.product_id,
                product_name: j.data.preFactorProducts[i]?.product_name,
                count: j.data.preFactorProducts[i]?.count || 1,
                price: j.data.preFactorProducts[i]?.price_by_discount?.price,
                discount: j.data.preFactorProducts[i]?.price_by_discount?.discount,
                finalPrice: j.data.preFactorProducts[i]?.price_by_discount?.finalPrice
              }
              updateConversationInfo.push(obj);
              this.setState({ rows: updateConversationInfo });
            }
          }
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export default UpdatePreFactorController;