import { Link } from "react-router-dom";
import { InputTextLabel, ButtonOutline, CustomButton, OtpInput } from "../../../components";
import { toFarsiNumber } from "../../../../core/utils";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {LoginController} from "../../../controllers";
import { BiHide, BiShowAlt } from "react-icons/bi";
import { connect } from "react-redux";
class Login extends LoginController {
  componentDidMount() {
    window.scrollTo(0, 0);


  }
  render() {
    const { phone, password, otpCode, showPassword, status, errorsList, minutes, seconds, resend , loading} = this.state;
    return (
      <HelmetProvider>

      <div
        className="login"
      >
        <Helmet>
                <title>ورود   </title>
            </Helmet>
        {/* <img src={LogoLight} className="login-icon" /> */}
        <div className="login-modal">
          <div className="login-modal-header">
            <h4 className="login-modal-header-title">ورود به پنل</h4>
            {status === 0 &&
              <Link to="/register">
                <ButtonOutline title={"ثبت نام"} />
              </Link>
            }
          </div>
          <div className="login-modal-input-group">
            {status === 0 &&
              <div className="login-modal-input-group-email">
                <InputTextLabel
                  value={phone}
                  placeholder={"شماره موبایل خود را وارد کنید"}
                  type={"number"}
                  name="phone"
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "phone")[0]?.message}
                  onKeyDown={this.handleKeyPressDown}
                  onChange={(event) =>
                    this.setState({ phone: event.currentTarget.value })
                  }
                />
              </div>
            }
            {status === 1 &&
              <>
                <div className="login-modal-input-group-password">
                  <InputTextLabel
                    value={password}
                    placeholder={"رمز خود را وارد کنید"}
                    type={showPassword ? "text" : "password"}
                    icon={showPassword ? <BiHide/> : <BiShowAlt />}
                    onClick={() => this.setState({showPassword:  !showPassword })}
                    name="password"
                    errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "password")[0]?.message}
                    onChange={(event) =>
                      this.setState({ password: event.currentTarget.value })
                    }
                    onKeyDown={this.handleKeyPressDown}
                  />
                </div>
                <div>
                  <div className="login-modal-otp-code" onClick={()=>this.handleChangeForgetPassword()}>
                    ارسال رمز یکبار مصرف
                  </div>
                  <div className="login-modal-forgot-passsword" >
                    <span onClick={() => this.handleChangeForgetPassword()} className="login-modal-forgot-passsword-link">
                      فراموشی رمز عبور
                    </span>
                  </div>
                </div>
              </>
            }
            {status === 2 &&

              <div className="login-modal-input-group-password">
                {/* <InputTextLabel
                  value={otpCode}
                  placeholder={"رمز یک بار مصرف خود را وارد نمایید"}
                  type={"number"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "otpCode")[0]?.message}
                  onChange={(event) =>
                    this.setState({ otpCode: event.currentTarget.value })
                  }
                  onKeyDown={this.handleOtpdKeyPressDown}

                  
                /> */}
                      <OtpInput value={otpCode}
                       valueLength={6} onChange={ (value: string) =>
                        this.setState({ otpCode: value })} onKeyDown={this.handleOtpdKeyPressDown}
                        />
                    <span className="error-text">{errorsList?.filter((obj: any) => obj.path === "otpCode")[0]?.message}</span>
              </div>
            }
          </div>

          <div className="login-modal-button">
            {status === 2 ?
              <CustomButton loading={loading} onClick={() => resend ? this.handleResendCode() : this.otpLogin()}
                title={`${resend ? " ارسال مجدد کد " : `${toFarsiNumber(minutes)} ${minutes > 0 ? ":" : " "} ${toFarsiNumber(seconds) < 10 ? toFarsiNumber("0") + toFarsiNumber(seconds) : toFarsiNumber(seconds)} `}`} />

              :
              <CustomButton loading={loading}
                onClick={() => {
                  status === 0 ? this.handleLogin() : this.handleSendPassword()
                }}
                title={status === 0 ? "ورود" : "رمز خود را وارد کنید "} />
            }
          </div>
        </div>
      </div>
      </HelmetProvider>
    );
  }
}

const mapStateToProps = (dispatch:any) => {
  return {
    dispatch,
    siteList:dispatch.general.siteList,
    loadingSiteList:dispatch.general.loadingSiteList,
    userData:dispatch.general.userData,
  }
};

export default connect(mapStateToProps)(Login);