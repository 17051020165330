import * as React from "react";
import { InputHTMLAttributes , ReactElement, useState } from "react";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { logout } from "../../core/service";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { menuItems } from "../../core/constants";
import { Drawer } from '@mui/material';
import { AiOutlineClose} from "react-icons/ai";
import {RxHamburgerMenu} from "react-icons/rx";
import SideBar from "./side_bar";
import { Logo } from "../../assets";
interface Props extends InputHTMLAttributes<HTMLInputElement> {
  userData?: any;
  dispatch?: any;
  title?:string;
  isActive?:string;
  siteList:any;
}
  const Header=({ userData , title, isActive, siteList}: Props): ReactElement => {
  const [anchorElUser4, setAnchorElUser4] = React.useState(null);
  const [open, setOpen] = useState(false);
  return (
    <>
     <div className="header">
       <div className="header-right-side">
   { userData?.roles && userData?.roles[0]?.name === "admin" &&
      <>
        <Drawer sx={{width:"100px"}} anchor={"right"} open={open} onClose={() => setOpen(false)}>
          <AiOutlineClose className="icon-gray side-bar-close-button" onClick={() => setOpen(false)}/>
          <SideBar isActive={isActive}/>
        </Drawer>
      <RxHamburgerMenu className="icon-gray" onClick={() => setOpen(true)}/>
      </>
  }
        <Link aria-label="move-to-home" to={userData?.roles &&  userData?.roles[0]?.name === "admin" ? "/admin/home" : "/home"}>
            <span className="header-title">
              <img width="66" height="66" src={Logo} alt="" />
            </span>
          </Link>
          {userData?.roles &&  userData?.roles[0]?.name === "admin" ? 
            <>
            <span id="1" className="header-title">
            <div className="header-options" >
              <Link className={`${window.location.pathname === "/admin/landing/products" ? "is-active" : "not-active"}`} to={`/admin/landing/${menuItems.filter((item:any) => item.route === "products")[0]?.route}`}>
                محصولات
              </Link>
            </div>
          </span>
          <span id="2" className="header-title">
          <div className="header-options" >
            <Link className={`${window.location.pathname === "/admin/landing/management" ? "is-active" : "not-active"}`} to={`/admin/landing/${menuItems.filter((item:any) => item.route === "management")[0]?.route}`}>
              مدیریت‌ها
            </Link>
          </div>
          </span>
          </>: 
          <>
            <span id="1" className="header-title">
              <div className="header-options" >
                <Link className={`${window.location.pathname === "/seller/landing/sell" ? "is-active" : "not-active"}`} to={`/seller/landing/${menuItems.filter((item:any) => item.route === "sell")[0]?.route}`}>
                  فروش
                </Link>
              </div>
            </span>
            <span id="2" className="header-title">
            <div className="header-options" >
              <Link className={`${window.location.pathname === "/seller/landing/store" ? "is-active" : "not-active"}`} to={`/seller/landing/${menuItems.filter((item:any) => item.route === "store")[0]?.route}`}>
                انبارداری
              </Link>
            </div>
            </span>
            <span id="3" className="header-title">
              <div className="header-options">
                <Link className={`${window.location.pathname === "/seller/landing/settings" ? "is-active" : "not-active"}`} to={`/seller/landing/${menuItems.filter((item:any) => item.route === "settings")[0]?.route}`}>
                    تنظیمات و امکانات
                </Link>
              </div>
            </span>
            </>
}
       </div>
       <span className="header-options"> {title} </span>
       <div className="header-left-side ">
          <div>
            <div className="header-title date">
              {new Intl.DateTimeFormat("fa-IR").format(new Date())}
            </div>
          </div>
          <div className="me-3">
            <Avatar
              sx={{ cursor: "pointer" }}
              alt={userData?.profile?.full_name}
              src={userData?.profile?.avatar}
              onClick={(event: any) => setAnchorElUser4(event.currentTarget)}/>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser4}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"}}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right"}}
              open={Boolean(anchorElUser4)}
              onClose={() => setAnchorElUser4(null)}>
              <MenuItem>
                <Link className="sub-menu" to="/home">پروفایل کاربری</Link>
              </MenuItem>
              <MenuItem>
                <Link className="sub-menu" to="/profile">ویرایش پروفایل</Link>
              </MenuItem>
              <MenuItem onClick={() => logout()}>خروج</MenuItem>
            </Menu>
          </div>
          <div className="role">
            <div className="header-title">{userData?.profile?.full_name}</div>
            <div className="header-title">
              {userData?.roles && userData?.roles[0].persian_name}
          </div>
        </div>
       </div>
      </div>
    </>
  );
}
const mapStateToProps = (dispatch: any) => {
  return {
    dispatch,
    userData: dispatch.general.userData,
    siteList:dispatch.general.siteList,
  };
};
export default connect(mapStateToProps)(Header);
