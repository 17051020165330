import {ProfileController} from "../../../controllers";
import { BsFillTrashFill } from "@react-icons/all-files/bs/BsFillTrashFill";
import { FaUpload } from "@react-icons/all-files/fa/FaUpload";
import { CustomButton, LoadingSpinner, Header,InputIcon } from "../../../components";
import { BsInstagram, BsWhatsapp } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import moment from 'moment-jalaali';
import { fetchSiteList, fetchUserData } from "../../../../Redux/services/generalService";
import { connect } from "react-redux";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BiShowAlt, BiHide } from "react-icons/bi";
import { Avatar, FormControlLabel, Switch } from "@mui/material";

class Profile extends ProfileController {
  componentDidMount() {
    this.getUserDetails();
    window.scrollTo(0, 0);
    this.props.dispatch(fetchSiteList());
    this.props.dispatch(fetchUserData());

  }
  render() {
    const { setPasswordmodal, errorsList, showPasswordOtp, loadingUpdateProfile, loading,
      profileImage, availebleProfileImage, mobile, userRole, registeryDate, firstName, lastName, gender, email,
      phone, newPassword, repeatPassword, showPassword, telegram, whatsapp, instagram, otpCode, loadingChangePassword } = this.state;
      return (
        <HelmetProvider>
      <div className="profile">
         <Helmet>
                <title> پروفایل کاربری  </title>
            </Helmet>
          <Header title="پروفایل کاربری" />
        <>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="profile-card">
                
                <>
                <div className="row">
                      <div className="col-md-2 pb-3 d-flex align-items-center col-sm-12  pe-2">
                        {profileImage ? (
                          <div className="image-upload-card">
                            <div
                            className="action"
                              onClick={() => this.deleteNewImage()}
                            >
                          <BsFillTrashFill className="icon-red"  />
                            </div>
                            <Avatar
                              sx={{ height: "110px",
                              width: "110px",
                              display: "inline-block" }}
                              alt={"profile"}
                              src={profileImage} />
                            {/* <img alt="" src={profileImage} className="img" /> */}
                          </div>
                        ) : availebleProfileImage ? (
                          <div className="image-upload-card">
                            <div className="action">
                              <input
                              name=""
                              type="file" id="file"
                              hidden
                              accept="image/*"
                              onChange={(event) =>
                                this.handleProfileImageUpload(event)
                              }
                            />
                             <label  htmlFor="file" className="upload-lable mx-1">                           
                            <FaUpload className="icon-green"/>
                            </label>
                          <BsFillTrashFill className="icon-red" onClick={() => this.handleDeleteProfileImage()}/>

                            </div>
                            <Avatar
                            sx={{ height: "110px",
                            width: "110px",
                            display: "inline-block" }}
                            alt={"profile"}
                            src={availebleProfileImage} />
                            {/* <img alt="" src={availebleProfileImage} className="img" /> */}
                          </div>
                        ) :  <div className="upload-container">
                        <div className="upload-input">
                          <input
                              name=""
                              type="file"
                              id="file"
                              hidden
                              accept="image/*"
                              onChange={(event) =>
                                this.handleProfileImageUpload(event)
                              }
                            />
                          <label htmlFor="file" className="upload-lable">
                          
                            <FaUpload className="icon-green"/>
                            <span className="mx-2">عکس پروفایل</span>
                          </label>
                        </div>
                        <span className="input-icon-error">
                          {this.state?.errorsList?.picture}
                        </span>
                      </div>}
                      </div>
                      <div className="col-lg-5 d-flex align-items-center col-md-12">
                        <InputIcon
                          value={mobile}
                          title={"موبایل "}
                          inBox={"موبایل"}
                          type={"text"}
                          disabled={true}
                          onChange={(event) =>
                            this.setState({
                              mobile: event.currentTarget.value,
                            })
                          }
                          name="mobile"
                        />
                      </div>
                      <div className="col-lg-5 d-flex align-items-center col-md-12 pe-2">
                        <InputIcon
                          value={userRole
                          }
                          title={" نقش کاربری"}
                          inBox={" نقش کاربری"}
                          type={"text"}
                          disabled={true}
                          onChange={(event) =>
                            this.setState({
                              userRole: event.currentTarget.value,
                            })
                          }
                          name="role"
                        />
                      </div>
                      
                    </div>
                  <div className="row">
                   
                    <div className="col-md-4 col-sm-12 pe-3">
                      <InputIcon
                        value={firstName}
                        title={"نام "}
                        inBox={"نام  "}
                        type={"text"}
                        name="firstName"
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "firstName")[0]?.message}
                        onChange={(event) =>
                          this.setState({
                            firstName: event.currentTarget.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4 col-sm-12 pe-3">
                      <InputIcon
                        value={lastName}
                        title={"نام خانوادگی  "}
                        inBox={"نام خانوادگی  "}
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "lastName")[0]?.message}
                        type={"text"}
                        name="lastName"
                        onChange={(event) =>
                          this.setState({
                            lastName: event.currentTarget.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4 d-flex align-items-center col-sm-12 pe-2">
                        <InputIcon
                          value={moment(registeryDate, "YYYY-MM-DD").format("jYYYY/jM/jD")
                          }
                          title={"تاریخ عضویت "}
                          inBox={"تاریخ عضویت "}
                          type={"text"}
                          disabled={true}
                          onChange={(event) =>
                            this.setState({
                              registeryDate: event.currentTarget.value,
                            })
                          }
                          name="date"
                        />
                      </div>
                  </div>
                  <div className="row">
                  
                    <div className="col-md-4 col-sm-12 pe-3">
                      <InputIcon
                        value={email}
                        title={"ایمیل"}
                        inBox={"ایمیل"}
                        type={"text"}
                        name="email"
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "email")[0]?.message}
                        onChange={(event) =>
                          this.setState({ email: event.currentTarget.value })
                        }
                      />
                    </div>
                    <div className="col-md-4 col-sm-12 pe-3">
                      <InputIcon
                        value={phone}
                        title={"تلفن "}
                        inBox={"تلفن "}
                        type={"text"}
                        name="phone"
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "phone")[0]?.message}
                        onChange={(event) =>
                          this.setState({ phone: event.currentTarget.value })
                        }
                      />
                    </div>
                    <div className="col-md-4 d-flex align-items-center justify-content-center col-sm-12 pe-3">
                      <FormControlLabel sx={{direction:"ltr", marginBottom:"10px"}} control={<Switch
                        checked={gender}
                        onChange={(event:any)=> this.setState({ gender: event.target.checked }) }
                        inputProps={{ 'aria-label': 'controlled' }}
                     />} 
                     label="زن" />
                    </div>
                  </div>
                  <div className="row">
                    <>
                      {/* <div className="col-md-4 col-sm-12 pe-3">
                    <DropDownIcon
                      value={automaticSave}
                      title={"ذخیره اتوماتیک"}
                      inBox={"ذخیره اتوماتیک"}
                      type={"text"}
                      optionList={["هر 5 دقیقه", "هر ده دقیقه"]}

                      errortext={this.state?.errorsList?.postalcode}
                      onChange={(event) =>
                        this.setState({
                          automaticSave: event.currentTarget.value,
                        })
                      }
                    />
                    </div>
                    <div className="col-md-4 col-sm-12 pe-3">
                      <InputIcon
                        value={localPassword}
                        title={"رمز محلی "}
                        inBox={"رمز محلی  "}
                        type={"text"}
                        errortext={this.state?.errorsList?.postalcode}
                        onChange={(event) =>
                          this.setState({
                            localPassword: event.currentTarget.value,
                          })
                        }
                      />
                    </div> */}
                    </>
                    {!setPasswordmodal &&
                      <>
                        <div className="col-md-6 col-sm-12 pe-3">
                          <InputIcon
                            value={newPassword}
                            title={"رمز عبور جدید "}
                            inBox={"رمز عبور جدید  "}
                            icon = {showPassword.newPassword ? <BiHide/> : <BiShowAlt />}
                            type={showPassword.newPassword ? "text" : "password"}
                            onClick={() => this.setState({showPassword: {...showPassword, newPassword: !showPassword.newPassword }})}
                            name="newPassword"
                            errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "newPassword")[0]?.message}
                            onChange={(event) =>
                              this.setState({
                                newPassword: event.currentTarget.value,
                              })
                            }
                          />
                        </div>
                        <div className="col-md-6 col-sm-12 pe-3">
                          <InputIcon
                            value={repeatPassword}
                            title={"تکرار رمز عبور "}
                            inBox={"تکرار رمز عبور  "}
                            type={showPassword.repeatPassword ? "text" : "password"}
                            icon={showPassword.repeatPassword ? <BiHide/> : <BiShowAlt />}
                            name="repeatPassword"
                            onClick={() => this.setState({showPassword: {...showPassword, repeatPassword: !showPassword.repeatPassword }})}
                            errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "repeatPassword")[0]?.message}
                            onChange={(event) =>
                              this.setState({
                                repeatPassword: event.currentTarget.value,
                              })
                            } />
                        </div>
                      </>
                    }
                  </div>
                  <hr className="profile-hr" />
                  <div className="row">
                    <div className="col-md-4 col-sm-12 pe-3">
                      <InputIcon
                        value={telegram}
                        title={"تلگرام "}
                        inBox={<FaTelegramPlane />}
                        type={"text"}
                        name="telegram"
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "telegram")[0]?.message}
                        onChange={(event) =>
                          this.setState({
                            telegram: event.currentTarget.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4 col-sm-12 pe-3">
                      <InputIcon
                        value={whatsapp}
                        title={"واتس اپ "}
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "whatsapp")[0]?.message}
                        inBox={<BsWhatsapp />}
                        type={"text"}
                        name="whatsapp"
                        onChange={(event) =>
                          this.setState({
                            whatsapp: event.currentTarget.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4 col-sm-12 pe-3">
                      <InputIcon
                        value={instagram}
                        title={"اینستاگرام   "}
                        name="instagram"
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "instagram")[0]?.message}
                        inBox={<BsInstagram />}
                        type={"text"}

                        onChange={(event) =>
                          this.setState({
                            instagram: event.currentTarget.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </>
                <div className="d-flex justify-content-end">
                  <CustomButton
                    myStyle="stepper-save"
                    loading={loadingUpdateProfile}
                    onClick={() => this.updatetUser()}
                    title="ذخیره "
                  />
                </div>
              </div>
            </>
          )}
          <Modal
            open={setPasswordmodal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="submission-alert">
              {showPasswordOtp ?
                <InputIcon
                  value={otpCode}
                  title={"رمز یک بار مصرف"}
                  placeholder={"رمز یک بار مصرف "}
                  type={"number"}
                  name="otpCode"
                  onKeyDown={this.handleKeyPressDown}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "otpCode")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      otpCode: event.currentTarget.value,
                    })
                  }
                />
                :
                <>
                  <InputIcon
                    value={newPassword}
                    title={"رمز عبور جدید "}
                    placeholder={"رمز عبور جدید  "}
                    name="newPassword"
                    type={showPassword.modalNewPassword ? "text" : "password"}
                    icon={showPassword.modalNewPassword ? <BiHide/> : <BiShowAlt />}
                    onClick={() => this.setState({showPassword: {...showPassword, modalNewPassword: !showPassword.modalNewPassword }})}
                    errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "newPassword")[0]?.message}
                    onChange={(event) =>
                      this.setState({
                        newPassword: event.currentTarget.value,
                      })
                    }
                  />
                  <InputIcon
                    value={repeatPassword}
                    title={"تکرار رمز عبور "}
                    placeholder={"تکرار رمز عبور  "}
                    type={showPassword.modalRepeatPassword ? "text" : "password"}
                    icon={showPassword.modalRepeatPassword ? <BiHide/> : <BiShowAlt />}
                    onClick={() => this.setState({showPassword: {...showPassword, modalRepeatPassword: !showPassword.modalRepeatPassword }})}
                    name="repeatPassword"
                    errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "repeatPassword")[0]?.message}
                    onChange={(event) =>
                      this.setState({
                        repeatPassword: event.currentTarget.value,
                      })
                    }
                  />
                </>
              }
              <div className="col-md-6 col-12">
                <CustomButton
                  myStyle="stepper-save"
                  loading={loadingChangePassword}
                  onClick={() => showPasswordOtp ? this.handlePasswordOtp() : this.handleSetPassword()}
                  title={showPasswordOtp ? " ارسال رمز " : "ایجاد رمز عبور "}
                />
              </div>
            </Box>
          </Modal>
        </>
      </div>
      </HelmetProvider>
    );
  }
}
const mapStateToProps = (dispatch:any) => {
  return {
    dispatch,
    siteList:dispatch.general.siteList,
    loadingSiteList:dispatch.general.loadingSiteList,
    userData:dispatch.general.userData,
  }
};

export default connect(mapStateToProps)(Profile);
