import { toast } from "react-toastify";
import {
  FETCH_GENERAL_DATA,
  FETCH_USER_DATA
} from "../type";
import { call_api, parseJSON } from "../../core/service";
import { AppConstants } from "../../core/constants";
export function sites(details:any) {
  return {
    type: FETCH_GENERAL_DATA,
    payload: details,
  };
}
export function userData(details:any) {
  return {
    type: FETCH_USER_DATA,
    payload: details,
  };
}
export const  fetchSiteList= () => {
  return async function (dispatch?:any) {
        try {
            call_api({
              address_api: `${AppConstants.base_url_api}seller/configs`
            })
              .then(parseJSON)
              .then(([status, j]) => {
                if (status === 200) {  
                dispatch(sites(j.data));
                } else if (status === 401) {
                  localStorage.clear();
                } else {
                  toast.error(j?.errors);
                }
              })
              .catch((error) => {
                console.error(error);
              });
        } catch (error) {
          throw error;
        }
      };
};

export const  fetchUserData= () => {
  return function (dispatch?:any) {
        try {
            call_api({
              address_api: `${AppConstants.base_url_api}seller/users/${window.localStorage.getItem('userId')}`,
            })
              .then(parseJSON)
              .then(([status, j]) => {
                if (status === 200) {  
                  dispatch(userData(j.data));
                } else if (status === 401) {
                  localStorage.clear();
                } else {
                  toast.error(j?.errors);
                }
              })
              .catch((error) => {
                console.error(error);
              });
        } catch (error) {
          throw error;
        }
      };
};


