import {
  FETCH_GENERAL_DATA,
  FETCH_USER_DATA
} from "./../type";

const initialState = {
  loadingSiteList: true,
  siteList: [],
  userData:[],
};

const generalReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_GENERAL_DATA:
      return {
        ...state,
        siteList: action.payload,
        loadingSiteList: false,
      };
      case FETCH_USER_DATA : 
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

export default generalReducer;
