import { Component } from 'react';
import { call_api, parseJSON } from "../../../core/service";
import { toast } from 'react-toastify';
import { AppConstants } from '../../../core/constants';
interface IProps {

}
interface IState {
  loading?: boolean,
  specialCellId: any,
  toggleModal: boolean,
  driversId: any
  driverList: any;
  bestSellingList: any;
  isCreating: boolean;
  productListData: any
  productLoading: boolean;
  selectedIdList: Array<number>;
  brandLoading: boolean;
  emptyDataText:boolean;
}

 class BestSellingController extends Component<IProps, IState> {
  state: IState = {
    loading: false,
    specialCellId: null,
    toggleModal: false,
    driverList: [],
    driversId: null ,
    bestSellingList: [],
    isCreating: false,
    productListData: [],
    productLoading: false,
    selectedIdList: [],
    brandLoading: false,
    emptyDataText:false,
  }
  searchTimeout: string | number | NodeJS.Timeout | undefined;
  handleSearch = (choice: any) => { 
    clearTimeout(this.searchTimeout);
    if(choice.length > 2)
    this.searchTimeout = setTimeout(() => {
      this.getProductList(choice)
    }, 1000);
  }
  handleSelectSpecialSell = (choice: any) => {
    
    const {bestSellingList} = this.state;
    var found = false;
    for( var i = 0; i < bestSellingList.length ; i++){
      if(bestSellingList[i].id === choice?.id){
          found = true;
          break;
      }
    }
    if(!found){
      this.setState({ specialCellId: choice })
      const selectedList = [...bestSellingList];
      selectedList.push(choice);
      this.setState({ bestSellingList: selectedList });
    }
    this.setState({emptyDataText: false})
  }
  driverTimeout: string | number | NodeJS.Timeout | undefined;
  driverSelected = (choice: any) => {
    this.setState({ driversId: choice })
    clearTimeout(this.driverTimeout);
    this.driverTimeout = setTimeout(() => {
      this.getBestSellingList()
      this.setState({ productListData: [] });
      this.setState({ specialCellId: [] });
      this.setState({ bestSellingList: [] });
    }, 1000);
  }
  async handleSubmit() {
    const { driversId, bestSellingList } = this.state;
    if (driversId) {
      const selectedIdList = [];
      for (var i = 0; i < bestSellingList.length; i++) {
        selectedIdList.push(bestSellingList[i]?.id)
      }
      this.setState({ isCreating: true })
      call_api({
        address_api: `${AppConstants.base_url_api}seller/best-selling`,
        method_api: "POST",
        body: JSON.stringify({
          db_name: driversId?.value?.name,
          item: "best",
          field: "best_sell",
          id_products: selectedIdList.toString()
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            this.getBestSellingList();
            this.setState({ isCreating: false, toggleModal: false, selectedIdList:[], specialCellId:null })
            toast.success("با موفقیت انجام شد ");
          } else if (status === 401) {
            localStorage.clear();
            this.setState({ isCreating: false })

          } else {
            toast.error(j?.message);
            this.setState({ isCreating: false })
          }
        })
        .catch((error) => {
          console.error(error);
          this.setState({ isCreating: false })
        });
    } else {
      toast.error("لطفا یک سایت را انتخاب کنید")
    }
  };
  async getBestSellingList() {
    const { driversId } = this.state;
    if (driversId) {
      this.setState({ loading: true })
      let parameters = {
        db_name: driversId?.value?.name,
        field: "best_sell",
      }

      let url = new URL(`${AppConstants.base_url_api}seller/best-selling`)
      for (const [key, value] of Object.entries(parameters)) {
        if (value) {
          url.searchParams.append(key, value)
        }
      }
      call_api({
        address_api: url.search ? `${AppConstants.base_url_api}seller/best-selling${url.search}` : `${AppConstants.base_url_api}seller/best-selling`,
        method_api: "GET"
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            if(j.data){
              this.setState({
                bestSellingList: j.data,
                emptyDataText:false,
                loading: false
              })
            }
            else{
                this.setState({emptyDataText:true, loading: false})
            }
            const selectedIdList = [];
            for (var i = 0; i < j.data.length; i++) {
              selectedIdList.push(j.data[i]?.id)
            }
            this.setState({ selectedIdList: selectedIdList })
          } else if (status === 401) {
            this.setState({ loading: false })
            localStorage.clear();
          } else {
            this.setState({ loading: false })
            toast.error(j?.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    else {
      toast.error("لطفا یک سایت را انتخاب کنید")
    }
  };
  async getProductList(search?:string) {
    this.setState({ productLoading: true })
    const { driversId } = this.state;
    let parameters = {
      db_name: driversId?.value?.name,
      search: search ? search: null,
    }
    let url = new URL(`${AppConstants.base_url_api}seller/product/search`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}seller/product/search${url.search}` : `${AppConstants.base_url_api}seller/product/search`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(async ([status, j]) => {
        if (status === 200) {
          if(j.data){
            this.setState({
              productListData: j.data
            })
          }
          this.setState({
            productLoading: false
          })
        } else if (status === 401) {
          this.setState({ productLoading: false })
          localStorage.clear();
        } else {
          this.setState({ productLoading: false })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  async handleDeleteProduct(item: any, index: number) {
    const { selectedIdList, bestSellingList } = this.state;
    if (selectedIdList.includes(item.id)) {

      call_api({
        address_api: `${AppConstants.base_url_api}seller/best-selling/${item.id}`,
        method_api: "DELETE",
        body: JSON.stringify({
          db_name: item.db_name,
          field: "best_sell",
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            this.setState({ toggleModal: false })
            toast.success("با موفقیت حذف شد ");

            const newSelectedList = [...bestSellingList];
            newSelectedList.splice(index, 1);
            this.setState({ bestSellingList: newSelectedList });

          } else if (status === 401) {
            localStorage.clear();

          } else {
            toast.error(j?.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      const newSelectedList = [...bestSellingList];
      newSelectedList.splice(index, 1);
      this.setState({ bestSellingList: newSelectedList });
      this.setState({ toggleModal: false })
    }
  };
  async getDbList() {
    this.setState({brandLoading: true})
    call_api({
      address_api: `${AppConstants.base_url_api}seller/configs`
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {            
          this.setState({driverList:j.data, brandLoading:false})
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export default BestSellingController;