
import { LoadingSpinner, Header, LableIcon, InputIcon, CustomButton, ImageUploader, CustomContainer, SubmissionAlert } from "../../../components";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { SiteSloganController } from "../../../controllers";
import { BsFlagFill } from "react-icons/bs";
import { FiAlertTriangle } from "react-icons/fi";

class SiteSlogan extends SiteSloganController {
  componentDidMount() {
    this.getPageData()
  }

  render() {
    const { loading ,
       errorsList , 
       toggleModal,
      compressedData
      } = this.state;
    return (
      <>
        <Helmet>
          <title>  شعارهای سایت</title>
        </Helmet>
        <Header title=" شعارهای سایت" />
        {loading ? <LoadingSpinner /> : 
         <>
         {toggleModal.status &&
                 <SubmissionAlert
                      isOpen={toggleModal.status}
                      title="اخطار!"
                      setClose={(open: boolean) =>
                        this.setState({
                          toggleModal: {
                            status: open,
                          },
                        })
                      }
                      onClick={()=> this.deleteImage(toggleModal.name)}
                      icon={<FiAlertTriangle color="orange" fontSize="50" />}
                      details="از حذف این تصویر اطمینان دارید؟"
                    />
  }
          <CustomContainer myStyle=" site-slogan">
         
            
            <LableIcon title=" لوگوی شماره یک" icon={<BsFlagFill />} />
            <div className="site-slogan-item">
            <ImageUploader onClick={() => this.handleDeleteImage("slogan_one_image") } 
            
            itemIndex={`s-1`} onChangeImage={(e:any) => this.handleImageUpload(e,"slogan_one_image")}
             image={compressedData?.slogan_one_image} imageAlt="netran" myStyle="col-md-4 col-sm-12"/>            
                 <div  className="col-md-8 col-sm-12">
                <InputIcon
                  value={compressedData?.slogan_one_title}
                  myStyle="col-md-12 col-sm-12 pe-3"
                  title="عنوان "
                  inBox="عنوان "
                  type={"text"}
                  name={"slogan_one_title"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path.includes("slogan_one_title"))[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      compressedData: {...compressedData, slogan_one_title: event.currentTarget.value},
                    })
                  }
                />
              <InputIcon
                  value={compressedData?.slogan_one_description}
                  myStyle="col-md-12 col-sm-12 pe-3"
                  title="توضیحات"
                  inBox="توضیحات"
                  type={"text"}
                  name={"slogan_one_description"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path.includes("slogan_one_description"))[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      compressedData: {...compressedData, slogan_one_description: event.currentTarget.value},
                    })
                  }
                />
                <InputIcon
                  value={compressedData?.slogan_one_url}
                  myStyle="col-md-12 col-sm-12 pe-3"
                  title="لینک"
                  inBox="لینک"
                  type={"text"}
                  name={"slogan_one_url"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path.includes("slogan_one_url"))[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      compressedData: {...compressedData, slogan_one_url: event.currentTarget.value},
                    })
                  }
                />
                </div>
            </div>
            <LableIcon title=" لوگوی شماره دو" icon={<BsFlagFill />} />
            <div className="site-slogan-item">
            <ImageUploader onClick={() => this.handleDeleteImage("slogan_two_image") } 
            itemIndex={`s-2`} onChangeImage={(e:any) => this.handleImageUpload(e,"slogan_two_image")}
             image={compressedData?.slogan_two_image}  imageAlt="netran" myStyle="col-md-4 col-sm-12"/>
                <div  className="col-md-8 col-sm-12">
                <InputIcon
                  value={compressedData?.slogan_two_title}
                  myStyle="col-md-12 col-sm-12 pe-3"
                  title="عنوان "
                  inBox="عنوان "
                  type={"text"}
                  name={"slogan_two_title"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path.includes("slogan_two_title"))[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      compressedData: {...compressedData, slogan_two_title: event.currentTarget.value},
                    })
                  }
                />
              <InputIcon
                  value={compressedData?.slogan_two_description}
                  myStyle="col-md-12 col-sm-12 pe-3"
                  title="توضیحات"
                  inBox="توضیحات"
                  type={"text"}
                  name={"slogan_two_description"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path.includes("slogan_two_description"))[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      compressedData: {...compressedData, slogan_two_description: event.currentTarget.value},
                    })
                  }
                />
                <InputIcon
                  value={compressedData?.slogan_two_url}
                  myStyle="col-md-12 col-sm-12 pe-3"
                  title="لینک"
                  inBox="لینک"
                  type={"text"}
                  name={"slogan_two_url"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path.includes("slogan_two_url"))[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      compressedData: {...compressedData, slogan_two_url: event.currentTarget.value},
                    })
                  }
                />
                </div>
            </div>
            <LableIcon title=" لوگوی شماره سه" icon={<BsFlagFill />} />
            <div className="site-slogan-item">
            <ImageUploader onClick={() => this.handleDeleteImage("slogan_three_image") } 
            itemIndex={`s-3`} onChangeImage={(e:any) => this.handleImageUpload(e,"slogan_three_image")}
             image={compressedData?.slogan_three_image}  imageAlt="netran" myStyle="col-md-4 col-sm-12"/>
                <div  className="col-md-8 col-sm-12">
                <InputIcon
                  value={compressedData?.slogan_three_title}
                  myStyle="col-md-12 col-sm-12 pe-3"
                  title="عنوان "
                  inBox="عنوان "
                  type={"text"}
                  name={"slogan_three_title"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path.includes("slogan_three_title"))[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      compressedData: {...compressedData, slogan_three_title: event.currentTarget.value},
                    })
                  }
                />
              <InputIcon
                  value={compressedData?.slogan_three_description}
                  myStyle="col-md-12 col-sm-12 pe-3"
                  title="توضیحات"
                  inBox="توضیحات"
                  type={"text"}
                  name={"slogan_three_description"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path.includes("slogan_three_description"))[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      compressedData: {...compressedData, slogan_three_description: event.currentTarget.value},
                    })
                  }
                />
                <InputIcon
                  value={compressedData?.slogan_three_url}
                  myStyle="col-md-12 col-sm-12 pe-3"
                  title="لینک"
                  inBox="لینک"
                  type={"text"}
                  name={"slogan_three_url"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path.includes("slogan_three_url"))[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      compressedData: {...compressedData, slogan_three_url: event.currentTarget.value},
                    })
                  }
                />
                </div>
            </div>
            <LableIcon title=" لوگوی شماره چهار" icon={<BsFlagFill />} />
            <div className="site-slogan-item">
            <ImageUploader onClick={() => this.handleDeleteImage("slogan_four_image") } 
            itemIndex={`s-4`} onChangeImage={(e:any) => this.handleImageUpload(e,"slogan_four_image")}
             image={compressedData?.slogan_four_image}  imageAlt="netran" myStyle="col-md-4 col-sm-12"/>
                <div  className="col-md-8 col-sm-12">
                <InputIcon
                  value={compressedData?.slogan_four_title}
                  myStyle="col-md-12 col-sm-12 pe-3"
                  title="عنوان "
                  inBox="عنوان "
                  type={"text"}
                  name={"slogan_four_title"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path.includes("slogan_four_title"))[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      compressedData: {...compressedData, slogan_four_title: event.currentTarget.value},
                    })
                  }
                />
              <InputIcon
                  value={compressedData?.slogan_four_description}
                  myStyle="col-md-12 col-sm-12 pe-3"
                  title="توضیحات"
                  inBox="توضیحات"
                  type={"text"}
                  name={"slogan_four_description"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path.includes("slogan_four_description"))[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      compressedData: {...compressedData, slogan_four_description: event.currentTarget.value},
                    })
                  }
                />
                <InputIcon
                  value={compressedData?.slogan_four_url}
                  myStyle="col-md-12 col-sm-12 pe-3"
                  title="لینک"
                  inBox="لینک"
                  type={"text"}
                  name={"slogan_four_url"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path.includes("slogan_four_url"))[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      compressedData: {...compressedData, slogan_four_url: event.currentTarget.value},
                    })
                  }
                />
                </div>
            </div>
            <LableIcon title=" لوگوی شماره پنج" icon={<BsFlagFill />} />
            <div className="site-slogan-item">
            <ImageUploader onClick={() => this.handleDeleteImage("slogan_five_image") } 
            itemIndex={`s-5`} onChangeImage={(e:any) => this.handleImageUpload(e,"slogan_five_image")}
             image={compressedData?.slogan_five_image}  imageAlt="netran" myStyle="col-md-4 col-sm-12"/>
                <div  className="col-md-8 col-sm-12">
                <InputIcon
                  value={compressedData?.slogan_five_title}
                  myStyle="col-md-12 col-sm-12 pe-3"
                  title="عنوان "
                  inBox="عنوان "
                  type={"text"}
                  name={"slogan_five_title"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path.includes("slogan_five_title"))[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      compressedData: {...compressedData, slogan_five_title: event.currentTarget.value},
                    })
                  }
                />
              <InputIcon
                  value={compressedData?.slogan_five_description}
                  myStyle="col-md-12 col-sm-12 pe-3"
                  title="توضیحات"
                  inBox="توضیحات"
                  type={"text"}
                  name={"slogan_five_description"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path.includes("slogan_five_description"))[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      compressedData: {...compressedData, slogan_five_description: event.currentTarget.value},
                    })
                  }
                />
                <InputIcon
                  value={compressedData?.slogan_five_url}
                  myStyle="col-md-12 col-sm-12 pe-3"
                  title="لینک"
                  inBox="لینک"
                  type={"text"}
                  name={"slogan_five_url"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path.includes("slogan_five_url"))[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      compressedData: {...compressedData, slogan_five_url: event.currentTarget.value},
                    })
                  }
                />
                </div>
            </div>
              <CustomButton
                myStyle="btn-confirm"
                loading={false}
                onClick={() => this.handleUpdate()}
                title="بروزرسانی "
              />

          </CustomContainer>
      </>
        }
</>
    );
  }
}
const mapStateToProps = (dispatch: any) => {
  return {
    dispatch,
    siteList: dispatch.general.siteList,
    loadingSiteList: dispatch.general.loadingSiteList,
    userData: dispatch.general.userData,
  }
};
export default connect(mapStateToProps)(SiteSlogan);
