
import { LoadingSpinner, Header, InputIcon, CustomButton, CustomContainer } from "../../../components";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { CommonQuestionsCreateController } from "../../../controllers";
import { Editor } from "@tinymce/tinymce-react";

class CommonQuestionsCreate extends CommonQuestionsCreateController {
  componentDidMount() {
  }

  render() {
    const { loading, question, fullAnswer ,
      answer , errorsList } = this.state;

    return (
      <>
        <Helmet>
          <title> ایجاد پرسش</title>
        </Helmet>
        <Header title=" ایجاد پرسش" />
        {loading ? <LoadingSpinner /> : <>
          {/* <LableIcon title="پرسش های متداول" icon={<BsStars />} /> */}
          <CustomContainer>
            <div className="content">
              <div className="col-md-12 col-sm-12 pe-3">
                <InputIcon
                  value={question}
                  title="پرسش"
                  inBox="پرسش"
                  type={"text"}
                  name={"question"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "question")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      question: event.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="col-md-12 col-sm-12 pe-3">
                <InputIcon
                  value={answer}
                  title="پاسخ کوتاه"
                  inBox="پاسخ کوتاه"
                  type={"text"}
                  name={"answer"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "answer")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      answer: event.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="col-md-12 col-sm-12 pe-3">

              <Editor
                 apiKey="bnl71iiv8jm79p88zww9qa0rvj9u4jz5zywkym0jj16uvli4"
                 initialValue={fullAnswer}
                 init={{
                   toolbar: 'undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                   plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
                   editimage_cors_hosts: ['picsum.photos'],
                   menubar: 'file edit view insert format tools table help',
                   toolbar_sticky: true,
                   autosave_ask_before_unload: true,
                   autosave_interval: '30s',
                   autosave_prefix: '{path}{query}-{id}-',
                   autosave_restore_when_empty: false,
                   autosave_retention: '2m',
                   image_advtab: true,
                   importcss_append: true,
                   directionality : 'rtl',
                   language:"fa"
 
                 }}
                 
                 onChange={this.handleEditorChangeGeneralInfo}
                 />
              </div>

              <CustomButton
                myStyle="btn-confirm mt-2"
                loading={false}
                onClick={() => this.create()}
                title="ثبت "
              />
            </div>

          </CustomContainer>
        </>
        }


      </>
    );
  }
}
const mapStateToProps = (dispatch: any) => {
  return {
    dispatch,
    siteList: dispatch.general.siteList,
    loadingSiteList: dispatch.general.loadingSiteList,
    userData: dispatch.general.userData,
  }
};
export default connect(mapStateToProps)(CommonQuestionsCreate);
