import { Component } from 'react';
import { call_api, parseJSON } from "../../../core/service";
import { AppConstants } from '../../../core/constants';
import { toast } from 'react-toastify';

interface IProps {
dispatch?:any,
siteList?:any,
loadingSiteList?:any,
userData?:any
}
interface IState {
  loading?: boolean,
  step: number,
  name: string,
  activeCheckBox: boolean,
  options: any
  series: any
  options2: any
  series2: any,
  dashboardListData:any,
}

 class HomeController extends Component<IProps, IState> {
  state: IState = {
    loading: false,
    step: 0,
    name: "",
    activeCheckBox: false,
    options: {
      chart: {
        id: "basic-bar"
      },
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
      },
      fill: {
        colors: ['#3ecbae']
      }
    },
    series: [
      {
        name: "series-1",
        data: [30, 40, 45, 50, 49, 60, 70, 91]
      }
    ],
    options2: {
      chart: {
        id: "basic-bar"
      },
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
      },
      fill: {
        colors: ['#3ecbae']
      }
    },
    series2: [44, 55, 41, 17, 15],
    dashboardListData:[],
    
  };

  async getDashboardList() {
    this.setState({loading : true})
    call_api({
      address_api:`${AppConstants.base_url_api}admin/dashboard`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {            
          this.setState({
            dashboardListData: j.data, loading:false
          })
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
 

  activeCheckBox = () => {
    this.setState({ activeCheckBox: !this.state.activeCheckBox })
  }
  handleSteps = (step: number) => {
    this.setState({ step: step });
  };
}


export default HomeController;