
import {MediasController} from "../../../controllers";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import Box from "@mui/material/Box";
import Select, { CSSObjectWithLabel } from "react-select";
import { AiOutlineFileExcel } from "react-icons/ai";
import {  Accordion, Header } from "../../../components";
import { CustomButton, DropDownIcon, Directory } from "../../../components";
import { InputTextLabel } from "../../../components/input_text_label";
import { createTheme, ThemeProvider } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Tooltip from "@mui/material/Tooltip";
import { TfiFilter } from "react-icons/tfi";
import files from './files.json';

class Medias extends MediasController {

  componentDidMount() {
   window.scrollTo(0, 0);
   this.getMediassList();

  }
  render() {
    const theme = createTheme({
        typography: {
          fontFamily: "Vazir",
        },
      });
    type ListOfData = {
        item?: any;
        row: number;
        preview: any;
        format:string;
        user: string;
        time: string;
      }; 
      const columns = [
        {
          accessorKey: "driver",
          header: "سایت",
          Cell: ({ cell }) => (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {cell.getValue<string>()}
            </div>
          ),
          size: 10,
        },
        {
          accessorKey: "preview",
          header: "پیش‌نمایش",
          Cell: ({ cell }) => (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {cell.getValue<string>()}
            </div>
          ),
          size: 10,
        },
        {
          accessorKey: "format",
          header: "فرمت",
          Cell: ({ cell }) => (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {cell.getValue<string>()}
            </div>
          ),
          size: 10,
        },
        {
          accessorKey: "user",
          header: "کاربر",
          Cell: ({ cell }) => (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {cell.getValue<string>()}
            </div>
          ),
          size: 10,
        },
        {
          accessorKey: "time",
          header: "زمان آپلود",
          Cell: ({ cell }) => (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "right",
              }}
            >
              {cell.getValue<string>()}
            </div>
          ),
        },
      ] as MRT_ColumnDef<ListOfData>[];
      
    const { loadingSiteList, siteList } = this.props; 
    const { medialistData, showFilters, total, page,  loading, search, category, categoryList, format, formatList, rowCount, rowCountList } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <div className="media price-list">
          <Helmet>
            <title>رسانه‌ها</title>
          </Helmet>
          <Header isActive= "isMedia" title="رسانه‌ها"/>
          <div className="custom-table">
          <div className="filter-select-container pe-3">
                <div className="filter-options-container">
                  {showFilters && (
                    <>
                      <div className="column-30">
                        <div className="wrapper" >
                        <Accordion
                          title="فیلترها"
                          content={
                            <>
                              <Select
                                styles={{
                                  control: (baseStyles: any, state: { isFocused: any; }): CSSObjectWithLabel => ({
                                    ...baseStyles,
                                    boxShadow: state.isFocused
                                      ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                      : "",
                                    borderWidth: "1px",
                                    backgroundColor: "#fff",
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    textAlign: "right",
                                    borderRadius: "4px",
                                    minHeight: "20px",
                                    
                                    outline: "unset",
                                    marginBottom: "10px",
                                  }) as CSSObjectWithLabel,
                                }}
                                onChange={(choice: any) =>
                                  this.formatSelected(choice)
                                  
                                }
                                value={format}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                options={formatList}
                                noOptionsMessage={() => "این مورد وجود ندارد!"}
                                getOptionLabel={(option: any) =>
                                  option?.label
                                }
                                getOptionValue={(option: any) => option?.value}
                                placeholder={"همه فرمت‌ها"}
                                isClearable
                              />

                    <Select
                      styles={{
                        control: (baseStyles, state):CSSObjectWithLabel => ({
                          ...baseStyles,
                          borderWidth: "1px",
                          backgroundColor: "#fff",
                          display: "flex",
                          flexDirection: "row-reverse",
                          textAlign: "right",
                          borderRadius: "4px",
                          height:"42px",
                          
                          outline: "unset",
                          marginBottom:"20px",
                        }) as CSSObjectWithLabel,
                      }}
                      onChange={(choice) => this.categorySelected(choice)}
                      value={category}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      noOptionsMessage={() => 'این مورد وجود ندارد!'}
                      menuPortalTarget={document.body}
                      options={categoryList }
                      getOptionLabel={(option: any) =>
                        option.persian_name
                      }
                      onInputChange={(choice: any) => this.handleCategorySearch(choice)}
                      getOptionValue={(option: any) => option.id}
                      placeholder="دسته‌بندی"
                      isClearable={false}
                      loadingMessage={() => 'در حال بارگذاری  ...'}
                      isLoading={loadingSiteList}
                    />
                               
                            </>
                          }
                        />
                      </div>
                      </div>
                    
                      <div className="column-30">
                        <InputTextLabel
                          type={"text"}
                          myStyle="search-input"
                          value={search}
                          onChange={(event) => this.handleSearch(event)}
                          placeholder="جستجو..."
                        />
                      </div>
                      
                      
                      </>
                  )}
                </div>
                <Directory files={files} />
                
              </div>
          <MaterialReactTable
            //   key={productListData?.data?.length}
                muiTablePaperProps={{
                  sx: {
                    mr: "1.5rem",
                    ml: "1.5rem",
                    border: "1px solid #e5e5e5",
                  },
                }}
                columns={columns}
                data={
                    medialistData &&
                  medialistData?.length > 0 &&
                  medialistData
                    ?.filter(
                      (v: string | null | undefined) =>
                        v !== "" && v !== undefined && v !== null
                    )
                    .map((item: any, index: number) => {
                      return {
                        item: item,
                        row: index + 1,
                        driver: <img className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo} alt="" />,
                        preview: <img src={item.url?.original} alt=""/>,
                        format: item?.icon,
                        user:"",
                        time:""
                      };
                    })
                }
                enableRowNumbers
                rowNumberMode="static"

                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                      sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap', justifyContent:"space-between", width:"100%" }}
                    >
                      <CustomButton
                        loading={false}
                        onClick={() => this.exportToExcel(medialistData, "Excel Export")}
                        title="اکسل"
                        myStyle="excel-btn"
                        icon={<AiOutlineFileExcel />}
                      />
                      <Tooltip title="نمایش فیلترها"><span> <TfiFilter className='filter-icon' onClick={() => this.setState(prevState => ({
                                  showFilters: !prevState.showFilters
                                }))
                              }/></span></Tooltip>
                      <div className='custom-pagination'>
                      <Pagination  page={page} count={total ? Math.ceil(total / rowCount) : 1} onChange={this.handleChangePage} />
                      <DropDownIcon
                      value={rowCount}
                      optionList={rowCountList}
                      title={""}
                      onChange={(event) =>
                        {
                          this.handlePaginationSelect(event)
                      }
                    }
                    />
                    </div>
                    </Box>
                  )}
                state={{ isLoading: loading, showProgressBars: loading }}
                enablePagination={false}
                renderBottomToolbarCustomActions={({table})=>{
                  return (
                    <div className="custom-pagination w-100">
                       <Pagination  page={page} count={total ? Math.ceil(total / rowCount) : 1} onChange={this.handleChangePage} />
                      <DropDownIcon
                      value={rowCount}
                      optionList={rowCountList}
                      title={""}
                      onChange={(event) =>
                        {
                          this.handlePaginationSelect(event)
                      }
                    }
                    />
                    </div>
                  );
                }}
                positionExpandColumn={"last"}
                enableGlobalFilter={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableHiding={false}
                sortDescFirst={true}
                enableColumnActions={false}
                enableSorting={true}
                initialState={{
                  pagination: { pageSize: 100, pageIndex: 0 },
                }}
                localization={{
                  noRecordsToDisplay: "داده‌ای برای نمایش وجود ندارد!",
                }}
                muiTableProps={{
                  sx: {
                    border: "1px solid #e5e5e5",
                  },
                }}
                muiTableHeadCellProps={{
                  sx: {
                    border: "1px solid #e5e5e5",
                    height: "fit-content",
                  },
                }}
                muiTableBodyCellProps={{
                  sx: {
                    border: "1px solid #e5e5e5",
                    height: "fit-content",
                    textAlign:"center"

                  },
                }}
                displayColumnDefOptions={{
                  'mrt-row-expand': {
                    muiTableHeadCellProps: {
                      align: 'center',
                    },
                    
                  },
                }}
                muiTableBodyRowProps={( {row,staticRowIndex} ) => ({
                  sx:{
                    height: "fit-content",
                    backgroundColor: (staticRowIndex % 2 === 0 ) ? '#f3f3f3'  : "",
                }})}
                tableInstanceRef={this.tableInstanceRef}
                
              />
          </div>
        </div>
      </ThemeProvider>
    );
  }
}
const mapStateToProps = (dispatch: any) => {
  return {
    dispatch,
    siteList: dispatch.general.siteList,
    loadingSiteList: dispatch.general.loadingSiteList,
  };
};
export default connect(mapStateToProps)(Medias);
