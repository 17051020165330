import { Component } from "react";
import { call_api, parseJSON } from "../../../core/service";
import { AppConstants } from "../../../core/constants";
import { toast } from "react-toastify";
import { createRef } from "react";
import { alertButtonsState } from "../../../core/utils";
import * as yup from 'yup';


interface IProps { 
  open?: boolean,
  dispatch?:any,
  siteList?:any,
  loadingSiteList?:any,
}
interface IState {
  buyPrice: string,
  description: string,
  priceListData: any,
  categoryList: any;
  category: any,
  statusList: any;
  statusId: any;
  brandList: any;
  brandId: any,
  dreiverId: any;
  search: string;
  groupChangeOptions: any;
  groupChangeSelectedOption: any,
  loading: boolean;
  updateLoading?: boolean,
  price: string,
  toggleModal: any,
  openPriceModal: boolean,
  openBuyPriceModal: boolean,
  openGroupOperationModal: boolean,
  selectInputValue: any,
  column: Array<any>,
  rowData: any,
  selectedList: any,
  rangeSliderNumbers:number[],
  idSelectedList: any,
  statusSelectedList: any,
  submitLoading: boolean,
  paginate: number,
  fileType: string,
  fileExtension: string,
  errorsList?: any,
  inventory: string
  groupPrice: string
  groupDiscount: string;
  priceIncrease: string;
  priceReduction: string;
  page: number,
  selectedRow: any,
  priceList: any,
  total:number
  rowCount:any,
  rowCountList:any,
  allOption:any,
  showFilters:boolean,
  syncStatus:any,
  syncStatus2:any,
  showSyncFilter:any;
  openLinkModal:boolean;
  relationshipProducts:any;
  multiSelectedList: any;
  confirmUnlink:boolean;
  unlinkedItem:any;
  tag:any;
  tagList:any;
  tagLoading: boolean;
  openMenuUrl:boolean;
  anchorEl:any;
  currentItem:any;
  selectedRowIndex:number;
  sitesListLoading:boolean;
  groupOpConfirm:any;
  selectAllToggle:boolean;
  editModal:boolean;
  modalDriverId:any;
  sites:any;
  editionSearch: string,
  modalLoading: boolean,
  rows: any,
  productListData: any,
  modalPage:number,
  modalRowCount:number,
  showRows:any,
  editModaltotalRow:number,
  modalRowCountList:any,
  editionSelectedRow:any,
  coloredRows:any
  cellBg:string,
}

 class PriceListController extends Component<IProps, IState> {
  tableInstanceRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    this.tableInstanceRef = createRef()
    this.state = {
      description: "",
      submitLoading: false,
      buyPrice: "",
      rowData: {},
      priceListData: [],
      loading: false,
      brandId: null,
      category: null,
      multiSelectedList: [],
      groupChangeOptions: ['تغییر ثابت',
        'تغییر درصدی افزایشی',
        'تغییر درصدی کاهشی',
      ],
      groupChangeSelectedOption: "تغییر ثابت",
      statusId: null,
      search: "",
      updateLoading: false,
      price: "",
      toggleModal: { status: false, buttonState: "" },
      openPriceModal: false,
      openGroupOperationModal: false,
      openBuyPriceModal: false,
      selectInputValue: "",
      column: [
        { title: "کد", field: "code" },
        { title: "نام محصول", field: "name" },
        {
          title: "موجودی و خرید",
          field: "leftOver",
        },
        { title: "قیمت", field: "price" },
        {
          title: "تخفیف",
          field: "offPrice"
        },
      ],
      categoryList: [],
      brandList: [],
      statusList: [{ title: "منتشرنشده", value: "unpublished" }, { title: "منتشرشده", value: "published" }, { title: "حذف شده", value: "deleted" },
      { title: "غیرقابل فروش", value: "dont_sell" },  { title: "قابل فروش", value: "do_sell" }, { title:"تایید نشده" , value:"unconfirmed"}, { title:"تایید شده" , value:"confirmed"},
      { title: "پیش نویس", value: "draft" }, { value: "unknown", title: "نا معلوم" }],
      dreiverId: [],
      idSelectedList: [],
      statusSelectedList: [],
      paginate: 0,
      fileType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      fileExtension: ".xlsx",
      inventory: "",
      groupPrice: "",
      groupDiscount: "",
      priceIncrease: "",
      priceReduction: "",
      errorsList: [],
      page: 1,
      selectedRow: "",
      rangeSliderNumbers:[0,1000000000],
      priceList: [],
      total:0,
      selectedList: [],
      rowCount:100,
      rowCountList:[10, 25 , 50 , 100],
      allOption:{id:0 ,value:{
        persian_name: "محصولات لینک شده ",
        name: "*",
      }},
      showFilters:false,
      syncStatus:1,
      syncStatus2: null,

      showSyncFilter:false,
      openLinkModal:false,
      relationshipProducts:[],
      confirmUnlink:false,
      unlinkedItem:[],
      tag:null,
      tagList:[],
      tagLoading: false,
      openMenuUrl:false,
      anchorEl:null,
      currentItem:[],
      selectedRowIndex:101,
      sitesListLoading:false,
      groupOpConfirm:{ status: false, buttonState: "" },
      selectAllToggle:false,
      editModal:false,
      modalDriverId:{},
      sites:[],
      editionSearch:"",
      modalLoading: false,
      rows: {id:""},
      productListData: [],
      modalPage:1,
      modalRowCount:100,
      showRows:[],
      editModaltotalRow:0,
      modalRowCountList:[10, 25 , 50 , 100],
      editionSelectedRow:[],
      coloredRows:[],
      cellBg:"#d1ecfb"
    }
  }
  statusTimeout: string | number | NodeJS.Timeout | undefined;
  statusSelected = (choice: any) => {
    this.setState({ statusId: choice, selectAllToggle:false, selectedList:[] })
    

    clearTimeout(this.statusTimeout);
    this.statusTimeout = setTimeout(() => {
      this.getPriceList()
    }, 1000);
  }

  handleClickEye = (event: React.MouseEvent<HTMLButtonElement>) => {
  };
 handleCloseEye = () => {
  this.setState({anchorEl:null,  openMenuUrl:false})
};

  categryTimeout: string | number | NodeJS.Timeout | undefined;
  categorySelected = (choice: any) => {
    this.setState({ category: choice, selectAllToggle:false, selectedList:[] })
    

    clearTimeout(this.categryTimeout);
    this.categryTimeout = setTimeout(() => {
      this.getPriceList()
    }, 1000);
  }
  brandTimeout: string | number | NodeJS.Timeout | undefined;
  brandSelected = (choice: any) => {
     

    this.setState({ brandId: choice, selectAllToggle:false, selectedList:[] })
    clearTimeout(this.brandTimeout);
    this.brandTimeout = setTimeout(() => {
      this.getPriceList()
    }, 1000);
  }
  driverTimeout: string | number | NodeJS.Timeout | undefined;
  driverSelected = (selected:any, index?:any) : any=> {
    this.setState({
      page: 1 , 
      selectAllToggle: false
    })
     

    this.setState({brandId:null, category:null, statusId:null, tag:null , selectedList:[]})
    if(index?.option?.id === 0 || selected.length === 0)
    
    {
        this.setState({
          syncStatus: 1 , 
          dreiverId:[],
          showSyncFilter:false
        })

      }
      else 
      this.setState({
        dreiverId: selected ,
          showSyncFilter:true,
          syncStatus: null,
      });
      clearTimeout(this.driverTimeout);
      this.driverTimeout = setTimeout(() => {
        this.getPriceList()
        this.getBrandList()
        this.getCategoryList()
      }, 1000);
    };
   
  searchTimeout: string | number | NodeJS.Timeout | undefined;
  handleSearch = (event: any) => {
     

    this.setState({ search: event.currentTarget.value, selectAllToggle:false, selectedList:[] })
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.getPriceList()
    }, 1000);
  };
  handleSelect = (index:number, selected: { id: any; }) => {
    this.setState({selectedRowIndex: index})
    const {selectedList} = this.state;
    let list = [...selectedList];
    var found = false;
    for( var i = 0; i < selectedList.length ; i++){
      if(selectedList[i].id === selected?.id){
          found = true;
          list.splice(i,1)
          this.setState({ selectedList: list })
      }
    }
    if(!found){
      list.push(selected)
            this.setState({ selectedList: list })
    }
  }
  handleSelectAll = () =>{
    const {selectedList, priceListData, selectAllToggle} = this.state;
    if(selectedList.length === priceListData.length){
      this.setState({selectedList: [], selectAllToggle: false})
    }
    else{
      this.setState({selectedList: priceListData, selectAllToggle: true})
    }
  }
  operationalGroup = () => {
     

    const { syncStatus, idSelectedList , selectedList} = this.state;
    this.setState({coloredRows: selectedList})
    if(selectedList.length > 0){
    let idList = [...idSelectedList];
    const selectedObj = [...this.state.multiSelectedList]

    
    if(Number(syncStatus === 1)){
    const siteSelectedCount = [];
      for (let i = 0; i < selectedList?.length; i++) {
        const dbNameLsit : string[] = []
        const obj : any = {
          mId: "",
          db_name: "",
        }
        obj.mId=selectedList[i]?.mId;
          for (let j = 0; j < selectedList[i]?.relationship_products?.length; j++) {
            if(selectedList[i]?.relationship_products[j]?.site_select === true){
              dbNameLsit.push(selectedList[i]?.relationship_products[j].db_name)
              siteSelectedCount.push(selectedList[i]?.relationship_products[j].db_name)
            }
          }
          obj.db_name = dbNameLsit.toString()
          selectedObj.push(obj);
        }
        if(siteSelectedCount.length>0){
          this.setState({ multiSelectedList: selectedObj });
    this.setState({ openGroupOperationModal: true })
          
        }else{
          toast.error("حداقل یک سایت را انتخاب کنید")
        }
    }else{
    this.setState({ openGroupOperationModal: true })
      for (let i = 0; i < selectedList?.length; i++) {
        if (!idList.includes(selectedList[i].id_select)) {
          idList.push(selectedList[i].id_select)
          this.setState({ idSelectedList: idList })
        }
      }
    }
    // 
  
  }
else{
  toast.error("حداقل یک محصول را انتخاب کنید")
}
  }
  priceOffTimeout: string | number | NodeJS.Timeout | undefined;
  handleBlurOffPrice = (event: any, item: any, index: number) => {
    const { name } = event.target;
    var value = (event.target.value).replace(/,/g, '');
    const newState = [...this.state.priceListData];

    const re = /^[0-9\b]+$/;

    if (value === '' || re.test(value)) {
        if (Number(value) <= Number(newState[index].price)) {
          newState[index] = {
            ...newState[index],
            [name]: value
          };
      this.setState({ priceListData: newState })
      clearTimeout(this.priceOffTimeout);
      this.priceOffTimeout = setTimeout(() => {
          if(Number(this.state.syncStatus) === 1){
            this.handleDiscountSync(value,item)
          }else{
        this.updateSingleDiscount(value, item)
          }
        }, 1000);
      }
    }
  }
  handleChangeOffPrice = (event: any, item: any, index: number) => {
     

    const { name } = event.target;
    var value = (event.target.value).replace(/,/g, '');
    const newState = [...this.state.priceListData];
    const re = /^[0-9\b]+$/;
    if (value === '' || re.test(value)) {
        if (Number(value) <= Number(newState[index].price)) {
          newState[index] = {
            ...newState[index],
            [name]: value
          };
      this.setState({ priceListData: newState })
      }
    }
  }
  priceTimeout: string | number | NodeJS.Timeout | undefined;
  handleBlurPrice = (event: any, item: any, index: number) => {
     

    const { name } = event.target;
    var value = (event.target.value).replace(/,/g, '');
    const newState = [...this.state.priceListData];

    const re = /^[0-9\b]+$/;

    if (value === '' || re.test(value)) {
      newState[index] = {
        ...newState[index],
        [name]: value
      };
      this.setState({ priceListData: newState })
      clearTimeout(this.priceTimeout);
      this.priceTimeout = setTimeout(() => {
        if (Number(value) >= Number(newState[index].discount)) {
        if(Number(this.state.syncStatus) === 1){
         this.handlePriceSync(value,item)
        }else{
        this.updateSinglePrice(value, item)
        }
      }else{
        toast.error("قیمت نمیتواند کوچک تر از تخفیف باشد")
      }
      }, 1000);
    }
  };
  handleChangePrice = (event: any, item: any, index: number) => {
     

    const { name } = event.target;
    var value = (event.target.value).replace(/,/g, '');
    const newState = [...this.state.priceListData];

    const re = /^[0-9\b]+$/;

    if (value === '' || re.test(value)) {
      newState[index] = {
        ...newState[index],
        [name]: value
      };
      this.setState({ priceListData: newState })
    }
  };
  paginationTimeOut: string | number | NodeJS.Timeout | undefined; 
  handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ page: value })
     

    clearTimeout(this.paginationTimeOut);
    this.paginationTimeOut = setTimeout(() => {
      this.getPriceList()
    }, 800);
    
  }
  paginationSelectTimeOut: string | number | NodeJS.Timeout | undefined; 
  handlePaginationSelect=(event:any)=>{
    this.setState({
      rowCount: event.currentTarget.value,
      page:1
    })
     

    clearTimeout(this.paginationSelectTimeOut);
    this.paginationSelectTimeOut = setTimeout(() => {
      this.getPriceList()
    }, 800);
    
  }
  syncProductsTimeOut: string | number | NodeJS.Timeout | undefined; 
  handlesyncProducts=( event: React.MouseEvent<HTMLElement>,
    newAlignment: any)=>{
    this.setState({
      syncStatus2:newAlignment === "null" ? JSON.parse(newAlignment) : newAlignment, selectAllToggle:false, selectedList:[]
    })
     

    clearTimeout(this.syncProductsTimeOut);
    this.paginationSelectTimeOut = setTimeout(() => {
      this.getPriceList()
      this.getBrandList()
      this.getCategoryList()
    }, 1000);
  }
  async updateSinglePrice(event: string, item: any) {
     
    call_api({
      address_api: `${AppConstants.base_url_api}seller/products/${item.id_select}`,
      method_api: "PUT",
      body: JSON.stringify({

        price: event
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          toast.success("با موفقیت انجام شد ");
          // this.getPriceList();
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async handlePriceSync(event: string,item:any) {
     

    const driversSelectedList = [];
    for (var i = 0; i < item?.relationship_products?.length; i++) {
      if(item?.relationship_products[i]?.site_select === true)
      driversSelectedList.push(item?.relationship_products[i]?.db_name)
    }
    call_api({
      address_api: `${AppConstants.base_url_api}seller/sync-products/price`,
      method_api: "POST",
      body: JSON.stringify({
        mId: item.mId,
        db_name:driversSelectedList.toString(),
        price: event
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          toast.success("با موفقیت انجام شد ");
          // this.getPriceList()
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async handleDiscountSync(event: string,item:any) {
     

    const driversSelectedList = [];
    for (var i = 0; i < item?.relationship_products?.length; i++) {
      if(item?.relationship_products[i]?.site_select === true)
      driversSelectedList.push(item?.relationship_products[i]?.db_name)
    }
    call_api({
      address_api: `${AppConstants.base_url_api}seller/sync-products/discount`,
      method_api: "POST",
      body: JSON.stringify({
        mId: item.mId,
        db_name:driversSelectedList.toString(),
        discount: event
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          toast.success("با موفقیت انجام شد ");
          // this.getPriceList();
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async updateSingleDiscount(event: string, item: any) {
     

    call_api({
      address_api: `${AppConstants.base_url_api}seller/products/${item.id_select}`,
      method_api: "PUT",
      body: JSON.stringify({
        discount: event,
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          toast.success("با موفقیت انجام شد ");
          // this.getPriceList()
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  fetchDataTimeOut: string | number | NodeJS.Timeout | undefined; 
  handleOpenLinkModal = (selected: any,index:number) => {
    this.setState({ openLinkModal: true, selectedRow :  index, sitesListLoading:true})
    call_api({
      address_api: `${AppConstants.base_url_api}seller/sync-products/${selected?.mId}`,
      method_api: "GET",
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({ relationshipProducts: j?.data})
          
    clearTimeout(this.fetchDataTimeOut);
    this.fetchDataTimeOut = setTimeout(() => {
      let arr = [...this.state.relationshipProducts]
          for(var i = 0; i < selected?.relationship_products.length; i++){
              arr[i].site_select = selected?.relationship_products[i].site_select 
          }
          this.setState({relationshipProducts: arr, sitesListLoading: false})
    }, 1000);
          
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });



  }
  handleClosePriceModal = () => {
     

    this.setState({ openPriceModal: false })
  }
  handleBuyPriceModal = (selected: any) => {
     

    this.setState({ openBuyPriceModal: true })
  }
  handleCloseBuyPriceModal = () => {
     

    this.setState({ openBuyPriceModal: false })
  }
  handleCloseGroupOperationModal = () => {
     

    this.setState({ openGroupOperationModal: false })
    this.setState({ idSelectedList: [] , multiSelectedList: [], selectedList:[], selectAllToggle:false})
  }
  handleCloseLinkModal = () => {
     

    this.setState({ openLinkModal: false, unlinkedItem:[]})
  }
  async handleGroupOperation() {
     
    const { priceIncrease, priceReduction, syncStatus , multiSelectedList , idSelectedList, groupChangeSelectedOption, groupDiscount, groupPrice, inventory } = this.state;
    const data = new FormData()
    this.setState({ submitLoading: true })
    if(Number(syncStatus) === 1){
    data.append('mId', JSON.stringify(multiSelectedList))
    }else{
      data.append('id_select', idSelectedList.toString())
    }
    if (groupChangeSelectedOption === 'تغییر ثابت') {
      groupDiscount && data.append('discount', groupDiscount)
      groupPrice && data.append('price', groupPrice)
      inventory && data.append('quantity', inventory)
      const otpFixedSchema = yup.object({
        groupDiscount: yup.number(),
        groupPrice: yup.number(),
        inventory: yup.number()
      });
      try {
        this.setState({ submitLoading: true })
        await otpFixedSchema.validate({ priceReduction }, { abortEarly: false });
        if(groupDiscount === "" && groupPrice  === "" && inventory === ""){
          toast.error("لطفاً یکی از موارد را پر کنید!")
          this.setState({ submitLoading: false})

        }
        else{
        if(Number(syncStatus)===1){
          this.updateMultipleSync(data)
        }else{
          this.updateMultiple(data)
        }
      }} catch (err: any) {
        this.setState({ submitLoading: false, errorsList: err.inner })
      }
    
    }
    if (groupChangeSelectedOption === 'تغییر درصدی افزایشی') {
      priceIncrease && data.append('price', `+${priceIncrease}`)
      const otpPriceIncreaseSchema = yup.object({
        priceIncrease: yup.string().required(" قیمت الزامی می باشد  "),
      });
      try {
        this.setState({ submitLoading: true })
        await otpPriceIncreaseSchema.validate({ priceIncrease }, { abortEarly: false });
        if(Number(syncStatus)===1){
          this.updateMultipleSync(data)
        }else{
          this.updateMultiple(data)
        }
      } catch (err: any) {
        this.setState({ submitLoading: false, errorsList: err.inner })
      }
    }
    if (groupChangeSelectedOption === 'تغییر درصدی کاهشی') {
      priceReduction && data.append('price', `-${priceReduction}`)
      const otpPriceReductionSchema = yup.object({
        priceReduction: yup.string().required(" قیمت الزامی می باشد  "),
      });
      try {
        this.setState({ submitLoading: true })
        await otpPriceReductionSchema.validate({ priceReduction }, { abortEarly: false });
        if(Number(syncStatus)===1){
          this.updateMultipleSync(data)
        }else{
          this.updateMultiple(data)
        }
      } catch (err: any) {
        this.setState({ submitLoading: false, errorsList: err.inner })
      }
    }
  }

  async updateMultiple(data: any) {
    call_api({
      address_api: `${AppConstants.base_url_api}seller/product/update-multiple`,
      method_api: "POST",
      body: data,
      file: true
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.getPriceList();
          this.setState({
            
            groupChangeSelectedOption: "تغییر ثابت",
            priceIncrease: "",
            priceReduction: "",
            groupDiscount: "",
            groupPrice: "",
            inventory: "",
            // openGroupOperationModal: false,
            submitLoading: false, errorsList: [], cellBg:"#1a85c4"})
          toast.success("با موفقیت انجام شد ");
        } else if (status === 401) {
          this.setState({ submitLoading: false, errorsList: [] })
          localStorage.clear();
        } else {
          this.setState({ submitLoading: false, errorsList: [] })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async updateMultipleSync(data: any) {
     
    call_api({
      address_api: `${AppConstants.base_url_api}seller/sync-products/update-multiple`,
      method_api: "POST",
      body: data,
      file: true
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.getPriceList();
          this.setState({
            groupChangeSelectedOption: "تغییر ثابت",
           
            priceIncrease: "",
            priceReduction: "",
            groupDiscount: "",
            groupPrice: "",
            inventory: "",
            // openGroupOperationModal: false,
            submitLoading: false, errorsList: [] , multiSelectedList:[], cellBg:"#1a85c4"
          })
          toast.success("با موفقیت انجام شد ");
        } else if (status === 401) {
          this.setState({ submitLoading: false, errorsList: [] })
          localStorage.clear();
        } else {
          this.setState({ submitLoading: false, errorsList: [] })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  rangeSliderTimeout: string | number | NodeJS.Timeout | undefined;
  handleChangeRangeSlider = (
   event: Event,
   newValue: number | number[],
   activeThumb: number,
   ) => {
     
    this.setState({selectAllToggle:false, selectedList:[]})
   const minDistance = 0;
   if (!Array.isArray(newValue)) {
     return;
   }

   if (newValue[1] - newValue[0] < minDistance) {
     if (activeThumb === 0) {
       const clamped = Math.min(newValue[0], 1000000000 - minDistance);
       this.setState({rangeSliderNumbers:[clamped, clamped + minDistance]});
     } else {
       const clamped = Math.max(newValue[1], minDistance);
       this.setState({rangeSliderNumbers:[clamped - minDistance, clamped]});
     }
   } else {
     this.setState({rangeSliderNumbers:newValue as number[]});
   }

   clearTimeout(this.rangeSliderTimeout);
   this.rangeSliderTimeout = setTimeout(() => {
     this.getPriceList()
   }, 1000);
   

 };
  async getPriceList() {
    this.setState({ loading: true, priceListData:[] })
    const { category, dreiverId, tag, page,syncStatus,syncStatus2, rangeSliderNumbers, showSyncFilter ,brandId, search, statusId, rowCount } = this.state;
    
    const driversSelectedList = [];
    for (var b = 0; b < dreiverId.length; b++) {
      driversSelectedList.push(dreiverId[b]?.value?.name)
      // this.setState({ usersUuid: driversSelectedList })
    }
    let usersSelectedList = [];
    let brandList = [];
    let statusSelectedList = [];
    let tagsList = [];
    if(Number(syncStatus) === 1){
      if(category) usersSelectedList.push(category.id)
      if(brandId) brandList.push(brandId.id)
      if(statusId) statusSelectedList.push(statusId.value)
      if(tag) tagsList.push(tag.id)


    }else{
    for (var i = 0; i < category?.length; i++) {
      usersSelectedList.push(category[i]?.id)
      // this.setState({ usersUuid: usersSelectedList })
    }
    for (var c = 0; c < brandId?.length; c++) {
      brandList.push(brandId[c]?.id)
    }
    
    for (var e = 0; e < statusId?.length; e++) {
      statusSelectedList.push(statusId[e]?.value)
    }

    for (var f = 0; f < tag?.length; f++) {
      tagsList.push(tag[f]?.id)
    }
    }

    let parameters = {
      brand_id: brandList.length > 0 ? brandList : null,
      search: search ? search : null,
      status: statusSelectedList.length > 0  ? statusSelectedList : null,
      page: page ? page.toString() : null,
      paginate: rowCount,
      is_sync:(showSyncFilter && Number(syncStatus2) !== 2) ? syncStatus2 : (!showSyncFilter && Number(syncStatus) !== null) ? syncStatus : null,
      is_delete: JSON.stringify(0),
      is_confirmed:1,
      price:JSON.stringify({min:rangeSliderNumbers[0],max:rangeSliderNumbers[1]}),
      tag_id: tagsList.length  > 0 ? tagsList : null,
      category_id: usersSelectedList.length > 0 ? usersSelectedList : null,
      db_name: dreiverId?.length > 0 ? driversSelectedList : null,
    }
    let url = Number(syncStatus) === 1 ?  new URL(`${AppConstants.base_url_api}seller/product/synced-list`) :  new URL(`${AppConstants.base_url_api}seller/products`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value.toString())
      }
    }
    call_api({
      address_api:Number(syncStatus) === 1 ? 
       url.search ? `${AppConstants.base_url_api}seller/product/synced-list${url.search}` : `${AppConstants.base_url_api}seller/product/synced-list` : 
       url.search ? `${AppConstants.base_url_api}seller/products${url.search}` : `${AppConstants.base_url_api}seller/products` ,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
            priceListData: j.data?.data, loading: false , total: j.data?.total
          })
        } else if (status === 401) {
          this.setState({ loading: false })
          localStorage.clear();
        } else {
          this.setState({ loading: false })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  async getCategoryList() {
     

        const { dreiverId , syncStatus2 , syncStatus} = this.state;

    const driversSelectedList = [];
    for (var i = 0; i < dreiverId.length; i++) {
      driversSelectedList.push(dreiverId[i]?.value?.name)
    }
    let parameters = {
      db_name: dreiverId?.length > 0 ? driversSelectedList : null,
      sync: (Number(syncStatus2) === 1 || Number(syncStatus) === 1) ? 1 : null

    }
     let url = new URL(`${AppConstants.base_url_api}seller/categories`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value.toString())
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}seller/categories${url.search}` : `${AppConstants.base_url_api}seller/categories`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          if (j.data) {
            this.setState({
              categoryList: j.data?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null),
            })
          }
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  async getBrandList() {
     

    const { dreiverId , syncStatus2 , syncStatus} = this.state;
    const driversSelectedList = [];
    for (var i = 0; i < dreiverId.length; i++) {
      driversSelectedList.push(dreiverId[i]?.value?.name)
    }
    let parameters = {
      db_name: dreiverId?.length > 0 ? driversSelectedList : null,
      sync: (Number(syncStatus2) === 1 || Number(syncStatus) === 1 ) ? 1 : null
    }
    let url = new URL(`${AppConstants.base_url_api}seller/brands`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value.toString())
      }
    }
    call_api({
      address_api:  url.search ? `${AppConstants.base_url_api}seller/brands${url.search}` : `${AppConstants.base_url_api}seller/brands`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
            brandList: j.data?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null),
          })
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  //Single
  async handleDeleteSingle() {
     

    const { rowData } = this.state;
    call_api({
      address_api: `${AppConstants.base_url_api}seller/products/${rowData.id_select}`,
      method_api: "DELETE"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
            toggleModal: { status: !this.state.toggleModal.status, buttonClick: alertButtonsState.DELETE }
          })
          this.getPriceList();

        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  async handleRecycleSubmit() {
     

    const { rowData } = this.state;
    call_api({
      address_api: `${AppConstants.base_url_api}seller/product/recovery/${rowData.id_select}`,
      method_api: "PATCH",
      body: JSON.stringify({
     

      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          //just admin can recycle !
          toast.error(j.message);
          this.getPriceList();
          this.setState({
            toggleModal: { status: !this.state.toggleModal.status, buttonClick: alertButtonsState.RECYCLE }
          })
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j.message);
          this.setState({
            toggleModal: { status: !this.state.toggleModal.status, buttonClick: alertButtonsState.RECYCLE }
          })
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async handleConfirmSingle() {
     

    const { rowData } = this.state;
    call_api({
      address_api: `${AppConstants.base_url_api}seller/products/confirmed/${rowData.id_select}`,
      method_api: "PUT",
      body: JSON.stringify({
        
        confirmed: rowData.confirmed === 1 ? '0' : '1',
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
            toggleModal: { status: !this.state.toggleModal.status, buttonClick: alertButtonsState.NOTCONFIRMED }
          })
          this.getPriceList();
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  async handleChangeStatusSingle() {
     

    const { rowData } = this.state;
    call_api({
      address_api: `${AppConstants.base_url_api}seller/products/status/${rowData.id_select}`,
      method_api: "PUT",
      body: JSON.stringify({
        status: rowData?.dont_sell?.name === 1 ? "do_sell" : "dont_sell"
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
            toggleModal: { status: !this.state.toggleModal.status, buttonClick: alertButtonsState.NOSELLING }
          })
          this.getPriceList();
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  async handleChangeMultipleStatus(state: string) {
     

    const { idSelectedList } = this.state;
    call_api({
      address_api: `${AppConstants.base_url_api}seller/product/status-update-multiple`,
      method_api: "PUT",
      body: JSON.stringify({
        id_select:idSelectedList.toString(),
        status: state
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          toast.success("با موفقیت انجام شد ");
          this.getPriceList();
          this.setState({groupOpConfirm:{status: false, buttonState:""}})
          if(state === "do_sell"){
            this.setState({cellBg:"#bfc720"})
          }
          else{
              this.setState({cellBg:"#fcd110"})
          }
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  //

  handlePagination = (event: any) => {
     

    this.setState({ paginate: event.currentTarget.textContent })
    this.getPriceList()
  }
  handleModal = (event?: any,status?:string) => {
    this.setState({ rowData: event.item })
    this.setState({
      toggleModal: {
        status: !this.state.toggleModal.status,
        buttonClick: status

      }
    })
  }
  priceIncrease = (event: any) => {
    if (Number(event.currentTarget.value) > 100) {
      toast.error("عدد وارد شده باید بین 0 و 100 باشد ");
    } else {
      this.setState({ priceIncrease: event.currentTarget.value })
    }
  }
  priceReduction = (event: any) => {
    if (Number(event.currentTarget.value) > 100) {
      toast.error("عدد وارد شده باید بین 0 و 100 باشد ");
    } else {
      this.setState({ priceReduction: event.currentTarget.value })
    }
  }
  
  relationshipProductsTimeout: string | number | NodeJS.Timeout | undefined;
  blurRelationshipProductsPrice = (event: any, item: any, index: number) => {
     

    const { name } = event.target;
    var value = (event.target.value).replace(/,/g, '');
    const newState = [...this.state.relationshipProducts];

    const re = /^[0-9\b]+$/;

    if (value === '' || re.test(value)) {
      newState[index] = {
        ...newState[index],
        [name]: value
      };
      this.setState({ relationshipProducts: newState })
      clearTimeout(this.relationshipProductsTimeout);
      this.relationshipProductsTimeout = setTimeout(() => {
        if (Number(value) >= Number(newState[index].discount)) {
          this.updateSinglePrice2(value, item)
        }else{
          toast.error("قیمت نمیتواند کوچک تر از تخفیف باشد")
        }
      }, 1000);
    }
  };
  changeRelationshipProductsPrice = (event: any, item: any, index: number) => {
  const { name } = event.target;
  var value = (event.target.value).replace(/,/g, '');
  const newState = [...this.state.relationshipProducts];

  const re = /^[0-9\b]+$/;

  if (value === '' || re.test(value)) {
    newState[index] = {
      ...newState[index],
      [name]: value
    };
    this.setState({ relationshipProducts: newState })
  }
}
  relationshipProductsDiscountTimeout: string | number | NodeJS.Timeout | undefined;
  blurRelationshipProductsDiscount = (event: any, item: any, index: number) => {
     

    const { name } = event.target;
    var value = (event.target.value).replace(/,/g, '');
    const newState = [...this.state.relationshipProducts];

    const re = /^[0-9\b]+$/;

    if (value === '' || re.test(value)) {
      if(name === "discount"){
        if (Number(value) <= Number(newState[index].price)) {
          newState[index] = {
            ...newState[index],
            [name]: value
          };
          this.setState({ relationshipProducts: newState })
          clearTimeout(this.relationshipProductsDiscountTimeout);
          this.relationshipProductsDiscountTimeout = setTimeout(() => {
            this.updateSingleDiscount2(value, item)
          }, 1000);
        }
        }
      }

   
  }
  changeRelationshipProductsDiscount = (event: any, item: any, index: number) => {
     

    const { name } = event.target;
    var value = (event.target.value).replace(/,/g, '');
    const newState = [...this.state.relationshipProducts];

    const re = /^[0-9\b]+$/;

    if (value === '' || re.test(value)) {
      if(name === "discount"){
        if (Number(value) <= Number(newState[index].price)) {
          newState[index] = {
            ...newState[index],
            [name]: value
          };
          this.setState({ relationshipProducts: newState })
        }
        }
      }

   
  }
  async updateSinglePrice2(event: string, item: any) {
     
    const {siteList} = this.props;
    const {priceListData, selectedRow} = this.state;
    call_api({
      address_api: `${AppConstants.base_url_api}seller/products/${item.id_select}`,
      method_api: "PUT",
      body: JSON.stringify({
        price: event
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          toast.success("با موفقیت انجام شد ");
          
          if(siteList[0]?.value?.name === item?.db_name)
          {
            let data = [...priceListData];
            data[selectedRow].price = event
            this.setState({priceListData: data})
          }
          // this.setState({openLinkModal: false})
    // this.getPriceList();

        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async updateSingleDiscount2(event: string, item: any) {
     

    const {siteList} = this.props;
    const {priceListData, selectedRow} = this.state;
    call_api({
      address_api: `${AppConstants.base_url_api}seller/products/${item.id_select}`,
      method_api: "PUT",
      body: JSON.stringify({
        discount: event,
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          toast.success("با موفقیت انجام شد ");
          
          if(siteList[0]?.value?.name === item?.db_name)
          {
            let data = [...priceListData];
            data[selectedRow].discount = event
            this.setState({priceListData: data})
          }
          // this.setState({openLinkModal: false})
    // this.getPriceList();

        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async handleUnLinkSingle(item:any) {
    const {unlinkedItem} = this.state;
    unlinkedItem.push(item)
    this.setState({confirmUnlink: true})
    
  };
  handleCloseConfirmLinkModal = (open:boolean) =>{
    const {unlinkedItem} = this.state;
    let list = [...unlinkedItem];
    list.splice(list.length - 1, 1);
    this.setState({confirmUnlink: open, unlinkedItem: list})
  }
  updateLinkedDatasTimeout: string | number | NodeJS.Timeout | undefined;

  confirmUnlinkSingle(){
    const {unlinkedItem, relationshipProducts} = this.state;
    call_api({
      address_api: `${AppConstants.base_url_api}seller/product/unsync`,
      method_api: "POST",
      body: JSON.stringify({
        id_select: unlinkedItem[unlinkedItem.length - 1]?.id_select,
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          toast.success("با موفقیت انجام شد");
          clearTimeout(this.updateLinkedDatasTimeout);

          this.updateLinkedDatasTimeout = setTimeout(() => {
            // 
          this.setState({ confirmUnlink: false, relationshipProducts: relationshipProducts.filter((el: any) =>  unlinkedItem.every((f:any) => 
            f.db_name !== el?.db_name))})
          this.getPriceList()
          }, 500);
          

        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  handleSelectedSite = (item :any , index :number , index2:number) => {
    const {siteList} = this.props;
    const newState = [...this.state.priceListData];
    const selectedSite = [...item.relationship_products];
    if(selectedSite[index2].site_select === null  || selectedSite[index2].site_select === false){
      selectedSite[index2].site_select = true
    }else{
      if(siteList[0]?.value?.name === selectedSite[index2]?.db_name)
      {
        toast.error("سایت اول باید همیشه روشن باشد!")
      }
      else{

        selectedSite[index2].site_select = false
      }
    }
      newState[index].relationship_products = selectedSite
      this.setState({ priceListData: newState })
    }

    handleSelectedSiteModal = (index:number) => {      
      const {selectedRow , priceListData,relationshipProducts} = this.state;
      const newState = [...priceListData];
      const selectedSite = [...relationshipProducts];
      if(selectedSite[index].site_select === null  || selectedSite[index].site_select === false){
        selectedSite[index].site_select = true
      }else{
        selectedSite[index].site_select = false
      }
        newState[selectedRow].relationship_products = selectedSite;

        this.setState({ priceListData: newState, relationshipProducts: selectedSite }) 
    }
  
    //Single sync
    siteSelectedCount = (rowData:any) => {
       ("siteSelectedCount")
    const driversSelectedList = [];
    if(this.state.syncStatus === 1){
      for (var i = 0; i < rowData?.relationship_products?.length; i++) {
        if(rowData?.relationship_products[i]?.site_select === true)
        driversSelectedList.push(rowData?.relationship_products[i]?.db_name)
      }
      if(driversSelectedList.length>0){
        return true
      }else{
        return false
      }
    }else{
      return true
    }
    }
  async handleDeleteSingleSync() {
     
    const { rowData } = this.state;
    const driversSelectedList = [];
    for (var i = 0; i < rowData?.relationship_products?.length; i++) {
      if(rowData?.relationship_products[i]?.site_select === true)
      driversSelectedList.push(rowData?.relationship_products[i]?.db_name)
    }
    call_api({
      address_api: `${AppConstants.base_url_api}seller/sync-products/${rowData.id}`,
      method_api: "DELETE",
      body: JSON.stringify({
        mId: rowData.mId,
        db_name:driversSelectedList.toString(),
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
            toggleModal: { status: !this.state.toggleModal.status, buttonClick: alertButtonsState.DELETE }
          })
          this.getPriceList();

        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  async handleUnLinkSingleSync() {
     

    const { rowData } = this.state;
    const driversSelectedList = [];
    for (var i = 0; i < rowData?.relationship_products?.length; i++) {
      if(rowData?.relationship_products[i]?.site_select === true)
      driversSelectedList.push(rowData?.relationship_products[i]?.db_name)
    }
    call_api({
      address_api: `${AppConstants.base_url_api}seller/sync-products/unsync-custom`,
      method_api: "POST",
      body: JSON.stringify({
        mId: rowData.mId,
        db_name:driversSelectedList.toString(),
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
            toggleModal: { status: !this.state.toggleModal.status, buttonClick: alertButtonsState.UNLINK }
          })
          this.getPriceList();

        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  async handleRecycleSubmitSync() {
     
    
    const { rowData } = this.state;
    const driversSelectedList = [];
    for (var i = 0; i < rowData?.relationship_products?.length; i++) {
      if(rowData?.relationship_products[i]?.site_select === true)
      driversSelectedList.push(rowData?.relationship_products[i]?.db_name)
    }
    call_api({
      address_api: `${AppConstants.base_url_api}seller/sync-products/recovery`,
      method_api: "POST",
      body: JSON.stringify({
        mId: rowData.mId,
        db_name:driversSelectedList.toString(),


      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          toast.success(j.message);
          this.getPriceList();
          this.setState({
            toggleModal: { status: !this.state.toggleModal.status, buttonClick: alertButtonsState.RECYCLE }
          })
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j.message);
          this.setState({
            toggleModal: { status: !this.state.toggleModal.status, buttonClick: alertButtonsState.RECYCLE }
          })
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async handleConfirmSingleSync() {
     

    const { rowData } = this.state;
    const driversSelectedList = [];
    for (var i = 0; i < rowData?.relationship_products?.length; i++) {
      if(rowData?.relationship_products[i]?.site_select === true)
      driversSelectedList.push(rowData?.relationship_products[i]?.db_name)
    }
    call_api({
      address_api: `${AppConstants.base_url_api}seller/sync-products/confirmed`,
      method_api: "PUT",
      body: JSON.stringify({
        db_name:driversSelectedList.toString(),
        confirmed: rowData.confirmed === 1 ? '0' : '1',
        mId: rowData.mId,
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
            toggleModal: { status: !this.state.toggleModal.status, buttonClick: alertButtonsState.NOTCONFIRMED }
          })
          this.getPriceList();
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  async handleChangeStatusSingleSync() {
     

    const { rowData } = this.state;
    const driversSelectedList = [];
    for (var i = 0; i < rowData?.relationship_products?.length; i++) {
      if(rowData?.relationship_products[i]?.site_select === true)
      driversSelectedList.push(rowData?.relationship_products[i]?.db_name)
    }
    call_api({
      address_api: `${AppConstants.base_url_api}seller/sync-products/status`,
      method_api: "POST",
      body: JSON.stringify({
          status: rowData?.dont_sell?.name === 1 ? "do_sell" : "dont_sell",
          mId: rowData.mId,
          db_name:driversSelectedList.toString(),
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
            toggleModal: { status: !this.state.toggleModal.status, buttonClick: alertButtonsState.NOSELLING }
          })
          this.getPriceList();
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  async handleChangeMultipleStatusSync(state: string) {
     

    const { multiSelectedList } = this.state;
    call_api({
      address_api: `${AppConstants.base_url_api}seller/sync-products/status-update-multiple`,
      method_api: "POST",
      body: JSON.stringify({
        mId:JSON.stringify(multiSelectedList),
        status: state
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          toast.success("با موفقیت انجام شد ");
          this.getPriceList();
          this.setState({groupOpConfirm:{status: false, buttonState:""}})
          if(state === "do_sell"){
            this.setState({cellBg:"#bfc720"})
          }
          else{
              this.setState({cellBg:"#fcd110"})
          }
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async handleChangeMultipleLinkSync() {
    const { multiSelectedList } = this.state;
     

    call_api({
      address_api: `${AppConstants.base_url_api}seller/product/unsync-multiple`,
      method_api: "POST",
      body: JSON.stringify({
        mId:multiSelectedList,
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          toast.success("با موفقیت انجام شد ");
          this.getPriceList();
          this.setState({groupOpConfirm:{status: false, buttonState:""}, cellBg:"#fc6e10"})

        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async getTagSearch(search?:string) {
    this.setState({tagLoading: true})
     

    let parameters = {
      search: search ? search: null,
      is_show: JSON.stringify(1)
    }
    let url = new URL(`${AppConstants.base_url_api}seller/tags`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}seller/tags${url.search}` : `${AppConstants.base_url_api}seller/tags`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(async ([status, j]) => {
        if (status === 200) {
          if (j.data) {
              
              this.setState({
                tagList: j.data, tagLoading: false
              })
          }
        } else if (status === 401) {
          this.setState({ tagLoading: false })
          localStorage.clear();
        } else {
          this.setState({tagLoading: false })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  tagTimeout: string | number | NodeJS.Timeout | undefined;
  tagSelected = (choice: any) => {
     

    this.setState({ tag: choice, selectAllToggle:false, selectedList:[] })
    clearTimeout(this.tagTimeout);
    this.tagTimeout = setTimeout(() => {
      this.getPriceList();
    }, 1000);
  }
  tagSearchTimeout: string | number | NodeJS.Timeout | undefined;
  handleTagSearch = (choice:any) =>{
     

    clearTimeout(this.tagSearchTimeout);
    if(choice.length > 2)
    this.tagSearchTimeout = setTimeout(() => {
      this.getTagSearch(choice)
      this.setState({selectAllToggle:false, selectedList:[]})
    }, 1000);
  }
  async getTagList() {
     
    call_api({
      address_api:`${AppConstants.base_url_api}seller/tags?sync=1`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          if (j.data) {
            this.setState({
              tagList: j.data.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null),
            })
          }
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    };
    handleCloseEditModal =()=>{
      this.setState({editModal: false, showRows:[], editionSelectedRow:[]});
      this.getPriceList()
    }
    modalDriverTimeout: string | number | NodeJS.Timeout | undefined;
    modalDriverSelected = (choice: any) => {
      this.setState({ modalDriverId: choice, modalPage: 1 })
      clearTimeout(this.modalDriverTimeout);
      this.modalDriverTimeout = setTimeout(() => {
        this.getProductList()
      }, 1000);
    }
    modalFilterTimeout: string | number | NodeJS.Timeout | undefined;
    handleSearchEditModal = (event: string) => {
      this.setState({ editionSearch: event, modalPage:1 })
      clearTimeout(this.modalFilterTimeout);
      this.modalFilterTimeout = setTimeout(() => {
        this.getProductList()
      }, 1000);
    };
    addNewRelatedProduct=()=>{
      const {rows, editionSelectedRow, showRows} = this.state;
      const {siteList} = this.props;
      if(rows.id === ""){
        toast.error("!لطفاً یک محصول را انتخاب کنید")
      }
      else{
        const arr = [...showRows];
  
        call_api({
          address_api: `${AppConstants.base_url_api}seller/product/add-to-sync`,
          method_api: "POST",
          body: JSON.stringify({
            db_name: rows?.db_name,
            id: rows?.id,
            mId: editionSelectedRow[0]?.mId
          })
        })
          .then(parseJSON)
          .then(([status, j]) => {
            if (status === 200) {
              this.setState({ })
              toast.success("با موفقیت انجام شد ");
              editionSelectedRow.push(rows)
              arr.push(rows)
              this.setState({sites:siteList?.filter((el: any) =>  editionSelectedRow.every((f:any) => 
                f.db_name !== el?.value?.name)), showRows:arr})
                if(editionSelectedRow.length === siteList.length )
                {
                  this.setState({editModal:false, showRows:[], rows: {id:""}})
                   this.getPriceList()
                }
                else{
        
                  this.getProductList()
                }
            } else if (status === 401) {
              localStorage.clear();
    
            } else {
              toast.error(j?.message);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
      
    }
    modalPaginationTimeOut: string | number | NodeJS.Timeout | undefined; 
    handleChangeModalPage= (event: React.ChangeEvent<unknown>, value: number) => {
      this.setState({ modalPage: value })
      clearTimeout(this.modalPaginationTimeOut);
      this.modalPaginationTimeOut = setTimeout(() => {
        this.getProductList()
      }, 800);
    }
    
  modalPaginationSelectTimeOut: string | number | NodeJS.Timeout | undefined; 
  handleModalPaginationSelect=(event:any)=>{
    this.setState({
      modalRowCount: event.currentTarget.value,
      modalPage:1
    })
    clearTimeout(this.modalPaginationSelectTimeOut);
    this.modalPaginationSelectTimeOut = setTimeout(() => {
      this.getProductList()
    }, 800);
    
  }
  handleAdd = (data: any) => {
    const {rows} = this.state;
        if (rows?.id === data?.id) {
        this.setState({ rows:null  });
        }
        else{
          this.setState({rows: data})
        }
  }
  async getProductList() {
    const {modalDriverId , modalRowCount, modalPage, editionSearch, editionSelectedRow} = this.state;
    const {siteList} = this.props;
    this.setState({ modalLoading: true, productListData:[] })
    let parameters = {
      search: editionSearch ? editionSearch : null,
      page: modalPage ? modalPage.toString() : null,
      db_name: modalDriverId && Object.keys(modalDriverId).length > 0 ? modalDriverId.value?.name : siteList?.filter((el: any) =>  editionSelectedRow.every((f:any) => 
      f.db_name !== el?.value?.name))[0].value?.name,
      paginate: modalRowCount,
    }
 
    let url = new URL(`${AppConstants.base_url_api}seller/products`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    this.setState({ modalLoading: true })
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}seller/products${url.search}` : `${AppConstants.base_url_api}seller/products`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          if(j.data){

            this.setState({
              productListData: j.data?.data, modalLoading: false , editModaltotalRow: j.data?.total
            })
          }
        } else if (status === 401) {
          this.setState({ modalLoading: false })
          localStorage.clear();
        } else {
          this.setState({ modalLoading: false })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  callAPITimeout: string | number | NodeJS.Timeout | undefined;
  editRelationsProduct=()=>{
    const {siteList} = this.props;
    const {editionSelectedRow, relationshipProducts} = this.state;
    this.setState({editionSelectedRow: relationshipProducts, editModal: true, modalLoading:true})
    this.setState({sites:siteList?.filter((el: any) =>  relationshipProducts.every((f:any) => 
      f.db_name !== el?.value?.name))})
    clearTimeout(this.callAPITimeout);
    this.callAPITimeout = setTimeout(() => {

            this.getProductList()
    }, 800);
    
  }
    // increaseInputSlider = (rangeList:any) =>{
    //   this.setState(prevState => ({
    //     rangeSliderNumbers: [...prevState.rangeSliderNumbers, rangeList]
    //   }))

      
    // }
    // decreaseInputSlider = (rangeList:any) => {
    //   this.setState(prevState => ({
    //     rangeSliderNumbers: [...prevState.rangeSliderNumbers, rangeList]
    //   }))
    // }
  }
export default PriceListController;


