import { Component } from 'react';
import { call_api, parseJSON } from '../../../core/service';
import { AppConstants } from '../../../core/constants';
import { toast } from 'react-toastify';
import * as yup from 'yup'

interface IProps {
  dispatch?: any,
  siteList?: any,
  loadingSiteList?: any,
  userData?: any
}
interface IState {
  loading?: boolean,
  supportEmail: string;
  managementEmail: string;
  financialEmail: string;
  phoneOne: string;
  phoneTwo: string;
  phoneThree: string;
  smsSystem: string;
  fax: string;
  address: string;
  xCoordinate: string;
  yCoordinate: string;
  postalCode: string;
  telegram: string;
  instagram: string;
  whatsapp: string;
  linkdin: string;

  errorsList: any;
  data: any
}

class WebsiteInformationController extends Component<IProps, IState> {
  state: IState = {
    loading: false,

    supportEmail: "",
    managementEmail: "",
    financialEmail: "",
    phoneOne: "",
    phoneTwo: "",
    phoneThree: "",
    smsSystem: "",
    fax: "",
    address: "",
    xCoordinate: "",
    yCoordinate: "",
    postalCode: "",
    telegram: "",
    instagram: "",
    whatsapp: "",
    linkdin: "",
    errorsList: [],
    data: []
  };
  async getDate() {
    let parameters = {
      db_name: window.location.pathname.split("/")[3],
    }
    let url = new URL(`${AppConstants.base_url_api}admin/setting/website`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}admin/setting/website${url.search}` : `${AppConstants.base_url_api}admin/setting/website`,
      method_api: "GET",
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
            data: j.data[0],
            supportEmail: j.data[0].value?.email_support,
            managementEmail: j.data[0].value?.email_manager,
            financialEmail: j.data[0].value?.email_sale,
            phoneOne: j.data[0].value?.phone_one,
            phoneTwo: j.data[0].value?.phone_two,
            phoneThree: j.data[0].value?.phone_three,
            smsSystem: j.data[0].value?.sms_number,
            fax: j.data[0].value?.fax || "",
            address: j.data[0].value?.address,
            xCoordinate: j.data[0].value?.map_x,
            yCoordinate: j.data[0].value?.map_y,
            postalCode: j.data[0].value?.postal_code,
            telegram: j.data[0].value?.telegram,
            instagram: j.data[0].value?.instagram,
            whatsapp: j.data[0].value?.whatsapp,
            linkdin: j.data[0].value?.linkedin,
          })
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  async update() {
    const { data, supportEmail,
      managementEmail,
      financialEmail,
      phoneOne,
      phoneTwo,
      phoneThree,
      smsSystem,
      fax,
      address,
      xCoordinate,
      yCoordinate,
      postalCode,
      telegram,
      instagram,
      whatsapp,
      linkdin, } = this.state;
      const mobileRegExp = /^09\d{9}$/;
      const telegramRegExp = /^[a-zA-Z0-9_]{5,32}$/
      const instagramRegExp = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/
      const phoneRegExp = /^\d{5,12}$/;
      const faxRegExp = /^\d{10}$/;
      const redirectsSchema = yup.object({
        smsSystem:yup.string().max(255, ' نباید بیشتر از 255 رقم باشد'),
        fax:yup.string().notRequired().matches(faxRegExp, 'فرمت وارد شده صحیح نمی باشد').max(255, ' نباید بیشتر از 255 رقم باشد').nullable().transform((value) => !!value ? value : null),
        whatsapp: yup.string().notRequired().matches(mobileRegExp,  'فرمت وارد شده صحیح نمی باشد ').nullable().transform((value) => !!value ? value : null),
        telegram: yup.string().notRequired().max(32, ' نباید بیش از 32 کاراکتر داشته باشد').min(5, '   باید حداقل دارای 5 کاراکتر باشد').matches(telegramRegExp,  'فرمت وارد شده صحیح نمی باشد ').nullable().transform((value) => !!value ? value : null),
        instagram: yup.string().notRequired().max(30, ' نباید بیش از 30 کاراکتر داشته باشد').min(3, '   باید حداقل دارای 3 کاراکتر باشد').matches(instagramRegExp,  'فرمت وارد شده صحیح نمی باشد ').nullable().transform((value) => !!value ? value : null),
        phoneOne:yup.string().matches(phoneRegExp, 'فرمت وارد شده صحیح نمی باشد').max(255, ' شماره تلفن بیشتر از 255 رقم نمی باشد'),
        phoneTwo:yup.string().matches(phoneRegExp, 'فرمت وارد شده صحیح نمی باشد').max(255, ' شماره تلفن بیشتر از 255 رقم نمی باشد'),
        phoneThree:yup.string().matches(phoneRegExp, 'فرمت وارد شده صحیح نمی باشد').max(255, ' شماره تلفن بیشتر از 255 رقم نمی باشد'),
        supportEmail:yup.string().email("فرمت ایمیل صحیح نمی باشد"),
        managementEmail:yup.string().email("فرمت ایمیل صحیح نمی باشد"),
        financialEmail:yup.string().email("فرمت ایمیل صحیح نمی باشد"),
        linkdin:yup.string(),
        postalCode: yup.string().notRequired().matches(/^[0-9]{10}/, "کدپستی باید شامل ۱۰ عدد باشد.").nullable().transform((value) => (!!value ? value : null)),
        xCoordinate:yup.string(),
        yCoordinate:yup.string(),
        address:yup.string(),
    });
    try {
      await redirectsSchema.validate({ supportEmail,managementEmail,financialEmail ,phoneOne , phoneTwo , phoneThree,smsSystem,fax,address,xCoordinate,yCoordinate,postalCode,telegram,instagram,whatsapp,linkdin}, { abortEarly: false });
    call_api({
      address_api: `${AppConstants.base_url_api}admin/setting/website/${data.id_select}`,
      method_api: "PUT",
      body: JSON.stringify({
        email_support: supportEmail,
        email_manager: managementEmail,
        email_sale: financialEmail,
        phone_one: phoneOne,
        phone_two: phoneTwo,
        phone_three: phoneThree,
        sms_number: smsSystem,
        fax: fax,
        address: address,
        map_x: xCoordinate,
        map_y: yCoordinate,
        postal_code: postalCode,
        telegram: telegram,
        instagram: instagram,
        whatsapp: whatsapp,
        linkedin: linkdin,
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          toast.success("با موفقیت انجام شد ");
        this.setState({ errorsList:[] })      
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }
      catch (err: any) {
        this.setState({ errorsList: err.inner })      
      }
  }


}

export default WebsiteInformationController;