import { Component } from 'react';
import { call_api, parseJSON } from "../../../core/service";
import { AppConstants } from "../../../core/constants";
import { toast } from "react-toastify";
import * as yup from 'yup'

interface IProps {
dispatch?:any,
siteList?:any,
loadingSiteList?:any,
userData?:any
}
interface IState {
  loading?: boolean,
  errorsList:any;
  compressedData:any;
  compressedImageData:any;
  toggleModal:any;
  idSelect:number;
}

 class SiteSloganController extends Component<IProps, IState> {
  state: IState = {
    loading: false,
  errorsList:[],
  compressedData:{},
  compressedImageData:{
    slogan_one_image:"",
    slogan_two_image:"",
    slogan_three_image:"",
    slogan_four_image:"",
    slogan_five_image:"",
  },
  toggleModal : {},
  idSelect:1,


  };
  getPageData=()=> {
    this.setState({loading: true})
    let parameters = {
      db_name: window.location.pathname.split("/")[3],
    }
    let url = new URL(`${AppConstants.base_url_api}admin/setting/slogan`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}admin/setting/slogan${url.search}` : `${AppConstants.base_url_api}admin/setting/slogan`,
      method_api: "GET",
    })
      .then(parseJSON)
      .then(([status, j]) => {
        
        if (status === 200) {
          this.setState({compressedData: j?.data[0].value,idSelect: j?.data[0].id_select , loading:false})
        
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  handleDeleteImage = (name:string) => {
    
    this.setState({
      toggleModal: {
        status:true,
        name:name
      }
    })
}

async handleImageUpload(event: any, name:string) {
  const {compressedData,compressedImageData} = this.state;
  let news_image_file = null;
  news_image_file = event.target.files[0];
  const reader = new FileReader();
  reader.readAsDataURL(news_image_file);
  reader.onloadend = () => {
  this.setState({ compressedData: {...compressedData, [name]: reader.result }});
  }
  if (news_image_file) {
  this.setState({ compressedImageData: {...compressedImageData, [name]: news_image_file }});
  }
}
  deleteImage = (name:string) => {
    const {compressedData} = this.state;
      this.setState({
        compressedData: {...compressedData, [name]:""},
        toggleModal:{status: false, index:null}
      })
  }

  async handleUpdate() {

    const {idSelect, compressedData,compressedImageData} = this.state;


    const redirectsSchema = yup.object().shape({
      compressedData: yup.object().shape({
        slogan_one_title:yup.string().max(255, 'ادرس قدیمی  بیشتر از 255 رقم نمی باشد'),
        slogan_one_description: yup.string().max(255, 'ادرس قدیمی  بیشتر از 255 رقم نمی باشد'),
        slogan_one_url: yup.string().max(255, 'ادرس قدیمی  بیشتر از 255 رقم نمی باشد'),
        // slogan_one_image: yup.string().max(255, 'ادرس قدیمی  بیشتر از 255 رقم نمی باشد'),


        slogan_two_title:yup.string().max(255, 'ادرس قدیمی  بیشتر از 255 رقم نمی باشد'),
        slogan_two_description: yup.string().max(255, 'ادرس قدیمی  بیشتر از 255 رقم نمی باشد'),
        slogan_two_url: yup.string().max(255, 'ادرس قدیمی  بیشتر از 255 رقم نمی باشد'),
        // slogan_two_image: yup.string().max(255, 'ادرس قدیمی  بیشتر از 255 رقم نمی باشد'),



        slogan_three_title:yup.string().max(255, 'ادرس قدیمی  بیشتر از 255 رقم نمی باشد'),
        slogan_three_description: yup.string().max(255, 'ادرس قدیمی  بیشتر از 255 رقم نمی باشد'),
        slogan_three_url: yup.string().max(255, 'ادرس قدیمی  بیشتر از 255 رقم نمی باشد'),
        slogan_three_image: yup.string().max(255, 'ادرس قدیمی  بیشتر از 255 رقم نمی باشد'),


        slogan_four_title:yup.string().max(255, 'ادرس قدیمی  بیشتر از 255 رقم نمی باشد'),
        slogan_four_description: yup.string().max(255, 'ادرس قدیمی  بیشتر از 255 رقم نمی باشد'),
        slogan_four_url: yup.string().max(255, 'ادرس قدیمی  بیشتر از 255 رقم نمی باشد'),
        // slogan_four_image: yup.string().max(255, 'ادرس قدیمی  بیشتر از 255 رقم نمی باشد'),



        slogan_five_title:yup.string().max(255, 'ادرس قدیمی  بیشتر از 255 رقم نمی باشد'),
        slogan_five_description: yup.string().max(255, 'ادرس جدید  بیشتر از 255 رقم نمی باشد'),
        slogan_five_url: yup.string().max(255, 'ادرس جدید  بیشتر از 255 رقم نمی باشد'),
        // slogan_five_image: yup.string().required("ادرس جدید الزامی می باشد.").max(255, 'ادرس جدید  بیشتر از 255 رقم نمی باشد'),


        })
  });
  try {
      
    await redirectsSchema.validate({ compressedData }, { abortEarly: false });



    const data = new FormData()
    data.append('slogan_one_title', compressedData?.slogan_one_title)
    data.append('slogan_one_description', compressedData?.slogan_one_description)
    data.append('slogan_one_url', compressedData?.slogan_one_url)
    data.append('slogan_one_image',compressedImageData?.slogan_one_image)

    data.append('slogan_two_title', compressedData?.slogan_two_title)
    data.append('slogan_two_description', compressedData?.slogan_two_description)
    data.append('slogan_two_url', compressedData?.slogan_two_url)
    data.append('slogan_two_image',compressedImageData?.slogan_two_image)

    data.append('slogan_three_title', compressedData?.slogan_three_title)
    data.append('slogan_three_description', compressedData?.slogan_three_description)
    data.append('slogan_three_url', compressedData?.slogan_three_url)
    data.append('slogan_three_image', compressedImageData?.slogan_three_image)

    data.append('slogan_four_title', compressedData?.slogan_four_title)
    data.append('slogan_four_description', compressedData?.slogan_four_description)
    data.append('slogan_four_url', compressedData?.slogan_four_url)
    data.append('slogan_four_image',compressedImageData?.slogan_four_image)

    data.append('slogan_five_title', compressedData?.slogan_five_title)
    data.append('slogan_five_description', compressedData?.slogan_five_description)
    data.append('slogan_five_url', compressedData?.slogan_five_url)
    data.append('slogan_five_image', compressedImageData?.slogan_five_image)

    data.append("db_name", window.location.pathname.split("/")[3]);


          let url = new URL(`${AppConstants.base_url_api}admin/setting/slogan/${idSelect.toString()}`)
          call_api({
            address_api: url.search ? `${AppConstants.base_url_api}admin/setting/slogan/${idSelect.toString()}${url.search}` : `${AppConstants.base_url_api}admin/setting/slogan/${idSelect.toString()}`,
            method_api: "POST",
            body:data,
            file:true
          })
            .then(parseJSON)
            .then(async ([status, j]) => {
              if (status === 200) {
                toast.success("بنر با موفقیت به‌روزرسانی شد.");
                this.getPageData();
              } else if (status === 401) {
                localStorage.clear();
              } else {
                toast.error(j?.errors);
              }
            })
            .catch((error) => {
              console.error(error);
            });
          }
          catch (err: any) {
            this.setState({ errorsList: err.inner })      
          }
      }
}

export default SiteSloganController;