import { CustomButton , DropDownIcon, InputTextLabel, Header, Option} from "../../../components";
import {ContactUsController} from "../../../controllers";
import { Box, createTheme, ThemeProvider } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { AiOutlineFileExcel } from "react-icons/ai";
import Pagination from '@mui/material/Pagination';
import {contactUsColumns} from "../../../../core/column";
import Select, { CSSObjectWithLabel } from "react-select";
import Tooltip from '@mui/material/Tooltip';
import { connect } from "react-redux";
import {TfiFilter} from "react-icons/tfi";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { exportToExcel } from "../../../../core/utils";
class ContactUs extends ContactUsController {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getList()
  }

  render() {
    const { search, showFilters,allOption, page, total, loading, dreiverId, rowCount, rowCountList, messagesList } = this.state;
      const { siteList, loadingSiteList  } = this.props;

      const theme = createTheme({
      direction: "ltr",
      typography: {
        fontFamily:
          'Vazir',
      }
    });    

    return (
      <HelmetProvider>
      <ThemeProvider theme={theme}>
        <div className="users-info">
        <Helmet>
          <title>پیام‌های تماس با ما</title>
        </Helmet>
          <Header title="پیام‌های تماس با ما"/>
          <div className="custom-card mb-3">   
              {showFilters && 
                  <>
              <div className="filter-select-container mb-2 pe-3">
                <div className="filter-options-container">
                <div className="column-50"> 
                    <Select
                      styles={{
                        control: (baseStyles: any, state: any):CSSObjectWithLabel => ({
                          ...baseStyles,
                          boxShadow:"rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",
                          borderWidth: "1px",
                          backgroundColor: "#fff",
                          display: "flex",
                          flexDirection: "row-reverse",
                          textAlign: "right",
                          borderRadius: "4px",
                          outline: "unset",
                        }) as CSSObjectWithLabel,
                      }}
                      onChange={this.driverSelected}
                      components={{ Option }}
                      value={dreiverId}
                      isMulti={true}
                      closeMenuOnSelect={true}
                      noOptionsMessage={() => 'این مورد وجود ندارد!'}
                      menuPortalTarget={document.body}
                      options={[ allOption, ...siteList]}
                      getOptionLabel={(option: any) => option?.value?.persian_name}
                      getOptionValue={(option: any) => option?.value?.name}
                      placeholder="سایت"
                      isClearable
                      loadingMessage={() => 'در حال بارگذاری  ...'}
                      isLoading={loadingSiteList}
                    />

                    </div> 
                    <div className="column-50">
                  <InputTextLabel
                    type={"text"}
                    value={search}
                    onChange={(event) => this.handleSearch(event)}
                    placeholder="جستجو..."
                  />
                </div>
                  
                </div>
                
              </div>
              </> }
          
              <div className="table-container">
             
                <MaterialReactTable
                  muiTablePaperProps={{
                    sx: { m: '1.3rem', border:"1px solid #e5e5e5" }}}
                  columns={contactUsColumns}
                  muiTableProps={{
                    sx: {
                      border: "1px solid #e5e5e5",
                
                    },
                  }}
                  muiTableHeadCellProps={{
                    sx: {
                      height:"fit-content",
                      border: "1px solid #e5e5e5",
                    },
                  }}
                  muiTableBodyRowProps={( {row,staticRowIndex} ) => ({
                    sx:{
                      height: "fit-content",
                      backgroundColor: (staticRowIndex % 2 === 0 ) ? '#f3f3f3'  : "",
                  }})}
                  muiTableBodyCellProps={{
                    sx: {
                      height:"fit-content",
                      border: "1px solid #e5e5e5",
                      textAlign:"center"

                      
                    },
                  }}
                  displayColumnDefOptions={{
                    'mrt-row-expand': {
                      muiTableHeadCellProps: {
                        align: 'center',
                      },
                    },
                  }}
                  data={messagesList && messagesList?.length > 0 &&
                    messagesList?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null).map((item: any, index: number) =>
                     {
                      return ({
                        item: item,
                        id: item.id,
                        name:item?.name,
                        driver: <img className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo} alt=""/>,
                        mobile: item?.tel,
                        email:item.email,
                        title: item?.subject,
                        date:item?.created_at
                      }
                      );
                    }
                    )}
                    enablePagination={false}
                    enableRowNumbers={true}
                    rowNumberMode="static"
                renderBottomToolbarCustomActions={({table})=>{
                  return (
                    <div className="custom-pagination w-100">
                     <Pagination  page={page} count={total ? Math.ceil(total / rowCount) : 1} onChange={this.handleChangePage} />
                      <DropDownIcon
                      value={rowCount}
                      optionList={rowCountList}
                      title={""}
                      onChange={(event) =>
                        {
                          this.handlePaginationSelect(event)
                      }
                    }
                    />
                    </div>
                  );
                }}
                  positionExpandColumn={'last'}
                  enableGlobalFilter={false}
                  enableColumnFilters={false}
                  enableDensityToggle={false}
                  enableFullScreenToggle={false}
                  enableHiding={false}
                  renderTopToolbarCustomActions={({ table }) => (
                    <Box
                      sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap', justifyContent:"space-between", width:"100%" }}
                    >
                      <CustomButton
                        loading={false}
                        onClick={() => exportToExcel(messagesList, "Excel Export")}
                        title="اکسل"
                        myStyle="excel-btn"
                        icon={<AiOutlineFileExcel />}
                      />
                      <Tooltip title="نمایش فیلترها"><span> <TfiFilter className='filter-icon' onClick={() => this.setState(prevState => ({
                                  showFilters: !prevState.showFilters
                                }))
                              }/></span></Tooltip>
                      <div className='custom-pagination'>
                      <Pagination  page={page} count={total ? Math.ceil(total / rowCount) : 1} onChange={this.handleChangePage} />
                  <DropDownIcon
                  value={rowCount}
                  optionList={rowCountList}
                  title={""}
                  onChange={(event) =>
                    {
                      this.handlePaginationSelect(event)
                  }
                }
                />
                    </div>
                    </Box>
                  )}
                  localization={{
                    noRecordsToDisplay: "داده‌ای برای نمایش وجود ندارد!",
                  }}
                  enableColumnActions={false}
                  renderDetailPanel={({ row }) => (
                    <div key={row.original?.item?.id} className="detail-container">
                      <div className="detail-col text-breack-space text-center
                       " >
                        <span className="detail-row">
                        {row?.original?.item?.description}
                       </span>
                      </div>
                    </div>
                  )}
                  enableRowActions={false}
                  sortDescFirst={true}
                  enableSorting={true}
                  state={{ isLoading: loading, showProgressBars: loading }}
                  initialState={{
                    sorting: [
                      {
                        id: 'id',
                        desc: true,

                      },
                      {
                        id: 'date',
                        desc: true,

                      }
                    ],
                    pagination : {pageSize:100, pageIndex:0}
                  }}
                />
              </div>
          </div>
        </div>
      </ThemeProvider>
      </HelmetProvider>
    );
  }
}
const mapStateToProps = (dispatch:any) => {
  return {
    dispatch,
    siteList:dispatch.general.siteList,
    loadingSiteList:dispatch.general.loadingSiteList,
  }
};
export default connect(mapStateToProps)(ContactUs);