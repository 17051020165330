import { HTMLAttributes, ReactElement} from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

interface Props extends HTMLAttributes<HTMLElement> {
  onClick?:any;
  description:string;
  borderColor:string;
  textColor:string;
  legrandCount?:number;
  ariaCount?:number;
  souhaCount?:number;
  ventoCount?:number;
  totalCount?:number;
  netranCount?:number;
  siteList?:any

}

const InvoicesCard = ({ventoCount,totalCount, netranCount, legrandCount,souhaCount, ariaCount, onClick, description, borderColor
    ,textColor, siteList}: Props):ReactElement => {
    return(
        <div className={`invoices-card radius-10 border-start border-0 border-3 ${borderColor}`}>
        
        <div className="invoices-card-body">
        <div className=" title">
                    
                    <h4 className={`mb-0 font-14 ${textColor}`}>{description}</h4>
                    <span className="px-1">
                    {totalCount}
                    </span>
        </div>
            <div className="items-container">
                <div className="filter-select-container">
                    <div className="filter-options-container">
                    <div className="invoices-card-column-25"> 
                <img alt="netran" className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === "netran")[0]?.value?.logo}/>

                    <span className="count">{netranCount}</span>
                    </div>
                    <div className="invoices-card-column-25"> 
                    <img alt="netran" className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === "legrand")[0]?.value?.logo}/>

                        <span className="count">{legrandCount}</span>
                    </div>
                    <div className="invoices-card-column-25"> 
                        <img className="logo" alt="netran" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === "aria")[0]?.value?.logo}/>
                        <span className="count">{ariaCount}</span>
                    </div>
                    <div className="invoices-card-column-25"> 
                    <img className="logo" alt="netran" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === "souha")[0]?.value?.logo}/>

                        <span className="count">{souhaCount}</span>
                    </div>
                    <div className="invoices-card-column-25"> 
                    <img className="logo"  alt="netran" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === "vento")[0]?.value?.logo}/>

                        <span className="count">{ventoCount}</span>
                    </div>
                    </div>
                </div>
                
                
            </div>
        </div>
     </div>
    )
}
const mapStateToProps = (dispatch:any) => {
    return {
      dispatch,
      siteList:dispatch.general.siteList,
    }
  };

export default connect(mapStateToProps)(InvoicesCard)




// import { HTMLAttributes, ReactElement} from "react";
// import { connect } from "react-redux";
// import { Link } from "react-router-dom";

// interface Props extends HTMLAttributes<HTMLElement> {
//   onClick?:any;
//   description:string;
//   borderColor:string;
//   textColor:string;
//   legrandCount?:number;
//   ariaCount?:number;
//   souhaCount?:number;
//   ventoCount?:number;
//   totalCount?:number;
//   link?: any;
//   siteList?:any

// }

// const InvoicesCard = ({ventoCount,totalCount, link, legrandCount,souhaCount, ariaCount, onClick, description, borderColor
//     ,textColor, siteList}: Props):ReactElement => {
//         const items = [{name:"aria", count:4}, {name:"souha", count:100}, {name:"netran", count:20}, {name:"vento", count:200}, {name:"vento", count:10}]
//     return(
//         <div className={`invoices-card radius-10 border-start border-0 border-3 ${borderColor}`}>
        
//         <Link to={link}>         
//         <div className="invoices-card-body">
//         <div className=" title">
                    
//                     <h4 className={`mb-0 font-14 ${textColor}`}>{description}</h4>
//                     <span className="px-1">
//                     {totalCount}
//                     </span>
//         </div>
//             <div className="items-container">
//             <div className="filter-select-container">
//                     <div className="filter-options-container">
//                 {items.map((i:any, index:number)=>{
//                     return(
                    
//                     <div className="column-30"> 
//                 <img alt="netran" className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === i.name)[0]?.value?.logo}/>

//                     <span className="count">{i.count}</span>
//                 </div>
                
                
                
                
//                 )
//                 })}
//                 </div>
//                 </div>
//                 </div>
//                 {/* <div className="filter-select-container">
//                     <div className="filter-options-container">
//                     <div className="column-30"> 
//                 <img alt="netran" className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === "netran")[0]?.value?.logo}/>

//                     <span className="count">{legrandCount}</span>
//                 </div>
//                 <div className="column-30"> 
//                 <img alt="netran" className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === "legrand")[0]?.value?.logo}/>

//                     <span className="count">{legrandCount}</span>
//                 </div>
//                 <div className="column-30"> 
//                     <img className="logo" alt="netran" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === "aria")[0]?.value?.logo}/>
//                     <span className="count">{ariaCount}</span>
//                 </div>
//                 <div className="column-30"> 
//                 <img className="logo" alt="netran" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === "souha")[0]?.value?.logo}/>

//                     <span className="count">{souhaCount}</span>
//                 </div>
//                 <div className="column-30"> 
//                 <img className="logo" alt="netran" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === "vento")[0]?.value?.logo}/>

//                     <span className="count">{ventoCount}</span>
//                 </div>
//                     </div>
//                 </div> */}
                
                
//         </div>
//         </Link>
//      </div>
//     )
// }
// const mapStateToProps = (dispatch:any) => {
//     return {
//       dispatch,
//       siteList:dispatch.general.siteList,
//     }
//   };

// export default connect(mapStateToProps)(InvoicesCard)