import { Component } from "react";
import { createRef } from "react";
import { fileItems } from "../../pages/admin/fileManagement/data";
interface IProps { 
    dispatch?:any,
    siteList?:any,
    loadingSiteList?:any,
    userData?:any
  }
  interface IState {
search:string,
isPopupVisible: boolean,

  }
 class FileMangementController extends Component<IProps, IState> {
    fileManagerRef: React.RefObject<any>;
    
    constructor(props: any) {
        
      super(props);

      this.fileManagerRef = createRef();
      this.onItemClick = this.onItemClick.bind(this);
      this.createFile = this.createFile.bind(this);
      this.updateCategory = this.updateCategory.bind(this);
      this.state = { 
        search:"",
        isPopupVisible:false,
        }
    }
        
  

      get fileManager() {
        return this.fileManagerRef.current.instance;
      }
    
      onItemClick(itemData: { extension: any; category: undefined; }, viewArea: string, fileSystemItem: any ) {
        let updated = false;
    
        if (itemData.extension) {
          updated = this.createFile(itemData.extension, fileSystemItem);
        } else if (itemData.category !== undefined) {
          updated = this.updateCategory(itemData.category, fileSystemItem, viewArea);
        }
    
        if (updated) {
          this.fileManager.refresh();
        }
      }
    
      createFile(fileExtension: any, directory = this.fileManager.getCurrentDirectory()) {
        const newItem = {
          __KEY__: Date.now(),
          name: `New file${fileExtension}`,
          isDirectory: false,
          size: 0,
        };
    
        if (!directory.isDirectory) {
          return false;
        }
    
        let array:any = null;
        if (!directory.dataItem) {
          array = fileItems;
        } else {
          array = directory.dataItem.items;
          if (!array) {
            array = [];
            directory.dataItem.items = array;
          }
        }
    
        array.push(newItem);
        return true;
      }
    
      updateCategory(newCategory: any, directory: any, viewArea: string) {
        let items = null;
    
        if (viewArea === 'navPane') {
          items = [directory];
        } else {
          items = this.fileManager.getSelectedItems();
        }
    
        items.forEach((item: { dataItem: { category: any; }; }) => {
          if (item.dataItem) {
            item.dataItem.category = newCategory;
          }
        });
    
        return items.length > 0;
      }
       newFileMenuOptions = {
        items: [
          {
            text: 'Create new file',
            icon: 'plus',
            items: [
              {
                text: 'Text Document',
                extension: '.txt',
              },
              {
                text: 'RTF Document',
                extension: '.rtf',
              },
              {
                text: 'Spreadsheet',
                extension: '.xls',
              },
            ],
          },
        ],
        onItemClick: this.onItemClick.bind(this),
      };
      changeCategoryMenuOptions = {
        items: [
          {
            text: 'Category',
            icon: 'tags',
            items: [
              {
                text: 'Work',
                category: 'Work',
              },
              {
                text: 'Important',
                category: 'Important',
              },
              {
                text: 'Home',
                category: 'Home',
              },
              {
                text: 'None',
                category: '',
              },
            ],
          },
        ],
        onItemClick: this.onItemClick.bind(this),
      };
 
}

export default FileMangementController;