import React, { HTMLAttributes, ReactElement, useState} from "react";
import { Checkbox, CustomButton, InputIcon } from ".";
import {  FaUpload } from "react-icons/fa";
import Select from "react-select";
import { Editor } from "@tinymce/tinymce-react";
import { BsFillTrashFill } from "react-icons/bs";

interface Props extends HTMLAttributes<HTMLElement> {
    onClick?:any,
    setDatas?:any,
    setParentData?:any,
    details?:any,
    isCategory?:boolean
    noImage?:boolean
    parentList?:any,
    reload?:string
}
 function UpdateContent({details, parentList,setDatas,isCategory,reload,noImage}: Props): ReactElement {
  const [faqList,setFaq]= useState([{question:"",answer:""}])
  const [data,setData]= useState({
    id:details?.id,
    idSelect:details?.idSelect,
    persianName:details?.persianName,
englishName:details?.englishName,
slug:details?.slug,
seoTitle:details?.seoTitle,
seoDesc:details?.seoDesc,
description:details?.description,
parent:details?.parent,
url:details?.url,
 noIndex:details?.noIndex,
  showFaq:details?.showFaq,
  dbName:details?.dbName,
  faq:details?.faq,
  image: details?.image,
  uploadImage: details?.uploadImage,
  errorsList: details?.errorsList,


})
React.useEffect(() => {
  setDatas(data)

}, [data])

React.useEffect(() => {
  setData({ 
    persianName:details.persianName,
    idSelect:details.idSelect,
      id:details.id,
      englishName:details.englishName,
      slug:details.slug,
      seoTitle:details.seoTitle,
      seoDesc:details.seoDesc,
      description:details.description,
      parent:details.parent,
      url:details.url,
       noIndex:details.noIndex,
        showFaq:details.showFaq,
  dbName:details.dbName,
  faq:details.faq,
  image: details.image,
  uploadImage: details.uploadImage,
  errorsList: details.errorsList,


      }
  )
  setFaq(details.faq)

}, [reload])
  const addFaq = () => {
  
    const newFaq = [...faqList];
    if(details.showFaq)
    {

      newFaq.push({question:"",answer:""});
      setFaq(newFaq)
    }
  }

  const changeFaq = (event:any,index:any) => {
    const newFaq = [...faqList];
    const { name, value } = event.currentTarget;
if(name === "question"){
  newFaq[index]["question"] = value

}else{

  newFaq[index]["answer"] = value
}
setFaq(newFaq)
setData({...data, faq:newFaq})
// setData(...data,faq:newFaq)
  }
  const uploadImage = (event: any) => {
    let news_image_file: any = null;
    news_image_file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(news_image_file);
    reader.onloadend = () => {
      setData({...data, image:reader.result , uploadImage:news_image_file})
      }
  }
  const deleteNewImage = () => {
    setData({...data,
      image: "", uploadImage: ""
    })
  }
  const removeFaq = (index:any) => {
    const newFaq = [...faqList];
    newFaq.splice(index,1)
    setFaq(newFaq)
    setData({...data, faq:newFaq})
      }
    return (
        <div className="update-content">
                        <InputIcon
                          myStyle="column-25"
                          name="persianName"
                          value={data.persianName}
                          onChange={e=> setData({...data, persianName:e.target.value, slug:e.target.value})}
                          title={"نام فارسی "}
                          inBox={"نام فارسی"}
                          type={"text"}
                  errortext={details.errorsList && details.errorsList?.filter((obj: any) => obj.path.includes(`persianName`))[0]?.message}
                        />
                        <InputIcon
                       myStyle="column-25"
                       name="englishName"
                       onChange={e=> setData({...data, englishName:e.target.value})}
                          value={data.englishName}
                          title={"نام انگلیسی"}
                          inBox={"نام انگلیسی"}
                          type={"text"}
                          errortext={details.errorsList && details.errorsList?.filter((obj: any) => obj.path.includes(`englishName`))[0]?.message}
                          
                        />
                         <InputIcon
                       myStyle="column-25"
                       onChange={e=> setData({...data, slug:e.target.value})}
                       value={data.slug}
                          name="slug"
                          title={"نامک"}
                          inBox={"نامک"}
                          type={"text"}
                  errortext={details.errorsList && details.errorsList?.filter((obj: any) => obj.path.includes(`slug`))[0]?.message}
                        />
                        {isCategory && 
                        <Select
                        className="column-25"
                        name="parent"
                          styles={{
                            control: (baseStyles: any, state: any):any => ({
                              ...baseStyles,
                              boxShadow:
                                "rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",
                              borderWidth: "1px",
                              backgroundColor: "#fff",
                              display: "flex",
                              flexDirection: "row-reverse",
                              textAlign: "right",
                              borderRadius: "4px",
                              minHeight: "20px",
                              outline: "unset",
                              
                            }),
                            menuPortal: (base):any => ({ ...base, zIndex: 9999 })
                            
                          }}
                          onChange={e=> setData({...data, parent:e})}
                          
                          value={details?.parent}
                          isMulti={false}
                          closeMenuOnSelect={true}
                          noOptionsMessage={() => "این مورد وجود ندارد!"}
                          menuPortalTarget={document.body}
                          options={ parentList}
                          getOptionLabel={(option: any) =>
                            option?.persian_name
                          }
                          getOptionValue={(option: any) => option?.id_select}
                          placeholder="والد"
                          isClearable
                          loadingMessage={() => "در حال بارگذاری  ..."}
                          // isLoading={loadingSiteList}
                        />
                      }
                         <Editor
                         
                apiKey="bnl71iiv8jm79p88zww9qa0rvj9u4jz5zywkym0jj16uvli4"
                initialValue={details?.description}
                init={{
                  width:"100%",
                  toolbar: 'undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                  plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
                  editimage_cors_hosts: ['picsum.photos'],
                  menubar: 'file edit view insert format tools table help',
                  toolbar_sticky: true,
                  autosave_ask_before_unload: true,
                  autosave_interval: '30s',
                  autosave_prefix: '{path}{query}-{id}-',
                  autosave_restore_when_empty: false,
                  autosave_retention: '2m',
                  image_advtab: true,
                  importcss_append: true,
                  directionality : 'rtl',
                  language:"fa"
                }}
                onChange={e=> setData({...data, description:e.target.getContent()})}
                />

                          <InputIcon
                       myStyle="column-25 mt-4"
                       onChange={e=> setData({...data, seoTitle:e.target.value})}
                       value={data.seoTitle}
                          title={"عنوان سئو"}
                          inBox={"عنوان سئو"}
                          type={"text"}
                           name="seoTitle"
                          
                        />

                          <InputIcon
                       myStyle="column-25 mt-4"
                       onChange={e=> setData({...data, seoDesc:e.target.value})}
                       value={data.seoDesc}
                          title={"توضیحات سئو"}
                          inBox={"توضیحات سئو"}
                          type={"text"}
                           name="seoDesc"
                           
                          
                        />

<InputIcon
                       myStyle="column-25 mt-4"
                       onChange={e=> setData({...data, url:e.target.value})}
                       value={data.url}
                          title={"آدرس URL Canonical"}
                          inBox={"آدرس URL Canonical"}
                          type={"text"}
                           name="url"
                           
                          
                        />
                        <div className="column-25 text-end mt-4">
                            <Checkbox 
                            title={"No Index"}
                            onClick={()=> setData({...data, noIndex:!data.noIndex})}
                              isActive={data.noIndex}
                            />
                          </div>
                          <div className="column-25 text-end">
                              
                            <Checkbox 
                            title={"سوالات متداول نمایش داده شود؟ (faq)"}
                            onClick={()=> setData({...data, showFaq:!data.showFaq})}
                              isActive={data.showFaq}
                            />
                          </div> 
                          

                          <CustomButton myStyle="mb-1" title={"سوال جدید"} onClick={()=>addFaq()}/>
{data.showFaq && faqList?.map((item: any, index: number) => {

      return( 
    <div className="faq-item">
                          <InputIcon
                       myStyle="column-50"
                          value={item.question}
                          title={`سوال ${index + 1}`}
                          inBox={`سوال ${index + 1}`}
                          name="question"
                          type={"text"}
                          onChange={(event:any) => changeFaq (event,index)}
                          icon={<BsFillTrashFill className="icon-green"/>}
                          onClick={()=>removeFaq(index)}

                        />
                         <InputIcon
                       myStyle="column-50"
                          value={item.answer}
                          title={`جواب ${index + 1}`}
                          inBox={`جواب ${index + 1}`}
                          type={"text"}
                          name="answer"
                          onChange={(event :any) =>
                            changeFaq (event,index)
                          }
                        />
                          </div>
  )
})}
                          
                         
                        {noImage ? null :  
                          <div className="col-md-12 col-sm-12  pe-3">
                  {details.image ? (
                    <div className="image-upload-card">
                      <div
                      className="action"
                        onClick={() => deleteNewImage()}
                      >
                     <BsFillTrashFill className="icon-red"  />

                      </div>
                      <img alt="" src={details.image} className="img" />
                    </div>
                  )  :  (<div className="upload-container">

<div className="upload-btn">
                   <>
               <input
                  name={`file-${details.id}`}
                  type="file"
                   id={`file-${details.id}`}
                   key={`file-${details.id}`}
                  accept="image/*"
                  hidden
                  onChange={(event)=> uploadImage(event)}
                 
                />
              <label htmlFor={`file-${details.id}`}>
               
                <div className="upload-btn">
                <FaUpload/>
                 <span>انتخاب تصویر</span>
                 
                 </div>
                </label>
                <span className="input-icon-error">
                    {/* {errorsList && errorsList?.filter((obj: any) => obj.path === "paymentImageUpload")[0]?.message} */}
                </span>
                </>
                </div>
                 
                  
                </div>)
                
                }
                </div>
                }
              
      </div>
    )
}
export default UpdateContent;