import {
  HTMLAttributes,
  ReactElement,
  useState,
} from "react";
import CustomButton from "./button";
import { RxCross2 } from "react-icons/rx";
import { FiAlertTriangle } from "react-icons/fi";
import { Box, Modal } from "@mui/material";

interface Props extends HTMLAttributes<HTMLElement> {
  details?: string;
  code?: number;
  onClickRoute?: any;
  onClick?: any;
  img?: any;
  setClose?: any;
}


  const VitrinCard = ({details, code,onClickRoute,onClick,setClose,img,}: Props): ReactElement => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(true);
  const handleClose = () => {
    setModal(false);
  }
  const confirm = () => {
    setModal(false);
    onClick();

  };
  return (
    <>

      <div className="vitrin-card col-md-3 col-lg-2 col-12">
        <Modal
          open={modal}
          onClose={() =>setModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"

        >
           <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    overflow: "scroll",
                    width: {
                      xxs: "90%",
                      xs: "90%",
                      sm: "90%",
                      md: "80%",
                      lg: "70%",
                      xl: "70%",
                    },
                    height: "max-content",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                  }}
                >
          <div className="vitrin-card-modal-body">


            <div>
              <FiAlertTriangle className="icon" />
            </div>
            <h4 className="name-card-section" >
              اخطار
            </h4>

            <span className="detail-card-section">
              آیا از حذف این محصول از لیست مطمئن اید؟
            </span>
            <div className="d-flex w-100">
              <CustomButton
                myStyle="btn"
                loading={false}
                onClick={confirm}
                title="بله مطمنم!"
              />
              <CustomButton
                myStyle="btn"
                loading={false}
                onClick={handleClose}
                title="انصراف"
              />
            </div>
          </div>
          </Box>
        </Modal>
        <div className="card card-block w-100">
          <div className="card-header">
            <h4 className="card-title">شناسه: {code}</h4>
            <RxCross2 className="vitrin-card-btn" onClick={toggle} />
          </div>
          <img src={img} alt="..." />
          <p className="card-text" onClick={onClickRoute}>{details}</p>
        </div>
      </div>
    </>
  );
}
export default VitrinCard;
