
import { CustomButton, LableIcon, LoadingSpinner, TextAreaLabel, Header ,InputIcon, Option} from "../../../components";
import {  BsStars } from "react-icons/bs";
import Select, { CSSObjectWithLabel } from "react-select";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { connect } from "react-redux";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {StoreSettingsController} from "../../../controllers";

class StoreSettings extends StoreSettingsController {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { loading,driverId ,
      alopeyk,
      tipax,
      post,
      porterage,
      order_date,
      order_hours,
      order_days,
      official_invoice,
      tax,
      invoice_tax,
      receive_address_one,
      receive_address_three,
      receive_address_two,
      receive_label_one,
      receive_label_three,
      receive_label_two,
      receive_person_one,
      receive_person_three,
      receive_person_two,
      receive_phone_one,
      receive_phone_three,
      receive_phone_two,
      factor_info_address,
      factor_info_email,
      factor_info_phone,
      factor_info_postal,
      factor_info_site,
      factor_info_title,
      isSend,
      errorsList} = this.state;
      const { siteList , loadingSiteList} = this.props;
    return (
      <HelmetProvider>
      <div className="vitrin">
        <Helmet>
                <title> تنظیمات فروشگاه  </title>
            </Helmet>
          <Header title="تنظیمات فروشگاه"/>
        <>
              <div className="">
              <div className="column-30">
              <Select
                  styles={{
                        control: (baseStyles, state):CSSObjectWithLabel => ({
                          ...baseStyles,
                          boxShadow:"rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",

                          borderWidth: "1px",
                          backgroundColor: "#fff",
                          display: "flex",
                          flexDirection: "row-reverse",
                          textAlign: "right",
                          borderRadius: "4px",
                          height:"35px",
                          minHeight:"20px",                         
                          outline: "unset",
                        }) as CSSObjectWithLabel,
                      }}
                      onChange={(choice) => this.driverSelected(choice)}
                      components={{ Option }}
                      value={driverId}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      noOptionsMessage={() => 'این مورد وجود ندارد!'}
                      menuPortalTarget={document.body}
                      options={siteList }
                      getOptionLabel={(option: any) => option?.value?.persian_name}
                      getOptionValue={(option: any) => option?.value?.name}
                      placeholder="سایت"
                      isClearable={false}
                      loadingMessage={() => 'در حال بارگذاری  ...'}
                      isLoading={loadingSiteList}
              />
                    <div className="input-icon-error text-end">
                      {errorsList && errorsList?.filter((obj: any) => obj.path === "driverId")[0]?.message}
                    </div>

              </div>
              {loading ? (
            <LoadingSpinner />
          ) : (
            <>
            <br/>
                <LableIcon title="روش های ارسال کالا" icon={<BsStars />} />

                <FormControlLabel sx={{ direction: "ltr" }} control={<Switch
                  checked={alopeyk}
                  onChange={(event) => this.setState({
                    alopeyk: event.target.checked
                 })}
                  inputProps={{ 'aria-label': 'controlled' }}
                />}
                  label="الو پیکارسال کالا توسط الو پیک" />


                <FormControlLabel sx={{ direction: "ltr" }} control={<Switch
                  checked={tipax}
                  onChange={(event) => this.setState({
                    tipax: event.target.checked
                 })}
                  inputProps={{ 'aria-label': 'controlled' }}
                />}
                  label="تیپاکس ارسال کالا توسط تیپاکس" />

                <FormControlLabel sx={{ direction: "ltr" }} control={<Switch
                  checked={porterage}
                  onChange={(event) => this.setState({
                    porterage: event.target.checked
                 })}
                  inputProps={{ 'aria-label': 'controlled' }}
                />}
                  label="باربری ارسال کالا توسط باربری" />

                <FormControlLabel sx={{ direction: "ltr" }} control={<Switch
                  checked={post}
                  onChange={(event) => this.setState({
                    post: event.target.checked
                 })}
                  inputProps={{ 'aria-label': 'controlled' }}
                />}
                  label="پست ارسال کالا توسط اداره پست" />

                <div className="row">
            <br/>

                  <LableIcon title="زمان ارسال کالا" icon={<BsStars />} />
                  
                  <div className="col-md-4 col-sm-12 pe-3">

                    <FormControlLabel sx={{ direction: "ltr" }} control={<Switch
                      checked={order_date}
                      onChange={(event) => this.setState({
                        order_date: event.target.checked
                     })}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />}
                      label="نمایش تاریخ ارسال سفارش" />
                  </div>

                  <div className="col-md-4 col-sm-12 pe-3">
                    <FormControlLabel sx={{ direction: "ltr" }} control={<Switch
                      checked={order_hours}
                      onChange={(event) => this.setState({
                        order_hours: event.target.checked
                     })}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />}
                      label="نمایش ساعت ارسال سفارش" />
                  </div>
                  <div className="col-md-4 col-sm-12 pe-3">
                    <InputIcon
                      value={order_days}
                      title= {"تعداد روز های پیشنهادی ارسال کالا"} 
                      inBox={"تعداد روز های پیشنهادی ارسال کالا"}
                      type={"number"}
                      
                      onChange={(event) =>
                        this.setState({
                          order_days: event.currentTarget.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="row">
            <br/>

                <LableIcon title="فاکتور رسمی و مالیات" icon={<BsStars />} />
                  
                  <div className="col-md-4 col-sm-12 pe-3">

                  <FormControlLabel sx={{ direction: "ltr" }} control={<Switch
                  checked={official_invoice}
                  onChange={(event) => this.setState({
                    official_invoice: event.target.checked
                 })}
                  inputProps={{ 'aria-label': 'controlled' }}
                />}
                  label="درخواست فاکتور رسمی" />
                  </div>

                  <div className="col-md-4 col-sm-12 pe-3">
                  <FormControlLabel sx={{ direction: "ltr" }} control={<Switch
                  checked={tax}
                  onChange={(event) => this.setState({
                    tax: event.target.checked
                 })}
                  inputProps={{ 'aria-label': 'controlled' }}
                />}
                  label="محاسبه مالیات بر فاکتور" />
                  </div>
                  <div className="col-md-4 col-sm-12 pe-3">
                    <InputIcon
                      value={invoice_tax}
                      title= {"مالیات فاکتور"} 
                      inBox={"مالیات فاکتور"}
                      type={"number"}
                      onChange={(event) =>
                        this.setState({
                          invoice_tax: event.currentTarget.value,
                        })
                      }
                    />
                  </div>
                </div>

                
                

                
                <br/>

                <LableIcon title="اطلاعات نمایش فاکتور" icon={<BsStars />} />
                <div className="row">
                      <div className="col-md-4 col-sm-12 pe-3">
                        <InputIcon
                          value={factor_info_title}
                          title={"عنوان فروشگاه "}
                          inBox={"عنوان فروشگاه"}
                          type={"text"}
                          name="factor_info_title"
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "factor_info_title")[0]?.message}
                          
                          onChange={(event) =>
                            this.setState({
                              factor_info_title: event.currentTarget.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-md-4 col-sm-12 pe-3">
                        <InputIcon
                          value={factor_info_site
                          }
                          title={"آدرس وبسایت"}
                          inBox={"آدرس وبسایت"}
                          type={"text"}
                          name="factor_info_site"
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "factor_info_site")[0]?.message}
                          
                          onChange={(event) =>
                            this.setState({
                              factor_info_site: event.currentTarget.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-md-4 col-sm-12 pe-3">
                        <InputIcon
                          value={factor_info_email}
                          title={"ایمیل فروشگاه"}
                          inBox={"ایمیل فروشگاه"}
                          type={"text"}
                          name="factor_info_email"
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "factor_info_email")[0]?.message}
                          
                          onChange={(event) =>
                            this.setState({
                              factor_info_email: event.currentTarget.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-md-4 col-sm-12 pe-3">
                        <InputIcon
                          value={factor_info_phone}
                          title={"تلفن تماس فروشگاه"}
                          inBox={"تلفن تماس فروشگاه"}
                          type={"number"}
                          name="factor_info_phone"
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "factor_info_phone")[0]?.message}
                          
                          onChange={(event) =>
                            this.setState({
                              factor_info_phone: event.currentTarget.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-md-4 col-sm-12 pe-3">
                        <InputIcon
                          value={factor_info_postal}
                          title={"کد پستی فروشگاه"}
                          inBox={"کد پستی فروشگاه"}
                          type={"number"}
                          name="factor_info_postal"
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "factor_info_postal")[0]?.message}
                          
                          onChange={(event) =>
                            this.setState({
                              factor_info_postal: event.currentTarget.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-md-12 col-sm-12 pe-3">

                      <TextAreaLabel
                      placeholder="آدرس فروشگاه"
                      value={factor_info_address}
                          onChange={(event: { currentTarget: { value: any; }; }) =>
                            this.setState({
                              factor_info_address: event.currentTarget.value,
                            })
                          }
                          
                          />
                    </div>
                    </div>


            <br/>
                <LableIcon title="آدرس های مراکز دریافت" icon={<BsStars />} />
               

                <div className="row">
                      <div className="col-md-4 col-sm-12 pe-3">
                        <InputIcon
                          value={receive_label_one}
                          title={"عنوان آدرس "}
                          inBox={"عنوان آدرس"}
                          type={"text"}
                          
                          onChange={(event) =>
                            this.setState({
                              receive_label_one: event.currentTarget.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-md-4 col-sm-12 pe-3">
                        <InputIcon
                          value={receive_phone_one
                          }
                          title={"تلفن تماس"}
                          inBox={"تلفن تماس"}
                          type={"number"}
                          name="receive_phone_one"
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "receive_phone_one")[0]?.message}
                          
                          onChange={(event) =>
                            this.setState({
                              receive_phone_one: event.currentTarget.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-md-4 col-sm-12 pe-3">
                        <InputIcon
                          value={receive_person_one}
                          title={"تحویل دهنده"}
                          inBox={"تحویل دهنده"}
                          type={"text"}
                          
                          onChange={(event) =>
                            this.setState({
                              receive_person_one: event.currentTarget.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-md-12 col-sm-12 pe-3">

                      <TextAreaLabel
                      placeholder="نشانی"
                      value={receive_address_one}
                          onChange={(event: { currentTarget: { value: any; }; }) =>
                            this.setState({
                              receive_address_one: event.currentTarget.value,
                            })
                          }
                          
                          />
                    </div>
                    </div>

                    <hr/>
                    <div className="row">
                      <div className="col-md-4 col-sm-12 pe-3">
                        <InputIcon
                          value={receive_label_two}
                          title={"عنوان آدرس "}
                          inBox={"عنوان آدرس"}
                          type={"text"}
                          
                          onChange={(event) =>
                            this.setState({
                              receive_label_two: event.currentTarget.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-md-4 col-sm-12 pe-3">
                        <InputIcon
                          value={receive_phone_two
                          }
                          title={"تلفن تماس"}
                          inBox={"تلفن تماس"}
                          type={"number"}
                          name="receive_phone_two"
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "receive_phone_two")[0]?.message}
                          
                          onChange={(event) =>
                            this.setState({
                              receive_phone_two: event.currentTarget.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-md-4 col-sm-12 pe-3">
                        <InputIcon
                          value={receive_person_two}
                          title={"تحویل دهنده"}
                          inBox={"تحویل دهنده"}
                          type={"text"}
                          
                          onChange={(event) =>
                            this.setState({
                              receive_person_two: event.currentTarget.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-md-12 col-sm-12 pe-3">

                      <TextAreaLabel
                      placeholder="نشانی"
                      value={receive_address_two}
                          onChange={(event: { currentTarget: { value: any; }; }) =>
                            this.setState({
                              receive_address_two: event.currentTarget.value,
                            })
                          }
                          
                          />
                    </div>
                    </div>
                    <hr/>
                    <div className="row">
                      <div className="col-md-4 col-sm-12 pe-3">
                        <InputIcon
                          value={receive_label_three}
                          title={"عنوان آدرس "}
                          inBox={"عنوان آدرس"}
                          type={"text"}
                          
                          onChange={(event) =>
                            this.setState({
                              receive_label_three: event.currentTarget.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-md-4 col-sm-12 pe-3">
                        <InputIcon
                          value={receive_phone_three}
                          title={"تلفن تماس"}
                          inBox={"تلفن تماس"}
                          type={"number"}
                          name="receive_phone_three"
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "receive_phone_three")[0]?.message}
                          
                          onChange={(event) =>
                            this.setState({
                              receive_phone_three: event.currentTarget.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-md-4 col-sm-12 pe-3">
                        <InputIcon
                          value={receive_person_three}
                          title={"تحویل دهنده"}
                          inBox={"تحویل دهنده"}
                          type={"text"}
                          
                          onChange={(event) =>
                            this.setState({
                              receive_person_three: event.currentTarget.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-md-12 col-sm-12 pe-3">
                        <TextAreaLabel
                        placeholder="نشانی"
                        value={receive_address_three}
                            onChange={(event: { currentTarget: { value: any; }; }) =>
                              this.setState({
                                receive_address_three: event.currentTarget.value,
                              })
                            }
                            
                            />
                    </div>
                    </div>
                    <br/>
                    <div className="btn-container">
              <CustomButton
                myStyle="submit-btn"
                loading={isSend}
                onClick={() => this.handleSubmit()}
                title="ثبت نهایی"
              />
            </div>
            </>
          )}
          
              </div>

        </>
      </div>
      </HelmetProvider>
    );
  }
}
const mapStateToProps = (dispatch:any) => {
  return {
    dispatch,
    siteList:dispatch.general.siteList,
    loadingSiteList:dispatch.general.loadingSiteList,
  }
};
export default connect(mapStateToProps)(StoreSettings);
