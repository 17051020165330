import { type MRT_ColumnDef } from 'material-react-table';
import moment from 'moment-jalaali';

type Order = {
    item: any;
    date: string;
    status: string;
    buyer: string;
    price: string;
    offPrice: string;
    carriage: string;
    finalPrice: string;
    pay: any;
    creator: string;
  };
  type ContactUs = {
    item: any;
    id: number;
    name:string;
    driver: string;
    mobile: string;
    email: string;
    title: string;
    date: string;

  };
  type Crud = {
    item: any;
    select: any;
    name: string;
    price: number;
    percent: number;
    finalPrice: number
  };
  type Payment = {
    item: any;
    driver: string;
    code: number;
    name: string;
    orderNumber: number;
    price: number;
    paymentStatus: any;
    date: string;
    status: any;
  };
  type Prefactor = {
    item: any;
    date: string;
    driver:any;
    buyer: string;
    finalPrice: string;
    applier: string;
  };
  type ProductRelations = {
    item: any;
    productName?:string,
    driver:string;
    mId: string;
    date: string;

  };
  type SupportTicket = {
    item: any;
    id: number;
    driver: string;
    title: string;
    user: string;
    priority: string;
    status: string;
    date: string;
  };
  type SystemEvents = {
    id: number;
    user: string;
    title: string;
    details: string;
    date: string;
  };
  type UsersComments = {
    item: any;
    id: number;
    driver: string;
    user: string;
    productName: string;
    status: string;
    date: string;
  };
  type Person = {
    item: any;
    id: number;
    mobile: string;
    name: string;
    role: string;
  };
  type AddressManagement = {
    item: any;
    id: number;
    driver: string;
    user: string;
    productName: string;
    status: string;
    date: string;
  };
  export const orderColumns =
    [
      {
        accessorKey: 'id',
        header: 'شناسه',
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        enableSorting:false,

      },
      {
        accessorKey: 'created_at',
        header: 'تاریخ',
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign:"center",
            }}
          >
            
            <span style={{paddingLeft:"15px"}}>
            {moment(cell.getValue<string>()).format("HH:mm:ss ")}
            </span>
            <span>
            {moment(cell.getValue<string>()).format("jYYYY/jM/jD")}
              
            </span>
          </div>
        ),
      },
      {
        accessorKey: 'driver',
        header: 'سایت',
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        size:5,
        enableSorting:false,

      },

      {
        accessorKey: 'buyer',
        header: 'خریدار',
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        enableSorting:false,

      },
      {
        accessorKey: 'finalPrice',
        header: 'مبلغ سفارش',
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),          
        invertSorting:true,
        sortingFn: (rowA, rowB, columnId) => {
          return Number(rowA.original.item?.finalPrice) - Number(rowB.original.item?.finalPrice)
        },
      },
      
      
      {
        accessorKey: 'pay',
        header: 'نوع پرداخت',
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              whiteSpace:"nowrap",
              fontSize:"11px"
            }}
          >
            {cell.getValue<string>()}

          </div>
        ),
        size:5,
        enableSorting:false,

      },
      
      {
        accessorKey: 'paymentStatus',
        header: 'وضعیت پرداخت',
        Cell: ({ cell, row }) => (
          <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: `${row?.original?.item?.payment_status?.name === 1 ?
                 "#e0ffde" : row?.original?.item?.payment_status?.name === 2 ?
                "#fff9b2" : row?.original?.item?.payment_status?.name === 3 ? "#ffc2c2" : "#fffff" }`,
                padding:"10px 74px",
                whiteSpace:"nowrap",

            }}
          >          
            {cell.getValue<string>()}

          </div>
      
          </>
        ),
        enableSorting:false,
      },    
      {
        accessorKey: 'status',
        header: 'وضعیت سفارش',
        Cell: ({ cell }) => (
          <div
            style={{display:"flex", justifyContent:"center", alignItems:"center"}}
          >
            {cell.getValue<string>()}
          </div>
        ),
        enableSorting:false,

      },     
      {
        accessorKey: 'creator',
        header: 'ایجادکننده',
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        enableSorting:false,

        
      },
    ] as MRT_ColumnDef<Order>[]
  
  export const contactUsColumns =
    [     
      {
        accessorKey: 'id',
        header: 'شناسه',
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        size:10,
      },
      {
        accessorKey: 'driver',
        header: 'سایت',
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        size:10,
        enableSorting:false,

      },
      {
        accessorKey: 'name',
        header: 'نام',
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        enableSorting:false,

      },
      {
        accessorKey: 'mobile',
        header: 'موبایل',
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        enableSorting:false,

      },

      {
        accessorKey: 'email',
        header: 'ایمیل',
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        enableSorting:false,

      },
      {
        accessorKey: 'title',
        header: 'عنوان',
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign:"center"
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        enableSorting:false,

      },
      {
        accessorKey: 'date',
        header: 'تاریخ',
        Cell: ({ cell }) => (
          <div
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign:"center",
            flexDirection:"column-reverse"
          }}
        >
          <span>
          {moment(cell.getValue<string>()).format("jYYYY/jM/jD")}
            
          </span>
          <span>
          {moment(cell.getValue<string>()).format("HH:mm:ss ")}
          </span>
        </div>
        ),
      }
     
    

    ] as MRT_ColumnDef<ContactUs>[]
  export const crudColumns =
      [
        {
          accessorKey: "row",
          header: "ردیف",
          Cell: ({ cell }) => (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {cell.getValue<string>()}
            </div>
          ),
          size: 5,
        },
        {
          accessorKey: "select",
          header: "انتخاب",
          Cell: ({ cell }) => (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {cell.getValue<string>()}
            </div>
          ),
          size: 10,
        },
        {
          accessorKey: 'driver',
          header: 'سایت',
          Cell: ({ cell }) => (
            <div
              style={{
                display: "flex",
                justifyContent: 'center',
              }}
            >
              {cell.getValue<string>()}
            </div>
          ),
          size:10
        },
        {
          accessorKey: 'name',
          header: 'نام محصول',
          Cell: ({ cell }) => (
            <div
              style={{
                display: "flex",
                justifyContent: 'flex-start',
                textAlign:"right",
                wordBreak: "break-word"

              }}
            >
              {cell.getValue<string>()}
            </div>
          )
        },

        {
          accessorKey: 'price',
          header: 'قیمت',
          Cell: ({ cell }) => (
            <div
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              {cell.getValue<string>()}
            </div>
          )
        },
        {
          accessorKey: 'percent',
          header: 'تخفیف',
          Cell: ({ cell }) => (
            <div
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              {cell.getValue<string>()}
            </div>
          ),
          size:10
        },
        {
          accessorKey: 'finalPrice',
          header: 'قیمت نهایی',
          Cell: ({ cell }) => (
            <div
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              {cell.getValue<string>()}
            </div>
          )
        },
    ] as MRT_ColumnDef<Crud>[]

  export const paymentColumns =
  [
    {
      accessorKey: 'driver',
      header: "سایت",
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: 'center',
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      size:10,
      enableSorting:false,

    },
    {
      accessorKey: 'code',
      header: 'شناسه',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      size:10,
      enableSorting:false,

    },
    {
      accessorKey: 'name',
      header: 'نام',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      enableSorting:false,

    },

    {
      accessorKey: 'orderNumber',
      header: 'شماره سفارش',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            cursor:"pointer",
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      size:10,
      enableSorting:false,

    },

    {
      accessorKey: 'price',
      header: 'قیمت',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      enableSorting:false,

    },
    {
      accessorKey: 'paymentStatus',
      header: 'شیوه پرداخت',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      enableSorting:false,

    },
    {
      accessorKey: 'date',
      header: 'زمان',
      Cell: ({ cell }) => (
        <div
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign:"center",
        }}
      >
         <span style={{paddingLeft:"10px"}}>
        {moment(cell.getValue<string>()).format("HH:mm:ss ")}
        </span>
        <span>
        {moment(cell.getValue<string>()).format("jYYYY/jM/jD")}
          
        </span>
       
      </div>
      )
    },
    {
      accessorKey: 'status',
      header: 'وضعیت',
      Cell: ({ cell , row}) => (
        <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding:"10px 0 "
        }}
      >
        {cell.getValue<string>()}
      </div>            
      ),
      enableSorting:false,

    },

    ] as MRT_ColumnDef<Payment>[]

  export const prefactorColumns =
  [
    {
      accessorKey: 'id',
      header: 'شناسه',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      size:10,
      enableSorting:false,

    },
    {
      accessorKey: 'created_at',
      header: 'تاریخ',
      Cell: ({ cell }) => (
        <div
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign:"center",
          flexDirection:"column-reverse",
        }}
      >
        <span>
        {moment(cell.getValue<string>()).format("jYYYY/jM/jD")}
          
        </span>
        <span>
        {moment(cell.getValue<string>()).format("HH:mm:ss ")}
        </span>
      </div>
      ),
      size:10,

    },
    {
      accessorKey: 'driver',
      header: 'سایت',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: 'center',
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      size:10,
      enableSorting:false,

    },
    
    {
      accessorKey: 'buyer',
      header: 'خریدار',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            whiteSpace: "pre"

            
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      size:15,
      enableSorting:false,

    },
    {
      accessorKey: 'totalPrice',
      header: 'مبلغ کل',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            whiteSpace: "pre"

          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      size:10,
      enableSorting:false,


    },
    {
      accessorKey: 'totalDiscount',
      header: 'تخفیف',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            whiteSpace: "pre"

          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      size:10,
      enableSorting:false,


    },
    {
      accessorKey: 'finalPrice',
      header: 'مبلغ نهایی',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      enableSorting:false,

    },

    {
      accessorKey: 'applier',
      header: 'ایجادکننده',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      size:15,
      enableSorting:false,

    },
   
  ] as MRT_ColumnDef<Prefactor>[]

  export const productRelationsColumns =
  [
    {
      accessorKey: 'driver',
      header: 'سایت',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      size:5,
      enableSorting:false,

    },
    {
      accessorKey: 'id',
      header: 'شناسه',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      size:15,
    },
    {
      accessorKey: 'mId',
      header: 'شناسه لینک',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      size:5,
      enableSorting:false,

    },
    {
      accessorKey: 'productName',
      header: 'نام محصول',
      Cell: ({ cell }) => (
        <div style={{display:"flex",justifyContent: "center", whiteSpace:"pre"}}>
          {cell.getValue<string>()}
        </div>
      )
      ,
      size:10,
    },
   
  ] as MRT_ColumnDef<ProductRelations>[]

  export const supportTicketColumns =
  [
    {
      accessorKey: 'id',
      header: "شناسه",
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: 'center',
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      size:10,
    },
    {
      accessorKey: 'driver',
      header: "سایت",
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: 'center',
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      size:10,
      enableSorting:false,

    },
    {
      accessorKey: 'title',
      header: 'عنوان',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: 'center',
            whiteSpace:"pre"
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      enableSorting:false,

    },
    {
      accessorKey: 'user',
      header: 'کاربر',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: 'center',

          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      enableSorting:false,

    },

    {
      accessorKey: 'priority',
      header: 'اولویت',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "right"
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      size:5,
      enableSorting:false,

    },
    {
      accessorKey: 'status',
      header: 'وضعیت',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            whiteSpace:"pre"
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      size:15,
      enableSorting:false,

    
    },
    {
      accessorKey: 'date',
      header: 'تاریخ ارسال تیکت',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign:"center",
            flexDirection:"row-reverse"
          }}
        >
          <span >
          {moment(cell.getValue<string>()).format("jYYYY/jM/jD")} 
            
          </span>
          <span style={{paddingLeft:"10px"}}>
          {moment(cell.getValue<string>()).format("HH:mm:ss ")}
          </span>
        </div>
      )
    },
  ] as MRT_ColumnDef<SupportTicket>[] 

  export const systemEventsColumns =
  [
    {
      accessorKey: 'id',
      header: 'شناسه',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: 'center',
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      size:1,
    },
    {
      accessorKey: 'user',
      header: 'کاربر',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: 'center',

          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      enableSorting:false,

    },
    {
      accessorKey: 'title',
      header: 'عنوان',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "right"
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      enableSorting:false,

    },
    {
      accessorKey: 'details',
      header: 'توضیخات',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      size:10,
      enableSorting:false,
    
    },
    {
      accessorKey: 'date',
      header: 'تاریخ ',
      Cell: ({ cell }) => (
        <div
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign:"center",
        }}
      >
        <span style={{paddingLeft:"10px"}}>
        {moment(cell.getValue<string>()).format("HH:mm:ss ")}
        </span>
        <span>
        {moment(cell.getValue<string>()).format("jYYYY/jM/jD")}
          
        </span>
        
      </div>
      ),
      size:5
    },
  ] as MRT_ColumnDef<SystemEvents>[]

  export const usersCommentsColumns =
  [
    {
      accessorKey: 'id',
      header: 'شناسه',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: 'center',
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      size:5,
      enableSorting:false,

    },
    
    {
      accessorKey: 'driver',
      header: "سایت",
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: 'center',
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      size:10,
      enableSorting:false,

    },
    {
      accessorKey: 'user',
      header: 'کاربر',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: 'center',

          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      enableSorting:false,

    },
    {
      accessorKey: 'productName',
      header: 'محصول',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "right"
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      enableSorting:false,

    },
    {
      accessorKey: 'status',
      header: 'وضعیت',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      size:5,
      enableSorting:false,

    },
    {
      accessorKey: 'date',
      header: 'تاریخ ارسال نظر',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign:"center",
            flexDirection:"column",
          }}
        >
          <span>
            {moment(cell.getValue<string>()).format("HH:mm:ss ")}
          </span>
          <span>
            {moment(cell.getValue<string>()).format("jYYYY/jM/jD")}
            
          </span>
        
        </div>
      ),
      size:10
    },
  ] as MRT_ColumnDef<UsersComments>[]

  export const usersInfoColumns =
  [
    {
      accessorKey: 'id',
      header: 'شناسه',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      size:10
    },
    {
      accessorKey: 'driver',
      header: 'سایت',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      size:10
    },
    {
      accessorKey: 'name',
      header: 'نام',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          {cell.getValue<string>()}
        </div>
      )
    },

    {
      accessorKey: 'mobile',
      header: 'همراه',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          {cell.getValue<string>()}
        </div>
      )
    },
    {
      accessorKey: 'role',
      header: 'نقش',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          {cell.getValue<string>()}
        </div>
      )
    },
   
  

  ] as MRT_ColumnDef<Person>[]

  export const addressManagementColumns =
  [
    {
      accessorKey: 'id',
      header: 'شناسه',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: 'center',
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      enableSorting:false,

    },
    
    {
      accessorKey: 'driver',
      header: "سایت",
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: 'center',
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      enableSorting:false,

    },
    {
      accessorKey: 'user',
      header: 'کاربر',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: 'center',

          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      enableSorting:false,

    },
    {
      accessorKey: 'addressTitle',
      header: 'عنوان آدرس',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "right"
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
      enableSorting:false,

    },
    {
      accessorKey: 'date',
      header: 'تاریخ ایجاد',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign:"center",
          }}
        >
          <span style={{
            paddingLeft:"10px"
          }}>
            {moment(cell.getValue<string>()).format("HH:mm:ss ")}
          </span>
          <span>
            {moment(cell.getValue<string>()).format("jYYYY/jM/jD")}
            
          </span>
        
        </div>
      ),
    },
  ] as MRT_ColumnDef<AddressManagement>[]