
import { CustomButton, ButtonTab, TextAreaLabel,DropDownIcon, Header, Option } from "../../../components";
import {SupportTicketController} from "../../../controllers";
import { createTheme, ThemeProvider, Tooltip } from '@mui/material';
import { InputTextLabel } from "../../../components";
import { MaterialReactTable } from 'material-react-table';
import { FaUpload } from "react-icons/fa";
import Pagination from '@mui/material/Pagination';
import Modal from '@mui/material/Modal';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import moment from 'moment-jalaali';
import { AiOutlineCheckCircle, AiOutlineComment, AiOutlineFile } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import Select from "react-select";
import { FiAlertTriangle } from "react-icons/fi";
import { SubmissionAlert } from "../../../components";
import { MdBlockFlipped } from "react-icons/md";
import { connect } from "react-redux";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {TfiFilter} from "react-icons/tfi";
import { supportTicketColumns } from "../../../../core/column"
class supportTicket extends SupportTicketController {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getTicketDataList(0);
  }
  render() {
    const { siteList , loadingSiteList} = this.props;
    const { page, showFilters, total, rowCount,commentImageUpload, openCommentsModal,
      dreiverId,rowCountList,toggleModal, errorsList, loading , commentImage, currentItem,
      search, step, comment, ticketDataList } = this.state;
    const theme = createTheme({
      typography: {
        fontFamily:
          'Vazir',
      }
    });
    return (
      <HelmetProvider>
      <ThemeProvider theme={theme}>
        <div className="support-ticket">
          <Helmet>
                <title>تیکت پشتیبانی</title>
            </Helmet>            
          <Header title="تیکت پشتیبانی"/>
          <div className=" custom-card mb-3">
            <div>
            {showFilters && 
            <>
            <div className="filter-select-container mb-2 pe-3">
            <div className="filter-options-container">
              <div className="column-25">
              <ButtonTab
                title="همه"
                isActive={step === 0 ? true : false}
                icon={<AiOutlineCheckCircle />}
                onClick={() => this.handleSetStep(0)}
                count={ticketDataList?.all}
              />
              </div>              
              <div className="column-25">
              <ButtonTab
                title="در انتظار پاسخ"
                icon={<AiOutlineCheckCircle />}
                isActive={step === 1 ? true : false}
                onClick={() => this.handleSetStep(1)}
                count={ticketDataList.watining}
              />
              </div>              
              <div className="column-25">
              <ButtonTab
                title="پاسخ داده شده"
                icon={<AiOutlineCheckCircle />}
                isActive={step === 2 ? true : false}
                onClick={() => this.handleSetStep(2)}
                count={ticketDataList.answered}
              />
              </div>
              <div className="column-25">

              <ButtonTab
                title="بسته شده"
                icon={<AiOutlineCheckCircle />}
                isActive={step === 3 ? true : false}
                onClick={() => this.handleSetStep(3)}
                count={ticketDataList.close}
              />
              </div>
            </div>
              <div className="filter-options-container">
              <div className="column-50">
            <Select
                      styles={{
                        control: (baseStyles, state):any => ({
                          ...baseStyles,
                          boxShadow:"rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",
                          borderWidth: "1px",
                          backgroundColor: "#fff",
                          display: "flex",
                          flexDirection: "row-reverse",
                          textAlign: "right",
                          borderRadius: "4px",
                          height:"35px",
                          minHeight:"20px",
                          outline: "unset",
                        }),
                        menuPortal: (base):any => ({ ...base, zIndex: 9999 })
                      }}
                      onChange={(choice) => this.driverSelected(choice)}
                      components={{ Option }}
                      value={dreiverId}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      noOptionsMessage={() => 'این مورد وجود ندارد!'}
                      menuPortalTarget={document.body}
                      options={siteList }
                      getOptionLabel={(option: any) => option?.value?.persian_name}
                      getOptionValue={(option: any) => option?.value?.name}
                      placeholder="سایت"
                      isClearable={false}
                      loadingMessage={() => 'در حال بارگذاری  ...'}
                      isLoading={loadingSiteList}
                      
                    />
                    </div>
                    <div className="column-50">
              <InputTextLabel
                type={"text"}
                value={search}
                onChange={(event) => this.handleSearch(event)}
                placeholder="جستجو..."
                myStyle="search-input"
                disabled={dreiverId && Object.keys(dreiverId).length > 0  ? false : true}
                
              />
              </div>
              </div>
            </div>
            </>
            }
              {toggleModal.status === true &&
                <SubmissionAlert isOpen={toggleModal.status} title="اخطار!" setClose={(open: boolean) => this.setState({ toggleModal: { status: open } })} onClick={() => this.handleClose()} icon={<FiAlertTriangle color="orange" fontSize="50" />}
                  details="از بستن این تیکت اطمینان دارید؟" />
              }
              <Modal
                open={openCommentsModal}
                onClose={this.handleCloseCommentsModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  overflowY:"scroll",
                  width: {
                    xxs: "90%",
                    xs: "90%",
                    sm: "90%",
                    md: "80%",
                    lg: "70%",
                    xl: "70%"
                  },
                  height: 'max-content',
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 4,
                }}>
                  <Box id="modal-modal-title" sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="subtitle1">
                      پیام‌ها
                    </Typography>
                      <img className="modal-logo-img" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === currentItem?.db_name)[0]?.value?.logo} alt="..." />
                  </Box>
                  <Grid container sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                    <TextAreaLabel
                      placeholder="پاسخ جدید خود را وارد کنید."
                      name="comment"
                      errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "comment")[0]?.message}
                      value={comment}
                      onChange={(event: { currentTarget: { value: any; }; }) =>
                        this.setState({
                          comment: event.currentTarget.value,
                        })
                      }
                    />
                    {commentImage ? (
                      <div className="comment-upload">
                        <div
                          className="mx-2"
                          onClick={() => this.handleDeleteFile()}
                        >
                          <BsFillTrashFill />
                          {commentImageUpload.name}
                        </div>
                      </div>
                    ) :
                    <>
                    <input
                          name=""
                          type="file"
                          id="file"
                          hidden
                          accept="png,jpg,doc,docx,pdf,xls,xlsx"
                          onChange={(event) =>
                            this.handleImageUpload(event)
                          }
                        />
                      <label htmlFor="file" className="comment-upload">
                        
                        <FaUpload />
                        <span className="mx-2">فایل ضمیمه</span>
                      </label>
                    </>
                    }

                  </Grid>
                  <Grid item sx={{ display: "flex", width: "100%" }} >
                    <CustomButton
                      myStyle='send-close-comment-btn'
                      loading={false}
                      onClick={() => this.handleSendComment("1", true)}
                      title="ارسال و بستن"
                    />
                    <CustomButton
                      myStyle='send-comment-btn'
                      loading={false}
                      onClick={() => this.handleSendComment("0", true)}
                      title="ارسال"
                    />
                  </Grid>
                  
                      <div  className="comments-container">
                      <div  className="w-100 d-flex" style={{ justifyContent: currentItem?.user_id === Number(window.localStorage.getItem('userId')) ? "flex-start" : "flex-end", paddingLeft:"25px" }}>
                        <Grid item sm={12} sx={{backgroundColor: currentItem?.user_id === Number(window.localStorage.getItem('userId')) ? "#d1e7dd" : "#f4f4f4", borderRadius: "10px", padding: "15px", marginTop: "20px", maxWidth: "75%", alignItems:"center" }}>
                          <Typography sx={{ textAlign: "right"}}>
                            <div dangerouslySetInnerHTML={{__html: currentItem.message}}></div>
                          </Typography>
                          <div className="w-100 d-flex" style={{ justifyContent: "center" }}>
                          {currentItem && currentItem?.files?.length > 0 && currentItem?.files?.map((child: any, index: number) => {
                            return (                     
                                <Grid item sm={12} sx={{ display:"flex", padding: "0px 15px", maxWidth: "84%" }} key={index}>                 
                                <Grid>
                                  <a
                                  href={`${child}`}
                                  download="Example-PDF-document"
                                  target="_blank"
                                  rel="noreferrer"
                                  >
                                {child.substring(child.length - 3) === "png" || child.substring(child.length - 3) === "jpg" ?
                                  <img alt="" style={{ width:"150px" , height:"100px" }} src={child}/>
                                  : 
                                  <AiOutlineFile style={{ width:"35px" , height:"35px" }} className="icon-comment"  />
                                  }
                                </a> 
                                </Grid>
                                </Grid>
                              
                            );
                          }
                          )}</div>
                          <Typography sx={{ textAlign: "left", marginTop: "10px" }}>
                            {moment(currentItem.created_at).format("jYYYY/jM/jD")}
                          </Typography>
                        </Grid>
                  </div>
                  {currentItem && currentItem?.children?.length > 0 && currentItem?.children?.map((child: any, index: number) => {
                    return (                     
                      <div key={index} className="w-100 d-flex" style={{ justifyContent: Number(window.localStorage.getItem('userId')) === child.user_id ? "flex-start" : "flex-end",  paddingLeft:"25px" }}>
                        <Grid item sm={12} sx={{ backgroundColor: Number(window.localStorage.getItem('userId')) === child.user_id ? "#d1e7dd" : "#f4f4f4", borderRadius: "10px", padding: "15px", marginTop: "20px", maxWidth: "75%" }} key={index}>
                          <Typography sx={{ textAlign: "right" }}>
                            <div dangerouslySetInnerHTML={{__html:child.message }}></div>
                          </Typography>
                          {child.files &&                          
                        <Grid>
                          <a
                          href={`${child.files}`}
                          download="Example-PDF-document"
                          target="_blank"
                          rel="noreferrer"
                          >
                         {child.get_type_file === "png" || child.get_type_file === "jpg" ?
                          <img alt="" style={{ width:"150px" , height:"100px" }} src={child.files}/>

                          : 
                          <AiOutlineFile style={{ width:"35px" , height:"35px" }} className="icon-comment"  />
                          }
                        </a> 
                        </Grid>
                        }
                          <Typography sx={{ textAlign: "left", marginTop: "10px" }}>
                            {moment(child.created_at).format("jYYYY/jM/jD")}
                          </Typography>
                        </Grid>
                      </div>
                      
                    );
                  }
                  )}
                  <div ref={this.myRef}></div>
                  </div>
            
                </Box>
              </Modal>
              <MaterialReactTable
                columns={supportTicketColumns}
                data={ticketDataList && ticketDataList?.tickets?.data?.length > 0 &&
                  ticketDataList?.tickets?.data?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null).map((item: any, index: number) =>
                   {
                    return ({
                      item: item,
                      id:item?.id,
                      driver:  <img className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo} alt="" /> , 
                      title: item?.subject,
                      user: item?.profile?.full_name,
                      priority: item?.priority?.value,
                      status: item?.status.value,
                      date: item.created_at && item.created_at,
                    }
                    );
                  }
                  )}
                  enablePagination={false}
                  rowNumberMode="static"
                  enableRowNumbers={true}
                  renderBottomToolbarCustomActions={({table})=>{
                  return (
                    <div className="custom-pagination w-100">
                      <Pagination  page={page} count={total ? Math.ceil(total / rowCount) : 1} onChange={this.handleChangePage} />
                      <DropDownIcon
                      value={rowCount}
                      optionList={rowCountList}
                      title={""}
                      onChange={(event) => { this.handlePaginationSelect(event)}
                    }
                    />
                    </div>
                  );
                }}
                 muiTablePaperProps={{
                sx: { mr: '1.5rem',ml: '1.5rem', border:"1px solid #e5e5e5", mt:'1.5rem' },
              }}
               muiTableProps={{
                  sx: {
                    border: "1px solid #e5e5e5",
                  },
                }}
                muiTableHeadCellProps={{
                  sx: {
                    height:"fit-content",
                    border: "1px solid #e5e5e5",
                    textAlign:"center"
                  },
                }}
                muiTableBodyRowProps={( {row,staticRowIndex} ) => ({
                  sx:{
                    height: "fit-content",
                    backgroundColor: (staticRowIndex % 2 === 0 ) ? '#f3f3f3'  : "",
                }})}
                rowVirtualizerProps={{ overscan: 20 }} 
                rowVirtualizerInstanceRef={this.tableInstanceRef} 
                enableRowVirtualization
                renderTopToolbarCustomActions={({ table }) => (
                  <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap', justifyContent:"space-between", width:"100%" }}
                  >
                <Tooltip title="نمایش فیلترها"><span> <TfiFilter className='filter-icon' onClick={() => this.setState(prevState => ({
                                  showFilters: !prevState.showFilters
                                }))
                              }/></span></Tooltip>
                <div className='custom-pagination'>
                  <Pagination  page={page} count={total ? Math.ceil(total / rowCount) : 1} onChange={this.handleChangePage} />
                    <DropDownIcon
                    value={rowCount}
                    optionList={rowCountList}
                    title={""}
                    onChange={(event) =>
                      {
                        this.handlePaginationSelect(event)
                    }
                  }
                />
                    </div>
                  </Box>
                  )}

                muiTableBodyCellProps={{
                  sx: {
                    border: "1px solid #e5e5e5",
                    textAlign:"center",
                    justifyContent:"center",
                  },
                }}
                state={{ isLoading: loading, showProgressBars: loading }}
                enableGlobalFilter={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableHiding={false}
                enableColumnActions={false}
                enableRowActions
                positionActionsColumn="last"
                localization={{
                  actions: 'عملیات',
                  noRecordsToDisplay:"داده‌ای برای نمایش وجود ندارد!"
                }}
                renderRowActions={({ row }) => [
                  <div  key={row && row?.original && row?.original?.item && row?.original?.item[0] && row?.original?.item[0]?.id} className='action-container'>
                    {(step === 3 || row?.original?.item?.status?.name === "3")  ?  <MdBlockFlipped size={18} /> :
                    <Tooltip title="بستن"><span><MdBlockFlipped className='icon-red' onClick={() => this.showModal(row.original.item)} /></span></Tooltip>}
                    <AiOutlineComment className="icon-green" onClick={() => this.handleComment(row.original.item)} />
                  </div>
                ]}
                sortDescFirst={true}
                enableSorting={true}
                initialState={{
                  pagination : {pageSize:100, pageIndex:0},sorting: [
                    {
                      id: "id",
                      desc: true
                    },
                    {
                      id: "date",
                      desc: true
                    }
                   
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </ThemeProvider >
      </HelmetProvider>
    );
  }
}
const mapStateToProps = (dispatch:any) => {
  return {
    dispatch,
    siteList:dispatch.general.siteList,
    loadingSiteList:dispatch.general.loadingSiteList,
  }
};
export default connect(mapStateToProps)(supportTicket);
