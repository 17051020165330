import { Component, PureComponent } from 'react';
import { call_api, parseJSON } from "../../../core/service";
import { AppConstants } from '../../../core/constants';
import { toast } from 'react-toastify';

interface IProps {
  dispatch?:any,
  siteList?:any,
  loadingSiteList?:any,
}
interface IState {
  loading?: boolean,

  selected1: string,
  selected2: string,
  selected3: string,
  searchColumnFour: string,
  searchColumnFive:string,

  toggleModal: boolean,
  isCreating: boolean;

  productListData1: any
  productLoading1: boolean;

  productListData2: any
  productLoading2: boolean;

  productListData3: any
  productLoading3: boolean;

  productListData4: any
  productLoading4: boolean;

  productListData5: any
  productLoading5: boolean;

  selectedIdList: Array<number>;
  selectedListColumnOne: any,
  selectedListColumnTwo:any,
  selectedListColumnThree:any,
  selectedListColumnFour:any,
  selectedListColumnFive:any,

  netranBrandId:any,
  legrandBrandId:any,
  ventoBrandId:any,
  souhaBrandId:any,
  ariaBrandId:any,

  netranBrandList:any,
  legrandBrandList:any,
  ventoBrandList:any,
  souhaBrandList:any,
  ariaBrandList:any,

  netranCategory:any,
  ventoCategory:any,
  souhaCategory:any,
  legrandCategory:any,
  ariaCategory:any,

  netranCategoryList:any,
  ventoCategoryList:any,
  souhaCategoryList:any,
  ariaCategoryList:any,
  legrandCategoryList:any,

  netranTag:any,
  ventoTag:any,
  souhaTag:any,
  ariaTag:any,
  legrandTag:any,

  netranTagList:any,
  ventoTagList:any,
  souhaTagList:any,
  ariaTagList:any,
  legrandTagList:any,

  tagLoading: boolean;


  

}

 class CreateProductsRelationController extends PureComponent<IProps, IState> {
  state: IState = {
    loading: false,
    toggleModal: false,
    isCreating: false,
    productListData1: [],
    productLoading1: false,
    productListData2: [],
    productLoading2: false,
    productListData3: [],
    productLoading3: false,
    productListData4: [],
    productLoading4: false,
    productListData5: [],
    productLoading5: false,
    selectedIdList: [],
    selectedListColumnOne: null,
    selectedListColumnTwo:null,
    selectedListColumnThree:null,
    selectedListColumnFour:null,
    selectedListColumnFive:null,
    selected1:"",
    selected2:"",
    selected3:"",
    searchColumnFour:"",
    searchColumnFive:"",
    
    netranBrandId:[],
    legrandBrandId:[],
    ventoBrandId:[],
    souhaBrandId:[],
    ariaBrandId:[],

    netranBrandList:[],
    legrandBrandList:[],
    ventoBrandList:[],
    souhaBrandList:[],
    ariaBrandList:[],

    netranCategory:[],
    ventoCategory:[],
    souhaCategory:[],
    legrandCategory:[],
    ariaCategory:[],

    netranCategoryList:[],
    ventoCategoryList:[],
    souhaCategoryList:[],
    ariaCategoryList:[],
    legrandCategoryList:[],
  
    netranTag:[],
    ventoTag:[],
    souhaTag:[],
    ariaTag:[],
    legrandTag:[],

    netranTagList:[],
    ventoTagList:[],
    souhaTagList:[],
    ariaTagList:[],
    legrandTagList:[],

    tagLoading: false,


}
  handleAria = (choice?: any) => {
  this.setState({selectedListColumnOne:{}, productLoading1: true  })
    const brandList = [];
    const {ariaBrandId, ariaCategory, ariaTag } = this.state;
    for (var a = 0; a < ariaBrandId.length; a++) {
      brandList.push(ariaBrandId[a]?.id)
    }
    const catrgoryList = [];
    for (var b = 0; b < ariaCategory?.length; b++) {
      catrgoryList.push(ariaCategory[b]?.id)
    }
    const tagsList = [];
    for (var c = 0; c < ariaTag?.length; c++) {
      tagsList.push(ariaTag[c]?.id)
    }
    let parameters = {
      db_name: "aria",
      brand_id: brandList.length > 0 ? brandList : null,
      search: choice ? choice : null,
      is_show: (choice  || brandList.length > 0 || catrgoryList?.length > 0 || tagsList?.length > 0) ? null  : 1,
      category_id: catrgoryList?.length > 0 ? catrgoryList : null,
      tag_id: tagsList?.length > 0 ? tagsList : null,
      sync: 1,

    }
    let url = new URL(`${AppConstants.base_url_api}seller/product/search`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }     
       
        call_api({
          address_api: url.search ? `${AppConstants.base_url_api}seller/product/search${url.search}` : `${AppConstants.base_url_api}seller/product/search`,
          method_api: "GET"
        })
          .then(parseJSON)
          .then(async ([status, j]) => {
            if (status === 200) {
              if (j.data) {
                  this.setState({
                    productListData1: j.data, productLoading1: false
                  })
              }
            } else if (status === 401) {
              this.setState({ productLoading1: false, productLoading2: false, productLoading3: false, productLoading4: false, productLoading5: false })
              localStorage.clear();
            } else {
              this.setState({ productLoading1: false, productLoading2: false, productLoading3: false, productLoading4: false, productLoading5: false })
              toast.error(j?.errors);
            }
          })
          .catch((error) => {
            console.error(error);
          });
  }

  handleSouha = (choice?: any) => {
    this.setState({productLoading2: true, selectedListColumnTwo:{}})
    const brandList = [];
    const {souhaBrandId, souhaCategory, souhaTag} = this.state;
    for (var a = 0; a < souhaBrandId.length; a++) {
      brandList.push(souhaBrandId[a]?.id)
    }
    const catrgoryList = [];
    for (var b = 0; b < souhaCategory?.length; b++) {
      catrgoryList.push(souhaCategory[b]?.id)
    }
    const tagsList = [];
    for (var c = 0; c < souhaTag?.length; c++) {
      tagsList.push(souhaTag[c]?.id)
    }
    let parameters = {
      db_name: "souha",
      brand_id: brandList.length > 0 ? brandList : null,
      search: choice ? choice : null,
      is_show: (choice  || brandList.length > 0 || catrgoryList?.length > 0 || tagsList?.length > 0) ? null  : 1,
      category_id: catrgoryList?.length > 0 ? catrgoryList : null,
      sync: 1,
      tag_id: tagsList?.length > 0 ? tagsList : null,

    }

    let url = new URL(`${AppConstants.base_url_api}seller/product/search`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
        call_api({
          address_api: url.search ? `${AppConstants.base_url_api}seller/product/search${url.search}` : `${AppConstants.base_url_api}seller/product/search`,
          method_api: "GET"
        })
          .then(parseJSON)
          .then(async ([status, j]) => {
            if (status === 200) {
              if (j.data) {
                  this.setState({
                    productListData2: j.data, productLoading2: false
                  })
              }
            } else if (status === 401) {
              this.setState({ productLoading1: false, productLoading2: false, productLoading3: false, productLoading4: false, productLoading5: false })
              localStorage.clear();
            } else {
              this.setState({ productLoading1: false, productLoading2: false, productLoading3: false, productLoading4: false, productLoading5: false })
              toast.error(j?.errors);
            }
          })
          .catch((error) => {
            console.error(error);
          });
  }

  handleVento = (choice?: any) => {
    this.setState({productLoading3: true, selectedListColumnThree:{}})
    const brandList = [];
    const {ventoBrandId, ventoCategory, ventoTag} = this.state;
    for (var a = 0; a < ventoBrandId.length; a++) {
      brandList.push(ventoBrandId[a]?.id)
    }
    const catrgoryList = [];
    for (var b = 0; b < ventoCategory?.length; b++) {
      catrgoryList.push(ventoCategory[b]?.id)
    }
    const tagsList = [];
    for (var c = 0; c < ventoTag?.length; c++) {
      tagsList.push(ventoTag[c]?.id)
    }
    let parameters = {
      db_name: "vento",
      brand_id: brandList.length > 0 ? brandList : null,
      search: choice ? choice : null,
      is_show: (choice  || brandList.length > 0 || catrgoryList?.length > 0 || tagsList?.length > 0) ? null  : 1,
      category_id: catrgoryList?.length > 0 ? catrgoryList : null,
      sync: 1,
      tag_id: tagsList?.length > 0 ? tagsList : null,


    }
    let url = new URL(`${AppConstants.base_url_api}seller/product/search`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
        call_api({
          address_api: url.search ? `${AppConstants.base_url_api}seller/product/search${url.search}` : `${AppConstants.base_url_api}seller/product/search`,
          method_api: "GET"
        })
          .then(parseJSON)
          .then(async ([status, j]) => {
            if (status === 200) {
              if (j.data) {
                  this.setState({
                    productListData3: j.data, productLoading3: false
                  })
              }
            } else if (status === 401) {
              this.setState({ productLoading1: false, productLoading2: false, productLoading3: false, productLoading4: false, productLoading5: false })
              localStorage.clear();
            } else {
              this.setState({ productLoading1: false, productLoading2: false, productLoading3: false, productLoading4: false, productLoading5: false })
              toast.error(j?.errors);
            }
          })
          .catch((error) => {
            console.error(error);
          });
  }

  handleLegrand = (choice?: any) => {
    this.setState({ productLoading4: true, selectedListColumnFour:{}})
    const brandList = [];
    const {legrandBrandId, legrandCategory, legrandTag} = this.state;
    for (var a = 0; a < legrandBrandId.length; a++) {
      brandList.push(legrandBrandId[a]?.id)
    }
    const catrgoryList = [];
    for (var b = 0; b < legrandCategory?.length; b++) {
      catrgoryList.push(legrandCategory[b]?.id)
    }
    const tagsList = [];
    for (var c = 0; c < legrandTag?.length; c++) {
      tagsList.push(legrandTag[c]?.id)
    }
    let parameters = {
      db_name: "legrand",
      brand_id: brandList.length > 0 ? brandList : null,
      search: choice ? choice : null,
      is_show: (choice  || brandList.length > 0 || catrgoryList?.length > 0 || tagsList?.length > 0) ? null  : 1,
      category_id: catrgoryList?.length > 0 ? catrgoryList : null,
      sync: 1,
      tag_id: tagsList?.length > 0 ? tagsList : null,
    }
    let url = new URL(`${AppConstants.base_url_api}seller/product/search`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
        call_api({
          address_api: url.search ? `${AppConstants.base_url_api}seller/product/search${url.search}` : `${AppConstants.base_url_api}seller/product/search`,
          method_api: "GET"
        })
          .then(parseJSON)
          .then(async ([status, j]) => {
            if (status === 200) {
              if (j.data) {
                  
                  this.setState({
                    productListData4: j.data, productLoading4: false
                  })
              }
            } else if (status === 401) {
              this.setState({ productLoading1: false, productLoading2: false, productLoading3: false, productLoading4: false, productLoading5: false })
              localStorage.clear();
            } else {
              this.setState({ productLoading1: false, productLoading2: false, productLoading3: false, productLoading4: false, productLoading5: false })
              toast.error(j?.errors);
            }
          })
          .catch((error) => {
            console.error(error);
          });
  }
  handleNetran = (choice?: any) => {
    this.setState({ productLoading5: true, selectedListColumnFive:{}})
    const netranBrand = [];
    const {netranBrandId, netranCategory, netranTag} = this.state;
    for (var a = 0; a < netranBrandId.length; a++) {
      netranBrand.push(netranBrandId[a]?.id)
    }
    const catrgoryList = [];
    for (var b = 0; b < netranCategory?.length; b++) {
      catrgoryList.push(netranCategory[b]?.id)
    }
    const tagsList = [];
    for (var c = 0; c < netranTag?.length; c++) {
      tagsList.push(netranTag[c]?.id)
    }
    let parameters = {
      db_name: "netran",
      brand_id: netranBrand.length > 0 ? netranBrand : null,
      search: choice ? choice : null,
      is_show: (choice  || netranBrand.length > 0 || catrgoryList?.length > 0 || tagsList?.length > 0) ? null  : 1,
      category_id: catrgoryList?.length > 0 ? catrgoryList : null,
      sync: 1,
      tag_id: tagsList?.length > 0 ? tagsList : null,
    }
    let url = new URL(`${AppConstants.base_url_api}seller/product/search`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
        call_api({
          address_api: url.search ? `${AppConstants.base_url_api}seller/product/search${url.search}` : `${AppConstants.base_url_api}seller/product/search`,
          method_api: "GET"
        })
          .then(parseJSON)
          .then(async ([status, j]) => {
            if (status === 200) {
              if (j.data) {
                  
                  this.setState({
                    productListData5: j.data, productLoading5: false
                  })
              }
            } else if (status === 401) {
              this.setState({ productLoading1: false, productLoading2: false, productLoading3: false, productLoading4: false, productLoading5: false })
              localStorage.clear();
            } else {
              this.setState({ productLoading1: false, productLoading2: false, productLoading3: false, productLoading4: false, productLoading5: false })
              toast.error(j?.errors);
            }
          })
          .catch((error) => {
            console.error(error);
          });
  }
  handleSelectColumnOne = (item:any)=>{
    // this.setState({selected1: item?.product_name});
    const {selectedListColumnOne} = this.state;
    
      if(selectedListColumnOne?.id === item?.id){
          this.setState({ selectedListColumnOne: {}})
      }
      else{
        this.setState({ selectedListColumnOne: item,  })

      }
  }
  handleSelectColumnTwo = (item:any)=>{
    // this.setState({selected2: item?.product_name});
    const {selectedListColumnTwo} = this.state;
    
      if(selectedListColumnTwo?.id === item?.id){
          this.setState({ selectedListColumnTwo: {}})
      }
      else{
        this.setState({ selectedListColumnTwo: item })

      }
  }
  handleSelectColumnThree = (item:any)=>{
    // this.setState({selected3: item?.product_name});
    const {selectedListColumnThree} = this.state;
    
      if(selectedListColumnThree?.id === item?.id){
          this.setState({ selectedListColumnThree: {} })
      }
      else{
        this.setState({ selectedListColumnThree: item })

      }
  }
  handleSelectColumnFour = (item:any)=>{
    // this.setState({searchColumnFour: item?.product_name});
    const {selectedListColumnFour} = this.state;
    
      if(selectedListColumnFour?.id === item?.id){
          this.setState({ selectedListColumnFour: {}})
      }
      else{
        this.setState({ selectedListColumnFour: item })

      }
  }
  handleSelectColumnFive = (item:any)=>{
    // this.setState({searchColumnFive: item?.product_name});
    const {selectedListColumnFive} = this.state;
    
      if(selectedListColumnFive?.id === item?.id){
          this.setState({ selectedListColumnFive: {}})
      }
      else{
        this.setState({ selectedListColumnFive: item })

      }
  }
  searchTimeout1: string | number | NodeJS.Timeout | undefined;
  handleSearchAria = (event: any) => {
    this.setState({ selected1: event })
    clearTimeout(this.searchTimeout1);
    if(event.length > 2 || event.length === 0)
    this.searchTimeout1 = setTimeout(() => {
      this.handleAria(event)
    }, 1000);
  };
  searchTimeout2: string | number | NodeJS.Timeout | undefined;
  handleSearchSouha = (event: any) => {
    this.setState({ selected2: event})
    clearTimeout(this.searchTimeout2);
    if(event.length > 2 || event.length === 0)
      this.searchTimeout2 = setTimeout(() => {
      this.handleSouha(event)
    }, 1000);
  };
  searchTimeout3: string | number | NodeJS.Timeout | undefined;
  handleSearchVento = (event: any) => {
    this.setState({ selected3: event})
    clearTimeout(this.searchTimeout3);
    if(event.length > 2 || event.length === 0)
    this.searchTimeout3 = setTimeout(() => {
      this.handleVento(event)
    }, 1000);
  };
  searchTimeout4: string | number | NodeJS.Timeout | undefined;
  handleSearchLegrand = (event: any) => {
    this.setState({ searchColumnFour: event})
    clearTimeout(this.searchTimeout4);
    if(event.length > 2 || event.length === 0)
    this.searchTimeout4 = setTimeout(() => {
      this.handleLegrand(event)
    }, 1000);
  };
  searchTimeout5: string | number | NodeJS.Timeout | undefined;
  handleSearchNetran= (event: any) => {
    this.setState({ searchColumnFive: event})
    clearTimeout(this.searchTimeout5);
    if(event.length > 2 || event.length === 0)
    this.searchTimeout5 = setTimeout(() => {
      this.handleNetran(event)
    }, 1000);
  };
  netranBrandTimeout: string | number | NodeJS.Timeout | undefined;
  netranBrandSelected = (choice: any) => {
    const {searchColumnFive} = this.state;
    this.setState({ netranBrandId: choice })
    clearTimeout(this.netranBrandTimeout);
    this.netranBrandTimeout = setTimeout(() => {
      this.handleNetran(searchColumnFive)
    }, 1000);
  }
  ariaBrandTimeout: string | number | NodeJS.Timeout | undefined;
  ariaBrandSelected = (choice: any) => {
    const {selected1} = this.state;
    this.setState({ ariaBrandId: choice})
    clearTimeout(this.ariaBrandTimeout);
    this.ariaBrandTimeout = setTimeout(() => {
      this.handleAria(selected1);
    }, 1000);
  }
  souhaBrandTimeout: string | number | NodeJS.Timeout | undefined;
  souhaBrandSelected = (choice: any) => {
    const {selected2} = this.state;
    this.setState({ souhaBrandId: choice })
    clearTimeout(this.souhaBrandTimeout);
    this.souhaBrandTimeout = setTimeout(() => {
      this.handleSouha(selected2);
    }, 1000);
  }
  ventoBrandTimeout: string | number | NodeJS.Timeout | undefined;
  ventoBrandSelected = (choice: any) => {
    const {selected3} = this.state;
    this.setState({ ventoBrandId: choice})
    clearTimeout(this.ventoBrandTimeout);
    this.ventoBrandTimeout = setTimeout(() => {
      this.handleVento(selected3);
    }, 1000);
  }
  legrandBrandTimeout: string | number | NodeJS.Timeout | undefined;
  legrandBrandSelected = (choice: any) => {
    const {searchColumnFour} = this.state;
    this.setState({ legrandBrandId: choice})
    clearTimeout(this.legrandBrandTimeout);
    this.legrandBrandTimeout = setTimeout(() => {
      this.handleLegrand(searchColumnFour);
    }, 1000);
  }
  
  netranTagTimeout: string | number | NodeJS.Timeout | undefined;
  netranTagSelected = (choice: any) => {
    this.setState({ netranTag: choice})
    const {searchColumnFive} = this.state;
    clearTimeout(this.netranTagTimeout);
    this.netranTagTimeout = setTimeout(() => {
      this.handleNetran(searchColumnFive)

    }, 1000);
  }
  ariaTagTimeout: string | number | NodeJS.Timeout | undefined;
  ariaTagSelected = (choice: any) => {
    this.setState({ ariaTag: choice})
    const {selected1} = this.state;
    clearTimeout(this.ariaTagTimeout);
    this.ariaTagTimeout = setTimeout(() => {
      this.handleAria(selected1);
    }, 1000);
  }
  souhaTagTimeout: string | number | NodeJS.Timeout | undefined;
  souhaTagSelected = (choice: any) => {
    this.setState({ souhaTag: choice})
    const {selected2} = this.state;
    clearTimeout(this.souhaTagTimeout);
    this.souhaTagTimeout = setTimeout(() => {
      this.handleSouha(selected2);
    }, 1000);
   }  
  ventoTagTimeout: string | number | NodeJS.Timeout | undefined;
  ventoTagSelected = (choice: any) => {
    this.setState({ ventoTag: choice})
    const {selected3} = this.state;
    clearTimeout(this.ventoTagTimeout);
    this.ventoTagTimeout = setTimeout(() => {
      this.handleVento(selected3);
    }, 1000);
  }  
  legrandTagTimeout: string | number | NodeJS.Timeout | undefined;
  legrandTagSelected = (choice: any) => {
    this.setState({ legrandTag: choice})
    const {searchColumnFour} = this.state;
    clearTimeout(this.legrandTagTimeout);
    this.legrandTagTimeout = setTimeout(() => {
      this.handleLegrand(searchColumnFour);
    }, 1000);
  }
  netranCategryTimeout: string | number | NodeJS.Timeout | undefined;
  netranCategorySelected = (choice: any) => {
    this.setState({ netranCategory: choice});
    const {searchColumnFive} = this.state;
    clearTimeout(this.netranCategryTimeout);
    this.netranCategryTimeout = setTimeout(() => {
      this.handleNetran(searchColumnFive);
    }, 1000);
  }
  ariaCategryTimeout: string | number | NodeJS.Timeout | undefined;
  ariaCategorySelected = (choice: any) => {
    this.setState({ ariaCategory: choice});
    const {selected1} = this.state;
    clearTimeout(this.ariaCategryTimeout);
    this.ariaCategryTimeout = setTimeout(() => {
      this.handleAria(selected1);
    }, 1000);
  }
  souhaCategryTimeout: string | number | NodeJS.Timeout | undefined;
  souhaCategorySelected = (choice: any) => {
    this.setState({ souhaCategory: choice});
    const {selected2} = this.state;
    clearTimeout(this.souhaCategryTimeout);
    this.souhaCategryTimeout = setTimeout(() => {
      this.handleSouha(selected2);
    }, 1000);
  }
  ventoCategryTimeout: string | number | NodeJS.Timeout | undefined;
  ventoCategorySelected = (choice: any) => {
    this.setState({ ventoCategory: choice});
    const {selected3} = this.state;
    clearTimeout(this.ventoCategryTimeout);
    this.ventoCategryTimeout = setTimeout(() => {
      this.handleVento(selected3);
    }, 1000);
  }
  legrandCategryTimeout: string | number | NodeJS.Timeout | undefined;
  legrandCategorySelected = (choice: any) => {
    this.setState({ legrandCategory: choice});
    const {searchColumnFour} = this.state;
    clearTimeout(this.legrandCategryTimeout);
    this.legrandCategryTimeout = setTimeout(() => {
      this.handleLegrand(searchColumnFour);
    }, 1000);
  }

  handleNetranInputBrand = (inputValue: any, actionMeta: any) => {
    clearTimeout(this.netranBrandTimeout);
    if(inputValue.length > 2)
    this.netranBrandTimeout = setTimeout(() => {
      this.getBrandList("netran", inputValue)
    }, 1000);
    
  };
  handleAriaInputBrand = (inputValue: any, actionMeta: any) => {
    clearTimeout(this.ariaBrandTimeout);
    if(inputValue.length > 2)
    this.ariaBrandTimeout = setTimeout(() => {
      this.getBrandList("aria",inputValue)
    }, 1000);
    
  };
  handleSouhaInputBrand = (inputValue: any, actionMeta: any) => {
    
    clearTimeout(this.souhaBrandTimeout);
    if(inputValue.length > 2)
    this.souhaBrandTimeout = setTimeout(() => {
      this.getBrandList("souha",inputValue)
    }, 1000);
    
  };
  handleVentoInputBrand = (inputValue: any, actionMeta: any) => {
    
    clearTimeout(this.ventoBrandTimeout);
    if(inputValue.length > 2)
    this.ventoBrandTimeout = setTimeout(() => {
      this.getBrandList("vento",inputValue)
    }, 1000);
    
  };
  handleLegrandInputBrand = (inputValue: any, actionMeta: any) => {
    clearTimeout(this.legrandBrandTimeout);
    if(inputValue.length > 2)
    this.legrandBrandTimeout = setTimeout(() => {
      this.getBrandList("legrand",inputValue)
    }, 1000);
    
  };
  handleNetranInputCategory = (inputValue: any, actionMeta: any) => {
    clearTimeout(this.netranCategryTimeout);
    if(inputValue.length > 2)
    this.netranCategryTimeout = setTimeout(() => {
      this.getCategoryList("netran",inputValue)
    }, 1000);
    
  };
  handleAriaInputCategory = (inputValue: any, actionMeta: any) => {
    clearTimeout(this.ariaCategryTimeout);
    if(inputValue.length > 2)
    this.ariaCategryTimeout = setTimeout(() => {
      this.getCategoryList("aria",inputValue)
    }, 1000);
    
  }; 
  handleSouhaInputCategory = (inputValue: any, actionMeta: any) => {
    clearTimeout(this.souhaCategryTimeout);
    if(inputValue.length > 2)
    this.souhaCategryTimeout = setTimeout(() => {
      this.getCategoryList("souha",inputValue)
    }, 1000);
    
  };  
  handleVentoInputCategory = (inputValue: any, actionMeta: any) => {
    clearTimeout(this.ventoCategryTimeout);
    if(inputValue.length > 2)
    this.ventoCategryTimeout = setTimeout(() => {
      this.getCategoryList("vento",inputValue)
    }, 1000);
    
  };  
  handleLegrandInputCategory = (inputValue: any, actionMeta: any) => {
    clearTimeout(this.legrandCategryTimeout);
    if(inputValue.length > 2)
    this.legrandCategryTimeout = setTimeout(() => {
      this.getCategoryList("legrand",inputValue)
    }, 1000);
    
  };
  handleNetranInputTag = (inputValue: any, actionMeta: any) => {
    clearTimeout(this.netranTagTimeout);
    if(inputValue.length > 2)
    this.netranTagTimeout = setTimeout(() => {
      this.getTagList("netran",inputValue)
    }, 1000);
    
  };
  handleAriaInputTag = (inputValue: any, actionMeta: any) => {
    clearTimeout(this.ariaTagTimeout);
    if(inputValue.length > 2)
    this.ariaTagTimeout = setTimeout(() => {
      this.getTagList("aria",inputValue)
    }, 1000);
    
  };
  handleSouhaInputTag = (inputValue: any, actionMeta: any) => {
    clearTimeout(this.souhaTagTimeout);
    if(inputValue.length > 2)
    this.souhaTagTimeout = setTimeout(() => {
      this.getTagList("souha",inputValue)
    }, 1000);
    
  };
  handleVentoInputTag = (inputValue: any, actionMeta: any) => {
    clearTimeout(this.ventoTagTimeout);
    if(inputValue.length > 2)
    this.ventoTagTimeout = setTimeout(() => {
      this.getTagList("vento",inputValue)
    }, 1000);
  };
  handleLegrandInputTag = (inputValue: any, actionMeta: any) => {
    
    clearTimeout(this.legrandTagTimeout);
    if(inputValue.length > 2)
    this.legrandTagTimeout = setTimeout(() => {
      this.getTagList("legrand",inputValue)
    }, 1000);
    
  };
  async getCategoryList(dbName:string, inputValue?:string) {
    let parameters = {
      search: inputValue ? inputValue: null,
      is_search : JSON.stringify(1),
      db_name : dbName,
    }
    let url = new URL(`${AppConstants.base_url_api}seller/categories`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
call_api({
  address_api: url.search ? `${AppConstants.base_url_api}seller/categories${url.search}` : `${AppConstants.base_url_api}seller/categories?sync=1`,
  method_api: "GET"
})
  .then(parseJSON)
  .then(([status, j]) => {
    if (status === 200) {
      if (j.data) {
        if(dbName === "netran")
        {
          this.setState({netranCategoryList: j.data})
        }
        else if (dbName === "souha"){
          this.setState({souhaCategoryList: j.data})
        }
        else if (dbName === "vento"){
          this.setState({ventoCategoryList: j.data})
        }
        else if (dbName === "aria"){
          this.setState({ariaCategoryList: j.data})
        }
        else if (dbName === "legrand"){
          this.setState({legrandCategoryList: j.data})
        }
      }
    } else if (status === 401) {
      localStorage.clear();
    } else {
      toast.error(j?.errors);
    }
  })
  .catch((error) => {
    console.error(error);
  });
};

  async getTagList(dbName:string,inputValue?:string) {
    let parameters = {
      search: inputValue ? inputValue: null,
      is_search : JSON.stringify(1),
      sync: JSON.stringify(1),
      db_name : dbName
    }
    let url = new URL(`${AppConstants.base_url_api}seller/tags`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
  call_api({
    address_api: url.search ? `${AppConstants.base_url_api}seller/tags${url.search}` : `${AppConstants.base_url_api}seller/tags`,
    method_api: "GET"
  })
    .then(parseJSON)
    .then(([status, j]) => {
      if (status === 200) {
        if (j.data) {
          if(dbName === "netran")
          {
            this.setState({netranTagList: j.data})
          }
          else if (dbName === "souha"){
            this.setState({souhaTagList: j.data})
          }
          else if (dbName === "vento"){
            this.setState({ventoTagList: j.data})
          }
          else if (dbName === "aria"){
            this.setState({ariaTagList: j.data})
          }
          else if (dbName === "legrand"){
            this.setState({legrandTagList: j.data})
          }
        }
      } else if (status === 401) {
        localStorage.clear();
      } else {
        toast.error(j?.errors);
      }
    })
    .catch((error) => {
      console.error(error);
    });
  };
  async getBrandList(dbName:string,inputValue?:string) {
    let parameters = {
      search: inputValue ? inputValue: null,
      is_search : JSON.stringify(1),
      db_name : dbName
    }
    let url = new URL(`${AppConstants.base_url_api}seller/brands`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}seller/brands${url.search}` : `${AppConstants.base_url_api}seller/brands?sync=1`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          if (j?.data) {
            if(dbName === "netran")
            {
              this.setState({netranBrandList: j.data})
            }
            else if (dbName === "souha"){
              this.setState({souhaBrandList: j.data})
            }
            else if (dbName === "vento"){
              this.setState({ventoBrandList: j.data})
            }
            else if (dbName === "aria"){
              this.setState({ariaBrandList: j.data})
            }
            else if (dbName === "legrand"){
              this.setState({legrandBrandList: j.data})
            }
        }
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  async handleSubmit() {
    const {selectedListColumnOne , selectedListColumnTwo,
      selectedListColumnThree,
      selectedListColumnFour, selectedListColumnFive, selected1, selected2, selected3, searchColumnFour, searchColumnFive} = this.state,
      relatedproducts = [selectedListColumnOne,selectedListColumnTwo,selectedListColumnThree,selectedListColumnFour, selectedListColumnFive]
    const selectedIdList = [];
    const {siteList} = this.props;
    for (var i = 0; i < relatedproducts.length; i++) {      
      
      if(Object.keys(relatedproducts[i]).length > 0 ){
        selectedIdList.push(relatedproducts[i]?.id_select)
      }
    }
    if(selectedIdList.length > 1)
    {
     
      if(relatedproducts.filter((item:any) => item?.db_name === siteList[0]?.value?.name).length > 0)
      {
        this.setState({ isCreating: true })
        call_api({
          address_api: `${AppConstants.base_url_api}seller/product/sync`,
          method_api: "POST",
          body: JSON.stringify({
            id_select: selectedIdList.toString()
          })
        })
          .then(parseJSON)
          .then(([status, j]) => {
            if (status === 200) {
              this.setState({ isCreating: false, toggleModal: false,
                 selectedListColumnOne:null, selectedListColumnTwo: null, 
                 selectedListColumnThree: null, selectedListColumnFour: null, })
              toast.success("با موفقیت انجام شد ");
              this.handleAria(selected1);
              this.handleLegrand(searchColumnFour);
              this.handleVento(selected3);
              this.handleSouha(selected2);
              this.handleNetran(searchColumnFive);
            } else if (status === 401) {
              localStorage.clear();
              this.setState({ isCreating: false })
    
            } else {
              toast.error(j?.message);
              this.setState({ isCreating: false })
            }
          })
          .catch((error) => {
            console.error(error);
            this.setState({ isCreating: false })
          });
        }
        else {
      toast.error("حتماً باید از ستون اول محصولی انتخاب شده باشد!")
    }
    }
    
    else{
      toast.error("حداقل دو مورد را انتخاب کنید");

    }
  };


}


export default CreateProductsRelationController;
