import { CustomButton, DropDownIcon, InputTextLabel, Header, Option } from "../../../components";
import { SystemLogsController } from "../../../controllers";
import { Box, createTheme, ThemeProvider } from '@mui/material';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { AiOutlineFileExcel } from "react-icons/ai";
import Pagination from '@mui/material/Pagination';
import Select, { CSSObjectWithLabel, components } from "react-select";
import Tooltip from '@mui/material/Tooltip';
import { connect } from "react-redux";
import { TfiFilter } from "react-icons/tfi";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import moment from "moment-jalaali";
class SystemLogs extends SystemLogsController {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getDate()

  }

  render() {
    type coulmnData = {
      item: any;
      id: number;
      driver:string;
      user: string,
      title: string;
      description: string;
      date: string

    };


    const theme = createTheme({
      direction: "ltr",
      typography: {
        fontFamily:
          'Vazir',
      }
    });
    const { search, showFilters, page , allOption, loading, dreiverId, filterId,rowCount, rowCountList, logsList , total} = this.state;
    const { siteList, loadingSiteList } = this.props;
    const columns = [
      {
        accessorKey: 'id',
        header: 'شناسه',
        Cell: ({ cell }) => (
          <div style={{display:"flex",justifyContent: "center"}}>
            {cell.getValue<string>()}
          </div>
        ),
        size:10,
        enableSorting:false,

      },
      {
        accessorKey: "driver",
        header: "سایت",
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        size: 10,
        enableSorting:false,

      },
      {
        accessorKey: 'user',
        header: 'کاربر',
        Cell: ({ cell }) => (
          <div style={{display:"flex",justifyContent: "center"}}>
            {cell.getValue<string>()}
          </div>
        )
      },
      {
        accessorKey: 'title',
        header: 'عنوان',
        Cell: ({ cell }) => (
          <div style={{display:"flex",justifyContent: "center"}}>
            {cell.getValue<string>()}
          </div>
        ),
        enableSorting:false,

      },
      {
        accessorKey: 'description',
        header: 'توضیحات',
        Cell: ({ cell }) => (
          <div style={{display:"flex",justifyContent: "center"}}>
            {cell.getValue<string>()}
          </div>
        ),
        enableSorting:false,

      },
      {
        accessorKey: 'date',
        header: 'تاریخ',
        Cell: ({ cell }) => (
          <div
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign:"center",
            flexDirection:"row-reverse"
          }}
        >
          <span>
          {moment(cell.getValue<string>()).format("jYYYY/jM/jD")}
            
          </span>
          <span style={{paddingLeft:"10px"}}>
          {moment(cell.getValue<string>()).format("HH:mm:ss ")}
          </span>
        </div>
        )
      },
    ] as MRT_ColumnDef<coulmnData>[]


    return (
      <HelmetProvider>
      <ThemeProvider theme={theme}>
        <div className="users-info">
          <Helmet>
            <title> رویدادهای سیستم </title>
          </Helmet>
          <Header isActive = "isLogs" title="رویدادهای سیستم" />
          <div className="custom-table">
            <div className="custom-card mb-3">
              {showFilters &&
                <>
                  <div className="filter-select-container mb-2 pe-3">
                    <div className="filter-options-container">
                      <div className="column-30">
                        <Select
                          styles={{
                            control: (baseStyles: any, state: any):CSSObjectWithLabel => ({
                              ...baseStyles,
                              boxShadow:"rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",
                              borderWidth: "1px",
                              backgroundColor: "#fff",
                              display: "flex",
                              flexDirection: "row-reverse",
                              textAlign: "right",
                              borderRadius: "4px",
                              outline: "unset",
                            }) as CSSObjectWithLabel,
                          }}
                          onChange={this.driverSelected}
                          components={{ Option }}
                          value={dreiverId}
                          isMulti={true}
                          closeMenuOnSelect={true}
                          noOptionsMessage={() => 'این مورد وجود ندارد!'}
                          menuPortalTarget={document.body}
                          options={[allOption, ...siteList]}
                          getOptionLabel={(option: any) => option?.value?.persian_name}
                          getOptionValue={(option: any) => option?.value?.name}
                          placeholder="سایت"
                          isClearable
                          loadingMessage={() => 'در حال بارگذاری  ...'}
                          isLoading={loadingSiteList}
                        />

                      </div>
                      <div className="column-30">
                        <Select
                          styles={{
                            
                            control: (baseStyles: any, state: any): CSSObjectWithLabel => ({
                              ...baseStyles,
                              boxShadow: "rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",
                              borderWidth: "1px",
                              backgroundColor: "#fff",
                              display: "flex",
                              flexDirection: "row-reverse",
                              textAlign: "right",
                              borderRadius: "4px",
                              height:"35px",
                              minHeight:"20px",
                              outline: "unset",
                            }) as CSSObjectWithLabel,
                          }}
                          onChange={this.filterSelected}
                          components={{ Option }}
                          value={filterId}
                          isMulti={false}
                          closeMenuOnSelect={true}
                          noOptionsMessage={() => 'این مورد وجود ندارد!'}
                          menuPortalTarget={document.body}
                          options={[{ val:"admin_product" , name : "محصولات"},
                            {val:"admin_category" ,name :" دسته بندی"},
                            {val:"admin_tag",name :" برچسب‌ها"},
                            {val:"admin_brand",name :" برندها"},
                            {val:"admin_note",name :" یادداشت‌ها"},
                           { val:"admin_user" ,name :" کاربران"},
                          ]}
                          getOptionLabel={(option: any) => option?.name}
                          getOptionValue={(option: any) => option?.val}
                          placeholder="فیلتر"
                          isClearable  
                        />

                      </div>
                      <div className="column-30">
                        <InputTextLabel
                          type={"text"}
                          value={search}
                          onChange={(event) => this.handleSearch(event)}
                          placeholder="جستجو..."
                        />
                      </div>

                    </div>

                  </div>
                </>}

              <div className="table-container">

                <MaterialReactTable
                   muiTablePaperProps={{
                    sx: { m: '1.3rem', border:"1px solid #e5e5e5" }}}
                  columns={columns}
                  muiTableProps={{
                    sx: {
                      border: "1px solid #e5e5e5",
                
                    },
                  }}
                  muiTableHeadCellProps={{
                    sx: {
                      height:"fit-content",
                      border: "1px solid #e5e5e5",
                      textAlign:"center"
                    },
                  }}
                  muiTableBodyRowProps={( {row,staticRowIndex} ) => ({
                    sx:{
                      height: "fit-content",
                      backgroundColor: (staticRowIndex % 2 === 0 ) ? '#f3f3f3'  : "",
                  }})}
                  muiTableBodyCellProps={{
                    sx: {
                      height:"fit-content",
                      border: "1px solid #e5e5e5",
                      textAlign:"center"

                      
                    },
                  }}
                  displayColumnDefOptions={{
                    'mrt-row-expand': {
                      muiTableHeadCellProps: {
                        align: 'center',
                      },
                    },
                  }}
                  data={logsList && logsList?.length > 0 &&
                    logsList?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null).map((item: any, index: number) => {
                      return ({
                        item: item,
                        driver: <img className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo} alt="" />,
                        id: item?.id,
                        user: item?.profile,
                        title: item?.title,
                        description: item?.desc,
                        date: item.created_at && item.created_at,
                      }
                      );
                    }
                    )}
                    enableRowNumbers={true}
                    rowNumberMode="static"
                  enablePagination={false}
                  renderBottomToolbarCustomActions={({ table }) => {
                    return (
                      <div className="custom-pagination w-100">
                        <Pagination  page={page} count={total ? Math.ceil(total / rowCount) : 1} onChange={this.handleChangePage} />
                        <DropDownIcon
                          value={rowCount}
                          optionList={rowCountList}
                          title={""}
                          onChange={(event) => {
                            this.handlePaginationSelect(event)
                          }
                          }
                        />
                      </div>
                    );
                  }}
                  localization={{
                    noRecordsToDisplay: "داده‌ای برای نمایش وجود ندارد!",
                  }}
                  enableGlobalFilter={false}
                  enableColumnFilters={false}
                  enableDensityToggle={false}
                  enableFullScreenToggle={false}
                  enableHiding={false}
                  enableColumnActions={false}
                  renderTopToolbarCustomActions={({ table }) => (
                    <Box
                      sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap', justifyContent: "space-between", width: "100%" }}
                    >
                      <CustomButton
                        loading={false}
                        onClick={() => this.exportToExcel(logsList, "Excel Export")}
                        title="اکسل"
                        myStyle="excel-btn"
                        icon={<AiOutlineFileExcel />}
                      />
                      <Tooltip title="نمایش فیلترها"><span> <TfiFilter className='filter-icon' onClick={() => this.setState(prevState => ({
                                  showFilters: !prevState.showFilters
                                }))
                              } /></span></Tooltip>
                      <div className='custom-pagination'>
                        <Pagination  page={page} count={total ? Math.ceil(total / rowCount) : 1} onChange={this.handleChangePage} />
                        <DropDownIcon
                          value={rowCount}
                          optionList={rowCountList}
                          title={""}
                          onChange={(event) => {
                            this.handlePaginationSelect(event)
                          }
                          }
                        />
                      </div>
                    </Box>
                  )}
                  sortDescFirst={true}
                  enableSorting={true}
                  state={{ isLoading: loading, showProgressBars: loading }}
                  initialState={{
                    sorting: [
                      {
                        id: 'date',
                        desc: true,

                      },
                    ],
                    pagination: { pageSize: 100, pageIndex: 0 }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
      </HelmetProvider>
    );
  }
}
const mapStateToProps = (dispatch: any) => {
  return {
    dispatch,
    siteList: dispatch.general.siteList,
    loadingSiteList: dispatch.general.loadingSiteList,
  }
};
export default connect(mapStateToProps)(SystemLogs);