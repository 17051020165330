import { components } from "react-select";

const Option = (props: any) => {
    return (
      <div
        style={{
          padding: "2px",
          display: "flex",
          border: "1px solid",
          justifyContent: "space-between"
        }}
      >
      {props?.data?.value?.logo &&
        <img className="logo m-2" alt="" src={props?.data?.value?.logo} />}
        <components.Option {...props} />
      </div>
    );
  };
  export default Option;