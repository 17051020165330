import {
    InputHTMLAttributes,
    ReactElement,
  } from "react";
  
  interface Props extends InputHTMLAttributes<HTMLInputElement> {
    children: any;
    title?:string;
    myStyle?:string
  }
  
const CustomContainer=({
    children,
    title,
    myStyle
  }: Props): ReactElement => {
    
    return (
      <div className={`custom-container ${myStyle}`}>
        {title && 
        <div className="custom-container-title">
            {title}
        </div>
        }
        <div className="custom-container-content">
        
        {children}
      </div>

      </div>
    );
  }
  export default CustomContainer;
  