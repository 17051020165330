import { Component, createRef } from "react";
import { toast } from "react-toastify";
import { call_api, parseJSON } from "../../../core/service";
import { AppConstants } from "../../../core/constants";
import * as yup from 'yup';
import { fetchSiteList, fetchUserData } from "../../../Redux/services/generalService";

interface IProps {
  dispatch?:any,
  siteList?:any,
  loadingSiteList?:any,
  userData?:any
 }

interface IState {
    loading: boolean,
}

 class LayoutController extends Component<IProps, IState> {

  constructor(props: any) {
    super(props);
    this.state = {
        loading: true
    };
  }
}
export default LayoutController;
