
import { CustomButton, LoadingSpinner,DropDownIcon, Header,InputIcon, Option, SubmissionAlert, ButtonTab, AddressInputs} from "../../../components";
import moment from 'moment-jalaali';
import { createTheme, ThemeProvider, Tooltip } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import { BiCube } from "react-icons/bi";
import { GrPowerCycle } from "react-icons/gr";
import { AiOutlineEdit, AiOutlineFilePdf } from "react-icons/ai";
import { numberWithCommas, exportToExcel} from "../../../../core/utils";
import { MaterialReactTable } from 'material-react-table';
import { AiOutlineFileExcel } from "react-icons/ai";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { HiPlus } from "react-icons/hi";
import Pagination from '@mui/material/Pagination';
import Select, { CSSObjectWithLabel, components } from "react-select";
import {BsPlusCircle, BsWhatsapp, BsArrowLeft} from "react-icons/bs";
import { connect } from "react-redux";
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import { Link } from "react-router-dom";
import {TfiFilter} from "react-icons/tfi";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {PreFactorController} from "../../../controllers";
import { FiAlertTriangle } from "react-icons/fi";
import { prefactorColumns  } from "../../../../core/column";

class PreFactor extends PreFactorController {
  componentDidMount() {
    this.getInvoicesList();
    window.scrollTo(0, 0);    
  }
  render() {
    const theme = createTheme({
      typography: {
        fontFamily:
          'Vazir'
      },
      
    });
    const ShowIdDropDown = (props: any) =>{
      return (
        
          <components.Option {...props} >
         <span>{props?.data?.id}</span> 
         <components.Option {...props} />
         <span>{props?.data?.mobile}</span>
         </components.Option>
        
      );
    }
    const filterOption = (option: any, inputValue: any): boolean =>
    {
      return (option.label?.match(inputValue) || option?.data?.mobile?.match(inputValue) || []).length > 0;
    }
    const { openCreateFactorModal, showFilters, page , purchaseStatus, converted, toggleAddressPage, errorsList,phone,
      whatsapp,openNewUserModal,userSelected,userDreiverId,userLoading,profileListData,loadingUpdateProfile, address,
      date, step, openPreOrderProductModal, recieverName, recieverPhone, sendingStatus, openEditAdress, firstName,
      mobile, lastName, dateValue ,currentItem, addressMessage, modalLoading, invoicesListData, openEditAddressAlert,
      dbName, addressError, dreiverId,allOption, addressLoading, usersAddressList, loading, rowCount,rowCountList,
      addressData, editAddressData
    } = this.state;
    const { siteList , loadingSiteList} = this.props;
    return (
      <HelmetProvider>
      <ThemeProvider theme={theme}>
        <div className="prefactor">
          <Helmet>
                <title>پیش‌فاکتورها</title>
            </Helmet>
          <Header title="پیش‌فاکتورها " />
            <div className="custom-card">

            <div className="filter-select-container pe-3">
              <Modal
                      open={openNewUserModal}
                      onClose={this.handleCloseNewUserModal}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                     <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        width: {
                          xxs: "90%",
                          xs: "90%",
                          sm: "90%",
                          md: "80%",
                          lg: "70%",
                          xl: "70%"
                        },
                        height: 'max-content',
                        display: 'block'
                      }}>
                        <Typography id="modal-modal-title" variant="subtitle1" sx={{fontSize:"14px", marginBottom:"10px"}}>
                        ایجاد کاربر جدید                  
                        </Typography>
                        <Grid container sx={{display:"flex", justifyContent:"center"}}>
                    </Grid>
                        <Grid container sx={{display:"flex", justifyContent:"space-between", padding:"10px"}} >
                        <Grid item={true} sm={12} lg={3} sx={{paddingBottom:"25px"}}>
                  <Select
                    styles={{
                      control: (baseStyles, state):CSSObjectWithLabel => ({
                        ...baseStyles,
                        boxShadow: "rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",
                        borderWidth: "1px",
                        backgroundColor: "#fff",
                        display: "flex",
                        flexDirection: "row-reverse",
                        textAlign: "right",
                        borderRadius: "4px",
                        minHeight: "42px",
                        
                        outline: "unset",
                      }) as CSSObjectWithLabel,
                    }}
                    onChange={(choice) => this.userDdriverSelected(choice)}
                    components={{ Option }}
                    value={userDreiverId}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    noOptionsMessage={() => 'این مورد وجود ندارد!'}
                    options={siteList}
                    getOptionLabel={(option: any) => option?.value?.persian_name}
                    getOptionValue={(option: any) => option?.value?.name}
                    placeholder="سایت"
                    name="userDreiverId"
                    loadingMessage={() => 'در حال بارگذاری  ...'}
                    isLoading={loadingSiteList}
                  />
                  <span className="input-icon-error">
                    {errorsList && errorsList?.filter((obj: any) => obj.path === "userDreiverId")[0]?.message}
                  </span>
                </Grid>
                      <Grid item={true} sm={12} lg={3}>
                      <InputIcon
                                value={mobile}
                                title={"موبایل "}
                                inBox={"موبایل"}
                                type={"text"}
                                name={"mobile"}
                                errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "mobile")[0]?.message}
                                onChange={(event) =>
                                  this.setState({
                                    mobile: event.currentTarget.value,
                                  })
                                }
                              />

                      </Grid>
                      <Grid item={true} sm={12} lg={3}>
                      <InputIcon
                              value={firstName}
                              title={"نام "}
                              inBox={"نام  "}
                              type={"text"}
                              name="firstName"
                              errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "firstName")[0]?.message}
                              onChange={(event) =>
                                this.setState({
                                  firstName: event.currentTarget.value,
                                })
                              }
                            />
                      </Grid>
                   
                        </Grid>
                        <Grid container sx={{display:"flex", justifyContent:"space-between", padding:"10px"}} >
                        <Grid item={true} sm={12} lg={3}>
                      <InputIcon
                              value={lastName}
                              title={"نام خانوادگی  "}
                              inBox={"نام خانوادگی  "}
                              errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "lastName")[0]?.message}
                              type={"text"}
                              name="lastName"
                              onChange={(event) =>
                                this.setState({
                                  lastName: event.currentTarget.value,
                                })
                              }
                            />
                      </Grid>
                        <Grid item={true} sm={12} lg={3}>
                        <InputIcon
                          value={phone}
                          title={"تلفن "}
                          inBox={"تلفن "}
                          type={"text"}
                          name="phone"
                          errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "phone")[0]?.message}
                          onChange={(event) =>
                            this.setState({ phone: event.currentTarget.value })
                          }
                        />
                      </Grid>
                      <Grid item={true} sm={12} lg={3}>
                        <InputIcon
                            value={whatsapp}
                            title={"واتس اپ "}
                            errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "whatsapp")[0]?.message}
                            inBox={<BsWhatsapp />}
                            type={"text"}
                            name="whatsapp"
                            onChange={(event) =>
                              this.setState({
                                whatsapp: event.currentTarget.value,
                              })
                            }
                          />
                      </Grid>
                        </Grid>
                        <div className="btn-container">
                        <CustomButton
                          myStyle="submit-btn"
                          loading={loadingUpdateProfile}
                          onClick={()=>this.createNewUser()}
                          title="ایجاد کاربر جدید "/>
                        </div>
                      </Box>
              </Modal>
            <div className="filter-options-container">
            {showFilters && 
                    <>
              <div className="column-25 two-section">
              <Tooltip title=" صدور پیش‌فاکتور "><Link to="/create-prefactor">  <BsPlusCircle className="icon-green mt-1" /></Link></Tooltip>
              <Select
                className="profile-select"
                  styles={{
                        control: (baseStyles, state):any => ({
                          ...baseStyles,
                          boxShadow:"rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",
                          borderWidth: "1px",
                          backgroundColor: "#fff",
                          display: "flex",
                          flexDirection: "row-reverse",
                          textAlign: "right",
                          borderRadius: "4px",
                          outline: "unset",
                        }),
                       
                        menuPortal: (base):any => ({ ...base, zIndex: 9999 })
                      }}                     
                      onChange={this.driverSelected}
                      components={{ Option }}
                      value={dreiverId}
                      isMulti={true}
                      closeMenuOnSelect={true}
                      noOptionsMessage={() => 'این مورد وجود ندارد!'}
                      menuPortalTarget={document.body}
                      options={[ allOption, ...siteList]}
                      getOptionLabel={(option: any) => option?.value?.persian_name}
                      getOptionValue={(option: any) => option?.value?.name}
                      placeholder="سایت"
                      isClearable
                      loadingMessage={() => 'در حال بارگذاری  ...'}
                      isLoading={loadingSiteList}
              />
              </div>
              <div className="column-25 two-section">
                <Tooltip title="اضافه کردن کاربر"><span>  <BsPlusCircle className="icon-green mt-0" onClick={ ()=> this.setState({ openNewUserModal: true })} /></span></Tooltip>
                  <Select
                      className="profile-select"
                      styles={{
                        control: (baseStyles, state):any => ({
                          ...baseStyles,
                          boxShadow: state.isFocused
                            ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                            : "",
                          borderWidth: "1px",
                          backgroundColor: `${dreiverId && Object.keys(dreiverId).length > 0  ? "#fff" : "#f7f8fa"}`,
                          display: "flex",
                          flexDirection: "row-reverse",
                          textAlign: "right",
                          borderRadius: "4px",
                          height:"35px",
                          minHeight:"20px",
                          width:"100%",
                          outline: "unset",
                        }),
                        menuPortal: (base):any => ({ ...base, zIndex: 9999 }),
                        option: (styles, {isSelected}):any => ({
                          ...styles,
                          background: 
                              isSelected
                                  ? 'light-blue'
                                  : undefined,
                          display:"flex",
                          padding: "2px",
                          justifyContent: "space-around",
                          alignItems:"center"
                      }),
                      placeholder:(styles):any => ({
                        ...styles,
                       fontSize:"14px"
                      }),}}
                      onInputChange={(choice) => this.handleSearchUser(choice)}
                      onChange={(choice) => this.userSelected(choice)}
                      components={{ Option:ShowIdDropDown }}
                      value={userSelected}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      isSearchable={true}
                      options={profileListData}
                      menuPortalTarget={document.body}
                      noOptionsMessage={() => 'این مورد وجود ندارد!'}
                      getOptionLabel={(option: any) => option.profile?.full_name}
                      getOptionValue={(option: any) => option?.id}
                      placeholder="شماره یا نام خانوادگی کاربر"
                      isClearable
                      filterOption={filterOption}
                      isLoading={userLoading}
                      loadingMessage={() => 'در حال بارگذاری  ...'}
                    />
                  <span className="input-icon-error">
                    {errorsList && errorsList?.filter((obj: any) => obj.path === "userSelected")[0]?.message}
                  </span>
              </div>
              <div className="column-25 ">
                    <InputIcon
                      value={userSelected?.mobile || ""}
                      placeholder="شماره  همراه کاربر"
                      title="شماره  همراه کاربر"
                      type={"text"}
                      disabled={true}
                      inputContainerStyle="user-mobile-inp"
                    />
              </div>  
              <div className="column-25 mt-18">
              <DatePicker 
                className="rmdp-prime"
                inputClass="date-picker"
                editable={false}
                range 
                value={dateValue}
                dateSeparator=" تا " 
                maxDate={new Date()}
                onChange={(date:any) => {
                  this.handleChange(date);
                }}
                placeholder="انتخاب بازه زمانی"
                calendar={persian}
                locale={persian_fa}
              >
                  <button
                  style={{ margin: "5px 0" }}
                  onClick={() => this.handleClearDate()}
                  >
                  لغو انتخاب 
                  </button>
              </DatePicker>
              </div>
              </>
  }
              </div>
              </div>
              <Modal
                open={openCreateFactorModal}
                onClose={this.handleCloseCreateFactorModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 4,
                  fontSize:"14px",
                  overflow: "scroll",
                  width: {
                    xxs: "90%",
                    xs: "90%",
                    sm: "90%",
                    md: "80%",
                    lg: "70%",
                    xl: "70%"
                  },
                  height: 'max-content',
                }}>
                  <Grid id="modal-modal-title" sx={{display:"flex", justifyContent:"space-between", fontSize:"14px", alignItems:"center"}}>
                  <Typography variant="subtitle1">
                    محصولات پیش‌فاکتور
                  </Typography>
                  <Typography variant="subtitle1">
                   شناسه پیش‌فاکتور: {currentItem?.id}
                  </Typography>
                  <Typography variant="subtitle1">
                     سایت مبدأ: <img style={{width:"30px", height:"auto", paddingBottom:"10px"}} src={siteList && siteList?.filter((obj: any) => obj?.value?.name === currentItem?.db_name)[0]?.value?.logo} alt="" />
                  </Typography>
                  {currentItem?.is_converted !== 1 && 
                      <Tooltip title="ویرایش"><Link target="_blank" to={`/prefactor-update/${currentItem?.id_select}`}><AiOutlineEdit className='icon-gray' /></Link></Tooltip>
                  }
                  </Grid>
                  <Grid container sx={{
                    display: "felx", justifyContent: "space-between"
                    , border: "0.5px solid rgb(222, 221, 221)", padding: "20px", fontSize:"14px" }}>
                    <Grid item={true} sm={12} md={4} lg={4} sx={{ justifyContent: "center", display: "flex"}}>
                      <span>
                        گیرنده: {`${currentItem.name || ""} ${currentItem.family || ""} `}
                      </span>
                    </Grid>
                    <Grid item={true} sm={12} md={4} lg={4} sx={{ justifyContent: "center", display: "flex" }}>
                      <span>
                        موبایل: {currentItem.mobile}
                      </span>
                    </Grid>
                    <Grid item={true} sm={12} md={4} lg={4} sx={{ justifyContent: "center", display: "flex" }}>
                      <span>
                        تاریخ ثبت: {moment(currentItem.created_at).format("jYYYY/jM/jD")}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: "20px", display: "felx", justifyContent: "space-between", border: "0.5px solid rgb(222, 221, 221)" }}>
                    <div className="table-responsive w-100">
                      {modalLoading ? <LoadingSpinner/> :
                      <table className="table table-product">
                        <thead>
                          <tr >
                            <th style={{whiteSpace:"nowrap"}} scope="col">شناسه کالا</th>
                            <th scope="col">محصول</th>
                            <th scope="col">تعداد</th>
                            <th scope="col">قیمت</th>
                            <th scope="col">تخفیف</th>
                            <th scope="col">مبلغ کل</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItem && currentItem?.preFactorProducts?.length > 0 && currentItem?.preFactorProducts?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null).map((item: any, index: number) => {
                            return (
                              <tr key={index}>
                                <td>{item?.product_id}</td>
                                <td>
                                  <span className="pre-factor-product-name">
                                    <Link to={item?.product_url} target="_blank">{item?.product_name}</Link>
                                  </span>
                                </td>
                                <td>{item?.count}</td>
                                <td>{numberWithCommas( item?.price_by_discount?.price)}</td>
                                <td>{Number(item?.price_by_discount?.discount) < 99 ? `${numberWithCommas( item?.price_by_discount.discount)}%`
                               :`${numberWithCommas( item?.price_by_discount.discount)} تومان ` }</td>
                                <td>{numberWithCommas(item?.price_by_discount?.finalPrice)}</td>
                              </tr>
                            )
                          })}
                          <tr className="table-warning">
                          <td colSpan={2} align="center" >مجموع</td>
                            <td>{currentItem?.totalCount}</td>
                            <td>{numberWithCommas(currentItem?.totalPrice)}</td>
                            <td>{numberWithCommas(currentItem?.totalDiscount)}</td>
                            <td className="table-danger">{numberWithCommas(currentItem?.finalPrice)} تومان </td>
                          </tr>
                        </tbody>
                      </table>
                      }
                    </div>
                  </Grid>
                </Box>
              </Modal>
              <Modal
                open={openPreOrderProductModal}
                onClose={this.handleClosePreOrderProductModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                  <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    fontSize:"14px",
                    boxShadow: 24,
                    p: 4,
                    overflowY: "scroll",
                    width: {
                      xxs: "90%",
                      xs: "90%",
                      sm: "90%",
                      md: "80%",
                      lg: "70%",
                      xl: "70%"
                    },
                    height: 'fit-content',
                    display: 'block'
                  }}>
                   {!toggleAddressPage ?
                    <>
                    <Box id="modal-modal-title" sx={{ display: "flex", justifyContent: "space-between"}}>
                    <Typography id="modal-modal-title" variant="subtitle1">
                      تبدیل به فاکتور
                    </Typography>
                      <img className="modal-logo-img" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === dbName)[0]?.value?.logo} alt="..." />
                    </Box>
                    <label className=" pt-3">
                      اطلاعات پروفایل
                    </label>
                    <Box>
                    {modalLoading ? <LoadingSpinner/> :
                      <>
                      <Grid container sx={{ display: "flex"}}>
                        <Grid item={true} sm={12} lg={4} sx={{padding:"5px"}}>
                          <InputIcon
                            inBox={"نام"}
                            type={"text"}
                            name="firstName"
                            key="firstName"
                            value={firstName}
                            title={"نام"}
                            errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "firstName")[0]?.message}
                            onChange={(event) => this.setState({ firstName: event.currentTarget.value,})}
                          />
                        </Grid>
                        <Grid item={true} sm={12} lg={4} sx={{padding:"5px"}}>
                          <InputIcon
                            inBox={"نام خانوادگی"}
                            type={"text"}
                            name="lastName"
                            key="lastName"
                            value={lastName}
                            title={"نام خانوادگی"}
                            errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "lastName")[0]?.message}
                            onChange={(event) =>this.setState({ lastName: event.currentTarget.value})}
                          />
                        </Grid>
                        <Grid item={true} sm={12} lg={4} sx={{padding:"5px"}}>
                        <InputIcon
                          inBox={"شماره همراه"}
                          type={"number"}
                          key="input"
                          name="mobile"
                          value={mobile}
                          title={"شماره همراه"}
                          errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "mobile")[0]?.message}
                          onChange={(event) => this.setState({mobile: event.currentTarget.value, })}
                        />
                        </Grid>
                      </Grid>
                          <label>
                            بازه زمانی ارسال
                          </label>
                      <Grid container sx={{display: "flex", justifyContent:"flex-start", padding:"5px"}}>
                          <Grid item={true} sm={12} md={6}>
                          <DatePicker 
                            className="rmdp-prime"
                            inputClass="date-picker"
                            editable={false}
                            minDate={moment(new Date().setDate(new Date().getDate() + 1)).format("jYYYY/jM/jD")}
                            maxDate={moment(new Date().setDate(new Date().getDate() + 7)).format("jYYYY/jM/jD")}
                            mapDays={({ date }) => {
                              let isWeekend = [6].includes(date.weekDay.index)
                              if (isWeekend) return {
                                disabled: true,
                                style: { color: "#ccc" },
                                onClick: () => alert("آخر هفته‌ها غیر فعال هستند")
                              }
                            }}
                            value={date}
                            onChange={(date:any) => {
                              this.handleDate(date);
                            }}
                            placeholder="انتخاب بازه زمانی"
                            calendar={persian}
                            locale={persian_fa}>
                      </DatePicker>
                          </Grid>
                          <Grid item={true} sx={{display:"flex", flexWrap:"wrap", justifyContent:"center"}} sm={12} md={6}>
                          <ButtonTab
                          title= "۱۰ الی ۱۳"
                          myStyle="hour-btn"
                          isActive={step === 0 ? true : false}
                          onClick={() => this.handleSetStep(0)}
                          />

                        <ButtonTab
                        title= "۱۳ الی ۱۶"
                        isActive={step === 1 ? true : false}
                        onClick={() => this.handleSetStep(1)}
                        myStyle="hour-btn"

                        />
                        <ButtonTab
                        title= "۱۶ الی ۱۹"
                        isActive={step === 2 ? true : false}
                        onClick={() => this.handleSetStep(2)}
                        myStyle="hour-btn mt-1"
                        />
                          </Grid>
                      </Grid>
                      <Grid container sx={{ display: "flex", marginBottom: "5px" }}>
                        <Grid item={true} sm={12} lg={6} sx={{ marginBottom: "5px", padding: "5px" }}>
                          <InputIcon
                            value={recieverName}
                            inBox={"نام و نام خانوادگی"}
                            type={"text"}
                            name={"recieverName"}
                            key="input"
                            errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "recieverName")[0]?.message}
                            onChange={(event) => this.setState({ recieverName: event.currentTarget.value }) }
                          />
                        </Grid>
                        <Grid item={true} sm={12} lg={6} sx={{ marginBottom: "5px", padding: "5px" }}>
                          <InputIcon
                            value={recieverPhone}
                            inBox={"شماره گیرنده"}
                            type={"number"}
                            onChange={(event) => this.setState({ recieverPhone: event.currentTarget.value })}
                            errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "recieverPhone")[0]?.message}
                            name={"recieverPhone"}
                          />
                        </Grid>
                      </Grid>
                      <Grid container sx={{ display: "flex", marginBottom: "5px" }}>
                        <Grid item={true} sm={12} lg={6} sx={{ padding: "5px" }} >
                        <RadioGroup
                          row
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="sendingStatus"
                          value={sendingStatus}
                          onChange={this.handleSendingStatus}>
                        <FormControlLabel
                        label={<Typography variant="subtitle2">دریافت حضوری</Typography>}
                        value="inPerson" control={<Radio />}/>
                        <FormControlLabel value="send" control={<Radio /> }   label={<Typography variant="subtitle2">ارسال برای مشتری</Typography>} />
                        </RadioGroup> 
                        </Grid>
                        {errorsList && errorsList?.filter((obj: any) => obj.path === "address")[0]?.message}
                        {sendingStatus === 'send' &&
                        <Grid item={true} sm={12} lg={6} sx={{ padding: "5px" }} >
                        <CustomButton
                            myStyle='new-address-btn'
                            loading={false}
                            onClick={this.handleNewAddress}
                            title={"افزودن آدرس"}
                            icon={<HiPlus />}
                          />
                        </Grid>
                        }
                      </Grid>
                      {sendingStatus === 'send' ?
                        <>
                          {addressLoading ? (
                            <LoadingSpinner />
                          ) : (
                            <>
                              <label>{addressMessage}</label>
                              <Box className="address">
                                <Box className="details">
                                  <FormControl>
                                    <RadioGroup
                                      sx={{ display: "flex" }}
                                      aria-labelledby="demo-controlled-radio-buttons-group"
                                      name="controlled-radio-buttons-group"
                                      value={address}
                                      onChange={this.handleAddress}
                                    >
                                      {usersAddressList?.length > 0 && usersAddressList.map((data: any, index: number) => {
                                        return (
                                          <FormControlLabel value={data?.id} control={<Radio />} label={<Box sx={{display:"flex", justifyContent:"space-between"}}><Typography sx={{fontSize:"12px"}} variant="subtitle2"><span className="color-blue">{data?.label} </span>{`${data?.address}`}
                                          <Tooltip title="ویرایش آدرس"><span><AiOutlineEdit className="icon-green" onClick={() => this.handleOpenEditAddressModal(data)}/></span></Tooltip></Typography></Box>}/>
                                          )
                                      })}
                                    </RadioGroup>
                                  </FormControl>
                                </Box>
                              </Box>
                            </>
                          )}
                  {addressError && <span className="error-text">انتخاب آدرس الزامی است.</span>}
                        </>
                        : ""}
                      <CustomButton
                        myStyle="submit-address-btn"
                        loading={false}
                        onClick={() => this.createFactor()}
                        title="تبدیل به فاکتور"
                      />
                      </>
                    }
                    </Box>  
                    </>                 
                    :
                    <>
                     {modalLoading ? <LoadingSpinner/> :
                    <>
                      <Grid container sx={{position: 'sticky', backgroundColor:"white", height:"10%",
                      zIndex:9000, top: 0, bottom:0, left:0, display: "flex", justifyContent: "space-between", marginTop:"10px" }}>
                        <Typography id="modal-modal-title" variant="subtitle1">
                        افزودن آدرس                  
                      </Typography> 
                        <Tooltip title="برگشت به صفحه پیش"><span><BsArrowLeft className="icon-back" onClick={() => this.setState({ toggleAddressPage: !toggleAddressPage })} /></span></Tooltip>
                      </Grid>
                    <Grid container>
                      <ul>
                      {errorsList.map((i:any, index:any)=>{
                        return(
                          <li className="error-text" key={index}>
                          {i.message}
                        </li>
                        )
                      
                      })}
                      </ul>
                    </Grid>
                    <AddressInputs addAddress={(argOne: any) =>                       
                        this.addAddress(argOne)
                      }/>
                    </>
                    }
                    </>
                }
                </Box>
              </Modal>
              {openEditAddressAlert &&
                  <SubmissionAlert
                  isOpen={openEditAddressAlert}
                  title="اخطار!"
                  setClose={(open: boolean) =>
                  this.setState({
                    openEditAddressAlert: open,
                  })
                  }
                  onClick={() => this.handleConfirmEdition()}
                  icon={<FiAlertTriangle color="orange" fontSize="50" />}
                  details={`آیا از ویراش آدرس شخص ${currentItem?.user?.full_name} با شناسه ${editAddressData?.id}  اطمینان دارید؟`}
                  />
                }
            <Modal
              open={openEditAdress}
              onClose={this.handleCloseAddressEditModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: {
                  xxs: "90%",
                  xs: "90%",
                  sm: "90%",
                  md: "80%",
                  lg: "70%",
                  xl: "70%"
                },
                height: 'max-content',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
              }}>
                <Box id="modal-modal-title" sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="subtitle1">
                    ویراش آدرس
                  </Typography>
                </Box>
                <AddressInputs addressDetail={addressData} addAddress={(argOne: any) =>                       
                        this.handleEdit(argOne)
                      }/>
              </Box>
            </Modal>
              <MaterialReactTable
                muiTablePaperProps={{
                  sx: { mr: '1.5rem', ml: '1.5rem', border: "1px solid #e5e5e5", mt: '1.5rem' , 
                  },
                }}
                muiTableBodyRowProps={( {row, staticRowIndex} ) => ( {
                  sx: {
                    height: "fit-content",
                    backgroundColor: row?.original?.item?.is_converted === 1 ? '#d1ecfb '  : (staticRowIndex % 2 === 0 ) ? '#f3f3f3'  : "" ,
                  },
                })}
                enableRowNumbers={true}
                rowNumberMode="static"
                columns={prefactorColumns}
                data={invoicesListData && invoicesListData?.data?.length > 0 && invoicesListData?.data?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null).map(
                  (item: any, index: number) => {
                    return (
                      {
                        item: item,
                        id:item.id && item.id,
                        created_at: item.created_at && item.created_at,
                        driver:<img className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo} alt=""/> ,
                        buyer: item?.name + item?.family ,
                        totalPrice: numberWithCommas(item?.totalPrice),
                        totalDiscount: numberWithCommas(item?.totalDiscount),
                        finalPrice:numberWithCommas(item?.finalPrice),
                        applier: item?.registered_by,
                      }
                    );
                  }
                )}
                state={{ isLoading: loading, showProgressBars: loading }}
                enablePagination={false}
                enableRowVirtualization
                renderBottomToolbarCustomActions={({table})=>{
                  return (
                    <div className="custom-pagination w-100">
                      <Pagination  page={page} count={invoicesListData?.total ? Math.ceil(invoicesListData?.total / rowCount) : 1} onChange={this.handleChangePage} />
                  <DropDownIcon
                  value={rowCount}
                  optionList={rowCountList}
                  title={""}
                  onChange={(event) => { this.handlePaginationSelect(event)}
                }
                />
                    </div>
                  );
                }}
                muiTableProps={{
                  sx: {
                    border: "1px solid #e5e5e5",
                  },
                }}
                muiTableHeadCellProps={{
                  sx: {
                    height:"fit-content",
                    border: "1px solid #e5e5e5",
                    textAlign:"center"
                  },
                }}
                muiTableBodyCellProps={{
                  sx: {
                    border: "1px solid #e5e5e5",
                    textAlign:"center",
                    justifyContent:"center"
                  },
                }}
                rowVirtualizerProps={{ overscan: 20 }} 
                rowVirtualizerInstanceRef={this.tableInstanceRef} 
                positionExpandColumn={'first'}
                enableGlobalFilter={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableHiding={false}
                renderTopToolbarCustomActions={({ table }) => (
                  <div className="d-flex w-100 flex-column">
                  <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap', justifyContent:"space-between", width:"100%" }}
                    >
                    <CustomButton
                      loading={false}
                      myStyle="excel-btn"
                      onClick={() => exportToExcel(invoicesListData?.data, "Excel Export")}
                      title="اکسل"
                      icon={<AiOutlineFileExcel />}
                    />
                    <Tooltip title="نمایش فیلترها"><span> <TfiFilter className='filter-icon' onClick={() => this.setState(prevState => ({
                                  showFilters: !prevState.showFilters}))
                              }/></span></Tooltip>
                    <div className='custom-pagination'>
                      <Pagination  page={page} count={invoicesListData?.total ? Math.ceil(invoicesListData?.total / rowCount) : 1} onChange={this.handleChangePage} />
                          <DropDownIcon
                          value={rowCount}
                          optionList={rowCountList}
                          title={""}
                          onChange={(event) =>
                            {
                              this.handlePaginationSelect(event)
                          }
                        }
                        />
                  </div>
                  </Box>
                  <div className="filter-tab">
                      <div className={`item ${(purchaseStatus === "1") && "is-active" }`}  onClick={() => this.handlePurchaseStatus("1")}>آنلاین</div>
                      <div className={`item ${(purchaseStatus === "0") && "is-active" }`} onClick={( )=> this.handlePurchaseStatus('0')}>آفلاین</div>
                      <div className={`item ${(converted === "1") && "is-active" }`} onClick={()=> this.handleConvertedStatus("1")}>تبدیل‌شده به فروش</div>
                      <div className={`item ${(converted === "0") && "is-active" }`} onClick={()=> this.handleConvertedStatus("0")}>لغو‌شده</div>
                  </div>
                </div>
                )}
                sortDescFirst={true}
                enableSorting={true}
                enableColumnActions={false}
                initialState={{
                  sorting: [
                    {
                      id: "created_at",
                      desc: true
                    }
                  ],
                }}
                enableRowActions
                localization={{actions: 'عملیات',noRecordsToDisplay: "داده‌ای برای نمایش وجود ندارد!"}}
                positionActionsColumn={'last'}
                renderRowActions={({ row, table }) => [
                  <div key={row?.id} className='action-container'>
                    {loading === false &&
                    <>
                    <Tooltip title="دانلود PDF"><span> <AiOutlineFilePdf className='icon-red' onClick={() => this.handleDownloadPDF(row.original)} /></span></Tooltip>
                    <Tooltip title="لیست محصولات پیش فاکتور"><span> <BiCube className='icon-green' onClick={() => this.handleOpenCreateFactorModal(row?.original)} /></span></Tooltip>
                    {row?.original?.item?.is_converted !== 1 && 
                    <Tooltip title="تبدیل به سفارش (فاکتور)"><span> <GrPowerCycle className='icon-gray' onClick={() => this.handleOpenPreOrderProductModal(row?.original)} /></span></Tooltip>
                  }
                  {row?.original?.item?.is_converted !== 1 && 
                      <Tooltip title="ویرایش"><Link to={`/prefactor-update/${row.original?.item?.id_select}`} target="_blank"><AiOutlineEdit className='icon-gray' /></Link></Tooltip>
                  }
                  </>
                }
                  </div>
                ]}
              />
            </div>
        </div>
      </ThemeProvider>
      </HelmetProvider>
    )
  }
}
const mapStateToProps = (dispatch:any) => {
  return {
    dispatch,
    siteList:dispatch.general.siteList,
    loadingSiteList:dispatch.general.loadingSiteList,
    userData:dispatch.general.userData,
  }
};
export default connect(mapStateToProps)(PreFactor);