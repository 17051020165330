
import { LoadingSpinner, Header, LableIcon, InputIcon, CustomButton, ImageUploader, TextAreaLabel, CustomContainer, SubmissionAlert } from "../../../components";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { AppearanceSettingsController } from "../../../controllers";
import { BsStars } from "react-icons/bs";
import { FiAlertTriangle } from "react-icons/fi";

class AppearanceSettings extends AppearanceSettingsController {
  componentDidMount() {
    this.getDate()
  }

  render() {
    const { loading ,
       errorsList ,
       enamadCode,
       organizationCode,
       newsletterTitle,
       placeholder,
       footerTitle,
       footerDescription,
       address,
       phone,
       copyRight,
       search,
       dLogo,
       mLogo,
       toggleModal
      } = this.state;

    return (
      <>
        <Helmet>
          <title> تنظیمات ظاهری</title>
        </Helmet>
        <Header title=" تنظیمات ظاهری" />
        {loading ? <LoadingSpinner /> : 
         <>
         <CustomContainer title=" تنظیمات هدر">
         <div className="site-slogan-item">
         <ImageUploader
          imageSize={"256 x 137 "}
          onClick={() => this.handleDeleteImage("d") }
          itemIndex="appearance-settings-m" 
          image={dLogo}
          title=" لوگو هدر (Desktop)"
           onChangeImage={(e:any) => this.uploadDLogo(e)} 
            myStyle="col-md-6 col-sm-12"/>

          <ImageUploader imageSize={"100 x 100 "}
           itemIndex="appearance-settings-d" 
          title="لوگو هدر (Mobile)"
           myStyle="col-md-6 col-sm-12"
           image={mLogo}
           onClick={() => this.handleDeleteImage("m") }
           onChangeImage={(e:any) => this.uploadMLogo(e)}
            />
          </div>
          {toggleModal.status &&
        <SubmissionAlert
                      isOpen={toggleModal.status}
                      title="اخطار!"
                      setClose={(open: boolean) =>
                        this.setState({
                          toggleModal: {
                            status: open,
                          },
                        })
                      }
                      onClick={()=> toggleModal.index === "m" ?  this.deleteMLogo() :  this.deleteDLogo()}
                      icon={<FiAlertTriangle color="orange" fontSize="50" />}
                      details="از حذف این تصویر اطمینان دارید؟"
                    />
                }
          <InputIcon
                  value={search}
                  title="محصول مورد نظر خود را جستجو کنید"
                  inBox="متن نمونه جستجو"
                  type={"text"}
                  name={"search"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "search")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      search: event.currentTarget.value,
                    })
                  }
                />
         </CustomContainer>
         
         <CustomContainer title="تنظیمات فوتر">

          <InputIcon
                  value={footerTitle}
                  title="عنوان فوتر ( H1 در صفحه اصلی)"
                  inBox="عنوان فوتر ( H1 در صفحه اصلی)"
                  type={"text"}
                  name={"footerTitle"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "footerTitle")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      footerTitle: event.currentTarget.value,
                    })
                  }
                />
                <TextAreaLabel
                          value={footerDescription}
                          onChange={(event: { target: { value: any; }; }) => this.setState({footerDescription: event.target.value})}
                          placeholder="متن فوتر                          "
                        />
                <InputIcon
                  value={address}
                  title="آدرس"
                  inBox="آدرس"
                  type={"text"}
                  name={"address"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "address")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      address: event.currentTarget.value,
                    })
                  }
                />
                <InputIcon
                  value={phone}
                  title="تلفن ها"
                  inBox="تلفن ها"
                  type={"text"}
                  name={"phone"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "phone")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      phone: event.currentTarget.value,
                    })
                  }
                />
                <InputIcon
                  value={copyRight}
                  title="کپی رایت"
                  inBox="کپی رایت"
                  type={"text"}
                  name={"copyRight"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "copyRight")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      copyRight: event.currentTarget.value,
                    })
                  }
                />
          <LableIcon title=" خبرنامه" icon={<BsStars />} />
          <InputIcon
                  value={newsletterTitle}
                  title="عنوان خبرنامه"
                  inBox="عنوان خبرنامه"
                  type={"text"}
                  name={"newsletterTitle"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "newsletterTitle")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      newsletterTitle: event.currentTarget.value,
                    })
                  }
                />
                <InputIcon
                  value={placeholder}
                  title="placeholder"
                  inBox="placeholder"
                  type={"text"}
                  name={"placeholder"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "placeholder")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      placeholder: event.currentTarget.value,
                    })
                  }
                />

          <LableIcon title=" مجوزها" icon={<BsStars />} />
          <TextAreaLabel
                          value={enamadCode}
                          onChange={(event: { target: { value: any; }; }) => this.setState({enamadCode: event.target.value})}
                          placeholder="کد اینماد"
                          errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "enamadCode")[0]?.message}
                        />

<TextAreaLabel
                          value={organizationCode}
                          onChange={(event: { target: { value: any; }; }) => this.setState({organizationCode: event.target.value})}
                          placeholder="کد ساماندهی"
                          errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "organizationCode")[0]?.message}
                        />

              <CustomButton
                myStyle="btn-confirm"
                loading={false}
                onClick={() => this.update()}
                title="بروزرسانی "
              />
</CustomContainer>
            

      </>
        }
</>
    );
  }
}
const mapStateToProps = (dispatch: any) => {
  return {
    dispatch,
    siteList: dispatch.general.siteList,
    loadingSiteList: dispatch.general.loadingSiteList,
    userData: dispatch.general.userData,
  }
};
export default connect(mapStateToProps)(AppearanceSettings);
