import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Component } from "react";
import {notFoundImg} from "../../../../assets";
import Header from "../../../components/header";
class NotFound extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);


  }
  render() {
    return (
      <HelmetProvider>
      <div
        className="not-found"
      >
        <Helmet>
            <title>404</title>
        </Helmet>
        <Header />
        <img src={notFoundImg} alt="" />
        <span>ممکن است صفحه ای که به دنبال آن میگردید حذف شده باشد و یا آدرس آن را به درستی وارد نکرده باشید</span>
      </div>
      </HelmetProvider>
    );
  }
}

export default NotFound;
