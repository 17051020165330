import React, { Component, createRef } from 'react';
import { call_api, parseJSON } from "../../../core/service";
import { toast } from 'react-toastify';
import { AppConstants } from '../../../core/constants';
import * as yup from 'yup'
import moment from "moment-jalaali";
import { fixNumbers } from "../../../core/utils";

interface IProps {
  dispatch?:any,
  siteList?:any,
  loadingSiteList?:any,
}
interface IState {
  loading: boolean,
  page: number,
  openCommentsModal: boolean,
  comment: string,
  commentsListData?: any
  approved: string;
  search: string;
  currentItem: any,
  isCreating: boolean,
  errorsList?:any,
  dreiverId: any;
  statusList: any;
  statusId: any;
  statusSelectedList: any,
  productListData: any
  productLoading: boolean;
  productId: any,
  rowCount:any,
  rowCountList:any,
  paginate: number,
  showFilters:boolean,
  dateValue: any;


}
class UsersCommentsController extends Component<IProps, IState> {
  myRef:any;
  tableInstanceRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    this.myRef = createRef();
    this.tableInstanceRef = createRef()
    this.state = {   
      loading: false,
    page: 1,
    openCommentsModal: false,
    comment: "",
    commentsListData: [],
    approved: "",
    search: "",
    currentItem: {},
    isCreating: false,
    errorsList:[],
      dreiverId: [],
      statusList: [{ title: "در انتظار تایید", value: "0" }, { title: "تایید  ", value: "1" }, { title: "رد شده", value: "2" }],
      statusId: [],
      statusSelectedList: [],
      productListData: [],
      productLoading: false,
    productId: [],
    rowCount:100,
    rowCountList:[10, 25 , 50 , 100],
    paginate: 0,
    showFilters:false,
    dateValue: [],

        
    }
  }
  
  paginationSelectTimeOut: string | number | NodeJS.Timeout | undefined; 
  handlePaginationSelect=(event:any)=>{
    this.setState({
      rowCount: event.currentTarget.value,
      page:1
    })
    clearTimeout(this.paginationSelectTimeOut);
    this.paginationSelectTimeOut = setTimeout(() => {
      this.getCommentsList()
    }, 800);
    
  }
  dateTimeOut: string | number | NodeJS.Timeout | undefined;
  handleChangeDate = (value: any) => {
    this.setState({
      dateValue: value,
    });
    if (value?.length === 2) {
      this.setState({page:1})
      clearTimeout(this.dateTimeOut);
      this.dateTimeOut = setTimeout(() => {
        this.getCommentsList();
      }, 800);
    }
  };
  clearDateTimeOut: string | number | NodeJS.Timeout | undefined;
  handleClearDate = () => {
    this.setState({
      dateValue: [],
    });
    this.setState({page:1})
    clearTimeout(this.clearDateTimeOut);
    this.clearDateTimeOut = setTimeout(() => {
      this.getCommentsList();
    }, 800);
  };
  paginationTimeOut: string | number | NodeJS.Timeout | undefined; 
  handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ page: value })
    clearTimeout(this.paginationTimeOut);
    this.paginationTimeOut = setTimeout(() => {
      this.getCommentsList()
    }, 800);
  }
  statusTimeout: string | number | NodeJS.Timeout | undefined;
  statusSelected = (choice: any) => {
    this.setState({ statusId: choice })
    clearTimeout(this.statusTimeout);
    this.statusTimeout = setTimeout(() => {
      this.getCommentsList()
    }, 1000);
  }
  searchTimeout: string | number | NodeJS.Timeout | undefined;
  handleSearch = (event: any) => {
    this.setState({ search: event.currentTarget.value })
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.getCommentsList()
    }, 1000);
  };
  driverTimeout: string | number | NodeJS.Timeout | undefined;
  driverSelected = (choice: any) => {
    this.setState({ dreiverId: choice , page : 1 })
   
    clearTimeout(this.driverTimeout);
    this.driverTimeout = setTimeout(() => {
      this.getCommentsList()
    }, 1000);
  }
  searchProductTimeout: string | number | NodeJS.Timeout | undefined;
  handleSearchProduct = (choice: any) => { 
    clearTimeout(this.searchProductTimeout);
    if(choice.length > 2)
    this.searchProductTimeout = setTimeout(() => {
      this.getProductList(choice)
    }, 1000);
  }
  async getProductList(search?:string) {
    this.setState({ productLoading: true })
    const { dreiverId } = this.state;
    let parameters = {
      db_name: dreiverId?.value?.name,
      search: search ? search: null,
    }
    let url = new URL(`${AppConstants.base_url_api}seller/product/search`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}seller/product/search${url.search}` : `${AppConstants.base_url_api}seller/product/search`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(async ([status, j]) => {
        if (status === 200) {
          if(j.data){
            this.setState({
              productListData: j.data
            })
          }
          this.setState({
            productLoading: false
          })
        } else if (status === 401) {
          this.setState({ productLoading: false })
          localStorage.clear();
        } else {
          this.setState({ productLoading: false })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  productTimeout: string | number | NodeJS.Timeout | undefined;
  handleSelectProduct = (choice: any) => { 
    this.setState({ productId: choice })
    clearTimeout(this.productTimeout);
    this.productTimeout = setTimeout(() => {
      this.getCommentsList()
    }, 1000);
  }

  
  handleFinalReject = (item: any) => {
    call_api({
      address_api: `${AppConstants.base_url_api}seller/comments/${item.id}`,
      method_api: "PATCH",
      body: JSON.stringify({
        db_name: item.db_name,
        approved: 2
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.getCommentsList()
          toast.success("نظر با موفقیت لغو تایید شد.");
        } else if (status === 401) {
          toast.error(j?.errors?.message);
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  handleFinalConfirm = (item: any) => {
    call_api({
      address_api: `${AppConstants.base_url_api}seller/comments/${item.id}`,
      method_api: "PATCH",
      body: JSON.stringify({
        db_name: item.db_name,
        approved: 1
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.getCommentsList()
          toast.success("نظر با موفقیت تایید شد.");
        } else if (status === 401) {
          toast.error(j?.errors?.message);
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  handleCloseCommentsModal = () => {
    this.setState({ openCommentsModal: false })
  }

async handleSendComment() {
    const { currentItem, comment } = this.state;
    const commentSchema = yup.object({
      comment: yup.string().required(" متن نظر الزامی می باشد ").max(255,  " متن نظرات شما باید حداکثر 255 کاراکتر باشد").min(10, " متن نظرات شما باید حداقل 10 کاراکتر باشد").trim(),
  });

    try {
      this.setState({ errorsList: [] })
      await commentSchema.validate({ comment }, { abortEarly: false });
         this.setState({ isCreating: true })
         call_api({
          address_api: `${AppConstants.base_url_api}seller/comments`,
          method_api: "POST",
          body: JSON.stringify({
            user_id: currentItem.user_id,
            reply_id: currentItem.id,
            comment: comment,
            db_name: currentItem.db_name,
            is_approved: Number(currentItem.approved?.value),
            product_id: currentItem.product_id
          })
        })
          .then(parseJSON)
          .then(([status, j]) => {
            if (status === 200) {
              toast.success(" با موفقیت انجام شد.");
              this.setState({ comment: "", openCommentsModal: false, isCreating: false })
              this.getCommentsList()
            } else if (status === 401) {
              toast.error(j?.message);
              localStorage.clear();
              this.setState({ comment: "", openCommentsModal: false, isCreating: false })
    
            } else {
              toast.error(j?.message);
              this.setState({isCreating: false })
            }
          })
          .catch((error) => {
            console.error(error);
            this.setState({ comment: "", openCommentsModal: false, isCreating: false })
          });
  
  

    }catch (err: any) {
      this.setState({ isCreating: false })
      this.setState({ errorsList: err.inner })
    }

  }
  async getCommentsList() {

    const { search, rowCount , statusId, productId , page, dateValue } = this.state;
    this.setState({ loading: true })
    const statusSelectedList = [];
    for (var i = 0; i < statusId.length; i++) {
      statusSelectedList.push(statusId[i]?.value)
    }
    
    let parameters = {
      search: search ? search : null,
      products:productId?.id ? productId?.id : null,
      db_name: this.state.dreiverId?.value?.name ,
      approved: statusId?.length > 0 ? statusSelectedList : null,
      page: page ? page.toString() : null,
      paginate: rowCount,
      start_at:
      dateValue.length === 2
        ? moment(
            fixNumbers(dateValue[0]?.format("YYYY-MM-DD")),
            "jYYYY/jMM/jDD"
          ).format("YYYY-MM-DD")
        : null,
    end_at:
      dateValue.length === 2
        ? moment(
            fixNumbers(dateValue[1]?.format("YYYY-MM-DD")),
            "jYYYY/jMM/jDD"
          ).format("YYYY-MM-DD")
        : null,
    }
    let url = new URL(`${AppConstants.base_url_api}seller/comments`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}seller/comments${url.search}` : `${AppConstants.base_url_api}seller/comments`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
            commentsListData: j.data,
            loading: false

          })
        } else if (status === 401) {
          this.setState({ loading: false })
          localStorage.clear();
        } else {
          this.setState({ loading: false })
          toast.error(j?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  modalSelectTimeOut: string | number | NodeJS.Timeout | undefined; 
  handleOpenModal=(row:any)=>{
    this.setState({ currentItem: row, openCommentsModal: true })
    clearTimeout(this.modalSelectTimeOut);
    this.modalSelectTimeOut = setTimeout(() => {
      this.myRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 800);
  }
}
export default UsersCommentsController;