import {
    InputHTMLAttributes,
    ReactElement,
    useCallback,
    ChangeEvent,
  } from "react";
import { AiOutlineCloudUpload, AiOutlineDelete } from "react-icons/ai";
  
  interface Props extends InputHTMLAttributes<HTMLInputElement> {
    myStyle?:string;
    title?:string;
    image?:any;
    onClick?:any;
    imageAlt?:string;
    imageSize?:string;
    onChangeImage?:any;
    itemIndex?:any;

  }
  
const ImageUploader=({
    title,
    image,
    myStyle,
    onChangeImage,
    imageSize,
    imageAlt,
    itemIndex,
    onClick,
  }: Props): ReactElement => {
    const handleOnChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        onChangeImage?.(event);
      },
      [onChangeImage]
    );    
    return (
      <div className={`image-uploader ${myStyle}`} key={itemIndex}>
        {title && 
        <span className="title">
          {title}
        </span>
        }
        <img src={image} alt={imageAlt} className="img"/>
        
        <div className="btn-container">
            
            <div className="select btn">
            <>
               <input
                  name={`file-${itemIndex}`}
                  type="file" 
                  accept="image/png, image/jpg, image/jpeg"
                  hidden
                  
                  key={itemIndex}
                  id={`file-${itemIndex}`}
                  onChange={handleOnChange}
                />
              <label htmlFor={`file-${itemIndex}`}  className="payment-pic">
               
                <div className="upload-pic">
                  
                  <AiOutlineCloudUpload className="ms-1"/> 
                 <span>انتخاب تصویر</span>
                 </div>
                </label>
               
                </>
            </div>
            <div className="delete btn" onClick={onClick}>
            <AiOutlineDelete className="ms-1"/>  حذف 
            </div>
        </div>
        {imageSize && 
        <span className="title">
          {imageSize} پیکسل
        </span>
        }
      </div>
    );
  }
  export default ImageUploader;
  