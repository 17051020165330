
import {  CustomButton , DropDownIcon, Accordion, Header } from "../../../components";
import {SystemEventsController} from "../../../controllers";
import { createTheme, ThemeProvider, Tooltip } from '@mui/material';
import Select, { CSSObjectWithLabel } from "react-select";
import { MaterialReactTable } from 'material-react-table';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import { AiOutlineFileExcel } from "react-icons/ai";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {TfiFilter} from "react-icons/tfi";
import { systemEventsColumns } from "../../../../core/column";
import { exportToExcel } from "../../../../core/utils";

class systemEvents extends SystemEventsController {
  componentDidMount() {
    this.getEventList()
    this.getLogNameList()
    this.getDescNameList()
    window.scrollTo(0, 0);
  }
  render() {

    const { logName, logNameList, total, showFilters, page , rowCount,rowCountList, logNameLoding,
       descName, descriptionList, descriptionLoding, eventListData, loading} = this.state;
    const theme = createTheme({
      typography: {
        fontFamily:
          'Vazir',
      }
    });

    return (
      <HelmetProvider>
      <ThemeProvider theme={theme}>
        <div className="system-events">
          <Helmet>
                <title> رویدادهای سیستم    </title>
            </Helmet>
          <Header title="رویدادهای سیستم"/>
          <div className="custom-card mb-3">
          {showFilters && 
                    
            <div className="accordion-container">
                <Accordion title='فیلترها' content={
                      <>
                  <Select
                      styles={{
                        control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                          ...baseStyles,
                          boxShadow: state.isFocused
                            ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                            : "",
                          borderWidth: "1px",
                          backgroundColor: "#fff",
                          display: "flex",
                          flexDirection: "row-reverse",
                          textAlign: "right",
                          borderRadius: "4px",
                          height:"35px",
                          minHeight:"20px",
                          marginBottom:"10px",
                          outline: "unset",

                        }) as CSSObjectWithLabel,
                      }}
                      onChange={(choice) => this.logNameSelected(choice)}
                      value={logName}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      options={logNameList}
                      getOptionLabel={(option: any) => option.log_name_persian}
                      getOptionValue={(option: any) => option.log_name}
                      placeholder="نوع"
                      loadingMessage={() => 'در حال بارگذاری  ...'}
                      isClearable
                      isLoading={logNameLoding}
                    />
                    <Select
                  styles={{
                    control: (baseStyles, state):CSSObjectWithLabel => ({
                      ...baseStyles,
                      boxShadow: state.isFocused
                        ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                        : "",
                      borderWidth: "1px",
                      backgroundColor: "#fff",
                      display: "flex",
                      flexDirection: "row-reverse",
                      textAlign: "right",
                      borderRadius: "4px",
                      height:"35px",
                      minHeight:"20px",
                      outline: "unset",
                    }) as CSSObjectWithLabel,
                  }}
                  onChange={(choice) => this.descSelected(choice)}
                  value={descName}
                  isMulti={false}
                  closeMenuOnSelect={true}
                  options={descriptionList}
                  getOptionLabel={(option: any) => option.description}
                  getOptionValue={(option: any) => option.description_name}
                  placeholder="نوع"
                  isClearable
                  isLoading={descriptionLoding}
                  loadingMessage={() => 'در حال بارگذاری  ...'}

                    />
                    </>
                    } />
              </div>
            }
              <div className="custom-table">
                <MaterialReactTable
                muiTablePaperProps={{
                  sx: { mr: '1.5rem',ml: '1.5rem', border:"1px solid #e5e5e5", mt:"1.3rem" },
                }}
                muiTableBodyRowProps={( {row,staticRowIndex} ) => ({
                  sx:{
                    height: "fit-content",
                    backgroundColor: (staticRowIndex % 2 === 0 ) ? '#f3f3f3'  : "",
                }})}
                muiTableProps={{
                  sx: {
                    border: "1px solid #e5e5e5",
                  },
                }}
                muiTableHeadCellProps={{
                  sx: {
                    height:"fit-content",
                    border: "1px solid #e5e5e5",
                    textAlign:"center",
                    justifyContent:"center",
                  },
                }}
                muiTableBodyCellProps={{
                  sx: {
                    height:"fit-content",
                    border: "1px solid #e5e5e5",
                    textAlign:"center"
                  },
                }}
                  columns={systemEventsColumns}
                  data={eventListData && eventListData?.data?.length > 0 && eventListData?.data?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null).map((item: any, index: number) => {
                    return (
                      {
                        id: item?.id,
                        user: item.causer?.full_name,
                        title: item.log_name_persian,
                        details: item.description,
                        date: item.created_at && item.created_at,
                      }
                    );
                  }
                  )}
                  localization={{
                    noRecordsToDisplay:"داده‌ای برای نمایش وجود ندارد!"
                  }}
                  enablePagination={false}
                  renderBottomToolbarCustomActions={({table})=>{
                  return (
                    <div className="custom-pagination w-100">
                       <Pagination  page={page} count={total ? Math.ceil(total / rowCount) : 1}  onChange={this.handleChangePage} />
                        <DropDownIcon
                        value={rowCount}
                        optionList={rowCountList}
                        title={""}
                        onChange={(event) =>
                          {
                            this.handlePaginationSelect(event)
                        }
                      }
                      />
                    </div>
                  );
                }}
                  state={{ isLoading: loading, showProgressBars: loading }}
                  enableGlobalFilter={false}
                  enableColumnFilters={false}
                  enableDensityToggle={false}
                  enableFullScreenToggle={false}
                  enableHiding={false}
                  enableColumnActions={false}
                  sortDescFirst={true}
                  enableSorting={true}
                  initialState={{
                    sorting: [
                      {
                        id: 'id',
                        desc: false,

                      },
                      {
                        id: "date",
                        desc: true
                      }

                    ],
                  }}
                  renderTopToolbarCustomActions={({ table }) => (
                    <div className="d-flex w-100 flex-column">
                    <Box
                      sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap', justifyContent:"space-between", width:"100%"  }}
                    >
                      <CustomButton
                        loading={false}
                        myStyle="excel-btn"
                        onClick={() => exportToExcel(eventListData?.data, "Excel Export")}
                        title="اکسل"
                        icon={<AiOutlineFileExcel />}
                      />
                      <Tooltip title="نمایش فیلترها"><span> <TfiFilter className='filter-icon' onClick={() => this.setState(prevState => ({
                                  showFilters: !prevState.showFilters
                                }))
                              }/></span></Tooltip>
                       <div className="custom-pagination">
                       <Pagination  page={page} count={total ? Math.ceil(total / rowCount) : 1}  onChange={this.handleChangePage} />
                        <DropDownIcon
                        value={rowCount}
                        optionList={rowCountList}
                        title={""}
                        onChange={(event) =>
                          {
                            this.handlePaginationSelect(event)
                        }
                      }
                      />
                    </div>
                    </Box>
                    </div>
                  )}
                />
              </div>
          </div>
        </div>
      </ThemeProvider >
      </HelmetProvider>
    );
  }
}

export default systemEvents;
