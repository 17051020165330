import { HTMLAttributes, ReactElement} from "react";

interface Props extends HTMLAttributes<HTMLElement> {
    title:string;
    isActive:boolean;
    icon?:ReactElement;
    count?:number;
    onClick:any;
    myStyle?:string;
}

const BtnTab = ({title, isActive, icon, onClick, count, myStyle}: Props): ReactElement => {

    return (
        <div className={`${myStyle} ${isActive ? "btn-tab-active btn-tab" : "btn-tab"}`} onClick={onClick}>
            {icon && icon}
            <span className="btn-tab-title">
            {title && title}
            </span>
            <span>
            {count && count}
            </span>
        </div>
    )
}
export default BtnTab;