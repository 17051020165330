import { useState } from "react";
import { HTMLAttributes, ReactElement} from "react";
import { call_api, parseJSON } from "../../../../core/service";
import { AppConstants } from "../../../../core/constants";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { BiRecycle, BiShowAlt } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegDotCircle } from "react-icons/fa";
import { AiOutlineEdit } from "react-icons/ai";
interface Props extends HTMLAttributes<HTMLElement> {
    explorer?:any;
    onClick?:any;
    key:any;
    handleEdit?:any;
    handleDelete?:any;
    handleRecycleItem?:any;
    handleNoIndexItem?:any;
}

function TreeStructure({ explorer, onClick, key, handleEdit, handleDelete, handleRecycleItem, handleNoIndexItem }:  Props):ReactElement  {
  
  const [expand, setExpand] = useState(true);
  const [tree, setTree] = useState<any>([]);
  const [message, setMessage] = useState("");
  const handleDetailPannel = (item:any) =>{
      call_api({
        address_api: `${AppConstants.base_url_api}admin/categories/childs/${item}`,
        method_api: "GET",
        
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            if(j.data)
            {
              setTree(j.data)
            }
            else setMessage(j.message)
            
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    const editHandler = (exp:any) =>{
      handleEdit(exp)
    }
    const deleteHandler = (exp:any) =>{
      handleDelete(exp)
    }
    const recycleHandler = (exp:any) =>{
      handleRecycleItem(exp)
    }
    const noIndexHandler = (exp:any) =>{
      handleNoIndexItem(exp)
    }

    if (tree?.length > 0) {
      return (
        <div style={{ marginTop: 5 }}>
          <ul onClick={() => setExpand(!expand)}>
  
          <li className="parent" key={key} >
         <span onClick={()=>handleDetailPannel(explorer.id_select)}>{explorer?.id}</span>
        <span><img src={explorer?.image} alt=""/></span>

        <span>{explorer.persian_name}</span>
        <span>{explorer?.products_count}</span>

        <span> 
            {explorer?.deleted_at ? (
                     <Tooltip title="بازیابی کردن">
                      <span>
                        <BiRecycle
                          className="icon-green"
                           onClick={()=> handleRecycleItem(explorer)}
                        />
                      </span>
                      </Tooltip>
                          ) : (
                      <Tooltip title="حذف کردن">
                        <span>
                        <RiDeleteBin6Line
                          className="icon-red"
                          onClick={() =>handleDelete(explorer)}
                          />
                          </span>
                        </Tooltip>
                         )}
                   
                   <Tooltip title={`${explorer?.no_index === 1 ? "no-index" : "index"} `}>
                        <span>
                          {" "}
                          <FaRegDotCircle
                            className={`${explorer?.no_index === 1 ? "icon-red" : "icon-green"} `}
                            onClick={() => handleNoIndexItem(explorer)}
                          />
                        </span>
                      </Tooltip>

                    <Tooltip title="نمایش">
                      <span>
                        <Link target="_blank" to={explorer?.view}>
                          <BiShowAlt className="icon-gray" />
                        </Link>
                      </span>
                    </Tooltip>
                    <Tooltip title="ویرایش"><span >
                    <AiOutlineEdit onClick={()=>handleEdit(explorer)} className='icon-yellow' />
                    </span></Tooltip>

        </span>
        </li>
          </ul>
  
          <ul  className={`parent ${expand ? "d-block" : "d-none"}`}>
            {tree.map((exp:any) => {
              return (
                <TreeStructure
                  key={exp.id}
                  explorer={exp}
                  handleEdit={()=>editHandler(exp)}
                  handleDelete={()=> deleteHandler(exp)}
                  handleRecycleItem = {() => recycleHandler(exp)}
                  handleNoIndexItem = {()=> noIndexHandler(exp)}
                />
              );
            })}
          </ul>
        </div>
      );
    } else {
      return <li className="children" key={key} >
         <span onClick={()=>handleDetailPannel(explorer.id_select)}>{explorer?.id}</span>
        <span><img src={explorer?.image} alt=""/></span>

        <span>{explorer.persian_name}</span>
        <span>{explorer?.products_count}</span>
        <span> 
                {explorer?.deleted_at ? (
                     <Tooltip title="بازیابی کردن">
                      <span>
                        {" "}
                        <BiRecycle
                          className="icon-green"
                          onClick={()=>handleRecycleItem(explorer)}
                        />
                      </span>
                      </Tooltip>
                                         ) : (
                                           <Tooltip title="حذف کردن">
                                             <span>
                                               {" "}
                                               <RiDeleteBin6Line
                                                 className="icon-red"
                                                onClick={() =>handleDelete(explorer)}
                                               />
                                             </span>
                                           </Tooltip>
                                         )}
                   
                   <Tooltip title={`${explorer?.no_index === 1 ? "no-index" : "index"} `}>
                        <span>
                          {" "}
                          <FaRegDotCircle
                            className={`${explorer?.no_index === 1 ? "icon-red" : "icon-green"} `}
                            onClick={() => handleNoIndexItem(explorer)}
                          />
                        </span>
                      </Tooltip>

                    <Tooltip title="نمایش">
                      <span>
                        <Link target="_blank" to={explorer?.view}>
                          <BiShowAlt className="icon-gray" />
                        </Link>
                      </span>
                    </Tooltip>
                    <Tooltip title="ویرایش"><span >
                    <AiOutlineEdit onClick={()=>handleEdit(explorer)} className='icon-yellow' />
                    </span></Tooltip>

        </span>
        <span className="error-text">{message}</span>
              </li>
    }
  }
  
  

export default TreeStructure