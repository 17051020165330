
import { LoadingSpinner, Header, LableIcon, InputIcon, CustomButton, CustomContainer } from "../../../components";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { WebsiteInformationController } from "../../../controllers";
import { BsStars } from "react-icons/bs";

class WebsiteInformation extends WebsiteInformationController {
  componentDidMount() {
    this.getDate()
  }

  render() {
    const { loading, supportEmail,
      managementEmail,
      financialEmail,
      phoneOne,
      phoneTwo,
      phoneThree,
      smsSystem,
      fax,
      address,
      xCoordinate,
      yCoordinate,
      postalCode,
      telegram,
      instagram,
      whatsapp,
      linkdin,
       errorsList } = this.state;

    return (
      <>
        <Helmet>
          <title>  اطلاعات وبسایت</title>
        </Helmet>
        <Header title="  اطلاعات وبسایت" />
        {loading ? <LoadingSpinner /> : 
         <>
          
          <CustomContainer>
            <div className="content">
            <div className="col-md-12">
            <LableIcon title=" ایمیل ها" icon={<BsStars />} />
            </div>
              <div className="col-md-4 col-sm-12 pe-3">
                <InputIcon
                  value={supportEmail}
                  title="ایمیل پشتیبانی"
                  inBox="ایمیل پشتیبانی"
                  type={"text"}
                  name={"supportEmail"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "supportEmail")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      supportEmail: event.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="col-md-4 col-sm-12 pe-3">
                <InputIcon
                  value={managementEmail}
                  title=" ایمیل مدیریت"
                  inBox=" ایمیل مدیریت"
                  type={"text"}
                  name={"managementEmail"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "managementEmail")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      managementEmail: event.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="col-md-4 col-sm-12 pe-3">
                <InputIcon
                  value={financialEmail}
                  title="ایمیل مالی "
                  inBox="ایمیل مالی "
                  type={"text"}
                  name={"financialEmail"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "financialEmail")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      financialEmail: event.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="col-md-12">
            <LableIcon title=" تلفن تماس " icon={<BsStars />} />
            </div>
              <div className="col-md-4 col-sm-12 pe-3">
                <InputIcon
                  value={phoneOne}
                  title="تلفن تماس یک"
                  inBox="تلفن تماس یک"
                  type={"text"}
                  name={"phoneOne"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "phoneOne")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      phoneOne: event.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="col-md-4 col-sm-12 pe-3">
                <InputIcon
                  value={phoneTwo}
                  title="تلفن تماس دو"
                  inBox="تلفن تماس دو"
                  type={"text"}
                  name={"phoneTwo"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "phoneTwo")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      phoneTwo: event.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="col-md-4 col-sm-12 pe-3">
                <InputIcon
                  value={phoneThree}
                  title="تلفن تماس سه"
                  inBox="تلفن تماس سه"
                  type={"text"}
                  name={"phoneThree"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "phoneThree")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      phoneThree: event.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="col-md-4 col-sm-12 pe-3">
                <InputIcon
                  value={smsSystem}
                  title="سامانه پیامک"
                  inBox="سامانه پیامک"
                  type={"number"}
                  name={"smsSystem"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "smsSystem")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      smsSystem: event.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="col-md-4 col-sm-12 pe-3">
                <InputIcon
                  value={fax}
                  title="فکس"
                  inBox="فکس"
                  type={"text"}
                  name={"fax"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "fax")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      fax: event.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="col-md-12">
            <LableIcon title="نشانی شرکت" icon={<BsStars />} />
            </div>
            <div className="col-md-12 col-sm-12 pe-3">
                <InputIcon
                  value={address}
                  title="آدرس"
                  inBox="آدرس"
                  type={"text"}
                  name={"address"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "address")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      address: event.currentTarget.value,
                    })
                  }
                />
      </div>

                <div className="col-md-4 col-sm-12 pe-3">
                <InputIcon
                  value={xCoordinate}
                  title="مختصات x"
                  inBox="مختصات x"
                  type={"text"}
                  name={"xCoordinate"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "xCoordinate")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      xCoordinate: event.currentTarget.value,
                    })
                  }
                />
      </div>

                <div className="col-md-4 col-sm-12 pe-3">
                <InputIcon
                  value={yCoordinate}
                  title="مختصات y "
                  inBox="مختصات y "
                  type={"text"}
                  name={"yCoordinate"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "yCoordinate")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      yCoordinate: event.currentTarget.value,
                    })
                  }
                />
      </div>

                <div className="col-md-4 col-sm-12 pe-3">
                <InputIcon
                  value={postalCode}
                  title="کد پستی"
                  inBox="کد پستی"
                  type={"text"}
                  name={"postalCode"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "postalCode")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      postalCode: event.currentTarget.value,
                    })
                  }
                />
            </div>

            <div className="col-md-12">
            <LableIcon title=" شبکه های اجتماعی " icon={<BsStars />} />
            </div>
            <div className="col-md-4 col-sm-12 pe-3">
                <InputIcon
                  value={telegram}
                  title="آی دی تلگرام"
                  inBox="آی دی تلگرام"
                  type={"text"}
                  name={"telegram"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "telegram")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      telegram: event.currentTarget.value,
                    })
                  }
                />
            </div>
            <div className="col-md-4 col-sm-12 pe-3">
                <InputIcon
                  value={whatsapp}
                  title="شماره واتس اپ"
                  inBox="شماره واتس اپ"
                  type={"text"}
                  name={"whatsapp"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "whatsapp")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      whatsapp: event.currentTarget.value,
                    })
                  }
                />
            </div>
            <div className="col-md-4 col-sm-12 pe-3">
                <InputIcon
                  value={instagram}
                  title="آی دی اینستاگرام"
                  inBox="آی دی اینستاگرام"
                  type={"text"}
                  name={"instagram"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "instagram")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      instagram: event.currentTarget.value,
                    })
                  }
                />
            </div>
            <div className="col-md-12 col-sm-12 pe-3">
                <InputIcon
                  value={linkdin}
                  title="لینکدین"
                  inBox="لینکدین"
                  type={"text"}
                  name={"linkdin"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "linkdin")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      linkdin: event.currentTarget.value,
                    })
                  }
                />
            </div>
              <CustomButton
                myStyle="btn-confirm"
                loading={false}
                onClick={() =>  this.update()}
                title="بروزرسانی "
              />

          </div>
        

      </CustomContainer>
      

      </>
        }
</>
    );
  }
}
const mapStateToProps = (dispatch: any) => {
  return {
    dispatch,
    siteList: dispatch.general.siteList,
    loadingSiteList: dispatch.general.loadingSiteList,
    userData: dispatch.general.userData,
  }
};
export default connect(mapStateToProps)(WebsiteInformation);
