
import {LandingController} from "../../../controllers";
import { LandingCard, Header } from "../../../components";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { menuItems } from "../../../../core/constants";

class Landing extends LandingController {
  componentDidMount() {
    let route:string;
    if(window.location.pathname.includes("admin"))
    {
       route = window.location.pathname.substring(15, window.location.pathname.length);
    }
    else if (window.location.pathname.includes("seller"))
    {
      route = window.location.pathname.substring(16, window.location.pathname.length);
    }
    if(menuItems.filter((item:any) => item.route === route).length === 0){
       window.location.href="/404"
    }
    window.scrollTo(0, 0);
    this.handleMenuItems();
    document.addEventListener("click",this.handleMenuItems);
  }
  render() {
    const {menuItem} = this.state;
    // const {userData} = this.props;
    return (
      <HelmetProvider>
       <Helmet>
                <title> {`${menuItem[0]?.title}`} </title>
            </Helmet>
      <Header title={`${menuItem[0]?.title}`}/>
      <div className="landing">

                <div className="landing-container">
                {menuItem[0]?.children?.map((item:any, index:number)=>{
                //  menuItems[0]?.children?.filter((obj: any) => obj.role === userData?.roles[0].name)?.map((item:any, index:number)=> 
                      return(
                        <LandingCard key={index}  myStyle={item?.myStyle} title={item?.title}
                        route={item?.route}/>
                    )})}
                </div>
    </div>
    </HelmetProvider>
    )
}
}
const mapStateToProps = (dispatch:any) => {
  return {
    dispatch,
    userData:dispatch.general.userData,
  }
};
export default connect(mapStateToProps,)(Landing)

