import { CustomButton, Header, InputTextLabel, LoadingSpinner, RadioSquare} from "../../../components";
import {CreateProductsRelationController} from "../../../controllers";
import Select, { CSSObjectWithLabel } from "react-select";
import { connect } from "react-redux";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";
import { BiShowAlt } from "react-icons/bi";
import { FixedSizeList } from "react-window";

class CreateProductsRelation extends CreateProductsRelationController {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.handleAria(null);
    this.handleSouha(null);
    this.handleVento(null);
    this.handleLegrand(null);
    this.handleNetran(null);
  }
  
  render() {
    const {
      productListData1,
      productListData2,
      productListData3,
      productListData4,
      productListData5,
      productLoading1,
      productLoading2,
      productLoading3,
      productLoading4,
      productLoading5,
      isCreating,
      selected1,
      selected2,
      selected3,
      searchColumnFour,
      searchColumnFive,

      netranBrandId,
      legrandBrandId,
      ventoBrandId,
      souhaBrandId,
      ariaBrandId,

      netranBrandList,
      legrandBrandList,
      souhaBrandList,
      ventoBrandList,
      ariaBrandList,

      selectedListColumnOne,
      selectedListColumnTwo,
      selectedListColumnThree,
      selectedListColumnFour,
      selectedListColumnFive,

      netranCategory,
      ventoCategory,
      souhaCategory,
      legrandCategory,
      ariaCategory,
    
      netranCategoryList,
      ventoCategoryList,
      souhaCategoryList,
      ariaCategoryList,
      legrandCategoryList,
      
      netranTagList,
      ventoTagList,
      souhaTagList,
      ariaTagList,
      legrandTagList,

      netranTag,
      ventoTag,
      souhaTag,
      ariaTag,
      legrandTag,

      tagLoading,
    } = this.state;
    const listHeight = 500;
    const listWidth = 250;
    const rowSize = 60;
    const { siteList } = this.props;  
    
    const renderNetranRow = ( index: any, key:any, style:any, parent:any):any => {
      return(
        <div key={key} style={index?.style} >
        {productListData5[index.index]?.mId === null ?
          <li key={index?.index} className="list-item" onClick={() => this.handleSelectColumnFive(productListData5[index.index])}>
            <div className="row-right-side">
              <RadioSquare
                  myStyle="radio-style"
                  title={""}
                  key={productListData5[index.index]?.id}
                  isActive={
                    selectedListColumnFive?.id === productListData5[index.index]?.id
                    
                      ? true
                      : false
                  }
                  onClick={() =>this.handleSelectColumnFive(productListData5[index.index])}
                />
              
              <label>{productListData5[index.index]?.id}</label>
              </div>
            <span>{productListData5[index.index]?.product_name}</span>
            <Link to={productListData5[index.index]?.product_url} target="_blank">
            <BiShowAlt className="icon-gray" />
          </Link>
          </li>
          :
          <li key={index?.index} className="is-linked">
            <label>{productListData5[index.index]?.id}</label>
            <span>{productListData5[index.index]?.product_name}</span>
            <Link to={productListData5[index.index]?.product_url} target="_blank">
            <BiShowAlt className="icon-gray" />
          </Link>
          </li>
              }
              </div>
        )
    }
    const renderAriaRow = ( index: any, key:any, style:any, parent:any):any => {
      return(
        <div key={key} style={index?.style} >
        {productListData1[index.index]?.mId === null ?
          <li key={index?.index} className="list-item" onClick={() => this.handleSelectColumnOne(productListData1[index.index])}>
            <div className="row-right-side">
              <RadioSquare
                  title={""}
                  myStyle="radio-style"
                  key={productListData1[index.index]?.id}
                  isActive={
                    selectedListColumnOne?.id === productListData1[index.index]?.id
                    
                      ? true
                      : false
                  }
                  onClick={() =>this.handleSelectColumnOne(productListData1[index.index])}
                />
              
              <label>{productListData1[index.index]?.id}</label>
            </div>
            <span>{productListData1[index.index]?.product_name}</span>
            <Link to={productListData1[index.index]?.product_url} target="_blank">
            <BiShowAlt className="icon-gray" />
          </Link>
          </li>
          :
          <li key={index?.index} className="is-linked">
            <label>{productListData1[index.index]?.id}</label>
            <span>{productListData1[index.index]?.product_name}</span>
            <Link to={productListData1[index.index]?.product_url} target="_blank">
            <BiShowAlt className="icon-gray" />
          </Link>
          </li>
              }
              </div>
        )
    }
    const renderSouhaRow = ( index: any, key:any, style:any, parent:any):any => {
      return(
        <div key={key} style={index?.style} >
        {productListData2[index.index]?.mId === null ?
          <li key={index?.index} className="list-item" onClick={() => this.handleSelectColumnTwo(productListData2[index.index])}>
            <div className="row-right-side">
              <RadioSquare
                  title={""}
                  myStyle="radio-style"
                  key={productListData2[index.index]?.id}
                  isActive={
                    selectedListColumnTwo?.id === productListData2[index.index]?.id
                    
                      ? true
                      : false
                  }
                  onClick={() =>this.handleSelectColumnTwo(productListData2[index.index])}
                />
              
              <label>{productListData2[index.index]?.id}</label>
            </div>
            <span>{productListData2[index.index]?.product_name}</span>
            <Link to={productListData2[index.index]?.product_url} target="_blank">
            <BiShowAlt className="icon-gray" />
          </Link>
          </li>
          :
          <li key={index?.index} className="is-linked">
            <label>{productListData2[index.index]?.id}</label>
            <span>{productListData2[index.index]?.product_name}</span>
            <Link to={productListData2[index.index]?.product_url} target="_blank">
            <BiShowAlt className="icon-gray" />
          </Link>
          </li>
              }
              </div>
        )
    }
    const renderVentoRow = ( index: any, key:any, style:any, parent:any):any => {
      return(
        <div key={key} style={index?.style} >
        {productListData3[index.index]?.mId === null ?
          <li key={index?.index} className="list-item" onClick={() => this.handleSelectColumnThree(productListData3[index.index])}>
            <div className="row-right-side">
              <RadioSquare
                  title={""}
                  myStyle="radio-style"
                  key={productListData3[index.index]?.id}
                  isActive={
                    selectedListColumnThree?.id === productListData3[index.index]?.id
                    
                      ? true
                      : false
                  }
                  onClick={() =>this.handleSelectColumnThree(productListData3[index.index])}
                />
              
              <label>{productListData3[index.index]?.id}</label>
              </div>
            <span>{productListData3[index.index]?.product_name}</span>
            <Link to={productListData3[index.index]?.product_url} target="_blank">
            <BiShowAlt className="icon-gray" />
          </Link>
          </li>
          :
          <li key={index?.index} className="is-linked">
            <label>{productListData3[index.index]?.id}</label>
            <span>{productListData3[index.index]?.product_name}</span>
            <Link to={productListData3[index.index]?.product_url} target="_blank">
            <BiShowAlt className="icon-gray" />
          </Link>
          </li>
              }
              </div>
        )
    }
    const renderLegrandRow = ( index: any, key:any, style:any, parent:any):any => {
      return(
        <div key={key} style={index?.style} >
        {productListData4[index.index]?.mId === null ?
          <li key={index?.index} className="list-item" onClick={() => this.handleSelectColumnFour(productListData4[index.index])}>
            <div className="row-right-side">
              <RadioSquare
                  title={""}
                  myStyle="radio-style"
                  key={productListData4[index.index]?.id}
                  isActive={
                    selectedListColumnFour?.id === productListData4[index.index]?.id
                    
                      ? true
                      : false
                  }
                  onClick={() =>this.handleSelectColumnFour(productListData4[index.index])}
                />
              
              <label>{productListData4[index.index]?.id}</label>
            </div>
            <span>{productListData4[index.index]?.product_name}</span>
            <Link to={productListData4[index.index]?.product_url} target="_blank">
            <BiShowAlt className="icon-gray" />
          </Link>
          </li>
          :
          <li key={index?.index} className="is-linked">
            <label>{productListData4[index.index]?.id}</label>
            <span>{productListData4[index.index]?.product_name}</span>
            <Link to={productListData4[index.index]?.product_url} target="_blank">
            <BiShowAlt className="icon-gray" />
          </Link>
          </li>
              }
              </div>
        )
    }
    return (
      <HelmetProvider>
      <div className="create-product-relations">
         <Helmet>
                <title>  ساخت محصولات مرتبط</title>
            </Helmet>
          <Header title="ساخت محصولات مرتبط "/>
      
          <div className="custom-table">
            <div className="filter-select-container pe-3">
                <div className="filter-options-container">
                  <div className="btn-center">
                  <div className="btn-container">
                  <CustomButton
                    myStyle="submit-btn"
                    loading={isCreating}
                    onClick={() => this.handleSubmit()}
                    title="ثبت نهایی"
                  />
                  </div>
                  </div>
                 
                </div>
              
            </div>
            <div className="select-container">
            <div className="item" >
            <img className="logo" alt="" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === "netran")[0]?.value?.logo}/>
               <InputTextLabel
                  type={"text"}
                  value={searchColumnFive}
                  onChange={(event: { target: { value: any; }; }) => this.handleSearchNetran(event.target.value)}
                      className="search-input"
                      placeholder="جستجو..."
                    />
                    <Select
                      styles={{
                                  control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                                    ...baseStyles,
                                    boxShadow: state.isFocused
                                      ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                      : "",
                                    borderWidth: "1px",
                                    backgroundColor:  "#fff",
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    textAlign: "right",
                                    borderRadius: "4px",
                                    height:"35px",
                                    minHeight:"20px",                                  
                                    outline: "unset",
                                    marginBottom: "10px",
                                  }) as CSSObjectWithLabel,
                                }}
                                onChange={(choice: any) =>
                                  this.netranBrandSelected(choice)
                                }
                                onInputChange={(input, action) => this.handleNetranInputBrand(input, action)}
                                value={netranBrandId}
                                isMulti={true}
                                closeMenuOnSelect={true}
                                options={netranBrandList}
                                noOptionsMessage={() => "این مورد وجود ندارد!"}
                                getOptionLabel={(option: any) =>
                                  option?.persian_name
                                }
                                getOptionValue={(option: any) => option?.id}
                                placeholder="برندها "
                                isClearable
                    />
                    <Select
                      styles={{
                                  control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                                    ...baseStyles,
                                    boxShadow: state.isFocused
                                      ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                      : "",
                                    borderWidth: "1px",
                                    backgroundColor:"#fff",
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    textAlign: "right",
                                    borderRadius: "4px",
                                    height:"35px",
                                    minHeight:"20px",                                   
                                    outline: "unset",
                                    marginBottom: "10px",
                                  }) as CSSObjectWithLabel,
                                }}
                                onChange={(choice: any) =>
                                  this.netranCategorySelected(choice)
                                }
                                value={netranCategory}
                                onInputChange={(input, action) => this.handleNetranInputCategory(input, action)}
                                isMulti={true}
                                noOptionsMessage={() => "این مورد وجود ندارد!"}
                                closeMenuOnSelect={true}
                                options={netranCategoryList}
                                getOptionLabel={(option: any) =>
                                  option?.persian_name
                                }
                                getOptionValue={(option: any) => option.id}
                                placeholder="همه دسته بندی‌ها"
                                isClearable
                    />
                   <Select
                    styles={{
                    control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                      ...baseStyles,
                      boxShadow: state.isFocused
                        ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                        : "",
                      borderWidth: "1px",
                      backgroundColor:"#fff",
                      display: "flex",
                      flexDirection: "row-reverse",
                      textAlign: "right",
                      borderRadius: "4px",
                      height:"35px",
                      minHeight:"20px",
                      outline: "unset",
                      marginBottom: "10px",
                    }) as CSSObjectWithLabel,
                  }}
                  onChange={(choice: any) =>
                    this.netranTagSelected(choice)
                  }
                  onInputChange={(input, action) => this.handleNetranInputTag(input, action)}
                  value={netranTag}
                  isMulti={true}
                  noOptionsMessage={() => "این مورد وجود ندارد!"}
                  closeMenuOnSelect={true}
                  options={netranTagList}
                  getOptionLabel={(option: any) =>
                    option.persian_name
                  }
                  // isLoading={tagLoading}
                  getOptionValue={(option: any) => option.id}
                  placeholder="برچسب"
                  isClearable
                  />
                  {productLoading5 ? <LoadingSpinner /> :
                  <FixedSizeList
                    height={listHeight}
                    width={listWidth}
                    itemCount={productListData5.length}
                    itemSize={rowSize} 
                    className="dir-rtl fixed-list"
                  >
                    {renderNetranRow}
                  </FixedSizeList>    
                  
                }      
            </div>
            <div className="item" >
            <img className="logo" alt="" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === "aria")[0]?.value?.logo}/>
               <InputTextLabel
                  type={"text"}
                  value={selected1}
                  onChange={(event: { target: { value: any; }; }) => this.handleSearchAria(event.target.value)}
                      className="search-input"
                      placeholder="جستجو..."
                    />
                      <Select
                        styles={{
                                  control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                                    ...baseStyles,
                                    boxShadow: state.isFocused
                                      ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                      : "",
                                    borderWidth: "1px",
                                    backgroundColor:  "#fff",
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    textAlign: "right",
                                    borderRadius: "4px",
                                    height:"35px",
                                    minHeight:"20px",                                  
                                    outline: "unset",
                                    marginBottom: "10px",
                                  }) as CSSObjectWithLabel,
                                }}
                                onChange={(choice: any) =>
                                  this.ariaBrandSelected(choice)
                                }
                                onInputChange={(input, action) => this.handleAriaInputBrand(input, action)}
                                value={ariaBrandId}
                                isMulti={true}
                                closeMenuOnSelect={true}
                                options={ariaBrandList}
                                noOptionsMessage={() => "این مورد وجود ندارد!"}
                                getOptionLabel={(option: any) =>
                                  option?.persian_name
                                }
                                getOptionValue={(option: any) => option?.id}
                                placeholder="برندها "
                                isClearable
                      />
                      <Select
                        styles={{
                                  control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                                    ...baseStyles,
                                    boxShadow: state.isFocused
                                      ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                      : "",
                                    borderWidth: "1px",
                                    backgroundColor:"#fff",
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    textAlign: "right",
                                    borderRadius: "4px",
                                    height:"35px",
                                    minHeight:"20px",                                   
                                    outline: "unset",
                                    marginBottom: "10px",
                                  }) as CSSObjectWithLabel,
                                }}
                                onChange={(choice: any) =>
                                  this.ariaCategorySelected(choice)
                                }
                                value={ariaCategory}
                                onInputChange={(input, action) => this.handleAriaInputCategory(input, action)}
                                isMulti={true}
                                noOptionsMessage={() => "این مورد وجود ندارد!"}
                                closeMenuOnSelect={true}
                                options={ariaCategoryList}
                                getOptionLabel={(option: any) =>
                                  option?.persian_name
                                }
                                getOptionValue={(option: any) => option.id}
                                placeholder="همه دسته بندی‌ها"
                                isClearable
                      />
                      <Select
                        styles={{
                        control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                          ...baseStyles,
                          boxShadow: state.isFocused
                            ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                            : "",
                          borderWidth: "1px",
                          backgroundColor:"#fff",
                          display: "flex",
                          flexDirection: "row-reverse",
                          textAlign: "right",
                          borderRadius: "4px",
                          height:"35px",
                          minHeight:"20px",
                          outline: "unset",
                          marginBottom: "10px",
                        }) as CSSObjectWithLabel,
                      }}
                      onChange={(choice: any) =>
                        this.ariaTagSelected(choice)
                      }
                      onInputChange={(input, action) => this.handleAriaInputTag(input, action)}
                      value={ariaTag}
                      isMulti={true}
                      noOptionsMessage={() => "این مورد وجود ندارد!"}
                      closeMenuOnSelect={true}
                      options={ariaTagList}
                      getOptionLabel={(option: any) =>
                        option.persian_name
                      }
                      // isLoading={tagLoading}
                      getOptionValue={(option: any) => option.id}
                      placeholder="برچسب"
                      isClearable
                      />
                    {productLoading1 ? <LoadingSpinner /> :
                       <FixedSizeList
                       height={listHeight}
                       width={listWidth}
                       itemCount={productListData1.length}
                       itemSize={rowSize} 
                       className="dir-rtl fixed-list"

                     >
                       {renderAriaRow}
                     </FixedSizeList> 
                      }
            </div>
            <div className="item">
            <img className="logo" alt="" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === "souha")[0]?.value?.logo}/>
               <InputTextLabel
                  value={selected2}
                  type={"text"}
                  onChange={(event: { target: { value: any; }; }) => this.handleSearchSouha(event.target.value)}
                      className="search-input"
                      placeholder="جستجو..."
                    />
                      <Select
                        styles={{
                                  control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                                    ...baseStyles,
                                    boxShadow: state.isFocused
                                      ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                      : "",
                                    borderWidth: "1px",
                                    backgroundColor:  "#fff",
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    textAlign: "right",
                                    borderRadius: "4px",
                                    height:"35px",
                                    minHeight:"20px",                                  
                                    outline: "unset",
                                    marginBottom: "10px",
                                  }) as CSSObjectWithLabel,
                                }}
                                onChange={(choice: any) =>
                                  this.souhaBrandSelected(choice)
                                }
                                onInputChange={(input, action) => this.handleSouhaInputBrand(input, action)}
                                value={souhaBrandId}
                                isMulti={true}
                                closeMenuOnSelect={true}
                                options={souhaBrandList}
                                noOptionsMessage={() => "این مورد وجود ندارد!"}
                                getOptionLabel={(option: any) =>
                                  option?.persian_name
                                }
                                getOptionValue={(option: any) => option?.id}
                                placeholder="برندها "
                                isClearable
                      />
                      <Select
                        styles={{
                                  control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                                    ...baseStyles,
                                    boxShadow: state.isFocused
                                      ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                      : "",
                                    borderWidth: "1px",
                                    backgroundColor:"#fff",
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    textAlign: "right",
                                    borderRadius: "4px",
                                    height:"35px",
                                    minHeight:"20px",                                   
                                    outline: "unset",
                                    marginBottom: "10px",
                                  }) as CSSObjectWithLabel,
                                }}
                                onChange={(choice: any) =>
                                  this.souhaCategorySelected(choice)
                                }
                                value={souhaCategory}
                                onInputChange={(input, action) => this.handleSouhaInputCategory(input, action)}
                                isMulti={true}
                                noOptionsMessage={() => "این مورد وجود ندارد!"}
                                closeMenuOnSelect={true}
                                options={souhaCategoryList}
                                getOptionLabel={(option: any) =>
                                  option?.persian_name
                                }
                                getOptionValue={(option: any) => option.id}
                                placeholder="همه دسته بندی‌ها"
                                isClearable
                      />
                      <Select
                        styles={{
                        control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                          ...baseStyles,
                          boxShadow: state.isFocused
                            ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                            : "",
                          borderWidth: "1px",
                          backgroundColor:"#fff",
                          display: "flex",
                          flexDirection: "row-reverse",
                          textAlign: "right",
                          borderRadius: "4px",
                          height:"35px",
                          minHeight:"20px",
                          outline: "unset",
                          marginBottom: "10px",
                        }) as CSSObjectWithLabel,
                      }}
                      onChange={(choice: any) =>
                        this.souhaTagSelected(choice)
                      }
                      onInputChange={(input, action) => this.handleSouhaInputTag(input, action)}
                      value={souhaTag}
                      isMulti={true}
                      noOptionsMessage={() => "این مورد وجود ندارد!"}
                      closeMenuOnSelect={true}
                      options={souhaTagList}
                      getOptionLabel={(option: any) =>
                        option.persian_name
                      }
                      // isLoading={tagLoading}
                      getOptionValue={(option: any) => option.id}
                      placeholder="برچسب"
                      isClearable
                      />
                      {productLoading2 ? <LoadingSpinner /> :
                     <FixedSizeList
                      height={listHeight}
                      width={listWidth}
                      itemCount={productListData2.length}
                      itemSize={rowSize} 
                      className="dir-rtl fixed-list"

                    >
                      {renderSouhaRow}
                    </FixedSizeList>    }
            </div>
            <div className="item">
            <img className="logo" alt="" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === "vento")[0]?.value?.logo}/>
               <InputTextLabel
                  type={"text"}
                  value={selected3}
                  onChange={(event: { target: { value: any; }; }) => this.handleSearchVento(event.target.value)}
                      className="search-input"
                      placeholder="جستجو..."
                    />
                      <Select
                        styles={{
                                  control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                                    ...baseStyles,
                                    boxShadow: state.isFocused
                                      ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                      : "",
                                    borderWidth: "1px",
                                    backgroundColor:  "#fff",
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    textAlign: "right",
                                    borderRadius: "4px",
                                    height:"35px",
                                    minHeight:"20px",                                  
                                    outline: "unset",
                                    marginBottom: "10px",
                                  }) as CSSObjectWithLabel,
                                }}
                                onChange={(choice: any) =>
                                  this.ventoBrandSelected(choice)
                                }
                                onInputChange={(input, action) => this.handleVentoInputBrand(input, action)}
                                value={ventoBrandId}
                                isMulti={true}
                                closeMenuOnSelect={true}
                                options={ventoBrandList}
                                noOptionsMessage={() => "این مورد وجود ندارد!"}
                                getOptionLabel={(option: any) =>
                                  option?.persian_name
                                }
                                getOptionValue={(option: any) => option?.id}
                                placeholder="برندها "
                                isClearable
                      />
                      <Select
                        styles={{
                                  control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                                    ...baseStyles,
                                    boxShadow: state.isFocused
                                      ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                      : "",
                                    borderWidth: "1px",
                                    backgroundColor:"#fff",
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    textAlign: "right",
                                    borderRadius: "4px",
                                    height:"35px",
                                    minHeight:"20px",                                   
                                    outline: "unset",
                                    marginBottom: "10px",
                                  }) as CSSObjectWithLabel,
                                }}
                                onChange={(choice: any) =>
                                  this.ventoCategorySelected(choice)
                                }
                                value={ventoCategory}
                                onInputChange={(input, action) => this.handleVentoInputCategory(input, action)}
                                isMulti={true}
                                noOptionsMessage={() => "این مورد وجود ندارد!"}
                                closeMenuOnSelect={true}
                                options={ventoCategoryList}
                                getOptionLabel={(option: any) =>
                                  option?.persian_name
                                }
                                getOptionValue={(option: any) => option.id}
                                placeholder="همه دسته بندی‌ها"
                                isClearable
                      />
                      <Select
                        styles={{
                        control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                          ...baseStyles,
                          boxShadow: state.isFocused
                            ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                            : "",
                          borderWidth: "1px",
                          backgroundColor:"#fff",
                          display: "flex",
                          flexDirection: "row-reverse",
                          textAlign: "right",
                          borderRadius: "4px",
                          height:"35px",
                          minHeight:"20px",
                          outline: "unset",
                          marginBottom: "10px",
                        }) as CSSObjectWithLabel,
                      }}
                      onChange={(choice: any) =>
                        this.ventoTagSelected(choice)
                      }
                      onInputChange={(input, action) => this.handleVentoInputTag(input, action)}
                      value={ventoTag}
                      isMulti={true}
                      noOptionsMessage={() => "این مورد وجود ندارد!"}
                      closeMenuOnSelect={true}
                      options={ventoTagList}
                      getOptionLabel={(option: any) =>
                        option.persian_name
                      }
                      // isLoading={tagLoading}
                      getOptionValue={(option: any) => option.id}
                      placeholder="برچسب"
                      isClearable
                      />
                      {productLoading3 ? <LoadingSpinner /> :
                      <FixedSizeList
                      className="dir-rtl fixed-list"
                      height={listHeight}
                      width={listWidth}
                      itemCount={productListData3.length}
                      itemSize={rowSize} 
                    >
                      {renderVentoRow}
                    </FixedSizeList> 
                      }
            </div>
            <div className="item">
            <img className="logo" alt="" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === "legrand")[0]?.value?.logo}/>

              <InputTextLabel
                  type={"text"}
                  value={searchColumnFour}
                  onChange={(event: { target: { value: any; }; }) => this.handleSearchLegrand(event.target.value)}
                      className="search-input"
                      placeholder="جستجو..."
                    />  
                      <Select
                        styles={{
                                  control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                                    ...baseStyles,
                                    boxShadow: state.isFocused
                                      ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                      : "",
                                    borderWidth: "1px",
                                    backgroundColor:  "#fff",
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    textAlign: "right",
                                    borderRadius: "4px",
                                    height:"35px",
                                    minHeight:"20px",                                  
                                    outline: "unset",
                                    marginBottom: "10px",
                                  }) as CSSObjectWithLabel,
                                }}
                                onChange={(choice: any) =>
                                  this.legrandBrandSelected(choice)
                                }
                                onInputChange={(input, action) => this.handleLegrandInputBrand(input, action)}
                                value={legrandBrandId}
                                isMulti={true}
                                closeMenuOnSelect={true}
                                options={legrandBrandList}
                                noOptionsMessage={() => "این مورد وجود ندارد!"}
                                getOptionLabel={(option: any) =>
                                  option?.persian_name
                                }
                                getOptionValue={(option: any) => option?.id}
                                placeholder="برندها "
                                isClearable
                      />
                      <Select
                        styles={{
                                  control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                                    ...baseStyles,
                                    boxShadow: state.isFocused
                                      ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                      : "",
                                    borderWidth: "1px",
                                    backgroundColor:"#fff",
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    textAlign: "right",
                                    borderRadius: "4px",
                                    height:"35px",
                                    minHeight:"20px",                                   
                                    outline: "unset",
                                    marginBottom: "10px",
                                  }) as CSSObjectWithLabel,
                                }}
                                onChange={(choice: any) =>
                                  this.legrandCategorySelected(choice)
                                }
                                value={legrandCategory}
                                onInputChange={(input, action) => this.handleLegrandInputCategory(input, action)}
                                isMulti={true}
                                noOptionsMessage={() => "این مورد وجود ندارد!"}
                                closeMenuOnSelect={true}
                                options={legrandCategoryList}
                                getOptionLabel={(option: any) =>
                                  option?.persian_name
                                }
                                getOptionValue={(option: any) => option.id}
                                placeholder="همه دسته بندی‌ها"
                                isClearable
                      />
                      <Select
                        styles={{
                        control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                          ...baseStyles,
                          boxShadow: state.isFocused
                            ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                            : "",
                          borderWidth: "1px",
                          backgroundColor:"#fff",
                          display: "flex",
                          flexDirection: "row-reverse",
                          textAlign: "right",
                          borderRadius: "4px",
                          height:"35px",
                          minHeight:"20px",
                          outline: "unset",
                          marginBottom: "10px",
                        }) as CSSObjectWithLabel,
                      }}
                      onChange={(choice: any) =>
                        this.legrandTagSelected(choice)
                      }
                      onInputChange={(input, action) => this.handleLegrandInputTag(input, action)}
                      value={legrandTag}
                      isMulti={true}
                      noOptionsMessage={() => "این مورد وجود ندارد!"}
                      closeMenuOnSelect={true}
                      options={legrandTagList}
                      getOptionLabel={(option: any) =>
                        option.persian_name
                      }
                      // isLoading={tagLoading}
                      getOptionValue={(option: any) => option.id}
                      placeholder="برچسب"
                      isClearable
                      />
                    {productLoading4 ? <LoadingSpinner /> :
                      <FixedSizeList
                      className="dir-rtl fixed-list"
                      height={listHeight}
                      width={listWidth}
                      itemCount={productListData4.length}
                      itemSize={rowSize} 
                    >
                      {renderLegrandRow}
                    </FixedSizeList> }
               
            </div>
          </div>
          </div>
      </div>
      </HelmetProvider>
    );
  }
}
const mapStateToProps = (dispatch: any) => {
  return {
    dispatch,
    siteList: dispatch.general.siteList,
    loadingSiteList: dispatch.general.loadingSiteList,
  }
};
export default connect(mapStateToProps)(CreateProductsRelation);

