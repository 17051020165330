import {
    InputHTMLAttributes,
    ReactElement,
    useState
  } from "react";
  import {AiOutlineFolderOpen} from "react-icons/ai"
  interface Props extends InputHTMLAttributes<HTMLInputElement> {
    files:any
  }
const Directory = ({ files }: Props) : ReactElement => {
    const [isExpanded, toggleExpanded] = useState(false);
    if (files.type === 'folder') {
        return (
            <div className="folder">
                <span className="folder-title" onClick={() => toggleExpanded(!isExpanded)}>{files.name}</span>
                <AiOutlineFolderOpen /><br />
                {
                    isExpanded && files.items.map((item:any) => <Directory files={item} />)
                }
            </div>
        )
    }
    return (
        <>
            <span className="file-name">{files.name}</span><br />
        </>
    )
}

export default Directory;