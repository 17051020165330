import { ReactElement , HTMLAttributes,
} from "react";

interface Props extends HTMLAttributes<HTMLElement> {
    title: string;
    icon?:ReactElement;
  }
  
const LableIcon = ({
    title,
    icon
  }: Props): ReactElement => {
       
    return (
       <div  className="lable-icon">
         <div className="headline">
            {icon}
           <span className="title">
            {title}
           </span>
        </div>
       </div>
    )
}
export default LableIcon;