import { CustomButton, InputIcon , Header, RadioSquare, DropDownIcon, Option} from "../../../components";
import { createTheme, ThemeProvider } from '@mui/material';
import Select, { CSSObjectWithLabel, components } from "react-select";
import { numberWithCommas, totalOrderDiscount, totoalOrderFinalPrice, totalOrdersPrice } from "../../../../core/utils";
import Modal from '@mui/material/Modal';
import Grid from "@mui/material/Grid";
import { connect } from "react-redux";
import Box from '@mui/material/Box';
import { BsWhatsapp } from "react-icons/bs";
import Typography from "@mui/material/Typography";
import Tooltip from '@mui/material/Tooltip';
import { BsPlusCircle } from "react-icons/bs";
import { BsFillTrashFill } from "@react-icons/all-files/bs/BsFillTrashFill";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { CreatePreFactorController } from "../../../controllers";
import { InputTextLabel } from "../../../components";
import { MaterialReactTable } from 'material-react-table';
import Pagination from '@mui/material/Pagination';
import { crudColumns } from "../../../../core/column";
class CreatePreFactor extends CreatePreFactorController {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { rows,page, loading,productModal,search, rowCount,total,rowCountList,userLoading, userSelected, profileListData, driversId, loadingUpdateProfile, errorsList, phone, mobile, firstName, lastName, whatsapp, openNewUserModal, productListData, isCreating } = this.state;
    const { siteList , loadingSiteList} = this.props;
    const theme = createTheme({
      typography: {
        fontFamily:
          'Vazir',
      }
    });
      const filterOption = (option: any, inputValue: any): boolean =>
      {
        return (option.label?.match(inputValue) || option?.data?.mobile?.match(inputValue) || []).length > 0;
      }
   
    const ShowIdDropDown = (props: any) =>{
      return (
        
          <components.Option {...props} >
         <span>{props?.data?.id}</span> 
         <components.Option {...props} />
         <span>{props?.data?.mobile}</span>
         </components.Option>
        
      );
    }
    return (
      <HelmetProvider>
      <ThemeProvider theme={theme}>
        <div className="issuing-pre-factor">
          <Helmet>
                <title>صدور پیش فاکتور</title>
            </Helmet>
          <Header title="صدور پیش‌فاکتور"/>
          <div className="custom-card">
            <div className="mb-3">
              <div className="filter-select-container mb-2 pe-3">
                <div className="filter-options-container bottom-14">
                  <Modal
                      open={openNewUserModal}
                      onClose={this.handleCloseNewUserModal}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                     <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        width: {
                          xxs: "90%",
                          xs: "90%",
                          sm: "90%",
                          md: "80%",
                          lg: "70%",
                          xl: "70%"
                        },
                        height: 'max-content',
                        display: 'block'
                      }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                        ایجاد کاربر جدید                           
                        </Typography>
                        <Grid container sx={{display:"flex", justifyContent:"center"}}>
                    </Grid>
                        <Grid container sx={{display:"flex", justifyContent:"space-between", padding:"10px"}} >
                        <Grid item sm={12} lg={3} sx={{paddingBottom:"25px"}}>
                  <Select
                    styles={{
                      control: (baseStyles, state):CSSObjectWithLabel => ({
                        ...baseStyles,
                        boxShadow: "rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",

                        borderWidth: "1px",
                        backgroundColor: "#fff",
                        display: "flex",
                        flexDirection: "row-reverse",
                        textAlign: "right",
                        borderRadius: "4px",
                        minHeight: "42px",
                        
                        outline: "unset",
                      }) as CSSObjectWithLabel,
                    }}
                    onChange={(choice) => this.driverSelected(choice)}
                    components={{ Option }}
                    value={driversId}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    noOptionsMessage={() => 'این مورد وجود ندارد!'}
                    // menuPortalTarget={document.body}
                    options={siteList}
                    getOptionLabel={(option: any) => option?.value?.persian_name}
                    getOptionValue={(option: any) => option?.value?.name}
                    placeholder="سایت"
                    isClearable={false}
                    name="driversId"
                    loadingMessage={() => 'در حال بارگذاری  ...'}
                    isLoading={loadingSiteList}
                  />
                  <span className="input-icon-error">
                    {errorsList && errorsList?.filter((obj: any) => obj.path === "driversId")[0]?.message}
                  </span>
                </Grid>
                      <Grid item sm={12} lg={3}>
                      <InputIcon
                                value={mobile}
                                title={"موبایل "}
                                inBox={"موبایل"}
                                type={"number"}
                                name={"mobile"}
                                errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "mobile")[0]?.message}
                                onChange={(event) =>
                                  this.setState({
                                    mobile: event.currentTarget.value,
                                  })
                                }
                              />

                      </Grid>
                      <Grid item sm={12} lg={3}>
                      <InputIcon
                              value={firstName}
                              title={"نام "}
                              inBox={"نام  "}
                              type={"text"}
                              name="firstName"
                              errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "firstName")[0]?.message}
                              onChange={(event) =>
                                this.setState({
                                  firstName: event.currentTarget.value,
                                })
                              }
                            />

                      </Grid>
                   
                        </Grid>
                        <Grid container sx={{display:"flex", justifyContent:"space-between", padding:"10px"}} >
                        <Grid item sm={12} lg={3}>
                      <InputIcon
                              value={lastName}
                              title={"نام خانوادگی  "}
                              inBox={"نام خانوادگی  "}
                              errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "lastName")[0]?.message}
                              type={"text"}
                              name="lastName"
                              onChange={(event) =>
                                this.setState({
                                  lastName: event.currentTarget.value,
                                })
                              }
                            />
                      </Grid>
                        <Grid item sm={12} lg={3}>
                        <InputIcon
                          value={phone}
                          title={"تلفن "}
                          inBox={"تلفن "}
                          type={"number"}
                          name="phone"
                          errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "phone")[0]?.message}
                          onChange={(event) =>
                            this.setState({ phone: event.currentTarget.value })
                          }
                        />

                      </Grid>
                      <Grid item sm={12} lg={3}>
                        <InputIcon
                            value={whatsapp}
                            title={"واتس اپ "}
                            errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "whatsapp")[0]?.message}
                            inBox={<BsWhatsapp />}
                            type={"number"}
                            name="whatsapp"
                            onChange={(event) =>
                              this.setState({
                                whatsapp: event.currentTarget.value,
                              })
                            }
                          />

                      </Grid>
                        </Grid>
                        <div className="btn-container">
                        <CustomButton
                          myStyle="submit-btn"
                          loading={loadingUpdateProfile}
                          onClick={()=>this.createNewUser()}
                          title="ایجاد کاربر جدید "
                    />
                        </div>
                       
                      </Box>
                  </Modal>
                  <Modal
                      open={productModal}
                      onClose={this.handleCloseProductModal}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                     <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        width: {
                          xxs: "90%",
                          xs: "90%",
                          sm: "90%",
                          md: "80%",
                          lg: "70%",
                          xl: "70%"
                        },
                        height: 'max-content',
                        display: 'block'
                      }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            محصولات                           
                        </Typography>
                        <div className="submit-order-search-input">
                  <InputTextLabel
                    type={"text"}
                    value={search}
                    onChange={(event) => this.handleSearch(event)}
                    placeholder="جستجو..."
                  />
                </div>

                <MaterialReactTable
                muiTablePaperProps={{
                  sx: { mr: '1.5rem',ml: '1.5rem', border:"1px solid #e5e5e5", mt:"1.3rem" },
                }}
                muiTableBodyRowProps={({ row, staticRowIndex}) => ({
                  sx: {
                    height: "fit-content",
                    backgroundColor: rows.find(
                      (c: { product_id: any }) => c.product_id === row.original?.item?.id
                    )
                      ? "#d1ecfb"
                      : (staticRowIndex % 2 === 0 ) ? '#f3f3f3'  : "",
                  },
                })}
                  columns={crudColumns}
                  data={productListData && productListData?.length > 0 && productListData?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null).map((item: any, index: number) => {
                    return ({
                      item: item,
                      row: index + 1,
                      select: (
                        <div>
                          <RadioSquare
                            title={""}
                            onClick={() => this.handleAdd(item)}
                            isActive={
                              rows.find(
                                (c: { product_id: any }) => c.product_id === item?.id
                              )
                                ? true
                                : false
                            }
                          />
                          <div>{item.id}</div>
                        </div>
                      ),
                      driver:<img className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo} alt=""/> ,
                      name: item.product_name,
                      price: numberWithCommas(item?.price_by_discount?.price),
                      percent: item?.price_by_discount?.type === "percent" ? `% ${item?.price_by_discount?.discount}` : numberWithCommas(item?.price_by_discount?.discount),
                      finalPrice: numberWithCommas(item?.price_by_discount?.finalPrice),
                    }
                    );
                  }
                  )}
                  muiTableProps={{
                    sx: {
                      border: "1px solid #e5e5e5",
                    },
                  }}
                  muiTableHeadCellProps={{
                    sx: {
                      border: "1px solid #e5e5e5",
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      border: "1px solid #e5e5e5",
                      textAlign:"center"

                    },
                  }}
                  state={{isLoading:loading, showProgressBars:loading, sorting: [
                    {id: 'row',
                    desc: false,}
                  ],}}
                enablePagination={false}
                renderBottomToolbarCustomActions={({table})=>{
                  return (
                    <div className="custom-pagination w-100">
                      <Pagination
                       page={page}
                       
                          count={Math.ceil(total / rowCount)}
                          onChange={this.handleChangePage}
                        />
                        <DropDownIcon
                          value={rowCount}
                          optionList={rowCountList}
                          title={""}
                          onChange={(event) => {
                            this.handlePaginationSelect(event);
                          }}
                        />
                    </div>
                  );
                }}
                positionExpandColumn={'last'}
                enableGlobalFilter={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableHiding={false}
                enableColumnActions={false}
                  initialState={{
                    sorting: [
                      {
                        id: "price",
                        desc: true
                      },
                     
                    ],
                  }}
                  localization={{
                    noRecordsToDisplay:"داده‌ای برای نمایش وجود ندارد!"
                  }}
                  positionActionsColumn={'first'}
                />

                      </Box>
                  </Modal>
                  <div className="column-30 mt-1">
                    <Select
                      styles={{
                        control: (baseStyles, state):CSSObjectWithLabel => ({
                          ...baseStyles,
                          boxShadow:"rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",
                          borderWidth: "1px",
                          backgroundColor: "#fff",
                          display: "flex",
                          flexDirection: "row-reverse",
                          textAlign: "right",
                          borderRadius: "4px",
                          height:"35px",
                          minHeight:"20px",
                          outline: "unset",
                        }) as CSSObjectWithLabel,
                      }}
                      onChange={(choice) => this.driverSelected(choice)}
                      components={{ Option }}
                      value={driversId}
                      noOptionsMessage={() => 'این مورد وجود ندارد!'}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      menuPortalTarget={document.body}
                      options={siteList}
                      getOptionLabel={(option: any) => option?.value?.persian_name}
                      getOptionValue={(option: any) => option?.value?.name}
                      placeholder="سایت"
                      loadingMessage={() => 'در حال بارگذاری  ...'}
                      isLoading={loadingSiteList}
                    />
                  </div>
                  <div className="column-30 two-section  mt-1">
                  <div className="add-user-btn">
                   <span className="lable"></span>
                <Tooltip title="اضافه کردن کاربر"><span>  <BsPlusCircle className="icon mt-0" onClick={ ()=> this.setState({ openNewUserModal: true })} /></span></Tooltip>
                    </div>
                    <Select
                      className="profile-select"
                      styles={{
                        control: (baseStyles, state): any => ({
                          ...baseStyles,
                          boxShadow: state.isFocused
                            ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                            : "",
                          borderWidth: "1px",
                          backgroundColor: `${driversId && Object.keys(driversId).length > 0  ? "#fff" : "#f7f8fa"}`,
                          display: "flex",
                          flexDirection: "row-reverse",
                          textAlign: "right",
                          borderRadius: "4px",
                          height:"35px",
                          minHeight:"20px",
                          outline: "unset",
                        }),
                        option: (styles, {isSelected}):any => ({
                          ...styles,
                          background: 
                              isSelected
                                  ? 'light-blue'
                                  : undefined,
                          display:"flex",
                          padding: "2px",
                          justifyContent: "space-around",
                          alignItems:"center"
                      }),
                      placeholder:(styles):any => ({
                        ...styles,
                       fontSize:"14px"
                      })
                      }}
                      isDisabled={driversId && Object.keys(driversId).length > 0  ? false : true}
                      onInputChange={(choice) => this.handleSearchUser(choice)}
                      onChange={(choice) => this.userSelected(choice)}
                      components={{ Option: ShowIdDropDown }}
                      value={userSelected}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      isSearchable={true}
                      options={profileListData}
                      menuPortalTarget={document.body}
                      noOptionsMessage={() => 'این مورد وجود ندارد!'}
                      getOptionLabel={(option: any) => option.profile?.full_name}
                      getOptionValue={(option: any) => option?.id}
                      placeholder="شماره یا نام خانوادگی کاربر"
                      isClearable
                      filterOption={filterOption}
                      isLoading={userLoading}
                      loadingMessage={() => 'در حال بارگذاری  ...'}
                    />
                 <span className="input-icon-error">
                    {errorsList && errorsList?.filter((obj: any) => obj.path === "userSelected")[0]?.message}
                  </span>
                    </div>
                  <div className="column-30  mt-1">
                    <InputIcon
                      value={userSelected?.mobile || ""}
                      title="شماره  همراه کاربر"
                      type={"text"}
                      disabled={true}
                    />
                  </div>
                  <div className="i-btn-container">
                  <CustomButton
                    myStyle='issuing-submit-btn'
                    loading={isCreating}
                    onClick={()=>this.createPrefactor()}
                    title="صدور پیش فاکتور"
                  />
                  </div>
                </div>
              </div>
              {driversId === null ? <div></div>
            :
            <>
              <div className="main-card">
              <div className="table-responsive w-100">
                <table  className="table">
                  <thead>
                    <tr>
                      <th>شناسه</th>
                      <th>محصول</th>
                      <th>تعداد</th>
                      <th>قیمت</th>
                      <th>تخفیف</th>
                      <th>مبلغ کل</th>
                      <th>عملیات</th>
                    </tr>
                  </thead>
                  <tbody>
                  {rows.map((rowsData: any, index: number) => {
                      return (
                        <>
                        <tr key={index}>
                          <td>
                            <span className="squere">{rowsData.product_id}</span>
                          </td>
                          <td>
                           <span className="squere">{rowsData.product_name}</span>
                          </td>
                          <td>
                            <input
                              type="number"
                              value={rowsData.count}
                              onChange={(event) => this.onValUpdate(index, event)}
                              name="count"
                              min="1"
                              className="form-control dir-ltr"
                            />
                          </td>
                          <td>
                          {/* <span className="squere">{numberWithCommas(rowsData?.price)}</span> */}
                            <input
                              type="text"
                              value={numberWithCommas(rowsData?.price)}
                              onChange={(event) => this.onValUpdate(index, event)}
                              name="price"
                              min="0"
                              className="form-control dir-ltr"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={numberWithCommas(rowsData?.discount)}
                              onChange={(event) => this.onValUpdate(index, event)}
                              name="discount"
                              min="0"
                              className="form-control dir-ltr"
                            />
                            {/* <span className="squere">{numberWithCommas(rowsData?.discount)}</span> */}
                          </td>
                          <td>
                            <input
                              type="text"
                              value={numberWithCommas(rowsData?.finalPrice)}
                              disabled={true}
                              name="finalPrice"
                              className="form-control"
                            />
                            {/* <span className="squere" >
                            {numberWithCommas(rowsData?.finalPrice)}
                            </span> */}
                          </td>
                          
                          <td>
                          <Tooltip title="حذف سفارش"><span>  <BsFillTrashFill className="delete-icon" onClick={() => this.tableRowRemove(index)}/></span></Tooltip>
                          </td>
                        </tr>
                        <tr><td colSpan={7} className="error-text" >{rowsData.error}</td></tr>
                    
                        </>
                      );
                    })
                    }

                      <tr className="w-100">
                          <td colSpan={7}>
                          <BsPlusCircle className="icon" onClick={() => this.openProductModal()}/>
                          </td>
                        </tr>
                      <tr>
                      <th colSpan={4}>مبلغ کل</th>
                      <td colSpan={4}>  {rows && numberWithCommas(totalOrdersPrice(rows))}</td>
                    </tr>
                    <tr>
                      <th colSpan={4}>
                        جمع کل
                      </th>
                      <td colSpan={4}>
                      {rows && numberWithCommas(totoalOrderFinalPrice(rows))}
                      </td>
                    </tr>
                    <tr>
                      <th colSpan={4}>تخفیف</th>
                      <td colSpan={4}>{numberWithCommas(totalOrderDiscount(rows))}</td>
                    </tr>
                    <tr className="table-success">
                      <th colSpan={4}>مبلغ نهایی</th>
                      <td colSpan={4}>  {rows && numberWithCommas(totoalOrderFinalPrice(rows))}</td>
                    </tr>
                  </tbody>
                </table>
                </div>
              </div>
              </>
            }
            </div>
          </div>
        </div>
      </ThemeProvider>
      </HelmetProvider>
    );
  }
}
const mapStateToProps = (dispatch:any) => {
  return {
    dispatch,
    siteList:dispatch.general.siteList,
    loadingSiteList:dispatch.general.loadingSiteList,
    userData:dispatch.general.userData,
  }
};
export default connect(mapStateToProps)(CreatePreFactor);