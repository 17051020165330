import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
export function toFarsiNumber(n: any) {
  if (n) {
    const farsiDigits = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
    return n.toString().replace(/\d/g, (x: any) => farsiDigits[x]);
  } else return "";
}
export enum alertButtonsState {
  DELETE = "delete",
  NOTCONFIRMED = "notconfirmed",
  CONFIRMED = "confirmed",
  SELLING = "selling",
  NOSELLING = "noselling", 
  NOINDEX = "noIndex", 
  RECYCLE = "recycle",
  UNLINK="unLink"
}
export enum sendingStatus {
  SENDFORCLIENT = "sendforClient",
  RECIEVEBYCLIENT = "recievebyclient"
}



export const totalOrdersPriceWithDiscount = (arrayList: any) => {
  var total = 0;

  if (arrayList?.length > 0) {
    
    for (var number of arrayList) {
      const countItem = Number(Number(number?.count) > 0 ? Number(Number(number?.count)) : 1);
      if (number?.discount > 99) {
        total += Number(number?.price) * countItem;

      } else {
        total += Number(number?.price) * countItem;
      }
    }
    return total;

  } else {
    return 0;
  }
}
export const totalOrdersPrice = (arrayList: any):any => {
  var total = 0;
  if (arrayList?.length > 0) {
    for (var number of arrayList) {
      const countItem = Number(Number(number?.count) > 0 ? Number(Number(number?.count)) : 1);
      total += Number(number?.price) * countItem;
    }
    return total;

  } else {
    return 0;
  }
}
export const totoalOrderFinalPrice = (arrayList: any) => {
  var total = 0;
  if (arrayList?.length > 0) {
    for (var number of arrayList) {
      total += Number(number?.finalPrice) ;
    }
    return total;

  } else {
    return 0;
  }
}
export const totalOrdersPriceWithOutDiscount = (arrayList: any) => {
  var total = 0;
  if (arrayList?.length > 0) {
    for (var number of arrayList) {
      total += Number(number?.price);
    }
    return total;

  } else {
    return 0;
  }
}
export const totalOrderDiscount = (arrayList: any) => {
  var total = 0;
  if (arrayList?.length > 0) {
    for (var number of arrayList) {
      const countItem = Number(Number(number?.count) > 0 ? Number(Number(number?.count)) : 1);

      if (Number(number?.discount) > 99) {
        total += countItem * Number(number?.discount);

      } else {
        total += Number(Number(number?.price * countItem) * number?.discount) / 100;
      }
    }
    return getNum(total);

  } else {
    return 0;
  }
}
export const totalOrderCount = (arrayList: any) => {
  var total = 0;
  if (arrayList?.length > 0) {
    for (var number of arrayList) {

      total += Number(number?.count);

    }
    return total;

  } else {
    return 0;
  }
}

export const orderStatus:any = 
   [
    { value: 0, name: 'در انتظار پرداخت' },
    { value: 1, name: 'درحال پردازش' },
    { value: 2, name: 'درحال آماده سازی'},
    { value: 3, name:  'درحال ارسال' },
    { value: 4, name: 'تحویل شده' },
    { value: 5, name: 'بازگشت خورده' },
    { value: 6, name: 'لغو شده' },
  ]

export const paymentStatus:any=
[
  {value: 0 , name: 'پرداخت نشده', color: "#ffffff"},
  {value: 1, name: 'پرداخت شده', color: "#c6ffc2"},
  // {value: 2 ,name: 'در انتظار تایید پرداخت', color: "#fff9b0"},
  // {value: 3, name: 'برگشت زده شده', color: "#ffc2c2"}
]
export function numberWithCommas(x:any) {
  x = x?.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
  return x;
}
function getNum(val:any) {
  if (isNaN(val)) {
    return 0;
  }
  return val;
}
export const latestPrefactorTotalPrice = (arrayList: any) => {
  var total = 0;
  if (arrayList?.length > 0) {
    for (var number of arrayList) {
        total += Number(Number(number?.product?.price_by_discount?.finalPrice) * Number(Number(number?.count)));
    }
    return getNum(total);

  } else {
    return 0;
  }
}
export const latestPrefactorTotalDiscount = (arrayList: any) => {
  var total = 0;
  if (arrayList?.length > 0) {
    for (var number of arrayList) {

      if (Number(number?.product?.price_by_discount?.discount) > 99) {
        total +=  Number(Number(number?.count) * Number(number?.product?.price_by_discount?.discount));

      } else {
        total +=  Number(Number(number?.count) * Number(number?.product?.price_by_discount?.price * number?.product?.price_by_discount?.discount) / 100);
      }
    }
    return getNum(total);

  } else {
    return 0;
  }
}

var
persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
arabicNumbers  = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];
export function fixNumbers (str:any)
{
  if(typeof str === 'string')
  {
    for(var i=0; i<10; i++)
    {
      str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
    }
  }
  return str;
};
export const convertHtmlToText = (text:string) =>{
  var regex = /(<([^>]+)>)/ig
  ,   body = text
  ,   result = body.replace(regex, "");
  
  return result
 }
 export const exportToExcel=async (excelData:any , fileName:any)=>{
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension=".xlsx";
  const ws = XLSX.utils.json_to_sheet(excelData);
  const wb = {Sheets: {'data':ws}, SheetNames:['data']};
  const excelBuffer = XLSX.write(wb,{bookType:'xlsx', type:"array"});
  const data = new Blob([excelBuffer],{type:fileType});
  FileSaver.saveAs(data, fileName + fileExtension);


}