import Select, { CSSObjectWithLabel } from "react-select";
import { DragDrop, Header, SeoCard, Tab, EditableCard, CustomButton, BadgeRow } from "../../../components";
import { TextAreaLabel, InputIcon, NoteCard} from "../../../components";
import { createTheme, Rating, ThemeProvider } from "@mui/material";
import { Helmet } from "react-helmet";
import {AddProductController} from "../../../controllers";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { Editor } from '@tinymce/tinymce-react';
import { connect } from "react-redux";
import { BsPlusCircle } from "react-icons/bs";
import {Logo} from "../../../../assets";
import CreatableSelect from 'react-select/creatable';
import moment from "moment-jalaali";
import { RxCross2 } from "react-icons/rx";

class ProductAdd extends AddProductController {

  componentDidMount() {
   window.scrollTo(0, 0);
   this.getBrandSearch();
   this.getCategorySearch();
   this.getLinkedProductsSearch();
   this.getTagSearch();
   this.getNotesSearch();
   }
  render() {    
    const { siteList, userData, loadingSiteList } = this.props;
    const {
      toggleTab, step , allBrandList, notListLoading, allCategoryList, allNotesList, allTagList, allLinkedProducts, allVariableProducts, toggleMediaTab, compressedData, atoumaticSave ,
       
      } = this.state;
    const theme = createTheme({
      typography: {
        fontFamily: "Vazir",
      },
    });
    const formatOptionLabel = (option: { __isNew__: any; message: any; }, { context }: any) => {
      return context === "menu" && option.__isNew__ ? (
        <>
          {option.message}
          <br />
          <small>اضافه‌نمودن</small>
        </>
      ) : (
        option.message
      );
    };    
    return (
      <ThemeProvider theme={theme}>
        <div className="add-product edit-product">
          <Helmet>
            <title>افزودن محصول</title>
          </Helmet>
          <Header isActive="isAdd" title="افزودن محصول"/>
          <div className="custom-card">
            <div className="sites-tab-conteainer">

            {siteList && siteList?.length > 0 && siteList?.map((item: any, index: number) => {
      return (
       < div key={index}>
       
       <Tab
        title={item?.value?.name}
        isActive={step === item?.value?.name ? true : false}
        onClick={() => this.addNewItem(item?.value.name, index)}
        />

</div>
      );
    }
    )}
          </div>
          <div className="static-content">
          <div className="side-btn-container">
          <CustomButton
                          myStyle="btn bg-light-green "
                          onClick={()=>this.handleAddProduct()}
                          title="افزودن محصول"
                    />
            </div>
            <label>ذخیره اتوماتیک</label>
            <div className="scrollable-container">
              { atoumaticSave && atoumaticSave.length > 0 && atoumaticSave.map((item:any, index:number) =>{
              return(

                <BadgeRow key={index} content={item?.date} badgeTtile={index + 1}/>
              )

              })}

            </div>
            <label>یادداشت‌های محصول</label>
            <CreatableSelect
              isClearable
              onChange={(choice, action) => this.handleChangeCreatableSelect(choice, action)}
              onInputChange={(input, action) => this.handleInputChangeCreatableSelect(input, action)}
              options={allNotesList}
              onKeyDown={this.handleKeyPressNoteCardSelect}
              value={compressedData.filter((item:any) => item?.dbName === step)[0]?.noteCardSelectId }
              getOptionValue={(option: any) => option.id}
              getOptionLabel={(option: any) =>
                option.message
              }
              loadingMessage={() => 'در حال بارگذاری  ...'}
              isLoading={notListLoading}
              formatOptionLabel={formatOptionLabel}
              onCreateOption={(input) => this.handleCreateNote(input)}
              placeholder={"یادداشت را وارد کنید."}
        />
            
            <div className="scrollable-container">
                { compressedData.filter((item:any) => item.dbName === step)[0]?.noteCardsList?.length > 0 && compressedData.filter((item:any) => item.dbName === step)[0]?.noteCardsList?.map((item:any, index:number) =>{
                  return(
                    <NoteCard date={item?.updated_at ? moment(item?.updated_at).format("HH:mm:ss jYYYY/jM/jD") : moment(item?.created_at).format("HH:mm:ss jYYYY/jM/jD")} user={item?.profile ? item?.profile : userData?.profile?.full_name} index={index}
                     value={item?.message} handleChange={(event: any)=>this.handleChangeNoteCard(item?.id , event, index)}
                     handleDelete={()=>this.handleDeleteNoteCard(index, item?.id)} />
                  )
                })}
            </div>
          </div>
          
          <div className="dynamic-content">
          <div className="column-30 mb-1 d-flex align-items-center">
                      <ToggleButtonGroup
                        value={toggleTab}
                        exclusive
                        onChange={this.handleToggleTabs}
                        aria-label="text alignment"
                        sx={{marginRight:"5px", borderLeft: "1px solid #ebebeb"}}
                        >
                        <ToggleButton sx={{borderRadius:"0px"}} value={0} aria-label="left aligned">
                        توضیحات
                        </ToggleButton>
                        <ToggleButton value={1} aria-label="centered">
                            رسانه
                        </ToggleButton>
                        <ToggleButton value={2} aria-label="right aligned">
                        ویژگی
                        </ToggleButton>
                        <ToggleButton value={3} aria-label="right aligned">
                        سئو
                        </ToggleButton>
                        <ToggleButton value={4} aria-label="right aligned">
                        توضیحات کوتاه
                        </ToggleButton>
                        <ToggleButton value={5} aria-label="right aligned">
                        توضیحات کلی
                        </ToggleButton>
      
                      </ToggleButtonGroup>
                  
          </div>
                  {compressedData.filter((item:any) => item.dbName === step)[0]?.errorsList?.map((obj: any, index: number) => {
                    
                return (
                  <span className="input-icon-error" key={index}> {obj.message} </span>

                
              )})}
          
          { toggleTab === 0 ?
                <div className="filter-select-container">
                  <div className="filter-options-container">
                <div className="column-50">
              
                <TextAreaLabel
                    value={compressedData.filter((item:any) => item.dbName === step)[0]?.persianName}
                    onChange={(event:any) =>
                      this.handlePersianName(event)
                  }                    
                  name={"persianName"}
                  placeholder="نام فارسی "
                  />
                </div>
                <div className="column-50">
                         <TextAreaLabel
                          value={compressedData.filter((item:any) => item.dbName === step)[0]?.englishName}
                          onChange={(event:any) =>
                              this.handleEnglishName(event)
                          }                    
                          placeholder="نام انگلیسی"
                  />
                </div>
                 <div className="column-70">      
               
                <InputIcon
                          type={"text"}
                          value={compressedData.filter((item:any) => item.dbName === step)[0]?.persianName}
                          title={"نامک"}
                          inBox={"نامک"}
                          onChange={(event) =>
                            this.handlePersianName(event)
                          
                          }
                        />
                </div>
                
                <div className="column-30">
                <InputIcon
                          type={"text"}
                          value={ compressedData.filter((item:any) => item.dbName === step)[0]?.weight }
                          title={"وزن به گرم"}
                          inBox={"وزن به گرم"}
                          onChange={(event) =>
                            this.handleWeight(event)
                          }
                        />
                </div>
                
                <div className="w-100">
                  <label>دسته‌بندی‌ها</label>
                <Select
                      styles={{
                        control: (baseStyles, state): CSSObjectWithLabel => ({
                          ...baseStyles,
                          boxShadow:"rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",
                          borderWidth: "1px",
                          backgroundColor: "#fff",
                          display: "flex",
                          flexDirection: "row-reverse",
                          textAlign: "right",
                          borderRadius: "4px",
                          height:"42px",
                          
                          outline: "unset",
                          marginBottom:"20px",
                        }) as CSSObjectWithLabel,
                      }}
                      onChange={(choice) => this.categorySelected(choice)}
                      value={compressedData.filter((item:any) => item.dbName === step)[0]?.category}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      noOptionsMessage={() => 'این مورد وجود ندارد!'}
                      menuPortalTarget={document.body}
                      options={allCategoryList?.length > 0 && allCategoryList.filter((item:any) => item?.db_name === step)}
                      getOptionLabel={(option: any) =>
                        option.persian_name
                      }
                      onInputChange={(choice: any) => this.handleCategorySearch(choice)}
                      getOptionValue={(option: any) => option.id}
                      placeholder="دسته‌بندی"
                      isClearable={false}
                      loadingMessage={() => 'در حال بارگذاری  ...'}
                      isLoading={loadingSiteList}
                    />
                </div>
                
                <div className="column-50">
                  <label>برندها</label>
                <Select
                                styles={{
                                  control: (baseStyles: any, state: { isFocused: any; }): CSSObjectWithLabel => ({
                                    ...baseStyles,
                                    boxShadow: state.isFocused
                                      ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                      : "",
                                    borderWidth: "1px",
                                    backgroundColor: "#fff",
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    textAlign: "right",
                                    borderRadius: "4px",
                                    minHeight: "20px",
                                    
                                    outline: "unset",
                                    marginBottom: "10px",
                                  }) as CSSObjectWithLabel,
                                }}
                                onChange={(choice: any) =>
                                  this.brandSelected(choice)
                                }
                                onInputChange={(choice: any) => this.handleBrandSearch(choice)}
                                value={ compressedData.filter((item:any) => item.dbName === step)[0]?.brandId}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                options={allBrandList?.length > 0 && allBrandList.filter((item:any) => item?.db_name === step)}
                                noOptionsMessage={() => "این مورد وجود ندارد!"}
                                getOptionLabel={(option: any) =>
                                  option?.persian_name
                                }
                                getOptionValue={(option: any) => option?.id}
                                placeholder="همه برندها "
                                isClearable
                                loadingMessage={() => 'در حال بارگذاری  ...'}
                                />
                </div>
                
                <div className="column-50">
                  <label>برچسب‌ها</label>
                <Select
                            styles={{
                              control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                                ...baseStyles,
                                boxShadow: state.isFocused
                                  ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                  : "",
                                borderWidth: "1px",
                                backgroundColor:"#fff",
                                display: "flex",
                                flexDirection: "row-reverse",
                                textAlign: "right",
                                borderRadius: "4px",
                                minHeight: "20px",
                                
                                outline: "unset",
                                marginBottom: "10px",
                              }) as CSSObjectWithLabel,
                            }}
                            onChange={(choice: any) =>
                              this.tagSelected(choice)
                            }
                            onInputChange={(choice: any) => this.handleTagSearch(choice)}
                            value={compressedData.filter((item:any) => item.dbName === step)[0]?.tag}
                            isMulti={true}
                            noOptionsMessage={() => "این مورد وجود ندارد!"}
                            closeMenuOnSelect={true}
                            options={allTagList?.length > 0 && allTagList.filter((item:any) => item?.db_name === step)}
                            getOptionLabel={(option: any) =>
                              option.persian_name
                            }
                            isLoading={compressedData.filter((item:any) => item.dbName === step)[0]?.tagLoading}
                            getOptionValue={(option: any) => option.id}
                            placeholder="برچسب"
                            isClearable
                            loadingMessage={() => 'در حال بارگذاری  ...'}

                            />
                </div>
               
                <div className="column-50">
                <Select
                            styles={{
                              control: (baseStyles: any, state: { isFocused: any; }): CSSObjectWithLabel => ({
                                ...baseStyles,
                                boxShadow: state.isFocused
                                  ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                  : "",
                                borderWidth: "1px",
                                backgroundColor:"#fff",
                                display: "flex",
                                flexDirection: "row-reverse",
                                textAlign: "right",
                                borderRadius: "4px",
                                minHeight: "20px",
                                
                                outline: "unset",
                                marginBottom: "10px",
                              }) as CSSObjectWithLabel,
                            }}
                            onChange={(choice: any) =>
                              this.linkedProductSelected(choice)
                            }
                            onInputChange={(choice: any) => this.handleLinkedProductsSearch(choice)}
                            value={ compressedData.filter((item:any) => item.dbName === step)[0]?.linkedProductId}
                            isMulti={true}
                            noOptionsMessage={() => "این مورد وجود ندارد!"}
                            closeMenuOnSelect={true}
                            options={allLinkedProducts?.length > 0 && allLinkedProducts?.filter((item:any) => item?.db_name === step)}
                            getOptionLabel={(option: any) =>
                              option?.product_name
                            }
                            loadingMessage={() => 'در حال بارگذاری  ...'}

                            getOptionValue={(option: any) => option?.id}
                            placeholder="محصول مرتبط را نتخاب کنید"
                            isClearable
                            />
                </div>
                
                <div className="column-50">
                          <Select
                            styles={{
                              control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                                ...baseStyles,
                                boxShadow: state.isFocused
                                  ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                  : "",
                                borderWidth: "1px",
                                backgroundColor:"#fff",
                                display: "flex",
                                flexDirection: "row-reverse",
                                textAlign: "right",
                                borderRadius: "4px",
                                minHeight: "20px",
                                
                                outline: "unset",
                                marginBottom: "10px",
                              }) as CSSObjectWithLabel,
                            }}
                            onChange={(choice: any) =>
                              this.variableProductsSelect(choice)
                            }
                            loadingMessage={() => 'در حال بارگذاری  ...'}

                            value={compressedData.filter((item:any) => item.dbName === step)[0]?.variableProductId}
                            isMulti={true}
                            noOptionsMessage={() => "این مورد وجود ندارد!"}
                            closeMenuOnSelect={true}
                            options={allVariableProducts?.length > 0 && allVariableProducts?.filter((item:any) => item?.db_name === step)}
                            getOptionLabel={(option: any) =>
                              option?.product_name
                            }
                            getOptionValue={(option: any) => option?.id}
                            placeholder="محصول متغیر را نتخاب کنید"
                            isClearable
                            />
                </div>
                
                <div className="column-50">
                <TextAreaLabel
                          onKeyDown={this.handleKeyPress}
                          value={compressedData.filter((item:any) => item.dbName === step)[0]?.pros}
                          onChange={(event: { target: { value: any; }; }) => this.handlePros(event) }
                          
                          placeholder="نقاط قوت محصول"
                        />
                <BsPlusCircle className="icon-blue" onClick={this.handleSubmitPros}/>
                <ul>
                {compressedData.filter((item:any) => item.dbName === step)[0]?.prosList.map((item:any, index:number) =>{
                  return(
                    <EditableCard  index={index} value={item} handleChange={(event: any)=>this.handleChangePros(event, index)}
                    handleCancel={()=>this.handleDeletePros(index)} />
                  )
                })}
                </ul>
               
                </div>
                
                <div className="column-50">
                <TextAreaLabel
                          onKeyDown={this.handleKeyPressCons}
                          value={compressedData.filter((item:any) => item.dbName === step)[0]?.cons}
                          onChange={(event: { target: { value: any; }; }) => this.handleCons(event)}
                          placeholder="نقاط ضعف محصول"
                        />
                <BsPlusCircle 
                className="icon-blue" onClick={this.handleSubmitCons}/>
                
                <ul>
                {compressedData.filter((item:any) => item.dbName === step)[0]?.consList?.length > 0 && compressedData.filter((item:any) => item.dbName === step)[0]?.consList.map((item:any, index:number) =>{
                  return(
                    <EditableCard  index={index} value={item} 
                    handleChange={(event: any)=>this.handleChangeCons(event, index)}
                    handleCancel={()=>this.handleDeleteCons(index)} />
                  )
                })}
                </ul>
                </div>
                  </div>
                
                </div> : toggleTab === 1 ? 
                <>
                <div className="media">
                    <ToggleButtonGroup
                        value={toggleMediaTab}
                        exclusive
                        onChange={this.handleToggleMediaTabs}
                        aria-label="text alignment"
                        sx={{borderLeft: "1px solid #ebebeb"}}
                        >
                        <ToggleButton sx={{borderRadius:"0px"}} value={0} aria-label="left aligned">
                        تصاویر
                        </ToggleButton>
                        <ToggleButton value={1} aria-label="centered">
                            ویدیوها
                        </ToggleButton>
                        <ToggleButton value={2} aria-label="right aligned">
                        فایل‌ها
                        </ToggleButton>     
                      </ToggleButtonGroup>
                      </div> 
                      <div className="drag-drop">
                      <div className='count'>
                             <div className='item'>
                                 <span>تعداد تصاویر</span>
                                 <span>{compressedData.filter((item:any) => item.dbName === step)[0]?.imagesList?.length}</span>
     
                             </div>
                             <div className='item'>
                                 <span>تعداد ویدیوها</span>
                                 <span>{compressedData.filter((item:any) => item.dbName === step)[0]?.videosList?.length}</span>
     
                             </div>
                             <div className='item'>
                                 <span>تعداد فایل‌ها</span>
                                 <span>{compressedData.filter((item:any) => item.dbName === step)[0]?.filesList?.length}</span>    
                             </div>
                         </div>
               
                         <p>فایل‌ها را در کادر رها کنید.</p>                       
                         <div className='upload-area'>
                        
                          
                              {toggleMediaTab === 0 ? 
                              <div className="file-upload-area" onDragStart={(e:any)=>this.handleDragStart(e)} onDragOver={this.handleDragOver}
                              onDrop={(e:any)=>this.handleDrop(e)} >
                                 <input hidden type='file' id="file" accept="image/*" onChange={(e:any)=>this.onSelectImage(e)} />
                         <label  htmlFor="file" className="upload-lable">                           
                           یا انتخاب کنید.
                           </label>                      
                             <div className="files-container" >                                
                                     {compressedData.filter((item:any) => item.dbName === step)[0]?.imagesList?.map((item:any, index: number) => {return(
                                         <div key={index} className="item">
                                             <RxCross2 className='icon-blue'  onClick={() => this.handleDelete(index, "image")} />
                                             <img alt="" src={URL.createObjectURL(item)} />    
                                             <span className='file-name'>
                                                 {item.name}     
                                             </span>
                                         </div>
                                     )})}
                             </div> 
                             </div>
                          : toggleMediaTab === 1 ?  
                          <div className="file-upload-area" onDragStart={(e:any)=>this.handleDragStart(e)} onDragOver={this.handleDragOver}
                          onDrop={(e:any)=>this.handleVideoUpload(e)} > 
                           <input
                            type="file"
                            id="file"
                            hidden
                            accept="video/*"
                            onChange={(event) => this.onSelectVideoUpload(event)}
                        />
                         <label  htmlFor="file" className="upload-lable">
                      
                      یا انتخاب کنید.
                      </label>
                                  <div className="files-container" >                                
                                  {compressedData.filter((item:any) => item.dbName === step)[0]?.videosList?.map((item:any, index: number) => {return(
                                      <div key={index} className="item">
                                          <RxCross2 className='icon-blue'  onClick={() => this.handleDelete(index, "video")} />
                                          <img alt="" src={URL.createObjectURL(item)} />    
                                          <span className='file-name'>
                                              {item.name}     
                                          </span>
                                      </div>
                                  )})}
                                </div> 
                                </div>
                          : toggleMediaTab === 2 ?  
                          <div className="file-upload-area" onDragStart={(e:any)=>this.handleDragStart(e)} onDragOver={this.handleDragOver}
                          onDrop={(e:any)=>this.handleFileUpload(e)} >
                            <input
                            type="file"
                            id="file"
                            hidden
                            onChange={(event) => this.onSelectFileUpload(event)}
                        />
                         <label  htmlFor="file" className="upload-lable">
                      
                      یا انتخاب کنید.
                      </label> 
                                <div className="files-container" >                                
                                {compressedData.filter((item:any) => item.dbName === step)[0]?.filesList?.map((item:any, index: number) => {return(
                                    <div key={index} className="item">
                                        <RxCross2 className='icon-blue'  onClick={() => this.handleDelete(index, "file")} />
                                        <img alt="" src={URL.createObjectURL(item)} />    
                                        <span className='file-name'>
                                            {item.name}     
                                        </span>
                                    </div>
                                )})}
                              </div>
                              </div>
                      : ""}
                      </div>
                      </div>
                    <div className="seo-card-preview">
                    {compressedData.filter((item:any) => item.dbName === step)[0]?.seoCardList?.length > 0 && compressedData.filter((item:any) => item.dbName === step)[0]?.seoCardList.map((item:any, index:number) =>{
                    return(
                    <SeoCard changeAlt={(event: any) => this.changeAlt(event, index)} handleSubmit={this.handleSubmitSeoCard} 
                    img={Logo} alt={item?.alt} greenIconTooltip="تصویر شاخص" redIconTooltip="حذف فایل" handleDelete={()=>this.handleDeleteSeoCard(index)}/>
                        )
                })}
                    </div>
                </>: toggleTab === 2 ? <div></div> : toggleTab === 3 
                ? <div className="seo">
                  <label>عنوان سئو</label>
                   <TextAreaLabel
                            value={compressedData.filter((item:any) => item.dbName === step)[0]?.seoTitle}
                            onChange={(event: { currentTarget: { value: any; }; }) =>
                            this.handleChangeSeoTitle(event)}                    
                          placeholder={"عنوان سئو"}
                          inputContainerStyle={ (Array.isArray(compressedData) && (compressedData.filter((item: any, index: number) => item?.db_name === step)[0]?.seoTitle.length < 60 && compressedData.filter((item: any, index: number) => item?.db_name === step)[0]?.seoTitle.length >= 50)  ? "border-green" :
                          (compressedData.filter((item: any, index: number) => item?.db_name === step)[0]?.seoTitle.length < 80 && compressedData.filter((item: any, index: number) => item?.db_name === step)[0]?.seoTitle.length >= 60) ?  "border-orange" : (compressedData.filter((item: any, index: number) => item?.db_name === step)[0]?.seoTitle.length >= 80) ? "border-red" : "border-green")}
                        
                  />
                  <label>توضیحات سئو</label>
                   <TextAreaLabel
                            value={compressedData.filter((item:any) => item.dbName === step)[0]?.seoDescription}
                                         
                          placeholder={"توضیحات سئو"}
                          onChange={(event: { currentTarget: { value: any; }; }) =>
                            this.handleChangeSeoDes(event)
                        }
                        inputContainerStyle={ (Array.isArray(compressedData) && (compressedData.filter((item: any, index: number) => item?.db_name === step)[0]?.seoDescription.length < 160 && compressedData.filter((item: any, index: number) => item?.db_name === step)[0]?.seoDescription.length >= 120)  ? "border-green" :
                        (compressedData.filter((item: any, index: number) => item?.db_name === step)[0]?.seoDescription.length < 180 && compressedData.filter((item: any, index: number) => item?.db_name === step)[0]?.seoDescription.length >= 160) ?  "border-orange" : (compressedData.filter((item: any, index: number) => item?.db_name === step)[0]?.seoDescription.length >= 180) ? "border-red" : "border-green")}
                        />
                        <label>آدرس URL Canonical</label>
                        <TextAreaLabel
                            value={compressedData.filter((item:any) => item.dbName === step)[0]?.canonicalUrl }
                                         
                          placeholder={"آدرس URL Canonical"}
                          onChange={(event: { currentTarget: { value: any; }; }) =>
                          this.handleChangeURL(event)
                        }
                        
                        />
                  <div className="google-preview">
                  <div className="details">
                      پیش‌نمایش گوگل

                      <span className="name">

                      {compressedData.filter((item:any) => item.dbName === step)[0]?.routeName}/product
                      </span>
                      <span className="seo-title">
                      {compressedData.filter((item:any) => item.dbName === step)[0]?.seoTitle}
                      </span>
                      <span className="seo-desc">
                      {compressedData.filter((item:any) => item.dbName === step)[0]?.seoDescription}
                        </span>
                      
                  </div>
                  <div className="rate">
                    <Rating name="read-only" value={3} readOnly />
                  </div>
                      
                  </div>

                </div>
                 : toggleTab === 4 ?
                // {editorLoading === true ? <LoadingSpinner />
                //  : 
                 <Editor
                 apiKey="bnl71iiv8jm79p88zww9qa0rvj9u4jz5zywkym0jj16uvli4"
                 initialValue={compressedData.filter((item:any) => item.dbName === step)[0]?.editorShortInfoText}
                 init={{
                   toolbar: 'undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                   plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
                   editimage_cors_hosts: ['picsum.photos'],
                   menubar: 'file edit view insert format tools table help',
                   toolbar_sticky: true,
                   autosave_ask_before_unload: true,
                   autosave_interval: '30s',
                   autosave_prefix: '{path}{query}-{id}-',
                   autosave_restore_when_empty: false,
                   autosave_retention: '2m',
                   image_advtab: true,
                   importcss_append: true,
                   directionality : 'rtl',
                   language:"fa",
                   
 
                 }}
                //  onInit={() => {
                //   this.setState({editorLoading:false})
                // }}
                 
                 onChange={this.handleEditorChangeShortInfo}
                 /> 
              // }
             
                 : toggleTab === 5 ?
                 <Editor
                 apiKey="bnl71iiv8jm79p88zww9qa0rvj9u4jz5zywkym0jj16uvli4"
                 initialValue={compressedData.filter((item:any) => item.dbName === step)[0]?.editorGeneralInfoText}
                 
                 init={{
                   toolbar: 'undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                   plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
                   editimage_cors_hosts: ['picsum.photos'],
                   menubar: 'file edit view insert format tools table help',
                   toolbar_sticky: true,
                   autosave_ask_before_unload: true,
                   autosave_interval: '30s',
                   autosave_prefix: '{path}{query}-{id}-',
                   autosave_restore_when_empty: false,
                   autosave_retention: '2m',
                   image_advtab: true,
                   importcss_append: true,
                   directionality : 'rtl',
                   language:"fa"
 
                 }}
                 
                 onChange={this.handleEditorChangeGeneralInfo} 
                 />
                 : ""}
         
          </div>
          </div>

          
          
            
                
          </div>
          </ThemeProvider>
    )
}
}
const mapStateToProps = (dispatch: any) => {
  return {
    dispatch,
    siteList: dispatch.general.siteList,
    loadingSiteList: dispatch.general.loadingSiteList,
    userData: dispatch.general.userData,
  };
};
export default connect(mapStateToProps)(ProductAdd)