import { HTMLAttributes, ReactElement} from "react";
import { Link } from "react-router-dom";

interface Props extends HTMLAttributes<HTMLElement> {
    title: string;
    // icon?:ReactElement;
    myStyle?:string;
    route:any;
  }

const LandingCard = ({title, myStyle, route}: Props):ReactElement => {
    return(
      <>
      {route ?
      
        <Link to={route} target="_blank">
      <div className={`landing-card ${myStyle}`}>
        {/* <div className="icon-white">
          {icon}
        </div> */}
        <div className="landing-card_title">{title}        
        </div>
      </div>
        </Link>
      :
      <div className={`landing-card ${myStyle}`}>
        {/* <div className="icon-white">
          {icon}
        </div> */}
        <div className="landing-card_title">{title}        
        </div>
      </div>
     }
    </>
        )
    }
    
export default LandingCard;