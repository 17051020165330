import { Component } from 'react';
import { call_api, parseJSON } from "../../../core/service";
import { toast } from 'react-toastify';
import { AppConstants } from '../../../core/constants';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
interface IProps {

}

interface IState {
  loading?: boolean,
  page: number,
  logName: any,
  logNameList: any,
  logNameLoding: boolean;
  descName: any,
  descriptionList: any,
  descriptionLoding: boolean;
  eventListData: any
  rowCount:any,
  rowCountList:any,
  paginate: number,
  showFilters: boolean,
  total: number,

}

 class SystemEventsController extends Component<IProps, IState> {
  state: IState = {
    loading: false,
    page: 1,
    logName: null,
    logNameList: [],
    logNameLoding: false,
    descriptionList: [],
    descName: null,
    descriptionLoding: false,
    eventListData: [],
    rowCount:100,
      rowCountList:[10, 25 , 50 , 100],
      paginate: 0,
      showFilters: false,
      total: 0,


  }
  paginationSelectTimeOut: string | number | NodeJS.Timeout | undefined; 
  handlePaginationSelect=(event:any)=>{
    this.setState({
      rowCount: event.currentTarget.value,
      page:1
    })
    clearTimeout(this.paginationSelectTimeOut);
    this.paginationSelectTimeOut = setTimeout(() => {
      this.getEventList()
    }, 800);
  }
  paginationTimeOut: string | number | NodeJS.Timeout | undefined; 
  handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ page: value })
    clearTimeout(this.paginationTimeOut);
    this.paginationTimeOut = setTimeout(() => {
      this.getEventList()
    }, 800);
  }
  logNameTimeout: string | number | NodeJS.Timeout | undefined;
  logNameSelected = (choice: any) => {
    this.setState({ logName: choice, page: 1 })
    clearTimeout(this.logNameTimeout);
    this.logNameTimeout = setTimeout(() => {
      this.getEventList()
    }, 100);
  }
  descTimeout: string | number | NodeJS.Timeout | undefined;
  descSelected = (choice: any) => {
    this.setState({ descName: choice, page: 1 })
    clearTimeout(this.descTimeout);
    this.descTimeout = setTimeout(() => {
      this.getEventList()
    }, 1000);
  };
  async getLogNameList() {
    this.setState({ logNameLoding: true })
    call_api({
      address_api: `${AppConstants.base_url_api}seller/log-name`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
            logNameList: j.data, logNameLoding: false
          })
        } else if (status === 401) {
          this.setState({ logNameLoding: false })
          localStorage.clear();
        } else {
          this.setState({ logNameLoding: false })
          toast.error(j?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };


  async getDescNameList() {
    this.setState({ descriptionLoding: true })
    call_api({
      address_api: `${AppConstants.base_url_api}seller/description`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
            descriptionList: j.data, descriptionLoding: false
          })
        } else if (status === 401) {
          this.setState({ descriptionLoding: false })
          localStorage.clear();
        } else {
          this.setState({ descriptionLoding: false })
          toast.error(j?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  async getEventList() {
    const { logName, descName , rowCount , page} = this.state;


    this.setState({ loading: true })

    let parameters = {
      log_name: logName && Object.keys(logName).length > 0 ? logName.log_name : null,
      page: page ? page.toString() : null,
      description_name: descName && Object.keys(descName).length > 0 ? descName.description_name : null,
      paginate: rowCount,
    }

    let url = new URL(`${AppConstants.base_url_api}seller/logs`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}seller/logs${url.search}` : `${AppConstants.base_url_api}seller/logs`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
            eventListData: j.data, total: j.data?.total
          })

          this.setState({
            loading: false
          })
        } else if (status === 401) {
          this.setState({ loading: false })
          localStorage.clear();
        } else {
          this.setState({ loading: false })
          toast.error(j?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}


export default SystemEventsController;