import React, { Component, createRef } from 'react';
import { call_api, parseJSON } from "../../../core/service";
import { toast } from 'react-toastify';
import { AppConstants } from '../../../core/constants';
import * as yup from 'yup'
import moment from "moment-jalaali";
import { fixNumbers } from "../../../core/utils";

interface IProps {
  dispatch?:any,
  siteList?:any,
  loadingSiteList?:any,
}
interface IState {
  loading: boolean,
  page: number,
  addressesList?: any
  approved: string;
  search: string;
  currentItem: any,
  isCreating: boolean,
  errorsList?:any,
  dreiverId: any;
  statusSelectedList: any,
  rowCount:any,
  rowCountList:any,
  paginate: number,
  showFilters:boolean,
  dateValue: any;
  openEditAdress:boolean,
  openEditAddressAlert:boolean,
  userSelected: any,
  userLoading: boolean,
  profileListData: any,
  allOption: any,
  addressTitle: string;
  provinceId: any;
  provincesList: any;
  cityId: any;
  citiesList: any;
  loadingProvinces: boolean;
  loadingCities: boolean;
  neighborhoodList:any;
  neighbourhood: any;
  position: string;
  postalCode: string;
  addressDetail: string;
  openAddAddressModal: boolean;
  loadingNeighborhood: boolean;
  addressData:any;
}
class AddressManagementController extends Component<IProps, IState> {
  myRef:any;
  tableInstanceRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    this.myRef = createRef();
    this.tableInstanceRef = createRef();
    this.state = {   
      loading: false,
      page: 1,
      addressesList: [],
      approved: "",
      search: "",
      currentItem: {},
      isCreating: false,
      errorsList:[],
      dreiverId: [],
      statusSelectedList: [],
      rowCount:100,
      rowCountList:[10, 25 , 50 , 100],
      paginate: 0,
      showFilters:false,
      dateValue: [],
      openEditAdress:false,
      openEditAddressAlert:false,
      userSelected: null,
      userLoading: false,
      profileListData: [],
      allOption: {
        id: 0,
        value: {
          persian_name: "همه",
          name: "*",
        },
      },
      addressTitle: "",
      provinceId: { id: 8, name: "تهران" },
      provincesList: [{ name: "تهران", id: 1 }],
      cityId: { id: 117, name: "تهران", porterage_id: "[,]", province_id: 8 },
      citiesList: [],
      loadingProvinces:false,
      loadingCities: false,
      neighborhoodList:[{name:"سوهانک", id:1}, {name:"توحید", id:2}, {name:"نارمک", id:3}, {name:"فردوس", id:4}],
      neighbourhood:{},
      position: "",
      postalCode: "",
      addressDetail: "",
      openAddAddressModal: false,
      loadingNeighborhood: false,
      addressData: {title:"", province:{id: 8, name:"تهران"}, city:{id:117, name:"تهران"}, region:{id:101, name:"", }, postal_code:"", address_description:"", full_address:""},
    }
  }
  
  paginationSelectTimeOut: string | number | NodeJS.Timeout | undefined; 
  handlePaginationSelect=(event:any)=>{
    this.setState({
      rowCount: event.currentTarget.value,
      page:1
    })
    clearTimeout(this.paginationSelectTimeOut);
    this.paginationSelectTimeOut = setTimeout(() => {
      this.getUsersAddress()
    }, 800);
    
  }
  paginationTimeOut: string | number | NodeJS.Timeout | undefined; 
  handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ page: value })
    clearTimeout(this.paginationTimeOut);
    this.paginationTimeOut = setTimeout(() => {
      this.getUsersAddress()
    }, 800);
  }
  searchTimeout: string | number | NodeJS.Timeout | undefined;
  handleSearch = (event: any) => {
    this.setState({ search: event.currentTarget.value })
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.getUsersAddress()
    }, 1000);
  };
  driverTimeout: string | number | NodeJS.Timeout | undefined;
  driverSelected = (selected: any, index?: any) => {
    this.setState({page:1})
    if (index?.option?.id === 0) {
      this.setState({
        dreiverId: this.props.siteList,
      });
    } else
      this.setState({
        dreiverId: selected,page:1, profileListData:[], userSelected:null
      });
    clearTimeout(this.driverTimeout);
    this.driverTimeout = setTimeout(() => {
      this.getUsersAddress();
    }, 1000);
  }
  handleCloseAddressEditModal = () => {
    this.setState({ openEditAdress: false, addressData:{title:"", province:{id: 8, name:"تهران"}, city:{id:117, name:"تهران"},
     region:{id:101, name:"", }, postal_code:"", address_description:"", full_address:""}})
  }
  handleEdit = (data:any)=>{
    this.setState({openEditAddressAlert: true, addressData: data})
  }
  async handleConfirmEdition() {
    const { currentItem, addressData } = this.state;
    const addressInfo = yup.object().shape({
      addressData:yup.object().shape({
      city: yup.object().required("شهر الزامی است."),
      full_address:yup.string().required("آدرس دقیق الزامی است."),
      province:yup.object().required("استان الزامی است."),
      title:yup.string().required("عنوان آدرس الزامی است."),
      postal_code: yup.string().notRequired().max(10,"کدپستی باید شامل ۱۰ عدد باشد.").min(10,"کدپستی باید شامل ۱۰ عدد باشد.").nullable().transform((value) => !!value ? value : null),
    })
  })   
  try{
   await addressInfo.validate({addressData}, { abortEarly: false });
    call_api({
      address_api: `${AppConstants.base_url_api}seller/address-all/${currentItem?.id}`,
      method_api: "PATCH",
      body: JSON.stringify({
        user_id: currentItem?.user_id.toString(),
        label: addressData.title,
        db_name: currentItem?.db_name,
        province_id: addressData.province?.id.toString(),
        address: addressData.full_address,
        city_id : addressData.city?.id.toString(),
        desc: addressData.address_description,
        postal_code: addressData.postal_code,
        region: addressData.region?.name
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        this.setState({ errorsList: [] })
        if (status === 200) {
          toast.success(j?.message);
          this.setState({openAddAddressModal: false, openEditAddressAlert: false, openEditAdress: false,
            addressData:{title:"", province:{id: 8, name:"تهران"}, city:{id:117, name:"تهران"}, region:{id:101, name:"", }, postal_code:"", address_description:"", full_address:""},
          });
            this.getUsersAddress()

        } else if (status === 401) {
          toast.error(j?.errors);
          localStorage.clear();
        } else {
          
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error("catch api",error);
      });
   }
    catch(err: any) 
        { this.setState({ errorsList: err.inner })}
  }
  async getUsersAddress() {

    const { userSelected, dreiverId, rowCount , page } = this.state;
    this.setState({ loading: true });
    const driversSelectedList = [];
    for (var i = 0; i < dreiverId.length; i++) {
      driversSelectedList.push(dreiverId[i]?.value?.name);
    }
    let parameters = {
      user_id : userSelected ? userSelected.id.toString() : null,
      db_name: dreiverId?.length > 0 ? driversSelectedList.toString() : null,
      page: page ? page.toString() : null,
      paginate: rowCount,
    }
    let url = new URL(`${AppConstants.base_url_api}seller/address`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}seller/address${url.search}` : `${AppConstants.base_url_api}seller/address`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
            addressesList: j?.data,
            loading: false

          })
        } else if (status === 401) {
          this.setState({ loading: false })
          localStorage.clear();
        } else {
          this.setState({ loading: false })
          toast.error(j?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  modalSelectTimeOut: string | number | NodeJS.Timeout | undefined; 
  handleOpenModal=(row:any)=>{
    this.setState({ currentItem: row, openEditAdress: true });
    let parameters = {
     db_name: row?.db_name,
    };
    let url = new URL(
      `${AppConstants.base_url_api}seller/address-all/${row.id}`
    );

    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value);
      }
    }
    call_api({
      address_api: url.search
        ? `${AppConstants.base_url_api}seller/address-all/${row.id}${url.search}`
        : `${AppConstants.base_url_api}seller/address-all/${row.id}`,
      method_api: "GET",
    })      
    .then(parseJSON)
    .then(([status, j]) => {
      if (status === 200) {
        this.setState({ addressData:{title:j?.data?.label, province:{id: j?.data?.province_id?.id, name: j?.data?.province_id?.name}, city:{id:j?.data?.city_id?.id, name:j?.data?.city_id?.name}, 
        region:{id:101, name:j?.data?.region, }, postal_code:j?.data?.postal_code, address_description:j?.data?.desc, full_address:j?.data?.address},
      })
      } else if (status === 401) {
        localStorage.clear();
      } else {
        toast.error(j?.message);
      }
    })
    .catch((error) => {
      console.error(error);
    });
    
  }
  async addAddress(address:any){
    const { currentItem } = this.state;
    this.setState({addressData: address})
    const addressInfo = yup.object().shape({
      address:yup.object().shape({
      city: yup.object().required("شهر الزامی است."),
      full_address:yup.string().required("آدرس دقیق الزامی است."),
      province:yup.object().required("استان الزامی است."),
      title:yup.string().required("عنوان آدرس الزامی است."),
      postal_code: yup.string().notRequired().max(10,"کدپستی باید شامل ۱۰ عدد باشد.").min(10,"کدپستی باید شامل ۱۰ عدد باشد.").nullable().transform((value) => !!value ? value : null),
    })
  })   
  try{
   await addressInfo.validate({address}, { abortEarly: false });
    call_api({
      address_api: `${AppConstants.base_url_api}seller/address-all`,
      method_api: "POST",
      body: JSON.stringify({
        user_id: currentItem?.user_id.toString(),
        label: address.title,
        db_name: currentItem?.db_name,
        province_id: address.province?.id.toString(),
        address: address.full_address,
        city_id : address.city?.id.toString(),
        desc: address.address_description,
        postal_code: address.postal_code,
        region: address.region?.name
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        this.setState({ errorsList: [] })
        if (status === 200) {
          toast.success(j?.message);
          this.setState({openAddAddressModal: false,
            addressData:{title:"", province:{id: 8, name:"تهران"}, city:{id:117, name:"تهران"}, region:{id:101, name:"", }, postal_code:"", address_description:"", full_address:""},
          });
            this.getUsersAddress()

        } else if (status === 401) {
          toast.error(j?.errors);
          localStorage.clear();
        } else {
          
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error("catch api",error);
      });
   }
    catch(err: any) 
        { this.setState({ errorsList: err.inner })}
}
  searchUserTimeout: string | number | NodeJS.Timeout | undefined;
  handleSearchUser = (choice: string) => {
    clearTimeout(this.searchUserTimeout);
    if (choice.length > 2)
      this.searchUserTimeout = setTimeout(() => {
        this.getProfilesList(choice);
      }, 1000);
  };
  searchUserSelectedTimeout: string | number | NodeJS.Timeout | undefined;

  userSelected = (choice: any) => {
    this.setState({
      userSelected: choice,
      page: 1
    });
    this.searchUserSelectedTimeout = setTimeout(() => {
      this.getUsersAddress();
    }, 1000);
  };
  async getProfilesList(search?: string) {
    const { dreiverId } = this.state;
    const driversSelectedList = [];
    for (var i = 0; i < dreiverId.length; i++) {
      driversSelectedList.push(dreiverId[i]?.value?.name);
    }
    this.setState({ userLoading: true });
    let parameters = {
      search: search ? search.toString() : null,
      db_name: dreiverId?.length > 0 ? driversSelectedList.toString() : null,
    };
    let url = new URL(
      `${AppConstants.base_url_api}seller/users-all-no-paginate`
    );

    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value);
      }
    }
    call_api({
      address_api: url.search
        ? `${AppConstants.base_url_api}seller/users-all-no-paginate${url.search}`
        : `${AppConstants.base_url_api}seller/users-all-no-paginate`,
      method_api: "GET",
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({ userLoading: false });
          if (j.data) {
            this.setState({ profileListData: j?.data });
          }
        } else if (status === 401) {
          this.setState({ userLoading: false });
          localStorage.clear();
        } else {
          this.setState({ userLoading: false });
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({ userLoading: false });
      });
  }
  handleOpenAddAddreassModal = (data:any) =>{
    this.setState({openAddAddressModal : true, currentItem: data})
  }
  handleCloseAddAddressModal = ()=>{
    this.setState({openAddAddressModal: false, addressData:{title:"", province_id: 8, city_id: 117,
    region:{name:"", id:""}, postal_code:"", address_description:"", full_address:""}})
  }
}
export default AddressManagementController;