import {
    InputHTMLAttributes,
    ReactElement,
    useState,
  } from "react";
  import {AiOutlineEdit} from "react-icons/ai";
  import {BsTrash} from "react-icons/bs";
  interface Props extends InputHTMLAttributes<HTMLInputElement> {
    value:string;
    myStyle?:string;
    defaultValue?:string,
    inputContainerStyle?:string,
    label?:string,
    setDatas?:any,
    handleChange:any,
    handleDelete:any,
    disabled?:boolean,
    index?:number,
    user:string,
    date:string,
  }
  
   function NoteCard({
    value,
    myStyle,
    handleChange,
    handleDelete,
    defaultValue,
    disabled,
    index,
    date,
    user,
  }: Props): ReactElement {
      const [isDisabled,setDisabled] = useState<boolean>(true)
  
    return (
      <div className={`note-card ${myStyle}`} >
        <div className="container">
            <span>{user}:</span>
        <AiOutlineEdit onClick={()=>setDisabled(!isDisabled)} className="icon-yellow icon" />
        <input value={value} disabled={isDisabled} name="name" onChange={handleChange} autoComplete="none" defaultValue={defaultValue}/>
              <div className="last-row">
                <BsTrash className="icon-red" onClick={handleDelete} />
                <div>{date}</div>
              </div>
        </div>
      </div>
    );
  }
  export default NoteCard;
  