import { Component } from "react";
import { createRef } from "react";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { AppConstants } from "../../../core/constants";
import { call_api, parseJSON } from "../../../core/service";
import { toast } from "react-toastify";

interface IProps { 
    dispatch?:any,
    siteList?:any,
    loadingSiteList?:any,
    userData?:any
  }
  interface IState {
  notesListData: any,
  fileType: string,
  fileExtension: string,
  total:number
  rowCount:any,
  rowCountList:any,
  loading: boolean,
  page: number,
  search:string,
  toggleModal:boolean,
  currentItem:any,
  notesList?:any,
  dreiverId?:any,
  showFilters:boolean,
  allOption:any

  }
 class UsersNoteController extends Component<IProps, IState> {
    tableInstanceRef: React.RefObject<any>;
    constructor(props: any) {
      super(props);
      this.tableInstanceRef = createRef()
      this.state = {   
    notesListData: [],
      fileType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      fileExtension: ".xlsx",
      total:0,
      rowCount:100,
      rowCountList:[10, 25 , 50 , 100],
      loading: false,
      page: 1,
      search:"",
      toggleModal:false,
      currentItem:{},
      notesList:[],
      dreiverId: [],
      showFilters:false,
      allOption:{id:0 ,value:{
        persian_name: "همه",
        name: "*"
      }},
      }
    }
    exportToExcel = async (excelData: any, fileName: any) => {
      const ws = XLSX.utils.json_to_sheet(excelData);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: "array" });
      const data = new Blob([excelBuffer], { type: this.state.fileType });
      FileSaver.saveAs(data, fileName + this.state.fileExtension);
    
    }
    paginationSelectTimeOut: string | number | NodeJS.Timeout | undefined; 
    handlePaginationSelect=(event:any)=>{
      this.setState({
        rowCount: event.currentTarget.value,
        page:1
      })
      clearTimeout(this.paginationSelectTimeOut);
      this.paginationSelectTimeOut = setTimeout(() => {
      this.getList()
      }, 800);
      
    }
    paginationTimeOut: string | number | NodeJS.Timeout | undefined; 
    handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
      this.setState({ page: value })
      clearTimeout(this.paginationTimeOut);
      this.paginationTimeOut = setTimeout(() => {
      this.getList()
    }, 800);
      
    }
    handleDelete = (item:any) =>{
    }
      searchTimeout: string | number | NodeJS.Timeout | undefined;
      handleSearch = (event: any) => {
        this.setState({ search: event.currentTarget.value })
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          this.getList()
        }, 1000);
      };
    handleCloseModal = () =>{
      this.setState({toggleModal: false})
    }
    async getList() {
      const {page,dreiverId,rowCount,search} = this.state;
      const driversSelectedList = [];
    this.setState({ loading: true })
    for (var i = 0; i < dreiverId.length; i++) {
      driversSelectedList.push(dreiverId[i]?.value?.name)
      // this.setState({ usersUuid: driversSelectedList })
    }
      let parameters = {
        search: search ? search: null,
        is_search : JSON.stringify(1),
      page: page ? page.toString() : null,
      paginate: rowCount,
      db_name: dreiverId?.length > 0 ? driversSelectedList.toString() : null,
      }
      let url = new URL(`${AppConstants.base_url_api}admin/notes`)
      for (const [key, value] of Object.entries(parameters)) {
        if (value) {
          url.searchParams.append(key, value)
        }
      }
      call_api({
        address_api: url.search ? `${AppConstants.base_url_api}admin/notes${url.search}` : `${AppConstants.base_url_api}admin/notes`,
        method_api: "GET"
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            if (j.data) {
              this.setState({
                notesListData:j.data?.data,
                loading: false,
                total: j.data?.total
              })
            }
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      };
      driverTimeout: string | number | NodeJS.Timeout | undefined;
      driverSelected = (selected:any, index?:any) : any=> {
      this.setState({ page: 1 })
        if(index?.option?.id === 0)
        {
          this.setState({
            dreiverId: this.props.siteList
          });
    
        }
        else 
        this.setState({
          dreiverId: selected
        });
        clearTimeout(this.driverTimeout);
        this.driverTimeout = setTimeout(() => {
          this.getList()
        }, 1000);
      }
}

export default UsersNoteController;