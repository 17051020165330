import { Component } from "react";
import { call_api, parseJSON } from "../../../core/service";
import { AppConstants } from "../../../core/constants";
import { toast } from "react-toastify";
import { createRef } from "react";
import {Logo} from "../../../assets";
import * as yup from 'yup'

interface IProps { 
    dispatch?:any,
    siteList?:any,
    loadingSiteList?:any,
    userData?:any
  }
  interface IState {

    toggleTab: number,
    toggleMediaTab:number,
    compressedData?:any,
    step:string,
    atoumaticSave:Array<any>,
    editorLoading:boolean,
    errorsList:any,
    allCategoryList:any,
    allBrandList:any,
    allTagList:any,
    allNotesList:any,
    allVariableProducts:any,
    allLinkedProducts:any,
    notListLoading:boolean,
    categoryLoading:boolean,
    productSelectLoading:boolean,
    brandLoading: boolean,
    notesLoading:boolean,
  
  }
export class AddProductController extends Component<IProps, IState> {
    tableInstanceRef: React.RefObject<any>;
    constructor(props: any) {
      super(props);
      this.tableInstanceRef = createRef()
      this.state = {   
        toggleTab: 0,
        compressedData:[{
          persianName: "",
          englishName:"",
          productName: "",
          seoTitle:"",
          seoDescription:"",
          canonicalUrl:"",
          routeName:"",
          weight:"",
          category:null,
          categoryList: [],
          brandId: null,
          brandList: [],
          tag:[],
          tagList:[],
          tagLoading: false,
          linkedProductId: [],
          linkedProductList:[],
          variableProductId:[],
          variableProductList:[],
          editorShortInfoText:"",
          editorGeneralInfoText:"",
          availebleMediaImage: "",
          mediaImage:"",
          mediaImageUpload:"",
          setSelectedFiles:[],
          imagesList:[],
          videosList:[],
          filesList:[],
          pros:"",
          prosList:[],
          cons:"",
          consList:[],
          seoCardList:[{image: Logo, alt:"تصویر موجود نیست" }],
        noteCardsList:[],
        notCardSelectList:[{id:1, message:"aria"}],
        noteCardSelectId:null,
        productDetails:{},
        dbName:"netran",
        idSelect:1,
        errorsList:[],
        linkedProductLoading:false,}],
        step:"netran",
        toggleMediaTab:0,
        atoumaticSave:[{date:"۱۴۰۲/۱۰/۲"}, {date:"۱۴۰۲/۱۰/۲"}, {date:"۱۴۰۲/۱۰/۲"}, {date:"۱۴۰۲/۱۰/۲"}],
        editorLoading:true,
        errorsList:[],
        allCategoryList:[],
        allBrandList:[],
        allTagList:[],
        allNotesList:[],
        allVariableProducts:[],
        allLinkedProducts:[],
        notListLoading:false,
        categoryLoading:false,
        productSelectLoading:false,
        brandLoading: false,
        notesLoading: false,
      }
    }
    handleToggleTabs =(event: React.MouseEvent<HTMLElement>,
    newAlignment: number,
  ) => { this.setState({toggleTab: newAlignment})}
  handleToggleMediaTabs =(event: React.MouseEvent<HTMLElement>,
    newAlignment: number,
  ) => {
    if(newAlignment !== null)
    {
      this.setState({toggleMediaTab: newAlignment})}
    }
    addNewItem = (step:string, index:number) => {
      
      const {compressedData} = this.state;
      this.setState({step:step})
      let ids = [...compressedData];   
      var found = false;
      for( var i = 0; i < compressedData?.length ; i++){
        if(compressedData[i]?.dbName === step){
            found = true;
            break;
        }
      }  
      if(!found){
        const obj = {
          persianName: "",
          englishName:"",
          productName: "",
          seoTitle:"",
          seoDescription:"",
          canonicalUrl:"",
          routeName:"",
          weight:"",
          category:null,
          categoryList: [],
          brandId: null,
          brandList: [],
          tag:[],
          tagList:[],
          tagLoading: false,
          linkedProductId: [],
          linkedProductList:[],
          variableProductId:[],
          variableProductList:[],
          editorShortInfoText:"",
          editorGeneralInfoText:"",
          availebleMediaImage: "",
          mediaImage:"",
          mediaImageUpload:"",
          setSelectedFiles:[],
          imagesList:[],
          videosList:[],
          filesList:[],
          pros:"",
          prosList:[],
          cons:"",
          consList:[],
          seoCardList:[{image: Logo, alt:"تصویر موجود نیست" }],
        noteCardsList:[],
        notCardSelectList:[{id:1, message:"aria"}],
        noteCardSelectId:null,
        productDetails:{},
        dbName:step,
        idSelect:1,
        errorsList:[],
        linkedProductLoading:false,
        step:step,
        toggleMediaTab:0,
        atoumaticSave:[{date:"۱۴۰۲/۱۰/۲"}, {date:"۱۴۰۲/۱۰/۲"}, {date:"۱۴۰۲/۱۰/۲"}, {date:"۱۴۰۲/۱۰/۲"}],
        editorLoading:true,
        allCategoryList:[],
        allBrandList:[],
        allTagList:[],
        allNotesList:[],
        allVariableProducts:[],
        allLinkedProducts:[],
        notListLoading:false,
        categoryLoading:false,
        productSelectLoading:false,
        brandLoading: false,
        notesLoading: false,
         
        }
            ids.push(obj);
            this.setState({ compressedData: ids });
      }
  }
    handlePersianName = (event:any) => {
      const {compressedData, step} = this.state;
      let ids = [...compressedData];     
      ids.filter((item:any) => item.dbName === step)[0].persianName = event.currentTarget.value;

      this.setState({
        compressedData:ids
      })
  }
                          
  handleEnglishName = (event:any) =>{
    const {compressedData, step} = this.state;
    let ids = [...compressedData];     
    ids.filter((item:any) => item.dbName === step)[0].englishName = event.currentTarget.value;

    this.setState({
      compressedData:ids
    })
  }
  handleProductName =(event:any)=>{
    const {compressedData, step} = this.state;
    let ids = [...compressedData];     
    ids.filter((item:any) => item.dbName === step)[0].productName = event.currentTarget.value;

    this.setState({
      compressedData:ids
    })
  }
  handleWeight =(event:any)=>{
    const {compressedData, step} = this.state;
    let ids = [...compressedData];     
    ids.filter((item:any) => item.dbName === step)[0].weight = event.currentTarget.value;

    this.setState({
      compressedData:ids
    })
  }
  handlePros = (event:any) => {
    const {compressedData, step} = this.state;
    let ids = [...compressedData];     
    ids.filter((item:any) => item.dbName === step)[0].pros = event.currentTarget.value;

    this.setState({
      compressedData:ids
    })
  }
  handleCons = (event:any) =>{
    const {compressedData, step} = this.state;
    let ids = [...compressedData];     
    ids.filter((item:any) => item.dbName === step)[0].cons = event.currentTarget.value;

    this.setState({
      compressedData:ids
    })
  }
    handleChangeSeoTitle = (event:any) =>{
        const {compressedData, step} = this.state;
        let ids = [...compressedData];     
        ids.filter((item:any) => item.dbName === step)[0].seoTitle = event.currentTarget.value;
    
        this.setState({
          compressedData:ids
        })
    }
    handleChangeSeoDes = (event:any) =>{
      const {compressedData, step} = this.state;
      let ids = [...compressedData];     
      ids.filter((item:any) => item.dbName === step)[0].seoDescription = event.currentTarget.value;
  
      this.setState({
        compressedData:ids
      })
    }
    handleChangeURL = (event:any) =>{
      const {compressedData, step} = this.state;
      let ids = [...compressedData];     
      ids.filter((item:any) => item.dbName === step)[0].canonicalUrl = event.currentTarget.value;
  
      this.setState({
        compressedData:ids
      })
    }
  categoryTimeout: string | number | NodeJS.Timeout | undefined;
  categorySelected = (choice: any) => {
    const {compressedData, step} = this.state;
    let ids = [...compressedData];    
    ids.filter((item:any) => item.dbName === step)[0].category = choice;
    this.setState({ compressedData:ids })
  }
  handleCategorySearch= (choice:any) =>{
    clearTimeout(this.categoryTimeout);
    if(choice.length > 2)
    this.categoryTimeout = setTimeout(() => {
      this.getCategorySearch(choice)
    }, 1000);
  }
  async getCategorySearch(search?:string) {
    const {step} = this.state;
    let parameters = {
      search: search ? search: null,
      is_search : JSON.stringify(1),
      db_name:step !== "" ? step : null,

    }
    let url = new URL(`${AppConstants.base_url_api}admin/categories`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}admin/categories${url.search}` : `${AppConstants.base_url_api}admin/categories`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          if (j.data) {
            
            this.setState({allCategoryList:j.data || []})
          }
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    };
  brandTimeout: string | number | NodeJS.Timeout | undefined;
  brandSelected = (choice: any) => {
    const {compressedData, step} = this.state;
    let ids = [...compressedData];    
    ids.filter((item:any) => item.dbName === step)[0].brandId = choice;
    this.setState({ compressedData:ids})
  }
  handleBrandSearch= (choice:any) =>{
    clearTimeout(this.brandTimeout);
    if(choice.length > 2)
    this.brandTimeout = setTimeout(() => {
      this.getBrandSearch(choice)
    }, 1000);
  }
  async getBrandSearch(search?:string) {
    let parameters = {
      search: search ? search: null,
      is_search : JSON.stringify(1),
    }
    let url = new URL(`${AppConstants.base_url_api}admin/brands`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}admin/brands${url.search}` : `${AppConstants.base_url_api}admin/brands`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          if (j?.data) {
            this.setState({allBrandList:j.data || []})
          }
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    };
    async getNotesSearch(search?:string) {
  this.setState({notListLoading: true})      
      let parameters = {
        search: search ? search: null,
        is_search : JSON.stringify(1),
      }
      let url = new URL(`${AppConstants.base_url_api}admin/notes`)
      for (const [key, value] of Object.entries(parameters)) {
        if (value) {
          url.searchParams.append(key, value)
        }
      }
      call_api({
        address_api: url.search ? `${AppConstants.base_url_api}admin/notes${url.search}` : `${AppConstants.base_url_api}admin/notes`,
        method_api: "GET"
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            if (j.data) {
              
                this.setState({allNotesList:j.data?.data || [],notListLoading:false })
            }
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      };
  tagSelected = (choice: any) => {
    const {compressedData, step} = this.state;
    let ids = [...compressedData];     
    ids.filter((item:any) => item.dbName === step)[0].tag = choice;
    this.setState({ compressedData:ids   })
  }
  tagSearchTimeout: string | number | NodeJS.Timeout | undefined;
  handleTagSearch = (choice:any) =>{
    clearTimeout(this.tagSearchTimeout);
    if(choice.length > 2)
    this.tagSearchTimeout = setTimeout(() => {
      this.getTagSearch(choice)
    }, 1000);
  }
  async getTagSearch(search?:string) {
    const {compressedData, step} = this.state;
    let ids = [...compressedData];
    // ids.filter((item:any) => item.dbName === step)[0].tagLoading = true;
    this.setState({compressedData: ids})
    let parameters = {
      search: search ? search: null,
      is_search : JSON.stringify(1),
      db_name:step !== "" ? step : null,

    }
    let url = new URL(`${AppConstants.base_url_api}admin/tags`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}admin/tags${url.search}` : `${AppConstants.base_url_api}admin/tags`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(async ([status, j]) => {
        if (status === 200) {
          if (j.data) {
            this.setState({allTagList:j.data})
          }
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  linkedProductSelected = (choice: any) => {
    const {step}= this.state;
    let ids = [...this.state.compressedData];
    ids.filter((item:any) => item.dbName === step)[0].linkedProductId = choice;

    this.setState({ compressedData:ids})
  };
  linkedProductsSearchTimeout: string | number | NodeJS.Timeout | undefined;

  handleLinkedProductsSearch= (choice:any) =>{
    clearTimeout(this.linkedProductsSearchTimeout);
    if(choice.length > 2)
    this.linkedProductsSearchTimeout = setTimeout(() => {
      this.getLinkedProductsSearch(choice)
    }, 1000);
  }
   getLinkedProductsSearch(search?:string) {
    const {step} = this.state;

    let parameters = {
      search: search ? search: null,
      is_search : JSON.stringify(1),
      db_name:step !== "" ? step : null,

    }
    let url = new URL(`${AppConstants.base_url_api}admin/product/search`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}admin/product/search${url.search}` : `${AppConstants.base_url_api}product/search`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(async ([status, j]) => {
        if (status === 200) {
          
          if (j.data) {   
            this.setState({allVariableProducts:j.data || [], allLinkedProducts:j.data })
          }
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  variableProductsSearchTimeout: string | number | NodeJS.Timeout | undefined;
  variableProductsSelect = (choice: any) => {
    const {compressedData, step} = this.state;
    let ids = [...compressedData];
    ids.filter((item:any) => item.dbName === step)[0].variableProductId = choice;
    this.setState({ compressedData:ids})

  };
  handleVariableProductsSearch= (choice:any) =>{
    clearTimeout(this.variableProductsSearchTimeout);
    if(choice === "")
    {
      this.getVariableProductsSearch()
    }
    if(choice.length > 2)
    this.variableProductsSearchTimeout = setTimeout(() => {
      this.getVariableProductsSearch(choice)
    }, 1000);
  }
  async getVariableProductsSearch(search?:string) {
    const {compressedData, step} = this.state;
    let parameters = {
      search: search ? search: null,
      is_search : JSON.stringify(1),
      db_name:step !== "" ? step : null,
    }
    let url = new URL(`${AppConstants.base_url_api}admin/product/search`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}admin/product/search${url.search}` : `${AppConstants.base_url_api}product/search`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(async ([status, j]) => {
        if (status === 200) {
          if (j.data) {
            let ids = [...compressedData];
            ids.filter((item:any) => item.dbName === step)[0].variableProductList = j.data;
            this.setState({ compressedData:ids})
          }
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  editNoteCard = (newValue: number, text:string) =>{
    const {compressedData, step} = this.state;
    let parameters = {
      message : text,
      id_select:compressedData.filter((item:any) => item.dbName === step)[0].idSelect ? compressedData.filter((item:any) => item.dbName === step)[0].idSelect : null,
    }
    let url = new URL(`${AppConstants.base_url_api}admin/notes/${newValue.toString()}`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}admin/notes/${newValue.toString()}${url.search}` : `${AppConstants.base_url_api}notes/${newValue.toString()}`,
      method_api: "PUT"
    })
      .then(parseJSON)
      .then(async ([status, j]) => {
        if (status === 200) {
          toast.success("یادداشت با موفقیت ویرایش شد.")
          this.getNotesSearch()
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }


  handleEditorChangeShortInfo = (e: { target: { getContent: () => any; }; }) => {
    const {compressedData, step} = this.state;
    let ids = [...compressedData];
    ids.filter((item:any) => item.dbName === step)[0].editorShortInfoText = e.target.getContent()
    this.setState({ compressedData:ids}) 

  };
  handleEditorChangeGeneralInfo = (e: { target: { getContent: () => any; }; }) => {
    const {compressedData, step} = this.state;
    let ids = [...compressedData];
    ids.filter((item:any) => item.dbName === step)[0].editorGeneralInfoText =  e.target.getContent();
    this.setState({ compressedData:ids}) 

  };
  handleMediaImageUpload = (event: any) => {
      const {step} = this.state;
      let news_image_file = null;
      news_image_file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(news_image_file);
      let ids = [...this.state.compressedData];
      ids.filter((item:any) => item.dbName === step)[0].mediaImage = reader.result;
      reader.onloadend = () => {
        this.setState({ compressedData:ids}) 
      }
      if (news_image_file) {
        ids.filter((item:any) => item.dbName === step)[0].mediaImageUpload = news_image_file;
        this.setState({ compressedData:ids}) 
      }
      ids.filter((item:any) => item.dbName === step)[0].availebleMediaImage = ""
      this.setState({ compressedData:ids}) 
    } 
    handleSubmitPros=()=> {
      const {compressedData, step} = this.state;
      if (compressedData.filter((item:any) => item.dbName === step)[0].pros === "") {
          alert("فیلد خالی است!");
        } else {
          let ids = [...compressedData];
          ids.filter((item:any) => item.dbName === step)[0].prosList = [...ids.filter((item:any) => item.dbName === step)[0].prosList,compressedData.filter((item:any) => item.dbName === step)[0].pros];
          ids.filter((item:any) => item.dbName === step)[0].pros = "";
          this.setState({ compressedData:ids}) 

        }
    }
    handleSubmitCons=()=> {
      const {compressedData, step} = this.state;
      if (compressedData.filter((item:any) => item.dbName === step)[0].cons === "") {
          alert("فیلد خالی است!");
        } else {
          let ids = [...this.state.compressedData];
          ids.filter((item:any) => item.dbName === step)[0].consList = [...ids.filter((item:any) => item.dbName === step)[0].consList,ids.filter((item:any) => item.dbName === step)[0].cons];
          ids.filter((item:any) => item.dbName === step)[0].pros = "";
          this.setState({ compressedData:ids})
        }
    }
  handleChangePros = (event:any, index:number) =>{
  const {compressedData, step} = this.state;
  const newPros = [...compressedData.filter((item:any) => item.dbName === step)[0].prosList];
  const { value } = event.currentTarget;
  newPros[index] = value;
  let ids = [...this.state.compressedData];
          ids.filter((item:any) => item.dbName === step)[0].prosList = newPros;
          this.setState({ compressedData:ids})
  }
  handleChangeCons = (event:any, index:number) =>{
    const {compressedData, step} = this.state;
    const newPros = [...compressedData.filter((item:any) => item.dbName === step)[0].consList];
    const { value } = event.currentTarget;
    newPros[index] = value; 
    let ids = [...compressedData];
    ids.filter((item:any) => item.dbName === step)[0].consList = newPros;
    this.setState({compressedData:ids})
    }
    changeImage = (argOne: any, argTwo: any, argThree: any) =>{
      const {compressedData, step} = this.state;
      let ids = [...compressedData];
      debugger
      ids.filter((item:any) => item.dbName === step)[0].imagesList = argOne;
      this.setState({compressedData: ids})
    }
    changeVideo = (argOne: any, argTwo: any, argThree: any) =>{
      const {compressedData, step} = this.state;
      let ids = [...compressedData];
      ids.filter((item:any) => item.dbName === step)[0].imagesList = argOne;
      ids.filter((item:any) => item.dbName === step)[0].videosList = argTwo;
      ids.filter((item:any) => item.dbName === step)[0].filesList = argThree;
      this.setState({compressedData: ids})
    }
    changeFile = (argOne: any, argTwo: any, argThree: any) =>{
      const {compressedData, step} = this.state;
      let ids = [...compressedData];
      ids.filter((item:any) => item.dbName === step)[0].imagesList = argOne;
      ids.filter((item:any) => item.dbName === step)[0].videosList = argTwo;
      ids.filter((item:any) => item.dbName === step)[0].filesList = argThree;
      this.setState({compressedData: ids})
    }
    
  editNoteCardTimeout: string | number | NodeJS.Timeout | undefined;
  handleChangeNoteCard = (item:any, event:any, index:number) =>{
      const {compressedData, step} = this.state;
      const newNoteCard = [...compressedData.filter((item:any) => item.dbName === step)[0].noteCardsList];
      const { value } = event.currentTarget;
      newNoteCard[index].message = value;
      let ids = [...this.state.compressedData];
      ids.filter((item:any) => item.dbName === step)[0].noteCardsList = newNoteCard;
      this.setState({compressedData:ids})

      clearTimeout(this.editNoteCardTimeout);
      this.editNoteCardTimeout = setTimeout(() => {
        this.editNoteCard(item, value)
      }, 1000);
      }
  handleSubmitSeoCard = () =>{

  }
  handleDeleteSeoCard = (index:number) =>{
    const { compressedData, step} = this.state;
    let ids = [...compressedData];
    ids.filter((item:any) => item.dbName === step)[0].seoCardList = [...compressedData.filter((item:any) => item.dbName === step)[0].seoCardList.slice(0,index)];
    const list = [...compressedData.filter((item:any) => item.dbName === step)[0].seoCardList]
    list.splice(index,1);
    ids.filter((item:any) => item.dbName === step)[0].seoCardList = list;
    this.setState({compressedData:ids})
  }
  changeAlt = (event:any, index:number) =>{
    const {compressedData, step} = this.state;
    const newSeoCard = [...compressedData.filter((item:any) => item.dbName === step)[0].seoCardList];
    const { value } = event.currentTarget;
    newSeoCard[index] = value;
    let ids = [...compressedData];
    ids.filter((item:any) => item.dbName === step)[0].seoCardList = newSeoCard;
    this.setState({compressedData:ids})
    }
  handleDeletePros = (index:number) =>{
    const { compressedData, step} = this.state;
    let ids = [...compressedData];
    const list = [...compressedData.filter((item:any) => item.dbName === step)[0].prosList]
    list.splice(index,1)
    ids.filter((item:any) => item.dbName === step)[0].prosList = list;
    this.setState({compressedData:ids})
  }
  handleDeleteCons = (index:number) =>{
    const { compressedData, step} = this.state;
    let ids = [...compressedData];
    const list = [...compressedData.filter((item:any) => item.dbName === step)[0].consList]
    list.splice(index,1)
    ids.filter((item:any) => item.dbName === step)[0].consList = list;
    this.setState({compressedData:ids})

  }
  handleDeleteNoteCard = (index:number, id:number) =>{
    const { compressedData, step} = this.state;
    let ids = [...compressedData];
    const list = [...compressedData.filter((item:any) => item.dbName === step)[0].noteCardsList]
    list.splice(index,1)
    ids.filter((item:any) => item.dbName === step)[0].noteCardsList = list;
    this.setState({compressedData:ids})
    let url = new URL(`${AppConstants.base_url_api}admin/notes/${id.toString()}`)
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}admin/notes/${id.toString()}${url.search}` : `${AppConstants.base_url_api}notes/${id.toString()}`,
      method_api: "DELETE"
    })
      .then(parseJSON)
      .then(async ([status, j]) => {
        if (status === 200) {
          toast.success("یادداشت با موفقیت حذف شد.");
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });

  }
   handleKeyPress = (event: { key: string; }) => {
    const {compressedData, step} = this.state;
    let ids = [...compressedData];
    if (event.key === 'Enter') {
      ids.filter((item:any) => item.dbName === step)[0].prosList = [...ids.filter((item:any) => item.dbName === step)[0].prosList,ids.filter((item:any) => item.dbName === step)[0].pros]
      ids.filter((item:any) => item.dbName === step)[0].pros = "";
      this.setState({compressedData : ids })
       }
  };
  handleKeyPressCons = (event: { key: string; }) => {
    const {compressedData, step} = this.state;
    let ids = [...compressedData];

    if (event.key === 'Enter') {
      ids.filter((item:any) => item.dbName === step)[0].consList = [...ids.filter((item:any) => item.dbName === step)[0].consList,ids.filter((item:any) => item.dbName === step)[0].cons]
      ids.filter((item:any) => item.dbName === step)[0].cons = "";
      this.setState({compressedData : ids });
    }
  };
  handleKeyPressNoteCardSelect = (event: { key: string; }) => {
    const {compressedData, step} = this.state;
    let ids = [...compressedData];
    if (event.key === 'Enter' && compressedData[0].noteCardSelectId !== null ) {
      ids.filter((item:any) => item.dbName === step)[0].noteCardsList = [...compressedData.filter((item: any, index: number) => item?.dbName === step)[0].noteCardsList,
      compressedData.filter((item: any, index: number) => item?.dbName === step)[0].noteCardSelectId];
      ids.filter((item:any) => item.dbName === step)[0].noteCardSelectId = null;
      this.setState({compressedData : ids});
      this.getNotesSearch()

     }
  };
  handleChangeCreatableSelect = (newValue: any, actionMeta: any) => {
    const { compressedData, step} = this.state;
    let ids = [...compressedData];

    if(newValue !== null)
    { 
      
      ids.filter((item:any) => item.dbName === step)[0].noteCardsList = [...compressedData.filter((item: any, index: number) => item?.dbName === step)[0].noteCardsList,newValue];
      ids.filter((item:any) => item.dbName === step)[0].noteCardSelectId= null;
      this.setState({compressedData : ids})
      this.handleSendNote(newValue)


    }

  };
  handleCreateNote = (input: string) =>{
    const {compressedData, step} = this.state;
    let ids = [...compressedData];
    const obj = {
      message: input,
    }
    if(input !== null)
    {      
      ids.filter((item:any) => item.dbName === step)[0].noteCardsList = [...compressedData.filter((item:any) => item.dbName === step)[0].noteCardsList,obj];
      ids.filter((item:any) => item.dbName === step)[0].noteCardsList.noteCardSelectId = null;
      this.setState({compressedData : ids})
      this.handleSendNote(input)

    }
    
  }
handleSendNote = (input:any) =>{
  const {step} = this.state;
  let parameters = {
    message : input?.message ? input?.message : input,
    status : "",
    parent_id:"",
    db_name: step,
  }
  let url = new URL(`${AppConstants.base_url_api}admin/notes`)
  for (const [key, value] of Object.entries(parameters)) {
    if (value) {
      url.searchParams.append(key, value)
    }
  }
  call_api({
    address_api: url.search ? `${AppConstants.base_url_api}admin/notes${url.search}` : `${AppConstants.base_url_api}notes`,
    method_api: "POST"
  })
    .then(parseJSON)
    .then(async ([status, j]) => {
      if (status === 200) {
        toast.success("یادداشت با موفقیت افزوده شد.")
      } else if (status === 401) {
        localStorage.clear();
      } else {
        toast.error(j?.errors);
      }
    })
    .catch((error) => {
      console.error(error);
    });
}
  notesTimeout: string | number | NodeJS.Timeout | undefined;
  handleInputChangeCreatableSelect = (inputValue: any, actionMeta: any) => {
    
    clearTimeout(this.notesTimeout);
    if(inputValue.length > 2)
    this.notesTimeout = setTimeout(() => {
      this.getNotesSearch(inputValue)
    }, 1000);
    
  };
  async handleAddProduct(){
    
    const {compressedData, step} = this.state;
    let filteredObj = compressedData.filter((item:any) => item.dbName === step)[0];
    const productSchema = yup.object().shape({
      filteredObj: yup.object().shape({
        persianName:yup.string().required("نام فارسی الزامی است."), 
        productName:yup.string().required(" نامک الزامی است."),
        seoDescription:yup.string().required("توضیحات سئو الزامی است."),
        seoTitle:yup.string().required("عنوان سئو الزامی است."),
        category:yup.object()
        .shape({
          id: yup.number().required(),
        })
        .default(undefined).required("دسته‌بندی الزامی است."),
        brandId: yup.object()
        .shape({
          id: yup.number().required(),
        })
        .default(undefined) 
        .required("برند انتخاب نشده‌است."),
        tag: yup.array().min(1, "حداقل یک مورد از برچسب باید انتخاب شود.").required("برچسب الزامی است."),
        editorShortInfoText:yup.string().required("توضیحات کوتاه الزامی است."),
        editorGeneralInfoText:yup.string().required("توضیحات کلی الزامی است."),        })
  });




    try {

      await productSchema.validate({filteredObj}, { abortEarly: false });
      

    const tagList = [];
    for (var a = 0; a < filteredObj.tag.length; a++) {
      tagList.push(filteredObj.tag[a]?.id)
    }
    const linkedProductList = [];
    for (var b = 0; b < filteredObj.linkedProductId.length; b++) {
      linkedProductList.push(filteredObj.linkedProductId[b]?.id)
    }
    const variableProductsList = [];
    for (var c = 0; c < filteredObj.variableProductId.length; c++) {
   
      variableProductsList.push(filteredObj.variableProductId[c]?.id)
    }
    
    const noteCardsArray = [];
    for (var d = 0; d < filteredObj.noteCardsList.length; d++) {
   
      noteCardsArray.push(filteredObj.noteCardsList[d]?.message)
    }

          const data = new FormData()
          
          filteredObj?.persianName && data.append('product_name', filteredObj?.persianName)
    filteredObj?.englishName && data.append('product_english_name',  filteredObj?.englishName)
    filteredObj?.productName && data.append('product_slug',  filteredObj?.productName)
    filteredObj?.weight && data.append('weight', filteredObj?.weight)
    filteredObj?.category?.id && data.append('category_id', filteredObj?.category?.id)
    filteredObj?.brandId?.id && data.append('brand_id', filteredObj?.brandId?.id)
    tagList.length > 0 && data.append('tag_id', tagList.toString())
    variableProductsList.length > 0 && data.append('product_var', JSON.stringify(variableProductsList))
    linkedProductList.length > 0 && data.append('related_id', JSON.stringify(linkedProductList))
    filteredObj?.editorShortInfoText && data.append('product_short_desc', filteredObj?.editorShortInfoText)
    filteredObj?.editorGeneralInfoText && data.append('product_desc', filteredObj?.editorGeneralInfoText)
    filteredObj?.seoTitle && data.append('seo_title', filteredObj?.seoTitle)
    filteredObj?.seoDescription && data.append('seo_description', filteredObj?.seoDescription)
    filteredObj?.canonicalUrl && data.append('seo_canonical', filteredObj?.canonicalUrl)
    data.append('db_name', step)
    for (let i = 0; i < filteredObj?.imagesList.length; i++) {
      data.append('images[]', filteredObj?.imagesList[i])
   }
  ;
    noteCardsArray.length > 0 && data.append('message', JSON.stringify(noteCardsArray))
    noteCardsArray.length > 0 && data.append('tip', JSON.stringify([{"good": filteredObj?.prosList, "bad": compressedData.filter((item:any) => item.dbName === step)[0]?.consList}][0]))
    call_api({
      address_api: `${AppConstants.base_url_api}admin/products`,
      method_api: "POST",
      body: data,
      file:true
    })

      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
         
          toast.success(j?.message);
          let ids = [...compressedData]
          let emptyObject =  ids.filter((item:any) => item.dbName === step)[0]
          emptyObject.persianName = "";
          emptyObject.englishName = "";
          emptyObject.productName = "";
          emptyObject.weight = "";
          emptyObject.category = "";
          emptyObject.brandId = "";
          emptyObject.tag = [];
          emptyObject.imagesList=[];
          emptyObject.videosList=[];
          emptyObject.filesList=[]

          emptyObject.linkedProductId = [];
          emptyObject.variableProductId = [];
          emptyObject.editorGeneralInfoText ="";
          emptyObject.editorShortInfoText ="";
          emptyObject.seoTitle ="";
          emptyObject.seoDescription ="";
          emptyObject.canonicalUrl ="";
          emptyObject.noteCardsList =[];
          emptyObject.prosList =[];
          emptyObject.consList =[];
          emptyObject.errorsList=[];
          this.setState({errorsList:[], compressedData: ids })
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
catch (err: any) {

    let ids = [...compressedData];     
    ids.filter((item:any) => item.dbName === step)[0].errorsList = err.inner;
    this.setState({ compressedData: ids});}
  
    
}
 handleDrop = (event: any) => {
  const {compressedData, step} = this.state;
  let ids = [...compressedData];     
  event.preventDefault();
  var fileType = event.dataTransfer.files[0].type;
  var imageTypes = ['image/png', 'image/jpeg', 'image/webp'];
 
  const { files } = event.dataTransfer;
  if((ids.filter((item:any) => item.dbName === step)[0]?.imagesList.some((i:any)=> i.name === event.dataTransfer?.files[0]?.name  ))
  || ids.filter((item:any) => item.dbName === step)[0]?.videosList.some((i:any)=> i.name === event.dataTransfer?.files[0]?.name)
|| ids.filter((item:any) => item.dbName === step)[0]?.filesList.some((i:any)=> i.name === event.dataTransfer?.files[0]?.name))
  {
      alert("  تکراری است!")
  }
 else if( !imageTypes.includes(fileType)){
      alert("فرمت فایل درست نیست!")
  }
   else {
    ids.filter((item:any) => item.dbName === step)[0]?.imagesList.push(files[0]);
    this.setState({compressedData : ids})

   }
};
 handleDragOver = (event: { preventDefault: () => void; }) => {
  event.preventDefault();
}

 handleDragStart = (event: { dataTransfer: { setData: (arg0: string, arg1: any) => void; }; target: { id: any; }; }) => {
  event.dataTransfer.setData("text/plain", event.target.id)
}
 onSelectImage = (e: any) => {
  const {compressedData, step} = this.state;
  let ids = [...compressedData];  
  if((ids.filter((item:any) => item.dbName === step)[0]?.imagesList.find((i:any)=>  i.name === e.target?.files[0]?.name )
  ))
  {
      alert("عکس انتخابی تکراری است!")
  }
  else {
      ids.filter((item:any) => item.dbName === step)[0]?.imagesList.push(e.target?.files[0]);
      this.setState({compressedData : ids})

  }
}

 handleVideoUpload = (event:any) => {
  event.preventDefault();
  const {compressedData, step} = this.state;
  let ids = [...compressedData]; 
  var temp = ".mp4"||".mkv"||".mov";
  
  if(!event.dataTransfer?.files[0].name.includes(temp)){
      
      alert("فایل آپلودشده ویدئو نیست!")
    }
    else if((ids.filter((item:any) => item.dbName === step)[0]?.videosList.some((i:any)=> i.name === event.dataTransfer?.files[0]?.name ))){
          alert("ویدیوی انتخابی تکراری است!")
    }
    else{
      ids.filter((item:any) => item.dbName === step)[0]?.videosList.push(event.dataTransfer?.files[0]);
      this.setState({compressedData : ids})

      
    }

}
 onSelectVideoUpload = (e:any) =>{
  const {compressedData, step} = this.state;
  let ids = [...compressedData]; 
    if((ids.filter((item:any) => item.dbName === step)[0]?.videosList.some((i:any)=> i.name === e.target.files[0]?.name )))
  {
      alert("ویدیوی انتخابی تکراری است!")
  }
  else {
      ids.filter((item:any) => item.dbName === step)[0]?.videosList.push( e.target.files[0]);
      this.setState({compressedData : ids})


   }
}

 handleFileUpload = (event:any)=>{
  event.preventDefault();
  const {compressedData, step} = this.state;
  let ids = [...compressedData]; 
  var temp = ".pdf"||".xlsx";
  if((ids.filter((item:any) => item.dbName === step)[0]?.filesList.some((i:any)=> i.name === event.dataTransfer?.files[0]?.name ))){
          alert("فایل انتخابی تکراری است!")
    }
    else if(!event.dataTransfer?.files[0].name.includes(temp)){
      
      alert("فرمت فایل درست نیست!")
    }
    else{
      ids.filter((item:any) => item.dbName === step)[0]?.filesList.push(event.dataTransfer?.files[0]);
      this.setState({compressedData : ids})

    }
}
 onSelectFileUpload = (e:any) =>{
  var temp = ".pdf"||".xlsx";
  const {compressedData, step} = this.state;
  let ids = [...compressedData]; 
  if((ids.filter((item:any) => item.dbName === step)[0]?.filesList.some((i:any)=> i.name === e.target.files[0]?.name )))
{
  alert("فایل انتخابی تکراری است!")
}
else if(!e.target?.files[0].name.includes(temp)){
      
  alert("فرمت فایل درست نیست!")
}
else {
  ids.filter((item:any) => item.dbName === step)[0]?.filesList.push(e.target.files[0]);
  this.setState({compressedData : ids})
 
}
}
 handleDelete = (index:any, type:string) =>{
  const {compressedData, step} = this.state;
  let ids = [...compressedData];
  
  if(type === "image")
  {
      const list = [...compressedData.filter((item:any) => item.dbName === step)[0]?.imagesList];
      list.splice(index,1)
      ids.filter((item:any) => item.dbName === step)[0].imagesList = list;
      this.setState({compressedData:ids})

  }
  // if(type === "image select")
  // {
  //     const list = [...selectedImage]
  //     list.splice(index,1)
  //     setSelectedImage(list)
  // }
 else if(type === "video")
  {
      const list = [...compressedData.filter((item:any) => item.dbName === step)[0]?.videosList];
      list.splice(index,1);
      ids.filter((item:any) => item.dbName === step)[0].videosList = list;
      this.setState({compressedData:ids});
  }
  // if(type === "video select")
  // {
  //     const list = [...selectedVideo]
  //     list.splice(index,1)
  //     setSelectedVideo(list)
  // }
  else if(type === "file")
  {
      const list = [...compressedData.filter((item:any) => item.dbName === step)[0]?.filesList];
      list.splice(index,1)
      ids.filter((item:any) => item.dbName === step)[0].filesList = list;
      this.setState({compressedData:ids})
  }
  // if(type === "file select")
  // {
  //     const list = [...selectedFile]
  //     list.splice(index,1)
  //     setSelectedFile(list)
  // }
}
  
}

export default AddProductController;