
import { LoadingSpinner, OrderDetails, NotificationsList, LandingCard, Header } from "../../../components";
import {CiCircleMore} from "react-icons/ci";
import { InvoicesCard } from "../../../components";
import {numberWithCommas, convertHtmlToText } from "../../../../core/utils";
import { connect } from "react-redux";
import { fetchSiteList, fetchUserData } from "../../../../Redux/services/generalService";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {menuItems} from "../../../../core/constants";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import moment from 'moment-jalaali';
import { HomeController } from "../../../controllers";
import { Link } from "react-router-dom";

class Home extends HomeController {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getDashboardList();
    this.props.dispatch(fetchSiteList());
    this.props.dispatch(fetchUserData());
  }
  
  render() {
    const { dashboardListData , loading, orderModal, selectedPreFactor,
       selectedComment, selectedOrder, commentsModal, prefactorModal} = this.state;
       const { siteList } = this.props;
       console.log("l", dashboardListData?.preFactorTime?.preFactorToday?.vento + dashboardListData?.preFactorTime?.preFactorToday?.aria
       + dashboardListData?.preFactorTime?.preFactorToday?.souha  + dashboardListData?.preFactorTime?.preFactorToday?.legrand + dashboardListData?.preFactorTime?.preFactorToday?.netran)
    return (
      <HelmetProvider>
      <div className="home">
        
        <Helmet>
                <title>پیشخوان</title>
            </Helmet>
          <Header title="پیشخوان"/>
        {loading ? <LoadingSpinner /> :
        <div>
        <div className="invoices-card-container">
     
          <div className="responsive-column-25">
            <InvoicesCard 
              legrandCount={dashboardListData?.preFactor?.orderConnection?.legrand} 
              ariaCount={dashboardListData?.preFactor?.orderConnection?.aria}
              souhaCount={dashboardListData?.preFactor?.orderConnection?.souha}
              ventoCount={dashboardListData?.preFactor?.orderConnection?.vento}
              netranCount={dashboardListData?.preFactor?.orderConnection?.netran}
              textColor="text-info" borderColor="border-info"
              description="تمام پیش‌فاکتورها"
              totalCount={dashboardListData?.preFactor?.all}/>
          </div>
          <div className="responsive-column-25">
            <InvoicesCard 
              textColor="text-warning" borderColor="border-warning"
              description="پیش‌فاکتور امروز"
              ventoCount={dashboardListData?.preFactorTime?.preFactorToday?.vento ? dashboardListData?.preFactorTime?.preFactorToday?.vento : 0 }
              souhaCount={dashboardListData?.preFactorTime?.preFactorToday?.souha ? dashboardListData?.preFactorTime?.preFactorToday?.souha : 0 }
              ariaCount={dashboardListData?.preFactorTime?.preFactorToday?.aria ? dashboardListData?.preFactorTime?.preFactorToday?.aria : 0 }
              legrandCount={dashboardListData?.preFactorTime?.preFactorToday?.legrand ? dashboardListData?.preFactorTime?.preFactorToday?.legrand : 0 }
              netranCount={dashboardListData?.preFactorTime?.preFactorToday?.netran ? dashboardListData?.preFactorTime?.preFactorToday?.netran : 0}
              totalCount={dashboardListData?.preFactorTime?.preFactorToday?.vento + dashboardListData?.preFactorTime?.preFactorToday?.aria
              + dashboardListData?.preFactorTime?.preFactorToday?.souha  + dashboardListData?.preFactorTime?.preFactorToday?.legrand + dashboardListData?.preFactorTime?.preFactorToday?.netran}
               />
          </div>
          <div className="responsive-column-25">
            <InvoicesCard 

              textColor="text-danger" borderColor="border-danger"
              description="پیش‌فاکتور دیروز"
              ventoCount={dashboardListData?.preFactorTime?.preFactorYesterday?.vento ? dashboardListData?.preFactorTime?.preFactorYesterday?.vento : 0 }
              souhaCount={dashboardListData?.preFactorTime?.preFactorYesterday?.souha ? dashboardListData?.preFactorTime?.preFactorYesterday?.souha : 0 }
              ariaCount={dashboardListData?.preFactorTime?.preFactorYesterday?.aria ? dashboardListData?.preFactorTime?.preFactorYesterday?.aria : 0 }
              legrandCount={dashboardListData?.preFactorTime?.preFactorYesterday?.legrand ? dashboardListData?.preFactorTime?.preFactorYesterday?.legrand : 0 }
              netranCount={dashboardListData?.preFactorTime?.preFactorYesterday?.netran ? dashboardListData?.preFactorTime?.preFactorYesterday?.netran : 0}
              totalCount={dashboardListData?.preFactorTime?.preFactorYesterday?.vento + dashboardListData?.preFactorTime?.preFactorYesterday?.aria
              + dashboardListData?.preFactorTime?.preFactorYesterday?.souha + dashboardListData?.preFactorTime?.preFactorYesterday?.legrand + dashboardListData?.preFactorTime?.preFactorYesterday?.netran}
               />
          </div>
          <div className="responsive-column-25">
            <InvoicesCard 
              textColor="text-success" borderColor="border-success"
              description="پیش فاکتور هفته گذشته"
              ventoCount={dashboardListData?.preFactorTime?.preFactorLastWeek?.vento ? dashboardListData?.preFactorTime?.preFactorLastWeek?.vento : 0 }
              souhaCount={dashboardListData?.preFactorTime?.preFactorLastWeek?.souha ? dashboardListData?.preFactorTime?.preFactorLastWeek?.souha : 0 }
              ariaCount={dashboardListData?.preFactorTime?.preFactorLastWeek?.aria ? dashboardListData?.preFactorTime?.preFactorLastWeek?.aria : 0 }
              legrandCount={dashboardListData?.preFactorTime?.preFactorLastWeek?.legrand ? dashboardListData?.preFactorTime?.preFactorLastWeek?.legrand : 0 }
              netranCount={dashboardListData?.preFactorTime?.preFactorLastWeek?.netran ? dashboardListData?.preFactorTime?.preFactorLastWeek?.netran : 0 }
              totalCount={dashboardListData?.preFactorTime?.preFactorLastWeek?.vento + dashboardListData?.preFactorTime?.preFactorLastWeek?.aria
                + dashboardListData?.preFactorTime?.preFactorLastWeek?.souha + dashboardListData?.preFactorTime?.preFactorLastWeek?.legrand  +  dashboardListData?.preFactorTime?.preFactorLastWeek?.netran           }
               />
          </div>
        </div>
        <Modal
                open={orderModal}
                onClose={() => this.setState({orderModal:false})}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 4,
                  overflowY:"scroll",
                  width: {
                    xxs: "90%",
                    xs: "90%",
                    sm: "90%",
                    md: "80%",
                    lg: "70%",
                    xl: "70%"
                  },
                  height:"fit-content" }}>
                  <Box id="modal-modal-title" sx={{ display: "flex", justifyContent: "space-between", alignItems:"center" }}>
                    <Typography variant="subtitle1" >
                      سفارش
                    </Typography>
                      <img className="modal-logo-img" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === selectedOrder?.db_name)[0]?.value?.logo} alt="..." />
                  </Box>
                  <OrderDetails selectedOrder={selectedOrder} />
                </Box>
        </Modal>

        <Modal
                open={prefactorModal}
                onClose={() => this.setState({prefactorModal:false})}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 4,
                  overflowY:"scroll",
                  width: {
                    xxs: "90%",
                    xs: "90%",
                    sm: "90%",
                    md: "80%",
                    lg: "70%",
                    xl: "70%"
                  },
                  height: 'fit-content',
                }}>
                  <Box id="modal-modal-title" sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="subtitle1" component="h2">
                      پیش‌فاکتور
                    </Typography>
                      <img className="modal-logo-img" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === selectedPreFactor?.db_name)[0]?.value?.logo} alt="..." />
                    </Box>
                  <Grid container sx={{ marginTop: "20px", display: "felx", justifyContent: "space-between", border: "1px solid grey", fontSize:"14px" }}>
                  <div className="table-responsive w-100">
                    <table className="table p-5 text-center">
                      <thead>
                        <tr >
                          <th scope="col">شناسه</th>
                          <th scope="col">محصول</th>
                          <th scope="col">تعداد</th>
                          <th scope="col">قیمت</th>
                          <th scope="col">تخفیف</th>
                          <th scope="col">مبلغ کل</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedPreFactor && selectedPreFactor?.preFactorProducts?.length > 0 && selectedPreFactor?.preFactorProducts?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null).map((item: any, index: number) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td> 
                                <span className="pre-factor-product-name">
                                  <Link to={item?.product_url} target="blank">{item?.product_name}</Link>
                                </span></td>
                              <td>{item?.count}</td>
                              <td>{numberWithCommas(item?.price_by_discount?.price)}</td>
                              <td>{Number(item?.price_by_discount?.discount) < 99 ? `${numberWithCommas( item?.price_by_discount.discount)}%`
                               :`${numberWithCommas( item?.price_by_discount.discount)} تومان ` }</td>
                              <td>{numberWithCommas(item?.price_by_discount?.finalPrice)}</td>
                            </tr>
                          );

                        }
                        )}
                        <tr className="table-warning">
                          <td colSpan={2} >کل</td>
                          <td>{numberWithCommas(selectedPreFactor?.totalCount)}</td>
                          <td>{numberWithCommas(selectedPreFactor?.totalPrice)}</td>
                          <td>{numberWithCommas(selectedPreFactor?.totalDiscount)}</td>
                          <td className="table-danger">{numberWithCommas(selectedPreFactor?.finalPrice)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  </Grid>
                </Box>
        </Modal>
        <Modal
              open={commentsModal}
              onClose={()=>this.setState({commentsModal: false})}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: {
                  xxs: "90%",
                  xs: "90%",
                  sm: "90%",
                  md: "80%",
                  lg: "70%",
                  xl: "70%"
                },
                height: 'max-content',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
              }}>
                <Box id="modal-modal-title" sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="subtitle1">
                    نظرات
                  </Typography>
                      <img className="modal-logo-img" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === selectedComment?.db_name)[0]?.value?.logo} alt="..." />
                    </Box>
                 <div className="comments-container">
                 <div className="w-100 d-flex" style={{ justifyContent: "flex-end", paddingLeft:"25px" }}>
                      <Grid sx={{ backgroundColor: "#f4f4f4", borderRadius: "10px", padding: "15px", maxWidth: "75%" }}>
                        <Typography sx={{ textAlign: "right" }}>
                          <div dangerouslySetInnerHTML={{__html:selectedComment.comment }}></div>

                        </Typography>
                        <Typography sx={{ textAlign: "left", marginTop: "10px" }}>
                          {moment(selectedComment.created_at).format("jYYYY/jM/jD")}
                        </Typography>
                      </Grid>
                 </div>

                {selectedComment && selectedComment?.children?.length > 0 && selectedComment?.children?.map((child: any, index: number) => {
                  return (

                    <>
                    <div className="w-100 d-flex " style={{ justifyContent: "flex-start" , paddingLeft:"25px" }}>
                      <Grid sm={12} sx={{ backgroundColor:"#e5f5ff", borderRadius: "10px", padding: "15px", marginTop: "20px", maxWidth: "75%" }} key={index}>
                        <Typography sx={{ textAlign: "right" }}>
                          <div dangerouslySetInnerHTML={{__html:child.comment }}></div>
                        </Typography>
                        <Typography sx={{ textAlign: "left", marginTop: "10px" }}>
                          {moment(child.created_at).format("jYYYY/jM/jD")}
                        </Typography>
                      </Grid>
                    </div>
                    </>
                  );
                  
                })}
                  <div ref={this.myRef}></div>
                </div>

              </Box>
            </Modal>
        {/* {loading ? (
          <LoadingSpinner />
        ) : ( */}
        {/* <Chart
              options={this.state.options}
              series={this.state.series}
              type="bar"
            />
              </div>
              <div className="col-md-6 col-12">
              <Chart
              options={this.state.options2}
              series={this.state.series2}
              type="donut"
            /> */}
        {/* )} */}
        <div>
          {/* <LableIcon title={"اعلانات"} /> */}
        </div>
        <div className="notifications-list-container">
          <div className="column-50">
            <NotificationsList title="آخرین پیش‌فاکتورها" link={"/prefactor"}>
            <table className="table text-center font-12">
                      <thead>
                        <tr>
                          <th scope="col">سایت</th>
                          <th scope="col">نام و نام خانوادگی</th>
                          <th scope="col">مبلغ نهایی</th>
                          <th scope="col">تاریخ</th>
                          <th scope="col"> جزيیات</th>
                        </tr>
                      </thead>
                      <tbody>
                      {dashboardListData && dashboardListData?.latestPrefactor?.length > 0 && dashboardListData?.latestPrefactor?.map((item: any,index:number) => {
                    return(
                      <tr key={index}>
                          <td><img className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo} alt=""/></td>
                          <td>{item?.full_name ? `${item?.full_name}` : `ثبت نشده` }</td>
                          <td>{`${item?.finalPrice && numberWithCommas(item?.finalPrice)} تومان`}</td>
                          <td>{moment(item?.created_at).format("jYYYY/jM/jD")}</td>
                          <td>{<CiCircleMore className="icon-gray" onClick={()=>this.handlePreFactorModal(item?.id_select)}  />}</td>
                        </tr>
                    );
                  }
                  )}    
                      </tbody>
                    </table>
            </NotificationsList>
          </div>
          <div className="column-50">
            <NotificationsList title="آخرین نظرات" link={"/comments"}>
              <table className="table text-center font-12">
                      <thead>
                        <tr>
                          <th scope="col">سایت</th>
                          <th scope="col">نام و نام خانوادگی</th>
                          <th scope="col">متن پیام</th>
                          <th scope="col">تاریخ</th>
                          <th scope="col"> جزيیات</th>
                        </tr>
                      </thead>
                      <tbody>
                      {dashboardListData && dashboardListData?.latestComment?.length > 0 && dashboardListData?.latestComment?.map((item: any,index:number) => {
                    return(
                      <tr key={index}>
                          
                          <td><img className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo} alt=""/></td>
                          <td>{item?.profile?.full_name ? `${item?.profile?.full_name}` : `ثبت نشده` }</td>
                          <td className="comment">{`${item?.comment}` }</td>
                          <td>{moment(item?.created_at).format("jYYYY/jM/jD")}</td>
                          <td>{<CiCircleMore className="icon-gray" onClick={()=>this.handleCommentModal(item?.id_select)} />}</td>
                        </tr>
                    );
                  }
                  )}
                      </tbody>
              </table>
            </NotificationsList>
          </div>
        </div>
        <div className="invoices-card-container ">
          <div className="responsive-column-25">
            <InvoicesCard 
              textColor="text-info" borderColor="border-info"
              description="تمام سفارشات" 
              ventoCount={dashboardListData?.orderLastAll?.orderConnection?.vento}
              souhaCount={dashboardListData?.orderLastAll?.orderConnection?.souha}
              ariaCount={dashboardListData?.orderLastAll?.orderConnection?.aria}
              netranCount={dashboardListData?.orderLastAll?.orderConnection?.netran}
              legrandCount={dashboardListData?.orderLastAll?.orderConnection?.legrand}
              totalCount={dashboardListData?.orderLastAll?.all}
               />
          </div>

          <div className="responsive-column-25">
            <InvoicesCard 
              textColor="text-success" borderColor="border-success"
              description="سفارشات امروز"
              ventoCount={dashboardListData?.orderTime?.orderToday?.vento}
              souhaCount={dashboardListData?.orderTime?.orderToday?.souha}
              ariaCount={dashboardListData?.orderTime?.orderToday?.aria}
              legrandCount={dashboardListData?.orderTime?.orderToday?.legrand}
              netranCount={dashboardListData?.orderTime?.orderToday?.netran}
              totalCount={dashboardListData?.orderTime?.orderToday?.legrand + dashboardListData?.orderTime?.orderToday?.aria 
              + dashboardListData?.orderTime?.orderToday?.souha + dashboardListData?.orderTime?.orderToday?.vento + dashboardListData?.orderTime?.orderToday?.netran }
               />
          </div>

          <div className="responsive-column-25">
            <InvoicesCard 
              textColor="text-danger" borderColor="border-danger"
              description="سفارشات دیروز"
              ventoCount={dashboardListData?.orderTime?.orderYesterday?.vento}
              souhaCount={dashboardListData?.orderTime?.orderYesterday?.souha}
              ariaCount={dashboardListData?.orderTime?.orderYesterday?.aria}
              legrandCount={dashboardListData?.orderTime?.orderYesterday?.legrand}
              netranCount={dashboardListData?.orderTime?.orderYesterday?.netran}
              totalCount={dashboardListData?.orderTime?.orderYesterday?.vento + dashboardListData?.orderTime?.orderYesterday?.aria 
              + dashboardListData?.orderTime?.orderYesterday?.souha + dashboardListData?.orderTime?.orderYesterday?.legrand + dashboardListData?.orderTime?.orderYesterday?.netran}
               />
          </div>

          <div className="responsive-column-25">
            <InvoicesCard 
              textColor="text-warning" borderColor="border-warning"
              description="سفارشات هفته گذشته"
              ventoCount={dashboardListData?.orderTime?.orderLastWeek?.vento ? dashboardListData?.orderTime?.orderLastWeek?.vento : 0 }
              souhaCount={dashboardListData?.orderTime?.orderLastWeek?.souha ? dashboardListData?.orderTime?.orderLastWeek?.souha : 0 }
              ariaCount={dashboardListData?.orderTime?.orderLastWeek?.aria ? dashboardListData?.orderTime?.orderLastWeek?.aria : 0 }
              netranCount={dashboardListData?.orderTime?.orderLastWeek?.netran ? dashboardListData?.orderTime?.orderLastWeek?.netran : 0}
              legrandCount={dashboardListData?.orderTime?.orderLastWeek?.legrand ? dashboardListData?.orderTime?.orderLastWeek?.legrand : 0 }
              totalCount={dashboardListData?.orderTime?.orderLastWeek?.vento + dashboardListData?.orderTime?.orderLastWeek?.souha +
                dashboardListData?.orderTime?.orderLastWeek?.aria + dashboardListData?.orderTime?.orderLastWeek?.netran + dashboardListData?.orderTime?.orderLastWeek?.legrand}
               />
          </div>
        </div>
        <div className="notifications-list-container">

          <div className="column-50">
            <NotificationsList title="آخرین سفارشات" link={"/orders"}>
             <table className="table text-center font-12">
                      <thead>
                        <tr>
                          <th scope="col">سایت</th>
                          <th scope="col">خریدار</th>
                          <th scope="col">قیمت نهایی</th>
                          <th scope="col">تاریخ</th>
                          <th scope="col"> جزيیات</th>
                        </tr>
                      </thead>
                      <tbody>
                      {dashboardListData && dashboardListData?.latestOrder?.length > 0 && dashboardListData?.latestOrder?.map((item: any,index:number) => {
                    return(
                      <tr key={index}>
                          
                          <td><img className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo} alt=""/></td>
                          <td>{item?.owner}</td>
                          <td>{numberWithCommas(item?.finalPrice)}</td>
                          <td>{moment(item?.time).format("jYYYY/jM/jD")}</td>
                          <td>{<CiCircleMore onClick={()=>this.handleOrderModal(item?.id_select)} className="icon-gray"/>}</td>
                        </tr>
                    );
                  }
                  )}
                      </tbody>
                    </table>
            </NotificationsList>
          </div>
          <div className="column-50">
            
            <NotificationsList title="آخرین کاربران" link={"/users"}>
            <table className="table text-center font-12">
                      <thead>
                        <tr>
                          <th scope="col">سایت</th>
                          <th scope="col">نام و نام خانوادگی</th>
                          <th scope="col">دسترسی</th>
                          <th scope="col">شماره تماس</th>
                          <th scope="col"> تاریخ</th>
                        </tr>
                      </thead>
                      <tbody>
                      {dashboardListData && dashboardListData?.latestUser?.length > 0 && dashboardListData?.latestUser?.map((item: any,index:number) => {
                    return(
                      <tr key={index}>
                          <td><img className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo} alt=""/></td>
                          <td>{item?.profile?.full_name ? `${item?.profile?.full_name}` : `ثبت نشده` }</td>
                          <td>{`${item?.roles?.persian_name}`}</td>
                          <td>{`${item?.mobile}` }</td>
                          <td>{moment(item?.time).format("jYYYY/jM/jD")}</td>
                        </tr>
                    );
                  }
                  )}
                      </tbody>
                    </table>
            </NotificationsList>
          </div>
        </div>
        <div className="notifications-list-container">
          
          <div className="column-50">
            <NotificationsList title="آخرین تیکت‌ها" link={"/tickets"}>
            <table className="table text-center font-12">
                      <thead>
                        <tr>
                          <th scope="col">سایت</th>
                          <th scope="col">نام و نام خانوادگی</th>
                          <th scope="col">متن پیام</th>
                          <th scope="col">شماره تماس</th>
                          <th scope="col"> تاریخ</th>
                        </tr>
                      </thead>
                      <tbody>
                      {dashboardListData && dashboardListData?.latestTicket?.length > 0 && dashboardListData?.latestTicket?.map((item: any,index:number) => {
                    return(
                      <tr key={index}>
                          <td><img className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo} alt=""/></td>
                          <td>{item?.profile?.full_name ? `${item?.profile?.full_name}` : `ثبت نشده` }</td>
                          <td className="comment">{convertHtmlToText(item?.message)}</td>
                          <td>{item?.user?.mobile ? `${item?.user?.mobile}`: "ثبت نشده" }</td>
                          <td>{moment(item?.time).format("jYYYY/jM/jD")}</td>
                        </tr>
                    );
                  }
                  )}
                      </tbody>
                    </table>
            </NotificationsList>
          </div>
        </div>
        <div className="home-landing-card">
          <div className="landing-container">
            {menuItems.map((item:any, index:number)=>(
                item.children.filter((item:any) => (
                  item?.role === "seller" 
                )).map((card:any, index:number)=> (
                  <LandingCard key={index}  myStyle={card?.myStyle} title={card?.title} 
                  route={card?.route}/>
                ))           
                ))}
             </div>
      </div>

        </div>
         
        }

             
    </div>
    </HelmetProvider>
    );
  }
}
const mapStateToProps = (dispatch:any) => {
  return {
    dispatch,
    siteList:dispatch.general.siteList,
    loadingSiteList:dispatch.general.loadingSiteList,
    userData:dispatch.general.userData,
  }
};

export default connect(mapStateToProps)(Home);

