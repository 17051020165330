
import { DropDownIcon, InputIcon, CustomButton , RadioSquare, Header, Option, ButtonTab, SubmissionAlert, AddressInputs } from "../../../components";
import { createTheme , Switch, ThemeProvider} from '@mui/material';
import { InputTextLabel } from "../../../components";
import Select, { CSSObjectWithLabel, components } from "react-select";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { numberWithCommas, totalOrderDiscount, totoalOrderFinalPrice, orderStatus } from "../../../../core/utils";
import Modal from '@mui/material/Modal';
import DatePicker from "react-multi-date-picker"
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import { FaUpload } from "@react-icons/all-files/fa/FaUpload";
import { BsWhatsapp } from "react-icons/bs";
import { MaterialReactTable } from 'material-react-table';
import Pagination from '@mui/material/Pagination';
import moment from 'moment-jalaali';
import {BsPlusCircle} from "react-icons/bs";
import Tooltip from '@mui/material/Tooltip';
import { BsFillTrashFill } from "@react-icons/all-files/bs/BsFillTrashFill";
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import { connect } from "react-redux";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {SubmitOrderController} from "../../../controllers";
import { AiOutlineEdit } from "react-icons/ai";
import { FiAlertTriangle } from "react-icons/fi";
import { crudColumns } from "../../../../core/column";
class SubmitOrder extends SubmitOrderController {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const {search,productModal,productListData,isCreating, loadingUpdateProfile, userLoading , openNewUserModal,
       whatsapp, phone, mobile, errorsList, rowCount, rowCountList, total, firstName, lastName, rows, loading, 
       usersAddressList, selectedOrderStatusDropDown, selectedPaymentStatus, openEditAddressAlert, date, editAddressData,
       page, step, paymentMethodStep, recieverName, openEditAdress, recieverPhone, address, inPerson, addressData,
       addressError, dreiverId,generalDiscount, shippingAmount, paymentImageUpload, sendingStatus, toggleAddressPage,
       profileListData, userSelected} = this.state;
      const theme = createTheme({
        typography: {
          fontFamily: 
            'Vazir',
          
        }    });    
        const { siteList , loadingSiteList} = this.props;
      const filterOption = (option: any, inputValue: any): boolean =>
      {
        return (option.label?.match(inputValue) || option?.data?.mobile?.match(inputValue) || [])?.length > 0;
      }
      const ShowIdDropDown = (props: any) =>{
        return (
          
            <components.Option {...props} >
           <span>{props?.data?.id}</span> 
           <components.Option {...props} />
           <span>{props?.data?.mobile}</span>
           </components.Option>
          
        );
      }       
    return (
      <HelmetProvider>
      <ThemeProvider theme={theme}>
      <div className="submit-order">   
            <Helmet>
                <title> سفارش جدید  </title>
            </Helmet>

            <Header title="سفارش جدید" />
              <div className="custom-card">
              <div className="filter-select-container pe-3">
              <div className="filter-options-container max-height-65">
                <div className="column-30 mt-2">
                <Select
                      className="site-select"
                      styles={{
                        control: (baseStyles, state):CSSObjectWithLabel  => ({
                          ...baseStyles,
                          boxShadow:"rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",
                          borderWidth: "1px",
                          backgroundColor: "#fff",
                          display: "flex",
                          flexDirection: "row-reverse",
                          textAlign: "right",
                          borderRadius: "4px",
                          height:"35px",
                          minHeight:"20px",
                          outline: "unset",
                          width:"100%"
                        }) as CSSObjectWithLabel,
                      }}
                      onChange={(choice) => this.driverSelected(choice)}
                      components={{ Option }}
                      value={dreiverId}
                      closeMenuOnSelect={true}
                      noOptionsMessage={() => 'این مورد وجود ندارد!'}
                      menuPortalTarget={document.body}
                      options={siteList }
                      getOptionLabel={(option: any) => option?.value?.persian_name}
                      getOptionValue={(option: any) => option?.value?.name}
                      placeholder="سایت"
                      isClearable
                      loadingMessage={() => 'در حال بارگذاری  ...'}
                      isLoading={loadingSiteList}
                    />
                </div>
                  <div className="column-30 two-section bottom-14">
                    <div className="add-user-btn">
                <Tooltip title="اضافه کردن کاربر"><span>  <BsPlusCircle className="icon-green" onClick={ ()=> this.setState({ openNewUserModal: true })} /></span></Tooltip>
                    </div>

                  <Modal
                      open={openNewUserModal}
                      onClose={this.handleCloseNewUserModal}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                     <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        width: {
                          xxs: "90%",
                          xs: "90%",
                          sm: "90%",
                          md: "80%",
                          lg: "70%",
                          xl: "70%"
                        },
                        height: 'max-content',
                        display: 'block'
                      }}>
                        <Typography id="modal-modal-title" variant="subtitle1" sx={{fontSize:"14px", marginBottom:"10px"}}>
                       ایجاد کاربر جدید                   
                        </Typography>
                        <Grid container sx={{display:"flex", justifyContent:"center"}}>
                    </Grid>
                        <Grid container sx={{display:"flex", justifyContent:"space-between", padding:"10px"}} >
                        <Grid item={true} sm={12} lg={3} sx={{paddingBottom:"25px"}}>
                  <Select
                    styles={{
                      control: (baseStyles, state):CSSObjectWithLabel => ({
                        ...baseStyles,
                        boxShadow: "rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",
                        borderWidth: "1px",
                        backgroundColor: "#fff",
                        display: "flex",
                        flexDirection: "row-reverse",
                        textAlign: "right",
                        borderRadius: "4px",
                        height:"35px",
                        outline: "unset",
                      }) as CSSObjectWithLabel,
                    }}
                    onChange={(choice:any) => this.driverSelected(choice)}
                    components={{Option}}
                    value={dreiverId}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    noOptionsMessage={() => 'این مورد وجود ندارد!'}
                    options={siteList}
                    getOptionLabel={(option: any) => option?.value?.persian_name}
                    getOptionValue={(option: any) => option?.value?.name}
                    placeholder="سایت"
                    isClearable={false}
                    name="dreiverId"
                    loadingMessage={() => 'در حال بارگذاری  ...'}
                    isLoading={loadingSiteList}
                    
                  />
                  <span className="input-icon-error">
                    {errorsList && errorsList?.filter((obj: any) => obj.path === "dreiverId")[0]?.message}
                  </span>
                </Grid>
                      <Grid item={true} sm={12} lg={3}>
                      <InputIcon
                                value={mobile}
                                title={"موبایل "}
                                inBox={"موبایل"}
                                type={"number"}
                                name={"mobile"}
                                errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "mobile")[0]?.message}
                                onChange={(event) =>
                                  this.setState({
                                    mobile: event.currentTarget.value,
                                  })
                                }
                              />

                      </Grid>
                      <Grid item={true}  sm={12} lg={3}>
                      <InputIcon
                              value={firstName}
                              title={"نام "}
                              inBox={"نام  "}
                              type={"text"}
                              name="firstName"
                              errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "firstName")[0]?.message}
                              onChange={(event) =>
                                this.setState({
                                  firstName: event.currentTarget.value,
                                })
                              }
                            />

                      </Grid>
                   
                        </Grid>
                        <Grid container sx={{display:"flex", justifyContent:"space-between", padding:"10px"}} >
                        <Grid item={true}  sm={12} lg={3}>
                      <InputIcon
                              value={lastName}
                              title={"نام خانوادگی  "}
                              inBox={"نام خانوادگی  "}
                              errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "lastName")[0]?.message}
                              type={"text"}
                              name="lastName"
                              onChange={(event) =>
                                this.setState({
                                  lastName: event.currentTarget.value,
                                })
                              }
                            />
                      </Grid>
                        <Grid item={true}  sm={12} lg={3}>
                        <InputIcon
                          value={phone}
                          title={"تلفن "}
                          inBox={"تلفن "}
                          type={"number"}
                          name="phone"
                          errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "phone")[0]?.message}
                          onChange={(event) =>
                            this.setState({ phone: event.currentTarget.value })
                          }
                        />

                      </Grid>
                      <Grid item={true}  sm={12} lg={3}>
                        <InputIcon
                            value={whatsapp}
                            title={"واتس اپ "}
                            errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "whatsapp")[0]?.message}
                            inBox={<BsWhatsapp />}
                            type={"number"}
                            name="whatsapp"
                            onChange={(event) =>
                              this.setState({
                                whatsapp: event.currentTarget.value,
                              })
                            }
                          />

                      </Grid>
                        </Grid>
                        <div className="btn-container">
                        <CustomButton
                          myStyle="submit-btn"
                          loading={loadingUpdateProfile}
                          onClick={()=>this.createNewUser()}
                          title="ایجاد کاربر جدید "
                    />
                        </div>
                      </Box>
                  </Modal>
                  <Select
                      className="profile-select"
                      styles={{
                        control: (baseStyles, state): any => ({
                          ...baseStyles,
                          boxShadow: state.isFocused
                            ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                            : "",
                          borderWidth: "1px",
                          backgroundColor: `${dreiverId && Object.keys(dreiverId).length > 0  ? "#fff" : "#f7f8fa"}`,
                          display: "flex",
                          flexDirection: "row-reverse",
                          textAlign: "right",
                          borderRadius: "4px",
                          height:"38px",
                          marginTop:"2px",
                          minHeight:"20px",
                          outline: "unset",
                        }),
                        option: (styles, {isSelected}):any => ({
                          ...styles,
                          background: 
                              isSelected
                                  ? 'light-blue'
                                  : undefined,
                          display:"flex",
                          padding: "2px",
                          justifyContent: "space-around",
                          alignItems:"center"
                      }),
                      placeholder:(styles):any => ({
                        ...styles,
                       fontSize:"14px"
                      })
                    }}
                      
                      isDisabled={dreiverId && Object.keys(dreiverId).length > 0  ? false : true}
                      onInputChange={(choice) => this.handleSearchUser(choice)}
                      onChange={(choice) => this.userSelected(choice)}
                      components={{ Option:ShowIdDropDown }}
                      value={userSelected}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      isSearchable={true}
                      options={profileListData}
                      menuPortalTarget={document.body}
                      noOptionsMessage={() => 'این مورد وجود ندارد!'}
                      getOptionLabel={(option: any) => option.profile?.full_name}
                      getOptionValue={(option: any) => option?.id}
                      placeholder="شماره یا نام خانوادگی کاربر"
                      isClearable
                      filterOption={filterOption}
                      isLoading={userLoading}
                      loadingMessage={() => 'در حال بارگذاری  ...'}
                    />
                            <span className="input-icon-error">
                              {errorsList && errorsList?.filter((obj: any) => obj.path === "userSelected")[0]?.message}
                            </span>
                             
                  </div>
                  <div className="column-30 mt-8">
                     <InputIcon
                        value={userSelected?.mobile || ""}
                        title="شماره  همراه کاربر"
                        type={"text"}
                        disabled={true}
                        
                      />
                  </div>
                  
                  </div>
                </div>
              <Modal
                open={toggleAddressPage}
                onClose={this.handleCloseAddressModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  overflowY:"scroll",
                  width: {
                    xxs: "90%",
                    xs: "90%",
                    sm: "90%",
                    md: "80%",
                    lg: "70%",
                    xl: "70%"
                  },
                  height: 'fit-content',
                  p: 4,
                }}>
                  <Typography id="modal-modal-title" variant="subtitle1" sx={{fontSize:"14px", marginBottom:"10px"}}>
                   افزودن آدرس                  
                  </Typography> 
                  <Grid container>
                    <ul>
                    {errorsList.map((i:any, index:any)=>{
                      return(
                        <li className="error-text" key={index}>
                        {i.message}
                      </li>
                      )
                    
                    })}
                    </ul>
                  </Grid>
                  <AddressInputs addAddress={(argOne: any) =>                       
                        this.addAddress(argOne)
                      }/>
                </Box>
              </Modal>
                  {openEditAddressAlert &&
                  <SubmissionAlert
                  isOpen={openEditAddressAlert}
                  title="اخطار!"
                  setClose={(open: boolean) =>
                  this.setState({
                    openEditAddressAlert: open,
                  })
                  }
                  onClick={() => this.handleConfirmEdition()}
                  icon={<FiAlertTriangle color="orange" fontSize="50" />}
                  details={`آیا از ویراش آدرس شخص ${userSelected?.profile?.full_name} با شناسه ${editAddressData?.id}  اطمینان دارید؟`}
                  />
                }
            <Modal
              open={openEditAdress}
              onClose={this.handleCloseAddressEditModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: {
                  xxs: "90%",
                  xs: "90%",
                  sm: "90%",
                  md: "80%",
                  lg: "70%",
                  xl: "70%"
                },
                height: 'max-content',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
              }}>
                <Box id="modal-modal-title" sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="subtitle1">
                    ویراش آدرس
                  </Typography>
                </Box>
                <AddressInputs addressDetail={addressData} addAddress={(argOne: any) =>                       
                        this.handleEdit(argOne)
                      }/>
              </Box>
            </Modal>
            {dreiverId === null ? <div></div>
            :
            <>
              <div className="pannel-conteiner">
              <div className="btn-container">
                  {selectedPaymentStatus ?
              <>
               <input
                  name=""
                  type="file" id="file"
                  accept="image/*"
                  hidden
                  onChange={(event) =>
                    this.handlePaymentImageUpload(event)
                  }
                />
              <label htmlFor="file"  className="payment-pic">
               
                <div className="upload-pic">
                  {paymentImageUpload.name ? <div>{paymentImageUpload.name}</div>
                  :
                  <>
                  <FaUpload className="upload-pic-icon"/>
                 <span className="font-12">بارگذاری فیش‌واریزی</span>
                 </>
                  }
                 
                 </div>
                </label>
                <span className="input-icon-error">
                    {errorsList && errorsList?.filter((obj: any) => obj.path === "paymentImageUpload")[0]?.message}
                </span>
                </>
                :
                ""
                }
                  <CustomButton
                    myStyle={`submit-btn-order ${selectedPaymentStatus && "submit-50"}`} 
                    loading={isCreating}
                    onClick={()=>this.order()}
                    title="ثبت سفارش"
                  />
                </div>
                
              
                <div className="pannel">
                    <label className="title">اطلاعات ارسال و گیرنده</label>

                        <div className="column-section">
                        <div className="item">
                          <InputIcon
                            value={recieverName}
                            inBox={"نام و نام خانوادگی گیرنده"}
                            placeholder={"نام و نام خانوادگی گیرنده"}
                            type={"text"}
                            errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "recieverName")[0]?.message}
                            onChange={(event) =>
                              this.setState({
                                recieverName: event.currentTarget.value,
                              })
                            }
                          disabled={dreiverId && Object.keys(dreiverId).length > 0  ? false : true}
                          />
                          </div>   
                          <div className="item justify-start">
                            <FormControlLabel sx={{direction:"ltr", marginRight:"0px", flexDirection:"column"}} control={<Switch
                                checked={inPerson}
                                onChange={this.handleTakingOrder}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />} 
                            label={<Typography sx={{whiteSpace:"nowrap", fontSize:"12px"}} variant="subtitle2">دریافت حضوری</Typography>}
                            />                    
                            {sendingStatus === "send" && userSelected  && 
                              <div className="button-with-alert">
                              <BsPlusCircle className="icon-green" onClick={ this.handleNewAddress} />
                              <label>افزودن آدرس</label>
                              </div>
                            }
{sendingStatus === "send" && 
                            <div className="error-part">
                              {userSelected ? <div>
                            <div>
                              {usersAddressList.length === 0 ? "برای کاربر موردنظر آدرسی ثبت نشده‌است." : ""}
                          
                          {errorsList && errorsList?.filter((obj: any) => obj.path === "address")[0]?.message}
                            </div>
                            </div> : 
                              <div className="alert-for-address">برای مشاهدهٔ آدرس‌ها، یک کاربر را انتخاب کنید.</div>
                            }
                            </div>
                            }

                          <FormControl>
                          <RadioGroup
                            sx={{display:"flex"}}
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={address}
                            onChange={this.handleAddress}
                            >   
                          {sendingStatus === "send" && usersAddressList?.length > 0 && usersAddressList.map((data:any, index:number) => {
                          return (
                            <FormControlLabel value={data?.id} control={<Radio />} label={<Box sx={{display:"flex", justifyContent:"space-between"}}><Typography sx={{fontSize:"12px"}} variant="subtitle2"><span className="color-blue">{data?.label} </span>{`${data?.address}`}
                              <Tooltip title="ویرایش آدرس"><span><AiOutlineEdit className="icon-green" onClick={() => this.handleOpenEditAddressModal(data)}/></span></Tooltip></Typography></Box>} />
                            )})}
                            {addressError && <span className="error-text">انتخاب آدرس الزامی است.</span>}
                        </RadioGroup>
                        </FormControl>
                          </div> 
                          <div className="mt-5 item justify-start">
                          <FormControlLabel sx={{direction:"ltr", marginRight:"0px", flexDirection:"column"}} control={<Switch
                                          checked={selectedPaymentStatus}
                                          onChange={(event:any)=> this.setState({ selectedPaymentStatus: event.target.checked })}
                                          name={"selectedPaymentStatus"}
                                          inputProps={{ 'aria-label': 'controlled' }}
                                      />} 
                                      label={<Typography sx={{whiteSpace:"nowrap", fontSize:"12px"}} variant="subtitle2">پرداخت‌شده</Typography>}
                                      />  
                            { selectedPaymentStatus &&
                              <ButtonTab
                                  title= "کارت به کارت"
                                  myStyle="payment-method-btn"
                                  isActive={paymentMethodStep === 0 ? true : false}
                                  onClick={() =>{}}
                                  />
                            }                     
                            
                          </div>
                        </div>
                        <div className="column-section">
                        <div className="item">
                        <InputIcon
                            value={recieverPhone}
                            title={"شماره گیرنده"}
                            placeholder={"شماره گیرنده"}
                            inBox={"شماره گیرنده"}
                            errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "recieverPhone")[0]?.message}
                            type={"number"}
                            onChange={(event) =>
                              this.setState({
                                recieverPhone: event.currentTarget.value,
                              })
                            }
                            disabled={dreiverId && Object.keys(dreiverId).length > 0  ? false : true}/>
                        </div>
                        <div className="item">
                          
                          <DatePicker 
                            className="rmdp-prime"
                            inputClass="date-picker"
                            editable={false}
                            minDate={moment(new Date().setDate(new Date().getDate() + 1)).format("jYYYY/jM/jD")}
                            maxDate={moment(new Date().setDate(new Date().getDate() + 7)).format("jYYYY/jM/jD")}
                            mapDays={({ date }) => {
                              let isWeekend = [6].includes(date.weekDay.index)
                              if (isWeekend) return {
                                disabled: true,
                                style: { color: "#ccc" },
                                onClick: () => alert("آخر هفته‌ها غیرفعال هستند")
                              }
                            }}
                            value={date}
                            disabled={dreiverId && Object.keys(dreiverId).length > 0  ? false : true}
                            onChange={(date:any) => {
                              this.handleDate(date);
                            }}
                            placeholder="انتخاب بازه زمانی"
                            calendar={persian}
                            locale={persian_fa}
                          >
  
                          </DatePicker>
                    
                          </div>
                        <div className="item"  >

                        <ButtonTab
                        title= "۱۰ الی ۱۳"
                        myStyle="hour-btn"
                        isActive={step === 0 ? true : false}
                        onClick={() => this.handleSetStep(0)}
                        />

                        <ButtonTab
                        title= "۱۳ الی ۱۶"
                        isActive={step === 1 ? true : false}
                        onClick={() => this.handleSetStep(1)}
                        myStyle="hour-btn"

                        />
                        <ButtonTab
                        title= "۱۶ الی ۱۹"
                        isActive={step === 2 ? true : false}
                        onClick={() => this.handleSetStep(2)}
                        myStyle="hour-btn m-t-responsive-1"

                        />
                        </div>
                        <div className="mt-4">
                        <DropDownIcon
                              value={selectedOrderStatusDropDown}
                              title={"وضعیت سفارش"}
                              inBox={"وضعیت سفارش"}
                              type={"text"}
                              optionList={orderStatus.filter((i:any)=> selectedPaymentStatus ?  i.value !== 0 : {})}
                              onChange={(event) =>
                                this.setState({
                                  selectedOrderStatusDropDown: event.currentTarget.value,
                                })
                              }
                               myStyle="w-100 "
                            />
                        </div>
                        </div>
                </div>
              </div>
              <div className="main-card">
                <div className="table-responsive w-100">
                <table  className="table">
                  <thead>
                    <tr>
                      <th >شناسه</th>
                      <th>محصول</th>
                      <th>تعداد</th>
                      <th>قیمت</th>
                      <th>تخفیف</th>
                      <th>مبلغ کل</th>
                      <th>عملیات</th>
                    </tr>
                  </thead>
                  <tbody >
                    {rows.map((rowsData: any, index: number) => {
                      return (
                        <>
                        <tr key={index}>
                          <td>
                            <span className="squere">{rowsData.product_id}</span>
                          </td>
                          <td>
                           <span className="squere">{rowsData.product_name}</span>
                          </td>
                          <td>
                            <input
                              type="number"
                              value={rowsData.count}
                              onChange={(event) => this.onValUpdate(index, event)}
                              name="count"
                              min="1"
                              className="form-control dir-ltr"
                            />
                          </td>
                          <td>
                            <input
                              type="txet"
                              value={numberWithCommas(rowsData?.price)}
                              onChange={(event) => this.onValUpdate(index, event)}
                              name="price"
                              min="0"
                              className="form-control dir-ltr"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={numberWithCommas(rowsData?.discount)}
                              onChange={(event) => this.onValUpdate(index, event)}
                              name="discount"
                              min="0"
                              className="form-control dir-ltr"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={numberWithCommas(rowsData?.finalPrice)}
                              disabled={true}
                              name="finalPrice"
                              className="form-control"
                            />
                          </td>
                          
                          <td>
                          <Tooltip title="حذف سفارش"><span>  <BsFillTrashFill className="delete-icon" onClick={() => this.tableRowRemove(index)}/></span></Tooltip>
                          </td>
                        </tr>
                        <tr><td colSpan={7} className="error-text" >{rowsData.error}</td></tr>
                        </>
                      );
                    })
                    }
                    <tr className="w-100">
                   <td colSpan={7}>
                   <BsPlusCircle className="icon-green" onClick={() => this.openProductModal()}/>
                   </td>
                        </tr>
                    <tr>
                      <td width="500px" colSpan={4}>
                        جمع کل
                      </td>
                      <td width="500px"  className="table-success" colSpan={4}>
                      {rows && numberWithCommas(totoalOrderFinalPrice(rows))}
                      </td>
                    </tr>
                    <tr>
                      <th colSpan={4}>تخفیف</th>
                      <td colSpan={4}>{numberWithCommas(totalOrderDiscount(rows))}</td>
                    </tr>
                    {sendingStatus === "send" &&
                    <tr>
                      <th colSpan={4}>مبلغ حمل و نقل</th>
                      <td colSpan={4}><input
                        type="text"
                        min="0"
                        value={numberWithCommas(shippingAmount)}
                        onChange={(event) => {
                          var value = (event.target.value).replace(/,/g, '');
                          const re = /^[0-9\b]+$/;
                          if (value === '' || re.test(value)) {
                          this.setState({ shippingAmount: value })}
                        }
                      }
                        className="form-control"
                      /></td>
                    </tr>
                    }
                    <tr>
                      <th colSpan={4}>تخفیف کلی روی فاکتور</th>
                      <td colSpan={4}>
                        <input
                        type="text"
                        min="0"
                        value={numberWithCommas(this.state.generalDiscount)}
                        className="form-control"
                        onChange={(event) => {
                          var value = (event.target.value).replace(/,/g, '');
                          const re = /^[0-9\b]+$/;
                          if ((value === '' || re.test(value)) && Number(value) <= ((totoalOrderFinalPrice(rows) + Number(shippingAmount )))) {
                          this.setState({ generalDiscount: value })}
                        }
                      }
                      />
                      </td>
                  </tr>
                  <tr className="table-success ">
                    <th colSpan={4}>مبلغ نهایی</th>
                    <td colSpan={4}>{rows && numberWithCommas((totoalOrderFinalPrice(rows) + Number(shippingAmount )) - Number(generalDiscount))}</td>
                  </tr>
                  </tbody>
                </table>
                </div>
              </div>
               <Modal
                      open={productModal}
                      onClose={this.handleCloseProductModal}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                     <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        width: {
                          xxs: "90%",
                          xs: "90%",
                          sm: "90%",
                          md: "80%",
                          lg: "70%",
                          xl: "70%"
                        },
                        height: 'max-content',
                        display: 'block'
                      }}>
                        <Typography id="modal-modal-title" variant="subtitle1" sx={{fontSize:"14px", marginBottom:"10px"}}>
                            محصولات                           
                        </Typography>
                        <div className="submit-order-search-input">
                  <InputTextLabel
                    type={"text"}
                    value={search}
                    onChange={(event) => this.handleSearch(event)}
                    placeholder="جستجو..."
                  />
                </div>

                <MaterialReactTable
                muiTablePaperProps={{
                  sx: { mr: '1.5rem',ml: '1.5rem', border:"1px solid #e5e5e5", mt:"1.3rem" },
                }}
                muiTableBodyRowProps={({ row, staticRowIndex }) => ({
                  sx: {
                    height: "fit-content",
                    backgroundColor: rows.find(
                      (c: { product_id: any }) => c.product_id === row.original?.item?.id
                    )
                      ? "#d1ecfb"
                      : (staticRowIndex % 2 === 0 ) ? '#f3f3f3'  : "",
                  },
                })}
                  columns={crudColumns}
                  data={productListData && productListData?.length > 0 && productListData?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null).map((item: any, index: number) => {
                    return ({
                      item: item,
                      row: index + 1,
                      select: (
                        <div>
                          <RadioSquare
                            title={""}
                            onClick={() => this.handleAdd(item)}
                            isActive={
                              rows.find(
                                (c: { product_id: any }) => c.product_id === item?.id
                              )
                                ? true
                                : false
                            }
                          />
                          <div>{item.id}</div>
                        </div>
                      ),
                      driver:<img className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo} alt=""/> ,
                      name: item.product_name,
                      price: numberWithCommas(item?.price_by_discount?.price),
                      percent: item?.price_by_discount?.type === "percent" ? `% ${item?.price_by_discount?.discount}` : numberWithCommas(item?.price_by_discount?.discount),
                      finalPrice: numberWithCommas(item?.price_by_discount?.finalPrice),
                    }
                    );
                  }
                  )}
                  muiTableProps={{
                    sx: {
                      border: "1px solid #e5e5e5",
                    },
                  }}
                  muiTableHeadCellProps={{
                    sx: {
                      border: "1px solid #e5e5e5",
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      border: "1px solid #e5e5e5",
                      textAlign:"center"

                    },
                  }}
                  positionPagination="bottom"

                  state={{isLoading:loading, showProgressBars:loading, sorting: [
                    {
                      id: 'row',
                      desc: false,

                    },

                  ],}}
                  enablePagination={false}
                renderBottomToolbarCustomActions={({table})=>{
                  return (
                    <div className="custom-pagination w-100">
                      <Pagination
                      page={page}
                            count={Math.ceil(total / rowCount)}
                            onChange={this.handleChangePage}
                          
                          />
                          <DropDownIcon
                            value={rowCount}
                            optionList={rowCountList}
                            title={""}
                            onChange={(event) => {
                              this.handlePaginationSelect(event);
                            }}
                          />
                    </div>
                  );
                }}
                positionExpandColumn={'last'}
                enableGlobalFilter={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableHiding={false}
                enableColumnActions={false}
                  initialState={{
                    sorting: [
                      {
                        id: "price",
                        desc: true
                      },
                        {
                          id: 'date',
                          desc: true,
  
                        },
                    ],
                  }}
                  localization={{
                    noRecordsToDisplay:"داده‌ای برای نمایش وجود ندارد!"
                  }}
                  positionActionsColumn={'first'}
                />

                      </Box>
                  </Modal>
            </>
            }
        </div>
      </div>
      </ThemeProvider >
      </HelmetProvider>
    );
  }
}
const mapStateToProps = (dispatch:any) => {
  return {
    dispatch,
    siteList:dispatch.general.siteList,
    loadingSiteList:dispatch.general.loadingSiteList,
  }
};
export default connect(mapStateToProps)(SubmitOrder);
