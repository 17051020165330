
import { LoadingSpinner, Header, InputIcon, Checkbox, CustomButton, CustomContainer } from "../../../components";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { GeneralSettingsController } from "../../../controllers";

class GeneralSettings extends GeneralSettingsController {
  componentDidMount() {
    this.getDate()
  }

  render() {
    const { loading, siteTitle,
      siteAddress,
      canonicalAddress,
      H1HomePage,
      shortIntroduction, siteUpdateMode,
      notDisplayable, errorsList } = this.state;

    return (
      <>
        <Helmet>
          <title> تنظیمات عمومی</title>
        </Helmet>
        <Header title=" تنظیمات عمومی" />
        {loading ? <LoadingSpinner /> : <>
          {/* <LableIcon title=" تنظیمات عمومی" icon={<BsStars />} /> */}
          <CustomContainer>
            <div className="content">
              <div className="col-md-6 col-sm-12 pe-3">
                <InputIcon
                  value={siteTitle}
                  title="عنوان سایت"
                  inBox="عنوان سایت"
                  type={"text"}
                  name={"siteTitle"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "siteTitle")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      siteTitle: event.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="col-md-6 col-sm-12 pe-3">
                <InputIcon
                  value={siteAddress}
                  title="آدرس سایت"
                  inBox="آدرس سایت"
                  type={"text"}
                  name={"siteAddress"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "siteAddress")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      siteAddress: event.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="col-md-6 col-sm-12 pe-3">
                <InputIcon
                  value={canonicalAddress}
                  title="آدرس کانونیکال"
                  inBox="آدرس کانونیکال"
                  type={"text"}
                  name={"canonicalAddress"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "canonicalAddress")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      canonicalAddress: event.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="col-md-6 col-sm-12 pe-3">
                <InputIcon
                  value={H1HomePage}
                  title="H1 صفحه اصلی "
                  inBox="H1 صفحه اصلی "
                  type={"text"}
                  name={"H1HomePage"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "H1HomePage")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      H1HomePage: event.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="col-md-6 col-sm-12 pe-3">
                <InputIcon
                  value={shortIntroduction}
                  title="معرفی کوتاه"
                  inBox="معرفی کوتاه"
                  type={"text"}
                  name={"shortIntroduction"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "shortIntroduction")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      shortIntroduction: event.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="col-md-6 col-sm-12 d-flex justify-content-center pe-3">
                <div className="col-md-6 col-sm-12">
                  <Checkbox
                    title={"حالت بروزرسانی سایت"}
                    onClick={(event: { currentTarget: { checked: any; }; }) => this.setState({
                      siteUpdateMode: event.currentTarget.checked,
                    })}
                    isActive={siteUpdateMode}
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <Checkbox
                    title={"غیر قابل نمایش برای موتورهای جستجو"}
                    onClick={(event: { currentTarget: { checked: any; }; }) => this.setState({
                      notDisplayable: event.currentTarget.checked,
                    })}
                    isActive={notDisplayable}
                  />
                </div>

              </div>
              <CustomButton
                myStyle="btn-confirm"
                loading={false}
                onClick={() => { this.update() }}
                title="بروزرسانی "
              />
            </div>

          </CustomContainer>
        </>
        }


      </>
    );
  }
}
const mapStateToProps = (dispatch: any) => {
  return {
    dispatch,
    siteList: dispatch.general.siteList,
    loadingSiteList: dispatch.general.loadingSiteList,
    userData: dispatch.general.userData,
  }
};
export default connect(mapStateToProps)(GeneralSettings);
