import { Component } from 'react';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { createRef } from "react";
import { AppConstants } from '../../../core/constants';
import { call_api, parseJSON } from '../../../core/service';
import { toast } from 'react-toastify';

interface IProps {
  dispatch?:any,
  siteList?:any,
  loadingSiteList?:any,
}

interface IState {
  loading?: boolean,
  orderLoading?: boolean,
  search: string,
  rows: Array<any>,
  fileType: string,
  fileExtension: string,
  rowData: any,
  page: number,
  rowCount:any,
  rowCountList:any,
  paginate: number,
  allOption:any,
  showFilters:any,
  errorsList?: any,
  dreiverId?:any,
  logsList?:any;
  total?:number;
  filterId:any
}
 class SystemLogsController extends Component<IProps, IState> {
  rowVirtualizerInstanceRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    this.rowVirtualizerInstanceRef = createRef()
    this.state = {
      loading: false,
      orderLoading: false,
      search: "",
      rows: [],
      fileType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      fileExtension: ".xlsx",
      rowData: {},
      page: 1,
      rowCount:100,
      rowCountList:[10, 25 , 50 , 100],
      paginate: 0,
      allOption:{id:0 ,value:{
        persian_name: "همه",
        name: "*"
      }},
      showFilters:false,
      errorsList: [],
      dreiverId: [],
      logsList:[],
      total:0,
      filterId:null
    }
  }



  paginationSelectTimeOut: string | number | NodeJS.Timeout | undefined; 
  handlePaginationSelect=(event:any)=>{
    this.setState({
      rowCount: event.currentTarget.value,
      page:1
    })
    clearTimeout(this.paginationSelectTimeOut);
    this.paginationSelectTimeOut = setTimeout(() => {
      this.getDate()
    }, 800);
    
  }
  driverTimeout: string | number | NodeJS.Timeout | undefined;
  driverSelected = (selected:any, index?:any) : any=> {
    this.setState({ page: 1 })
    if(index?.option?.id === 0)
    {
      this.setState({
        dreiverId: this.props.siteList
      });

    }
    else 
    this.setState({
      dreiverId: selected
    });
    clearTimeout(this.driverTimeout);
    this.driverTimeout = setTimeout(() => {
      this.getDate()
    }, 1000);
  }
  filterTimeout: string | number | NodeJS.Timeout | undefined;
  filterSelected = (selected:any, index?:any) : any=> {
      this.setState({
        filterId: selected
      });
    clearTimeout(this.filterTimeout);
    this.filterTimeout = setTimeout(() => {
      this.getDate()
    }, 1000);
  }
  searchTimeout: string | number | NodeJS.Timeout | undefined;
  handleSearch = (event: any) => {
    this.setState({ search: event.currentTarget.value })
    clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.getDate()
      }, 1000);
  };

  exportToExcel = async (excelData: any, fileName: any) => {
    const { fileType, fileExtension } = this.state;

    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  
  handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ page: value })
    this.getDate(value);
  };
  async getDate(page?:number) {
    const {search , rowCount , dreiverId , filterId} = this.state;
    const driversSelectedList = [];
    for (var i = 0; i < dreiverId.length; i++) {
      driversSelectedList.push(dreiverId[i]?.value?.name)
      // this.setState({ usersUuid: driversSelectedList })
    }
    this.setState({ loading: true })
    let parameters = {
      search: search ? search : null , 
      type:filterId? filterId.val : null,
      page: page ? page.toString() : null,
      paginate: rowCount,
      db_name: dreiverId?.length > 0 ? driversSelectedList.toString() : null,
    }
    let url = new URL(`${AppConstants.base_url_api}admin/logs`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}admin/logs${url.search}` : `${AppConstants.base_url_api}admin/logs`,
      method_api: "GET",
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
            logsList:j.data?.data,
            loading: false,
            total: j.data?.total 
          })

        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };


}

export default SystemLogsController;