import { Component, createRef } from "react";
import { toast } from 'react-toastify';
import { AppConstants } from '../../../core/constants';
import { call_api, parseJSON } from "../../../core/service";
interface IProps { 
  open?: boolean,
  dispatch?:any,
  siteList?:any,
  loadingSiteList?:any,
  userData?:any
}

interface IState {
  PaymentList: any;
  loading:boolean;
  search:string ;
  toggleConfirmModal:boolean,
  toggleRejectModal:boolean,
  driverList: any;
  dreiverId: any;
  paymentListData:any,
  page:number,
  isCreating:boolean,
  openBillModal: boolean,
  openDetailModal: boolean,
  magnifiedImage:any,
  detailData: any,
  rowCount:any,
  rowCountList:any,
  paginate: number,
  optionSelected:any,
  allOption:any,
  showFilters:boolean,
  currentItem:any,


}

 class PaymentController extends Component<IProps, IState> {
  tableInstanceRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    this.tableInstanceRef = createRef()  
    this.state =  {
      PaymentList: [],
      loading:false,
      search:"",
      toggleConfirmModal:false,
      toggleRejectModal:false,
      driverList: [],
      dreiverId: [],
      paymentListData:[],
      page:1,
      isCreating:false,
      openBillModal:false,
      openDetailModal:false,
      magnifiedImage:"",
      detailData:null,
      rowCount:100,
      rowCountList:[10, 25 , 50 , 100],
      paginate: 0,
      optionSelected: null,
      allOption:{id:0 ,value:{
          persian_name: "همه",
          name: "*"
        }},
      showFilters:false,
      currentItem:null,

      }

  }
  paginationSelectTimeOut: string | number | NodeJS.Timeout | undefined; 
  handlePaginationSelect=(event:any)=>{
    this.setState({
      rowCount: event.currentTarget.value,
      page:1
    })
    clearTimeout(this.paginationSelectTimeOut);
    this.paginationSelectTimeOut = setTimeout(() => {
      this.getPaymentList()
    }, 800);
    
  }
  paginationTimeOut: string | number | NodeJS.Timeout | undefined; 
  handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ page: value })
    clearTimeout(this.paginationTimeOut);
    this.paginationTimeOut = setTimeout(() => {
      this.getPaymentList()
    }, 800);
  }
  handleSelect = (selected:any)=>{
    }

    handleCloseBill = () => {
      this.setState({ openBillModal: false })
    }
    handleCloseDetailModal = () => {
      this.setState({ openDetailModal: false, detailData:null })
    }
    handleOpenDetailModal = (data:any) =>{
      this.setState({openDetailModal : true})
      let parameters = {
        db_name: data?.db_name,
      }
      let url:any = new URL(`${AppConstants.base_url_api}seller/order/${data?.order_id}`)
      for (const [key, value] of Object.entries(parameters)) {
        if (value) {
          url.searchParams.append(key, value)
        }
      }
      call_api({
        address_api: url.search ? `${AppConstants.base_url_api}seller/order/${data?.order_id}${url.search}` : `${AppConstants.base_url_api}seller/order/${data?.order_id}`,
        method_api: "GET",
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            if(j.data)
            {

              this.setState({
                detailData: j.data
              })
            }
            
          } else if (status === 401) {
            
            localStorage.clear();
          } else {
           
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      
    }
    magnifyImage = (data:any)=>{
      this.setState({openBillModal : true, magnifiedImage:data?.file, currentItem: data})
    }

  async handleConfirm(item:any){
         this.setState({ isCreating: true })
         call_api({
          address_api: `${AppConstants.base_url_api}seller/payments/verify-card-to-card`,
          method_api: "POST",
          body: JSON.stringify({
            db_name: item.db_name,
            status: 1,
            payment_id: item.id
          })
        })
          .then(parseJSON)
          .then(([status, j]) => {
            if (status === 200) {
              toast.success(" با موفقیت انجام شد.");
              this.getPaymentList()
              this.setState({ toggleConfirmModal: false , openBillModal: false})
            } else if (status === 401) {
              toast.error(j?.message);
              localStorage.clear();
              this.setState({ toggleConfirmModal: false , openBillModal: false})
            } else {
              toast.error(j?.message);
              this.setState({ toggleConfirmModal: false , openBillModal: false})
            }
          })
          .catch((error) => {
            console.error(error);
            this.setState({ toggleConfirmModal: false , openBillModal: false})
          });
          

    }
    handleReject=(item:any)=>{
      this.setState({ isCreating: true })
      call_api({
       address_api: `${AppConstants.base_url_api}seller/payments/verify-card-to-card`,
       method_api: "POST",
       body: JSON.stringify({
         db_name: item.db_name,
         status: 0,
         payment_id: item.id
       })
     })
       .then(parseJSON)
       .then(([status, j]) => {
         if (status === 200) {
           toast.success(" با موفقیت انجام شد.");
           this.getPaymentList()
           this.setState({ toggleRejectModal: false, openBillModal:false})
         } else if (status === 401) {
           toast.error(j?.message);
           localStorage.clear();
           this.setState({ toggleRejectModal: false, openBillModal:false})
         } else {
           toast.error(j?.message);
           this.setState({ toggleRejectModal: false, openBillModal:false})
         }
       })
       .catch((error) => {
         console.error(error);
         this.setState({ toggleRejectModal: false, openBillModal:false})
        });
    }
    searchTimeout: string | number | NodeJS.Timeout | undefined;
    handleSearch = (event: any) => {
      this.setState({ search: event.currentTarget.value })
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.getPaymentList()
      }, 1000);
    };
    async getPaymentList() {
      const {dreiverId,search,rowCount,page} = this.state;
      this.setState({ loading: true })
      const driversSelectedList = [];
      for (var i = 0; i < dreiverId.length; i++) {
        driversSelectedList.push(dreiverId[i]?.value?.name)
      }
      let parameters = {
        search: search ? search: null,
        db_name: dreiverId?.length > 0  ? driversSelectedList : null,
        page: page ? page.toString() : null,
        paginate: rowCount,
      }
      let url:any = new URL(`${AppConstants.base_url_api}seller/payments`)
      for (const [key, value] of Object.entries(parameters)) {
        if (value) {
          url.searchParams.append(key, value)
        }
      }
      call_api({
        address_api: url.search ? `${AppConstants.base_url_api}seller/payments${url.search}` : `${AppConstants.base_url_api}seller/payments`,
        method_api: "GET"
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            if(j?.data){

              this.setState({
                paymentListData: j.data, loading: false
              })
            }
          } else if (status === 401) {
            this.setState({ loading: false })
            localStorage.clear();
          } else {
            this.setState({ loading: false })
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    driverTimeout: string | number | NodeJS.Timeout | undefined;
    handleChange = (selected:any, index?:any) : any=> {
      this.setState({
        page: 1
      });
      if(index?.option?.id === 0)
      {
        this.setState({
          dreiverId: this.props.siteList
        });

      }
      else 
      this.setState({
        dreiverId: selected
      });
      clearTimeout(this.driverTimeout);
      this.driverTimeout = setTimeout(() => {
         this.getPaymentList()
      }, 1000);
    };
}
 
 
  

export default PaymentController;
