import { Component } from "react";
import {menuItems} from "../../../core/constants";
interface IProps {
  userData?:any;
}
interface IState {
menuItem:any
}
 class LandingController extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
    menuItem:[]
    }
  }
  handleMenuItems = ()=> {
    if(window.location.pathname.includes("admin"))
    {
      this.setState({menuItem: menuItems.filter((item:any) => item.route === window.location.pathname.substring(15, window.location.pathname.length))})
    }
    else if (window.location.pathname.includes("seller"))
    {
      this.setState({menuItem: menuItems.filter((item:any) => item.route === window.location.pathname.substring(16, window.location.pathname.length))})
    }

  }
}
export default LandingController;
