import { HTMLAttributes, ReactElement } from "react";
interface Props extends HTMLAttributes<HTMLElement> {
  infoColOne?: string;
  infoColTwo?: string;
  infoColThree?: any;
  infoColFour?:any;
  infoColFive?:any;
  typeOfInfoColOne?:string;
  site?: any;
  siteText?: string;
  isVertical?: boolean;
  item?: any;
}

const NotificationCard = ({
  infoColOne,
  infoColTwo,
  infoColThree,
  infoColFour,
  infoColFive,
  site,
  siteText,
  isVertical,
  typeOfInfoColOne,
  item,
}: Props): ReactElement => {
  return (
    <div className="notification-card">
      <div className={`${isVertical ? "flex-column" : ""} details ${typeOfInfoColOne === "image" && "border-bottom"}`}>
        {item ? (
          <>
            <p>{item.db_name}</p>
            <p>{item.categories}</p>
            <p>{item.notes}</p>
            <p>{item.products}</p>
            <p>{item.redirects}</p>
            <p>{item.tags}</p>
            <p>{item.tickets}</p>
          </>
        ) : (
          <>
            {site ? (
              <img src={site} alt="" />
            ) : (
              siteText && <p>{siteText}</p>
            )}

            {typeOfInfoColOne === "image" ? infoColOne && <img className="logo logo-margin" src={infoColOne} alt="..."/> : <p>{infoColOne}</p>}
            {infoColTwo && <p >{infoColTwo}</p>}
            {infoColThree && (
              <p className="message">{infoColThree}</p>
            )}
            {infoColFour && <p>{infoColFour}</p>}
            {infoColFive && <p>{infoColFive}</p>}
          </>
        )}
      </div>
    </div>
  );
};
export default NotificationCard;
