import {
  LoadingSpinner,
  Header,
  Checkbox,
  CustomButton,
  CustomContainer,
  InputTextLabel,
  Tab,
  SubmissionAlert,
} from "../../../components";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { PageManagementController } from "../../../controllers";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Editor } from "@tinymce/tinymce-react";
import Tooltip from "@mui/material/Tooltip";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiAlertTriangle } from "react-icons/fi";

class PageManagement extends PageManagementController {
  componentDidMount() {
    this.getPagesList();
  }

  render() {
    const {
      loading,
      slug,
      noIndex,
      title,
      header,
      desc,
      titleDesc,
      seoCanonical,
      isPublished,
      errorsList,
      toggleTab,
      seoDesc,
      seoTitle,
      dataList,
      toggleModal
    } = this.state;

    return (
      <CustomContainer>
        <Helmet>
          <title> مدیریت صفحات </title>
        </Helmet>
        <Header isActive="isPageManagement" title="مدیریت صفحات" />
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
         
                         {toggleModal === true &&
                    <SubmissionAlert
                      isOpen={toggleModal}
                      title="اخطار!"
                      setClose={(open: boolean) =>
                        this.setState({
                          toggleModal:false
                        })
                      }
                      onClick={() =>  this.handleDelete()}
                      icon={<FiAlertTriangle color="orange" fontSize="50" />}
                      details="از این عملیات اطمینان دارید؟"
                    />
                  }
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>ایجاد صفحه جدید</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="site-slogan-item">
                  <div className="col-md-8 col-sm-12">
                    <div className="sites-tab-conteainer d-flex justify-content-start mb-3 w-100">
                      <Tab
                        title="محتوا"
                        isActive={toggleTab === "0" ? true : false}
                        onClick={() => this.setState({ toggleTab: "0" })}
                      />
                      <Tab
                        title="سئو"
                        isActive={toggleTab === "1" ? true : false}
                        onClick={() => this.setState({ toggleTab: "1" })}
                      />
                      <div></div>
                    </div>

                    {toggleTab === "0" ? (
                      <>
                        <InputTextLabel
                          type={"text"}
                          myStyle="search-input"
                          value={header}
                          onChange={(event) =>
                            this.setState({ header: event.currentTarget.value })
                          }
                          placeholder="هدر"
                          name={"header"}
                          errortext={
                            errorsList &&
                            errorsList?.filter(
                              (obj: any) => obj.path === "header"
                            )[0]?.message
                          }
                        />
                        <InputTextLabel
                          type={"text"}
                          myStyle="search-input"
                          value={title}
                          onChange={(event) =>
                            this.setState({ title: event.currentTarget.value })
                          }
                          placeholder="عنوان صفحه"
                          name={"title"}
                          errortext={
                            errorsList &&
                            errorsList?.filter(
                              (obj: any) => obj.path === "title"
                            )[0]?.message
                          }
                        />
                        <InputTextLabel
                          type={"text"}
                          myStyle="search-input"
                          value={titleDesc}
                          onChange={(event) =>
                            this.setState({
                              titleDesc: event.currentTarget.value,
                            })
                          }
                          placeholder="توضیحات صفحه"
                          name={"titleDesc"}
                          errortext={
                            errorsList &&
                            errorsList?.filter(
                              (obj: any) => obj.path === "titleDesc"
                            )[0]?.message
                          }
                        />
                        <InputTextLabel
                          type={"text"}
                          myStyle="search-input"
                          value={slug}
                          onChange={(event) =>
                            this.setState({ slug: event.currentTarget.value })
                          }
                          placeholder="نامک"
                          name={"slug"}
                          errortext={
                            errorsList &&
                            errorsList?.filter(
                              (obj: any) => obj.path === "slug"
                            )[0]?.message
                          }
                        />

                        <Editor
                          apiKey="bnl71iiv8jm79p88zww9qa0rvj9u4jz5zywkym0jj16uvli4"
                          initialValue={desc}
                          init={{
                            toolbar:
                              "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
                            plugins:
                              "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons",
                            editimage_cors_hosts: ["picsum.photos"],
                            menubar:
                              "file edit view insert format tools table help",
                            toolbar_sticky: true,
                            autosave_ask_before_unload: true,
                            autosave_interval: "30s",
                            autosave_prefix: "{path}{query}-{id}-",
                            autosave_restore_when_empty: false,
                            autosave_retention: "2m",
                            image_advtab: true,
                            importcss_append: true,
                            directionality: "rtl",
                            language: "fa",
                          }}
                          onChange={(e) =>
                            this.setState({ desc: e.target.getContent() })
                          }
                        />
                        <div className="d-flex flex-wrap">
                          <Checkbox
                            title={"ایا منتشر شود؟"}
                            onClick={() =>
                              this.setState({ isPublished: !isPublished })
                            }
                            isActive={isPublished}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <InputTextLabel
                          type={"text"}
                          myStyle="search-input"
                          value={seoTitle}
                          onChange={(event) =>
                            this.setState({
                              seoTitle: event.currentTarget.value,
                            })
                          }
                          placeholder="عنوان سئو "
                          name={"seoTitle"}
                          errortext={
                            errorsList &&
                            errorsList?.filter(
                              (obj: any) => obj.path === "seoTitle"
                            )[0]?.message
                          }
                        />
                        <InputTextLabel
                          type={"text"}
                          myStyle="search-input"
                          value={seoDesc}
                          onChange={(event) =>
                            this.setState({
                              seoDesc: event.currentTarget.value,
                            })
                          }
                          placeholder="توضیحات "
                          name={"seoDesc"}
                          errortext={
                            errorsList &&
                            errorsList?.filter(
                              (obj: any) => obj.path === "seoDesc"
                            )[0]?.message
                          }
                        />

                        <InputTextLabel
                          type={"text"}
                          myStyle="search-input"
                          value={seoCanonical}
                          onChange={(event) =>
                            this.setState({
                              seoCanonical: event.currentTarget.value,
                            })
                          }
                          placeholder="آدرس url canonical "
                          name={"seoCanonical"}
                          errortext={
                            errorsList &&
                            errorsList?.filter(
                              (obj: any) => obj.path === "seoCanonical"
                            )[0]?.message
                          }
                        />

                        <div className="d-flex flex-wrap">
                          <Checkbox
                            title={"no_index"}
                            onClick={() => this.setState({ noIndex: !noIndex })}
                            isActive={noIndex}
                          />
                        </div>
                      </>
                    )}

                    <CustomButton
                      myStyle="btn-confirm mt-1"
                      loading={false}
                      onClick={() => this.create()}
                      title="ساخت "
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            {dataList &&
              dataList?.length > 0 &&
              dataList?.map((item: any, index: number) => {
                return (
                  <Accordion key={index}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      
                      <Typography
                      
                      
                      > {item?.header}</Typography>
                      
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="site-slogan-item">
                        <div className="col-md-8 col-sm-12">
                        <div className="d-flex justify-content-between w-100">
                          <div className="sites-tab-conteainer d-flex justify-content-start mb-3 w-100">
                        
                            <Tab
                              title="محتوا"
                              isActive={toggleTab === "0" ? true : false}
                              onClick={() => this.setState({ toggleTab: "0" })}
                            />
                            <Tab
                              title="سئو"
                              isActive={toggleTab === "1" ? true : false}
                              onClick={() => this.setState({ toggleTab: "1" })}
                            />
                            <div>
                              
                            </div>
                          </div>
                            <Tooltip title="حذف کردن">
                                             <span>
                                               {" "}
                                               <RiDeleteBin6Line
                                                 className="icon-red"
                                                 onClick={() =>this.handleDeleteModal(item)}
                                               />
                                             </span>
                                           </Tooltip>
                                           </div>
                          {toggleTab === "0" ? (
                            <>
                              <InputTextLabel
                                type={"text"}
                                myStyle="search-input"
                                value={item?.title}
                                onChange={(event) =>
                                  this.changeUpdate(event.currentTarget.value,index,"title")
                                }
                                placeholder="عنوان صفحه"
                                name={"title"}
                                errortext={
                                  errorsList &&
                                  errorsList?.filter(
                                    (obj: any) => obj.path === "title"
                                  )[0]?.message
                                }
                              />
                              <InputTextLabel
                                type={"text"}
                                myStyle="search-input"
                                value={item?.desc_title}
                                onChange={(event) =>
                                  this.changeUpdate(event.currentTarget.value,index,"desc_title")
                                }
                                placeholder="توضیحات صفحه"
                                name={"desc_title"}
                                errortext={
                                  errorsList &&
                                  errorsList?.filter(
                                    (obj: any) => obj.path === "desc_title"
                                  )[0]?.message
                                }
                              />
                              <InputTextLabel
                                type={"text"}
                                myStyle="search-input"
                                value={item?.slug}
                                onChange={(event) =>
                                  this.changeUpdate(event.currentTarget.value,index,"slug")
                                }
                                placeholder="نامک"
                                name={"slug"}
                                errortext={
                                  errorsList &&
                                  errorsList?.filter(
                                    (obj: any) => obj.path === "slug"
                                  )[0]?.message
                                }
                              />

                              <Editor
                                apiKey="bnl71iiv8jm79p88zww9qa0rvj9u4jz5zywkym0jj16uvli4"
                                initialValue={item?.description}
                                init={{
                                  toolbar:
                                    "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
                                  plugins:
                                    "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons",
                                  editimage_cors_hosts: ["picsum.photos"],
                                  menubar:
                                    "file edit view insert format tools table help",
                                  toolbar_sticky: true,
                                  autosave_ask_before_unload: true,
                                  autosave_interval: "30s",
                                  autosave_prefix: "{path}{query}-{id}-",
                                  autosave_restore_when_empty: false,
                                  autosave_retention: "2m",
                                  image_advtab: true,
                                  importcss_append: true,
                                  directionality: "rtl",
                                  language: "fa",
                                }}
                                onChange={(event) =>
                                  this.changeUpdate(event.target.getContent(),index,"description")
                                }
                              />
                              <div className="d-flex flex-wrap">
                                <Checkbox
                                  title={"ایا منتشر شود؟"}
                                  name="published"
                                  onClick={(event: { currentTarget: { checked: any; }; }) =>
                                    this.changeUpdate(event.currentTarget.checked,index,"published")
                                  }
                                  isActive={
                                    item?.published?.name.toString() === "1" ? true : false
                                  }
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <InputTextLabel
                                type={"text"}
                                myStyle="search-input"
                                value={item?.seo_title}
                                onChange={(event) =>
                                  this.changeUpdate(event.currentTarget.value,index,"seo_title")
                                }
                                placeholder="عنوان سئو "
                                name={"seo_title"}
                                errortext={
                                  errorsList &&
                                  errorsList?.filter(
                                    (obj: any) => obj.path === "seo_title"
                                  )[0]?.message
                                }
                              />
                              <InputTextLabel
                                type={"text"}
                                myStyle="search-input"
                                value={item?.seo_description}
                                onChange={(event) =>
                                  this.changeUpdate(event.currentTarget.value,index,"seo_description")
                                }
                                placeholder="توضیحات "
                                name={"seo_description"}
                                errortext={
                                  errorsList &&
                                  errorsList?.filter(
                                    (obj: any) => obj.path === "seo_description"
                                  )[0]?.message
                                }
                              />

                              <InputTextLabel
                                type={"text"}
                                myStyle="search-input"
                                value={item?.seo_canonical}
                                onChange={(event) =>
                                  this.changeUpdate(event.currentTarget.value,index,"seo_canonical")
                                }
                                placeholder="آدرس url canonical "
                                name={"seo_canonical"}
                                errortext={
                                  errorsList &&
                                  errorsList?.filter(
                                    (obj: any) => obj.path === "seo_canonical"
                                  )[0]?.message
                                }
                              />

                              <div className="d-flex flex-wrap">
                                <Checkbox
                                  title={"no_index"}
                                  name="no_index"
                                  
                                  onClick={(event: { currentTarget: { checked: any; }; }) =>
                                    this.changeUpdate(event.currentTarget.checked,index,"no_index")
                                  }
                                  isActive={
                                    item?.no_index.toString() === "1" ? true : false
                                  }
                                />
                              </div>
                            </>
                          )}

                          <CustomButton
                            myStyle="btn-confirm mt-1"
                            loading={false}
                            onClick={() => this.update(item)}
                            title="ویرایش "
                          />
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
          </>
        )}
      </CustomContainer>
    );
  }
}
const mapStateToProps = (dispatch: any) => {
  return {
    dispatch,
    siteList: dispatch.general.siteList,
    loadingSiteList: dispatch.general.loadingSiteList,
    userData: dispatch.general.userData,
  };
};
export default connect(mapStateToProps)(PageManagement);
