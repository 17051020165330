import {
    InputHTMLAttributes,
    ReactElement,
  } from "react";
  interface Props extends InputHTMLAttributes<HTMLInputElement> {
    content:string,
    badgeTtile:number,
  }
  
   function BadgeRow({
    content,
    badgeTtile,
  }: Props): ReactElement {

  
    return (
        <div className="badge-row w-100">
         <div>{content}</div>
         <div className="badge-title">{badgeTtile}</div>
          
        </div>
    );
  }
  export default BadgeRow;
  