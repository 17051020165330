
import {AiOutlineShoppingCart } from "react-icons/ai";
import {LuNewspaper } from "react-icons/lu"; 
import {TbRelationOneToMany} from "react-icons/tb";
import {BsPeople } from "react-icons/bs";
import {MdOutlinePayment} from "react-icons/md";
import {FiLink} from "react-icons/fi";
import {AiOutlineComment } from "react-icons/ai";
import {TiTicket } from "react-icons/ti";
import { IoPricetagOutline } from "react-icons/io5";
export class AppConstants {
  static token: string = "";
  // static base_url_api: string = "http://iseller.local/api/v1/";
  static base_url_api: string = "https://back.netran.net/api/v1/";

  static base_url_image: string = "";

}
export enum landingRoutes {
  SELL = "sell",
  STORE = "store",
  SETTINGS = "settings"
}
export const menuItems: { route: string; title : string ; children: any; }[]  =  [
  {route: "sell", title: "فروش" , children:[{
    myStyle:"bg-orange",
    title:"لیست قیمت",
    role:"seller",
    route:"/price-management",
    icon:IoPricetagOutline},
    {
      myStyle:"bg-red",
      title:"سفارشات",
      route:"/orders",
      role:"seller",
      icon:AiOutlineShoppingCart},
      {
        myStyle:"bg-purple",
        title:"آدرس‌ها",
        route:"/address-managent",
        role:"seller",
        icon:AiOutlineShoppingCart},
    {
      myStyle:"bg-hot-pink",
      title:"پیش‌فاکتور",
      route:"/prefactor",
      role:"seller",
      icon:LuNewspaper 
    },
    {
      myStyle:"bg-yellow",
      title:" پیام‌های تماس با ما",
      role:"seller",
      route:"/contact-us",
      icon:MdOutlinePayment 
    },
    {
      myStyle:"bg-light-green",
      title:"محصولات مرتبط",
      route:"/relations",
      role:"seller",
      icon:TbRelationOneToMany
    },
    {
      myStyle:"bg-purple",
      title:"اطلاعات اشخاص",
      route:"/users",
      role:"seller",
      icon:BsPeople 
    },
    {
      myStyle:"bg-yellow",
      title:"لینک پرداخت آماده",
      route:"",
      role:"seller",
      icon:FiLink 
    },
    {
      myStyle:"bg-orange",
      title:"نظرات کاربران",
      route:"/comments",
      role:"seller",
      icon:AiOutlineComment  
    },
    {
      myStyle:"bg-hot-pink",
      title:"تیکت",
      route:"/tickets",
      role:"seller",
      icon:TiTicket 
    },
    // {
    //   myStyle:"bg-red",
    //   title:"مدیریت تأمین‌کنندگان",
    //   route:"",
    //   role:"seller",
    //   icon:RiOrganizationChart 
    // },
    {
      myStyle:"bg-light-green",
      title:"پرداخت",
      role:"seller",
      route:"/payments",
      icon:MdOutlinePayment  
    },
  ]},
  {route: "settings",title: " تنظیمات و امکانات " , children:[{
    myStyle:"bg-orange",
    title:"پرفروش‌ها",
    role:"seller",
    route:"/best-selling",
    icon:IoPricetagOutline},
    {
      myStyle:"bg-red",
      title:"ویترین",
    role:"seller",
      route:"/vitrin",
      icon:AiOutlineShoppingCart },
    {
      myStyle:"bg-hot-pink",
      title:"تنظیمات فروشگاه",
    role:"seller",
      route:"/store-settings",
      icon:LuNewspaper 
    },
    {
      myStyle:"bg-light-green",
      title:"رویدادهای سیستم",
    role:"seller",
      route:"/logs",
      icon:LuNewspaper 
    },

  ]},
  {route: "store",title: " انبار داری "  , children:[{
    myStyle:"bg-orange",
    title:"لیست فاکتورها",
    role:"seller",
    icon:IoPricetagOutline},
  ]},
  {route:"products", title:"محصولات", children:[
    {
      myStyle:"bg-orange",
      title:"داشبورد",
      role:"admin",
      route:"/admin/home",
      icon:MdOutlinePayment 
    },
    {
      myStyle:"bg-red",
      title:"لیست محصولات",
      role:"admin",
      route:"/admin/products",
      icon:MdOutlinePayment 
    },
    {
      myStyle:"bg-purple",
      title:"افزودن محصول",
      role:"admin",
      route:"/admin/add-product",
      icon:MdOutlinePayment 
    },
    {
      myStyle:"bg-hot-pink",
      title:"یادداشت‌های کاربران",
      role:"admin",
      route:"/admin/note",
      icon:MdOutlinePayment 
    },
    {
      myStyle:"bg-light-green",
      title:" رویدادهای سیستم ",
      role:"admin",
      route:"/admin/system-logs",
      icon:MdOutlinePayment 
    },
  ]},
  {route:"management", title:"مدیریت‌ها", children:[
    {
      myStyle:"bg-black",
      title:"رسانه‌ها",
      role:"admin",
      route:"/admin/media",
      icon:MdOutlinePayment 
    },
    {
      myStyle:"bg-black",
      title:"مدیریت فایل",
      role:"admin",
      route:"/admin/filemanager",
      icon:MdOutlinePayment 
    },
    {
      myStyle:"bg-blue",
      title:"مدیریت برند",
      role:"admin",
      route:"/admin/brands",
      icon:MdOutlinePayment 
    },
    {
      myStyle:"bg-yellow",
      title:"مدیریت دسته‌بندی",
      role:"admin",
      route:"/admin/categories",
      icon:MdOutlinePayment 
    },
    {
      myStyle:"bg-orange",
      title:"مدیریت برچسب",
      role:"admin",
      route:"/admin/tags",
      icon:MdOutlinePayment 
    },
    {
      myStyle:"bg-red",
      title:"مدیریت کاربران",
      role:"admin",
      route:"/admin/users",
      icon:MdOutlinePayment 
    },
    {
      myStyle:"bg-purple",
      title:" مدیریت وب‌سایت ",
      role:"admin",
      route:"/admin/web-management",
      icon:MdOutlinePayment 
    },
    {
      myStyle:"bg-light-green",
      title:"مدیریت ریدایرکت‌ها",
      role:"admin",
      route:"/admin/redirect",
      icon:MdOutlinePayment 
    },
  ]}
]
export const RE_DIGIT = new RegExp(/^\d+$/);
export const ROLES:any = {
  'User': "user",
  'Seller': "seller",
  'Admin': "admin"
}
