import { RedirectsManageMentController } from "../../../controllers";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import Box from "@mui/material/Box";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlineFileExcel, AiOutlineEdit } from "react-icons/ai";
import { Header, SubmissionAlert, Option } from "../../../components";
import { CustomButton, DropDownIcon, InputIcon } from "../../../components";
import { InputTextLabel } from "../../../components/input_text_label";
import { createTheme, ThemeProvider } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Tooltip from "@mui/material/Tooltip";
import Select, { components, CSSObjectWithLabel } from "react-select";
import { TfiFilter } from "react-icons/tfi";
import { FiAlertTriangle } from "react-icons/fi";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
class RedirectsManagement extends RedirectsManageMentController {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getDate();
  }
  render() {
    const DropdownIndicator = (props: any) => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator
            style={{ color: "red" }}
            {...props}
          ></components.DropdownIndicator>
        )
      );
    };
    const theme = createTheme({
      typography: {
        fontFamily: "Vazir",
      },
    });
    const columns = [
      {
        accessorKey: "row",
        header: "ردیف",
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        size: 10,
      },
      {
        accessorKey: "driver",
        header: "سایت",
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        size: 10,
      },
      {
        accessorKey: "id",
        header: "شناسه",
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        size: 10,
      },
      {
        accessorKey: "status",
        header: "نوع",
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        size: 10,
      },
    ] as MRT_ColumnDef<any>[];
    const { siteList, loadingSiteList } = this.props;
    const {
      redirectListData,
      showAddNewItem,
      updateLoading,
      selectedSearch,
      total,
      typeList,
      type,
      errorsList,
      toggleModal,
      editItem,
      createLoading,
      dreiverId,
      oldURL,
      showFilters,
      status,
      statusList,
      loading,
      edit,
      search,
      newURL,
      rowCount,
      description,
      rowCountList,
      updateErrorsList,
      page
    } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <div className="redirect">
          <Helmet>
            <title>ریدایرکت‌ها</title>
          </Helmet>
          <Header isActive = "isRedirect" title="ریدایرکت‌ها" />
          <div className="custom-table">
            {showFilters && (
              <>
                <div className="filter-select-container mb-2 pe-3">
                  <div className="filter-options-container">
                    <div className="column-50">
                      <Select
                        styles={{
                          control: (baseStyles: any, state: any):CSSObjectWithLabel => ({
                            ...baseStyles,
                            boxShadow: "rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",

                            borderWidth: "1px",
                            backgroundColor: "#fff",
                            display: "flex",
                            flexDirection: "row-reverse",
                            textAlign: "right",
                            borderRadius: "4px",
                            minHeight: "42px",
                           
                            outline: "unset",
                          }) as CSSObjectWithLabel,
                        }}
                        onChange={this.driverSelected}
                        components={{ Option }}
                        value={dreiverId}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        noOptionsMessage={() => "این مورد وجود ندارد!"}
                        menuPortalTarget={document.body}
                        options={siteList}
                        getOptionLabel={(option: any) =>
                          option?.value?.persian_name
                        }
                        getOptionValue={(option: any) => option?.value?.name}
                        placeholder="سایت"
                        name="dreiverId"
                        isClearable={false}
                        loadingMessage={() => "در حال بارگذاری  ..."}
                        isLoading={loadingSiteList}
                      />
                      <span className="input-icon-error">
                        {errorsList &&
                          errorsList?.filter(
                            (obj: any) => obj.path === "dreiverId"
                          )[0]?.message}
                      </span>
                    </div>
                    <div className="column-50">
                      <Select
                        styles={{
                          control: (
                            baseStyles: any,
                            state: { isFocused: any }
                          ):CSSObjectWithLabel => ({
                            ...baseStyles,
                            boxShadow: state.isFocused
                              ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                              : "",
                            borderWidth: "1px",
                            backgroundColor: "#fff",
                            display: "flex",
                            flexDirection: "row-reverse",
                            textAlign: "right",
                            borderRadius: "4px",
                            height: "42px",
                            marginBottom: "10px",
                           
                            outline: "unset",
                          }) as CSSObjectWithLabel,
                        }}
                        onChange={(choice) => this.typeSelected(choice)}
                        components={{ DropdownIndicator }}
                        value={type}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        options={typeList}
                        getOptionLabel={(option: any) => option?.title}
                        getOptionValue={(option: any) => option?.value}
                        placeholder="نوع"
                        isClearable
                        menuPortalTarget={document.body}

                      />
                    </div>

                    <div className="column-50">
                      <div className="w-100 mb-1 d-flex align-items-center">
                        <ToggleButtonGroup
                          value={selectedSearch}
                          exclusive
                          onChange={this.handleSelectSearch}
                          aria-label="text alignment"
                        >
                          <ToggleButton value="0" aria-label="left aligned">
                            قدیم
                          </ToggleButton>
                          <ToggleButton value="null" aria-label="centered">
                            همه
                          </ToggleButton>
                          <ToggleButton value="1" aria-label="right aligned">
                            جدید
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                      <InputTextLabel
                        type={"text"}
                        value={search}
                        onChange={(event) => this.handleSearch(event)}
                        placeholder="جستجو..."
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="filter-select-container pe-3">
              <div className="filter-options-container">
                <CustomButton
                  loading={false}
                  onClick={() =>
                    this.setState({ showAddNewItem: !showAddNewItem })
                  }
                  title="افزودن ریدایرکت"
                  myStyle="bg-purple mb-2"
                />
                {showAddNewItem && (
                  <div className="add-container">
                    <label>افزودن ریدایرکت</label>
                    <div className="add-new-redirect">
                      <div className="column-50">
                        <Select
                          styles={{
                            control: (baseStyles: any, state: any):CSSObjectWithLabel => ({
                              ...baseStyles,
                              boxShadow:
                                "rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",

                              borderWidth: "1px",
                              backgroundColor: "#fff",
                              display: "flex",
                              flexDirection: "row-reverse",
                              textAlign: "right",
                              borderRadius: "4px",
                              minHeight: "42px",
                             
                              outline: "unset",
                            }) as CSSObjectWithLabel,
                          }}
                          onChange={this.driverSelected}
                          components={{ Option }}
                          value={dreiverId}
                          isMulti={false}
                          closeMenuOnSelect={true}
                          noOptionsMessage={() => "این مورد وجود ندارد!"}
                          menuPortalTarget={document.body}
                          options={siteList}
                          getOptionLabel={(option: any) =>
                            option?.value?.persian_name
                          }
                          getOptionValue={(option: any) => option?.value?.name}
                          placeholder="سایت"
                          isClearable={false}
                          loadingMessage={() => "در حال بارگذاری  ..."}
                          isLoading={loadingSiteList}
                        />
                      </div>
                      <div className="column-50">
                        <InputIcon
                          type={"text"}
                          value={newURL}
                          title="New:https://netran.net/"
                          inBox="New:https://netran.net/"
                          name="newURL"
                          errortext={
                            errorsList &&
                            errorsList?.filter(
                              (obj: any) => obj.path === "newURL"
                            )[0]?.message
                          }
                          onChange={(event) =>
                            this.setState({
                              newURL: event.currentTarget.value,
                            })
                          }
                        />
                      </div>
                      <div className="column-50">
                        <InputIcon
                          type={"text"}
                          value={oldURL}
                          title="Old:https://netran.net/"
                          name="oldURL"
                          errortext={
                            errorsList &&
                            errorsList?.filter(
                              (obj: any) => obj.path === "oldURL"
                            )[0]?.message
                          }
                          inBox="Old:https://netran.net/"
                          onChange={(event) =>
                            this.setState({
                              oldURL: event.currentTarget.value,
                            })
                          }
                        />
                      </div>
                      <div className="column-50">
                        <DropDownIcon
                          value={status}
                          optionList={statusList}
                          title={"نوع ریدایرکت"}
                          inBox={"نوع ریدایرکت"}
                          onChange={(event) => {
                            this.statusSelect(event);
                          }}
                        />
                      </div>
                      <div className="column-50">
                        <InputIcon
                          type={"text"}
                          value={description}
                          title={"توضیحات"}
                          inBox={"توضیحات"}
                          onChange={(event) =>
                            this.setState({
                              description: event.currentTarget.value,
                            })
                          }
                        />
                      </div>
                      <CustomButton
                        loading={createLoading}
                        myStyle="btn bg-blue"
                        onClick={() => this.create()}
                        title="ثبت"
                      />
                    </div>
                  </div>
                )}
                {edit && (
                  <div className="add-container">
                    <label>
                      <span>ویرایش ریدایرکت</span>
                      <div>
                        <Tooltip title="حذف کردن">
                          <span>
                            <RiDeleteBin6Line
                              className="icon-red"
                              onClick={() => this.setState({toggleModal: true})}
                            />
                          </span>
                        </Tooltip>
                      </div>
                    </label>
                    <div className="add-new-redirect">
                      <div className="column-50">
                        <InputIcon
                          type={"text"}
                          value={editItem?.new_url}
                          title={"New: https://netran.net/"}
                          inBox={"New: https://netran.net/"}
                          onChange={(event) =>
                            this.setState({
                              editItem: {
                                ...editItem,
                                new_url: event.currentTarget.value,
                              },
                            })
                          }
                          errortext={
                            updateErrorsList &&
                            updateErrorsList?.filter(
                              (obj: any) => obj.path === "editItem.new_url"
                            )[0]?.message
                          }
                          name="editItem.new_url"
                        />
                      </div>
                      <div className="column-50">
                        <InputIcon
                          type={"text"}
                          value={editItem?.old_url}
                          title={"Old: https://netran.net/"}
                          inBox={"Old: https://netran.net/"}
                          errortext={
                            updateErrorsList &&
                            updateErrorsList?.filter(
                              (obj: any) => obj.path === "editItem.old_url"
                            )[0]?.message
                          }
                          name="editItem.old_url"
                          onChange={(event) =>
                            this.setState({
                              editItem: {
                                ...editItem,
                                old_url: event.currentTarget.value,
                              },
                            })
                          }
                        />
                      </div>
                      <div className="column-50">
                        <DropDownIcon
                          value={editItem?.type}
                          optionList={statusList}
                          title={"نوع ریدایرکت"}
                          inBox={"نوع ریدایرکت"}
                          onChange={(event) =>
                            this.setState({
                              editItem: {
                                ...editItem,
                                type: event.currentTarget.value,
                              },
                            })
                          }
                        />
                      </div>
                      <div className="column-50">
                        <InputIcon
                          type={"text"}
                          value={editItem?.desc}
                          title={"توضیحات"}
                          inBox={"توضیحات"}
                          onChange={(event) =>
                            this.setState({
                              editItem: {
                                ...editItem,
                                desc: event.currentTarget.value,
                              },
                            })
                          }
                        />
                      </div>
                      <CustomButton
                        loading={updateLoading}
                        myStyle="btn bg-blue"
                        onClick={() => this.update()}
                        title="ویرایش"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {toggleModal === true && (
              <SubmissionAlert
                isOpen={toggleModal}
                title="اخطار!"
                setClose={(open: boolean) =>
                  this.setState({
                    toggleModal: false,
                  })
                }
                onClick={() => this.handleDelete()}
                icon={<FiAlertTriangle color="orange" fontSize="50" />}
                details="از این عملیات اطمینان دارید؟"
              />
            )}
            <MaterialReactTable
              muiTablePaperProps={{
                sx: {
                  mr: "1.5rem",
                  ml: "1.5rem",

                  border: "1px solid #e5e5e5",
                },
              }}
              columns={columns}
              data={
                redirectListData &&
                redirectListData?.length > 0 &&
                redirectListData
                  ?.filter(
                    (v: string | null | undefined) =>
                      v !== "" && v !== undefined && v !== null
                  )
                  .map((item: any, index: number) => {
                    return {
                      item: item,
                      row: index + 1,
                      driver: <img src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo} alt="" />,
                      id: item?.id,
                      status: item?.type,
                    };
                  })
              }
              renderTopToolbarCustomActions={({ table }) => (
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    p: "0.5rem",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <CustomButton
                    loading={false}
                    onClick={() =>
                      this.exportToExcel(redirectListData, "Excel Export")
                    }
                    title="اکسل"
                    myStyle="excel-btn"
                    icon={<AiOutlineFileExcel />}
                  />
                  <Tooltip title="نمایش فیلترها">
                    <span>
                      {" "}
                      <TfiFilter
                        className="filter-icon"
                        onClick={() => this.setState(prevState => ({
                          showFilters: !prevState.showFilters
                        }))
                      }
                      />
                    </span>
                  </Tooltip>
                  <div className="custom-pagination">
                    <Pagination
                      page={page}
                      count={total ? Math.ceil(total / rowCount) : 1}
                      onChange={this.handleChangePage}
                    />
                    <DropDownIcon
                      value={rowCount}
                      optionList={rowCountList}
                      title={""}
                      onChange={(event) => {
                        this.handlePaginationSelect(event);
                      }}
                    />
                  </div>
                </Box>
              )}
              state={{ isLoading: loading, showProgressBars: loading }}
              enablePagination={false}
              renderBottomToolbarCustomActions={({ table }) => {
                return (
                  <div className="custom-pagination w-100">
                    <Pagination
                     page={page}
                      count={total ? Math.ceil(total / rowCount) : 1}
                      onChange={this.handleChangePage}
                    />
                    <DropDownIcon
                      value={rowCount}
                      optionList={rowCountList}
                      title={""}
                      onChange={(event) => {
                        this.handlePaginationSelect(event);
                      }}
                    />
                  </div>
                );
              }}
              positionExpandColumn={"last"}
              enableGlobalFilter={false}
              enableColumnFilters={false}
              enableDensityToggle={false}
              enableFullScreenToggle={false}
              enableHiding={false}
              sortDescFirst={true}
              enableColumnActions={false}
              enableSorting={true}
              enableRowActions
              positionActionsColumn="last"
              initialState={{
                pagination: { pageSize: 100, pageIndex: 0 },
              }}
              localization={{
                noRecordsToDisplay: "داده‌ای برای نمایش وجود ندارد!",
                actions: "عملیات",
              }}
              muiTableProps={{
                sx: {
                  border: "1px solid #e5e5e5",
                },
              }}
              muiTableHeadCellProps={{
                sx: {
                  border: "1px solid #e5e5e5",
                  height: "fit-content",
                  textAlign: "center",
                },
              }}
              muiTableBodyCellProps={{
                sx: {
                  border: "1px solid #e5e5e5",
                  height: "fit-content",
                  textAlign:"center"

                },
              }}
              muiTableBodyRowProps={( {row,staticRowIndex} ) => ({
                sx:{
                  height: "fit-content",
                  backgroundColor: (staticRowIndex % 2 === 0 ) ? '#f3f3f3'  : "",
              }})}
              displayColumnDefOptions={{
                "mrt-row-expand": {
                  muiTableHeadCellProps: {
                    align: "center",
                  },
                },
              }}
              tableInstanceRef={this.tableInstanceRef}
              renderRowActions={({ row, table }) => [
                <div key={row.original.item?.id} className="detail-container">
                  <Tooltip title="حذف کردن">
                    <span>
                      <RiDeleteBin6Line
                        className="icon-red"
                        onClick={() =>
                          this.setState({
                            toggleModal: true,
                            rowData: row.original.item,
                          })
                        }
                      />
                    </span>
                  </Tooltip>
                  <Tooltip title="ویرایش">
                    <span>
                      <AiOutlineEdit
                        onClick={() => this.handleEditForm(row.original?.item)}
                        className="icon-yellow"
                      />
                    </span>
                  </Tooltip>
                </div>,
              ]}
              renderDetailPanel={({ row }) => (
                <div key={row.original?.item?.id} className="detail-container">
                  <div className="detail-col">
                    <p className="detail-row">
                      آدرس جدید : {row.original?.item?.new_url || " ثبت نشده "}
                    </p>
                    <p className="detail-row">
                      آدرس قدیمی: {row.original?.item?.old_url}
                    </p>
                  </div>
                  <div className="detail-col">
                    <p className="detail-row">
                      توضیحات: {row.original?.item?.desc}
                    </p>
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </ThemeProvider>
    );
  }
}
const mapStateToProps = (dispatch: any) => {
  return {
    dispatch,
    siteList: dispatch.general.siteList,
    loadingSiteList: dispatch.general.loadingSiteList,
  };
};
export default connect(mapStateToProps)(RedirectsManagement);
