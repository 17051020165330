
import { DropDownIcon, Header, CustomButton, InputTextLabel, SubmissionAlert } from "../../../components";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { CommonQuestionsListController } from "../../../controllers";
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { Box, createTheme, ThemeProvider } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { AiOutlineEdit, AiOutlineFileExcel } from "react-icons/ai";
import { TfiFilter } from "react-icons/tfi";
import Tooltip from '@mui/material/Tooltip';
import { Link } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiAlertTriangle } from "react-icons/fi";

class CommonQuestionsList extends CommonQuestionsListController {
  componentDidMount() {
    this.getDate()
  }

  render() {
    const { loading , commenQuestionsList ,toggleModal , page , rowCount,rowCountList  , showFilters , search} = this.state;
    type coulmnData = {
        item: any;
        row: number;
        question: number;
      }

   
      const theme = createTheme({
      direction: "ltr",
      typography: {
        fontFamily:
          'Vazir',
      }
    });    

  
  const columns = [
    {
      accessorKey: 'row',
      header: 'ردیف',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {cell.getValue<string>()}
        </div>
      )
    },
    {
      accessorKey: 'question',
      header: 'پرسش',
      Cell: ({ cell }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          {cell.getValue<string>()}
        </div>
      )
    },
  ] as MRT_ColumnDef<coulmnData>[]

    return (
      <ThemeProvider theme={theme}>
        <div className="users-info">
        <Helmet>
        <title>پرسش های متداول</title>
            </Helmet>
            <Header title="پرسش های متداول" />
          <div className="custom-table">
          <div className="custom-card mb-3">

              <div className="table-container">
                
              
              <div className="filter-select-container mb-2 pe-3">
                <div className="filter-options-container"> 
                {showFilters && 
<>
                    <div className="column-50">
                  <InputTextLabel
                    type={"text"}
                    value={search}
                    onChange={(event) => this.handleSearch(event)}
                    placeholder="جستجو..."
                  />
                </div>
                </>
                  
               }
              <div className="w-100">
                <CustomButton
                        loading={false}
                        route={`/admin/commen-question-create/${window.location.pathname.split("/")[3]}`}
                        title="افزودن"
                        myStyle="excel-btn mb-2 "
                      />
                </div>
                </div>
                
              </div>
              {toggleModal === true &&
                    <SubmissionAlert
                      isOpen={toggleModal}
                      title="اخطار!"
                      setClose={(open: boolean) =>
                        this.setState({
                          toggleModal: false
                        })
                      }
                      onClick={() =>  this.handleDelete()}
                      icon={<FiAlertTriangle color="orange" fontSize="50" />}
                      details="از این عملیات اطمینان دارید؟"
                    />
                  }
                <MaterialReactTable
                  muiTablePaperProps={{
                    sx: { m: '1.3rem', border:"1px solid #e5e5e5" }}}
                  columns={columns}
                  muiTableProps={{
                    sx: {
                      border: "1px solid #e5e5e5",
                
                    },
                  }}
                  muiTableHeadCellProps={{
                    sx: {
                      height:"fit-content",
                      border: "1px solid #e5e5e5",
                    },
                  }}
                  muiTableBodyRowProps={( {row,staticRowIndex} ) => ({
                    sx:{
                      height: "fit-content",
                      backgroundColor: (staticRowIndex % 2 === 0 ) ? '#f3f3f3'  : "",
                  }})}
                  muiTableBodyCellProps={{
                    sx: {
                      height:"fit-content",
                      border: "1px solid #e5e5e5",
                      textAlign:"center"

                      
                    },
                  }}
                  displayColumnDefOptions={{
                    'mrt-row-expand': {
                      muiTableHeadCellProps: {
                        align: 'center',
                      },
                    },
                  }}
                  data={commenQuestionsList && commenQuestionsList?.length > 0 &&
                    commenQuestionsList?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null).map((item: any, index: number) =>
                     {
                      return ({
                        item: item,
                        row: index + 1,
                        question:item?.question,
                      }
                      );
                    }
                    )}
                    enablePagination={false}
                renderBottomToolbarCustomActions={({table})=>{
                  return (
                    <div className="custom-pagination w-100">
                     <Pagination  page={page} count={commenQuestionsList?.total ? Math.ceil(commenQuestionsList?.total / rowCount) : 1} onChange={this.handleChangePage} />
                      <DropDownIcon
                      value={rowCount}
                      optionList={rowCountList}
                      title={""}
                      onChange={(event) =>
                        {
                          this.handlePaginationSelect(event)
                      }
                    }
                    />
                    </div>
                  );
                }}
                  positionActionsColumn={'last'}
                  positionExpandColumn={'first'}
                  enableGlobalFilter={false}
                  enableColumnFilters={false}
                  enableDensityToggle={false}
                  enableFullScreenToggle={false}
                  enableHiding={false}
                  sortDescFirst={true}
                  enableColumnActions={false}
                  enableSorting={true}
                  renderTopToolbarCustomActions={({ table }) => (
                    <Box
                      sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap', justifyContent:"space-between", width:"100%" }}
                    >
                      <CustomButton
                        loading={false}
                        onClick={() => this.exportToExcel(commenQuestionsList?.data, "Excel Export")}
                        title="اکسل"
                        myStyle="excel-btn"
                        icon={<AiOutlineFileExcel />}
                      />
                      <Tooltip title="نمایش فیلترها"><span> <TfiFilter className='filter-icon' onClick={() => this.setState(prevState => ({
                                  showFilters: !prevState.showFilters
                                }))
                              }/></span></Tooltip>
                      <div className='custom-pagination'>
                      <Pagination  page={page} count={commenQuestionsList?.total ? Math.ceil(commenQuestionsList?.total / rowCount) : 1} onChange={this.handleChangePage} />
                  <DropDownIcon
                  value={rowCount}
                  optionList={rowCountList}
                  title={""}
                  onChange={(event) =>
                    {
                      this.handlePaginationSelect(event)
                  }
                }
                />
                    </div>
                    </Box>
                  )}              
                  enableRowActions
                  localization={{
                    actions: 'عملیات',
                    noRecordsToDisplay:"داده‌ای برای نمایش وجود ندارد!"
                  }}
                  
                  state={{ isLoading: loading, showProgressBars: loading }}
                  renderRowActions={({ row, table }) => [
                    <div key={row.original?.item?.id} className='detail-container'>
                       <Link target="_blank" to={`/admin/commen-questions-update/${row.original?.item?.id_select}`}>
                      <Tooltip title="ویرایش"><span><AiOutlineEdit className='icon-yellow' />
                      </span>
                      </Tooltip>
                    </Link>
                    <RiDeleteBin6Line
                            className="icon-red"
                            onClick={() => this.setState({toggleModal:true , rowData: row.original.item}) }
                          />
                    </div>
                  ]}
                  initialState={{
                    pagination : {pageSize:100, pageIndex:0}
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}
const mapStateToProps = (dispatch: any) => {
  return {
    dispatch,
    siteList: dispatch.general.siteList,
    loadingSiteList: dispatch.general.loadingSiteList,
    userData: dispatch.general.userData,
  }
};
export default connect(mapStateToProps)(CommonQuestionsList);
