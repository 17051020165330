import { Component } from 'react';
import { call_api, parseJSON } from '../../../core/service';
import { AppConstants } from '../../../core/constants';
import { toast } from 'react-toastify';
import * as yup from 'yup'

interface IProps {
dispatch?:any,
siteList?:any,
loadingSiteList?:any,
userData?:any;
history?:any
}
interface IState {
  loading?: boolean,
  question:string;
  answer:string;
  fullAnswer:string;
  errorsList:any
}

 class CommenQuestionsCreateController extends Component<IProps, IState> {
  state: IState = {
    loading: false,
    question:"",
  answer:"",
  fullAnswer:"",
  errorsList:[]
  };

  handleEditorChangeGeneralInfo = (e: { target: { getContent: () => any; }; }) => {
    this.setState({fullAnswer: e.target.getContent()})
  };
  async create() {
    const { answer,fullAnswer,question} = this.state;


    //
    const redirectsSchema = yup.object({
      answer:yup.string(),
      question:yup.string().required("پرسش الزامی می باشد."),
  });
  try {
      
    await redirectsSchema.validate({ answer,question}, { abortEarly: false });

  //
   call_api({
     address_api: `${AppConstants.base_url_api}admin/faq`,
     method_api: "POST",
     body: JSON.stringify({
        db_name: window.location.pathname.split("/")[3],
        answer:answer,
        description:fullAnswer,
        question:question,
     })
   })
     .then(parseJSON)
     .then(([status, j]) => {
       if (status === 200) {
          window.location.href = `/admin/commen-questions-list/${window.location.pathname.split("/")[3]}`;
         toast.success(j?.message);
      this.setState({ errorsList:[] })      
       } else if (status === 401) {
         localStorage.clear();
       } else {
         toast.error(j?.message);
       }
     })
     .catch((error) => {
       console.error(error);
     });
    }
    catch (err: any) {
      this.setState({ errorsList: err.inner })      
    }
 };
}

export default CommenQuestionsCreateController;