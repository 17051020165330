import { Component } from 'react';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { createRef } from "react";
import { call_api, parseJSON } from '../../../core/service';
import { toast } from 'react-toastify';
import { AppConstants } from '../../../core/constants';

interface IProps {
  dispatch?:any,
  siteList?:any,
  loadingSiteList?:any,
}

interface IState {
  loading?: boolean,
  orderLoading?: boolean,
  search: string,
  rows: Array<any>,
  rowData: any,
  page: number,
  rowCount:any,
  rowCountList:any,
  paginate: number,
  allOption:any,
  showFilters:any,
  errorsList?: any,
  dreiverId?:any,
  messagesList?:any;
  total:number

}
 class ContactUsController extends Component<IProps, IState> {
  rowVirtualizerInstanceRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    this.rowVirtualizerInstanceRef = createRef()
    this.state = {
      loading: false,
      orderLoading: false,
      search: "",
      rows: [],
      rowData: {},
      page: 1,
      rowCount:100,
      rowCountList:[10, 25 , 50 , 100],
      paginate: 0,
      allOption:{id:0 ,value:{
        persian_name: "همه",
        name: "*"
      }},
      showFilters:false,
      errorsList: [],
      dreiverId: [],
    total:0,
      messagesList:[]
    }
  }



  paginationSelectTimeOut: string | number | NodeJS.Timeout | undefined; 
  handlePaginationSelect=(event:any)=>{
    this.setState({
      rowCount: event.currentTarget.value,
      page:1
    })
    clearTimeout(this.paginationSelectTimeOut);
    this.paginationSelectTimeOut = setTimeout(() => {
      this.getList()
    }, 800);
    
  }
  driverTimeout: string | number | NodeJS.Timeout | undefined;
  driverSelected = (selected:any, index?:any) : any=> {
    this.setState({ page: 1 })
    if(index?.option?.id === 0)
    {
      this.setState({
        dreiverId: this.props.siteList
      });

    }
    else 
    this.setState({
      dreiverId: selected
    });
    clearTimeout(this.driverTimeout);
    this.driverTimeout = setTimeout(() => {
      this.getList()
    }, 1000);
  }
  searchTimeout: string | number | NodeJS.Timeout | undefined;
  handleSearch = (event: any) => {
    this.setState({ search: event.currentTarget.value })
    clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.getList()
      }, 1000);
  };

  paginationTimeOut: string | number | NodeJS.Timeout | undefined; 
  handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ page: value })
    clearTimeout(this.paginationTimeOut);
    this.paginationTimeOut = setTimeout(() => {
      this.getList()
    }, 800);
  }

  async getList() {
    const {search,page,rowCount,dreiverId} = this.state;
    this.setState({ loading: true })
    const driversSelectedList = [];
      for (var i = 0; i < dreiverId.length; i++) {
        driversSelectedList.push(dreiverId[i]?.value?.name)
      }
    let parameters = {
      search: search ? search: null,
      page: page ? page.toString() : null,
      paginate: rowCount,
      db_name: dreiverId?.length > 0 ? driversSelectedList : null,
    }
    let url = new URL(`${AppConstants.base_url_api}seller/contact`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}seller/contact${url.search}` : `${AppConstants.base_url_api}seller/contact`,
      method_api: "GET",
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
            messagesList: j.data?.data , total: j.data?.total , loading:false
          })
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

}

export default ContactUsController;