import { Component } from "react";
import { Outlet } from "react-router-dom"
import RedirectConfirmController from "../../../controllers/commen/redirect_confirm_controller"
import { LoadingSpinner } from "../../../components";
import { connect } from "react-redux";
import { withRouter, WithRouterProps } from "./withRouter";



class RedirectConfirm extends RedirectConfirmController {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.confirmAPI()
  }
  render() {
    const { match, location } = this.props;
    const {loading } = this.state;
    return (
      <div>
        {loading && <LoadingSpinner />}
      </div>
            )
  }
}
const mapStateToProps = (dispatch:any) => {
  return {
    dispatch,
    siteList:dispatch.general.siteList,
    loadingSiteList:dispatch.general.loadingSiteList,
    userData:dispatch.general.userData,
  }
};

export default connect(mapStateToProps)(withRouter(RedirectConfirm))
