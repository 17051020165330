import { Component } from 'react';

interface IProps {
dispatch?:any,
siteList?:any,
loadingSiteList?:any,
userData?:any
}
interface IState {
  dreiverId?:any,

}

 class WebManagementController extends Component<IProps, IState> {
  state: IState = {
    dreiverId: null,
  };
  driverTimeout: string | number | NodeJS.Timeout | undefined;
  driverSelected = (selected:any) : any=> {
    this.setState({
        dreiverId: selected
      });
  }
}

export default WebManagementController;