import { Component } from "react";
import { createRef } from "react";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { AppConstants } from "../../../core/constants";
import { call_api, parseJSON } from "../../../core/service";
import { toast } from "react-toastify";
import * as yup from 'yup'

interface IProps {
  dispatch?: any,
  siteList?: any,
  loadingSiteList?: any,
  userData?: any
}
interface IState {
  redirectListData: any,
  fileType: string,
  fileExtension: string,
  total: number
  rowCount: any,
  rowCountList: any,
  loading: boolean,
  createLoading: boolean,
  updateLoading: boolean,

  page: number,
  search: string,
  showAddNewItem: boolean,
  newURL: string,
  oldURL: string,
  status: string,
  statusList: Array<any>,
  description: string,
  edit: boolean,
  editItem: any,
  currentItem: any,
  showFilters: boolean
  dreiverId: any,
  errorsList?: any,
  updateErrorsList?: any,
  toggleModal:boolean
  rowData: any;
  type:any;
  typeList:any;
  selectedSearch:any;


}
class RedirectsManageMent extends Component<IProps, IState> {
  tableInstanceRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    this.tableInstanceRef = createRef()
    this.state = {
      redirectListData: [],
      fileType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      fileExtension: ".xlsx",
      total: 0,
      rowCount: 100,
      rowCountList: [10, 25, 50, 100],
      loading: false,
      createLoading: false,
      updateLoading: false,
      page: 1,
      search: "",
      showAddNewItem: false,
      newURL: "",
      oldURL: "",
      status: "301",
      statusList: ["301", "302", "307", "410"],
      description: "",
      edit: false,
      editItem: null,
      currentItem: {},
      showFilters: false,
      dreiverId: this.props.siteList && this.props.siteList?.filter((obj: any) => obj?.value?.name === "aria")[0],
      errorsList: [],
      updateErrorsList: [],
      toggleModal:false,
      rowData: {},
      type:null,
      typeList: [ { title: "301", value: "301" },{ title:"302" , value:"302"}, { title:"307" , value:"307"}, { title:"410" , value:"410"}],
      selectedSearch:null,

    }
  }
  exportToExcel = async (excelData: any, fileName: any) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: "array" });
    const data = new Blob([excelBuffer], { type: this.state.fileType });
    FileSaver.saveAs(data, fileName + this.state.fileExtension);

  }
  paginationSelectTimeOut: string | number | NodeJS.Timeout | undefined;
  handlePaginationSelect = (event: any) => {
    this.setState({
      rowCount: event.currentTarget.value,
      page:1
    })
    clearTimeout(this.paginationSelectTimeOut);
    this.paginationSelectTimeOut = setTimeout(() => {
      this.getDate()
    }, 800);

  }
  paginationTimeOut: string | number | NodeJS.Timeout | undefined;
  handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ page: value })
    clearTimeout(this.paginationTimeOut);
    this.paginationTimeOut = setTimeout(() => {
      this.getDate()
    }, 800);

  }

  searchTimeout: string | number | NodeJS.Timeout | undefined;
  handleSearch = (event: any) => {
    this.setState({ search: event.currentTarget.value })
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.getDate()
    }, 1000);
  };
  statusSelect = (e: any) => {
    this.setState({ status: e.currentTarget.value })

  }

  handleEditForm = (item: any) => {
    this.setState({ edit: true, updateErrorsList : [] , editItem: item,
      rowData: item,
})
  }
  async update() {
    const { editItem } = this.state;
    
    const redirectsSchema = yup.object().shape({
      editItem: yup.object().shape({
        old_url:yup.string().required("ادرس قدیمی الزامی  می باشد.").max(255, 'ادرس قدیمی  بیشتر از 255 رقم نمی باشد'),
        new_url: yup.string().required("ادرس جدید الزامی می باشد.").max(255, 'ادرس جدید  بیشتر از 255 رقم نمی باشد'),
        })
  });
  try {
      
    await redirectsSchema.validate({ editItem }, { abortEarly: false });
    this.setState({
      updateLoading: true
    });
    call_api({
      address_api: `${AppConstants.base_url_api}admin/redirects/${editItem.id_select}`,
      method_api: "PUT",
      body: JSON.stringify({
        db_name:editItem?.db_name,
        old_url: editItem.old_url,
        new_url: editItem.new_url,
        type: editItem.type,
        desc: editItem.desc
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.getDate();
          this.setState({
            updateLoading: false,
            updateErrorsList: [],
            edit:false,
            editItem: null,
          });
         
          toast.success(j?.message);
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }catch (err: any) {
      this.setState({ updateErrorsList: err.inner })      

    }
  
  };
  
  driverTimeout: string | number | NodeJS.Timeout | undefined;
  driverSelected = (selected:any, index?:any) : any=> {
    this.setState({ page: 1 })
    this.setState({
      dreiverId: selected
    });
    clearTimeout(this.driverTimeout);
    this.driverTimeout = setTimeout(() => {
      this.getDate()
    }, 1000);
  }
  async getDate() {
    const { search, rowCount, dreiverId , page , type ,  selectedSearch} = this.state;
    this.setState({ loading: true })
    let parameters = {
      page: page ? page.toString() : null,
      paginate: rowCount,
      db_name: dreiverId?.value?.name.toString(),
      type: type ? type?.value : null ,
      old_url:selectedSearch === "0"  ? search : null,
      new_url:selectedSearch === '1' ? search : null,
      search: selectedSearch === null ? search : null,
      
    }
    let url = new URL(`${AppConstants.base_url_api}admin/redirects`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}admin/redirects${url.search}` : `${AppConstants.base_url_api}admin/redirects`,
      method_api: "GET",
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
            redirectListData: j.data?.data,
            loading: false,
            total: j?.data?.meta?.total
          })

        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  async create() {
    const { newURL, oldURL, status, description , dreiverId } = this.state;
    
    const redirectsSchema = yup.object({
      oldURL:yup.string().required("ادرس قدیمی الزامی  می باشد.").max(255, 'ادرس قدیمی  بیشتر از 255 رقم نمی باشد'),
      newURL:yup.string().required("ادرس جدید الزامی می باشد.").max(255, 'ادرس جدید  بیشتر از 255 رقم نمی باشد'),
  });
  try {
      
    await redirectsSchema.validate({ newURL, oldURL, status, description}, { abortEarly: false });
    this.setState({
      createLoading: true
    });
    call_api({
      address_api: `${AppConstants.base_url_api}admin/redirects`,
      method_api: "POST",
      body: JSON.stringify({
        db_name:dreiverId?.value?.name,
        old_url: oldURL,
        new_url: newURL,
        type: status,
        desc: description
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.getDate();
          this.setState({
            createLoading: false,
            errorsList: [],
            oldURL: "",
            newURL: "",
            description:"",
            showAddNewItem:false,
            status: "301",
          });
         
          toast.success(j?.message);
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }catch (err: any) {
      this.setState({ errorsList: err.inner })      
    }
  
  };
  async handleDelete() {
    const { rowData } = this.state;
    call_api({
      address_api: `${AppConstants.base_url_api}admin/redirects/${rowData.id_select}`,
      method_api: "DELETE"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
        toast.success(j?.message);
          this.setState({
            toggleModal: false,
            edit: false
          })
          this.getDate();
  
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  typeTimeout: string | number | NodeJS.Timeout | undefined;
  typeSelected = (choice: any) => {
    this.setState({ type: choice })
    clearTimeout(this.typeTimeout);
    this.typeTimeout = setTimeout(() => {
      this.getDate()
    }, 100);
  }
  
  selectSearchTimeOut: string | number | NodeJS.Timeout | undefined; 
  handleSelectSearch=( event: React.MouseEvent<HTMLElement>,
      value: any)=>{
      this.setState({
        selectedSearch:value === "null" ? JSON.parse(value) : value,
      })
      clearTimeout(this.selectSearchTimeOut);
      this.paginationSelectTimeOut = setTimeout(() => {
        this.getDate()
      }, 1000);
    }
}
export default RedirectsManageMent;