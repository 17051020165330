import {
  FETCH_TAG_SELECTED,
  FETCH_BRAND_SELECTED,
  FETCH_CATEGORY_SELECTED
} from "../type";

const initialState = {
  tagFilter:{},
  brandFilter:{},
  categoryFilter:{},

};

const productFilterReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_TAG_SELECTED:
      return {
        ...state,
        tagFilter: action.payload,
      };
      case FETCH_BRAND_SELECTED : 
      return {
        ...state,
        brandFilter: action.payload,
      };
      case FETCH_CATEGORY_SELECTED : 
      return {
        ...state,
        categoryFilter: action.payload,
      };
    default:
      return state;
  }
};

export default productFilterReducer;
