import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import moment from 'moment-jalaali';
import Select, { components, CSSObjectWithLabel } from "react-select";
import { alertButtonsState, numberWithCommas } from "../../../../core/utils";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BsCheckSquare, BsEnvelope, BsFillEnvelopeFill } from "react-icons/bs";
import { AiOutlineCloseCircle, AiOutlineFileExcel, AiOutlineEdit, AiOutlineDelete, AiOutlineCheckSquare } from "react-icons/ai";
import { RadioSquare, SubmissionAlert, Accordion, Header, InputIcon, LoadingSpinner, Option } from "../../../components";
import { CustomButton, DropDownIcon } from "../../../components";
import { FiAlertTriangle } from "react-icons/fi";
import { InputTextLabel } from "../../../components/input_text_label";
import { createTheme, ThemeProvider } from "@mui/material";
import { Link } from "react-router-dom";
import { FaBan, FaRegDotCircle } from "react-icons/fa";
import { BiShowAlt, BiUnlink } from "react-icons/bi";
import Pagination from "@mui/material/Pagination";
import {BiCopy} from "react-icons/bi";
import Tooltip from "@mui/material/Tooltip";
import { BiRecycle } from "react-icons/bi";
import { connect } from "react-redux";
import { TfiFilter } from "react-icons/tfi";
import { Helmet } from "react-helmet";
import {ProductsListController} from "../../../controllers";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { toast } from "react-toastify";
import {LiaMinusSquare} from "react-icons/lia";
import {
  AiOutlinePlusCircle,
  AiOutlineMinusCircle,
  AiOutlineCheckCircle,
} from "react-icons/ai";

class ProductsList extends ProductsListController {

  componentDidMount() {
   window.scrollTo(0, 0);
    this.getCategoryList();
    this.getBrandList();
   this.getProductsList();
   this.getTagSearch();

  }
  render() {
    type ListOfData = {
      item?: any;
      row: number;
      select: any;
      link:any;
      code: string;
      driver: any;
      name: string;
      writer: string;
    }; 
    const { siteList, loadingSiteList } = this.props; 
    const { 
      description,
      priceReduction,
      showSyncFilter,
      syncStatus,
      allOption,
      showFilters,
      category,
      errorsList,
      rowCountList,
      priceIncrease,
      openBuyPriceModal,
      buyPrice,
      dreiverId,
      brandId,
      brandList,
      categoryList,
      statusList,
      statusId,
      emptyList,
      emptyId,
      loading,
      toggleModal,
       productListData,
       tag,
       tagList,
       tagLoading,
       writers,
       toggleShowNotesModal,
       writersList,
      detailPannelData,
      detailPannelLoading,
      rowCount,
      search,
      submitLoading,
      groupChangeOptions,
      groupDiscount,
      weight,
      selectedList,
      groupPrice,
      inventory,
      openGroupOperationModal,
      groupChangeSelectedOption,
      openLinkModal,
      relationshipProducts,
      syncStatus2,
      confirmUnlink,
      unlinkedItem,
      selectedDbName,
      chooseDbNameModal,
      dbNamesList,
      page,
      selectedRowIndex
    } = this.state;
    const DropdownIndicator = (props: any) => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator
            style={{ color: "red" }}
            {...props}
          ></components.DropdownIndicator>
        )
      );
    };
    const theme = createTheme({
      typography: {
        fontFamily: "Vazir",
      },
    });
    const columns = [
      {
        accessorKey: "row",
        header: "ردیف",
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        size: 10,
      },
      {
        accessorKey: "select",
        header: "انتخاب",
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        size: 10,
      },
      {
        accessorKey: "link",
        header: "مرتبط سازی",
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        size: 10,
      },
      {
        accessorKey: "driver",
        header: "سایت",
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        size: 10,
      },
      {
        accessorKey: "name",
        header: "نام محصول",
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "right",
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
      },
      {
        accessorKey: "writer",
        header: "نویسنده",
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        invertSorting:true,
        sortingFn: (rowA, rowB, columnId) => {
          return Number(rowA.original.item.price) - Number(rowB.original.item.price)

        }
      },
    ] as MRT_ColumnDef<ListOfData>[];
    return (
      <ThemeProvider theme={theme}>
        <div className="price-list products-list">
          <Helmet>
            <title>لیست محصولات</title>
          </Helmet>
          <Header isActive= "isProducts" title="لیست محصولات"/>
          <div className="custom-table">
            <div className="custom-card">
              <div className="filter-select-container pe-3">
                <div className="filter-options-container">
                  {showFilters && (
                    <>
                      <div className="column-30">
                        <Select
                          styles={{
                            control: (baseStyles: any, state: any):any => ({
                              ...baseStyles,
                              boxShadow:"rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",
                              borderWidth: "1px",
                              backgroundColor: "#fff",
                              display: "flex",
                              flexDirection: "row-reverse",
                              textAlign: "right",
                              borderRadius: "4px",
                              outline: "unset",
                            }),
                            menuPortal: (base):any => ({ ...base, zIndex: 9999 })
                          }}
                          onChange={this.driverSelected}
                          components={{ Option }}
                          value={dreiverId}
                          isMulti={true}
                          closeMenuOnSelect={true}
                          noOptionsMessage={() => "این مورد وجود ندارد!"}
                          menuPortalTarget={document.body}
                          options={[ allOption, ...siteList]}
                          getOptionLabel={(option: any) =>
                            option?.value?.persian_name
                          }
                          getOptionValue={(option: any) => option?.value?.name}
                          placeholder={ showSyncFilter === true  ? "سایت" :  "محصولات لینک شده"}
                          isClearable
                          loadingMessage={() => "در حال بارگذاری  ..."}
                          isLoading={loadingSiteList}
                        />
                      </div>
                      <div className="column-30">
                        <div className="wrapper" >
                        <Accordion
                          title="فیلترها"
                          content={
                            <>
                              <Select
                                styles={{
                                  control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                                    ...baseStyles,
                                    boxShadow: state.isFocused
                                      ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                      : "",
                                    borderWidth: "1px",
                                    backgroundColor: "#fff",
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    textAlign: "right",
                                    borderRadius: "4px",
                                    minHeight: "20px",                                  
                                    outline: "unset",
                                    marginBottom: "10px",
                                  }) as CSSObjectWithLabel,
                                }}
                                onChange={(choice: any) =>
                                  this.brandSelected(choice)
                                }
                                components={{ DropdownIndicator }}
                                value={brandId}
                                isMulti={true}
                                closeMenuOnSelect={true}
                                options={brandList}
                                noOptionsMessage={() => "این مورد وجود ندارد!"}
                                getOptionLabel={(option: any) =>
                                  option?.persian_name
                                }
                                getOptionValue={(option: any) => option?.id}
                                placeholder="همه برندها "
                                isClearable
                              />

                              <Select
                                styles={{
                                  control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                                    ...baseStyles,
                                    boxShadow: state.isFocused
                                      ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                      : "",
                                    borderWidth: "1px",
                                    backgroundColor: "#fff",
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    textAlign: "right",
                                    borderRadius: "4px",
                                    minHeight: "20px",
                                   
                                    outline: "unset",
                                    marginBottom: "10px",
                                  }) as CSSObjectWithLabel,
                                }}
                                onChange={(choice: any) =>
                                  this.categorySelected(choice)
                                }
                                components={{ DropdownIndicator }}
                                value={category}
                                isMulti={true}
                                noOptionsMessage={() => "این مورد وجود ندارد!"}
                                closeMenuOnSelect={true}
                                options={categoryList}
                                getOptionLabel={(option: any) =>
                                  option.persian_name
                                }
                                getOptionValue={(option: any) => option.id}
                                placeholder="همه دسته بندی‌ها"
                                isClearable
                              />

                              <Select
                                styles={{
                                  control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                                    ...baseStyles,
                                    boxShadow: state.isFocused
                                      ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                      : "",
                                    borderWidth: "1px",
                                    backgroundColor: "#fff",
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    textAlign: "right",
                                    borderRadius: "4px",
                                    minHeight: "20px",
                                   
                                    outline: "unset",
                                    marginBottom: "10px",
                                  }) as CSSObjectWithLabel,
                                }}
                                onChange={(choice: any) =>
                                  this.statusSelected(choice)
                                }
                                components={{ DropdownIndicator }}
                                value={statusId}
                                isMulti={true}
                                closeMenuOnSelect={true}
                                noOptionsMessage={() => "این مورد وجود ندارد!"}
                                options={statusList}
                                getOptionLabel={(option: any) => option.title}
                                getOptionValue={(option: any) => option.value}
                                placeholder="وضعیت"
                                isClearable
                              />
                              <Select
                                styles={{
                                  control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                                    ...baseStyles,
                                    boxShadow: state.isFocused
                                      ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                      : "",
                                    borderWidth: "1px",
                                    backgroundColor: "#fff",
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    textAlign: "right",
                                    borderRadius: "4px",
                                    minHeight: "20px",
                                   
                                    outline: "unset",
                                    marginBottom: "10px",
                                  }) as CSSObjectWithLabel,
                                }}
                                onChange={(choice: any) =>
                                  this.emptySelected(choice)
                                }
                                components={{ DropdownIndicator }}
                                value={emptyId}
                                isMulti={true}
                                closeMenuOnSelect={true}
                                noOptionsMessage={() => "این مورد وجود ندارد!"}
                                options={emptyList}
                                getOptionLabel={(option: any) => option.title}
                                getOptionValue={(option: any) => option.value}
                                placeholder="بدون چی ؟ "
                                isClearable
                              />
                              <Select
                            styles={{
                              control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                                ...baseStyles,
                                boxShadow: state.isFocused
                                  ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                  : "",
                                borderWidth: "1px",
                                backgroundColor:"#fff",
                                display: "flex",
                                flexDirection: "row-reverse",
                                textAlign: "right",
                                borderRadius: "4px",
                                minHeight: "20px",
                               
                                outline: "unset",
                                marginBottom: "10px",
                              }) as CSSObjectWithLabel,
                            }}
                            onChange={(choice: any) =>
                              this.tagSelected(choice)
                            }
                            onInputChange={(choice: any) => this.handleTagSearch(choice)}
                            value={tag}
                            isMulti={true}
                            noOptionsMessage={() => "این مورد وجود ندارد!"}
                            closeMenuOnSelect={true}
                            options={tagList}
                            getOptionLabel={(option: any) =>
                              option.persian_name
                            }
                            isLoading={tagLoading}
                            getOptionValue={(option: any) => option.id}
                            placeholder="برچسب"
                            isClearable
                            />
                                 <Select
                                styles={{
                                  control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                                    ...baseStyles,
                                    boxShadow: state.isFocused
                                      ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                      : "",
                                    borderWidth: "1px",
                                    backgroundColor:"#fff",
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    textAlign: "right",
                                    borderRadius: "4px",
                                    minHeight: "20px",
                                   
                                    outline: "unset",
                                    marginBottom: "10px",
                                  }) as CSSObjectWithLabel,
                                }}
                                onChange={(choice: any) =>
                                    this.writerSelected(choice)
                                }
                                value={writers}
                                isMulti={true}
                                noOptionsMessage={() => "این مورد وجود ندارد!"}
                                closeMenuOnSelect={true}
                                options={writersList}
                                getOptionLabel={(option: any) =>
                                    option.persian_name
                                }
                                isLoading={tagLoading}
                                getOptionValue={(option: any) => option.id}
                                placeholder="نویسندگان"
                                isClearable
                                />
                               
                            </>
                          }
                        />
                      </div>
                      </div>
                    
                      <div className="column-30">
                        <InputTextLabel
                          type={"text"}
                          myStyle="search-input"
                          value={search}
                          onChange={(event) => this.handleSearch(event)}
                          placeholder="جستجو..."
                        />
                      </div>
                      {showSyncFilter && 
                      <div className="column-30 mb-1 d-flex align-items-center">
                      <ToggleButtonGroup
      value={syncStatus2}
      exclusive
      onChange={this.handlesyncProducts}
      aria-label="text alignment"
    >
      <ToggleButton value="0" aria-label="left aligned">
      لینک نشده
      </ToggleButton>
      <ToggleButton value="null" aria-label="centered">
        همه
      </ToggleButton>
      <ToggleButton value="1" aria-label="right aligned">
      لینک شده
      </ToggleButton>
      
    </ToggleButtonGroup>
                      </div>
                      }
                      </>
                  )}
                </div>
              </div>
              <div>
                {confirmUnlink && <SubmissionAlert
                      isOpen={confirmUnlink}
                      title="اخطار غیرمرتبط‌ کردن!"
                      setClose={(open: boolean) =>
                        this.setState({confirmUnlink: false })
                      }
                      onClick={() => this.confirmUnlinkSingle()}
                      icon={<FiAlertTriangle color="orange" fontSize="50" />}
                      details="آیا از غیرمرتبط‌ کردن محصول زیر اطمینان دارید؟"
                      extraInfoFirstRow={siteList && siteList?.filter((obj: any) => obj?.value?.name === unlinkedItem?.db_name)[0]?.value?.logo}
                      extraInfoSecondRow={unlinkedItem?.db_name}
                      extraInfoDetail={unlinkedItem?.product_name}
                      />}
                {toggleModal.status === true &&
                  toggleModal.buttonClick === alertButtonsState.DELETE && (
                    <SubmissionAlert
                      isOpen={toggleModal.status}
                      title="اخطار!"
                      setClose={(open: boolean) =>
                        this.setState({
                          toggleModal: {
                            status: open,
                            buttonClick: alertButtonsState.DELETE,
                          },
                        })
                      }
                      onClick={() => Number(syncStatus) === 1 ? this.handleDeleteSingleSync() : this.handleDeleteSingle()}
                      icon={<FiAlertTriangle color="orange" fontSize="50" />}
                      details="از این عملیات اطمینان دارید؟"
                    />
                  )}
                {toggleModal.status === true &&
                  toggleModal.buttonClick ===
                    alertButtonsState.NOTCONFIRMED && (
                    <SubmissionAlert
                      isOpen={toggleModal.status}
                      title="اخطار!"
                      icon={<FiAlertTriangle color="orange" fontSize="50" />}
                      details="از این عملیات اطمینان دارید؟"
                      setClose={(open: boolean) =>
                        this.setState({
                          toggleModal: {
                            status: open,
                            buttonClick: alertButtonsState.NOTCONFIRMED,
                          },
                        })
                      }
                      onClick={() => Number(syncStatus) === 1 ? this.handleConfirmSingleSync() : this.handleConfirmSingle()}
                    />
                  )}
                {toggleModal.status === true &&
                  toggleModal.buttonClick === alertButtonsState.NOSELLING && (
                    <SubmissionAlert
                      isOpen={toggleModal.status}
                      title="اخطار!"
                      setClose={(open: boolean) =>
                        this.setState({
                          toggleModal: {
                            status: open,
                            buttonClick: alertButtonsState.NOSELLING,
                          },
                        })
                      }
                      onClick={() => Number(syncStatus) === 1 ? this.handleChangeStatusSingleSync() : this.handleChangeStatusSingle()}
                      icon={<FiAlertTriangle color="orange" fontSize="50" />}
                      details="از این عملیات اطمینان دارید؟"
                    />
                  )}
                {toggleModal.status === true &&
                  toggleModal.buttonClick === alertButtonsState.RECYCLE && (
                    <SubmissionAlert
                      isOpen={toggleModal.status}
                      title="اخطار!"
                      setClose={(open: boolean) =>
                        this.setState({
                          toggleModal: {
                            status: open,
                            buttonClick: alertButtonsState.RECYCLE,
                          },
                        })
                      }
                      onClick={() => Number(syncStatus) === 1 ? this.handleRecycleSubmitSync() : this.handleRecycleSubmit()}
                      icon={<FiAlertTriangle color="orange" fontSize="50" />}
                      details="از این عملیات اطمینان دارید؟"
                    />
                  )}

                  {toggleModal.status === true && toggleModal.buttonClick === alertButtonsState.UNLINK &&

                    <SubmissionAlert isOpen={toggleModal.status} title="اخطار!" setClose={(open: boolean) => this.setState({ toggleModal: { status: open, buttonClick: alertButtonsState.UNLINK } })} onClick={() => this.handleUnLinkSingleSync()} icon={<FiAlertTriangle color="orange" fontSize="50" />}
                      details="از این عملیات اطمینان دارید؟" />
                    }
                 <Modal
                open={chooseDbNameModal}
                onClose={this.handleCloseBuyPriceModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    overflow: "scroll",
                    width: {
                      xxs: "90%",
                      xs: "90%",
                      sm: "90%",
                      md: "80%",
                      lg: "70%",
                      xl: "70%",
                    },
                    height: "max-content",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
انتخاب سایت
                  </Typography>
                  <Grid container>
                    <div className="table-responsive w-100">
                    <table className="table p-5 text-center">
                      <thead>
                        <tr>
                        {dbNamesList && dbNamesList.length > 0 && dbNamesList.map((item:any, index:number) =>{
                      
                      return(
                        <th>
                          {item?.db_name}
                        </th>
                      )
                      
                    })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                        {dbNamesList && dbNamesList.length > 0 && dbNamesList.map((item:any, index:number) =>{
                      
                      return(
                        <td>
                          <RadioSquare
                        title={""}
                        key={item.id_select}
                        onClick={() => this.setState({selectedDbName: {db_name: item?.db_name, id_select: item?.id_select}})}
                        isActive={selectedDbName.db_name === item?.db_name
                          ? true
                          : false}
                          />
                        </td>
                      )
                      
                    })}                          
                        </tr>
                      </tbody>
                    </table>
                    </div>
                    
                  </Grid>
                  <Grid sx={{display:"flex", justifyContent:"space-between"}}>
                  {selectedDbName.db_name !== "" &&
                  <Link target="_blank" to={`/admin/products/edit/${selectedDbName.id_select}`} >
                  <span>تایید</span>
                  </Link>
                  }
                  <span onClick={()=>this.setState({chooseDbNameModal : false})}>انصراف</span>
                  </Grid>
                </Box>
              </Modal> 
              <Modal
                open={openBuyPriceModal}
                onClose={this.handleCloseBuyPriceModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    overflow: "scroll",
                    width: {
                      xxs: "90%",
                      xs: "90%",
                      sm: "90%",
                      md: "80%",
                      lg: "70%",
                      xl: "70%",
                    },
                    height: "max-content",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    قیمت خرید تامین کنندگان
                  </Typography>
                  <Grid container>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">کد</th>
                          <th scope="col">نام</th>
                          <th scope="col">قیمت</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1234</td>
                          <td>تستر شبکه نود یاب ونتولینک مدل NF-8601</td>
                          <td className="d-flex flex-column">
                            <InputIcon
                              value={buyPrice}
                              type={"number"}
                              // defaultValue={1}
                              min="1"
                              onChange={(event) =>
                                this.setState({
                                  buyPrice: event.currentTarget.value,
                                })
                              }
                            />
                            <InputIcon
                              value={description}
                              type={"text"}
                              onChange={(event) =>
                                this.setState({
                                  description: event.currentTarget.value,
                                })
                              }
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Grid>
                  <CustomButton loading={false} title="تایید" />
                </Box>
              </Modal>
              <Modal
                open={openGroupOperationModal}
                onClose={this.handleCloseGroupOperationModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  className="modal-box"
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    overflow: "scroll",
                    width: {
                      xxs: "90%",
                      xs: "90%",
                      sm: "90%",
                      md: "80%",
                      lg: "70%",
                      xl: "70%",
                    },
                    height: "70%",
                    display: "block",
                  }}
                >
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    عملیات گروهی
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid
                      item={true}
                      xs={12}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Grid item sx={{ color: "red", margin: "20px" }}>
                        توجه در صورت عدم نیاز به هر یک از فیلدهای زیر حتما آن را
                        خالی بگذارید.
                      </Grid>
                    </Grid>
                    <Grid item={true} xs={12}>
                      <DropDownIcon
                        value={groupChangeSelectedOption}
                        title={"نوع تغییر گروهی"}
                        placeholder={"نوع تغییر گروهی را انتخاب نمایید"}
                        type={"text"}
                        optionList={groupChangeOptions}
                        onChange={(event) =>
                          this.setState({
                            groupChangeSelectedOption: event.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      {groupChangeSelectedOption === "تغییر ثابت" && (
                        <Grid container>
                          <Grid
                            item={true}
                            lg={3}
                            md={12}
                            xs={12}
                            sx={{ padding: "10px" }}
                          >
                            <InputIcon
                              placeholder={"تغییر وزن"}
                              type={"number"}
                              value={weight}
                              onChange={(event) =>
                                this.setState({
                                  weight: event.currentTarget.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid
                            item={true}
                            lg={3}
                            md={12}
                            xs={12}
                            sx={{ padding: "10px" }}
                          >
                            <InputIcon
                              placeholder={"تغییر موجودی"}
                              type={"number"}
                              value={inventory}
                              onChange={(event) =>
                                this.setState({
                                  inventory: event.currentTarget.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid
                            item={true}
                            lg={3}
                            md={12}
                            xs={12}
                            sx={{ padding: "10px" }}
                          >
                            <InputIcon
                              placeholder={"تغییر قیمت"}
                              type={"text"}
                              value={numberWithCommas(groupPrice)}
                              onChange={(event) => {
                                var value = event.target.value.replace(
                                  /,/g,
                                  ""
                                );
                                const re = /^[0-9\b]+$/;
                                if (value === "" || re.test(value)) {
                                  this.setState({ groupPrice: value });
                                }
                              }}
                            />
                          </Grid>
                          <Grid
                            item={true}
                            lg={3}
                            md={12}
                            xs={12}
                            sx={{ padding: "10px" }}
                          >
                            <InputIcon
                              placeholder={"تغییر تخفیف"}
                              type={"text"}
                              value={numberWithCommas(groupDiscount)}
                              onChange={(event) => {
                                var value = event.target.value.replace(
                                  /,/g,
                                  ""
                                );
                                const re = /^[0-9\b]+$/;
                                if (value === "" || re.test(value)) {
                                  this.setState({ groupDiscount: value });
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {groupChangeSelectedOption === "تغییر درصدی افزایشی" && (
                        <Grid container>
                          <Grid
                            item={true}
                            className="d-flex flex-column"
                            xs={12}
                          >
                            <label className="change-price">
                              تغییر قیمت به درصد ( فقط عدد وارد شود)
                            </label>
                            <InputIcon
                              inBox={"تغییر قیمت"}
                              type={"text"}
                              errortext={
                                errorsList &&
                                errorsList?.filter(
                                  (obj: any) => obj.path === "priceIncrease"
                                )[0]?.message
                              }
                              name="priceIncrease"
                              value={priceIncrease}
                              onChange={(event) => this.priceIncrease(event)}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {groupChangeSelectedOption === "تغییر درصدی کاهشی" && (
                        <Grid container>
                          <Grid
                            item={true}
                            className="d-flex flex-column"
                            xs={12}
                          >
                            <label>
                              تغییر قیمت به درصد ( فقط عدد وارد شود)
                            </label>
                            <InputIcon
                              inBox={"تغییر قیمت"}
                              type={"text"}
                              errortext={
                                errorsList &&
                                errorsList?.filter(
                                  (obj: any) => obj.path === "priceReduction"
                                )[0]?.message
                              }
                              name="priceReduction"
                              value={priceReduction}
                              onChange={(event) => this.priceReduction(event)}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "20px",
                      }}
                    >
                      <Grid item={true} lg={2} md={12} xs={12}>
                        <CustomButton
                          myStyle="bg-red  price-list-btn"
                          icon={<AiOutlineDelete />}
                          onClick={() =>Number(syncStatus) === 1 ?  this.handleDeleteMultipleSync() :this.handleDeleteMultiple()}
                          loading={false}
                          title="حذف کردن"
                        />
                      </Grid>
                      <Grid item={true} lg={2} md={12} xs={12}>
                        <CustomButton
                          myStyle="bg-purple price-list-btn"
                          icon={<BiRecycle />}
                          onClick={() =>Number(syncStatus) === 1 ?  this.handleRecoveryMultipleSync() :this.handleRecoveryMultiple()}
                          loading={false}
                          title="بازیابی کردن"
                        />
                      </Grid>
                      <Grid item={true} lg={2} md={12} xs={12}>
                        <CustomButton
                          myStyle="bg-light-green  price-list-btn"
                          icon={<AiOutlineCheckCircle />}
                          loading={false}
                          title="تایید محصول"
                          onClick={() => Number(syncStatus) === 1 ?  this.handleConfirmeMultipleSync(1) : this.handleConfirmeMultiple(1)}
                        />
                      </Grid>
                      <Grid item={true} lg={2} md={12} xs={12}>
                        <CustomButton
                          myStyle="bg-gray  price-list-btn"
                          icon={<AiOutlineMinusCircle />}
                          loading={false}
                          title="عدم تایید "
                          onClick={() => Number(syncStatus) === 1 ?  this.handleConfirmeMultipleSync(0) : this.handleConfirmeMultiple(0)}
                        />
                      </Grid>
                      <Grid item={true} lg={2} md={12} xs={12}>
                        <CustomButton
                          myStyle="bg-blue price-list-btn"
                          icon={<AiOutlinePlusCircle />}
                          loading={false}
                          title="قابل فروش"
                          onClick={() => Number(syncStatus) === 1 ?  this.handleChangeMultipleStatusSync("do_sell") :
                            this.handleChangeMultipleStatus("do_sell")
                          }
                        />
                      </Grid>
                      {Number(syncStatus) === 1  && 
                      <Grid item={true} lg={2} md={12} xs={12}>
                        <CustomButton
                          myStyle="bg-orange price-list-btn"
                          icon={<FaBan />}
                          loading={false}
                          title="غیر مرتبط کردن "
                          onClick={() => this.handleChangeMultipleLinkSync() 
                          }
                        />
                      </Grid>
                      }
                        <Grid item={true} lg={2} md={12} xs={12}>
                        <CustomButton
                          myStyle="bg-yellow  price-list-btn"
                          icon={<FaBan />}
                          loading={false}
                          title="index"
                          onClick={() =>this.handleChangeMultipleIndex("1") 
                          }
                        />
                      </Grid>
                      <Grid item={true} lg={2} md={12} xs={12}>
                        <CustomButton
                          myStyle="bg-hot-pink  price-list-btn"
                          icon={<FaBan />}
                          loading={false}
                          title="no-index"
                          onClick={() => this.handleChangeMultipleIndex("0") 
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <CustomButton
                    myStyle="final-submission-btn"
                    title="ثبت نهایی "
                    loading={submitLoading}
                    onClick={() =>  this.handleGroupOperation()}
                  />
                </Box>
              </Modal>
              <Modal
                  open={openLinkModal}
                  onClose={this.handleCloseLinkModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    overflow:"scroll",
                    width: {
                      xxs: "90%",
                      xs: "90%",
                      sm: "90%",
                      md: "80%",
                      lg: "70%",
                      xl: "70%"
                    },
                    height: 'max-content',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                  }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                      محصولات مرتبط
                    </Typography>
                    <table className="table">
                      <thead>
                        <tr>
                        <th scope="col">عملیات</th>
                          <th scope="col">سایت</th>
                          <th scope="col">نام</th>
                          <th scope="col">شناسه</th>
                          <th scope="col">قیمت</th>
                          <th scope="col">تخفیف</th>
                        </tr>
                      </thead>
                      <tbody>
                      {relationshipProducts?.map((item: any, index: number) => {
                    return (
                      
                     
                      <tr key={index}>
                          <td><div className="d-flex">
                            {Number(syncStatus) === 1 &&   <RadioSquare
                        title={""}
                        onClick={() => this.handleSelectedSiteModal(index)}
                        isActive={item?.site_select === true ? true : false}
                      /> 
                      }
                       <Tooltip title="غیر مرتبط کردن "><span> <BiUnlink onClick={() => this.handleUnLinkSingle(item)} className='icon-red px-1'/></span></Tooltip>
                      </div>
                      </td>
                          <td><img src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo} alt="..."/></td>
                          <td>{item?.product_name}</td>
                          <td>{item?.id}</td>
                          <td >
                            <input
                              name="price"
                              className="form-control dir-ltr"
                              value={numberWithCommas(item.price)}
                              type={"text"}
                              onChange={(event) => this.changeRelationshipProductsPrice(event , item , index)}
                            />
                          </td>
                            <td >
                              <input
                                type={"text"}
                                name="discount"
                                className="form-control dir-ltr"
                                value={numberWithCommas(item.discount)}
                                onChange={(event) => this.changeRelationshipProductsDiscount(event , item , index)}
                          />
                            </td>
                        </tr>
                    );
                  }
                  )}
                        
                      </tbody>
                    </table>
                  </Box>
                </Modal>
                <Modal
                open={toggleShowNotesModal}
                onClose={() => this.setState({toggleShowNotesModal: false})}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    overflow: "scroll",
                    width: {
                      xxs: "90%",
                      xs: "90%",
                      sm: "90%",
                      md: "80%",
                      lg: "70%",
                      xl: "70%",
                    },
                    height: "max-content",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    لیست یادداشت‌ها
                  </Typography>
                  <Grid container>
                    <ul>
                      <li>یادداشت</li>
                    </ul>
                  </Grid>
                </Box>
              </Modal>
                {toggleModal.status === true &&
                  toggleModal.buttonClick === alertButtonsState.DELETE && (
                    <SubmissionAlert
                      isOpen={toggleModal.status}
                      title="اخطار!"
                      setClose={(open: boolean) =>
                        this.setState({
                          toggleModal: {
                            status: open,
                            buttonClick: alertButtonsState.DELETE,
                          },
                        })
                      }
                      onClick={() => Number(syncStatus) === 1 ? this.handleDeleteSingleSync() : this.handleDeleteSingle()}
                      icon={<FiAlertTriangle color="orange" fontSize="50" />}
                      details="از این عملیات اطمینان دارید؟"
                    />
                  )}
                {toggleModal.status === true &&
                  toggleModal.buttonClick ===
                    alertButtonsState.NOTCONFIRMED && (
                    <SubmissionAlert
                      isOpen={toggleModal.status}
                      title="اخطار!"
                      icon={<FiAlertTriangle color="orange" fontSize="50" />}
                      details="از این عملیات اطمینان دارید؟"
                      setClose={(open: boolean) =>
                        this.setState({
                          toggleModal: {
                            status: open,
                            buttonClick: alertButtonsState.NOTCONFIRMED,
                          },
                        })
                      }
                      onClick={() => Number(syncStatus) === 1 ? this.handleConfirmSingleSync() : this.handleConfirmSingle()}
                    />
                  )}
                {toggleModal.status === true &&
                  toggleModal.buttonClick === alertButtonsState.NOSELLING && (
                    <SubmissionAlert
                      isOpen={toggleModal.status}
                      title="اخطار!"
                      setClose={(open: boolean) =>
                        this.setState({
                          toggleModal: {
                            status: open,
                            buttonClick: alertButtonsState.NOSELLING,
                          },
                        })
                      }
                      onClick={() => Number(syncStatus) === 1 ? this.handleChangeStatusSingleSync() : this.handleChangeStatusSingle()}
                      icon={<FiAlertTriangle color="orange" fontSize="50" />}
                      details="از این عملیات اطمینان دارید؟"
                    />
                  )}
                    {toggleModal.status === true &&
                  toggleModal.buttonClick === alertButtonsState.NOINDEX && (
                    <SubmissionAlert
                      isOpen={toggleModal.status}
                      title="اخطار!"
                      setClose={(open: boolean) =>
                        this.setState({
                          toggleModal: {
                            status: open,
                            buttonClick: alertButtonsState.NOINDEX,
                          },
                        })
                      }
                      onClick={() => this.handleChangeIndexSingle()}
                      icon={<FiAlertTriangle color="orange" fontSize="50" />}
                      details="از این عملیات اطمینان دارید؟"
                    />
                  )}
                {toggleModal.status === true &&
                  toggleModal.buttonClick === alertButtonsState.RECYCLE && (
                    <SubmissionAlert
                      isOpen={toggleModal.status}
                      title="اخطار!"
                      setClose={(open: boolean) =>
                        this.setState({
                          toggleModal: {
                            status: open,
                            buttonClick: alertButtonsState.RECYCLE,
                          },
                        })
                      }
                      onClick={() => Number(syncStatus) === 1 ? this.handleRecycleSubmitSync() : this.handleRecycleSubmit()}
                      icon={<FiAlertTriangle color="orange" fontSize="50" />}
                      details="از این عملیات اطمینان دارید؟"
                    />
                  )}

                  {toggleModal.status === true && toggleModal.buttonClick === alertButtonsState.UNLINK &&

                    <SubmissionAlert isOpen={toggleModal.status} title="اخطار!" setClose={(open: boolean) => this.setState({ toggleModal: { status: open, buttonClick: alertButtonsState.UNLINK } })} onClick={() => this.handleUnLinkSingleSync()} icon={<FiAlertTriangle color="orange" fontSize="50" />}
                      details="از این عملیات اطمینان دارید؟" />
                    }
              </div>
              <MaterialReactTable
              key={productListData?.data?.length}
                muiTablePaperProps={{
                  sx: {
                    mr: "1.5rem",
                    ml: "1.5rem",
                    border: "1px solid #e5e5e5",
                  },
                }}
                muiTableBodyRowProps={({ row, staticRowIndex }) => ({
                  sx: {
                    height: "fit-content",
                    backgroundColor: selectedList.find(
                      (c: { id_select: any }) => c.id_select === row.original?.item?.id_select
                    )
                      ? "#d1ecfb"
                      : (staticRowIndex % 2 === 0 ) ? '#f3f3f3'  : "",
                      }})}
                  muiExpandButtonProps={( cell:any ) => ({
                  onClick: (event:any) => {
                      this.handleDetailPannel(cell?.row?.original?.item, cell?.row?.index)
                  }
                })}
                columns={columns}
                data={
                  productListData &&
                  productListData?.length > 0 &&
                  productListData
                    ?.filter(
                      (v: string | null | undefined) =>
                        v !== "" && v !== undefined && v !== null
                    )
                    .map((item: any, index: number) => {
                      return {
                        item: item,
                        row: index + 1,
                        select: (<div>
                      {/* <input className="form-check-input" type="checkbox"
                       onChange={()=> this.handleSelect(item)}
                       /> */}

                          <RadioSquare
                          key={item.id_select}
                          title={""}
                          onClick={() => this.handleSelect(item)}
                          isActive={
                            selectedList.find(
                              (c: { id_select: any }) => c.id_select === item?.id_select
                            )
                              ? true
                              : false
                          }
                        />
                        <div>{item.id_select}</div>
                      </div>),
                        link: item.mId === null ?  <AiOutlineCloseCircle
                          className="icon-red cursor-default"
                       
                        /> : <BsCheckSquare
                        className="icon-green"
                        onClick={() => this.handleOpenLinkModal(item,index)}
                      /> ,
                      driver: item?.relationship_products?.length > 0 ? item?.relationship_products?.map((item2: any, index2: number) => {
                        return (
                          <img key={index2} src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item2?.db_name)[0]?.value?.logo} className={item2?.site_select ? "cursor-pointer" :'unselected-site-logo' }alt="" onClick={(event)=> Number(syncStatus) === 1 ?  this.handleSelectedSite(item , index , index2) : null} />
                          );
                        }
                        ) : <img src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo} alt="" />,
                        name: item.product_name,
                        writer: ""
                      };
                    })
                }
                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                      sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap', justifyContent:"space-between", width:"100%" }}
                    >
                      <CustomButton
                        loading={false}
                        onClick={() => this.exportToExcel(productListData?.data, "Excel Export")}
                        title="اکسل"
                        myStyle="excel-btn"
                        icon={<AiOutlineFileExcel />}
                      />
                      <CustomButton
                          myStyle="group-op-btn"
                          loading={false}
                          onClick={this.operationalGroup}
                          title="عملیات گروهی "
                        />
                      <Tooltip title="نمایش فیلترها"><span> <TfiFilter className='filter-icon' onClick={() => this.setState(prevState => ({
                                  showFilters: !prevState.showFilters
                                }))
                              }/></span></Tooltip>
                      <div className='custom-pagination'>
                      <Pagination  page={page} count={productListData?.total ? Math.ceil(productListData?.total / rowCount) : 1} onChange={this.handleChangePage} />
                      <DropDownIcon
                      value={rowCount}
                      optionList={rowCountList}
                      title={""}
                      onChange={(event) =>
                        {
                          this.handlePaginationSelect(event)
                      }
                    }
                    />
                    </div>
                    </Box>
                  )}
                state={{ isLoading: loading, showProgressBars: loading, expanded:{[selectedRowIndex]:true} }}
                enablePagination={false}
                renderBottomToolbarCustomActions={({table})=>{
                  return (
                    <div className="custom-pagination w-100">
                        <Pagination
                        page={page}
                          count={productListData?.total ? Math.ceil(productListData?.total / rowCount) : 1}
                          onChange={this.handleChangePage}
                        />
                        <DropDownIcon
                          value={rowCount}
                          optionList={rowCountList}
                          title={""}
                          onChange={(event) => {
                            this.handlePaginationSelect(event);
                          }}
                        />
                    </div>
                  );
                }}
                positionExpandColumn={"last"}
                enableGlobalFilter={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableHiding={false}
                sortDescFirst={true}
                getRowId={(row) => row.code}
                enableColumnActions={false}
                enableSorting={true}
                initialState={{
                  pagination: { pageSize: 100, pageIndex: 0 },
                }}
                enableRowActions
                localization={{
                  actions: "عملیات",
                  noRecordsToDisplay: "داده‌ای برای نمایش وجود ندارد!",
                }}
                muiTableProps={{
                  sx: {
                    border: "1px solid #e5e5e5",
                  },
                }}
                muiTableHeadCellProps={{
                  sx: {
                    border: "1px solid #e5e5e5",
                    height: "fit-content",
                  },
                }}
                muiTableBodyCellProps={{
                  sx: {
                    border: "1px solid #e5e5e5",
                    height: "fit-content",
                    textAlign:"center"

                  },
                }}
                displayColumnDefOptions={{
                  'mrt-row-expand': {
                    muiTableHeadCellProps: {
                      align: 'center',
                    },
                  },
                }}
                renderDetailPanel={({ row }) => (
                  <>
                  {detailPannelLoading ? <LoadingSpinner /> :
                    <div key={detailPannelData?.id} className="detail-container">
                      <div className="detail-col text-end" >
                        <p className="detail-row d-flex">
                        آخرین ویرایش: {moment(detailPannelData?.updated_at).format("jYYYY/jM/jD")} 
                       </p>
                       <p className="detail-row d-flex">
                           برند: {detailPannelData?.brand?.persian_name}
                        </p>
                        <p className="detail-row d-flex ">
                           دسته‌بندی : {detailPannelData?.categories?.length > 0 && detailPannelData?.categories?.map((item:any)=>{
                           return(

                           <div>
                              {item?.persian_name}
                            </div>
                           )
                           })}
                        </p>
                        <p className="detail-row d-flex">
                           برچسب :  {detailPannelData?.tags?.length > 0 && detailPannelData?.tags?.map((item:any)=>{
                            return(

                            <div>
                              {item?.persian_name}
                            </div>
                            )
                           })}
                        </p>
                        <p className="detail-row d-flex">
                           وضعیت : {detailPannelData?.status?.value}
                        </p>
                      </div>
                      <div className="detail-col text-end" >
                        <p className="detail-row d-flex">

                          عنوان سئو: {detailPannelData?.seo_title === 1 ?<AiOutlineCheckSquare className="icon-green"/> : <LiaMinusSquare className="icon-red"/>}
                        </p>
                        <p className="detail-row d-flex">
                          توضیحات سئو: {detailPannelData?.seo_description === 1 ?<AiOutlineCheckSquare className="icon-green"/> : <LiaMinusSquare className="icon-red"/>}</p>
                        <p className="detail-row d-flex">
                          توضیحات محصول: {detailPannelData?.product_desc === 1 ?<AiOutlineCheckSquare className="icon-green"/> : <LiaMinusSquare className="icon-red"/>}
                        </p>
                        <p className="detail-row d-flex">
                           وضعیت تایید: {detailPannelData?.confirmed === 1 ?<AiOutlineCheckSquare className="icon-green"/> : <LiaMinusSquare className="icon-red"/>}
                        </p>
                        
                      </div>

                    </div>
                          }
                          </>
                  )}
                positionActionsColumn={"last"}
                tableInstanceRef={this.tableInstanceRef}
                renderRowActions={({ row, table }) => [
                  <div key={row.original.item?.id} className="detail-container">
                    {row.original?.item?.is_note === 1 ? (
                       <Tooltip title="مشاهده یادداشت‌ها">
                       <span>
                         <BsEnvelope 
                           className="icon-blue"
                           onClick={() =>this.handleShowNotes(row.original?.item)}
                         />
                       </span>
                     </Tooltip>
                    ) :
                    <BsEnvelope  className="icon-gray" />}
                    {row.original?.item?.deleted_at ? (
                      <Tooltip title="بازیابی کردن">
                        <span>
                          <BiRecycle
                            className={`${this.siteSelectedCount(row.original.item) ? "icon-green" : "icon-gray"} `}
                            onClick={() => this.siteSelectedCount(row.original.item) ? this.handleRecycle(row.original) : toast.error("حداقل یک سایت را انتخاب کنید")}
                          />
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip title="حذف کردن">
                        <span>
                          {" "}
                          <RiDeleteBin6Line
                            className={`${this.siteSelectedCount(row.original.item) ? "icon-red" : "icon-gray"} `}
                            onClick={() => this.siteSelectedCount(row.original.item) ? this.handleDelete(row.original) : toast.error("حداقل یک سایت را انتخاب کنید")}
                          />
                        </span>
                      </Tooltip>
                    )}

                    {row.original?.item?.confirmed === 0 ? (
                      <Tooltip title="تایید">
                        <span>
                          {" "}
                          <BsCheckSquare
                            className={`${this.siteSelectedCount(row.original.item) ? "icon-green" : "icon-gray"} `}
                            onClick={() => this.siteSelectedCount(row.original.item) ? this.handleConfirm(row.original) : toast.error("حداقل یک سایت را انتخاب کنید")}
                          />
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip title="عدم تایید">
                        <span>
                          {" "}
                          <BsCheckSquare
                            className={`${this.siteSelectedCount(row.original.item) ? "icon-red" : "icon-gray"} `}
                            onClick={() => this.siteSelectedCount(row.original.item) ? this.handleConfirm(row.original) : toast.error("حداقل یک سایت را انتخاب کنید")}
                          />
                        </span>
                      </Tooltip>
                    )}
                    {row.original?.item?.dont_sell?.name === 0 ? (
                      <Tooltip title="غیرقابل فروش کردن">
                        <span>
                          {" "}
                          <AiOutlineCloseCircle
                            className={`${this.siteSelectedCount(row.original.item) ? "icon-red" : "icon-gray"} `}
                            onClick={() => this.siteSelectedCount(row.original.item) ? this.handleNoSelling(row.original) : toast.error("حداقل یک سایت را انتخاب کنید")}
                          />
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip title="قابل فروش کردن">
                        <span>
                          {" "}
                          <AiOutlineCloseCircle
                            className={`${this.siteSelectedCount(row.original.item) ? "icon-green" : "icon-gray"} `}
                            onClick={() => this.siteSelectedCount(row.original.item) ? this.handleNoSelling(row.original) : toast.error("حداقل یک سایت را انتخاب کنید")}
                          />
                        </span>
                      </Tooltip>
                    )}

{row.original?.item?.no_index === 0 ? (
                      <Tooltip title="index">
                        <span>
                          {" "}
                          <FaRegDotCircle
                            className={`${this.siteSelectedCount(row.original.item) ? "icon-green" : "icon-gray"} `}
                            onClick={() => this.siteSelectedCount(row.original.item) ? this.handleNoIndex(row.original) : toast.error("حداقل یک سایت را انتخاب کنید")}
                          />
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip title="no-index">
                        <span>
                          {" "}
                          <FaRegDotCircle
                            className={`${this.siteSelectedCount(row.original.item) ? "icon-red" : "icon-gray"} `}
                            onClick={() => this.siteSelectedCount(row.original.item) ? this.handleNoIndex(row.original) : toast.error("حداقل یک سایت را انتخاب کنید")}
                          />
                        </span>
                      </Tooltip>
                    )}

                    {Number(syncStatus) === 1 && 
                    
                      <Tooltip title="غیر مرتبط کردن "><span> <BiUnlink
                      className={`${this.siteSelectedCount(row.original.item) ? "icon-green" : "icon-gray"} `}
                      onClick={() => this.siteSelectedCount(row.original.item) ? this.handleUnLink(row.original) : toast.error("حداقل یک سایت را انتخاب کنید")} /></span></Tooltip>
                    }
                    {row.original.item?.is_note === 1 ? 
                    <Tooltip title="کامنت">
                      <span>
                        {" "}
                          <BsFillEnvelopeFill className="icon-blue" />
                      </span>
                    </Tooltip>
                    : null}
                    <Tooltip title="نمایش">
                      <span>
                        <Link target="_blank" to={row.original.item?.product_url}>
                          <BiShowAlt className="icon-gray" />
                        </Link>
                      </span>
                    </Tooltip>
                    {Number(syncStatus) === 0 ?
                    <Link target="_blank" to={`/admin/products/edit/${row.original?.item?.id_select}`}>
                      <Tooltip title="ویرایش"><span><AiOutlineEdit className='icon-yellow' />
                      </span>
                      </Tooltip>
                    </Link>
                    : 
                      <Tooltip title="ویرایش"><span><AiOutlineEdit className='icon-yellow' onClick={() => this.handleChooseDbName(row.original?.item)} />
                      </span>
                      </Tooltip>
                    }


                    <Tooltip title="کپی"><span><BiCopy className='icon-blue' /></span></Tooltip>

                  </div>,
                ]}
              />
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}
const mapStateToProps = (dispatch: any) => {
  return {
    dispatch,
    siteList: dispatch.general.siteList,
    loadingSiteList: dispatch.general.loadingSiteList,
    tagFilter: dispatch.productFilter.tagFilter,
    brandFilter: dispatch.productFilter.brandFilter,
    categoryFilter: dispatch.productFilter.categoryFilter,
  };
};
export default connect(mapStateToProps)(ProductsList);
