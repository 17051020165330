import { Navigate, Route, Routes, Outlet, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  BestSelling,
  CreateProductsRelations,
  Home,
  ProductUpdate,
  AddressManagement,
  Landing,
  Login,
  Orders,
  Payment,
  PreFactor,
  PriceList,
  NotFound,
  ProductsRelations,
  Profile,
  Register,
  StoreSettings,
  SubmitOrder,
  SupportTicket,
  SystemEvents,
  UpdateUser,
  UsersComments,
  UsersInfo,
  Vitrin,
  ProductsList,
  ProductAdd,
  HomeAdmin,
  TagsList,
  CategoriesList,
  BrandsList,
  GeneralSettings,
  WebsiteInformation,
  ContenSettings,
  Medias,
  UsersManagement,
  UpdateAdminUser,
  PageManagement,
  FileManagement,
  RedirectsManagement,
  UsersNote,
  AppearanceSettings,
  BannerManagement,
  CommonQuestionsList,
  CommonQuestionsCreate,
  SiteSlogan,
  ContactUs,
  SystemLogs,
  WebManagement,
  CommonQuestionsUpdate,
  UpdatePreFactor,
  CreatePreFactor,
  RedirectConfirm,
  UpdateOrder,
  Layout,
} from ".";
import { ToastContainer } from "react-toastify";
import {AuthManager}  from "../../route";
import { RequireAuth } from "../components";
import { ROLES } from "../../core/constants";
interface Props {
  userData?: any;
  dispatch?: any;
}

const Pages = ({ userData}: Props) => {
  const isLogin = localStorage.getItem("token");
  return (
   <>
    <Routes >
      <Route path="/" element={<Layout />}>
        {/* public routes */}
      <Route path="register" element={<Register />} />
      <Route path="/profile" element={<Profile />}/>
      <Route path="users" element={<UsersInfo />} />
      <Route path="/landing/*" element={<RedirectConfirm />} />
      <Route path="login" element={isLogin === null ? <Login /> : isLogin && userData?.roles?.map((el: any) =>  ROLES.Seller === el.name)[0] ? <Navigate to="/home" /> :
      <Navigate to="/admin/home"/>}/>
        <Route path="/" element={<AuthManager userData={userData} />} />
        <Route  element={<RequireAuth auth={userData && userData} allowedRoles={ROLES.Seller} />}>
          <Route path="home" element={<Home />} />
          <Route path="address-managent" element={<AddressManagement />} />
          <Route path="price-management" element={<PriceList />}/>
          <Route path="orders" element={<Orders />}/>
          <Route path="payments" element={<Payment />} />
          <Route path="prefactor" element={<PreFactor />} />
          <Route path="/prefactor-update/:id" element={<UpdatePreFactor />} />
          <Route path="/create-prefactor" element={<CreatePreFactor />} />
          <Route path="relations" element={<ProductsRelations />} />
          <Route path="best-selling" element={<BestSelling />} />
          <Route path="/comments" element={<UsersComments />} />
          <Route path="/tickets" element={<SupportTicket />} />
          <Route path="update-user-info/:id" element={<UpdateUser />} />
          <Route path="/seller/landing/:title" element={<Landing />} />
          <Route path="/logs" element={<SystemEvents />} />
          <Route path="vitrin" element={<Vitrin />} />
          <Route path="ordersubmit" element={<SubmitOrder />} />
          <Route path="/order-update/:id" element={<UpdateOrder />} />
          <Route path="store-settings" element={<StoreSettings />} />
          <Route path="create-relations" element={<CreateProductsRelations />} />
          <Route path="contact-us" element={<ContactUs />} />
        </Route>
          {/* admin */}
        <Route  element={<RequireAuth auth={userData && userData} allowedRoles={ROLES.Admin} />} >
          <Route path="/admin/products" element={<ProductsList />} />
          <Route path="/admin/products/edit/:id" element={<ProductUpdate />} />
          <Route path="/admin/home" element={<HomeAdmin />} /> 
          <Route path="/admin/brands" element={<BrandsList />} /> 
          <Route path="/admin/categories" element={<CategoriesList />} /> 
          <Route path="/admin/tags" element={<TagsList />} /> 
          <Route path="/admin/add-product" element={<ProductAdd />} />
          <Route path="/admin/media" element={<Medias />} />
          <Route path="/admin/filemanager" element={<FileManagement />} />
          <Route path="/admin/redirect" element={<RedirectsManagement />} />
          <Route path="/admin/note" element={<UsersNote />} />
          <Route path="/admin/general-settings" element={<GeneralSettings />} />
          <Route path="/admin/content-settings" element={<ContenSettings />} />
          <Route path="/admin/website_information" element={<WebsiteInformation />} />
          <Route path="/admin/commen-question-create" element={<CommonQuestionsCreate />} />
          <Route path="/admin/commen-questions-list" element={<CommonQuestionsList />} />
          <Route path="/admin/appearance-settings" element={<AppearanceSettings />} />
          <Route path="/admin/banner-management" element={<BannerManagement />} />
          <Route path="/admin/site-slogan" element={<SiteSlogan />} />
          <Route path="/admin/users" element={<UsersManagement />} />
          <Route path="/admin/system-logs" element={<SystemLogs />} />
          <Route path="/admin/update-user/:id" element={<UpdateAdminUser />} />
          <Route path="/admin/landing/:title" element={<Landing />} />
          <Route path="/admin/page/:db" element={<PageManagement />} />
          <Route path="/admin/web-management" element={<WebManagement />} />

        <Route path="/admin/products">
          <Route index element={<ProductsList />} />
          <Route path=":brand&:db-name" element={<ProductsList />} />
          <Route path=":tag&:db-name" element={<ProductsList />} />
          <Route path=":category&:db-name" element={<ProductsList />} />

        </Route>
        <Route path="/admin/general-settings/:db" element={<GeneralSettings />} />
        <Route path="/admin/content-settings/:db" element={<ContenSettings />} />
        <Route
          path="/admin/website_information/:db"
          element={<WebsiteInformation />}
        />
        <Route
          path="/admin/commen-question-create/:db"
          element={<CommonQuestionsCreate />}
        />
        <Route
          path="/admin/commen-questions-list/:db"
          element={<CommonQuestionsList />}
        />
        <Route
          path="/admin/commen-questions-update/:id"
          element={<CommonQuestionsUpdate />}
        />
        <Route
          path="/admin/appearance-settings/:db"
          element={<AppearanceSettings />}
        />
        <Route path="/admin/banner-management/:db" element={<BannerManagement />} />
        <Route path="/admin/site-slogan/:db" element={<SiteSlogan />} />
        </Route>
        <Route path="*" element={isLogin === null ? <Login /> : <NotFound />} />
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
};
const mapStateToProps = (dispatch: any) => {
  return {
    dispatch,
    userData: dispatch.general.userData,
  };
};
export default connect(mapStateToProps)(Pages);
