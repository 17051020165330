import { CustomButton, VitrinCard, LoadingSpinner, Header, Option } from "../../../components";
import {BestSellingController} from "../../../controllers";
import Select, { CSSObjectWithLabel } from "react-select";
import { Helmet, HelmetProvider } from 'react-helmet-async';

class BestSelling extends BestSellingController {
  componentDidMount() {
    this.getDbList() 
    window.scrollTo(0, 0);
  }
  render() {
    const { specialCellId, brandLoading, productListData,productLoading , emptyDataText, bestSellingList , driverList , isCreating , driversId , loading } = this.state;

    // const dragHandlers = {onStart: this.onStart, onStop: this.onStop};


    return (
      <HelmetProvider>
      <div className="best-selling">
        <Helmet>
                <title> پر فروش‌ها </title>
            </Helmet>
          <Header title="پرفروش"/>
        <div className="custom-card">
          <div className="best-selling-card">
            <div className="filter-select-container pe-3">
              <div className="filter-options-container">
              <div className="column-50">

              
            <Select
                
                styles={{
                  control: (baseStyles, state):CSSObjectWithLabel => ({
                    ...baseStyles,
                    boxShadow:"rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",
                    borderWidth: "1px",
                    backgroundColor: "#fff",
                    display: "flex",
                    flexDirection: "row-reverse",
                    textAlign: "right",
                    borderRadius: "4px",
                    height:"35px",
                    minHeight:"20px",                   
                    outline: "unset",
                    width: "100%"
                  }) as CSSObjectWithLabel,
                }}
                onChange={(choice) => this.driverSelected(choice)}
                components={{ Option }}
                value={driversId}
                isMulti={false}
                closeMenuOnSelect={true}
                noOptionsMessage={() => 'این مورد وجود ندارد!'}
                menuPortalTarget={document.body}
                options={
                  driverList
                }
                getOptionLabel={(option: any) => option?.value?.persian_name}
                getOptionValue={(option: any) => option?.value?.name}
                placeholder="سایت"
                loadingMessage={() => 'در حال بارگذاری  ...'}
                isLoading={brandLoading}
              />
              </div>            
              <div className="column-50">
              <Select
                
                styles={{
                  control: (baseStyles, state):CSSObjectWithLabel => ({
                    ...baseStyles,
                    boxShadow: state.isFocused
                      ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                      : "",
                    borderWidth: "1px",
                    backgroundColor: `${driversId && Object.keys(driversId).length > 0  ? "#fff" : "#f7f8fa"}`,
                    display: "flex",
                    flexDirection: "row-reverse",
                    textAlign: "right",
                    borderRadius: "4px",
                    height:"35px",
                    minHeight:"20px",                    
                    outline: "unset",
                    width: "100%"
                  }) as CSSObjectWithLabel,
                }}
                onInputChange={(choice) => this.handleSearch(choice)}
                onChange={(choice) => this.handleSelectSpecialSell(choice)}
                value={specialCellId}
                isMulti={false}
                closeMenuOnSelect={false}
                isSearchable={true}
                options={productListData}
                menuPortalTarget={document.body}
                noOptionsMessage={() => 'این مورد وجود ندارد!'}
                getOptionLabel={(option: any) => option?.product_name}
                getOptionValue={(option: any) => option?.id}
                placeholder="نام محصول را وارد کنید."
                loadingMessage={() => 'در حال بارگذاری  ...'}
                isLoading={productLoading}   
                isDisabled={driversId && Object.keys(driversId).length > 0  ? false : true}
                />
              </div>
              
            </div>
            </div>
            {loading ? (
              <LoadingSpinner />
            ) : (
              <>
               {emptyDataText ? <span className="error-text">داده‌ای برای نمایش وجود ندارد، لطفاً محصولی اضافه کنید!</span> :
                  <>
               <div className="special-sell">
                  
                  {bestSellingList && bestSellingList?.length > 0 && bestSellingList?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null).map((item: any, index: number) => {
                    return (
                      // <Draggable bounds={{top: -100, left: -100, right: 100, bottom: 100}}  axis="x" {...dragHandlers}>
                      
                      
                      <VitrinCard details={item?.product_name} code={item?.id}
                        key={index}
                        setClose={(open: boolean) => this.setState({ toggleModal: open })}
                        onClick={() => this.handleDeleteProduct(item,index)}
                        img={item?.thumbnail ? item?.thumbnail : item?.product_url}
                      />
             
                    // </Draggable>
                      );
                    }
                    )}
                   
                </div>
       
                <div className="btn-container">
                  <CustomButton
                    myStyle="submit-btn"
                    loading={isCreating}
                    onClick={() => this.handleSubmit()}
                    title="ثبت نهایی"
                  />
                </div>
                 </>
                }
              </>
            )}
          </div>
        </div>
      </div>
      </HelmetProvider>
    )
  }
}
export default BestSelling;