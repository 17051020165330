
import {  CustomButton, TextAreaLabel,Accordion, InputTextLabel , DropDownIcon, Header, Option  } from "../../../components";
import {UsersCommentsController} from "../../../controllers";
import { createTheme, ThemeProvider } from '@mui/material';
import { MaterialReactTable} from 'material-react-table';
import moment from 'moment-jalaali';
import Pagination from '@mui/material/Pagination';
import Modal from '@mui/material/Modal';
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import { RxCrossCircled } from "react-icons/rx";
import { AiOutlineCheckCircle, AiOutlineComment } from "react-icons/ai";
import Tooltip from '@mui/material/Tooltip';
import Select, { CSSObjectWithLabel } from "react-select";
import { connect } from "react-redux";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {TfiFilter} from "react-icons/tfi";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { usersCommentsColumns } from "../../../../core/column"
class usersComments extends UsersCommentsController {
  componentDidMount() {
    this.getCommentsList()
    window.scrollTo(0, 0);
  }
  render() {

    const { siteList, loadingSiteList} = this.props;
    const {openCommentsModal, dateValue, showFilters, errorsList, page, productId, rowCount,
      rowCountList, productListData, productLoading, statusId, statusList, search, dreiverId,
      comment, loading, currentItem, commentsListData, isCreating} = this.state;
    const theme = createTheme({
      typography: {
        fontFamily:
          'Vazir',
      }
    });

    return (
      <HelmetProvider>
      <ThemeProvider theme={theme}>
        <div className="users-comments">
          <Helmet>
                <title>نظرات کاربران </title>
            </Helmet>
            <Header title="نظرات کاربران "/>
          <div className="custom-card mb-3">
          {showFilters && 
          <div className="filter-select-container mb-2 pe-3">
              <div className="filter-options-container">
              <div className="column-25">
                <Select
                      styles={{
                        control: (baseStyles: any, state: any):any => ({
                          ...baseStyles,
                          boxShadow:"rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",
                          borderWidth: "1px",
                          backgroundColor: "#fff",
                          display: "flex",
                          flexDirection: "row-reverse",
                          textAlign: "right",
                          borderRadius: "4px",
                          height:"35px",
                          minHeight:"20px",
                          outline: "unset",
                        }),
                        menuPortal: (base):any => ({ ...base, zIndex: 9999 })

                      }}
                      onChange={(choice: any) => this.driverSelected(choice)}
                      components={{ Option }}
                      value={dreiverId}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      noOptionsMessage={() => 'این مورد وجود ندارد!'}
                      menuPortalTarget={document.body}
                      options={siteList }
                      getOptionLabel={(option: any) => option?.value?.persian_name}
                      getOptionValue={(option: any) => option?.value?.name}
                      placeholder="سایت"
                      isClearable={true}
                      loadingMessage={() => 'در حال بارگذاری  ...'}
                      isLoading={loadingSiteList}
                    />
              </div>
              <div className="column-25"> 
              <div className="wrapper">
                      <Accordion title='فیلترها' content={
                      <>
                      <Select
                        styles={{
                                control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                                  ...baseStyles,
                                  boxShadow: state.isFocused
                                    ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                    : "",
                                  borderWidth: "1px",
                                  backgroundColor: `${dreiverId && Object.keys(dreiverId).length > 0  ? "#fff" : "#f7f8fa"}`,
                                  display: "flex",
                                  flexDirection: "row-reverse",
                                  textAlign: "right",
                                  borderRadius: "4px",
                                  height:"35px",
                                  minHeight:"20px",
                                  outline: "unset",
                                  marginBottom:"10px"

                                }) as CSSObjectWithLabel,
                              }}
                              onChange={(choice: any) => this.statusSelected(choice)}
                              value={statusId}
                              isMulti={true}
                              closeMenuOnSelect={true}
                              noOptionsMessage={() => 'این مورد وجود ندارد!'}
                              options={statusList}
                              getOptionLabel={(option: any) => option.title}
                              getOptionValue={(option: any) => option.value}
                              placeholder="وضعیت"
                              isClearable
                              // isDisabled={dreiverId && Object.keys(dreiverId).length > 0  ? false : true}

                        />
                      <Select
                        styles={{
                                        control: (baseStyles: any, state: { isFocused: any; }):CSSObjectWithLabel => ({
                                          ...baseStyles,
                                          boxShadow: state.isFocused
                                            ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                            : "",
                                          borderWidth: "1px",
                                          backgroundColor: `${dreiverId && Object.keys(dreiverId).length > 0  ? "#fff" : "#f7f8fa"}`,
                                          display: "flex",
                                          flexDirection: "row-reverse",
                                          textAlign: "right",
                                          borderRadius: "4px",
                                          height:"35px",
                                          minHeight:"20px",                                          
                                          outline: "unset",
                                          width: "100%"
                                        }) as CSSObjectWithLabel,
                                      }}
                                      onInputChange={(choice: any) => this.handleSearchProduct(choice)}
                                      onChange={(choice: any) => this.handleSelectProduct(choice)}
                                      value={productId}
                                      isMulti={false}
                                      closeMenuOnSelect={false}
                                      isSearchable={true}
                                      options={productListData}
                                      noOptionsMessage={() => 'این مورد وجود ندارد!'}
                                      getOptionLabel={(option: any) => option?.product_name}
                                      getOptionValue={(option: any) => option?.id}
                                      placeholder="نام محصول را وارد کنید."
                                      loadingMessage={() => 'در حال بارگذاری  ...'}
                                      isLoading={productLoading}
                                      // isDisabled={dreiverId && Object.keys(dreiverId).length > 0  ? false : true}
                                      isClearable={true}
                        />
                    </>
                    } />
              </div>
              </div>
              <div className="column-25">
                <InputTextLabel
                  type={"text"}
                  value={search}
                  onChange={(event) => this.handleSearch(event)}
                  placeholder="جستجو..."
                  myStyle="search-input"
                  // disabled={dreiverId && Object.keys(dreiverId).length > 0  ? false : true}
                  
                />
              </div>
              <div className="column-25">
              <DatePicker 
                className="rmdp-prime"
                inputClass="date-picker"
                editable={false}
                range 
                value={dateValue}
                // disabled={dreiverId && Object.keys(dreiverId).length > 0  ? false : true}
                dateSeparator=" تا " 
                maxDate={new Date()}
                onChange={(date:any) => {
                  this.handleChangeDate(date);
                }}
                placeholder="انتخاب بازه زمانی"
                calendar={persian}
                locale={persian_fa}
                // disabled={dreiverId && Object.keys(dreiverId).length > 0  ? false : true}

              >

                  <button
                  style={{ margin: "5px 0" }}
                  onClick={() => this.handleClearDate()}
                  >
                  لغو انتخاب 
                  </button>
              </DatePicker>
              </div>
              </div>
          </div>
        }

            <Modal
              open={openCommentsModal}
              onClose={this.handleCloseCommentsModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: {
                  xxs: "90%",
                  xs: "90%",
                  sm: "90%",
                  md: "80%",
                  lg: "70%",
                  xl: "70%"
                },
                height: 'max-content',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
              }}>
                <Box id="modal-modal-title" sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="subtitle1">
                    نظرات
                  </Typography>         
                      <img className="modal-logo-img" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === currentItem?.db_name)[0]?.value?.logo} alt="..." />
                </Box>
                <Grid container>
                  <TextAreaLabel
                    placeholder="پاسخ جدید خود را وارد کنید."
                    value={comment}
                    name="comment"
                    errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "comment")[0]?.message}
                    onChange={(event: { currentTarget: { value: any; }; }) =>
                      this.setState({
                        comment: event.currentTarget.value,
                      })
                    }
                  />
                </Grid>
                <CustomButton
                  myStyle='send-comment-btn w-100'
                  loading={isCreating}
                  onClick={() => this.handleSendComment()}
                  title="ارسال"
                />
           
                 <div className="comments-container">
                 <div className="w-100 d-flex" style={{ justifyContent: currentItem?.user_id ===  Number(window.localStorage.getItem('userId'))? "flex-start" : "flex-end", paddingLeft:"25px" }}>
                      <Grid item sm={12} sx={{ backgroundColor: currentItem?.user_id ===  Number(window.localStorage.getItem('userId'))? "#e5f5ff" : "#f4f4f4", borderRadius: "10px", padding: "15px", marginTop: "20px", maxWidth: "75%" }}>
                        <Typography sx={{ textAlign: "right" }}>
                          {currentItem.comment}
                        </Typography>
                        <Typography sx={{ textAlign: "left", marginTop: "10px" }}>
                          {moment(currentItem.created_at).format("HH:mm:ss jYYYY/jM/jD")}
                        </Typography>
                      </Grid>
                 </div>
                {currentItem && currentItem?.children?.length > 0 && currentItem?.children?.map((child: any, index: number) => {
                  return (
                    
                    <div key={index} className="w-100 d-flex " style={{ justifyContent: Number(window.localStorage.getItem('userId')) === child.user_id ? "flex-start" : "flex-end",  paddingLeft:"25px" }}>
                      <Grid item  sm={12} sx={{ backgroundColor: Number(window.localStorage.getItem('userId')) === child.user_id ? "#e5f5ff" : "#f4f4f4", borderRadius: "10px", padding: "15px", marginTop: "20px", maxWidth: "75%" }} key={index}>
                        <Typography sx={{ textAlign: "right" }}>
                          {child.comment}
                        </Typography>
                        <Typography sx={{ textAlign: "left", marginTop: "10px" }}>
                          {moment(child.created_at).format("HH:mm:ss jYYYY/jM/jD")}
                        </Typography>
                      </Grid>
                    </div>
                    
                    );
                  })}
                  <div ref={this.myRef}></div>
                </div>
              </Box>
            </Modal>
            <MaterialReactTable
              muiTablePaperProps={{
              sx: { ml: '1.3rem', mt:"1.3rem", mr:"1.3rem", border:"1px solid #e5e5e5" }}}
              columns={usersCommentsColumns}
              data={commentsListData && commentsListData?.data?.length > 0 && commentsListData?.data?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null).map((item: any, index: number) => {
                return (
                  {
                    item: item,
                    id: item?.id,
                    driver:  <img className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo} alt="" /> , 
                    user: item?.profile?.full_name,
                    productName: item.product?.product_name,
                    status: item.approved?.value,
                    date: item.created_at && item.created_at,
                  }
                );
              }
              )}
              rowNumberMode="static"
              enableRowNumbers
              renderTopToolbarCustomActions={({table}) =>(
               
                <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap', justifyContent:"space-between", width:"100%" }}                >
                  <Tooltip title="نمایش فیلترها"><span> <TfiFilter className='filter-icon' onClick={() => this.setState(prevState => ({
                                  showFilters: !prevState.showFilters
                                }))
                              }/></span></Tooltip>
                  <div className='custom-pagination'>
                      <Pagination  page={page} count={commentsListData?.total ? Math.ceil(commentsListData?.total / rowCount) : 1} onChange={this.handleChangePage} />
                  <DropDownIcon
                  value={rowCount}
                  optionList={rowCountList}
                  title={""}
                  onChange={(event) =>
                    {this.handlePaginationSelect(event)}
                }
                />
                    </div>
                </Box>
                )}
                enablePagination={false}
                renderBottomToolbarCustomActions={({table})=>{
                  return (
                    <div className="custom-pagination w-100">
                      <Pagination  page={page} count={commentsListData?.total ? Math.ceil(commentsListData?.total / rowCount) : 1} onChange={this.handleChangePage} />
                      <DropDownIcon
                      value={rowCount}
                      optionList={rowCountList}
                      title={""}
                      onChange={(event) =>
                        {this.handlePaginationSelect(event)}
                    }
                    />
                    </div>
                  );
                }}
              state={{isLoading:loading,showProgressBars:loading}}
              enableGlobalFilter={false}
              enableColumnFilters={false}
              enableDensityToggle={false}
              enableFullScreenToggle={false}
              enableHiding={false}
              enableColumnActions={false}
              enableRowActions
              rowVirtualizerProps={{ overscan: 20 }} 
              rowVirtualizerInstanceRef={this.tableInstanceRef} 
              enableRowVirtualization
              positionActionsColumn="last"
              muiTableProps={{
                sx: {
                  border: "1px solid #e5e5e5",
                },
              }}
              muiTableHeadCellProps={{
                sx: {
                  height:"fit-content",
                  border: "1px solid #e5e5e5",
                  textAlign:"center",
                  justifyContent:"center",
                },
              }}
              muiTableBodyCellProps={{
                sx: {
                  border: "1px solid #e5e5e5",
                  textAlign:"center",
                  justifyContent:"center"
                },
              }}
              muiTableBodyRowProps={( {row,staticRowIndex} ) => ({
                sx:{
                  height: "fit-content",
                  backgroundColor: (staticRowIndex % 2 === 0 ) ? '#f3f3f3'  : "",
              }})}
              localization={{
                actions: 'عملیات',
                noRecordsToDisplay:"داده‌ای برای نمایش وجود ندارد!"
              }}
              renderRowActions={({ row }) => [
                <div key={row && row?.original && row?.original?.item && row?.original?.item[0] && row?.original?.item[0]?.id} className='detail-container'>
                  {row?.original?.item?.approved?.name === 1 ? 
                  <Tooltip title="لغو نظر"><span> <RxCrossCircled className='icon-red' onClick={() => this.handleFinalReject(row.original.item)} /></span></Tooltip>
                                    : 
                  <Tooltip title="تایید نظر"><span> <AiOutlineCheckCircle className="icon-green" onClick={() => this.handleFinalConfirm(row.original.item)} /></span></Tooltip>

                                    }
                  <Tooltip title="ثبت پاسخ"><span> <AiOutlineComment className="icon-blue" onClick={() => this.handleOpenModal(row.original.item)} /></span></Tooltip>

                  
                </div>
              ]}
              sortDescFirst={true}
              enableSorting={true}
              initialState={{
                sorting: [
                      {
                        id: 'date',
                        desc: true,

                      },
                    ],
                pagination : {pageSize:100, pageIndex:0}
              }}
            />
          </div>
        </div>
      </ThemeProvider >
      </HelmetProvider>
    );
  }
}
const mapStateToProps = (dispatch:any) => {
  return {
    dispatch,
    siteList:dispatch.general.siteList,
    loadingSiteList:dispatch.general.loadingSiteList,
  }
};
export default connect(mapStateToProps)(usersComments);
