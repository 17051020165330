import { Component } from 'react';
import { createRef } from "react";
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';
import { AppConstants } from '../../../core/constants';
import { call_api, parseJSON } from '../../../core/service';
import { toast } from 'react-toastify';

interface IProps {
dispatch?:any,
siteList?:any,
loadingSiteList?:any,
userData?:any
}
interface IState {
  loading?: boolean,
  commenQuestionsList:any;
  search:string;
  showFilters:any,
  total: number;
  rowData: any,
  page: number,
  rowCount:any,
  rowCountList:any,
  paginate: number,
  fileType: string,
  fileExtension: string,
  toggleModal:boolean
}

 class CommenQuestionsListController extends Component<IProps, IState> {

  rowVirtualizerInstanceRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    this.rowVirtualizerInstanceRef = createRef()
    this.state = {
    loading: false,
    commenQuestionsList:[],
    search:"",
    showFilters:false,
    total: 0 , 
    rowData: {},
    page: 1,
    rowCount:100,
    rowCountList:[10, 25 , 50 , 100],
    paginate: 0,
    toggleModal:false,
    fileType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      fileExtension: ".xlsx",
    }
  };
  searchTimeout: string | number | NodeJS.Timeout | undefined;
  handleSearch = (event: any) => {
    this.setState({ search: event.currentTarget.value })
    clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.getDate()
      }, 1000);
  };
  paginationSelectTimeOut: string | number | NodeJS.Timeout | undefined; 
  handlePaginationSelect=(event:any)=>{
    this.setState({
      rowCount: event.currentTarget.value,
      page:1
    })
    clearTimeout(this.paginationSelectTimeOut);
    this.paginationSelectTimeOut = setTimeout(() => {
      this.getDate()
    }, 800);
    
  }
  handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ page: value })
    this.getDate()
  };
  exportToExcel = async (excelData: any, fileName: any) => {
    const { fileType, fileExtension } = this.state;

    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  async getDate() {
    const {page,rowCount} = this.state;
    let parameters = {
      db_name: window.location.pathname.split("/")[3],
      search: this.state.search,
      page: page ? page.toString() : null,
      paginate: rowCount,
    }
    let url = new URL(`${AppConstants.base_url_api}admin/faq`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}admin/faq${url.search}` : `${AppConstants.base_url_api}admin/faq`,
      method_api: "GET",
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
            commenQuestionsList: j.data?.data,
            total: j.data?.total
          })
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  async handleDelete() {
    const { rowData } = this.state;
    call_api({
      address_api: `${AppConstants.base_url_api}admin/faq/${rowData?.id_select}`,
      method_api: "DELETE"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          toast.success(j?.message);
          this.setState({
            toggleModal: false
          })
          this.getDate();
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export default CommenQuestionsListController;