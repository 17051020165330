import {
    InputHTMLAttributes,
    ReactElement,
    useState,
  } from "react";
  import {AiOutlineEdit} from "react-icons/ai";
  import {BsTrash} from "react-icons/bs";
  interface Props extends InputHTMLAttributes<HTMLInputElement> {
    value:string;
    myStyle?:string;
    defaultValue?:number,
    inputContainerStyle?:string,
    label?:string,
    setDatas?:any,
    handleChange:any,
    handleCancel:any,
    disabled?:boolean,
    index?:number
  }
  
   function EditableCard({
    value,
    myStyle,
    handleChange,
    handleCancel,
  }: Props): ReactElement {
      const [isDisabled,setDisabled] = useState<boolean>(true)
  
    return (
      <div className={`editable-card ${myStyle}`} >
        <li className="container">
        <input value={value} disabled={isDisabled} name="name" onChange={handleChange} />
              <div >
                <AiOutlineEdit onClick={()=>setDisabled(!isDisabled)} className="icon-yellow" />
                <BsTrash className="icon-red" onClick={handleCancel} />
              </div>
        </li>
      </div>
    );
  }
  export default EditableCard;
  