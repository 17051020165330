
import {  CustomButton,SubmissionAlert, InputTextLabel , DropDownIcon, Header, Option, InputIcon, AddressInputs  } from "../../../components";
import {AddressManagementController} from "../../../controllers";
import { createTheme, ThemeProvider } from '@mui/material';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { IoMdAddCircleOutline } from "react-icons/io";
import Pagination from '@mui/material/Pagination';
import Modal from '@mui/material/Modal';
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Tooltip from '@mui/material/Tooltip';
import Select, { components } from "react-select";
import { connect } from "react-redux";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {TfiFilter} from "react-icons/tfi";
import { BiShowAlt } from "react-icons/bi";
import { FiAlertTriangle } from "react-icons/fi";
import { addressManagementColumns } from "../../../../core/column";
import { AiOutlineEdit } from "react-icons/ai";
class AddressManagement extends AddressManagementController {
  componentDidMount() {
    this.getUsersAddress()
    window.scrollTo(0, 0);
  }
  render() {
    const { siteList , loadingSiteList} = this.props;
    const {showFilters, userSelected, userLoading, allOption, profileListData, page, openEditAddressAlert,
      errorsList, openEditAdress , rowCount,rowCountList, addressTitle, addressData, dreiverId, loading, addressesList,
      isCreating, provinceId, provincesList, cityId, citiesList, loadingCities, loadingProvinces, currentItem,
      neighborhoodList, neighbourhood, position, postalCode, addressDetail, loadingNeighborhood,
      openAddAddressModal} = this.state;
    const theme = createTheme({
      typography: {
        fontFamily: 
          'Vazir',
      }
    });
    const ShowIdDropDown = (props: any) =>{
      return (
        
          <components.Option {...props} >
         <span>{props?.data?.id}</span> 
         <components.Option {...props} />
         <span>{props?.data?.mobile}</span>
         </components.Option>
        
      );
    }
    const filterOption = (option: any, inputValue: any): boolean =>
    {
      return (option.label?.match(inputValue) || option?.data?.mobile?.match(inputValue) || []).length > 0;
    }

    return (
      <HelmetProvider>
      <ThemeProvider theme={theme}>
        <div className="users-comments">
          <Helmet>
                <title>مدیریت آدرس‌ها </title>
            </Helmet>
            <Header title="مدیریت آدرس‌ها"/>
            {openEditAddressAlert &&
                  <SubmissionAlert
                  isOpen={openEditAddressAlert}
                  title="اخطار!"
                  setClose={(open: boolean) =>
                  this.setState({
                    openEditAddressAlert: open,
                  })
                  }
                  onClick={() => this.handleConfirmEdition()}
                  icon={<FiAlertTriangle color="orange" fontSize="50" />}
                  details={`آیا از ویراش آدرس شخص ${currentItem?.user_name} با شناسه ${currentItem?.id}  اطمینان دارید؟`}
                  />
                }
          <div className="custom-card mb-3">
          {showFilters && 
                  
          <div className="filter-select-container mb-2 pe-3">
              <div className="filter-options-container">
              <div className="column-50">
              <Select
                className="profile-select"
                  styles={{
                        control: (baseStyles, state):any => ({
                          ...baseStyles,
                          boxShadow:"rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",
                          borderWidth: "1px",
                          backgroundColor: "#fff",
                          display: "flex",
                          flexDirection: "row-reverse",
                          textAlign: "right",
                          borderRadius: "4px",
                          outline: "unset",
                        }),
                        menuPortal: (base):any => ({ ...base, zIndex: 9999 })
                      }}
                      onChange={this.driverSelected}
                      components={{ Option }}
                      value={dreiverId}
                      isMulti={true}
                      closeMenuOnSelect={true}
                      noOptionsMessage={() => 'این مورد وجود ندارد!'}
                      menuPortalTarget={document.body}
                      options={[ allOption, ...siteList]}
                      getOptionLabel={(option: any) => option?.value?.persian_name}
                      getOptionValue={(option: any) => option?.value?.name}
                      placeholder="سایت"
                      isClearable
                      loadingMessage={() => 'در حال بارگذاری  ...'}
                      isLoading={loadingSiteList}
              />
              </div>
              <div className="column-50">
              <Select
                      className="profile-select"
                      styles={{
                        control: (baseStyles, state):any => ({
                          ...baseStyles,
                          boxShadow: state.isFocused
                            ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                            : "",
                          borderWidth: "1px",
                          backgroundColor: `${dreiverId && Object.keys(dreiverId).length > 0  ? "#fff" : "#f7f8fa"}`,
                          display: "flex",
                          flexDirection: "row-reverse",
                          textAlign: "right",
                          borderRadius: "4px",
                          height:"35px",
                          minHeight:"20px",
                          width:"100%",
                          outline: "unset",
                        }),
                        option: (styles, {isSelected}):any => ({
                          ...styles,
                          background: 
                              isSelected
                                  ? 'light-blue'
                                  : undefined,
                          display:"flex",
                          padding: "2px",
                          justifyContent: "space-around",
                          alignItems:"center"
                      }),
                      placeholder:(styles):any => ({
                        ...styles,
                       fontSize:"14px"
                      }),}}
                      onInputChange={(choice) => this.handleSearchUser(choice)}
                      onChange={(choice) => this.userSelected(choice)}
                      components={{ Option:ShowIdDropDown }}
                      value={userSelected}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      isSearchable={true}
                      options={profileListData}
                      menuPortalTarget={document.body}
                      noOptionsMessage={() => 'این مورد وجود ندارد!'}
                      getOptionLabel={(option: any) => option.profile?.full_name}
                      getOptionValue={(option: any) => option?.id}
                      placeholder="شماره یا نام خانوادگی کاربر"
                      isClearable
                      filterOption={filterOption}
                      isLoading={userLoading}
                      loadingMessage={() => 'در حال بارگذاری  ...'}
                    />
              
              </div>
              </div>
              
          </div>
        }

            <Modal
              open={openEditAdress}
              onClose={this.handleCloseAddressEditModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: {
                  xxs: "90%",
                  xs: "90%",
                  sm: "90%",
                  md: "80%",
                  lg: "70%",
                  xl: "70%"
                },
                height: 'max-content',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
              }}>
                <Box id="modal-modal-title" sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="subtitle1">
                    ویراش آدرس
                  </Typography>
                </Box>
                <AddressInputs addressDetail={addressData} addAddress={(argOne: any) =>                       
                        this.handleEdit(argOne)
                      }/>
              </Box>
            </Modal>
            <Modal
              open={openAddAddressModal}
              onClose={this.handleCloseAddAddressModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: {
                  xxs: "90%",
                  xs: "90%",
                  sm: "90%",
                  md: "80%",
                  lg: "70%",
                  xl: "70%"
                },
                height: 'max-content',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
              }}>
                <Box id="modal-modal-title" sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="subtitle1">
                    افزودن آدرس
                  </Typography>
                </Box>
                <Grid container>
                    <Grid container>
                      <ul>
                      {errorsList.map((i:any, index:any)=>{
                        return(
                          <li className="error-text" key={index}>
                          {i.message}
                        </li>
                        )
                      
                      })}
                      </ul>
                    </Grid>
                      <AddressInputs addAddress={(argOne: any) =>                       
                        this.addAddress(argOne)
                      }/>
                </Grid>
              </Box>
            </Modal>
            <MaterialReactTable
              muiTablePaperProps={{
              sx: { ml: '1.3rem', mt:"1.3rem", mr:"1.3rem", border:"1px solid #e5e5e5" }}}
              columns={addressManagementColumns}
              data={addressesList && addressesList?.data?.length > 0 && addressesList?.data?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null).map((item: any, index: number) => {
                return (
                  {
                    item: item,
                    id: item?.id,
                    driver:  <img className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo} alt="" /> , 
                    user: item?.user_name,
                    addressTitle: item?.label && item.label,
                    date: item.created_at && item.created_at,
                  }
                );
              }
              )}
              rowNumberMode="static"
              enableRowNumbers
              renderTopToolbarCustomActions={({table}) =>(      
                <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap', justifyContent:"space-between", width:"100%" }}                >
                  <Tooltip title="نمایش فیلترها"><span> <TfiFilter className='filter-icon' onClick={() => this.setState(prevState => ({
                                  showFilters: !prevState.showFilters
                                }))
                              }/></span></Tooltip>
                  <div className='custom-pagination'>
                      <Pagination page={page} count={addressesList?.total ? Math.ceil(addressesList?.total / rowCount) : 1} onChange={this.handleChangePage} />
                  <DropDownIcon
                  value={rowCount}
                  optionList={rowCountList}
                  title={""}
                  onChange={(event) =>
                    {this.handlePaginationSelect(event)}
                }
                />
                    </div>
                </Box>
                )}
                enablePagination={false}
                renderBottomToolbarCustomActions={({table})=>{
                  return (
                    <div className="custom-pagination w-100">
                      <Pagination  page={page} count={addressesList?.total ? Math.ceil(addressesList?.total / rowCount) : 1} onChange={this.handleChangePage} />
                      <DropDownIcon
                      value={rowCount}
                      optionList={rowCountList}
                      title={""}
                      onChange={(event) =>
                        {this.handlePaginationSelect(event)}
                    }
                    />
                    </div>
                  );
                }}
              state={{isLoading:loading,showProgressBars:loading}}
              enableGlobalFilter={false}
              enableColumnFilters={false}
              enableDensityToggle={false}
              enableFullScreenToggle={false}
              enableHiding={false}
              enableColumnActions={false}
              enableRowActions
              positionActionsColumn="last"
              muiTableProps={{
                sx: {
                  border: "1px solid #e5e5e5",
                },
              }}
              muiTableHeadCellProps={{
                sx: {
                  height:"fit-content",
                  border: "1px solid #e5e5e5",
                  textAlign:"center",
                },
              }}
              muiTableBodyCellProps={{
                sx: {
                  border: "1px solid #e5e5e5",
                  textAlign:"center",
                  justifyContent:"center"
                },
              }}
              enableRowVirtualization
              rowVirtualizerProps={{ overscan: 20 }} 
              rowVirtualizerInstanceRef={this.tableInstanceRef} 
              muiTableBodyRowProps={( {row,staticRowIndex} ) => ({
                sx:{
                  height: "fit-content",
                  backgroundColor: (staticRowIndex % 2 === 0 ) ? '#f3f3f3'  : "",
              }})}
              localization={{
                actions: 'عملیات',
                noRecordsToDisplay:"داده‌ای برای نمایش وجود ندارد!"
              }}
              renderRowActions={({ row }) => [
                <div key={row && row?.original && row?.original?.item && row?.original?.item[0] && row?.original?.item[0]?.id} className='detail-container'>
                  <Tooltip title="مشاهده و ویرایش"><span> <AiOutlineEdit className='icon-orange' onClick={() => this.handleOpenModal(row.original.item)} /></span></Tooltip>
                  <Tooltip title="افزودن آدرس"><span> <IoMdAddCircleOutline className='icon-green' onClick={() => this.handleOpenAddAddreassModal(row.original.item)} /></span></Tooltip>
                </div>
              ]}
              sortDescFirst={true}
              enableSorting={true}
              initialState={{
                sorting: [
                      {
                        id: 'date',
                        desc: true,

                      },
                    ],
                pagination : {pageSize:100, pageIndex:0}
              }}
            />
          </div>
        </div>
      </ThemeProvider >
      </HelmetProvider>
    );
  }
}
const mapStateToProps = (dispatch:any) => {
  return {
    dispatch,
    siteList:dispatch.general.siteList,
    loadingSiteList:dispatch.general.loadingSiteList,
  }
};
export default connect(mapStateToProps)(AddressManagement);
