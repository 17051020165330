import { Link } from "react-router-dom";
import { CustomButton , ButtonOutline} from "../../../components";
import { InputTextLabel } from "../../../components/input_text_label";
import {RegisterController} from "../../../controllers";
import { toFarsiNumber } from "../../../../core/utils";
import { Helmet, HelmetProvider } from 'react-helmet-async';
class Register extends RegisterController {
  componentDidMount() {

  }
  render() {
    const { firstName, lastName, phone, password, showPassword, errorsList, minutes, seconds, loading, resend } = this.state;
    return (
      <HelmetProvider>
      <div
        className="register"
      >
        {/* <img src={LogoLight} className="register-icon" /> */}
        <div className="register-modal">
         <Helmet>
                <title> ثبت نام </title>
            </Helmet>
          <div className="register-modal-header">
            <h4 className="register-modal-header-title">{showPassword ? "احراز شماره همراه " : "ثبت نام"}</h4>
            {!showPassword &&
              <Link to="/">
                <ButtonOutline title={"ورود به پنل"} />
              </Link>
            }
          </div>
          <div className="register-modal-input-group">
            {!showPassword ?
              <>
                <div className="register-modal-input-group-name">
                  <div className="ms-1 w-50">
                    <InputTextLabel
                      value={firstName}
                      placeholder={"نام"}
                      type={"text"}
                      errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "firstName")[0]?.message}
                      name="firstName"
                      onChange={(event) => {
                        this.setState({ firstName: event.currentTarget.value });
                      }}
                    />
                  </div>
                  <div className="me-1 w-50">
                    <InputTextLabel
                      value={lastName}
                      placeholder={"نام خانوادگی"}
                      type={"text"}
                      name="lastName"
                      errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "lastName")[0]?.message}
                      onChange={(event) => {
                        this.setState({ lastName: event.currentTarget.value });
                      }}
                    />
                  </div>
                </div>
                <div className="register-modal-input-group-phone">
                  <InputTextLabel
                    value={phone}
                    placeholder={"شماره تماس"}
                    type={"number"}
                    name="phone"
                    errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "phone")[0]?.message}
                    onChange={(event) => {
                      this.setState({ phone: event.currentTarget.value });
                    }}
                  />
                </div>
                <CustomButton loading={loading} myStyle="register-modal-submit-btn" onClick={() => this.registerUser()} title={"ثبت نام"} />
              </>
              :
              <><div className="register-modal-input-group-phone">
                <InputTextLabel
                  value={password}
                  placeholder={"کد ارسال شده "}
                  type={"number"}
                  name="password"
                  onKeyDown={this.handleKeyPressDown}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "password")[0]?.message}
                  onChange={(event) => {
                    this.setState({ password: event.currentTarget.value });
                  }} />
              </div>
                <CustomButton myStyle="register-modal-button" loading={loading} onClick={() => resend ? this.handleResendCode() : this.otpRegister()}
                  title={`${resend ? " ارسال مجدد کد " : `${toFarsiNumber(minutes)} ${minutes > 0 ? ":" : " "} ${toFarsiNumber(seconds) < 10 ? toFarsiNumber("0") + toFarsiNumber(seconds) : toFarsiNumber(seconds)} `}`} />
              </>
            }
          </div>
        </div>
      </div>
      </HelmetProvider>
    );
  }
}
export default Register;
