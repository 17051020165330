import { Component } from "react";
import * as yup from 'yup'
import { toast } from "react-toastify";
import { call_api, parseJSON } from "../../../core/service";
import { AppConstants } from "../../../core/constants";
interface IProps { }

interface IState {
  details: any;
  loading: boolean;
  firstName: string;
  lastName: string;
  email: string;
  automaticSave: string;
  availebleProfileImage?: any;
  loadingUpdateProfile: boolean;
  profileImage?: any,
  profileImageUpload?: any,
  errorsList?: any,
  firstNameValidation?: string
  phone?: string;
  password: string;
  instagram: string;
  whatsapp: string;
  telegram: string,
  mobile: string,
  userRole: string,
  registeryDate: string,
  gender: boolean,
  birth: string,
  localPassword: string,
  emailCheck: boolean,
  banUser: boolean,
  roleList:any,
  userInfo:any
}

 class UpdateUserController extends Component<IProps, IState> {

  constructor(props: any) {
    super(props);
    this.state = {
      details: [],
      loading: false,
      firstName: "",
      lastName: "",
      email: "",
      automaticSave: "",
      phone: "",
      password: "",
      instagram: "",
      whatsapp: "",
      telegram: "",
      mobile: "",
      firstNameValidation: "",
      availebleProfileImage: "",
      profileImage: "",
      profileImageUpload: "",
      errorsList: [],
      userRole: "",
      registeryDate: "",
      gender: false,
      birth: "",
      localPassword: "",
      emailCheck: false,
      banUser: false,
      loadingUpdateProfile: false,
      roleList:[],
      userInfo:{}

    };
  }
  async getRolesList() {
    call_api({
      address_api: `${AppConstants.base_url_api}seller/roles`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          if (j.data.data) {
            const roleSelected = [] 
            for (var i = 0; i < j.data.data.length; i++) {
              if(!this.state.roleList.includes(j.data.data[i].id)){
                const obj = {
                  value: j.data.data[i].name,
                  name:j.data.data[i].persian_name
                }
                roleSelected.push(obj);
              }
            } 
            this.setState({ roleList: roleSelected });
          }
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  async getUserDetails() {
    this.setState({ loading: true })
    call_api({
      address_api: `${AppConstants.base_url_api}seller/users-all/${window.location.pathname.split("/")[2]}`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({ loading: false })
          this.setState({
            userInfo:j.data,
            mobile: j.data.mobile,
            email: j.data.email || "",
            firstName: j.data.profile?.name || "",
            lastName: j.data.profile?.family || "",
            gender: j.data.profile?.gender === 0 ? true : false,
            telegram: j.data?.profile?.telegram || "",
            whatsapp: j.data?.profile?.whatsapp || "",
            instagram: j.data?.profile?.instagram || "",
            phone: j.data.profile?.phone || "",
            // localPassword: j.data?.local_password,
            emailCheck: j.data?.email_verified === 1 ? true : false,
            // automaticSave: j.data.profile?.autosave_time,
            banUser: j.data?.status?.name === 1 ? false : true,
            // birth: j.data.profile?.birthday,
            registeryDate: j.data.created_at,
            userRole:j.data?.roles.persian_name,
            // availebleProfileImage: j.data?.profile?.avatar || "",
          })

        } else if (status === 401) {
          this.setState({ loading: false })
          localStorage.clear();
        } else {
          this.setState({ loading: false })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };


  async updatetUser() {
    const phoneRegExp = /^\d{5,12}$/;
    const mobileRegExp = /^09\d{9}$/;
    const passwordRegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
    const telegramRegExp = /^[a-zA-Z0-9_]{5,32}$/
    const instagramRegExp = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/
    const justPersian = /^[\u0600-\u06FF\s]+$/gi;
    const { mobile, firstName, lastName, gender, userInfo ,  email, phone, password, localPassword, telegram, whatsapp, instagram,
      banUser, automaticSave, emailCheck, birth } = this.state;
      const updateProfileSchema = yup.object({
        firstName: yup.string().required(" نام الزامی می باشد ").max(32, 'نام بیشتر از 32 رقم نمی باشد').min(3, ' نام کمتر از 3 رقم نمی باشد'),
        lastName : yup.string().required(" نام خانوادگی الزامی می باشد ").max(32, 'نام خانوادگی بیشتر از 32 رقم نمی باشد').min(3, '  نام خانوادگی کمتر از 3 رقم نمی باشد'),
      gender: yup.string(),
      password: yup.string().notRequired().min(8, 'کد ورود کمتر از 8 رقم نمی باشد').max(255, 'کد ورود بیشتر از 255 رقم نمی باشد').matches(passwordRegExp, 'فرمت وارد شده صحیح نمی باشد رمز عبور باید حداقل شامل یک حرف بزرگ , یک حرف کوچک  , یک عدد و یک سیمبل باشد').nullable().transform((value) => !!value ? value : null),
      email: yup.string().email("فرمت ایمیل صحیح نمی باشد"),
      // phone: yup.string().required("تلفن الزامی است").matches(phoneRegExp, 'فرمت وارد شده صحیح نمی باشد'),
      companyName: yup.string().max(255, ' نباید بیشتر از 255 کاراکتر باشد'),
      whatsapp: yup.string().notRequired().matches(mobileRegExp,  'فرمت وارد شده صحیح نمی باشد ').nullable().transform((value) => !!value ? value : null),
      telegram: yup.string().notRequired().max(32, 'نام کاربری نباید بیش از 32 کاراکتر داشته باشد').min(5, 'نام های کاربری باید حداقل دارای 5 کاراکتر باشد').matches(telegramRegExp,  'فرمت وارد شده صحیح نمی باشد ').nullable().transform((value) => !!value ? value : null),
      instagram: yup.string().notRequired().max(30, 'نام کاربری نباید بیش از 30 کاراکتر داشته باشد').min(3, 'نام های کاربری باید حداقل دارای 3 کاراکتر باشد').matches(instagramRegExp,  'فرمت وارد شده صحیح نمی باشد ').nullable().transform((value) => !!value ? value : null)
    });

    try {

      await updateProfileSchema.validate({ password,userInfo, firstName, lastName, gender, email, mobile, phone, whatsapp, telegram, instagram }, { abortEarly: false });
      this.setState({ loadingUpdateProfile: true })
      const data = new FormData()
      data.append('name', firstName)
      data.append('family', lastName)
      data.append('mobile', mobile)
      localPassword && data.append('local_password', localPassword)
      data.append('email_verified', emailCheck === true ? '1' : '0')
      phone && data.append('phone', phone)
      data.append('gender', gender ? "0" : "1")
      data.append('email', email)
      data.append('autosave_time', automaticSave === "هر 5 دقیقه" ? '5' : '10')
      birth && data.append('birthday', birth)
      data.append('status', banUser ? "2" : "1")
      data.append('whatsapp', whatsapp)
      data.append('telegram', telegram)
      data.append('instagram', instagram)
      data.append('pasword', password)
      data.append('db_name', userInfo.db_name)

      // if (availebleProfileImage === "") {
      //   data.append('avatar', profileImageUpload)
      // }
      call_api({
        address_api: `${AppConstants.base_url_api}seller/users-all/update/${userInfo.id}`,
        method_api: "POST",
        body: data,
        file: true,
      })

        .then(parseJSON)
        .then(([status, j]) => {
          this.setState({ errorsList: [] })
          if (status === 200) {
            toast.success("با موفقیت انجام شد");
            this.setState({ loadingUpdateProfile: false })
            window.location.href = "/users";
          } else if (status === 401) {
            this.setState({ loadingUpdateProfile: false })
            toast.error(j?.message);

            localStorage.clear();
          } else {
            console.warn(status, j);
            this.setState({ loadingUpdateProfile: false })
            toast.error(j?.message);

          }
        })
        .catch((error) => {
          console.error(error);
        });

    } catch (err: any) {
      this.setState({ loadingUpdateProfile: false })
      this.setState({ errorsList: err.inner })
    }
  };

  async handleProfileImageUpload(event: any) {
    let news_image_file = null;
    news_image_file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(news_image_file);
    reader.onloadend = () => {
      this.setState({ profileImage: reader.result });
    }
    if (news_image_file) {
      this.setState({ profileImageUpload: news_image_file });
    }
    this.setState({ availebleProfileImage: "" });
  }

  deleteNewImage = () => {
    this.setState({
      profileImage: "", profileImageUpload: "", availebleProfileImage: ""
    })
  }
  async handleDeleteProfileImage() {
    call_api({
      address_api: `${AppConstants.base_url_api}seller/users/delete_avatar/${window.location.pathname.split("/")[2]}`,
      method_api: "POST",
    })

      .then(parseJSON)
      .then(([status, j]) => {
        this.setState({ errorsList: [] })
        if (status === 200) {
          toast.success("با موفقیت انجام شد");
          this.setState({
            profileImage: "", profileImageUpload: "", availebleProfileImage: ""
          })
        } else if (status === 401) {
          toast.error(j?.message);
          localStorage.clear();
        } else {
          console.warn(status, j);
          this.setState({ loadingUpdateProfile: false })
          toast.error(j?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  handleEmailChange = (event: any) => {
    this.setState({ emailCheck: event.target.checked });
  };
  handleBanUserChange = (event: any) => {
    this.setState({ banUser: event.target.checked });
  };
}
export default UpdateUserController;
