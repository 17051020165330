import { Component } from "react";
import { AppConstants } from "../../../core/constants";
import { call_api, parseJSON } from "../../../core/service";
import { toast } from "react-toastify";
import * as yup from 'yup'
interface IProps {
  dispatch?:any,
  siteList?:any,
  loadingSiteList?:any,
}
interface IState {
  loading: boolean;
  firstName: string;
  check: boolean;
  driverId: any;
  settingList: any;
  alopeyk: boolean;
  tipax: boolean;
  post: boolean;
  porterage: boolean;
  order_date: boolean;
  order_hours: boolean;
  order_days: string;
  official_invoice: boolean;
  tax: boolean;
  invoice_tax: string;
  receive_address_one: string;
  receive_address_three: string;
  receive_address_two: string;
  receive_label_one: string;
  receive_label_three: string;
  receive_label_two: string;
  receive_person_one: string;
  receive_person_three: string;
  receive_person_two: string;
  receive_phone_one: string;
  receive_phone_three: string;
  receive_phone_two: string;
  factor_info_address: string;
factor_info_email: string;
factor_info_phone: string;
factor_info_postal: string;
factor_info_site: string;
errorsList?: any,
factor_info_title: string;
isSend?:boolean;
}
 class StoreSettingsController extends Component<IProps, IState> {
  
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      firstName: "",
      check: false,
      driverId: null,
      settingList: [],
      alopeyk: false,
      tipax: false,
      post: false,
      porterage: false,
      order_date: false,
      order_hours: false,
      order_days: "",
      official_invoice: false,
      tax: false,
      invoice_tax: "",
      receive_address_one: "",
      receive_address_three: "",
      receive_address_two: "",
      receive_label_one: "",
      receive_label_three: "",
      receive_label_two: "",
      receive_person_one: "",
      receive_person_three: "",
      receive_person_two: "",
      receive_phone_one: "",
      receive_phone_three: "",
      receive_phone_two: "",
      factor_info_address: "",
factor_info_email: "",
factor_info_phone: "",
factor_info_postal: "",
factor_info_site: "",
factor_info_title: "",
errorsList: [],
isSend:false,
    };
  }
  driverTimeout: string | number | NodeJS.Timeout | undefined;
  driverSelected = (choice: any) => {
    this.setState({ driverId: choice });
    clearTimeout(this.driverTimeout);
    this.driverTimeout = setTimeout(() => {
      this.getSetting();
    }, 1000);
  };
  async getSetting(page?: number) {
    this.setState({ loading: true });
    const { driverId } = this.state;
    let parameters = {
      page: page ? page.toString() : null,
      db_name: driverId?.value?.name,
    };
    let url = new URL(`${AppConstants.base_url_api}seller/setting`);
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value);
      }
    }
    call_api({
      address_api: url.search
        ? `${AppConstants.base_url_api}seller/setting${url.search}`
        : `${AppConstants.base_url_api}seller/setting`,
      method_api: "GET",
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
            settingList: j.data,
            loading: false,
            alopeyk: j.data.alopeyk  === 1 ? true : false,
            tipax: j.data.tipax === 1 ? true : false,
            post: j.data.post  === 1 ? true : false,
            porterage: j.data.porterage  === 1 ? true : false,
            order_date: j.data.order_date === 1 ? true : false,
            order_hours: j.data.order_hours === 1 ? true : false,
            order_days: j.data.order_days.toString(),
            official_invoice: j.data.official_invoice === 1 ? true : false,
            tax: j.data.tax === 1 ? true : false,
            invoice_tax: j.data.invoice_tax,
            receive_address_one: j.data.receive_address_one,
            receive_address_three: j.data.receive_address_three,
            receive_address_two: j.data.receive_address_two,
            receive_label_one: j.data.receive_label_one,
            receive_label_three: j.data.receive_label_three,
            receive_label_two: j.data.receive_label_two,
            receive_person_one: j.data.receive_person_one,
            receive_person_three: j.data.receive_person_three,
            receive_person_two: j.data.receive_person_two,
            receive_phone_one: j.data.receive_phone_one,
            receive_phone_three: j.data.receive_phone_three,
            receive_phone_two: j.data.receive_phone_two,
            factor_info_address: j.data.factor_info_address,
            factor_info_email: j.data.factor_info_email,
            factor_info_phone: j.data.factor_info_phone,
            factor_info_postal: j.data.factor_info_postal,
            factor_info_site: j.data.factor_info_site,
            factor_info_title: j.data.factor_info_title,
          });
        } else if (status === 401) {
          this.setState({ loading: false });
          localStorage.clear();
        } else {
          this.setState({ loading: false });
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async handleSubmit() {
    const {driverId ,
      alopeyk,
      tipax,
      post,
      porterage,
      order_date,
      order_hours,
      order_days,
      official_invoice,
      tax,
      invoice_tax,
      receive_address_one,
      receive_address_three,
      receive_address_two,
      receive_label_one,
      receive_label_three,
      receive_label_two,
      receive_person_one,
      receive_person_three,
      receive_person_two,
      receive_phone_one,
      receive_phone_three,
      receive_phone_two,
      factor_info_address,
      factor_info_email,
      factor_info_phone,
      factor_info_postal,
      factor_info_site,
      factor_info_title} = this.state;
      if (driverId) {
    const domainRegExp = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/
    const phoneRegExp = /^\d{5,12}$/;

      const settingSchema = yup.object({
        driverId: yup.object().required(" سایت الزامی می باشد "),
        factor_info_email:yup.string().email("فرمت ایمیل صحیح نمی باشد"),
        factor_info_title: yup.string().max(32, 'نام فروشگاه بیشتر از 32 رقم نمی باشد').min(2, ' نام،روشگاه کمتر از2  رقم نمی باشد'),
        factor_info_site:yup.string().matches(domainRegExp,  'فرمت وارد شده صحیح نمی باشد '),
        factor_info_phone:yup.string().matches(phoneRegExp,  'فرمت وارد شده صحیح نمی باشد '),
        factor_info_postal:yup.string().max(10, ' کد پستی بیشتر از 10 رقم نمی باشد').min(10, '  کد پستی کمتر از 10 رقم نمی باشد'),
        receive_phone_one:yup.string().matches(phoneRegExp,  'فرمت وارد شده صحیح نمی باشد '),
        receive_phone_two:yup.string().matches(phoneRegExp,  'فرمت وارد شده صحیح نمی باشد '),
        receive_phone_three:yup.string().matches(phoneRegExp,  'فرمت وارد شده صحیح نمی باشد '),
      });
      try {
      await settingSchema.validate({ driverId,factor_info_email,factor_info_title,factor_info_site,factor_info_phone,factor_info_postal,receive_phone_one,receive_phone_two,receive_phone_three}, { abortEarly: false });

      this.setState({ isSend: true })
      call_api({
        address_api: `${AppConstants.base_url_api}seller/setting`,
        method_api: "POST",
        body: JSON.stringify({
          db_name: driverId?.value?.name,
          alopeyk : alopeyk ? "1" : "0",
      tipax: tipax ? "1" : "0",
      post : post ? "1" : "0",
      porterage : porterage ? "1" : "0",
      order_date : order_date ? "1" : "0",
      order_hours : order_hours ? "1" : "0",
      order_days,
      official_invoice : official_invoice ? "1" : "0",
      tax : tax ? "1" : "0",
      invoice_tax:Number(invoice_tax),
      receive_address_one,
      receive_address_three,
      receive_address_two,
      receive_label_one,
      receive_label_three,
      receive_label_two,
      receive_person_one,
      receive_person_three,
      receive_person_two,
      receive_phone_one,
      receive_phone_three,
      receive_phone_two,
      factor_info_address,
      factor_info_email,
      factor_info_phone,
      factor_info_postal,
      factor_info_site,
      factor_info_title

        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            this.setState({ isSend: false , errorsList: []})
            toast.success("با موفقیت انجام شد ");
          } else if (status === 401) {
            localStorage.clear();
            this.setState({ isSend: false })

          } else {
            toast.error(j?.message);
            this.setState({ isSend: false })
          }
        })
        .catch((error) => {
          console.error(error);
          this.setState({ isSend: false })
        });
      } catch (err: any) {
        this.setState({ loading: false })
        this.setState({ errorsList: err.inner })
      }}
      else {
        toast.error("لطفا یک سایت را انتخاب کنید")
      }
    } 
  };
export default StoreSettingsController;
