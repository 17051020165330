import {
    InputHTMLAttributes,
    ReactElement,
  } from "react";
  import {InputTextLabel} from "."
import { RxCrossCircled } from "react-icons/rx";
import { AiFillCheckCircle } from "react-icons/ai";
import { Tooltip } from "@mui/material";
  interface Props extends InputHTMLAttributes<HTMLInputElement> {
    img:any,
    alt:any,
    redIconTooltip:string,
    greenIconTooltip:string,
    handleSubmit:any,
    handleDelete:any,
    changeAlt:any,
  }
  
   function SeoCard({
    img,
    alt,
    changeAlt,
    redIconTooltip,
    handleDelete,
    handleSubmit,
    greenIconTooltip,
  }: Props): ReactElement {

  
    return (
        <div className="seo-card w-100">
          <div className="action">
            <Tooltip title={redIconTooltip}><span> <RxCrossCircled className="icon-red" onClick={handleDelete}  /></span></Tooltip>
            <Tooltip title={greenIconTooltip}><span> <AiFillCheckCircle className="icon-green" onClick={handleSubmit}/></span></Tooltip>
          </div>
          <img src={img} alt="..." />
          <InputTextLabel
                type={"text"}
                myStyle="search-input"
                value={alt}
                onChange={changeAlt} 
                placeholder={""}                    
            />
        </div>
    );
  }
  export default SeoCard;
  