import { CustomButton , DropDownIcon, InputTextLabel, Header, Option} from "../../../components";
import {UsersManagementController} from "../../../controllers";
import { Box, createTheme, ThemeProvider } from '@mui/material';
import { Link } from "react-router-dom";
import { FiAlertTriangle } from "react-icons/fi";
import { SubmissionAlert} from '../../../components';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { AiOutlineFileExcel, AiOutlineEdit } from "react-icons/ai";
import { MdBlockFlipped } from "react-icons/md";
import {BsCheckSquare} from "react-icons/bs";
import Pagination from '@mui/material/Pagination';
import Select, { CSSObjectWithLabel, components } from "react-select";
import moment from 'moment-jalaali';
import Tooltip from '@mui/material/Tooltip';
import { connect } from "react-redux";
import {TfiFilter} from "react-icons/tfi";
import { Helmet } from "react-helmet";
class UsersManagement extends UsersManagementController {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getUsersList(1);
    this.getRolesList();
  }

  render() {
    type Person = {
      item: any;
      id: number;
      mobile: string;
      name: string;
      role: string;
    };

    const { search, showFilters, usersType, usersTypeList, loading, page ,
      paymentStatusDropDown,rowCount,rowCountList,selectedPaymentStatusDropDown, openBanModal, usersDataList } = this.state;
      const {loadingSiteList, siteList } = this.props;

      const theme = createTheme({
      direction: "ltr",
      typography: {
        fontFamily:
          'Vazir',
      }
    });    
    const columns =
      [
       
        {
          accessorKey: 'id',
          header: 'ردیف',
          Cell: ({ cell }) => (
            <div
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              {cell.getValue<string>()}
            </div>
          ),
          size:10
        },
        {
          accessorKey: 'driver',
          header: 'دسترسی',
          Cell: ({ cell }) => (
            <div
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              {cell.getValue<string>()}
            </div>
          ),
          size:10
        },
        {
          accessorKey: 'name',
          header: 'نام',
          Cell: ({ cell }) => (
            <div
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              {cell.getValue<string>()}
            </div>
          )
        },

        {
          accessorKey: 'mobile',
          header: 'همراه',
          Cell: ({ cell }) => (
            <div
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              {cell.getValue<string>()}
            </div>
          )
        },
        {
          accessorKey: 'role',
          header: 'نقش',
          Cell: ({ cell }) => (
            <div
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              {cell.getValue<string>()}
            </div>
          )
        },
       
      

      ] as MRT_ColumnDef<Person>[]
    return (
      <ThemeProvider theme={theme}>
        <div className="users-info">
        <Helmet>
                <title>لیست کاربران</title>
            </Helmet>
          <Header isActive= "isUsersMangement" title="لیست کاربران"/>
          <div className="custom-table">
          <div className="custom-card mb-3">

              {openBanModal && <SubmissionAlert isOpen={openBanModal} title="اخطار!"
                setClose={(open: boolean) => this.setState({ openBanModal: false })}
                onClick={() => this.handleSubmitBanUser()} icon={<FiAlertTriangle color="orange" fontSize="50" />}
                details={`${selectedPaymentStatusDropDown === "1" ? "از مسدودکردن این کاربر اطمینان دارید؟" : "از فعال کردن این کاربر اطمینان دارید؟"}`} />}
              
              <div className="filter-select-container mb-2 pe-3">
                <div className="filter-options-container">
                {showFilters && 
                  <>
                <div className="column-30"> 
                    <Select
                      styles={{
                        control: (baseStyles: any, state: any):CSSObjectWithLabel => ({
                          ...baseStyles,
                          boxShadow:"rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",

                          borderWidth: "1px",
                          backgroundColor: "#fff",
                          display: "flex",
                          flexDirection: "row-reverse",
                          textAlign: "right",
                          borderRadius: "4px",
                          minHeight:"42px",
                          outline: "unset",
                        }) as CSSObjectWithLabel,
                      }}
                      onChange={this.userTypeSelect}
                      components={{ Option }}
                      value={usersType}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      noOptionsMessage={() => 'این مورد وجود ندارد!'}
                      menuPortalTarget={document.body}
                      options={usersTypeList}
                      getOptionLabel={(option: any) => option?.name}
                      getOptionValue={(option: any) => option?.value}
                      placeholder="همه کاربران"
                      isClearable
                      loadingMessage={() => 'در حال بارگذاری  ...'}
                      isLoading={loadingSiteList}
                    />

                    </div> 
                  <div className="column-30">
                  <DropDownIcon
                      value={selectedPaymentStatusDropDown}
                      title={" وضعیت کاربر "}
                      inBox={" وضعیت کاربر "}
                      type={"text"}
                      optionList={paymentStatusDropDown}
                      onChange={(event) => this.handleChangeStatus(event)}
                />
                  </div>
<div className="column-30">
                  <div className="users-info-search-input">
                  <InputTextLabel
                    type={"text"}
                    value={search}
                    onChange={(event) => this.handleSearch(event)}
                    placeholder="جستجو..."
                  />
                </div>
                </div>
              </> }
                </div>
              <CustomButton
                        loading={false}
                        route="/users"
                        title="لیست کاربران"
                        myStyle="excel-btn mb-2"
                      />
              </div>
              <div className="table-container">
            
                <MaterialReactTable
                  muiTablePaperProps={{
                    sx: { m: '1.3rem', border:"1px solid #e5e5e5" }}}
                  columns={columns}
                  muiTableProps={{
                    sx: {
                      border: "1px solid #e5e5e5",
                
                    },
                  }}
                  muiTableHeadCellProps={{
                    sx: {
                      height:"fit-content",
                      border: "1px solid #e5e5e5",
                    },
                  }}
                  muiTableBodyRowProps={( {row,staticRowIndex} ) => ({
                    sx:{
                      height: "fit-content",
                      backgroundColor: (staticRowIndex % 2 === 0 ) ? '#f3f3f3'  : "",
                  }})}
                  muiTableBodyCellProps={{
                    sx: {
                      height:"fit-content",
                      border: "1px solid #e5e5e5",
                      textAlign:"center"
                      
                    },
                  }}
                  displayColumnDefOptions={{
                    'mrt-row-expand': {
                      muiTableHeadCellProps: {
                        align: 'center',
                      },
                      muiTableBodyCellProps: {
                        align: 'center',
                      },
                    },
                  }}
                  data={usersDataList && usersDataList?.data?.length > 0 &&
                    usersDataList?.data?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null).map((item: any, index: number) =>
                     {
                      return ({
                        item: item,
                        row: index + 1,
                        driver: <img src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo} alt="..." />,
                        id: index + 1,
                        name: item?.full_name,
                        mobile: item.mobile,
                        role: item.role[0]?.persian_name
                      }
                      );
                    }
                    )}
                    enablePagination={false}
                renderBottomToolbarCustomActions={({table})=>{
                  return (
                    <div className="custom-pagination w-100">
                     <Pagination  page={page} count={usersDataList?.total ? Math.ceil(usersDataList?.total / rowCount) : 1} onChange={this.handleChangePage} />
                      <DropDownIcon
                      value={rowCount}
                      optionList={rowCountList}
                      title={""}
                      onChange={(event) =>
                        {
                          this.handlePaginationSelect(event)
                      }
                    }
                    />
                    </div>
                  );
                }}
                  positionActionsColumn={'last'}
                  positionExpandColumn={'last'}
                  enableGlobalFilter={false}
                  enableColumnFilters={false}
                  enableDensityToggle={false}
                  enableFullScreenToggle={false}
                  enableHiding={false}
                  renderTopToolbarCustomActions={({ table }) => (
                    <Box
                      sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap', justifyContent:"space-between", width:"100%" }}
                    >
                      <CustomButton
                        loading={false}
                        onClick={() => this.exportToExcel(usersDataList?.data, "Excel Export")}
                        title="اکسل"
                        myStyle="excel-btn"
                        icon={<AiOutlineFileExcel />}
                      />
                      <Tooltip title="نمایش فیلترها"><span> <TfiFilter className='filter-icon' onClick={() => this.setState(prevState => ({
                                  showFilters: !prevState.showFilters
                                }))
                              }/></span></Tooltip>
                      <div className='custom-pagination'>
                      <Pagination  page={page} count={usersDataList?.total ? Math.ceil(usersDataList?.total / rowCount) : 1} onChange={this.handleChangePage} />
                  <DropDownIcon
                  value={rowCount}
                  optionList={rowCountList}
                  title={""}
                  onChange={(event) =>
                    {
                      this.handlePaginationSelect(event)
                  }
                }
                />
                    </div>
                    </Box>
                  )}
                  enableColumnActions={false}
                  renderDetailPanel={({ row }) => (
                    <div key={row.original?.item?.id} className="detail-container">
                      <div className="detail-col" >
                        <p className="detail-row">
                        ایمیل :  {row.original?.item?.email || " ثبت نشده "} 
                       </p>
                       <p className="detail-row">
                         اعتبار: {row.original?.item?.credit}
                        </p>
                       <p className="detail-row">
                          عضویت: {moment(row.original?.item?.created_at).format("HH:mm:ss jYYYY/jM/jD ")}
                        </p>
                      </div>
                      <div className="detail-col" >
                        <p className="detail-row">
                          وضعیت: {row.original?.item?.status?.value}</p>
                        <p className="detail-row">
                          نمایه: {row.original?.item?.avatar && <img alt="..." className="users-img" src={row.original?.item?.avatar} />}
                        </p>
                        <p className="detail-row">
                          آخرین ویرایش: {moment(row.original?.item?.updated_at).format("HH:mm:ss jYYYY/jM/jD ")}
                        </p>
                      </div>

                    </div>
                  )}
                  enableRowActions
                  localization={{
                    actions: 'عملیات',
                    noRecordsToDisplay:"داده‌ای برای نمایش وجود ندارد!"
                  }}
                  
                  state={{ isLoading: loading, showProgressBars: loading }}
                  renderRowActions={(row: any) => [
                    <div key={row.row.original?.item?.id} className='detail-container'>
                      {selectedPaymentStatusDropDown === "1" ?
                        <Tooltip title="مسدودکردن"><span><MdBlockFlipped className='icon-red' onClick={() => this.handleBanUser(row.row.original)} /></span></Tooltip>
                        :
                        <Tooltip title="رفع مسدودیت"><span><BsCheckSquare className='icon-green' onClick={() => this.handleBanUser(row.row.original)} />
                        </span></Tooltip>

                      }
                      <Link target="_blank" to={`/admin/update-user/${row.row.original?.item?.id_select}`}>
                      <Tooltip title="ویرایش"><span><AiOutlineEdit className='icon-gray' /></span></Tooltip>
                        
                      </Link>
                    </div>
                  ]}
                  initialState={{
                    pagination : {pageSize:100, pageIndex:0}
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}
const mapStateToProps = (dispatch:any) => {
  return {
    dispatch,
    siteList:dispatch.general.siteList,
    loadingSiteList:dispatch.general.loadingSiteList,
  }
};
export default connect(mapStateToProps)(UsersManagement);