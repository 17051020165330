import Box from "@mui/material/Box";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { alertButtonsState } from "../../../../core/utils";
import { RiDeleteBin6Line } from "react-icons/ri";
import {  AiOutlineFileExcel, AiOutlineEdit  } from "react-icons/ai";
import {SubmissionAlert, Header, UpdateContent, Tab, Option } from "../../../components";
import { CustomButton, DropDownIcon } from "../../../components";
import { FiAlertTriangle } from "react-icons/fi";
import { InputTextLabel } from "../../../components/input_text_label";
import { createTheme, ThemeProvider } from "@mui/material";
import { Link } from "react-router-dom";
import { FaRegDotCircle } from "react-icons/fa";
import { BiRecycle, BiShowAlt } from "react-icons/bi";
import Pagination from "@mui/material/Pagination";
import Tooltip from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { TfiFilter } from "react-icons/tfi";
import { Helmet } from "react-helmet";
import {CategoriesListController} from "../../../controllers";
import Select, { components, CSSObjectWithLabel } from "react-select";
import { categoryData } from "../../../../Redux/services/productFilterService";
import TreeStructure from "./tree";




class CategoriesList extends CategoriesListController {

  componentDidMount() {
   window.scrollTo(0, 0);
    this.getList();

  }
  render() {
    type ListOfData = {
      item?: any;
      row: number;
      driver: any;
      image: string;
      name:string;
      count: string;
      code: string;
      detail:any;
    };
   
    const { 
      showFilters,
      rowCountList,
      loading,
      toggleModal,
       tagList,
      total,
      rowCount,
      details,
      search,
      dreiverId,
      allOption,
      showAddNewItem,
      showUpdateItem,
      step,
      updateDetails,
      page,
      detailPannelData,
      selectedRowIndex
    } = this.state;
    const { siteList , loadingSiteList} = this.props;
    const theme = createTheme({
      typography: {
        fontFamily: "Vazir",
      },
    });
    const columns = [
      {
        accessorKey: "row",
        header: "ردیف",
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        size: 10,
      },
      {
        accessorKey: "driver",
        header: "سایت",
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        size: 10,
      },
      {
        accessorKey: "image",
        header: "تصویر",
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        size: 10,
      },
      {
        accessorKey: "name",
        header: "نام ",
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "right",
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
      },
      {
        accessorKey: "count",
        header: "تعداد",
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        invertSorting:true,
        sortingFn: (rowA, rowB, columnId) => {
          return Number(rowA.original.item.price) - Number(rowB.original.item.price)

        }
      },
    ] as MRT_ColumnDef<ListOfData>[];
    return (
      <ThemeProvider theme={theme}>
        <div className="price-list products-list">
        <Helmet>
            <title> مدیریت دسته بندی   </title>
          </Helmet>
          <Header isActive = "isCategory" title="مدیریت دسته بندی  "/>
          <div className="custom-table">
            <div className="custom-card">
              <div className="filter-select-container pe-3">
                <div className="filter-options-container ">
                  {showFilters && (
                    <>
                     <div className="column-30">
                <Select
                placeholder="سایت"
                noOptionsMessage={() => 'این مورد وجود ندارد!'}
                isClearable
                loadingMessage={() => 'در حال بارگذاری  ...'}
                isLoading={ loadingSiteList}
                styles={{
                  control: (baseStyles: any, state: any): CSSObjectWithLabel => ({
                    ...baseStyles,
                    boxShadow:"rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",
                    borderWidth: "1px",
                    backgroundColor: "#fff",
                    display: "flex",
                    flexDirection: "row-reverse",
                    textAlign: "right",
                    borderRadius: "4px",
                    outline: "unset",
                  }) as CSSObjectWithLabel,
                  }}
                  components={{ Option }}
                  menuPortalTarget={document.body}
                  closeMenuOnSelect={true}
                  value={dreiverId}
                  isMulti={true}
                  getOptionLabel={(option: any) => option?.value?.persian_name}
                  getOptionValue={(option: any) => option?.value?.name}
                  options={[ allOption, ...siteList]}
                  onChange={this.handleChange}
                />                 
                </div>
                      <div className="column-30">
                        <InputTextLabel
                          type={"text"}
                          myStyle="search-input"
                          value={search}
                          onChange={(event) => this.handleSearch(event)}
                          placeholder="جستجو..."
                        />
                      </div>
                      <div></div>
                     
                      </>
                  )}
                  <CustomButton
                        loading={false}
                        onClick={() => this.setState({showAddNewItem:!showAddNewItem})}
                        title="افزودن"
                        myStyle="excel-btn mb-2"
                      />
               {showAddNewItem && 
               <>
        <div className="sites-tab-conteainer d-flex justify-content-start mb-3 w-100">
      {siteList && siteList?.length > 0 && siteList?.map((item: any, index: number) => {
                return (
                 < div key={index}>
                 
                 <Tab
    title={item?.value?.name}
    isActive={step === item?.value?.name ? true : false}
    onClick={() => this.addNewObject(item?.value?.name)}
    />

</div>
                );
              }
              )}
              </div>
              {siteList?.filter((obj: any) => obj?.value?.name === step)[0]?.value?.name &&
                <UpdateContent 
                details={details?.filter((obj: any) => obj?.dbName === step)[0]}
 reload={step}
    setDatas={(per:any) => this.setDetailsInfo(per)}
    />
}
                 <CustomButton
                        loading={false}
                        onClick={() =>this.create()}
                        title="ثبت"
                        myStyle="m-auto excel-btn mb-2"
                      />
                </>
               }
{showUpdateItem && 

<>
 <UpdateContent 
 details={updateDetails}
 reload={updateDetails.id}
 setDatas={this.setUpdateDetailsInfo}
 />
  <CustomButton
         loading={false}
         onClick={() => this.update()}
         title="ویرایش"
         myStyle="m-auto excel-btn mb-2"
       />
 </>
}

               
                </div>
              </div>
              <div>
              {toggleModal.status === true &&
                  toggleModal.buttonClick === alertButtonsState.RECYCLE && (
                    <SubmissionAlert
                      isOpen={toggleModal.status}
                      title="اخطار!"
                      setClose={(open: boolean) =>
                        this.setState({
                          toggleModal: {
                            status: open,
                            buttonClick: alertButtonsState.RECYCLE,
                          },
                        })
                      }
                      onClick={()=> this.handleRecovery()}
                      icon={<FiAlertTriangle color="orange" fontSize="50" />}
                      details="از این عملیات اطمینان دارید؟"
                    />
                  )}
                {toggleModal.status === true &&
                  toggleModal.buttonClick === alertButtonsState.DELETE && (
                    <SubmissionAlert
                      isOpen={toggleModal.status}
                      title="اخطار!"
                      setClose={(open: boolean) =>
                        this.setState({
                          toggleModal: {
                            status: open,
                            buttonClick: alertButtonsState.DELETE,
                          },
                        })
                      }
                      onClick={() =>  this.handleDelete()}
                      icon={<FiAlertTriangle color="orange" fontSize="50" />}
                      details="از این عملیات اطمینان دارید؟"
                    />
                  )}
                    {toggleModal.status === true &&
                  toggleModal.buttonClick === alertButtonsState.NOINDEX && (
                    <SubmissionAlert
                      isOpen={toggleModal.status}
                      title="اخطار!"
                      setClose={(open: boolean) =>
                        this.setState({
                          toggleModal: {
                            status: open,
                            buttonClick: alertButtonsState.NOINDEX,
                          },
                        })
                      }
                      onClick={() => this.handleChangeIndex()}
                      icon={<FiAlertTriangle color="orange" fontSize="50" />}
                      details="از این عملیات اطمینان دارید؟"
                    />
                  )}
              </div>
              <MaterialReactTable
                muiTablePaperProps={{
                  sx: {
                    mr: "1.5rem",
                    ml: "1.5rem",
                    border: "1px solid #e5e5e5",
                  },
                }}
                columns={columns}
                enablePagination={false}
                // enableExpanding
                muiTableBodyRowProps={( {row,staticRowIndex} ) => ({
                  sx:{
                    height: "fit-content",
                    backgroundColor: (staticRowIndex % 2 === 0 ) ? '#f3f3f3'  : "",
                }})}
                data={
                  tagList &&
                  tagList?.length > 0 &&
                  tagList
                    ?.filter(
                      (v: string | null | undefined) =>
                        v !== "" && v !== undefined && v !== null
                    )
                    .map((item: any, index: number) => {
                      return {
                        item: item,
                        row: index + 1,
                        driver: <img src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo}alt="" />,
                        image: item?.image ? <img src={item?.image} alt=""/>  : null,
                      name: item.persian_name,
                      count: Number(item?.products_count) > 0 ? <Link target="_blank" onClick={()=> this.props.dispatch(categoryData(item))} to={`/admin/products?category=${item?.id}&db-name=${item?.db_name}`}>
                      {item?.products_count}
                        </Link> :item?.products_count ,
                      };
                    })
                }
                 
                muiExpandButtonProps={( cell:any ) => ({
                  
                  onClick: (event:any) => {
                      this.handleDetailPannel(cell?.row?.original?.item,cell?.row?.index)
                     
                  
                  }
                })}
                renderBottomToolbarCustomActions={({table})=>{
                  return (
                    <div className='custom-pagination w-100'>
                      <Pagination  page={page} count={total ? Math.ceil(total / rowCount) : 1} onChange={this.handleChangePage} />
                      <DropDownIcon
                      value={rowCount}
                      optionList={rowCountList}
                      title={""}
                      onChange={(event) =>
                        {
                          this.handlePaginationSelect(event)
                      }
                    }
                    />
                    </div>
                  );
                }}
                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                      sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap', justifyContent:"space-between", width:"100%" }}
                    >
                      <CustomButton
                        loading={false}
                        onClick={() => this.exportToExcel(tagList, "Excel Export")}
                        title="اکسل"
                        myStyle="excel-btn"
                        icon={<AiOutlineFileExcel />}
                      />
                      
                      <Tooltip title="نمایش فیلترها"><span> <TfiFilter className='filter-icon' onClick={() => this.setState(prevState => ({
                                  showFilters: !prevState.showFilters
                                }))
                              }/></span></Tooltip>
                      <div className='custom-pagination'>
                      <Pagination  page={page} count={total ? Math.ceil(total / rowCount) : 1} onChange={this.handleChangePage} />
                      <DropDownIcon
                      value={rowCount}
                      optionList={rowCountList}
                      title={""}
                      onChange={(event) =>
                        {
                          this.handlePaginationSelect(event)
                      }
                    }
                    />
                    </div>
                    </Box>
                  )}
                state={{ isLoading: loading, showProgressBars: loading, expanded:{[selectedRowIndex]:true} }}
                positionExpandColumn={"last"}
                enableGlobalFilter={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableHiding={false}
                sortDescFirst={true}
                // getRowId={(row) => row.code}
                enableColumnActions={false}
                enableSorting={true}
                initialState={{
                 
                  pagination: { pageSize: 100, pageIndex: 0 },
                }}
                enableRowActions
                localization={{
                  actions: "عملیات",
                  noRecordsToDisplay: "داده‌ای برای نمایش وجود ندارد!",
                }}
                muiTableProps={{
                  sx: {
                    border: "1px solid #e5e5e5",
                  },
                }}
                muiTableHeadCellProps={{
                  sx: {
                    border: "1px solid #e5e5e5",
                    height: "fit-content",
                  },
                }}
                muiTableBodyCellProps={{
                  sx: {
                    border: "1px solid #e5e5e5",
                    height: "fit-content",
                    textAlign:"center"

                  },
                }}
                
                renderDetailPanel={({ row }) => (
                  <div  key={row?.index}>
                   {detailPannelData?.length > 0 ? detailPannelData?.map((item:any, index:number) =>{
                      return (
                        <TreeStructure handleEdit={(data:any) =>{
                          this.handleOpenUpdate(data)
                        }} 
                        handleDelete={(data:any)=>{
                          this.handleDeleteModal(data)
                        }}
                        handleRecycleItem={(data:any)=>{
                          this.handleRecycle(data)
                        }}
                        handleNoIndexItem={(data:any)=>{
                          this.handleNoIndex(data)
                        }}
                        explorer={item} key={index}/>
                      )
                   })
                   :          
                   <div className="error-text">!هیچ داده‌ای وجود ندارد.</div>}
                  </div>
                )}
                  displayColumnDefOptions={{
                  'mrt-row-expand': {
                    muiTableHeadCellProps: {
                      align: 'center',
                    },
                    muiTableBodyCellProps: {
                      align: 'center',
                    },
                    
                  },
                }}
                positionActionsColumn={"last"}
                tableInstanceRef={this.tableInstanceRef}
                renderRowActions={({ row, table }) => [
                  <div key={row.original.item?.id} className="detail-container">
                    {row.original?.item?.deleted_at ? (
                     <Tooltip title="بازیابی کردن">
                      <span>
                        {" "}
                        <BiRecycle
                          className="icon-green"
                          onClick={() =>this.handleRecycle(row.original?.item)}
                        />
                      </span>
                      </Tooltip>
                        ) : (
                                           <Tooltip title="حذف کردن">
                                             <span>
                                               {" "}
                                               <RiDeleteBin6Line
                                                 className="icon-red"
                                                 onClick={() =>this.handleDeleteModal(row.original?.item)}
                                               />
                                             </span>
                                           </Tooltip>
                                         )}
                   
                   <Tooltip title={`${row.original?.item?.no_index === 1 ? "no-index" : "index"} `}>
                        <span>
                          {" "}
                          <FaRegDotCircle
                            className={`${row.original?.item?.no_index === 1 ? "icon-red" : "icon-green"} `}
                            onClick={() => this.handleNoIndex(row.original?.item)}
                          />
                        </span>
                      </Tooltip>

                    <Tooltip title="نمایش">
                      <span>
                        <Link target="_blank" to={row.original.item?.view}>
                          <BiShowAlt className="icon-gray" />
                        </Link>
                      </span>
                    </Tooltip>
                    <Tooltip title="ویرایش"><span onClick={()=> this.handleOpenUpdate(row.original.item)}>
                    <AiOutlineEdit className='icon-yellow' />
                    </span></Tooltip>

                  </div>,
                ]}
              />
             
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}
const mapStateToProps = (dispatch: any) => {
  return {
    dispatch,
    siteList: dispatch.general.siteList,
    loadingSiteList: dispatch.general.loadingSiteList,
  };
};
export default connect(mapStateToProps)(CategoriesList);