import { Component } from 'react';
import { call_api, parseJSON } from "../../../core/service";
import { toast } from 'react-toastify';
import { AppConstants } from '../../../core/constants';
import * as yup from 'yup'
import { fixNumbers } from '../../../core/utils';
import moment from 'moment-jalaali';
interface IProps {
  dispatch?:any,
  siteList?:any,
  loadingSiteList?:any,
}

interface IState {
  loading?: boolean,
  search: string,
  userSearch: string,
  usersInfoDropDOwn: Array<any>,
  selectedTime: string,
  recieveByUser: boolean,
  rows: Array<any>,
  platformDropdownOpen: boolean,
  platformList: Array<any>,
  selectedOrderStatusDropDown: string,
  selectedPaymentStatus: boolean,
  selectedPaymentMethod: string,
  productListData: any,
  generalDiscount: string,
  shippingAmount: string
  date: any,
  userInfo: string,
  recieverName: string,
  recieverPhone: string,
  hour: string,
  address: string,
  profileListData: any,
  userSelected: any,
  openNewUserModal: boolean,
  availebleProfileImage?: any,
  availeblePaymentImage:any,
  profileImage?: any,
  profileImageUpload?: any,
  paymentImage: any,
  paymentImageUpload: any,
  errorsList?: any,
  mobile: string,
  firstName: string,
  lastName: string,
  phone: string,
  whatsapp: string,
  loadingUpdateProfile: boolean,
  isCreating: boolean,
  userId: number,
  isActive?:boolean,
  toggleAddressPage:boolean,
  sendingStatus:string,
    usersAddressList:Array<any>,
    addressMessage:string,
    addressLoading:boolean,
    dreiverId:any,
    userLoading?:boolean
    addressError: boolean;
    currentItem: any,
    productModal:boolean,
    total:number
  rowCount:any,
  rowCountList:any,
  page: number,
  finalyDate: any,
  inPerson:boolean,
  step: number,
  paymentMethodStep:number,
  openEditAdress:boolean,
  editAddressData: any,
  openEditAddressAlert:boolean,
  addressData:any,
}

 class SubmitOrderController extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {

      loading: false,
      search: "",
      userSearch: "",
      usersInfoDropDOwn: [],
      selectedTime: "",
      recieveByUser: false,
      rows: [],
      platformDropdownOpen: false,
      userSelected: null,
      generalDiscount: "0",
      shippingAmount: "0",
      platformList: [
        { value: "blues", label: "Blues" },
        { value: "rock", label: "Rock" },
        { value: "jazz", label: "Jazz" },
        { value: "orchestra", label: "Orchestra" },
      ],

      selectedOrderStatusDropDown: "0",

      selectedPaymentStatus: false,
      selectedPaymentMethod: "1",
      productListData: [],
      date:moment(new Date().setDate(new Date().getDate() + 1)).format("jYYYY/jM/jD"),
      finalyDate:moment(new Date().setDate(new Date().getDate() + 1)).format("jYYYY/jM/jD"),
      userInfo: "",
      recieverName: "",
      recieverPhone: "",
      hour: "10-13",
      address: "",
      profileListData: [],
      profileImage: "",
      profileImageUpload: "",
      paymentImage:"",
      paymentImageUpload :"",
      openNewUserModal: false,
      errorsList: [],
      availebleProfileImage: "",
      availeblePaymentImage:"",
      mobile: "",
      firstName: "",
      lastName: "",
      phone: "",
      whatsapp: "",
      loadingUpdateProfile: false,
      isCreating: false,
      userId: 1,
      isActive:false,
      toggleAddressPage:false,
      sendingStatus:"send",
      usersAddressList:[],
      addressMessage:"",
      addressLoading:false,
      dreiverId:null,
    userLoading:false,
    addressError: false,
    currentItem: null,
    productModal:false,
    total:0,
      rowCount:100,
      rowCountList:[10, 25 , 50 , 100],
      page: 1,
      inPerson: false,
      step:0,
      paymentMethodStep:0,
      openEditAdress:false,
      editAddressData:null,
      openEditAddressAlert:false,
      addressData:{title:"", province:{id: 8, name:"تهران"}, city:{id:117, name:"تهران"}, region:{id:101, name:"", }, postal_code:"", address_description:"", full_address:""},

    }
  }
  driverTimeout: string | number | NodeJS.Timeout | undefined;
  driverSelected = (choice?:any) : any=> {
    this.setState({ dreiverId: choice , currentItem : null, rows:[], page:1, profileListData:[], userSelected:null })
    clearTimeout(this.driverTimeout);
    this.driverTimeout = setTimeout(() => {
      this.getProductList()
    }, 1000);
  }
  handleSetStep = (step: number) => {
    this.setState({ step: step })
  };
    searchTimeout: string | number | NodeJS.Timeout | undefined;
    handleSearch = (event: any) => {
      this.setState({ search: event.currentTarget.value, page:1 })
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.getProductList()
      }, 1000);
    };
  
  handleDeleteProfileImage = () => {
    this.setState({
      profileImage: "", profileImageUpload: ""
    })

  }
  async addAddress(address:any){
    const { userSelected, dreiverId } = this.state;
    this.setState({addressData: address})
    const addressInfo = yup.object().shape({
      address:yup.object().shape({
      city: yup.object().required("شهر الزامی است."),
      full_address:yup.string().required("آدرس دقیق الزامی است."),
      province:yup.object().required("استان الزامی است."),
      title:yup.string().required("عنوان آدرس الزامی است."),
      postal_code: yup.string().notRequired().max(10,"کدپستی باید شامل ۱۰ عدد باشد.").min(10,"کدپستی باید شامل ۱۰ عدد باشد.").nullable().transform((value) => !!value ? value : null),
    })
  })   
  try{
   await addressInfo.validate({address}, { abortEarly: false });
    call_api({
      address_api: `${AppConstants.base_url_api}seller/address-all`,
      method_api: "POST",
      body: JSON.stringify({
        user_id: userSelected?.id.toString(),
        label: address.title,
        db_name: dreiverId?.value?.name,
        province_id: address.province?.id.toString(),
        address: address.full_address,
        city_id : address.city?.id.toString(),
        desc: address.address_description,
        postal_code: address.postal_code,
        region: address.region?.name
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        this.setState({ errorsList: [] })
        if (status === 200) {
          toast.success(j?.message);
          this.getUsersAddress(userSelected?.id_select)
          this.setState({toggleAddressPage: false, addressData:{title:"", province:{id: 8, name:"تهران"},
           city:{id:117, name:"تهران"}, region:{id:101, name:"", }, postal_code:"", address_description:"", 
           full_address:""},
        })

        } else if (status === 401) {
          toast.error(j?.errors);
          localStorage.clear();
        } else {
          
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error("catch api",error);
      });
   }
   
    catch(err: any) 
        {
          this.setState({ errorsList: err.inner })
        }
}
  searchLocation = ()=>{

  }
    handleSendingStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({sendingStatus:(event.target as HTMLInputElement).value});
  }
  handleCloseNewUserModal = () => {
    this.setState({ openNewUserModal: false, errorsList:[] })
  }
  handleNewAddress = ()=>{
    this.setState({toggleAddressPage : true, errorsList:[] })  
    
  }
  handleCloseAddressModal = ()=>{
    this.setState({toggleAddressPage : false,
      addressData:{title:"", province:{id: 8, name:"تهران"}, city:{id:117, name:"تهران"}, 
      region:{id:101, name:"", }, postal_code:"", address_description:"", full_address:""},
    })

  }
  async handleProfileImageUpload(event: any) {
    let news_image_file = null;
    news_image_file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(news_image_file);
    reader.onloadend = () => {
      this.setState({ profileImage: reader.result });
    }
    if (news_image_file) {
      this.setState({ profileImageUpload: news_image_file });
    }
    this.setState({ availebleProfileImage: "" });

  }
  async handlePaymentImageUpload(event: any) {
    let news_image_file = null;
    news_image_file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(news_image_file);
    reader.onloadend = () => {
      this.setState({ paymentImage: reader.result });
    }
    if (news_image_file) {
      this.setState({ paymentImageUpload: news_image_file });
    }
    this.setState({ availeblePaymentImage: "" });

  }
  handleChange = (e: any) => {
    this.setState({selectedTime: e.target.value});


  }
  handleOpen = (e: any, data: any) => {
  }
  handleRecieveByUser = () => {
    this.setState({ recieveByUser: !this.state.recieveByUser })

  }

  async createNewUser() {
      const mobileRegExp = /^09\d{9}$/;
      const phoneRegExp = /^\d{5,12}$/;
      const justPersian = /^[\u0600-\u06FF\s]+$/gi;
      const { mobile, firstName , dreiverId , lastName, phone, whatsapp, } = this.state;
      const updateProfileSchema = yup.object({
      dreiverId: yup.object().required(" سایت الزامی می باشد "),
      firstName: yup.string().required(" نام الزامی می باشد ").max(32, 'نام بیشتر از 32 رقم نمی باشد').min(3, ' نام کمتر از 3 رقم نمی باشد').matches(justPersian,  ' لطفا از زبان فارسی استفاده کنید  '),
      lastName : yup.string().required(" نام خانوادگی الزامی می باشد ").max(32, 'نام خانوادگی بیشتر از 32 رقم نمی باشد').min(3, '  نام خانوادگی کمتر از 3 رقم نمی باشد').matches(justPersian,  ' لطفا از زبان فارسی استفاده کنید  '),
      mobile: yup.string().required(" شماره همراه الزامی می باشد  ").matches(mobileRegExp, 'فرمت وارد شده صحیح نمی باشد'),
      phone: yup.string().notRequired().matches(phoneRegExp, 'فرمت وارد شده صحیح نمی باشد').nullable().transform((value) => !!value ? value : null),
      whatsapp: yup.string().notRequired().matches(mobileRegExp,  'فرمت وارد شده صحیح نمی باشد ').nullable().transform((value) => !!value ? value : null),

      });
  
      try {
  
        await updateProfileSchema.validate({ firstName, lastName,dreiverId, mobile, whatsapp, }, { abortEarly: false });
        this.setState({ loadingUpdateProfile: true })
        const data = new FormData()
        data.append('name', firstName)
        data.append('family', lastName)
        data.append('mobile', mobile)
        phone && data.append('phone', phone)
        data.append('whatsapp', whatsapp)
        data.append('db_name', dreiverId?.value?.name)

        // if (availebleProfileImage === "") {
        //   data.append('avatar', profileImageUpload)
        // }
        call_api({
          address_api: `${AppConstants.base_url_api}seller/users-all`,
          method_api: "POST",
          body: data,
          file: true,
        })
          .then(parseJSON)
          .then(([status, j]) => {
            this.setState({ errorsList: [] })
            if (status === 200) {
              toast.success(j?.message);
              this.getProfilesList();
              this.userSelected(j?.data)
              this.getUsersAddress(j?.data?.id_select)
              this.setState({openNewUserModal:false, firstName:"",lastName:"",mobile:"",phone:"",whatsapp:"", loadingUpdateProfile: false , userLoading : false})
            } else if (status === 401) {
              this.setState({ loadingUpdateProfile: false })
              toast.error(j?.message);
              localStorage.clear();
            } else {
              console.warn(status, j);
              this.setState({ loadingUpdateProfile: false })
              toast.error(j?.message);
            }
          })
          .catch((error) => {
            console.error(error);
          });
  
      } catch (err: any) {
        this.setState({ loadingUpdateProfile: false, errorsList: err.inner  })
      }
    
  }

  addRowTable = () => {
    const data = {
      id_select: "",
      product_name: "",
      count: "",
      price: "",
      discount: "",
      finalPrice: ""

    };
    this.setState({ rows: [...this.state.rows, data] });
  };
  tableRowRemove = (index: number) => {
    const dataRow = [...this.state.rows];
    dataRow.splice(index, 1);
    this.setState({ rows: dataRow});
  };
  onValUpdate = (i: number, event: any) => {

    const { name, value } = event.target;
    const data = [...this.state.rows];
    var newValue = value.replace(
        /,/g,
        ""
      );
      const re = /^[0-9\b]+$/;
      if (newValue === "" || re.test(newValue)) {
        data[i].error = "" 
        let countItem:number =  Number(data[i]?.count);
  
        if(name === "discount" ){
          data[i][name] = newValue;
          if(Number(newValue) < Number(data[i]["price"]))
          {
            data[i].error = ""
          }
          else{

            data[i].error += "تخفیف نمی‌تواند بزرگ‌تر یا مساوی با قیمت باشد"
          }
        } 
        else if(name === "price"){
          
            if(Number(newValue) > Number(data[i]["discount"] ))
            {
              data[i][name] = newValue;
              data[i].error = ""
            }
            else{
              data[i].error = ""  

              if(Number(data[i]?.discount) < 99)
              {              
                data[i][name] = newValue;
              }
              else
            { 
              data[i][name] = newValue;
              data[i].error += "قیمت نمی‌تواند کوچک‌تر از تخفیف باشد"
                
            }
            }
          }
        else if(name === "count"){
          if(Number(newValue) > 0){

            countItem = Number(newValue) 
            data[i][name] = newValue;

          }else{
            countItem = 1
            data[i][name] = 1
          }
        }
        if (Number(data[i]?.discount) > 99) {
            data[i]["finalPrice"] = (Number(data[i]?.price) -  Number(data[i]?.discount)) * countItem ; 
          } 
        else {
            data[i]["finalPrice"] = (Number(data[i]?.price) * countItem) - Number(Number(Number(data[i]?.price * Number(data[i]?.discount)) / 100) * countItem)  ;
            
          }
      }
    this.setState({ rows: data });

  };
  userSelected = (choice: any) => {
    this.setState({ userSelected: choice, recieverName: choice?.profile?.full_name || "",recieverPhone: choice?.mobile || "",
    userId: choice?.id_select || "", addressError:false,  usersAddressList:[], address:""})
    this.getUsersAddress(choice?.id_select)
  }
  searchUserTimeout: string | number | NodeJS.Timeout | undefined;
  handleSearchUser = (choice: string) => { 
    clearTimeout(this.searchUserTimeout);
    if(choice.length > 2)
    this.searchUserTimeout = setTimeout(() => {
      this.setState({addressError:false, usersAddressList:[], address:""})
      this.getProfilesList(choice)
    }, 1000);
  }
  handleAdd = (data: any) => {
    const {dreiverId} = this.state;
    
    if(dreiverId === null)
    {
      toast.error("لطفا یک سایت را انتخاب کنید.")
    }
    else{
      const { rows } = this.state;
      var found = false;
      const updateConversationInfo = [...this.state.rows]
      for (var i = 0; i < rows.length; i++) {
        if (rows[i].product_id === data?.id) {
          updateConversationInfo.splice(i,1)
        this.setState({ rows: updateConversationInfo });
          // toast.error("این محصول در لیست محصولات انتخابی شما وجود دارد ")
          found = true;
          break;
        }
      }
      if (!found) {
        const obj = {
          id_select: data.id_select,
          product_id: data.id,
          product_name: data.product_name,
          count: data.count || 1,
          price: data.price_by_discount.price,
          discount: data.price_by_discount.discount,
          finalPrice: data.price_by_discount.finalPrice,
          error:""
        }
        updateConversationInfo.push(obj);
        this.setState({ rows: updateConversationInfo });
      }
    }
  }
  handleAddress = (event: React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({address:(event.target as HTMLInputElement).value});

  }
  handleDate=(value:any)=>{
    this.setState({date: value, finalyDate : fixNumbers(value?.format("YYYY/MM/DD"))})
  }
  async getProductList() {
    const {dreiverId , rowCount, page} = this.state;
    this.setState({ loading: true, productListData:[] })
    let parameters = {
      search: this.state.search ? this.state.search : null,
      page: page ? page.toString() : null,
      db_name: dreiverId && Object.keys(dreiverId).length > 0 ? dreiverId.value?.name : null,
      paginate: rowCount
    }
    let url = new URL(`${AppConstants.base_url_api}seller/products`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    this.setState({ loading: true })
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}seller/products${url.search}` : `${AppConstants.base_url_api}seller/products`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          if(j.data){
            this.setState({
              productListData: j.data?.data, loading: false , total: j.data?.total
            })
          }
        } else if (status === 401) {
          this.setState({ loading: false })
          localStorage.clear();
        } else {
          this.setState({ loading: false })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
   getUsersAddress(userId?:number) {
    this.setState({ addressLoading: true })
    call_api({
      address_api: `${AppConstants.base_url_api}seller/address-user-all/${userId}` ,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          if(j?.data)
          {
            this.setState({ usersAddressList: j.data, addressMessage:j.message, addressLoading:false, address:j.data[j?.data.length - 1]?.id})
          }
          else{
            this.setState({addressMessage:j.message, usersAddressList:[]})
          }
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  async order() {
    let errorsLists = [];
    const {  rows } = this.state;
    for(var i = 0; i< rows.length; i++){
          if(rows[i].error && rows[i].error !== "")
          {
            errorsLists.push(rows[i].error)
          }
    }
    if(errorsLists.length === 0){
    if(this.state.rows.length > 0){
      this.setState({ isCreating: true })
      const mobileRegExp = /^09\d{9}$/;
      const { userSelected,
        recieverName,
        recieverPhone,
        date,
        step,
        address,
        shippingAmount,
        generalDiscount,
        finalyDate,
        selectedPaymentStatus,
        selectedOrderStatusDropDown,
        selectedPaymentMethod, rows , sendingStatus, paymentImageUpload,dreiverId } = this.state;  
        const updateProfileSchema = yup.object({
        userSelected: yup.object().required("انتخاب کاربر الزامی می باشد "),
        address: yup.string() 
        .when('sendingStatus', {
          is: (val:any) => val === "send",
          then: (schema) => 
            schema.required("انتخاب آدرس الزامی است."),
        }),
        paymentImageUpload: yup.string()
        .nullable()
        .notRequired()
        .when('selectedPaymentStatus', {
          is: (val:any) => val === true,
          then: (schema) => 
            schema.required("تصویر فیش واریزی را آپلود کنید یا وضعیت پرداخت را به حالت در انتظار پرداخت تغییر دهید"),
        }),
        recieverPhone: yup.string().required(" شماره همراه الزامی می باشد  ").matches(mobileRegExp, 'فرمت وارد شده صحیح نمی باشد'),
        recieverName: yup.string().required(" نام و نام خانوادگی الزامی می باشد ").max(255, 'کد ورود بیشتر از 255 رقم نمی باشد'),
      }); 
      try {
        await updateProfileSchema.validate({ userSelected, address, recieverName, paymentImageUpload, recieverPhone, date, shippingAmount, generalDiscount, selectedPaymentStatus, selectedPaymentMethod, selectedOrderStatusDropDown }, { abortEarly: false });
        this.setState({ loadingUpdateProfile: true })
        const data = new FormData()
        data.append('user_id', userSelected.id)
        data.append('db_name', dreiverId.value?.name)
        data.append('receiver_name', recieverName)
        data.append('receiver_phone', recieverPhone)
        data.append('send_date',moment(finalyDate, 'jYYYY/jMM/jDD').format('YYYY-MM-DD'))
        data.append('send_period', step === 0 ? "10-13" : step === 1 ? "13-16" : "16-19")
        data.append('shipping_price', shippingAmount)
        data.append('payment_status', selectedPaymentStatus ? "1" : "0")
        data.append('payment_type', selectedPaymentMethod)
        data.append('order_status', selectedOrderStatusDropDown)
        data.append('shipping_type', "4")
        data.append('extra_discount', generalDiscount)
        data.append('products', JSON.stringify(rows))
        data.append('description', "")
        data.append('address_id', sendingStatus === "send"? address : "0" )
        data.append('self_receive', sendingStatus === "send"? "0" : "1" )
        if(selectedPaymentStatus === true) {
          data.append('avatar', paymentImageUpload)
        } 
        if((sendingStatus === "send" && address) || sendingStatus !== "send"){
          call_api({
            address_api: `${AppConstants.base_url_api}seller/orders`,
            method_api: "POST",
            body: data,
            file: true,
          })
            .then(parseJSON)
            .then(([status, j]) => {
              this.setState({ errorsList: [] })
              if (status === 200) {
                toast.success(j?.message);
                this.setState({ isCreating: false, address:"",recieverName:"", recieverPhone:"", rows:[],
                 paymentImageUpload:"", addressError:false, selectedPaymentStatus:false, selectedPaymentMethod:'1'})
                 window.location.href = "/orders";
              } else if (status === 401) {
                this.setState({ isCreating: false })
                toast.error(j?.errors);
                localStorage.clear();
              } else {
                console.warn(status, j);
                this.setState({ isCreating: false })
                toast.error(j?.errors);
              }
            })
            .catch((error) => {
              console.error(error);
              toast.error(error?.errors?.message)
            });
          }
          else{
            this.setState({addressError : true, isCreating: false})
          }
      } catch (err: any) {
        this.setState({ isCreating: false, errorsList: err.inner  })
        
      }
    }else{
      toast.error("حداقل یک محصول را انتخاب کنید");
    }
  }
  else {
    toast.error("لطفاً به مقادیر قیمت یا تخفیف دقت نمایید!")
  }
  };
  handleTakingOrder = (event: any) => {
    this.setState({ inPerson: event.target.checked });
    if(event.target.checked){
      this.setState({sendingStatus:"inPerson", addressError: false})
    }
    else {
      this.setState({sendingStatus:"send"})
    }
  };
  async getProfilesList(search?: string) {
    this.setState({ userLoading: true })
    let parameters = {
      search: search ? search.toString(): null,
      db_name:this.state.dreiverId?.value?.name,
    }
    let url = new URL(`${AppConstants.base_url_api}seller/users-all-no-paginate`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}seller/users-all-no-paginate${url.search}` : `${AppConstants.base_url_api}seller/users-all-no-paginate`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          if(j.data){
          this.setState({profileListData: j?.data, userLoading: false})
          }
        } else if (status === 401) {
          this.setState({ userLoading: false })
          localStorage.clear();
        } else {
          this.setState({ userLoading: false })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({ userLoading: false })
      });
  };
  openProductModal = () => {
    this.setState({ productModal: true })
  }
  handleCloseProductModal = () => {
    this.setState({ productModal: false })
  }
  paginationSelectTimeOut: string | number | NodeJS.Timeout | undefined; 
  handlePaginationSelect=(event:any)=>{
    this.setState({
      rowCount: event.currentTarget.value,
      page:1
    })
    clearTimeout(this.paginationSelectTimeOut);
    this.paginationSelectTimeOut = setTimeout(() => {
      this.getProductList()
    }, 800);
    
  }
  paginationTimeOut: string | number | NodeJS.Timeout | undefined; 
  handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ page: value })
    clearTimeout(this.paginationTimeOut);
    this.paginationTimeOut = setTimeout(() => {
      this.getProductList()
    }, 800);
  }
  handleOpenEditAddressModal = (data:any) =>{
    this.setState({openEditAdress: true, editAddressData: data});
      let parameters = {
       db_name: data?.db_name,
      };
      let url = new URL(
        `${AppConstants.base_url_api}seller/address-all/${data.id}`
      );
  
      for (const [key, value] of Object.entries(parameters)) {
        if (value) {
          url.searchParams.append(key, value);
        }
      }
      call_api({
        address_api: url.search
          ? `${AppConstants.base_url_api}seller/address-all/${data.id}${url.search}`
          : `${AppConstants.base_url_api}seller/address-all/${data.id}`,
        method_api: "GET",
      })      
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({ addressData:{title:j?.data?.label, province:{id: j?.data?.province_id?.id, name: j?.data?.province_id?.name}, city:{id:j?.data?.city_id?.id, name:j?.data?.city_id?.name}, 
          region:{id:101, name:j?.data?.region, }, postal_code:j?.data?.postal_code, address_description:j?.data?.desc, full_address:j?.data?.address},
        })
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  handleCloseAddressEditModal = () => {
    this.setState({ openEditAdress: false })
  }
  handleEdit = (data:any)=>{
    this.setState({openEditAddressAlert: true, addressData: data})
  }
  async handleConfirmEdition() {
    const { editAddressData, addressData, userSelected } = this.state;
    const addressInfo = yup.object().shape({
      addressData:yup.object().shape({
      city: yup.object().required("شهر الزامی است."),
      full_address:yup.string().required("آدرس دقیق الزامی است."),
      province:yup.object().required("استان الزامی است."),
      title:yup.string().required("عنوان آدرس الزامی است."),
      postal_code: yup.string().notRequired().max(10,"کدپستی باید شامل ۱۰ عدد باشد.").min(10,"کدپستی باید شامل ۱۰ عدد باشد.").nullable().transform((value) => !!value ? value : null),
    })
  })   
  try{
   await addressInfo.validate({addressData}, { abortEarly: false });
    call_api({
      address_api: `${AppConstants.base_url_api}seller/address-all/${editAddressData?.id}`,
      method_api: "PATCH",
      body: JSON.stringify({
        user_id: userSelected?.id.toString(),
        label: addressData.title,
        db_name: editAddressData?.db_name,
        province_id: addressData.province?.id.toString(),
        address: addressData.full_address,
        city_id : addressData.city?.id.toString(),
        desc: addressData.address_description,
        postal_code: addressData.postal_code,
        region: addressData.region?.name
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        this.setState({ errorsList: [] })
        if (status === 200) {
          toast.success(j?.message);
          this.setState({openEditAdress: false, openEditAddressAlert: false,
            addressData:{title:"", province:{id: 8, name:"تهران"}, city:{id:117, name:"تهران"}, region:{id:101, name:"", }, postal_code:"", address_description:"", full_address:""},
          });
            this.getUsersAddress(userSelected?.id_select)

        } else if (status === 401) {
          toast.error(j?.errors);
          localStorage.clear();
        } else {
          
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error("catch api",error);
      });
   }
    catch(err: any) 
        { this.setState({ errorsList: err.inner })}
  }
}


export default SubmitOrderController;

