import { Component, createRef } from 'react';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { AppConstants } from '../../../core/constants';
import { call_api, parseJSON } from "../../../core/service";
import { toast } from 'react-toastify';
import moment from 'moment-jalaali';
import { fixNumbers } from '../../../core/utils';

interface IProps {
  dispatch?:any,
  siteList?:any,
  loadingSiteList?:any,
}
interface IState {
  loading?: boolean,
  search: string,
  openOrderModal: boolean,
  openLinkModal: boolean,
  selectedOrderStatusDropDown: string,
  orderListData: any,
  currentItem: any,
  page: number,
  selectedRow: any,
  toggleConfirmModal: boolean,
  link?: string,
  smsLoading: boolean,
  dreiverId: any;
  rowCount:any,
  rowCountList:any,
  paginate: number,
  pageStatus?:boolean,
  showFilters:boolean,
  dateValue:any,
  total:number,
  orderTab:number | null,
  paymentStatus:number | null,
  active:boolean,
  rowIndex:number,
  allOption:any,
  openBillModal: boolean,
  billData: any,
  toggleRejectModal:boolean,
  toggleBillConfirmModal:boolean,
  billModalLoading:boolean,
  orderModalLoading:boolean,

  
}
 class OrdersController extends Component<IProps, IState> {
  tableInstanceRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    this.tableInstanceRef = createRef()
    this.state =  {
    loading: false,
    search: "",
    openOrderModal: false,
    openLinkModal: false,
    selectedOrderStatusDropDown: "",
    orderListData: [],
    currentItem: null,
    page: 1,
    selectedRow: [],
    toggleConfirmModal: false,
    link: "",
    smsLoading: false,
      dreiverId: [],
      rowCount:100,
      rowCountList:[10, 25 , 50 , 100],
      paginate: 0,
      pageStatus:false,
      showFilters:false,
      dateValue: [],
      total:0,
      orderTab:null,
      paymentStatus:null,
      active:false, 
      rowIndex:0,
      allOption:{id:0 ,value:{
        persian_name: "همه",
        name: "all",
      }},
      openBillModal: false,
      billData:null,
      toggleRejectModal:false,
      toggleBillConfirmModal:false,
      billModalLoading: false,
      orderModalLoading: false,

  }
}

  handleChangePageStatus = (event: any) => {
    this.setState({ pageStatus: event.target.checked });
  };
  paginationTimeOut: string | number | NodeJS.Timeout | undefined; 
  handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ page: value })
    clearTimeout(this.paginationTimeOut);
      this.paginationTimeOut = setTimeout(() => {
    this.getOrdersList()
  }, 800);
  };    
  dateTimeOut: string | number | NodeJS.Timeout | undefined; 
        handleChangeDate=(value:any)=>{
          
          this.setState({
            dateValue: value,
          })
          if(value?.length === 2){
            clearTimeout(this.dateTimeOut);
            this.dateTimeOut = setTimeout(() => {
              this.getOrdersList()
            }, 800);
          }
        }
  clearDateTimeOut: string | number | NodeJS.Timeout | undefined; 
  handleClear=()=>{
    this.setState({ dateValue: [] })
      clearTimeout(this.clearDateTimeOut);
      this.clearDateTimeOut = setTimeout(() => {
        this.getOrdersList()
      }, 800);
  }
  async handleFinalConfirm() {
    const {currentItem , selectedOrderStatusDropDown, rowIndex } = this.state;
    const data = [...this.state.orderListData];
    data[rowIndex].order_status.name = selectedOrderStatusDropDown;
    call_api({
      address_api: `${AppConstants.base_url_api}seller/orders/${currentItem.id}`,
      method_api: "PUT",
      body: JSON.stringify({
        order_status: selectedOrderStatusDropDown, 
        db_name: currentItem.db_name,
        order_id: currentItem?.id
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({ toggleConfirmModal : false, orderListData: data})
          toast.success(j.message)
        } else if (status === 401) {
          this.setState({ toggleConfirmModal : false })
          localStorage.clear();
        } else {
          toast.error(j?.errors);
          this.setState({ toggleConfirmModal : false })
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({ toggleConfirmModal : false })
      });
  };
  handleCopy = () => {
    navigator.clipboard.writeText(this.state.link || "")
    toast.success("کپی شد")
  }
  driverTimeout: string | number | NodeJS.Timeout | undefined;
  driverSelected = (choice: any) => {
    this.setState({ dreiverId: choice, page:1 })
   
    clearTimeout(this.driverTimeout);
    this.driverTimeout = setTimeout(() => {
      this.getOrdersList()
    }, 1000);
  }
  filterTimeout: string | number | NodeJS.Timeout | undefined;
  handleSearch = (event: string) => {
    this.setState({ search: event })
    clearTimeout(this.filterTimeout);
    this.filterTimeout = setTimeout(() => {
      this.getOrdersList()
    }, 1000);
  };
  handleOrderModal = () => {
    this.setState({ openOrderModal: true })
  }
  handleCloseOrderModal = () => {
    this.setState({ openOrderModal: false, currentItem: null })
  }
  handleLinkModal = () => {
    this.setState({ openLinkModal: true })
  }
  handleCloseLinkModal = () => {
    this.setState({ openLinkModal: false })
  }
  async handleOpenModal(row: any) {
    this.setState({ currentItem: row.item })
    call_api({
      address_api: `${AppConstants.base_url_api}seller/make-disposable`,
      method_api: "POST",
      body: JSON.stringify({
        order_id: row.item?.id,
        db_name: row.item?.db_name,
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({ openLinkModal: true, link: j?.data?.url })
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  async handleSendSms() {
    this.setState({ smsLoading: true })
    call_api({
      address_api: `${AppConstants.base_url_api}seller/send-disposable-sms`,
      method_api: "POST",
      body: JSON.stringify({
        order_id: this.state.currentItem.id,
        db_name: this.state.currentItem.db_name,
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({ openLinkModal: false, smsLoading: false })
          toast.success(" ارسال شد ")
        } else if (status === 401) {
          this.setState({ openLinkModal: false, smsLoading: false })
          localStorage.clear();
        } else {
          toast.error(j?.errors);
          this.setState({ openLinkModal: false, smsLoading: false })
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({ openLinkModal: false, smsLoading: false })
      });
  };
  async handleDownloadPDF(item:any) {
    call_api({
      address_api:`${AppConstants.base_url_api}seller/order/pdf/${item.id}?db_name=${item.db_name}`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          window.open(j.link.OrderLink)
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  async getOrdersList() {
    const { search , dreiverId,rowCount,dateValue, orderTab, paymentStatus, page} = this.state;
    
    this.setState({ loading: true, orderListData:[]})
    let parameters = {
      search: search ? search : null,
      db_name: dreiverId?.value?.name === "all" ? "" : dreiverId?.value?.name ,
      page: page ? page.toString() : null,
      paginate: rowCount,
      payment_status:paymentStatus,
      order_status: orderTab?.toString(),
      start_at:dateValue.length === 2 ? moment(fixNumbers(dateValue[0]?.format("YYYY-MM-DD")), 'jYYYY/jMM/jDD').format('YYYY-MM-DD') : null,
      end_at:dateValue.length === 2 ? moment(fixNumbers(dateValue[1]?.format("YYYY-MM-DD")), 'jYYYY/jMM/jDD').format('YYYY-MM-DD')  : null 

    }
    let url = new URL(`${AppConstants.base_url_api}seller/orders`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}seller/orders${url.search}` : `${AppConstants.base_url_api}seller/orders`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          if(j?.data)
          {
            this.setState({ orderListData: j.data.data, total: j.data?.total, loading: false  }) 
          }
        } else if (status === 401) {
          this.setState({ loading: false })
          localStorage.clear();
        } else {
          this.setState({ loading: false })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  paginationSelectTimeOut: string | number | NodeJS.Timeout | undefined; 
  handlePaginationSelect=(event:any)=>{
    this.setState({
      rowCount: event.currentTarget.value,
      page:1
    })
    clearTimeout(this.paginationSelectTimeOut);
    this.paginationSelectTimeOut = setTimeout(() => {
      this.getOrdersList()
    }, 800);
    
  }
  handleChangeModalOrderStatus = (event:any)=>{
    const {currentItem, rowIndex} = this.state;
    this.setState({
      toggleConfirmModal: true
    })
    this.handleChangeDropDown(event, currentItem, rowIndex, true)
  }
  dropDownTimeout: string | number | NodeJS.Timeout | undefined;

  handleChangeDropDown =  (event: any, item: any, index: number, openModal?:boolean)=>{
    this.setState({ selectedOrderStatusDropDown: event.target.value, currentItem:item, rowIndex: index})
    if(!openModal){
      
      this.setState({
        toggleConfirmModal: true
      })
    }
  }
  orderStatusTabsTimeOut: string | number | NodeJS.Timeout | undefined;
  handleOrderStatus =  (status: number)=>{
    this.setState({orderTab:status, paymentStatus:null, page: 1})
    clearTimeout(this.orderStatusTabsTimeOut);
      this.orderStatusTabsTimeOut = setTimeout(() => {

        this.getOrdersList()
      }, 1000);
  }
  orderTabsTimeOut: string | number | NodeJS.Timeout | undefined;
  allOrder= ()=>{
    this.setState({orderTab:null, paymentStatus:null, page:1})
    clearTimeout(this.orderTabsTimeOut);
      this.orderTabsTimeOut = setTimeout(() => {
        this.getOrdersList()
      }, 1000);
  }
  async handleOpenOrderModal(row: any) {
    this.setState({ openOrderModal: true, rowIndex: row?.index, orderModalLoading: true })
      call_api({
        address_api: `${AppConstants.base_url_api}seller/orders/${row?.original.item?.id_select}?has_relation=0`,
        method_api: "GET"
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
           this.setState({currentItem: j?.data, selectedOrderStatusDropDown:j?.data?.order_status?.name, orderModalLoading: false})
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });

  };
  async handleLinkClick(row: any) {
    this.setState({ openLinkModal: true, selectedRow: row });
  }
  handleCloseBill = () => {
    this.setState({ openBillModal: false, billData: null })
  }
  async handleConfirm(item:any){
    call_api({
     address_api: `${AppConstants.base_url_api}seller/payments/verify-card-to-card`,
     method_api: "POST",
     body: JSON.stringify({
       db_name: item.db_name,
       status: 1,
       payment_id: item.id
     })
   })
     .then(parseJSON)
     .then(([status, j]) => {
       if (status === 200) {
         toast.success(" با موفقیت انجام شد.");
         this.getOrdersList()
         this.setState({ toggleBillConfirmModal: false , openBillModal: false, billData: null})
       } else if (status === 401) {
         toast.error(j?.message);
         localStorage.clear();
         this.setState({ toggleBillConfirmModal: false , openBillModal: false})
       } else {
         toast.error(j?.message);
         this.setState({ toggleBillConfirmModal: false , openBillModal: false})
       }
     })
     .catch((error) => {
       console.error(error);
       this.setState({ toggleBillConfirmModal: false , openBillModal: false})
     });
}
handleReject=(item:any)=>{
 call_api({
  address_api: `${AppConstants.base_url_api}seller/payments/verify-card-to-card`,
  method_api: "POST",
  body: JSON.stringify({
    db_name: item.db_name,
    status: 0,
    payment_id: item.id
  })
})
  .then(parseJSON)
  .then(([status, j]) => {
    if (status === 200) {
      toast.success(" با موفقیت انجام شد.");
      this.getOrdersList()
      this.setState({ toggleRejectModal: false, openBillModal:false, billData: null})
    } else if (status === 401) {
      toast.error(j?.message);
      localStorage.clear();
      this.setState({ toggleRejectModal: false, openBillModal:false})
    } else {
      toast.error(j?.message);
      this.setState({ toggleRejectModal: false, openBillModal:false})
    }
  })
  .catch((error) => {
    console.error(error);
    this.setState({ toggleRejectModal: false, openBillModal:false})
   });
}

getBillImage = (item:any) =>{
  this.setState({openBillModal: true, billModalLoading: true})
  call_api({
    address_api: `${AppConstants.base_url_api}seller/payment`,
    method_api: "POST",
    body: JSON.stringify({
      db_name: item?.db_name,
      order_id: item.id
    })
  })
    .then(parseJSON)
    .then(([status, j]) => {
      if (status === 200) {
        this.setState({billData:j?.data[0], billModalLoading: false})
      } else if (status === 401) {

      } else {
        toast.error(j?.message);

      }
    })
    .catch((error) => {
      console.error(error);
     });
}
}
export default OrdersController;