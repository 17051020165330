import { Component, createRef } from "react";
import { toast } from "react-toastify";
import { call_api, parseJSON } from "../../../core/service";
import { AppConstants } from "../../../core/constants";
import * as yup from 'yup';
import { fetchSiteList, fetchUserData } from "../../../Redux/services/generalService";
import { FETCH_USER_DATA } from "../../../Redux/type";
import { withRouter, WithRouterProps } from "../../pages/commen/redirectConfirm/withRouter";

interface IProps {
  dispatch?:any,
  siteList?:any,
  loadingSiteList?:any,
  userData?:any,
  location?:any,
  match:any,
 }

interface IState {
    loading: boolean,
}

 class RedirectConfirmController extends Component<IProps, IState> {

  constructor(props: any) {
    super(props);
    this.state = {
        loading: true
    };
  }
  fetchUserDetails = ()=>{
    call_api({
      address_api: `${AppConstants.base_url_api}seller/users/${window.localStorage.getItem('userId')}`,
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {  
          this.props.dispatch({
            type: FETCH_USER_DATA,
            payload: j?.data,});
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  fetchUserDataTimeOut: string | number | NodeJS.Timeout | undefined;     
  confirmAPI = () => {
    const { match, location } = this.props;
    if(window.localStorage.getItem('token')){
      window.location.href = "/home";

      toast.success("شما با موفقیت وارد شدید!")

    }
    else {
      fetch(`${AppConstants.base_url_api}login-api`, {
        method: 'POST',
        body: JSON.stringify({
          mobile: location?.params?.search.split("?mobile=")[1].substring(0,11),
          rtoken: location?.params?.search.split("rtoken=")[1],
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
         .then((response) => response.json())
         .then(j => {
          if (j?.status === 200) {
            this.setState({loading: false})
            window.localStorage.setItem('token', j.data.token)
            window.localStorage.setItem('userId', j.data?.user?.id)
            this.props.dispatch({
              type: FETCH_USER_DATA,
              payload: j?.data?.user});
            clearTimeout(this.fetchUserDataTimeOut);
            this.fetchUserDataTimeOut = setTimeout(() => {
              window.location.href = `/home`;
              toast.success(j?.message)
              }, 800);
      } else if (j?.status === 401) {
        toast.error(j?.message);
        localStorage.clear();
      } else {
        console.warn(j?.status, j);
        toast.error(j?.message);
      }
         })
         .catch((err) => {
            console.log(err.message);
         });
      }
  };

}
export default RedirectConfirmController;
