import React, { memo } from 'react';

interface Props {
  title?: string;
  onClick?: any;
  isActive?: boolean;
  myStyle?:string
}
const RadioSquare = ({ title, onClick, isActive, myStyle }: Props) => {

  return (
    <div className={`check-box ${myStyle}`}>
      <div className="square-radio">
      {/* <input class="form-check-input" type="checkbox" value={isActive} id="flexCheckDefault" onChange={()=>onClick()}/> */}
        <div className={isActive === true ? "square-radio-active" : "square-radio"} onClick={()=>onClick()}></div>
      </div>
      <div className="smalText ">{title}</div>
    </div>
  );
};

export default memo(RadioSquare);
