import {UpdateUserController} from "../../../controllers";
import { BsPeople } from "react-icons/bs";
import { CustomButton, LoadingSpinner, LableIcon, Header , InputIcon } from "../../../components";
import { BsInstagram, BsWhatsapp } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Helmet, HelmetProvider } from 'react-helmet-async';
class UpdateUser extends UpdateUserController {
  componentDidMount() {
      this.getUserDetails();
      this.getRolesList();
    window.scrollTo(0, 0);
  }
  render() {
    const {emailCheck, banUser, password, errorsList, userRole, gender, instagram,loading,firstName,lastName,mobile,email,phone,whatsapp,telegram,loadingUpdateProfile} = this.state;
    return (
      <HelmetProvider>
      <div className="user-update">
        <Helmet>
                <title>پروفایل کاربری   </title>
            </Helmet>
          <Header title="پروفایل کاربری "/>
        <div className="">
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="user-update-card">
                <>
                  <div className="row">
                  <div className="row">
                    <div className="col-md-4 col-sm-12 pe-3">
                      <InputIcon
                        value={mobile}
                        title={"شماره همراه "}
                        inBox={"شماره همراه"}
                        type={"text"}
                        disabled={true}
                        onChange={(event) =>
                          this.setState({
                            mobile: event.currentTarget.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4 col-sm-12 pe-3">
                      <InputIcon
                        value={firstName}
                        title={"نام "}
                        inBox={"نام  "}
                        type={"text"}
                        name="firstName"
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "firstName")[0]?.message}
                        onChange={(event) =>
                          this.setState({
                            firstName: event.currentTarget.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4 col-sm-12 pe-3">
                      <InputIcon
                        value={lastName}
                        title={"نام خانوادگی  "}
                        inBox={"نام خانوادگی  "}
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "lastName")[0]?.message}
                        type={"text"}
                        name="lastName"
                        onChange={(event) =>
                          this.setState({
                            lastName: event.currentTarget.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4 col-sm-12 pe-3">
                    <InputIcon
                          value={userRole}
                        title={"نقش کاربر "}
                        inBox={" نقش کاربر"}
                        type={"text"}
                        disabled={true}
                      />
                    </div>
                    <div className="col-md-4 col-sm-12 pe-3">
                      <InputIcon
                        value={password}
                        title={"رمز عبور "}
                        inBox={"رمز عبور  "}
                        type={"password"}
                        name="password"
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "password")[0]?.message}
                        onChange={(event) =>
                          this.setState({
                            password: event.currentTarget.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4 col-sm-12 pe-3">
                      <InputIcon
                        value={email}
                        title={"ایمیل"}
                        inBox={"ایمیل"}
                        type={"text"}
                        name="email"
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "email")[0]?.message}
                        onChange={(event) =>
                          this.setState({ email: event.currentTarget.value })
                        }
                      />
                    </div>
                    <div className="col-md-4 col-sm-12 pe-3">
                      <InputIcon
                        value={phone}
                        title={"تلفن "}
                        inBox={"تلفن "}
                        type={"text"}
                        name="phone"
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "phone")[0]?.message}
                        onChange={(event) =>
                          this.setState({ phone: event.currentTarget.value })
                        }
                      />
                    </div>
                    <div className="col-md-4 col-sm-12">
                    <FormControlLabel sx={{direction:"ltr"}} control={<Switch
                        checked={emailCheck}
                        onChange={this.handleEmailChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                     />} 
                     label="احراز ایمیل" />
                    </div>
                    <div className="col-md-4 col-sm-12">
                    <FormControlLabel sx={{direction:"ltr"}} control={<Switch
                        checked={banUser}
                        onChange={this.handleBanUserChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                     />} 
                     label="مسدودکردن کاربر" />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <FormControlLabel sx={{direction:"ltr"}} control={<Switch
                        checked={gender}
                        onChange={(event:any)=> this.setState({ gender: event.target.checked }) }
                        inputProps={{ 'aria-label': 'controlled' }}
                     />} 
                     label="زن" />
                    </div>
                  </div>                    
                  </div>
                  <LableIcon title="سایر اطلاعات" icon={<BsPeople />}/>
                  <hr className="profile-hr" />
                  <div className="row">
                  <div className="col-md-4 col-sm-12 pe-3">
                      <InputIcon
                        value={telegram}
                        title={"تلگرام "}
                        name="telegram"
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "telegram")[0]?.message}
                        inBox={<FaTelegramPlane/>}
                        type={"text"}
                        onChange={(event) =>
                          this.setState({
                            telegram: event.currentTarget.value,
                          })
                        }
                      />
                    </div>  
                    <div className="col-md-4 col-sm-12 pe-3">
                      <InputIcon
                        value={whatsapp}
                        title={"واتس اپ "}
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "whatsapp")[0]?.message}
                        name="whatsapp"
                        inBox={<BsWhatsapp/>}
                        type={"text"}
                        onChange={(event) =>
                          this.setState({
                            whatsapp: event.currentTarget.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4 col-sm-12 pe-3">
                      <InputIcon
                        value={instagram}
                        title={"اینستاگرام   "}
                        inBox={<BsInstagram/>}
                        type={"text"}
                        errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "instagram")[0]?.message}
                        name="instagram"
                        onChange={(event) =>
                          this.setState({
                            instagram: event.currentTarget.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </>
                <div className="btn-container">
                  <CustomButton
                    myStyle="submit-btn"
                    loading={loadingUpdateProfile}
                    onClick={() => this.updatetUser()}
                    title="بروز رسانی اطلاعات"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      </HelmetProvider>
    );
  }
}

export default UpdateUser;
