import { Component } from 'react';
import { AppConstants } from '../../../core/constants';
import { call_api, parseJSON } from '../../../core/service';
import { toast } from 'react-toastify';

interface IProps {
  dispatch?: any,
  siteList?: any,
  loadingSiteList?: any,
  userData?: any
}
interface IState {
  loading?: boolean,
  productsCount: string;
  bestSellingProductsCount: string;
  categoryProductsCount: string;
  popularProductsCount: string;
  latestProductsCount: string;
  errorsList: any;
  brandProductsCount: string
  countList: any
  data:any;
}

class ContentSettingsController extends Component<IProps, IState> {
  state: IState = {
    loading: false,
    productsCount: "",
    bestSellingProductsCount: "",
    categoryProductsCount: "",
    popularProductsCount: "",
    latestProductsCount: "",
    brandProductsCount: "",
    errorsList: [],
    countList: Array.from(Array(50).keys()).map(function(val: number, index: any){
      return { name: `${val + 1} عدد`, value: (val + 1).toString() };
  }),
  data:[]
  
  };
  async getDate() {
    

    let parameters = {
      db_name: window.location.pathname.split("/")[3],
    }
    let url = new URL(`${AppConstants.base_url_api}admin/setting/content`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}admin/setting/content${url.search}` : `${AppConstants.base_url_api}admin/setting/content`,
      method_api: "GET",
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
            data:j.data[0],
            productsCount: j.data[0]?.value?.product_number_general,
            bestSellingProductsCount: j.data[0]?.value?.product_number_sale,
            categoryProductsCount: j.data[0]?.value?.product_number_category,
            popularProductsCount: j.data[0]?.value?.product_number_popular,
            latestProductsCount: j.data[0]?.value?.product_number_last,
            brandProductsCount: j.data[0]?.value?.product_number_brand,
          })
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
    async update() {
      const {
        data,
        productsCount,
        bestSellingProductsCount,
        categoryProductsCount,
        popularProductsCount,
        latestProductsCount,
        brandProductsCount 
      } = this.state;
  //
   call_api({
     address_api: `${AppConstants.base_url_api}admin/setting/content/${data.id_select}`,
     method_api: "PUT",
     body: JSON.stringify({
      product_number_general:Number(productsCount),
      product_number_popular:Number(popularProductsCount),
      product_number_sale:Number(bestSellingProductsCount),
      product_number_last:Number(latestProductsCount),
      product_number_category:Number(categoryProductsCount),
      product_number_brand:Number(brandProductsCount)
     })
   })
     .then(parseJSON)
     .then(([status, j]) => {
       if (status === 200) {
         toast.success(j?.message);
       } else if (status === 401) {
         localStorage.clear();
       } else {
         toast.error(j?.message);
       }
     })
     .catch((error) => {
       console.error(error);
     });
 };
}

export default ContentSettingsController;