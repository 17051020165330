import { Component } from "react";
import { call_api, parseJSON } from "../../../core/service";
import { AppConstants } from "../../../core/constants";
import { toast } from "react-toastify";
import { createRef } from "react";
import {Logo} from "../../../assets";
import * as yup from 'yup'

interface IProps { 
    dispatch?:any,
    siteList?:any,
    loadingSiteList?:any,
    userData?:any
  }
  interface IState {
    togglePasswordModal:boolean,
    toggleTab: number,
    toggleMediaTab:number,    
    toggleAlert:boolean,
    dbName:string,
    atoumaticSave:Array<any>,
    idSelect:number,
    persianName: string,
    englishName:string,
    productName: string,
    seoTitle:string,
    seoDescription:string,
    canonicalUrl:string,
    routeName:string,
    weight:string,
    category:any,
    productURL:string,
    categoryList: Array<any>,
    brandId: any,
    brandList: Array<any>,
    tag:any,
    tagList:Array<any>,
    tagLoading: boolean,
    linkedProductId: Array<any>,
    linkedProductList:Array<any>,
    variableProductId:Array<any>,
    variableProductList:Array<any>,
    editorShortInfoText:string,
    editorGeneralInfoText:string,
    availebleMediaImage: string,
    mediaImage:any,
    mediaImageUpload:string,
    setSelectedFiles:Array<any>,
    imagesList:Array<any>,
    videosList:Array<any>,
    filesList:Array<any>,
    pros:string,
    prosList:any,
    cons:string,
    consList:Array<any>,
    seoCardList:Array<any>,
    confirmEditName:boolean,
  noteCardsList:any,
  notCardSelectList:Array<any>,
  noteCardSelectId:null,
  productDetails:any,
  linkedProductLoading:boolean,
  password:string,
  errorsList:any,
  passwordError:any,
  logsList:any,
  toggleLogListModal:boolean,
  categoryLoading:boolean,
  productSelectLoading:boolean,
  brandLoading: boolean,
  notesLoading: boolean,
  lastEditLoading: boolean,
  notListLoading:boolean,


  }
export class ProductUpdateController extends Component<IProps, IState> {
    tableInstanceRef: React.RefObject<any>;
    constructor(props: any) {
      super(props);
      this.tableInstanceRef = createRef()
      this.state = {   
        toggleTab: 0,
        togglePasswordModal:false,
          persianName: "",
          englishName:"",
          productName: "",
          seoTitle:"",
          seoDescription:"",
          canonicalUrl:"",
          routeName:"",
          weight:"",
          category:null,
          productURL:"",
          categoryList: [],
          brandId: null,
          brandList: [],
          tag:[],
          tagList:[],
          tagLoading: false,
          linkedProductId: [],
          linkedProductList:[],
          variableProductId:[],
          variableProductList:[],
          editorShortInfoText:"",
          editorGeneralInfoText:"",
          availebleMediaImage: "",
          mediaImage:"",
          mediaImageUpload:"",
          setSelectedFiles:[],
          imagesList:[],
          videosList:[],
          filesList:[],
          pros:"",
          prosList:[],
          cons:"",
          consList:[],
          seoCardList:[{image: Logo, alt:"تصویر موجود نیست" }],
          confirmEditName:false,
          noteCardsList:[],
          notCardSelectList:[],
          noteCardSelectId:null,
          productDetails:{},
          linkedProductLoading:false,
          dbName:"",
          idSelect:1,
          toggleMediaTab:0,
          toggleAlert:false,
          atoumaticSave:[{date:"۱۴۰۲/۱۰/۲"}, {date:"۱۴۰۲/۱۰/۲"}, {date:"۱۴۰۲/۱۰/۲"}, {date:"۱۴۰۲/۱۰/۲"}],
          password:"",
          errorsList:[],
          passwordError:[],
          logsList:[],
          toggleLogListModal:false,
          categoryLoading:false,
          productSelectLoading:false,
          brandLoading: false,
          notesLoading: false,
          lastEditLoading: false,
          notListLoading:false,
          
      }
    }
    handleToggleTabs =(event: React.MouseEvent<HTMLElement>,
    newAlignment: number,
  ) => { this.setState({toggleTab: newAlignment})}
  handleToggleMediaTabs =(event: React.MouseEvent<HTMLElement>,
    newAlignment: number,
  ) => {
    if(newAlignment !== null)
    {
      this.setState({toggleMediaTab: newAlignment})}

    }
   getProductDetails() {
    this.setState({notesLoading: true, lastEditLoading: true})
      call_api({
        address_api: `${AppConstants.base_url_api}admin/products/${window.location.pathname.split("/")[4]}`,
        method_api: "GET"
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            this.setState({
            persianName : j?.data?.product_name, 
            englishName: j?.data?.product_english_name,
            productName: j?.data?.product_slug,
            weight: j?.data?.weight,
            category: j?.data?.categories,
            brandId: j?.data?.brand,
            tag: j?.data?.tags,
            noteCardsList: j?.data?.notes || [],
            linkedProductId: j?.data?.relationship_products,
            variableProductId:j?.data?.relationship_products,
            seoDescription: j?.data?.seo_description,
            seoTitle: j?.data?.seo_title,
            productURL : j?.data?.product_url,
            routeName: j?.data?.product_name,
            canonicalUrl: j?.data?.seo_canonical,
            dbName: j?.data?.db_name,
            idSelect: j?.data?.id_select,
            editorGeneralInfoText : j?.data?.product_desc,
            editorShortInfoText : j?.data?.product_short_desc ,
            prosList : j?.data?.tip?.good ? j?.data?.tip?.good : [] ,
            consList: j?.data?.tip?.bad ? j?.data?.tip?.bad : [],
            logsList: j?.data?.logs ? j?.data?.logs : [],

          })      
            this.setState({notesLoading: false, lastEditLoading: false})   
            this.getLinkedProductsSearch()
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };

  categoryTimeout: string | number | NodeJS.Timeout | undefined;
  categorySelected = (choice: any) => {
    this.setState({category: choice})
  }
  handleCategorySearch= (choice:any) =>{
    clearTimeout(this.categoryTimeout);
    if(choice.length > 2)
    this.categoryTimeout = setTimeout(() => {
      this.getCategorySearch(choice)
    }, 1000);
  }
  async getCategorySearch(search?:string) {
    const {dbName} = this.state;
    let parameters = {
      search: search ? search: null,
      is_search : JSON.stringify(1),
      db_name:dbName ? dbName : null,

    }
    let url = new URL(`${AppConstants.base_url_api}admin/categories`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}admin/categories${url.search}` : `${AppConstants.base_url_api}admin/categories`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          if (j.data) {
            this.setState({
              categoryList:j.data
            })
          }
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    };
  brandTimeout: string | number | NodeJS.Timeout | undefined;
  brandSelected = (choice: any) => {
    this.setState({ brandId:choice})
  }
  handleBrandSearch= (choice:any) =>{
    clearTimeout(this.brandTimeout);
    if(choice.length > 2)
    this.brandTimeout = setTimeout(() => {
      this.getBrandSearch(choice)
    }, 1000);
  }
  async getBrandSearch(search?:string) {
    const {dbName} = this.state;
    let parameters = {
      search: search ? search: null,
      is_search : JSON.stringify(1),
      db_name:dbName ? dbName : null,
    }
    let url = new URL(`${AppConstants.base_url_api}admin/brands`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}admin/brands${url.search}` : `${AppConstants.base_url_api}admin/brands`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          if (j.data) {
            this.setState({brandList:j.data})
          }
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    };
    async getNotesSearch(search?:string) {
      const {dbName} = this.state;  
      let parameters = {
        search: search ? search: null,
        is_search : JSON.stringify(1),
        db_name:dbName ? dbName : null,
      }
      let url = new URL(`${AppConstants.base_url_api}admin/notes`)
      for (const [key, value] of Object.entries(parameters)) {
        if (value) {
          url.searchParams.append(key, value)
        }
      }
      call_api({
        address_api: url.search ? `${AppConstants.base_url_api}admin/notes${url.search}` : `${AppConstants.base_url_api}admin/notes`,
        method_api: "GET"
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            if (j.data) {
              this.setState({
                notCardSelectList:j.data?.data})
            }
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      };
  tagSelected = (choice: any) => {
    this.setState({ tag:choice   })
  }
  tagSearchTimeout: string | number | NodeJS.Timeout | undefined;
  handleTagSearch = (choice:any) =>{
    clearTimeout(this.tagSearchTimeout);
    if(choice.length > 2)
    this.tagSearchTimeout = setTimeout(() => {
      this.getTagSearch(choice)
    }, 1000);
  }
  async getTagSearch(search?:string) {
    const {dbName} = this.state;
    this.setState({tagLoading: true})
    let parameters = {
      search: search ? search: null,
      is_search : JSON.stringify(1),
      db_name:dbName ? dbName : null,

    }
    let url = new URL(`${AppConstants.base_url_api}admin/tags`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}admin/tags${url.search}` : `${AppConstants.base_url_api}admin/tags`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(async ([status, j]) => {
        if (status === 200) {
          if (j.data) {
            this.setState({ tagList:j.data, tagLoading:false  })
          }
        } else if (status === 401) {
          this.setState({ tagLoading:false  })
          localStorage.clear();
        } else {
          this.setState({ tagLoading:false  })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  linkedProductSelected = (choice: any) => {
    this.setState({ linkedProductId:choice})
  };
  linkedProductsSearchTimeout: string | number | NodeJS.Timeout | undefined;

  handleLinkedProductsSearch= (choice:any) =>{
    clearTimeout(this.linkedProductsSearchTimeout);
    if(choice.length > 2)
    this.linkedProductsSearchTimeout = setTimeout(() => {
      this.getLinkedProductsSearch(choice)
    }, 1000);
  }
   getLinkedProductsSearch(search?:string) {
    const {dbName} = this.state;
    this.setState({linkedProductLoading: true})

    let parameters = {
      search: search ? search: null,
      is_search : JSON.stringify(1),
      db_name:dbName ? dbName : null,

    }
    let url = new URL(`${AppConstants.base_url_api}admin/product/search`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}admin/product/search${url.search}` : `${AppConstants.base_url_api}product/search`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(async ([status, j]) => {
        if (status === 200) {
          if (j.data) {
            this.setState({ linkedProductList:j.data, variableProductList:j.data,linkedProductLoading:false })
          }
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  variableProductsSearchTimeout: string | number | NodeJS.Timeout | undefined;
  variableProductsSelect = (choice: any) => {
    this.setState({ variableProductId:choice})

  };
  handleVariableProductsSearch= (choice:any) =>{
    clearTimeout(this.variableProductsSearchTimeout);
    if(choice.length > 2)
    this.variableProductsSearchTimeout = setTimeout(() => {
      this.getVariableProductsSearch(choice)
    }, 1000);
  }
  async getVariableProductsSearch(search?:string) {
    const {dbName} = this.state;
    let parameters = {
      search: search ? search: null,
      is_search : JSON.stringify(1),
      db_name:dbName ? dbName : null,
    }
    let url = new URL(`${AppConstants.base_url_api}admin/product/search`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}admin/product/search${url.search}` : `${AppConstants.base_url_api}product/search`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(async ([status, j]) => {
        if (status === 200) {
          if (j.data) {
            this.setState({ variableProductList:j.data})
          }
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  editNoteCard = (id: number, text:string) =>{
    const {idSelect} = this.state;
    let parameters = {
      message : text,
      id_select:idSelect ? idSelect.toString() : null,
    }
    let url = new URL(`${AppConstants.base_url_api}admin/notes/${id?.toString()}`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}admin/notes/${id?.toString()}${url.search}` : `${AppConstants.base_url_api}notes/${id?.toString()}`,
      method_api: "PUT"
    })
      .then(parseJSON)
      .then(async ([status, j]) => {
        if (status === 200) {
          this.getProductDetails()

          toast.success("یادداشت با موفقیت ویرایش شد.")
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }


  handleEditorChangeShortInfo = (e: { target: { getContent: () => any; }; }) => {
    this.setState({ editorShortInfoText:e.target.getContent()}) 

  };
  handleEditorChangeGeneralInfo = (e: { target: { getContent: () => any; }; }) => {
    this.setState({ editorGeneralInfoText:e.target.getContent()}) 

  };
  handleMediaImageUpload = (event: any) => {
      let news_image_file = null;
      news_image_file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(news_image_file);
      reader.onloadend = () => {
        this.setState({ mediaImage:reader.result}) 

      }
      
      if (news_image_file) {
        this.setState({ mediaImageUpload:news_image_file}) 
      }
      this.setState({ availebleMediaImage:""}) 

    } 
    handleSubmitPros=()=> {
      const {pros, prosList} = this.state;
      
      if (pros === "") {
          alert("فیلد خالی است!");
        } else {

          this.setState({prosList: [...prosList, pros], pros:""})

        }
    }
    handleSubmitCons=()=> {
      const {cons, consList} = this.state;

      if (cons === "") {
          alert("فیلد خالی است!");
        } else {
          this.setState({ consList:[...consList,cons],cons:""})
        }
    }
  handleChangePros = (event:any, index:number) =>{
    const {prosList} = this.state;
    const newPros = [...prosList];
  const { value } = event.currentTarget;
  newPros[index] = value;
          this.setState({ prosList:newPros})
  }
  handleChangeCons = (event:any, index:number) =>{
    const {consList} = this.state;
    const newPros = [...consList];
    const { value } = event.currentTarget;
    newPros[index] = value; 
    this.setState({consList:newPros})
    }
    changeImage = (argOne: any, argTwo: any, argThree: any) =>{
      this.setState({imagesList: argOne, videosList: argTwo, filesList: argThree})
    }
    changeVideo = (argOne: any, argTwo: any, argThree: any) =>{
      this.setState({imagesList: argOne, videosList: argTwo, filesList: argThree})
    }
    changeFile = (argOne: any, argTwo: any, argThree: any) =>{
      this.setState({imagesList: argOne, videosList: argTwo, filesList: argThree})
    }
    
  editNoteCardTimeout: string | number | NodeJS.Timeout | undefined;
  handleChangeNoteCard = (item:any, event:any, index:number) =>{
      const {noteCardsList} = this.state;
      const newNoteCard = [...noteCardsList];
      const { value } = event.currentTarget;
      newNoteCard[index].message = value;
      this.setState({noteCardsList:newNoteCard})
      clearTimeout(this.editNoteCardTimeout);
      this.editNoteCardTimeout = setTimeout(() => {
        this.editNoteCard(item, value)
      }, 1000);
      }
  handleSubmitSeoCard = () =>{

  }
  handleDeleteSeoCard = (index:number) =>{
    const { seoCardList} = this.state;
    this.setState({seoCardList:[...seoCardList.slice(0,index)] })
    const list = [...seoCardList]
    list.splice(index,1);
    this.setState({seoCardList:list})
  }
  changeAlt = (event:any, index:number) =>{
    const {seoCardList} = this.state;
    const newSeoCard = [...seoCardList];
    const { value } = event.currentTarget;
    newSeoCard[index] = value;
    this.setState({seoCardList:newSeoCard})
    }
  handleDeletePros = (index:number) =>{
    const { prosList} = this.state;
    const list = [...prosList]
    list.splice(index,1)
    this.setState({prosList:list})
  }
  handleDeleteCons = (index:number) =>{
    const { consList} = this.state;
    const list = [...consList]
    list.splice(index,1)
    this.setState({consList:list})

  }
  handleDeleteNoteCard = (index:number, id:number) =>{
    const { noteCardsList, idSelect} = this.state;
    const list = [...noteCardsList]
    list.splice(index,1)
    this.setState({noteCardsList:list})

    let parameters = {
      id_select:idSelect ? idSelect.toString() : null,
    }
    let url = new URL(`${AppConstants.base_url_api}admin/notes/${id?.toString()}`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}admin/notes/${id?.toString()}${url.search}` : `${AppConstants.base_url_api}notes/${id?.toString()}`,
      method_api: "DELETE"
    })
      .then(parseJSON)
      .then(async ([status, j]) => {
        if (status === 200) {
          toast.success("یادداشت با موفقیت حذف شد.");
          this.getProductDetails()
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });

  }
   handleKeyPress = (event: { key: string; }) => {
    const {prosList, pros} = this.state;
    if (event.key === 'Enter') {
      this.setState({prosList: [...prosList, pros], pros:""})
       }
  };
  handleKeyPressCons = (event: { key: string; }) => {
    const {consList,cons} = this.state;
    if (event.key === 'Enter') {
      this.setState({consList: [...consList, cons], cons:""})
    }
  };
  handleKeyPressNoteCardSelect = (event: { key: string; }) => {
    const {noteCardSelectId, noteCardsList} = this.state;
    let ids = [...noteCardsList]
    ids = [...noteCardsList, noteCardSelectId]

    if (event.key === 'Enter' && noteCardSelectId !== null ) {
      this.setState({noteCardsList: ids, noteCardSelectId:null})
     }
  };
  handleAlert = (event: any) =>{
    const {confirmEditName} = this.state;
    
    if(event)
    {
      if(confirmEditName === false)
      {
  
        this.setState({toggleAlert: true})
      }
    }

  }
  handleChangeCreatableSelect = (newValue: any, actionMeta: any) => {
    const { noteCardsList} = this.state;
    if(newValue !== null)
    {      
      this.setState({noteCardsList : [...noteCardsList, newValue] , noteCardSelectId : null})
      this.handleSendNote(newValue)

    }
  };
  handleCreateNote = (input: string) =>{
    const { noteCardsList} = this.state;
    let ids = [...noteCardsList]
    ids = [...noteCardsList, input]

    if(input !== null)
    {      
      this.setState({noteCardsList : ids, noteCardSelectId:null});
      this.handleSendNote(input)
    }
   
  }
  handleSendNote = (input:any) => {
    const { idSelect} = this.state;

    let parameters = {
      id_select :  idSelect ? idSelect.toString() : null,
      message : input?.message ? input?.message : input,
      status : "",
      parent_id:""
    }
    let url = new URL(`${AppConstants.base_url_api}admin/notes`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}admin/notes${url.search}` : `${AppConstants.base_url_api}notes`,
      method_api: "POST"
    })
      .then(parseJSON)
      .then(async ([status, j]) => {
        if (status === 200) {
          toast.success("یادداشت با موفقیت افزوده شد.")
          this.getProductDetails()
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  notesTimeout: string | number | NodeJS.Timeout | undefined;
  handleInputChangeCreatableSelect = (inputValue: any, actionMeta: any) => {
    clearTimeout(this.notesTimeout);
    if(inputValue.length > 2)
    this.notesTimeout = setTimeout(() => {
      this.getNotesSearch(inputValue)
    }, 1000);
    
  };
 async confirmPassword (){
  const confirmPassword = yup.object({
      password:yup.string().required("واردکردن رمز الزامی است."),
    }); 
    const {password} = this.state;
    try{
      await confirmPassword.validate({password}, { abortEarly: false });
    call_api({
      address_api: `${AppConstants.base_url_api}admin/user/check-password-local`,
      method_api: "POST",
      body: JSON.stringify({
        local_password:password
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          if(j?.data)
          {
            this.setState({togglePasswordModal: false})
            this.handleEdit()
          }
          else 
          toast.error("رمز محلی اشتباه است!");
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    } catch(err: any) 
    {
      this.setState({ passwordError: err.inner })
    }
    }
    async handleEdit (){
      const {idSelect, dbName, prosList, imagesList, productName, consList, noteCardsList, variableProductId,linkedProductId,  persianName, englishName, weight, category, brandId, tag, editorGeneralInfoText, editorShortInfoText, seoTitle, seoDescription,canonicalUrl} = this.state;

      const tagList = [];
    
      for (var a = 0; a < tag?.length; a++) {
        tagList.push(tag[a]?.id)
      }
      const linkedProductList = [];
      for (var b = 0; b < linkedProductId?.length; b++) {
        linkedProductList.push(linkedProductId[b]?.id)
      }
      const variableProductsList = [];
      for (var c = 0; c < variableProductId?.length; c++) {
     
        variableProductsList.push(variableProductId[c]?.id)
      }
      
      const noteCardsArray = [];
      for (var d = 0; d < noteCardsList?.length; d++) {
        
        noteCardsArray.push(noteCardsList[d]?.message)
      }
      
      const data = new FormData()
      persianName && data.append('product_name', persianName)
      englishName && data.append('product_english_name',  englishName)
      productName && data.append('product_slug',  productName)
      weight && data.append('weight', weight)
      category?.id && data.append('category_id', category?.id)
      brandId?.length > 0 && data.append('brand_id', brandId?.id)
      tagList?.length > 0 && data.append('tag_id', tagList.toString())
      variableProductsList?.length > 0 && data.append('product_var', JSON.stringify(variableProductsList))
      linkedProductList?.length > 0 && data.append('related_id', JSON.stringify(linkedProductList))
      editorShortInfoText && data.append('product_short_desc', editorShortInfoText)
      editorGeneralInfoText && data.append('product_desc', editorGeneralInfoText)
      seoTitle && data.append('seo_title', seoTitle)
      seoDescription && data.append('seo_description', seoDescription)
      canonicalUrl && data.append('seo_canonical', canonicalUrl)
      data.append('db_name', dbName)
      noteCardsArray?.length > 0 && data.append('message', JSON.stringify(noteCardsArray))
      noteCardsArray?.length > 0 && data.append('tip', JSON.stringify([{"good": prosList, "bad": consList}][0]))
      for (let i = 0; i < imagesList.length; i++) {
        data.append('images[]', imagesList[i])
     }
  
  call_api({
    address_api: `${AppConstants.base_url_api}admin/products/update/${idSelect.toString()}`,
    method_api: "POST",
    body: data,
    file:true
  })
    .then(parseJSON)
    .then(([status, j]) => {
      if (status === 200) {
       
        toast.success(j?.message);
        this.setState({errorsList:[]})
        this.getProductDetails()
      } else if (status === 401) {
        localStorage.clear();
      } else {
        toast.error(j?.message);
      }
    })
    .catch((error) => {
      console.error(error);
    });
    }
  async handleupdateValidations (){
    
    const editValidation = yup.object({
      persianName:yup.string().required("نام فارسی الزامی است."),
      productName:yup.string().required(" نامک الزامی است."),
      seoDescription:yup.string().required("توضیحات سئو الزامی است."),
      seoTitle:yup.string().required("عنوان سئو الزامی است."),
      category:yup.object()
      .shape({
        id: yup.number().required(),
      })
      .default(undefined).required("برند الزامی است."),
      brandId:yup.object()
      .shape({
        id: yup.number().required(),
      })
      .default(undefined).required("برند الزامی است."),  
          tag: yup.array().min(1, "حداقل یک مورد از برچسب باید انتخاب شود.").required("برچسب الزامی است."),
      editorShortInfoText:yup.string().required("توضیحات کوتاه الزامی است."),
      editorGeneralInfoText:yup.string().required("توضیحات کلی الزامی است."),
      
    }); 
    const {productName,persianName, category, brandId, tag, editorGeneralInfoText, editorShortInfoText, seoTitle, seoDescription} = this.state;
  
    try{

      await editValidation.validate({ persianName, productName, seoDescription, seoTitle, category,
        brandId , tag, editorShortInfoText, editorGeneralInfoText}, { abortEarly: false });
        this.setState({togglePasswordModal: true, errorsList:[]})

       this.confirmPassword()
    } catch(err: any) 
    {
      this.setState({ errorsList: err.inner })
    }
  }
  handleCopyProduct = () =>{
      const {idSelect,} = this.state;
  
            let url = new URL(`${AppConstants.base_url_api}admin/product/copy/${idSelect?.toString()}`)
            call_api({
              address_api: url.search ? `${AppConstants.base_url_api}admin/product/copy/${idSelect?.toString()}${url.search}` : `${AppConstants.base_url_api}admin/product/copy/${idSelect?.toString()}`,
              method_api: "POST",
            })
              .then(parseJSON)
              .then(async ([status, j]) => {
                if (status === 200) {
                  toast.success("محصول با موفقیت کپی شد.");
                  window.open(`/admin/products/edit/${j.data}`, '_blank');
                } else if (status === 401) {
                  localStorage.clear();
                } else {
                  toast.error(j?.errors);
                }
              })
              .catch((error) => {
                console.error(error);
              });
        
  }
}

export default ProductUpdateController;