import { Component } from "react";
import * as yup from 'yup'
import { toast } from "react-toastify";
import { call_api, parseJSON } from "../../../core/service";
import { AppConstants } from "../../../core/constants";
import { fetchSiteList, fetchUserData } from "../../../Redux/services/generalService";

interface IProps {
  dispatch?:any,
  siteList?:any,
  loadingSiteList?:any,
  userData?:any
 }

interface IState {
  loading: boolean;
  loadingChangePassword: boolean;
  loadingUpdateProfile: boolean;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  automaticSave: string;
  availebleProfileImage?: any;
  profileImage?: any,
  profileImageUpload?: any,
  errorsList?: any,
  localPassword?: string;
  instagram: string;
  whatsapp: string;
  telegram: string,
  mobile: string,
  userRole: string,
  registeryDate: string,
  gender: boolean;
  setPasswordmodal: boolean;
  newPassword: string,
  repeatPassword: string,
  otpCode?: string;
  showPasswordOtp?: boolean;
  dateOfBirth: Date;
  showPassword: any;
}

 class ProfileController extends Component<IProps, IState> {

  constructor(props: any) {
    super(props);
    // this.getUserDetails = this.getUserDetails.bind(this);
    this.state = {
      loading: false,
      loadingChangePassword: false,
      loadingUpdateProfile: false,
      firstName: "",
      lastName: "",
      automaticSave: "",
      email: "",
      phone: "",
      localPassword: "",
      newPassword: "",
      repeatPassword: "",
      instagram: "",
      whatsapp: "",
      telegram: "",
      mobile: "",
      availebleProfileImage: "",
      profileImage: "",
      profileImageUpload: "",
      errorsList: [],
      userRole: "",
      registeryDate: "",
      gender: false,
      setPasswordmodal: false,
      otpCode: "",
      showPasswordOtp: false,
      dateOfBirth: new Date(),
      showPassword: {},
    };
  }

  async getUserDetails() {
    this.setState({ loading: true })
    call_api({
      address_api: `${AppConstants.base_url_api}seller/users/${window.localStorage.getItem('userId')}`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({ loading: false })
          this.setState({
            mobile:j.data.mobile,
            email:j.data.email || "",
            userRole:j.data?.roles[0].persian_name,
            firstName: j.data.profile?.name || "",
            lastName: j.data.profile?.family || "",
            phone: j.data.profile?.phone || "",
            telegram:j.data.social?.telegram || "",
            whatsapp:j.data.social?.whatsapp || "",
            instagram:j.data.social?.instagram || "",
            dateOfBirth:j.data.profile?.birthday,
            gender: j.data.profile?.gender === 0 ? true : false,
            registeryDate:j.data.created_at ,
            availebleProfileImage: j.data?.profile?.avatar || "",
          })
          if (j.data.password_is_changed === 1) {
            this.setState({
              setPasswordmodal: false
            })
          } else {
            this.setState({
              setPasswordmodal: true
            })
          }
        } else if (status === 401) {
          this.setState({ loading: false })
          localStorage.clear();
        } else {
          this.setState({ loading: false })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  handleKeyPressDown = (event: { key: string; }) =>{
    const {showPasswordOtp} = this.state;
    if (event.key === 'Enter') {
      if(showPasswordOtp)
      {
        this.handlePasswordOtp()
      }
      else{
        this.handleSetPassword()
      }
       
  };
  }
  async updatetUser() {
    const passwordRegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
    const telegramRegExp = /^[a-zA-Z0-9_]{5,32}$/
    const instagramRegExp = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/
    const mobileRegExp = /^09\d{9}$/;
    const phoneRegExp = /^\d{5,12}$/;
    const justPersian = /^[\u0600-\u06FF\s]+$/gi;
    const { mobile, firstName, lastName, gender, email, phone, newPassword,repeatPassword, telegram, whatsapp, instagram , availebleProfileImage,profileImageUpload} = this.state;
    const updateProfileSchema = yup.object({
      newPassword: yup.string().notRequired().min(8, 'کد ورود کمتر از 8 رقم نمی باشد').max(255, 'کد ورود بیشتر از 255 رقم نمی باشد').matches(passwordRegExp, 'فرمت وارد شده صحیح نمی باشد رمز عبور باید حداقل شامل یک حرف بزرگ , یک حرف کوچک  , یک عدد و یک سیمبل باشد').nullable().transform((value) => !!value ? value : null),
      repeatPassword: yup.string().when("$condition", (condition, schema) =>
      newPassword.length > 0 ? schema.min(8, 'کد ورود کمتر از 8 رقم نمی باشد').max(255, 'کد ورود بیشتر از 255 رقم نمی باشد').matches(passwordRegExp, 'فرمت وارد شده صحیح نمی باشد رمز عبور باید حداقل شامل یک حرف بزرگ , یک حرف کوچک  , یک عدد و یک سیمبل باشد').oneOf([yup.ref('newPassword')], 'رمز عبور و تکرار ان با هم مطابقت ندارند') : schema,
    ),
      firstName: yup.string().required(" نام الزامی می باشد ").max(32, 'نام بیشتر از 32 رقم نمی باشد').min(3, ' نام کمتر از 3 رقم نمی باشد').matches(justPersian,  ' لطفا از زبان فارسی استفاده کنید  '),
      lastName : yup.string().required(" نام خانوادگی الزامی می باشد ").max(32, 'نام خانوادگی بیشتر از 32 رقم نمی باشد').min(3, '  نام خانوادگی کمتر از 3 رقم نمی باشد').matches(justPersian,  ' لطفا از زبان فارسی استفاده کنید  '),
      gender : yup.string(),
      email:yup.string().email("فرمت ایمیل صحیح نمی باشد"),
      phone: yup.string().notRequired().matches(phoneRegExp,  'فرمت وارد شده صحیح نمی باشد ').nullable().transform((value) => !!value ? value : null),
      whatsapp: yup.string().notRequired().matches(mobileRegExp,  'فرمت وارد شده صحیح نمی باشد ').nullable().transform((value) => !!value ? value : null),
      telegram: yup.string().notRequired().max(32, 'نام کاربری نباید بیش از 32 کاراکتر داشته باشد').min(5, 'نام های کاربری باید حداقل دارای 5 کاراکتر باشد').matches(telegramRegExp,  'فرمت وارد شده صحیح نمی باشد ').nullable().transform((value) => !!value ? value : null),
      instagram: yup.string().notRequired().max(30, 'نام کاربری نباید بیش از 30 کاراکتر داشته باشد').min(3, 'نام های کاربری باید حداقل دارای 3 کاراکتر باشد').matches(instagramRegExp,  'فرمت وارد شده صحیح نمی باشد ').nullable().transform((value) => !!value ? value : null)
    });
    try {
      await updateProfileSchema.validate({ newPassword,repeatPassword,firstName,lastName,gender,email,mobile,phone,whatsapp,telegram,instagram }, { abortEarly: false });
      this.setState({ loadingUpdateProfile: true })
      const data = new FormData()
      data.append('name',firstName)
      data.append('family', lastName)
      phone && data.append('phone',phone)
      data.append('gender', gender ? "0" : "1")
      data.append('email',email)
      data.append('whatsapp',whatsapp)
      data.append('telegram',telegram ? telegram : "")
      data.append('instagram',instagram ? instagram : "")
      data.append('password', newPassword)
      data.append('password_confirmation', repeatPassword)
      if(availebleProfileImage === "") {
        data.append('avatar', profileImageUpload)
      } 
      call_api({
        address_api: `${AppConstants.base_url_api}seller/users/update/${window.localStorage.getItem('userId')}`,
        method_api: "POSt",
        body: data,
        file:true,
      })
        .then(parseJSON)
        .then(([status, j]) => {
          this.setState({ errorsList: [] })
          if (status === 200) {
            toast.success(j?.message);
            this.setState({ loadingUpdateProfile: false })
            this.props.dispatch(fetchSiteList());
            this.props.dispatch(fetchUserData());
            this.setState({
              profileImage: "", availebleProfileImage: ""
            })
            this.getUserDetails();
          } else if (status === 401) {
            this.setState({ loadingUpdateProfile: false })
            toast.error(j?.errors);
            localStorage.clear();
          } else {
            console.warn(status, j);
            this.setState({ loadingUpdateProfile: false })
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });

    } catch (err: any) {
      this.setState({ loadingUpdateProfile: false })
      this.setState({ errorsList: err.inner })

    }
  };

  async handleProfileImageUpload(event: any) {
    let news_image_file = null;
    news_image_file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(news_image_file);
    reader.onloadend = () => {
      this.setState({ profileImage: reader.result });
    }
    if (news_image_file) {
      this.setState({ profileImageUpload: news_image_file });

    }
    this.setState({ availebleProfileImage: "" });

  }
  deleteNewImage = () => {
    this.setState({
      profileImage: "", profileImageUpload: "", availebleProfileImage: ""
    })
  }
  async handleDeleteProfileImage() {
    call_api({
      address_api: `${AppConstants.base_url_api}seller/users/delete_avatar/${window.localStorage.getItem('userId')}`,
      method_api: "POST",
    })

      .then(parseJSON)
      .then(([status, j]) => {
        this.setState({ errorsList: [] })
        if (status === 200) {
          toast.success("با موفقیت انجام شد");
          this.setState({
            profileImage: "", profileImageUpload: "", availebleProfileImage: ""
          })
        } else if (status === 401) {
          toast.error(j?.message);
          localStorage.clear();
        } else {
          console.warn(status, j);
          this.setState({ loadingUpdateProfile: false })
          toast.error(j?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  handleClose = () => {
    this.setState({
      setPasswordmodal: false
    })
  };
  async handleSetPassword() {
    const passwordRegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
    const { newPassword, repeatPassword } = this.state;
    const otpRegisterSchema = yup.object({
      newPassword: yup.string().required(" رمز ورود الزامی می باشد  ").min(8, 'کد ورود کمتر از 8 رقم نمی باشد').max(255, 'کد ورود بیشتر از 255 رقم نمی باشد').matches(passwordRegExp, 'فرمت وارد شده صحیح نمی باشد رمز عبور باید حداقل شامل یک حرف بزرگ , یک حرف کوچک  , یک عدد و یک سیمبل باشد'),
      repeatPassword: yup.string().required(" رمز ورود الزامی می باشد  ").min(8, 'کد ورود کمتر از 8 رقم نمی باشد').max(255, 'کد ورود بیشتر از 255 رقم نمی باشد').matches(passwordRegExp, 'فرمت وارد شده صحیح نمی باشد رمز عبور باید حداقل شامل یک حرف بزرگ , یک حرف کوچک  , یک عدد و یک سیمبل باشد').oneOf([yup.ref('newPassword')], 'رمز عبور و تکرار ان با هم مطابقت ندارند')
    });
    try {
      this.setState({ loadingChangePassword: true })
      await otpRegisterSchema.validate({ newPassword, repeatPassword }, { abortEarly: false });
      call_api({
        address_api: `${AppConstants.base_url_api}seller/change-password/${window.localStorage.getItem('userId')}`,
        method_api: "POST",
        body: JSON.stringify({
          password: newPassword,
          password_confirmation: repeatPassword,
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          this.setState({ errorsList: [] })
          if (status === 200) {
            this.setState({
              showPasswordOtp: true
            })
            this.setState({ loadingChangePassword: false })
          } else if (status === 401) {
            this.setState({ loadingChangePassword: false })
            toast.error(j?.errors);
            localStorage.clear();
          } else {
            console.warn(status, j);
            this.setState({ loadingChangePassword: false })
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });

    } catch (err: any) {
      this.setState({ loadingChangePassword: false })
      this.setState({ errorsList: err.inner })
    }
  }
  async handlePasswordOtp() {

    const { otpCode } = this.state;
    const otpRegisterSchema = yup.object({
      otpCode: yup.string().required(" رمز ورود الزامی می باشد  ").min(4, 'کد ورود کمتر از 4 رقم نمی باشد').max(6, 'کد ورود بیشتر از 6 رقم نمی باشد'),
    });
    try {
      this.setState({ loadingChangePassword: true })
      await otpRegisterSchema.validate({ otpCode }, { abortEarly: false });
      call_api({
        address_api: `${AppConstants.base_url_api}seller/change-password/otp/${window.localStorage.getItem('userId')}`,
        method_api: "PATCH",
        body: JSON.stringify({
          otp: otpCode,
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            this.setState({ errorsList: [] })
            this.setState({ loadingChangePassword: false })
            this.setState({
              setPasswordmodal: false
            })
            this.setState({
              showPasswordOtp: false
            })
            toast.success(j?.message);
          } else if (status === 401) {
            this.setState({ loading: false })
            toast.error(j?.errors?.message);
            localStorage.clear();
          } else {
            this.setState({ loadingChangePassword: false })
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (err: any) {
      this.setState({ loadingChangePassword: false })
      this.setState({ errorsList: err.inner })
    }

  }
}
export default ProfileController;

