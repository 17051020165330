
import { LoadingSpinner, NotificationCard, NotificationsList, LandingCard, Header } from "../../../components";
import { connect } from "react-redux";
import { fetchSiteList, fetchUserData } from "../../../../Redux/services/generalService";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {menuItems} from "../../../../core/constants";
import { HomeAdminController } from "../../../controllers";


class Home extends HomeAdminController {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getDashboardList();
    this.props.dispatch(fetchSiteList());
    this.props.dispatch(fetchUserData());
  }
  
  render() {
    const { dashboardListData , loading } = this.state;
    
    return (
      <HelmetProvider>
      <div className="home">
        <Helmet>
                <title>پیشخوان</title>
            </Helmet>
          <Header isActive="isAdminHome" title="پیشخوان"/>
        {loading ? <LoadingSpinner /> :
        <div>
        {/* {loading ? (
          <LoadingSpinner />
        ) : ( */}
        {/* <Chart
              options={this.state.options}
              series={this.state.series}
              type="bar"
            />
              </div>
              <div className="col-md-6 col-12">
              <Chart
              options={this.state.options2}
              series={this.state.series2}
              type="donut"
            /> */}
        {/* )} */}
        <div>
          {/* <LableIcon title={"اعلانات"} /> */}
        </div>
       
       
        <div className="notifications-list-container">

           <div className="">
           <NotificationsList hideBtn isVertical >
           <NotificationCard
              isVertical
              item={ {categories:"دسته‌بندی‌ها",
                db_name:"سایت",
              notes:"یادداشت‌ها",
              products:"محصولات",
              redirects:"ریدایرکت‌ها ",
              tags:"برچسب‌ها ",
              tickets:"تیکت‌ها"}}
            />
            {dashboardListData && dashboardListData?.length > 0 && dashboardListData?.map((item: any,index:number) => {
             return(
              <NotificationCard
              key={index}
              isVertical
              item={item}
            />
             )
            })}
            </NotificationsList>
           </div>
          
        </div>
        
        <div className="home-landing-card">
         {menuItems.map((item:any, index:number)=>(
           <div key={index} className="landing-container">
             {item.children.filter((item:any) => (
              item?.role === "admin" 
             )).map((card:any, index:number)=> (
              <LandingCard key={index}  myStyle={card?.myStyle} title={card?.title} 
               route={card?.route}/>
             ))
             }              
           </div>
           ))}
      </div>
        </div>
         
        }

             
    </div>
    </HelmetProvider>
    );
  }
}
const mapStateToProps = (dispatch:any) => {
  return {
    dispatch,
    siteList:dispatch.general.siteList,
    loadingSiteList:dispatch.general.loadingSiteList,
    userData:dispatch.general.userData,
  }
};

export default connect(mapStateToProps)(Home);

