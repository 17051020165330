import {
  HTMLAttributes,
  ReactElement,
} from "react";

interface Props extends HTMLAttributes<HTMLElement> {
  placeholder: string;
  value?: string;
  icon?: ReactElement;
  onChange?: any;
  errortext?:string;
  name?:string;
  myStyle?:string;
  label?:string;
  onKeyDown?:any;
  inputContainerStyle?:string;

}

 function TextAreaLabel({
  placeholder,
  onChange,
  value,
  myStyle,
  errortext,
  name,
  onKeyDown,
  inputContainerStyle,
  label
}: Props): ReactElement {
  return (
    <div className={`textarea ${myStyle}`}>
      <label className="textarea-label">{label}</label>
      <textarea
      onKeyDown={onKeyDown}
      name={name}
          className={`textarea-input ${inputContainerStyle} `}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        ></textarea>
         {errortext && 
      <span className="input-icon-error">
        {errortext}
      </span>
      }
    </div>
  );
}
export default TextAreaLabel;
