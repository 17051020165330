import { DropDownIcon, CustomButton, Header } from "../../../components";
import moment from 'moment-jalaali';
import {BsPlusCircle } from "react-icons/bs";
import { createTheme, ThemeProvider } from '@mui/material';
import { InputTextLabel, SubmissionAlert, Option } from "../../../components";
import Box from '@mui/material/Box';
import { AiOutlineFileExcel } from "react-icons/ai";
import Pagination from '@mui/material/Pagination';
import { MaterialReactTable } from 'material-react-table';
import Tooltip from '@mui/material/Tooltip';
import { FiAlertTriangle } from "react-icons/fi";
import Select, { CSSObjectWithLabel } from "react-select";
import { Link } from "react-router-dom";
import {ProductsRelationsController} from "../../../controllers";
import { connect } from "react-redux";
import { RiDeleteBin6Line } from "react-icons/ri";
import {TfiFilter} from "react-icons/tfi";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { TfiUnlink } from "react-icons/tfi";
import { productRelationsColumns } from "../../../../core/column";
import { exportToExcel } from "../../../../core/utils";

class ProductsRelations extends ProductsRelationsController {

  componentDidMount() {
   window.scrollTo(0, 0);
    this.getRelatedProducts()
  }
  render() {
    const { openSingleModal , showFilters, openMultipleModal,
      loading, page,rowCount,rowCountList, relatedProductsLis, dreiverId    } = this.state;
    const { siteList, loadingSiteList} = this.props;
    const theme = createTheme({
      typography: {
        fontFamily:
          'Vazir'
      }
    });
    return (
      <HelmetProvider>
      <ThemeProvider theme={theme}>
        <div className="orders">
          <Helmet>
                <title>محصولات مرتبط </title>
            </Helmet>
          <Header title="محصولات مرتبط"/>
          <div className="custom-card">
            <div className="filter-select-container mb-2 pe-3">
                  <div className="filter-options-container">
                  {showFilters && 
                  <>
                    <div className="column-50 two-section bottom-20">
              <Tooltip title=" ثبت ارتباط جدید بین محصولات "><Link target="_blank" to="/create-relations">  <BsPlusCircle className="icon" /></Link></Tooltip>
              <Select
                className="profile-select"
                      styles={{
                        control: (baseStyles, state):CSSObjectWithLabel => ({
                          ...baseStyles,
                          boxShadow:"rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",
                          borderWidth: "1px",
                          backgroundColor: "#fff",
                          display: "flex",
                          flexDirection: "row-reverse",
                          textAlign: "right",
                          borderRadius: "4px",
                          height:"35px",
                          minHeight:"20px",
                          outline: "unset",
                        }) as CSSObjectWithLabel,
                      }}
                      onChange={(choice) => this.driverSelected(choice)}
                      components={{ Option }}
                      value={dreiverId}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      noOptionsMessage={() => 'این مورد وجود ندارد!'}
                      menuPortalTarget={document.body}
                      options={siteList }
                      getOptionLabel={(option: any) => option?.value?.persian_name}
                      getOptionValue={(option: any) => option?.value?.name}
                      placeholder="سایت"
                      isClearable={true}
                      loadingMessage={() => 'در حال بارگذاری  ...'}
                      isLoading={loadingSiteList}
                    />
                    </div> 
                    <div className="column-50"> 
                    <InputTextLabel
                      type={"text"}
                      onChange={(event) => this.handleSearch(event.currentTarget.value)}
                      className="search-input"
                      placeholder="جستجو..."
                    disabled={dreiverId && Object.keys(dreiverId).length > 0  ? false : true}
                    />                   
                  </div>
                </>
                }
                </div>
              </div>
              {openSingleModal &&
                <SubmissionAlert isOpen={openSingleModal} title="اخطار!" setClose={(open: boolean) => this.setState({ openSingleModal: false })} onClick={() => this.handleDeleteSingle()} icon={<FiAlertTriangle color="orange" fontSize="50" />}
                  details="ایا از حذف این محصول اطمینان دارید؟" />
                }
                {openMultipleModal &&

                <SubmissionAlert isOpen={openMultipleModal} title="اخطار!" setClose={(open: boolean) => this.setState({ openMultipleModal: false })} onClick={() => this.handleDeleteMultiple()} icon={<FiAlertTriangle color="orange" fontSize="50" />}
                  details="ایا از حذف گروهی این محصولات اطمینان دارید؟" />
                }
              <MaterialReactTable
              muiTablePaperProps={{
                sx: { mr: '1.5rem',ml: '1.5rem', border:"1px solid #e5e5e5", mt:'1.5rem' },
              }}
              positionActionsColumn={'last'}
                columns={productRelationsColumns}
                data={relatedProductsLis && relatedProductsLis?.data?.length > 0 && relatedProductsLis?.data?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null).map((item: any, index: number) => {
                  return ({
                    item: item,
                    mId: item[0]?.mId,
                    driver:  <img className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item[0]?.db_name)[0]?.value?.logo} alt="" />,
                    productName: item[0]?.product_name,
                    id: item[0]?.id ,
                    date: moment(item[0]?.created_at).format("jYYYY/jM/jD")
                  }
                  );
                }
                )}
                enableRowNumbers={true}
                rowNumberMode="static"
                enablePagination={false}
                muiTableHeadCellProps={{
                  sx: {
                    border: "1px solid #e5e5e5",
                    height: "fit-content",
                    textAlign:"center",
                    justifyContent:"center",
                  },
                }}
                renderBottomToolbarCustomActions={({table})=>{
                  return (
                    <div className="custom-pagination w-100">
                       <Pagination  page={page} count={relatedProductsLis?.total ? Math.ceil(relatedProductsLis?.total / rowCount) : 1} onChange={this.handleChangePage} />
                      <DropDownIcon
                      value={rowCount}
                      optionList={rowCountList}
                      title={""}
                      onChange={(event) => { this.handlePaginationSelect(event)}
                    }
                    />
                    </div>
                  );
                }}
                muiTableBodyRowProps={( {row,staticRowIndex} ) => ({
                  sx:{
                    height: "fit-content",
                    backgroundColor: (staticRowIndex % 2 === 0 ) ? '#f3f3f3'  : "",
                }})}
                state={{ isLoading: loading, showProgressBars: loading }}
                positionExpandColumn={'first'}
                enableGlobalFilter={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                muiTableProps={{
                  sx: {
                    border: "1px solid #e5e5e5",
                  },
                }}
                muiTableBodyCellProps={{
                  sx: {
                    height:"fit-content",
                    border: "1px solid #e5e5e5",
                    textAlign:"center"
                  },
                }}
                enableHiding={false}
                renderTopToolbarCustomActions={({ table }) => (
                  <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap', justifyContent:"space-between", width:"100%" }}
                  >
                    <CustomButton
                      loading={false}
                      myStyle="excel-btn"
                      onClick={() => exportToExcel(relatedProductsLis?.data, "Excel Export")}
                      title="اکسل"
                      icon={<AiOutlineFileExcel />}
                    />
                    <Tooltip title="نمایش فیلترها"><span> <TfiFilter className='filter-icon' onClick={() => this.setState(prevState => ({
                                  showFilters: !prevState.showFilters
                                }))
                              }/></span></Tooltip>
                    <div className='custom-pagination'>
                      <Pagination  page={page} count={relatedProductsLis?.total ? Math.ceil(relatedProductsLis?.total / rowCount) : 1} onChange={this.handleChangePage} />
                  <DropDownIcon
                  value={rowCount}
                  optionList={rowCountList}
                  title={""}
                  onChange={(event) =>
                    {
                      this.handlePaginationSelect(event)
                  }
                }
                />
                    </div>
                  </Box>
                )}
                enableColumnActions={false}
                renderDetailPanel={({ row }) => (
                  <div className="detail-container">
                  {row?.original?.item && row?.original?.item[0]?.relationship_products.length === 0 ?
                         <div className="error-text">داده‌ای برای نمایش وجود ندارد!</div>
                         :
                         <table className="table text-center">
                      <thead>
                        <tr >
                          <th scope="col">ردیف</th>
                          <th scope="col">شناسه</th>
                          <th scope="col">سایت</th>
                          <th scope="col">نام محصول</th>
                          <th scope="col">عملیات</th>
                        </tr>
                      </thead>
                      <tbody>
                  
                      {row?.original?.item && row?.original?.item[0]?.relationship_products.filter((i:any)=> (i.db_name !== row?.original?.item[0]?.db_name)).map((item: any, index: number) => {
                          return(

                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.id}</td>
                              <td><img className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo}alt="" /></td>
                              <td>{item.product_name}</td>
                              <td>
                              {item?.db_name === siteList[0]?.value?.name ? <Tooltip title="حذف کلی"><span> <RiDeleteBin6Line  className="icon-red" onClick={() => this.handleOpenMultiple(row.original?.item[0])} /></span></Tooltip> :
                             <Tooltip title="غیر مرتبط‌کردن"><span> <TfiUnlink className='icon-orange' onClick={() => this.handleOpenSingle(item)} /></span></Tooltip> }
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    }
                  </div>
                )}
                initialState={{
                  pagination : {pageSize:100, pageIndex:0},    sorting: [
                    {
                      id: 'id',
                      desc: true,

                    },
                  ],
                }}
                displayColumnDefOptions={{
                  'mrt-row-expand': {
                    muiTableHeadCellProps: {
                      align: 'center',
                    },
                  },
                }}
                enableRowActions
                localization={{
                  actions: 'عملیات',
                  noRecordsToDisplay:"داده‌ای برای نمایش وجود ندارد!",
                  
                }}
                renderRowActions={({ row }) => [
                  <div key={row && row?.original && row?.original?.item && row?.original?.item[0] && row?.original?.item[0]?.id} className='detail-container'>
                      {row?.original?.item[0].db_name !== siteList[0]?.value?.name && <Tooltip title="غیر مرتبط‌کردن"><span> <TfiUnlink  className="icon-orange" onClick={() => this.handleOpenSingle(row.original?.item[0])} /></span></Tooltip>}
                      {row?.original?.item[0].db_name === siteList[0]?.value?.name && <Tooltip title="حذف کلی"><span> <RiDeleteBin6Line  className="icon-red" onClick={() => this.handleOpenMultiple(row.original?.item[0])} /></span></Tooltip>}
                  </div>
                ]}
              />
          </div>
        </div>
      </ThemeProvider>
      </HelmetProvider>
    )
  }
}
const mapStateToProps = (dispatch:any) => {
  return {
    dispatch,
    siteList:dispatch.general.siteList,
    loadingSiteList:dispatch.general.loadingSiteList,
  }
};
export default connect(mapStateToProps)(ProductsRelations)
