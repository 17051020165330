import {ReactElement,} from "react";
import { useState } from "react";
import { AiOutlineFileDone, AiOutlineHome, AiOutlinePlus} from "react-icons/ai";
import {MdEvent , MdOutlineManageAccounts, MdOutlinePermMedia, MdOutlineProductionQuantityLimits, MdOutlineSpeakerNotes} from "react-icons/md";
import { IoIosAddCircleOutline} from "react-icons/io";
import { Link } from "react-router-dom";
import { TbBrandCakephp } from "react-icons/tb";
import { BiCategory, BiSubdirectoryRight } from "react-icons/bi";
import { BsTag } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
 
  interface IMyProps  { 
    details?:any;
    isActive?:string
  
  }
 
  export function SideBar({isActive }: IMyProps): ReactElement {
    const [open, setOpen] = useState({products:false, seo:false});
    return (
      <div className="side-bar">
        <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
        <Link className={isActive === "isAdminHome" ? "item active mt-4" : "item"} to="/admin/home"  >
          <span className="text"><AiOutlineHome className="icon-red"/> داشبورد  </span>
        </Link>
        <span onClick={() => setOpen({...open,products:!open.products})} className="item"><AiOutlinePlus className="icon-red"/> مدیریت محصولات  </span>
      <Collapse in={open.products} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{paddingRight:"20px"}}>
        <Link className={isActive === "isProducts" ? "item active" : "item"} to="/admin/products" >
          <span className="text"><MdOutlineProductionQuantityLimits className="icon-blue"/> لیست محصولات </span>
        </Link>
        <Link className={isActive === "isAdd" ? "item active" : "item"} to="/admin/add-product" >
          <span className="text"><IoIosAddCircleOutline className="icon-green"/> افزودن محصول </span>
        </Link>
        <Link className={isActive === "isCategory" ? "item active" : "item"} to="/admin/categories" >
          <span className="text"><BiCategory className="icon-blue"/>مدیریت دسته‌بندی</span>
        </Link>
        <Link className={isActive === "isTag" ? "item active" : "item"} to="/admin/tags" >
          <span className="text"><BsTag className="icon-orange"/>مدیریت برچسب </span>
        </Link>
        <Link className={isActive === "isBrand" ? "item active" : "item"} to="/admin/brands" >
          <span className="text"><TbBrandCakephp className="icon-red"/>مدیریت برند </span>
        </Link>
        <Link className={isActive === "isMedia" ? "item active" : "item"} to="/admin/media" >
          <span className="text"><MdOutlinePermMedia className="icon-gray"/>رسانه‌ها </span>
        </Link>
        <Link className={isActive === "isRedirect" ? "item active" : "item"} to="/admin/redirect" >
          <span className="text"><BiSubdirectoryRight className="icon-blue"/>مدیریت ریدایرکت‌ها  </span>
        </Link>
        <Link className={isActive === "isUsersNotes" ? "item active" : "item"} to="/admin/note" >
          <span className="text"><MdOutlineSpeakerNotes className="icon-orange"/> یادداشت‌های کاربران </span>
        </Link>
        </List>
      </Collapse>
      <Link className={isActive === "isUsersMangement" ? "item active" : "item"} to="/admin/users" >
          <span className="text"><FiUsers className="icon-yellow"/>مدیریت کاربران </span>
      </Link>
      
      <Link className={isActive === "isWebsiteManagement" ? "item active" : "item"} to="/admin/web-management" >
          <span className="text"><MdOutlineManageAccounts className="icon-green"/>مدیریت وب‌سایت </span>
        </Link>
        <Link className={isActive === "isFileManager" ? "item active" : "item"} to="/admin/filemanager" >
          <span className="text"><AiOutlineFileDone className="icon-dark-green"/>مدیریت فایل </span>
        </Link>
        <Link className={isActive === "isLogs" ? "item active" : "item"} to="/admin/system-logs" >
          <span className="text"><MdEvent className="icon-green"/>رویدادهای سیستم</span>
        </Link>
    </List>
      </div>
    );
  }
  export default SideBar;
  