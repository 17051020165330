
import { LoadingSpinner, Header, InputIcon, CustomButton, ImageUploader, CustomContainer, DropDownIcon, SubmissionAlert } from "../../../components";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { BannerManagementController } from "../../../controllers";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FiAlertTriangle } from "react-icons/fi";

class BannerManagement extends BannerManagementController {
  componentDidMount() {
    this.getPageData();
  }

  render() {
    const { loading ,
        mlider,
        slider,
        toggleSliderModal,
        toggleMliderModal,
        toggleBannerModal,
        linkStatusOptions,
        banner } = this.state;
    return (
      <CustomContainer>
        <Helmet>
          <title> مدیریت بنرها </title>
        </Helmet>
        <Header title=" لیست بنرهای صفحه اصلی " />
        {loading ? <LoadingSpinner /> : 
         <>
          
          <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>  اسلایدر دسکتاپ</Typography>
        </AccordionSummary>
        {toggleSliderModal.status &&
        <SubmissionAlert
                      isOpen={toggleSliderModal.status}
                      title="اخطار!"
                      setClose={(open: boolean) =>
                        this.setState({
                          toggleSliderModal: {
                            status: open,
                          },
                        })
                      }
                      onClick={()=> this.deleteSliderImage(toggleSliderModal.index)}
                      icon={<FiAlertTriangle color="orange" fontSize="50" />}
                      details="از حذف این تصویر اطمینان دارید؟"
                    />
  }
        {slider?.length > 0 && slider.map((item:any, sliderIndex:number) =>{
          return(
        <AccordionDetails>
          <div className="site-slogan-item">
            <ImageUploader onClick={() => this.handleDeleteSliderImage(sliderIndex) } itemIndex={`s-${sliderIndex}`} onChangeImage={(e:any) => this.handleImageUploadSlider(e,sliderIndex)}
             image={item?.image} imageAlt={item?.link_alt} imageSize={item?.size} myStyle="col-md-4 col-sm-12"/>
                <div  className="col-md-8 col-sm-12">
                <InputIcon
                  value={item?.link_alt}
                  myStyle="col-md-12 col-sm-12 pe-3"
                  title=" بنر Alt "
                  inBox=" بنر Alt"
                  type={"text"}
                  name={`s-link-alt-${item?.id}`} 
                  id={`s-${item?.id}`} 
                  errortext={item?.errorsList && item?.errorsList?.filter((obj: any) => obj.path === "filteredObj.link_alt")[0]?.message}
                  onChange={(event) =>
                   this.handleSlider(event, sliderIndex, "link_alt")
                  }
                />
                <InputIcon
                  value={item?.link_url && item?.link_url}
                  myStyle="col-md-12 col-sm-12 pe-3"
                  title="لینک"
                  inBox="لینک"
                  type={"text"}
                  name={"link_url"}
                  onChange={(event) =>
                    this.handleSlider(event, sliderIndex, "link_url")
                  }
                />
                <DropDownIcon
                  value={item?.link_target}
                  optionList={linkStatusOptions}
                  title="وضعیت بازشدن لینک"
                  inBox="وضعیت بازشدن لینک"
                  inputStyle="pe-3"
                  type={"text"}
                  name={"link_target"}
                  onChange={(event) =>
                    this.handleSlider(event, sliderIndex, "link_target")
                  }
                />
                </div>
                <CustomButton
                myStyle="btn-confirm mt-1"
                loading={false}
                onClick={() => this.handleUpdate(item, "slider")}
                title="بروزرسانی "
              />
            </div>
            </AccordionDetails>
         ) })}
        
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography> اسلایدر موبایل </Typography>
        </AccordionSummary>
        {toggleMliderModal.status &&
        <SubmissionAlert
                      isOpen={toggleMliderModal.status}
                      title="اخطار!"
                      setClose={(open: boolean) =>
                        this.setState({
                          toggleMliderModal: {
                            status: open,
                          },
                        })
                      }
                      onClick={()=> this.deleteMliderImage(toggleMliderModal.index)}
                      icon={<FiAlertTriangle color="orange" fontSize="50" />}
                      details="از حذف این تصویر اطمینان دارید؟"
                    />
  }
        {mlider?.length > 0 && mlider.map((item:any, mliderIndex:number) =>{
          return(
        <AccordionDetails>
          <div className="site-slogan-item">
            <ImageUploader itemIndex={`m-${mliderIndex}`} onClick={() => this.handleDeleteMliderImage(mliderIndex) } onChangeImage={(e:any) => this.handleImageUploadMlider(e,mliderIndex)}
             image={item?.image} imageAlt={item?.link_alt} imageSize={item?.size} myStyle="col-md-4 col-sm-12"/>
                <div  className="col-md-8 col-sm-12">
                <InputIcon
                  value={item?.link_alt}
                  myStyle="col-md-12 col-sm-12 pe-3"
                  title=" بنر Alt "
                  inBox=" بنر Alt"
                  type={"text"}
                  name={`m-link-alt-${item?.id}`} 
                  id={`m-${item?.id}`} 
                  errortext={item?.errorsList && item?.errorsList?.filter((obj: any) => obj.path === "filteredObj.link_alt")[0]?.message}
                  onChange={(event) =>
                   this.handleMlider(event, mliderIndex,"link_alt")
                  }
                />
                <InputIcon
                  value={item?.link_url && item?.link_url}
                  myStyle="col-md-12 col-sm-12 pe-3"
                  title="لینک"
                  inBox="لینک"
                  type={"text"}
                  name={"link_url"}
                  onChange={(event) =>
                    this.handleMlider(event, mliderIndex, "link_url")
                  }
                />
                <DropDownIcon
                  value={item?.link_target}
                  optionList={linkStatusOptions}
                  title="وضعیت بازشدن لینک"
                  inBox="وضعیت بازشدن لینک"
                  type={"text"}
                  inputStyle="pe-3"
                  name={"link_target"}
                  onChange={(event) =>
                    this.handleMlider(event, mliderIndex, "link_target")
                  }
                />
                </div>
                <CustomButton
                myStyle="btn-confirm mt-1"
                loading={false}
                onClick={() => this.handleUpdate(item, "mlider")}
                title="بروزرسانی "
              />
            </div>
            </AccordionDetails>
         ) })}
        
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography> بنر های میان صفحه </Typography>
        </AccordionSummary>
        {toggleBannerModal.status &&
        <SubmissionAlert
                      isOpen={toggleBannerModal.status}
                      title="اخطار!"
                      setClose={(open: boolean) =>
                        this.setState({
                          toggleBannerModal: {
                            status: open,
                          },
                        })
                      }
                      onClick={()=> this.deleteBannerImage(toggleBannerModal.index)}
                      icon={<FiAlertTriangle color="orange" fontSize="50" />}
                      details="از حذف این تصویر اطمینان دارید؟"
                    />
  }
        <AccordionDetails>
        {banner?.length > 0 && banner.map((item:any, bannerIndex:number) =>{
          return(

          <div className="site-slogan-item">
            <ImageUploader itemIndex={`b-${bannerIndex}`} onClick={() => this.handleDeleteBannerImage(bannerIndex) }  onChangeImage={(e:any) => this.handleImageUploadBanner(e,bannerIndex)}
             image={item?.image} imageAlt={item?.link_alt} imageSize={item?.size} myStyle="col-md-4 col-sm-12"/>
                <div  className="col-md-8 col-sm-12 pe-3">
                <InputIcon
                  value={item?.link_alt}
                  myStyle="col-md-12 col-sm-12 pe-3"
                  title=" بنر Alt "
                  inBox=" بنر Alt"
                  type={"text"}
                  name={`b-link-alt-${item?.id}`} 
                  id={`b-${item?.id}`}
                  errortext={item?.errorsList && item?.errorsList?.filter((obj: any) => obj.path === "filteredObj.link_alt")[0]?.message}
                  onChange={(event) =>
                   this.handleBanner(event, bannerIndex, "link_alt")
                  }
                />
                <InputIcon
                  value={item?.link_url && item?.link_url }
                  myStyle="col-md-12 col-sm-12 pe-3"
                  title="لینک"
                  inBox="لینک"
                  type={"text"}
                  name={"link_url"}
                  onChange={(event) =>
                    this.handleBanner(event, bannerIndex, "link_url")
                  }
                />
                <DropDownIcon
                  value={item?.link_target}
                  optionList={linkStatusOptions}
                  title="وضعیت بازشدن لینک"
                  inBox="وضعیت بازشدن لینک"
                  type={"text"}
                  name={"link_target"}
                  inputStyle="pe-3"
                  onChange={(event) =>
                    this.handleBanner(event, bannerIndex, "link_target")
                  }
                />
                </div>
                 <CustomButton
                myStyle="btn-confirm mt-1"
                loading={false}
                onClick={() => this.handleUpdate(item, "banner")}
                title="بروزرسانی "
              />
            </div>
            
         ) })}
         </AccordionDetails>
      </Accordion>
      

             
      

      </>
        }
</CustomContainer>
    );
  }
}
const mapStateToProps = (dispatch: any) => {
  return {
    dispatch,
    siteList: dispatch.general.siteList,
    loadingSiteList: dispatch.general.loadingSiteList,
    userData: dispatch.general.userData,
  }
};
export default connect(mapStateToProps)(BannerManagement);
