import { Component } from "react";
import { call_api, parseJSON } from "../../../core/service";
import { AppConstants } from "../../../core/constants";
import { toast } from "react-toastify";
import { createRef } from "react";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

interface IProps { 
    dispatch?:any,
    siteList?:any,
    loadingSiteList?:any,
    userData?:any
  }
  interface IState {
  medialistData: any,
  fileType: string,
  fileExtension: string,
  showFilters:boolean,
  total:number
  rowCount:any,
  rowCountList:any,
  loading: boolean,
  page: number,
  format:any,
  formatList:Array<any>,
  category: string,
  categoryList:Array<any>,
  search:string,
  draggedList:any,



  }
 class MediasController extends Component<IProps, IState> {
    tableInstanceRef: React.RefObject<any>;
    constructor(props: any) {
      super(props);
      this.tableInstanceRef = createRef()
      this.state = {   
      medialistData: [],
      fileType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      fileExtension: ".xlsx",
      showFilters:false,
      total:0,
      rowCount:100,
      rowCountList:[10, 25 , 50 , 100],
      loading: false,
      page: 1,
      format:"",
      formatList:[{ value: "image", label: "تصویر" },
      { value: "video", label: "ویدیو" }, { value: "file", label: "فایل" } ],
      category:"",
      categoryList: [],
      search:"",
      draggedList:[],



        
        

      }
    }
    exportToExcel = async (excelData: any, fileName: any) => {
      const ws = XLSX.utils.json_to_sheet(excelData);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: "array" });
      const data = new Blob([excelBuffer], { type: this.state.fileType });
      FileSaver.saveAs(data, fileName + this.state.fileExtension);
    
    }
    paginationSelectTimeOut: string | number | NodeJS.Timeout | undefined; 
    handlePaginationSelect=(event:any)=>{
      this.setState({
        rowCount: event.currentTarget.value,
        page:1
      })
      clearTimeout(this.paginationSelectTimeOut);
      this.paginationSelectTimeOut = setTimeout(() => {
        this.getMediassList();
      }, 800);
      
    }
    paginationTimeOut: string | number | NodeJS.Timeout | undefined; 
    handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
      this.setState({ page: value })
      clearTimeout(this.paginationTimeOut);
      this.paginationTimeOut = setTimeout(() => {
        this.getMediassList();

    }, 800);
      
    }
    formatTimeout: string | number | NodeJS.Timeout | undefined;
    formatSelected = (choice: any) => {
      this.setState({ format: choice });
      clearTimeout(this.formatTimeout);
      this.formatTimeout = setTimeout(() => {
        this.getMediassList();
      }, 1000);
    }
    categoryTimeout: string | number | NodeJS.Timeout | undefined;
    categorySelected = (choice: any) => {
      this.setState({ category: choice });
      clearTimeout(this.categoryTimeout);
      this.categoryTimeout = setTimeout(() => {
        this.getMediassList();
      }, 1000);

    }
    handleCategorySearch= (choice:any) =>{
      clearTimeout(this.categoryTimeout);
      if(choice === "")
      {
        this.getCategorySearch()
      }
      if(choice.length > 2)
      this.categoryTimeout = setTimeout(() => {
        this.getCategorySearch(choice)
      }, 1000);
    }
    async getCategorySearch(search?:string) {
      let parameters = {
        search: search ? search: null,
        is_search : JSON.stringify(1)
      }
      let url = new URL(`${AppConstants.base_url_api}admin/categories`)
      for (const [key, value] of Object.entries(parameters)) {
        if (value) {
          url.searchParams.append(key, value)
        }
      }
      call_api({
        address_api: url.search ? `${AppConstants.base_url_api}admin/categories${url.search}` : `${AppConstants.base_url_api}admin/categories`,
        method_api: "GET"
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            if (j.data) {
              this.setState({
                categoryList: j.data
              })
            }
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      };
      searchTimeout: string | number | NodeJS.Timeout | undefined;
      handleSearch = (event: any) => {
        this.setState({ search: event.currentTarget.value })
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          this.getMediassList();
        }, 1000);
      };
    async getMediassList() {
        const { page, rowCount, category, format} = this.state;
        this.setState({ loading: true })
        
        // const usersSelectedList = [];
        // for (var a = 0; a < category?.length; a++) {
        //   usersSelectedList.push(category[a]?.id)
        //   // this.setState({ usersUuid: usersSelectedList })
        // }
        // const categoryNameList = [];
        // for (var b = 0; b < category?.length; b++) {
        //   categoryNameList.push(category[b]?.english_name)
        // }
        // const driversSelectedList = [];
        // for (var c = 0; c < dreiverId.length; c++) {
        //   driversSelectedList.push(dreiverId[c]?.value?.name)
        //   // this.setState({ usersUuid: driversSelectedList })
        // }
        // const brandList = [];
        // for (var d = 0; d < brandId.length; d++) {
        //   brandList.push(brandId[d]?.id)
        // }
        // const brandNameList = [];
        // for (var e = 0; e < brandId.length; e++) {
        //   brandNameList.push(brandId[e]?.english_name)
        // }
        // const statusSelectedList = [];
        // for (var f = 0; f < statusId.length; f++) {
        //   statusSelectedList.push(statusId[f]?.value)
        // }
        // const emptySelectedList = [];
        // for (var g = 0; g < emptyId.length; g++) {
        //   emptySelectedList.push(emptyId[g]?.value)
        // }
        // const tagsList = [];
        // for (var h = 0; h < tag.length; h++) {
        //   tagsList.push(tag[h]?.id)
        // }
        // const tagsNameList = [];
        // for (var i = 0; i < tag.length; i++) {
        //   tagsNameList.push(tag[i]?.english_name)
        // }
    
        let parameters = {
          // brand_id: ((Number(syncStatus2) === 1 || Number(syncStatus) === 1) && brandNameList.length > 0) ? brandNameList :   ((Number(syncStatus2) !== 1 || Number(syncStatus) !== 1) && brandList?.length > 0 )? brandList : null,
          // search: search ? search : null,
          // status: statusId?.length > 0 ? statusSelectedList : null,
          // empty: emptyId?.length > 0 ? emptySelectedList : null,
          page: page ? page.toString() : null,
          paginate: rowCount,
          format : format?.value ? format?.value : null,
          // is_sync:(showSyncFilter && Number(syncStatus2) !== null) ? Number(syncStatus2) : (!showSyncFilter && Number(syncStatus) !== null) ? Number(syncStatus) : null,
          // is_delete:1,
          // hide_sync:hideSync,
          // tag_id: ((Number(syncStatus2) === 1 || Number(syncStatus) === 1) && tagsNameList.length > 0) ? tagsNameList :   ((Number(syncStatus2) !== 1 || Number(syncStatus) !== 1) && tagsList?.length > 0 )? tagsList : null,
          category_id: category ? category : null,
          // db_name: dreiverId?.length > 0 ? driversSelectedList : null,
        }
        let url = new URL(`${AppConstants.base_url_api}admin/media`)
        for (const [key, value] of Object.entries(parameters)) {
          if (value) {
            url.searchParams.append(key, value.toString())
          }
        }
        call_api({
          address_api: url.search ? `${AppConstants.base_url_api}admin/media${url.search}` : `${AppConstants.base_url_api}admin/media`,
          method_api: "GET"
        })
          .then(parseJSON)
          .then(([status, j]) => {
            if (status === 200) {
              this.setState({
                medialistData: j?.data?.data ,loading: false , total: j.data?.meta?.total
              })
            } else if (status === 401) {
              this.setState({ loading: false })
              localStorage.clear();
            } else {
              this.setState({ loading: false })
              toast.error(j?.errors);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      };
    
}

export default MediasController;