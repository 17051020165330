import { Component, createRef } from 'react';
import { call_api, parseJSON } from "../../../core/service";
import { toast } from 'react-toastify';
import { AppConstants } from '../../../core/constants';
import * as FileSaver from 'file-saver';
import * as yup from 'yup'
import XLSX from 'sheetjs-style';
interface IProps {
  dispatch?:any,
  siteList?:any,
  loadingSiteList?:any,
}
interface IState {
  loading?: boolean,
  page: number,
  search: string,
  openCommentsModal: boolean,
  step: number,
  comment: string,
  commentImage?: any,
  commentImageUpload?: any,
  ticketDataList: any,
  currentItem: any,
  isCreating: boolean,
  errorsList?: any,
  dreiverId: any;
  toggleModal: any,
  rowData: any,
  rowCount:any,
  rowCountList:any,
  paginate: number,
  showFilters:boolean,
  total:number,

}

 class SupportTicketController extends Component<IProps, IState> {
  editorRef: React.RefObject<any>;
  myRef:any;
  tableInstanceRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    this.editorRef = createRef();
    this.myRef = createRef();
    this.tableInstanceRef = createRef()
    this.state = {
      loading: false,
      page: 1,
      search: "",
      openCommentsModal: false,
      step: 0,
      comment: "",
      commentImage: "",
      commentImageUpload: "",
      ticketDataList: [],
      currentItem: [],
      isCreating: false,
      errorsList: [],
      dreiverId: [],
      toggleModal: { status: false},
      rowData: {},
      rowCount:100,
      rowCountList:[10, 25 , 50 , 100],
      paginate: 0,
      showFilters:false,
      total: 0,

    }
  }
  paginationSelectTimeOut: string | number | NodeJS.Timeout | undefined; 
  handlePaginationSelect=(event:any)=>{
    const { step } = this.state;
    this.setState({
      rowCount: event.currentTarget.value,
      page:1
    })
    clearTimeout(this.paginationSelectTimeOut);
    this.paginationSelectTimeOut = setTimeout(() => {
      this.getTicketDataList(step)
    }, 800);
    
  }
  paginationTimeOut: string | number | NodeJS.Timeout | undefined; 
  handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ page: value })
    clearTimeout(this.paginationTimeOut);
    this.paginationTimeOut = setTimeout(() => {
      this.getTicketDataList(this.state.step)  
    }, 800);
  }
  searchTimeout: string | number | NodeJS.Timeout | undefined;
  handleSearch = (event: any) => {
    const { step } = this.state;
    this.setState({ search: event.currentTarget.value })
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.getTicketDataList(step)
    }, 1000);
  };
  driverTimeout: string | number | NodeJS.Timeout | undefined;
  driverSelected = (choice: any) => {
    const { step } = this.state;
    this.setState({ dreiverId: choice, page:1 })
   
    clearTimeout(this.driverTimeout);
    this.driverTimeout = setTimeout(() => {
      this.getTicketDataList(step)
    }, 1000);
  }
  changeStepTimeout: string | number | NodeJS.Timeout | undefined;
  handleSetStep = (step: number) => {
    this.setState({ search: "", page: 1, step: step })

    clearTimeout(this.changeStepTimeout);
    this.changeStepTimeout = setTimeout(() => {
      this.getTicketDataList(step)
    }, 1000);
  };
  async getTicketDataList(step: number) {
    const { search , dreiverId,rowCount,page} = this.state;
    this.setState({ loading: true })
    let parameters = {
      search: search ? search : null,
      status: step === 0 ? null : step,
      page: page ? page.toString() : null,
      db_name: dreiverId?.value?.name ,
      paginate: rowCount,
    }
    let url: any = new URL(`${AppConstants.base_url_api}seller/tickets`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}seller/tickets${url.search}` :
        `${AppConstants.base_url_api}seller/tickets`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {

          this.setState({
            ticketDataList: j.data, loading: false, total: j.data?.tickets?.total 
          })
        } else if (status === 401) {
          this.setState({ loading: false })
          localStorage.clear();
        } else {
          this.setState({ loading: false })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  handleDeleteFile = () => {
    this.setState({
      commentImage: "", commentImageUpload: ""
    })
  }
  async handleImageUpload(event: any) {
    let news_image_file = null;
    news_image_file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(news_image_file);
    reader.onloadend = () => {
      this.setState({ commentImage: reader.result });
    }
    if (news_image_file) {
      this.setState({ commentImageUpload: news_image_file });

    }

  }
  handleCloseCommentsModal = () => {
    this.setState({ openCommentsModal: false })
  }
  async handleSendComment(isClose: string, sendComment: boolean) {
    const { comment, currentItem, commentImageUpload, step } = this.state;
    const commentSchema = yup.object({
      comment: yup.string().when("$condition", (condition, schema) =>
        sendComment ? schema.required(" متن نظر الزامی می باشد ").max(255, " متن نظرات شما باید حداکثر 255 کاراکتر باشد").min(10, " متن نظرات شما باید حداقل 10 کاراکتر باشد") : schema,
      )
    });

    try {
      this.setState({ errorsList: [] })
      await commentSchema.validate({ comment }, { abortEarly: false });
      this.setState({ isCreating: true })
      const data = new FormData()
      data.append('parent_id', currentItem.id)
      sendComment && data.append('message', comment) 
      data.append('db_name', currentItem.db_name)
      commentImageUpload && data.append('files', commentImageUpload)
      data.append('is_close', isClose)
      data.append('user_id', currentItem.user_id)
      call_api({
        address_api: `${AppConstants.base_url_api}seller/tickets`,
        method_api: "POST",
        body: data,
        file: true,
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            toast.success(j?.message);
            this.setState({ openCommentsModal: false, comment: "", isCreating: false,
             commentImage: "", commentImageUpload: ""})
            this.getTicketDataList(step)
          } else if (status === 422) {
            toast.error(j?.errors);
            this.setState({ isCreating: false })
          }
          else if (status === 401) {
            toast.error(j?.message);
            localStorage.clear();
            this.setState({ isCreating: false })
          } else {
            toast.error(j?.message);
            this.setState({ isCreating: false })
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (err: any) {
      this.setState({ isCreating: false, errorsList: err.inner })
    }
  }
  async handleClose(){
    const { rowData } = this.state;
    const { step } = this.state;
    call_api({
      address_api: `${AppConstants.base_url_api}seller/tickets/${rowData.id}`,
      method_api: "PATCH",
      body: JSON.stringify({
        db_name: rowData.db_name,
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          toast.success(j?.message);
          this.setState({
            toggleModal: { status: !this.state.toggleModal.status }
          })
          this.getTicketDataList(step)
        } else if (status === 422) {
          toast.error(j?.errors);
        }
        else if (status === 401) {
          toast.error(j?.message);
          localStorage.clear();
        } else {
          toast.error(j?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async showModal(item: any){
    this.setState({ rowData: item })
    this.setState({
      toggleModal: {
        status: !this.state.toggleModal.status,
      }
    })
  }     
  modalSelectTimeOut: string | number | NodeJS.Timeout | undefined; 
  handleComment = (item: any) => {
    this.setState({ currentItem: item, openCommentsModal: true })
    clearTimeout(this.modalSelectTimeOut);
    this.modalSelectTimeOut = setTimeout(() => {
      this.myRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 800);
  }
}


export default SupportTicketController;