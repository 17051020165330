
import {FileMangementController} from "../../../controllers";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import {Header, InputTextLabel } from "../../../components";
import { createTheme, ThemeProvider } from "@mui/material";
import FileManager, {
  Permissions, Toolbar, ContextMenu, Item, FileSelectionItem, ItemView, Details, Column,
} from 'devextreme-react/file-manager';
 
import {
  Popup
} from 'devextreme-react/popup';
import { fileItems } from './data.js';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
class FileManagement extends FileMangementController {

  componentDidMount() {
   window.scrollTo(0, 0);

  }
  render() {
    const theme = createTheme({
        typography: {
          fontFamily: "Vazir",
        },
      });
    //   const remoteFileProvider = new RemoteFileSystemProvider({
    //     endpointUrl: 'https://js.devexpress.com/Demos/Mvc/api/file-manager-file-system-scripts'
    // });
   
    const { isPopupVisible} = this.state;
   
    return (
      <ThemeProvider theme={theme}>
        <div className="media " style={{margin:"100px"}}>
          <Helmet>
            <title>مدیریت فایل</title>
          </Helmet>
          <Header isActive = "isFileManager" title="مدیریت فایل"/>
          <div className="" >
          <Popup
                visible={isPopupVisible}
                hideOnOutsideClick={true}
                onHiding={()=>this.setState({isPopupVisible:false})}
                position="center"
                dragEnabled={false}
                width={400}
                height={400} 

            >  
            <InputTextLabel placeholder="type something" type="text" onChange={(e:any)=>this.setState({search: e.target.value})}/>          
              </Popup>
            <FileManager
          ref={this.fileManagerRef}
        fileSystemProvider={fileItems}
        onContextMenuItemClick={this.onItemClick}
        height={450} > 
        {/* rtlEnabled="true" */}
        <Permissions
          create={true}
          delete={true}
          rename={true}
          download={true}
          upload={true}
          copy={true}
          move={true}>
        </Permissions>
        <ItemView showParentFolder={false}>
          <Details>
            <Column dataField="thumbnail"></Column>
            <Column dataField="name"></Column>
            <Column dataField="category" caption="Category" width="95"></Column>
            <Column dataField="dateModified"></Column>
            <Column dataField="size"></Column>
          </Details>
        </ItemView>
        <Toolbar>
          <Item name="showNavPane" visible={true} />
          <Item name="separator" />
          <Item name="create" />
          <Item widget="dxMenu" location="before" options={this.newFileMenuOptions} />
          <Item name="refresh" />
          <Item name="separator" location="after" />
          <Item name="switchView" />
          <Item location="after" text="search" icon="find" onClick={()=> this.setState({isPopupVisible: true})}/>
        
          <FileSelectionItem name="rename" />
          <FileSelectionItem name="separator" />
          <FileSelectionItem name="delete" />
          <FileSelectionItem name="separator" />
          <FileSelectionItem widget="dxMenu" location="before" options={this.changeCategoryMenuOptions} />
          <FileSelectionItem name="refresh" />
          <FileSelectionItem name="clearSelection" />
          <FileSelectionItem name="download" />
          <FileSelectionItem name="upload" />
          <FileSelectionItem name="copy" />
          <FileSelectionItem name="move" />
        </Toolbar>
        <ContextMenu>
          <Item name="create" />
          <Item text="Create new file" icon="plus">
            <Item text="Text Document" extension=".txt" />
            <Item text="RTF Document" extension=".rtf" />
            <Item text="Spreadsheet" extension=".xls" />
          </Item>
          <Item name="rename" beginGroup="true" />
          <Item name="delete" />
          <Item text="Category" icon="tags" beginGroup="true">
            <Item text="Work" category="Work" />
            <Item text="Important" category="Important" />
            <Item text="Home" category="Home" />
            <Item text="None" category="" />
          </Item>
          <Item name="refresh" />
        </ContextMenu>
      </FileManager>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}
const mapStateToProps = (dispatch: any) => {
  return {
    dispatch,
    siteList: dispatch.general.siteList,
    loadingSiteList: dispatch.general.loadingSiteList,
  };
};
export default connect(mapStateToProps)(FileManagement);
