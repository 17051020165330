import { Component } from 'react';
import { call_api, parseJSON } from '../../../core/service';
import { AppConstants } from '../../../core/constants';
import { toast } from 'react-toastify';
import * as yup from 'yup'
interface IProps {
dispatch?:any,
siteList?:any,
loadingSiteList?:any,
userData?:any
}
interface IState {
  loading?: boolean,
  slug:string;
  noIndex:boolean;
  title: string,
  titleDesc: string,
  desc: string,
  seoCanonical: string,
  isPublished:boolean;
  header:string;
  errorsList:any;
  seoTitle:string;
  seoDesc:string;
  search?:string;
  page: number,
  total:number
  rowCount:any,
  rowCountList:any,
  dataList:any;
  loadingCreate?:boolean
  toggleTab: string;
  toggleModal:boolean;
  currentItem:any
  
}

export class PageManagementController extends Component<IProps, IState> {
  state: IState = {
    loading: false,
  errorsList:[],
  search:"",
  page: 1,
  total:0,
  rowCount:100,
  rowCountList:[10, 25 , 50 , 100],
  dataList:[],
  slug:"",
  noIndex:false,
  title: "",
  titleDesc: "",
  desc: "",
  seoCanonical: "",
  seoTitle:"",
  seoDesc:"",
  header:"",
  isPublished:false,
  loadingCreate:false,
  toggleTab: "0",
  toggleModal:false,
  currentItem:null
  };


  async getPagesList() {
    const {rowCount} = this.state;
    
    const { search,page } = this.state;
    this.setState({ loading: true })
    let parameters = {
      search: search ? search : null,
      page: page ? page.toString() : null,
      paginate: rowCount,
      db_name: window.location.pathname.split("/")[3],

    }
    let url: any = new URL(`${AppConstants.base_url_api}admin/pages`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}admin/pages${url.search}` :
        `${AppConstants.base_url_api}admin/pages`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
            dataList: j.data?.data
          })
          this.setState({ loading: false })
        } else if (status === 401) {
          this.setState({ loading: false })
          localStorage.clear();
        } else {
          this.setState({ loading: false })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

 async create() {
  const { 
  slug,
  noIndex,
  title,
  header,
  desc,
  titleDesc,
  seoCanonical,
  isPublished,
  seoTitle,
  seoDesc,
} = this.state;
  const updateProfileSchema = yup.object({
    header: yup.string().required(" هدر  الزامی می باشد  ").max(255, 'کد ورود بیشتر از 255 رقم نمی باشد'),
    slug: yup.string().required(" نامک الزامی می باشد ").max(255, 'کد ورود بیشتر از 255 رقم نمی باشد'),
    title : yup.string().required(" عنوان الزامی می باشد ").max(255, 'کد ورود بیشتر از 255 رقم نمی باشد'),
    desc: yup.string(),
    titleDesc: yup.string(),
    seoCanonical: yup.string().max(255, 'کد ورود بیشتر از 255 رقم نمی باشد'),
  });

  try {

    await updateProfileSchema.validate({ 
      slug,
      noIndex,
      title,
      header,
      desc,
      titleDesc,
      seoCanonical,
      isPublished 
      
    }, { abortEarly: false });
    this.setState({ loadingCreate: true })
    const data = new FormData()
    data.append('header', header)
    data.append('title', title)
    data.append('desc_title', titleDesc)
    data.append('description', desc)
    data.append('published', isPublished ? "1" : "0")
    data.append('no_index', noIndex ? "1" : "0" )
    data.append('slug', slug)
    data.append('seo_canonical', seoCanonical)
    data.append('seo_title', seoTitle)
    data.append('seo_description', seoDesc)
    data.append('db_name', window.location.pathname.split("/")[3])

    call_api({
      address_api: `${AppConstants.base_url_api}admin/pages`,
      method_api: "POST",
      body: data,
      file: true,
    })
      .then(parseJSON)
      .then(([status, j]) => {
        this.setState({ errorsList: [] })
        if (status === 200) {
          toast.success(j?.message);
          this.getPagesList();
          this.setState({loadingCreate:false,   slug:"",
          noIndex:false,
          title: "",
          titleDesc: "",
          desc: "",
          seoCanonical: "",
          header:"",
          seoTitle:"",
          seoDesc:"",
          toggleTab:"0",
          isPublished:false, })
        } else if (status === 401) {
          this.setState({ loadingCreate: false })
          toast.error(j?.message);
          localStorage.clear();
        } else {
          console.warn(status, j);
          this.setState({ loadingCreate: false })
          toast.error(j?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });

  } catch (err: any) {
    this.setState({ loadingCreate: false, errorsList: err.inner  })
  }

}
 changeUpdate = (event: any,index: any,name:string) => {
  const newDetails = [...this.state.dataList];
  if(name === "published"){
    const isPublish: any = newDetails[index].published
    isPublish.name = event === true ? "1" : "0"
    
  }else if(name === "no_index"){
    newDetails[index].no_index = event === true? "1" : "0"
  }else{
    newDetails[index][name] = event
  }
  this.setState({
    dataList: newDetails
    })
 }
 async update(item:any) {


  const redirectsSchema = yup.object().shape({
    item: yup.object().shape({
      // header: yup.string().required(" هدر  الزامی می باشد  ").max(255, 'کد ورود بیشتر از 255 رقم نمی باشد'),
      // slug: yup.string().required(" نامک الزامی می باشد ").max(255, 'کد ورود بیشتر از 255 رقم نمی باشد'),
      // title : yup.string().required(" عنوان الزامی می باشد ").max(255, 'کد ورود بیشتر از 255 رقم نمی باشد'),
      // seo_description: yup.string(),
      // desc_title: yup.string(),
      // seo_canonical: yup.string().max(255, 'کد ورود بیشتر از 255 رقم نمی باشد'),
      })
});
try {
    
  await redirectsSchema.validate({ item }, { abortEarly: false });

  call_api({
    address_api: `${AppConstants.base_url_api}admin/pages/${item.id_select}`,
    method_api: "PUT",
    body: JSON.stringify({

      header:item.header,
      title:item.title,
      desc_title:item.desc_title,
      description:item.description,
      published:item.published.name,
      no_index:item?.no_index,
      slug:item?.slug,
      seo_canonical:item?.seo_canonical,
      seo_title:item?.seo_title,
      seo_description:item?.seo_description,
      db_name:item?.dreiverId?.value?.name

    })
  })
    .then(parseJSON)
    .then(([status, j]) => {
      if (status === 200) {
        toast.success("با موفقیت انجام شد ");
      } else if (status === 401) {
        localStorage.clear();
      } else {
        toast.error(j?.errors);
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }catch (err: any) {


    // const ids = {...item};
    //   ids.errorsList = err.inner;
    //   this.setState({ item: ids});   


    // let ids = [...compressedData];
    // ids.filter((item:any) => item.dbName === step)[0].errorsList = err.inner;
    // this.setState({ compressedData: ids});}


  }
}

      handleDeleteModal = (item:any) => {

        this.setState({
          toggleModal: true,
          currentItem:item
        })
      }
      async handleDelete() {
        const { currentItem } = this.state;
        call_api({
          address_api: `${AppConstants.base_url_api}admin/pages/${currentItem.id_select}`,
          method_api: "DELETE"
        })
          .then(parseJSON)
          .then(([status, j]) => {
            if (status === 200) {
            toast.success(j?.message);
              this.setState({
                toggleModal: false
              })
              this.getPagesList();
      
            } else if (status === 401) {
              localStorage.clear();
            } else {
              toast.error(j?.errors);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      };
       
}

export default PageManagementController;