import { Component } from "react";
import { call_api, parseJSON } from "../../../core/service";
import { AppConstants } from "../../../core/constants";
import { toast } from "react-toastify";
import { createRef } from "react";
import { alertButtonsState } from "../../../core/utils";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import * as yup from 'yup'

interface IProps {
  open?: boolean,
  dispatch?: any,
  siteList?: any,
  loadingSiteList?: any,
}
interface IState {
  search: string;
  loading: boolean;
  toggleModal: any;
  rowData: any;
  selectedList: any;
  paginate: number;
  page: number;
  total: number;
  rowCount: any;
  rowCountList: any;
  showFilters: boolean;
  showSyncFilter: any;
  tagList: any;
  fileType: string;
  fileExtension: string;
  loadingSiteList?: boolean;
  dreiverId: any;
  allOption: any;
  details: Array<any>;
  updateDetails: any;
  showAddNewItem: boolean;
  showUpdateItem: boolean;
  faq?: any;
  step?: string;
  detailPannelData:any,
  children:any,
  selectedRowIndex:number,
}
 class CategoriesController extends Component<IProps, IState> {
  tableInstanceRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    this.tableInstanceRef = createRef()
    this.state = {
      rowData: {},
      dreiverId: [],
      loading: false,
      search: "",
      toggleModal: { status: false, buttonState: "" },
      paginate: 0,
      fileType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      fileExtension: ".xlsx",
      page: 1,
      total: 0,
      selectedList: [],
      rowCount: 100,
      rowCountList: [10, 25, 50, 100],
      showFilters: false,
      showSyncFilter: null,
      tagList: [],
      loadingSiteList: false,
      allOption: {
        id: 0, value: {
          persian_name: "همه",
          name: "*"
        }
      },
      details: [{
        errorsList:[],
        persianName: "",
        englishName: "",
        slug: "",
        seoTitle: "",
        seoDesc: "",
        description: "",
        parent: "",
        url: "",
        noIndex: false,
        showFaq: false,
          faq: [{ question: "", answer: "" }],
          dbName: "aria",
        image: "",
        uploadImage: "",
        }],
      updateDetails: {
        id: "",
        idSelect:"",
        persianName: "",
        englishName: "",
        slug: "",
        seoTitle: "",
        seoDesc: "",
        description: "",
        parent: "",
        url: "",
        noIndex: false,
        showFaq: false,
        faq: [{ question: "", answer: "" }],
        dbName: "aria",
        image: "",
        uploadImage: "",
        errorsList:[],

      },
      showAddNewItem: false,
      showUpdateItem: false,
      detailPannelData:null,
      children:{},
      selectedRowIndex:101,
      step: "aria"

    }
  }
  handleRecycle = (event: any) => {
    this.setState({ rowData: event })
    this.setState({
      toggleModal: {
        status: !this.state.toggleModal.status,
        buttonClick: alertButtonsState.RECYCLE
      }
    })
  }
  handleDeleteModal = (event: any) => {
    this.setState({ rowData: event })
    this.setState({
      toggleModal: {
        status: !this.state.toggleModal.status,
        buttonClick: alertButtonsState.DELETE
      }
    })
  }
  handleNoIndex = (event: any) => {
    this.setState({ rowData: event })
    this.setState({
      toggleModal: {
        status: !this.state.toggleModal.status,
        buttonClick: alertButtonsState.NOINDEX
      }
    })
  }
  searchTimeout: string | number | NodeJS.Timeout | undefined;
  handleSearch = (event: any) => {
    this.setState({ search: event.currentTarget.value })
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.getList()
    }, 1000);
  };
  paginationTimeOut: string | number | NodeJS.Timeout | undefined;
  handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ page: value })
    clearTimeout(this.paginationTimeOut);
    this.paginationTimeOut = setTimeout(() => {
      this.getList()
    }, 800);
  }
  paginationSelectTimeOut: string | number | NodeJS.Timeout | undefined;
  handlePaginationSelect = (event: any) => {
    this.setState({
      rowCount: event.currentTarget.value,
      page:1
    })
    clearTimeout(this.paginationSelectTimeOut);
    this.paginationSelectTimeOut = setTimeout(() => {
      this.getList()
    }, 800);
  }
  async getList() {
    const { page, search, rowCount, dreiverId } = this.state;
    this.setState({ loading: true })
    const driversSelectedList = [];
    for (var i = 0; i < dreiverId.length; i++) {
      driversSelectedList.push(dreiverId[i]?.value?.name)
    }
    let parameters = {
      search: search ? search : null,
      page: page ? page.toString() : null,
      paginate: rowCount,
      db_name: dreiverId?.length > 0 ? driversSelectedList : null,
    }
    let url = new URL(`${AppConstants.base_url_api}admin/categories`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}admin/categories${url.search}` : `${AppConstants.base_url_api}admin/categories`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(async ([status, j]) => {
        if (status === 200) {
          if (j.data) {
            this.setState({
              tagList: j.data?.data?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null), loading: false, total: j.data?.total
            })
          }
        } else if (status === 401) {
          this.setState({ loading: false })
          localStorage.clear();
        } else {
          this.setState({ loading: false })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  driverTimeout: string | number | NodeJS.Timeout | undefined;
  handleChange = (selected: any, index?: any): any => {
    this.setState({ page: 1 })
    if (index?.option?.id === 0) {
      this.setState({
        dreiverId: this.props.siteList
      });
    }
    else
      this.setState({
        dreiverId: selected
      });
    clearTimeout(this.driverTimeout);
    this.driverTimeout = setTimeout(() => {
      this.getList()
    }, 1000);
  };
  //Single
  async handleDelete() {
    const { rowData } = this.state;
    call_api({
      address_api: `${AppConstants.base_url_api}admin/categories/${rowData.id_select}`,
      method_api: "DELETE"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          toast.success(j?.message);
          this.setState({
            toggleModal: { status: !this.state.toggleModal.status, buttonClick: alertButtonsState.DELETE }
          })
          this.getList();
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  async handleChangeIndex() {
    const { rowData } = this.state;
    call_api({
      address_api: `${AppConstants.base_url_api}admin/categories/no-index/${rowData.id_select}`,
        method_api: "PUT",
        body: JSON.stringify({
          no_index: rowData?.no_index === 0 ? "1" : "0"
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            this.setState({
              toggleModal: { status: !this.state.toggleModal.status, buttonClick: alertButtonsState.NOINDEX }
            })
            this.getList();
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    async handleRecovery() {
      const { rowData } = this.state;
      call_api({
        address_api: `${ AppConstants.base_url_api}admin/categories/recovery/${rowData.id_select}`,
        method_api: "PUT",
        body: JSON.stringify({
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            toast.success(j.message);
            this.getList();
            this.setState({
              toggleModal: { status: !this.state.toggleModal.status, buttonClick: alertButtonsState.RECYCLE }
            })
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j.message);
            this.setState({
              toggleModal: { status: !this.state.toggleModal.status, buttonClick: alertButtonsState.RECYCLE }
            })
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    exportToExcel = async (excelData: any, fileName: any) => {
      const ws = XLSX.utils.json_to_sheet(excelData);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: "array" });
      const data = new Blob([excelBuffer], { type: this.state.fileType });
      FileSaver.saveAs(data, fileName + this.state.fileExtension);
    }
    setParentsInfo = (event:any) => {
      const newDetails = [...this.state.details];
        newDetails[0]["parent"] = event
      this.setState({
        details: newDetails
        })
    }
    setDetailsInfo = (event:any) => {
    const { details } = this.state;
    const newList = [...details];
    const index = details?.findIndex(
      (obj: any) => obj?.dbName === event.dbName
    );
    newList[index] = event;
    this.setState({
      details: newList,
    });
  };
  setUpdateDetailsInfo = (event: any) => {
    this.setState({
      updateDetails: event
    })
     }
  async create() {
    const { details, step } = this.state;
    let filteredObj = details.filter((item:any) => item.dbName === step)[0];
    const ids = [...details];
    const index = details?.findIndex((obj: any) => obj?.dbName === step)
    const objSchema =  yup.object().shape({
      filteredObj:yup.object().shape({
          persianName:yup.string().required("نام فارسی الزامی  می باشد.").max(255, 'نام فارسی  بیشتر از 255 رقم نمی باشد'),
        englishName: yup.string().required("نام انگلیسی الزامی می باشد.").max(255, 'نام انگلیسی  بیشتر از 255 رقم نمی باشد'),
        slug: yup.string().required("نامک الزامی می باشد.").max(255, 'نامک  بیشتر از 255 رقم نمی باشد'),
        })
  });
   try {
    await objSchema.validate({filteredObj}, { abortEarly: false });
    const index = details?.findIndex((obj: any) => obj?.dbName === step);

    const data = new FormData()
     data.append('persian_name', details[index].persianName)
     data.append('english_name', details[index].englishName)
     data.append('slug', details[index].slug)
     data.append('seo_title', details[index].seoTitle)
     data.append('seo_description', details[index].seoDesc)
     data.append('description', details[index].description)
     data.append('seo_canonical',details[index].url)
     data.append('no_index', `${details[index].noIndex ? 1 : 0}`)
     data.append('showFaq',`${details[index].showFaq ? 1 : 0}`)
     data.append('db_name',details[index].dbName)
     data.append('faq',JSON.stringify(details[index].faq))
     data.append('image',details[index].uploadImage)
     data.append('parent_id',"0")

      call_api({
        address_api: `${AppConstants.base_url_api}admin/categories`,
        method_api: "POST",
        body: data,
          file: true,
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          ids[index].errorsList = []
          ids[index].persianName =""
          ids[index].englishName =""
          ids[index].slug =""
          ids[index].seoTitle =""
          ids[index].seoDesc =""
          ids[index].description =""
          ids[index].url =""
          ids[index].noIndex = false
          ids[index].showFaq = false
          ids[index].faq =[{question:"",answer:""}]
          ids[index].uploadImage = ""


         
      this.setState({ details: ids})
            this.getList();
            toast.success(j?.message);
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      }catch (err: any) {
        ids[index].errorsList = err.inner;
        this.setState({ details: ids});}
    };

    async handleOpenUpdate(item:any) {
      call_api({
        address_api: `${AppConstants.base_url_api}admin/categories/${item.id_select}`,
        method_api: "GET"
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            this.setState({
            showUpdateItem: true,
            updateDetails: {
              id: j.data.id,
              idSelect:j.data.id_select,
              persianName:j?.data?.persian_name,
              englishName: j.data.english_name,
              seoTitle: j.data.seo_title,
              seoDesc: j.data.seo_description,
              description: j.data.description,
              url: j.data.seo_canonical,
              faq:
                j.data.faq?.length > 0
                  ? j.data.faq
                  : [{ question: "", answer: "" }],
              slug: j.data.slug,
              noIndex: j.data.no_index ? true : false,
              showFaq: j.data.showFaq ? true : false,
              dbName: j.data.db_name,
              image:j.data.image,
            },
            rowData: item,
          });
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    handleChildren = (data:any) =>{
  const copy = [...this.state.children]
  for(var i=0; i< data.length; i++){
    copy.push(data[i])
  }
  this.setState({children : copy})
   
  }
  addNewObject = (step: string) => {
    this.setState({ step: step });
    const { details } = this.state;
    const selectedList = [...this.state.details];

    var found = false;
    for (var i = 0; i < details?.length; i++) {
      if (details[i]?.dbName === step) {
        found = true;
        break;
      }
    }
    if (!found) {
      const obj = {
        persianName: "",
        englishName: "",
        slug: "",
        seoTitle: "",
        seoDesc: "",
        description: "",
        parent: "",
        url: "",
        noIndex: false,
        showFaq: false,
        faq: [{ question: "", answer: "" }],
        dbName: step,
        image: "",
        uploadImage: "",
        errorsList:[]
      };
      selectedList.push(obj);
      this.setState({ details: selectedList });
    }
  };
  async update() {
    const { updateDetails, rowData } = this.state;
    const ids = {...updateDetails};
    const filteredObj = yup.object().shape({
      updateDetails: yup.object().shape({
        persianName:yup.string().required("نام فارسی الزامی  می باشد.").max(255, 'نام فارسی  بیشتر از 255 رقم نمی باشد'),
        englishName: yup.string().required("نام انگلیسی الزامی می باشد.").max(255, 'نام انگلیسی  بیشتر از 255 رقم نمی باشد'),
        slug: yup.string().required("نامک الزامی می باشد.").max(255, 'نامک  بیشتر از 255 رقم نمی باشد'),
        })
  });
  
   try {
    await filteredObj.validate({updateDetails}, { abortEarly: false });
    const data = new FormData()
    data.append('id', updateDetails.id) 
    data.append('persian_name', updateDetails.persianName) 
    data.append('english_name', updateDetails.englishName) 
    data.append('slug', updateDetails.slug) 
    updateDetails.seoTitle &&data.append('seo_title', updateDetails.seoTitle) 
    updateDetails.seoDesc && data.append('seo_description', updateDetails.seoDesc) 
    updateDetails.description && data.append('description', updateDetails.description) 
    updateDetails.url && data.append('seo_canonical', updateDetails.url) 
    data.append('no_index', updateDetails.noIndex ? "1" : "0") 
    data.append('showFaq',  updateDetails.showFaq ? "1" : "0") 
    data.append('db_name', updateDetails.dbName) 
    data.append('faq', JSON.stringify(updateDetails.faq)) 
    updateDetails.uploadImage && data.append('image', updateDetails.uploadImage)


    call_api({
      address_api: `${AppConstants.base_url_api}admin/categories/update/${rowData?.id_select}`,
      method_api: "POSt",
      body: data,
      file: true,
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.getList();
          this.setState({ showUpdateItem: false, updateDetails: {id: "",
          idSelect:"",
          persianName: "",
          englishName: "",
          slug: "",
          seoTitle: "",
          seoDesc: "",
          description: "",
          parent: "",
          url: "",
          noIndex: false,
          showFaq: false,
          faq: [{ question: "", answer: "" }],
          dbName: "aria",
          image: "",
          uploadImage: "",
          errorsList:[],} });
          toast.success(j?.message);
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }
      catch (err: any) {
        ids.errorsList = err.inner;
        this.setState({ updateDetails: ids});}
      
  };
handleDetailPannel = (item:any, index:number) =>{
  this.setState({selectedRowIndex:index})
    call_api({
      address_api: `${AppConstants.base_url_api}admin/categories/childs/${item?.id_select}`,
      method_api: "GET",
      
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({detailPannelData: j?.data, });
          
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
}
  }
export default CategoriesController;