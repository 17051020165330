import { Component } from 'react';
import { AppConstants } from '../../../core/constants';
import { call_api, parseJSON } from '../../../core/service';
import { toast } from 'react-toastify';
import * as yup from 'yup'

interface IProps {
dispatch?:any,
siteList?:any,
loadingSiteList?:any,
userData?:any
}
interface IState {
  loading?: boolean,
  siteTitle:string;
  siteAddress:string;
  canonicalAddress:string;
  H1HomePage:string;
  shortIntroduction:string;
  errorsList:any;
  siteUpdateMode:boolean
  notDisplayable:boolean,
  data:any

}

 class GeneralSettingsController extends Component<IProps, IState> {
  state: IState = {
    loading: false,
    siteTitle:"",
  siteAddress:"",
  canonicalAddress:"",
  H1HomePage:"",
  shortIntroduction:"",
  errorsList:[],
  siteUpdateMode:false,
  notDisplayable:false,
  data:null
  };
  async getDate() {
    let parameters = {
      db_name:window.location.pathname.split("/")[3],
    }
    let url = new URL(`${AppConstants.base_url_api}admin/setting/public`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}admin/setting/public${url.search}` : `${AppConstants.base_url_api}admin/setting/public`,
      method_api: "GET",
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
            data:j.data[0],
            canonicalAddress:j.data[0].value?.canonical_url || "",
            siteTitle:j.data[0].value?.title_site || "",
            siteAddress:j.data[0].value?.url_site || "",
            H1HomePage:j.data[0].value?.h1_text || "",
            shortIntroduction:j.data[0].value?.description_site || "",
            siteUpdateMode:j.data[0].value?.updating_site === 1 ? true : false,
            notDisplayable:j.data[0].value?.noindex_site === 1 ? true : false,
          })
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  async update() {
    const {data ,canonicalAddress,siteTitle,H1HomePage ,siteAddress , shortIntroduction , siteUpdateMode , notDisplayable} = this.state;

    const redirectsSchema = yup.object({
      canonicalAddress:yup.string().max(255, ' نباید بیشتر از 255 کاراکتر باشد'),
      siteTitle:yup.string().max(255, ' نباید بیشتر از 255 کاراکتر باشد'),
      siteAddress:yup.string().max(255, ' نباید بیشتر از 255 کاراکتر باشد'),
      H1HomePage:yup.string().max(255, ' نباید بیشتر از 255 کاراکتر باشد'),
      shortIntroduction:yup.string().max(255, ' نباید بیشتر از 255 کاراکتر باشد'),
  });
  try {
      
    await redirectsSchema.validate({ canonicalAddress,siteTitle,H1HomePage ,siteAddress , shortIntroduction }, { abortEarly: false });

    call_api({
      address_api: `${AppConstants.base_url_api}admin/setting/public/${data.id_select}`,
      method_api: "PUT",
      body: JSON.stringify({
        canonical_url: canonicalAddress,
        title_site:  siteTitle,
        url_site:    siteAddress,
        h1_text:   H1HomePage,
        description_site:    shortIntroduction,
        updating_site:  siteUpdateMode === true ? 1 : 0,
        noindex_site: notDisplayable  === true ? 1 : 0,
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          toast.success("با موفقیت انجام شد ");
        this.setState({ errorsList: [] })      
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }
    catch (err: any) {
      this.setState({ errorsList: err.inner })      
    }
  }
}

export default GeneralSettingsController;