import { Navigate, Outlet, Route } from "react-router-dom";
import {
    InputHTMLAttributes,
    ReactElement,
  } from "react";
import { Login, NotFound } from "../views/pages";
import { ROLES } from "../core/constants";
  interface Props extends InputHTMLAttributes<HTMLInputElement> {
    userData:any,
  }
  export  const AuthManager = ({userData}: Props): ReactElement => {
    const isLogin = localStorage.getItem("token");
    return isLogin === null ? <Navigate to="/login" /> :
    
     isLogin && userData && userData.roles && userData?.roles[0]?.name === ROLES.Admin ? <Navigate to="/admin/home" /> :
     isLogin && userData && userData.roles && userData?.roles[0]?.name === ROLES.Seller  && <Navigate to="/home" /> 

  };