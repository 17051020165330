import {
    HTMLAttributes,
    ReactElement,
    useState
    
  } from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import MyButton from "./button";
  interface Props extends HTMLAttributes<HTMLElement> {
    icon:any;
    title:any;
    details: any;
    isOpen:boolean;
    onClick?: any;
    setClose?: any; 
    extraInfoFirstRow?:string;
    extraInfoSecondRow?:string;
    extraInfoDetail?:string;
    detailIsArray?:boolean;
    array?:any;

  }

const SubmissionAlert = ({
    icon, 
    title,
    details,
    isOpen,
    onClick,
    setClose, 
    extraInfoDetail,
    extraInfoFirstRow,
    extraInfoSecondRow,
    detailIsArray,
    array,
  }: Props): ReactElement => {
    const [modal, setModal] = useState(isOpen);
    const handleClose = () => {
      setModal(false);
      setClose(!modal)

    };
    return (
        <Modal
        
        open={modal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="submission-alert">
          
        <div>
            {icon}
        </div>
      <h4 className="name-card-section" >
           {title}
      </h4>
      {
        detailIsArray ?
        <span className=" list-style">
          {details}
          <div className="item">
            {array.map((item:any)=>{
              return(
                <span >
              <span>{item?.id}</span>،
              </span>
              )
            })}
            </div>
        </span> 
      :
      <span className="detail-card-section">
      {details}
      </span> 
      }
    

    
      {extraInfoFirstRow &&
      <span className="extra-info-card-section">
        <div className="first-column">
       <img src= {extraInfoFirstRow}  alt=""/>
        {extraInfoSecondRow}
        </div>
        <div className="second-column">
          {extraInfoDetail}
        </div>
          
      </span> 
      }
      <div className="d-flex w-100">
          <MyButton
                    myStyle="submit-btn"
                    loading={false}
                    onClick={onClick}
                    title="بله مطمنم!"
          />
          <MyButton
                    myStyle="submit-btn"
                    loading={false}
                    onClick={handleClose}
                    title="انصراف"
          />
        </div>
        </Box>
      </Modal>
    );
  }
  export default SubmissionAlert;
  