import { Component } from 'react';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { toast } from 'react-toastify';
import { AppConstants } from '../../../core/constants';
import { call_api, parseJSON } from '../../../core/service';
import { createRef } from "react";
import * as yup from 'yup'

interface IProps {
  dispatch?:any,
  siteList?:any,
  loadingSiteList?:any,
}

interface IState {
  loading?: boolean,
  orderLoading?: boolean,
  search: string,
  userSearch: string,
  recieveByUser: boolean,
  rows: Array<any>,
  userId: Array<any>,
  bannedUser: boolean,
  selectedPaymentMethod: string,
  clickedIndex: number,
  openOrdersModal: boolean,
  openBanModal: boolean,
  usersDataList: any,
  rowData: any,
  page: number,
  userOrdersList: any,
  productList: any;
  product: any,
  productLoading: boolean,
  dreiverId: any;
  openDetailModal: boolean,
  detailData:any,
  rowCount:any,
  rowCountList:any,
  paginate: number,
  allOption:any,
  showFilters:any,
  openNewUserModal: boolean,
  mobile: string,
  firstName: string,
  lastName: string,
  phone: string,
  whatsapp: string,
  loadingUpdateProfile: boolean,
  errorsList?: any,
  userDreiverId: any,
  selectedRowIndex:number,

}
 class UsersInfoController extends Component<IProps, IState> {
  rowVirtualizerInstanceRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    this.rowVirtualizerInstanceRef = createRef()
    this.state = {
      loading: false,
      orderLoading: false,
      search: "",
      userSearch: "",
      recieveByUser: false,
      rows: [],
      userId: [],
      bannedUser:false,
      selectedPaymentMethod: "کارت به کارت",
      clickedIndex: -1,
      openOrdersModal: false,
      openBanModal: false,
      usersDataList: [],
      rowData: {},
      page: 1,
      userOrdersList: {},
      productList: [],
      product: [],
      productLoading: false,
      dreiverId: [],
      openDetailModal: false,
      detailData:[],
      rowCount:100,
      rowCountList:[10, 25 , 50 , 100],
      paginate: 0,
      allOption:{id:0 ,value:{
        persian_name: "همه",
        name: "*"
      }},
      showFilters:false,
      openNewUserModal: false,
      loadingUpdateProfile: false,
      mobile: "",
      firstName: "",
      lastName: "",
      phone: "",
      whatsapp: "",
      errorsList: [],
      userDreiverId: null,
      selectedRowIndex:101,

    }
  }
  handleCloseNewUserModal = () => {
    this.setState({ openNewUserModal: false })
  }
  userDdriverSelected = (choice: any) => {
    this.setState({ userDreiverId: choice })
  }
  async createNewUser() {
    const mobileRegExp = /^09\d{9}$/;
    const phoneRegExp = /^\d{5,12}$/;
    const justPersian = /^[\u0600-\u06FF\s]+$/gi;
    const { mobile, firstName,userDreiverId , lastName, phone, whatsapp, } = this.state;
    const updateProfileSchema = yup.object({
      userDreiverId: yup.object().required(" سایت الزامی می باشد "),
      firstName: yup.string().required(" نام الزامی می باشد ").max(32, 'نام بیشتر از 32 رقم نمی باشد').min(3, ' نام کمتر از 3 رقم نمی باشد').matches(justPersian,  ' لطفا از زبان فارسی استفاده کنید  '),
      lastName : yup.string().required(" نام خانوادگی الزامی می باشد ").max(32, 'نام خانوادگی بیشتر از 32 رقم نمی باشد').min(3, '  نام خانوادگی کمتر از 3 رقم نمی باشد').matches(justPersian,  ' لطفا از زبان فارسی استفاده کنید  '),
      mobile: yup.string().required(" شماره همراه الزامی می باشد  ").matches(mobileRegExp, 'فرمت وارد شده صحیح نمی باشد'),
      phone: yup.string().required("تلفن الزامی است").matches(phoneRegExp, 'فرمت وارد شده صحیح نمی باشد'),
      whatsapp: yup.string().notRequired().matches(mobileRegExp,  'فرمت وارد شده صحیح نمی باشد ').nullable().transform((value) => !!value ? value : null),

    });

    try {

      await updateProfileSchema.validate({ firstName, lastName,userDreiverId, mobile, phone, whatsapp, }, { abortEarly: false });
      this.setState({ loadingUpdateProfile: true })
      const data = new FormData()
      data.append('name', firstName)
      data.append('family', lastName)
      data.append('mobile', mobile)
      phone && data.append('phone', phone)
      data.append('whatsapp', whatsapp)
      data.append('db_name', userDreiverId?.value?.name)

      // if (availebleProfileImage === "") {
      //   data.append('avatar', profileImageUpload)
      // }
      call_api({
        address_api: `${AppConstants.base_url_api}seller/users-all`,
        method_api: "POST",
        body: data,
        file: true,
      })
        .then(parseJSON)
        .then(([status, j]) => {
          this.setState({ errorsList: [] })
          if (status === 200) {
            toast.success(j?.message);
            this.getUsersList();
            this.setState({openNewUserModal:false, firstName:"",lastName:"",mobile:"",phone:"",whatsapp:"", loadingUpdateProfile: false })
          } else if (status === 401) {
            this.setState({ loadingUpdateProfile: false })
            toast.error(j?.message);
            localStorage.clear();
          } else {
            console.warn(status, j);
            this.setState({ loadingUpdateProfile: false })
            toast.error(j?.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });

    } catch (err: any) {
      this.setState({ loadingUpdateProfile: false, errorsList: err.inner  })
    }
  
}
handleDetailPannel = (item:any, index:number) =>{
  const {selectedRowIndex} = this.state;
  if(selectedRowIndex === index)
  {
    this.setState({selectedRowIndex: 101})
  }
  else{
    this.setState({selectedRowIndex:index})
  }
}
  paginationSelectTimeOut: string | number | NodeJS.Timeout | undefined; 
  handlePaginationSelect=(event:any)=>{
    this.setState({
      rowCount: event.currentTarget.value, page:1
    })
    clearTimeout(this.paginationSelectTimeOut);
    this.paginationSelectTimeOut = setTimeout(() => {
      this.getUsersList()
    }, 800);
    
  }
  driverTimeout: string | number | NodeJS.Timeout | undefined;
  driverSelected = (selected:any, index?:any) : any=> {
    this.setState({ page: 1  })
    if(index?.option?.id === 0)
    {
      this.setState({
        dreiverId: this.props.siteList
      });

    }
    else 
    this.setState({
      dreiverId: selected
    });
    clearTimeout(this.driverTimeout);
    this.driverTimeout = setTimeout(() => {
      this.getUsersList()
    }, 1000);
  }
  searchTimeout: string | number | NodeJS.Timeout | undefined;
  handleSearch = (event: any) => {
    this.setState({ search: event.currentTarget.value })
    clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.getUsersList()
      }, 1000);
  };
  async getUsersList() {
    const {dreiverId,rowCount, page} = this.state;
    // const productSelectedList = [];
    // for (var i = 0; i < this.state.product.length; i++) {
    //   productSelectedList.push(this.state.product[i]?.id)
    // }
    const driversSelectedList = [];
    for (var i = 0; i < dreiverId.length; i++) {
      driversSelectedList.push(dreiverId[i]?.value?.name)
      // this.setState({ usersUuid: driversSelectedList })
    }
    const { search,bannedUser } = this.state;
    this.setState({ loading: true })
    let parameters = {
      search: search ? search : null,
      page: page ? page.toString() : null,
      status: bannedUser ? 2 : 1,
      paginate: rowCount,
      // products:productSelectedList,
      db_name: dreiverId?.length > 0 ? driversSelectedList.toString() : null,

    }
    let url: any = new URL(`${AppConstants.base_url_api}seller/users-all`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}seller/users-all${url.search}` :
        `${AppConstants.base_url_api}seller/users-all`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
            usersDataList: j.data
          })
          this.setState({ loading: false })
        } else if (status === 401) {
          this.setState({ loading: false })
          localStorage.clear();
        } else {
          this.setState({ loading: false })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  showMoreDetails = (data:any)=>{
    this.setState({openDetailModal: true})
    call_api({
      address_api:`${AppConstants.base_url_api}seller/orders/${data?.item?.id_select}?has_relation=0`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({
            detailData: j.data
          })
          
        } else if (status === 401) {
          
          localStorage.clear();
        } else {
         
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  handleCloseOrdersModal = () => {
    this.setState({ openOrdersModal: false });
  }
  handleCloseDetailModal = ()=>{
    this.setState({ openDetailModal: false });

  }
  handleOrdersListByEachUser = (event: any, page?: number) => {
    this.setState({ openOrdersModal: true });
    this.setState({ orderLoading: true })
    call_api({
      address_api: `${AppConstants.base_url_api}seller/user/orders/${event?.item?.id_select}`,
      method_api: "GET",
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({ orderLoading: false })
          this.setState({ userOrdersList: j?.data })

        } else if (status === 404) {
          toast.error(j?.message);
          this.setState({ orderLoading: false })

        } else if (status === 401) {
          toast.error(j?.message);
          this.setState({ orderLoading: false })
        }
        else {
          toast.error(j?.errors);
          this.setState({ orderLoading: false })
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({ orderLoading: false })

      });
  }
  paginationTimeOut: string | number | NodeJS.Timeout | undefined; 
  handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ page: value })
    clearTimeout(this.paginationTimeOut);
    this.paginationTimeOut = setTimeout(() => {
      this.getUsersList();
    }, 800);
  };
  async getProductList(search?:string) {
    
    this.setState({ productLoading: true })
    let parameters = {
      search: search ? search : null,
    }

    let url = new URL(`${AppConstants.base_url_api}seller/product/search`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}seller/product/search${url.search}` : `${AppConstants.base_url_api}seller/products`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({ productLoading: false })
          this.setState({
            productList: j.data
          })

        } else if (status === 401) {
          localStorage.clear();
          this.setState({ productLoading: false })
        } else {
          toast.error(j?.errors);
          this.setState({ productLoading: false })
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({ productLoading: false })
      });
  };
  productSelectedTimeout: string | number | NodeJS.Timeout | undefined;
  productSelected = (choice: any) => {
    this.setState({ product: choice })
    clearTimeout(this.productSelectedTimeout);
    this.productSelectedTimeout = setTimeout(() => {
      this.getUsersList()
    }, 1000);
  }
  handleBanUser = (event: any) => {
    this.setState({ rowData: event.item })
    this.setState({ openBanModal: true })
  }
  
  handleSubmitBanUser = () => {
    const {bannedUser} = this.state
    call_api({
      address_api: `${AppConstants.base_url_api}seller/users-all/update/${this.state.rowData.id}`,
      method_api: "POST",
      body: JSON.stringify({
        status: bannedUser? 1 : 2,
        db_name:this.state.rowData.db_name
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          toast.success(j?.message);
          this.setState({ openBanModal: false });
          this.getUsersList();
        } else if (status === 404) {
          this.setState({ openBanModal: false });
          toast.error(j?.message);

        } else if (status === 401) {
          this.setState({ openBanModal: false });
          toast.error(j?.message);
        }
        else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
      });
  }


  statusTimeout: string | number | NodeJS.Timeout | undefined;
  handleChangeStatus = (event: any) => {
    this.setState({
      bannedUser: event.currentTarget.checked,
    })
    clearTimeout(this.statusTimeout);
      this.statusTimeout = setTimeout(() => {
        this.getUsersList();
      }, 100);
  };
  productListTimeout: string | number | NodeJS.Timeout | undefined;
  handleSearchProduct = (choice: any) => {
    clearTimeout(this.searchTimeout);
    if(choice.length > 2)
    this.searchTimeout = setTimeout(() => {
      this.getProductList(choice)
    }, 1000);
  };
}

export default UsersInfoController;