import { Component } from 'react';
import { call_api, parseJSON } from "../../../core/service";
import { toast } from 'react-toastify';
import { AppConstants } from '../../../core/constants';
import * as yup from 'yup'
import {  fixNumbers, totalOrdersPriceWithDiscount } from '../../../core/utils';
import moment from 'moment-jalaali';
interface IProps {
  dispatch?:any,
  siteList?:any,
  loadingSiteList?:any,
}

interface IState {
  loading?: boolean,
  search: string,
  userSearch: string,
  usersInfoDropDOwn: Array<any>,
  selectedTime: string,
  recieveByUser: boolean,
  rows: Array<any>,
  platformDropdownOpen: boolean,
  platformList: Array<any>,
  selectedOrderStatusDropDown: string,
  selectedPaymentStatus: boolean,
  paymentStatusDropDown: Array<any>,
  paymentMethodDropDown: Array<any>,
  selectedPaymentMethod: string,
  productListData: any,
  generalDiscount: string,
  shippingAmount: string
  date: any,
  finalyDate: any,
  userInfo: string,
  recieverName: string,
  recieverPhone: string,
  hour: string,
  address: string,
  profileListData: any,
  userSelected: any,
  availebleProfileImage?: any,
  availeblePaymentImage:any,
  profileImage?: any,
  profileImageUpload?: any,
  paymentImage: any,
  paymentImageUpload: any,
  errorsList?: any,
  isCreating: boolean,
  userId: number,
  isActive?:boolean,
  toggleAddressPage:boolean,
  sendingStatus:string,
    usersAddressList:Array<any>,
    addressMessage:string,
    addressLoading:boolean,
    dreiverId:any,
    userLoading?:boolean
    addressError: boolean;
    currentItem: any,
    productModal:boolean,
    total:number
  rowCount:any,
  rowCountList:any,
  page: number,
  uploadedImage:any,
  openBillModal:boolean,
  inPerson:boolean,
  step: number,
  paymentMethodStep:number,
  dbName:string,
  id:number,
  openEditAdress:boolean,
  editAddressData: any,
  openEditAddressAlert:boolean,
  addressData:any,

}

 class UpdateOrderController extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {

      loading: false,
      search: "",
      userSearch: "",
      usersInfoDropDOwn: [],
      selectedTime: "",
      recieveByUser: false,
      rows: [],
      platformDropdownOpen: false,
      userSelected: null,
      generalDiscount: "0",
      shippingAmount: "0",
      platformList: [
        { value: "blues", label: "Blues" },
        { value: "rock", label: "Rock" },
        { value: "jazz", label: "Jazz" },
        { value: "orchestra", label: "Orchestra" },
      ],
      selectedPaymentStatus: false,
      selectedOrderStatusDropDown: "0",
      paymentStatusDropDown: [{ value: "0", name: 'در انتظار پرداخت' },
      { value: "1", name: 'پرداخت شده' },
      ],
      paymentMethodDropDown: [{ value: 1, name: "انلاین" },
      { value: 2, name: "کارت به کارت" },
      ],
      selectedPaymentMethod: "1",
      productListData: [],
      date:moment(new Date().setDate(new Date().getDate() + 1)).format("jYYYY/jM/jD"),
      finalyDate:moment(new Date().setDate(new Date().getDate() + 1)).format("jYYYY/jM/jD"),
      userInfo: "",
      recieverName: "",
      recieverPhone: "",
      hour: "10-13",
      address: "",
      profileListData: [],
      profileImage: "",
      profileImageUpload: "",
      paymentImage:"",
      paymentImageUpload :"",
      errorsList: [],
      availebleProfileImage: "",
      availeblePaymentImage:"",
      isCreating: false,
      userId: 1,
      isActive:false,
      toggleAddressPage:false,
      sendingStatus:"send",
      usersAddressList:[],
      addressMessage:"",
      addressLoading:false,
      dreiverId:null,
    userLoading:false,
    addressError: false,
    currentItem: null,
    productModal:false,
    total:0,
      rowCount:100,
      rowCountList:[10, 25 , 50 , 100],
      page: 1,
      uploadedImage:"",
      openBillModal:false,
      inPerson: false,
      step:0,
      paymentMethodStep:0,
      dbName:"",
      id:1,
      openEditAdress:false,
      editAddressData:null,
      openEditAddressAlert:false,
      addressData:{title:"", province:{id: 8, name:"تهران"}, city:{id:117, name:"تهران"}, region:{id:101, name:"", }, postal_code:"", address_description:"", full_address:""},

    }
  }
    searchTimeout: string | number | NodeJS.Timeout | undefined;
    handleSearch = (event: any) => {
      this.setState({ search: event.currentTarget.value, page:1 })
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.getProductList()
      }, 1000);
    };
    handleSetStep = (step: number) => {
      this.setState({ step: step })
    };
    handleTakingOrder = (event: any) => {
      this.setState({ inPerson: event.target.checked });
      if(event.target.checked){
        this.setState({sendingStatus:"inPerson", addressError: false})
      }
      else {
        this.setState({sendingStatus:"send"})
  
      }
    };
  handleDeleteProfileImage = () => {
    this.setState({
      profileImage: "", profileImageUpload: ""
    })

  }
  async addAddress(address:any){
    const { userSelected, dreiverId } = this.state;
    this.setState({addressData: address})
    const addressInfo = yup.object().shape({
      address:yup.object().shape({
      city: yup.object().required("شهر الزامی است."),
      full_address:yup.string().required("آدرس دقیق الزامی است."),
      province:yup.object().required("استان الزامی است."),
      title:yup.string().required("عنوان آدرس الزامی است."),
      postal_code: yup.string().notRequired().max(10,"کدپستی باید شامل ۱۰ عدد باشد.").min(10,"کدپستی باید شامل ۱۰ عدد باشد.").nullable().transform((value) => !!value ? value : null),
    })
  })   
  try{
   await addressInfo.validate({address}, { abortEarly: false });
    call_api({
      address_api: `${AppConstants.base_url_api}seller/address-all`,
      method_api: "POST",
      body: JSON.stringify({
        user_id: userSelected?.id.toString(),
        label: address.title,
        db_name: dreiverId?.value?.name,
        province_id: address.province?.id.toString(),
        address: address.full_address,
        city_id : address.city?.id.toString(),
        desc: address.address_description,
        postal_code: address.postal_code,
        region: address.region?.name
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        this.setState({ errorsList: [] })
        if (status === 200) {
          toast.success(j?.message);
          this.getUsersAddress(userSelected?.id_select)
          this.setState({toggleAddressPage: false, addressData:{title:"", province:{id: 8, name:"تهران"}, 
          city:{id:117, name:"تهران"}, region:{id:101, name:"", }, postal_code:"", address_description:"", 
          full_address:""},
        })

        } else if (status === 401) {
          toast.error(j?.errors);
          localStorage.clear();
        } else {
          
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error("catch api",error);
      });
   }
   
    catch(err: any) 
        {
          this.setState({ errorsList: err.inner })
        }
}
  searchLocation = ()=>{

  }
    handleSendingStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({sendingStatus:(event.target as HTMLInputElement).value});
  }
  handleNewAddress = ()=>{
    this.setState({toggleAddressPage : true, errorsList:[] })  
  }
  handleCloseAddressModal = ()=>{
    this.setState({toggleAddressPage : false,
      addressData:{title:"", province:{id: 8, name:"تهران"}, city:{id:117, name:"تهران"}, region:{id:101, name:"", }, postal_code:"", address_description:"", full_address:""},
    })
  }
  async handleProfileImageUpload(event: any) {
    let news_image_file = null;
    news_image_file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(news_image_file);
    reader.onloadend = () => {
      this.setState({ profileImage: reader.result });
    }
    if (news_image_file) {
      this.setState({ profileImageUpload: news_image_file });
    }
    this.setState({ availebleProfileImage: "" });
  }
  async handlePaymentImageUpload(event: any) {
    let news_image_file = null;
    news_image_file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(news_image_file);
    reader.onloadend = () => {
      this.setState({ paymentImage: reader.result });
    }
    if (news_image_file) {
      this.setState({ paymentImageUpload: news_image_file });
    }
    this.setState({ availeblePaymentImage: "" });
  }
  handleChange = (e: any) => {
    this.setState({selectedTime: e.target.value});
  }
  handleOpen = (e: any, data: any) => {
  }
  handleRecieveByUser = () => {
    this.setState({ recieveByUser: !this.state.recieveByUser })
  }
  addRowTable = () => {
    const data = {
      id_select: "",
      product_name: "",
      count: "",
      price: "",
      discount: "",
      finalPrice: ""
    };
    this.setState({ rows: [...this.state.rows, data] });
  };
  tableRowRemove = (index: number) => {
    const dataRow = [...this.state.rows];
    dataRow.splice(index, 1);
    this.setState({ rows: dataRow});
  };
  onValUpdate = (i: number, event: any) => {
    const { name, value } = event.target;
    const data = [...this.state.rows];
    var newValue = value.replace(
        /,/g,
        ""
      );
      const re = /^[0-9\b]+$/;
      if (newValue === "" || re.test(newValue)) {
        data[i].error = "" 
        let countItem:number =  Number(data[i]?.count);
        if(name === "discount" ){
          data[i][name] = newValue;
          if(Number(newValue) < Number(data[i]["price"]))
          {
            data[i].error = ""
          }
          else{
            data[i].error += "تخفیف نمی‌تواند بزرگ‌تر یا مساوی با قیمت باشد"
          }
        } 
        else if(name === "price"){
            if(Number(newValue) > Number(data[i]["discount"] ))
            {
              data[i][name] = newValue;
              data[i].error = ""
            }
            else{
              data[i].error = ""  
              if(Number(data[i]?.discount) < 99)
              {              
                data[i][name] = newValue;
              }
              else
            {   
              data[i][name] = newValue;
              data[i].error += "قیمت نمی‌تواند کوچک‌تر یا مساوی با تخفیف باشد";
            }
            }
          }
        else if(name === "count"){
          if(Number(newValue) > 0){
            countItem = Number(newValue) 
            data[i][name] = newValue;
          }else{
            countItem = 1
            data[i][name] = 1
          }
        }
        if (Number(data[i]?.discount) > 99) {
            data[i]["finalPrice"] = (Number(data[i]?.price) -  Number(data[i]?.discount)) * countItem ; 
          } 
        else {
            data[i]["finalPrice"] = (Number(data[i]?.price) * countItem) - Number(Number(Number(data[i]?.price * Number(data[i]?.discount)) / 100) * countItem)  ;
          }
      }
    this.setState({ rows: data });
  };
  handleAdd = (data: any) => {
    const {dreiverId} = this.state;
    if(dreiverId === null)
    {
      toast.error("لطفا یک سایت را انتخاب کنید.")
    }
    else{
      const { rows } = this.state;
      var found = false;
      const updateConversationInfo = [...this.state.rows]
      for (var i = 0; i < rows.length; i++) {
        if (rows[i].product_id === data?.id) {
          updateConversationInfo.splice(i,1)
        this.setState({ rows: updateConversationInfo });
          found = true;
          break;
        }
      }
      if (!found) {
        const obj = {
          product_id: data.id,
          product_name: data.product_name,
          count: data.count || 1,
          price: data.price_by_discount.price,
          discount: data.price_by_discount.discount,
          finalPrice: data.price_by_discount.finalPrice,
          error: ""
        }
        updateConversationInfo.push(obj);
        this.setState({ rows: updateConversationInfo });
      }
    }
  }
  handleAddress = (event: React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({address:(event.target as HTMLInputElement).value});
  }
  handleDate=(value:any)=>{
    this.setState({date: value, finalyDate : fixNumbers(value?.format("YYYY/MM/DD"))})
  }
  async getProductList(dbName?:string) {
    const {dreiverId , rowCount, page} = this.state;
    this.setState({ loading: true, productListData:[] })
    let parameters = {
      search: this.state.search ? this.state.search : null,
      page: page ? page.toString() : null,
      db_name: dbName ? dbName : dreiverId?.value.name,
      paginate: rowCount,
    }
    let url = new URL(`${AppConstants.base_url_api}seller/products`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    this.setState({ loading: true })
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}seller/products${url.search}` : `${AppConstants.base_url_api}seller/products`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          if(j.data){

            this.setState({
              productListData: j.data?.data, loading: false , total: j.data?.total
            })
          }
        } else if (status === 401) {
          this.setState({ loading: false })
          localStorage.clear();
        } else {
          this.setState({ loading: false })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
   getUsersAddress(userId?:number) {
    this.setState({ addressLoading: true })
    call_api({
      address_api: `${AppConstants.base_url_api}seller/address-user-all/${userId}` ,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          if(j?.data)
          {
            this.setState({ usersAddressList: j.data, addressMessage:j.message, addressLoading:false,
              address:j.data[j?.data.length - 1]?.id})
          }
          else{
            this.setState({addressMessage:j.message})
          }         
        } 
        else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  async order() {
    let errorsLists = [];
    const {  rows } = this.state;
    for(var i = 0; i< rows.length; i++){
          if(rows[i].error && rows[i].error !== "")
          {
            errorsLists.push(rows[i].error)
          }
    }
    if(errorsLists.length === 0){

    if(this.state.rows.length > 0){
      this.setState({ isCreating: true })
      const mobileRegExp = /^09\d{9}$/;
      const { userSelected,
        recieverName,
        recieverPhone,
        finalyDate,
        hour,
        dbName,
        id,
        address,
        shippingAmount,
        generalDiscount,
        selectedPaymentStatus,
        selectedOrderStatusDropDown,
        selectedPaymentMethod, rows , sendingStatus, paymentImageUpload, uploadedImage } = this.state;  
        if(uploadedImage === null && paymentImageUpload === "" && selectedPaymentStatus === true)
        {
            toast.error("تصویر فیش واریزی را آپلود کنید یا وضعیت پرداخت را به حالت در انتظار پرداخت تغییر دهید")
        }
      else{
        const updateProfileSchema = yup.object({
        userSelected: yup.object().required("انتخاب کاربر الزامی می باشد "),
        address: yup.string() 
        .when('sendingStatus', {
          is: (val:any) => val === "send",
          then: (schema) => 
            schema.required("انتخاب آدرس الزامی است."),
        }),
        recieverPhone: yup.string().required(" شماره همراه الزامی می باشد  ").matches(mobileRegExp, 'فرمت وارد شده صحیح نمی باشد'),
        recieverName: yup.string().required(" نام و نام خانوادگی الزامی می باشد ").max(255, 'کد ورود بیشتر از 255 رقم نمی باشد'),
      });
      try {
        await updateProfileSchema.validate({ userSelected, address, recieverName, paymentImageUpload, recieverPhone , hour, shippingAmount, generalDiscount, selectedPaymentStatus, selectedPaymentMethod, selectedOrderStatusDropDown }, { abortEarly: false });
        const data = new FormData()
        data.append('user_id', userSelected.id)
        data.append('receiver_name', recieverName)
        data.append('receiver_phone', recieverPhone)
        data.append('send_date',moment(finalyDate, 'jYYYY/jMM/jDD').format('YYYY-MM-DD'))
        data.append('send_period', hour)
        data.append('price', `${totalOrdersPriceWithDiscount(rows) - (Number(generalDiscount) + Number(shippingAmount))}`)
        data.append('shipping_price', shippingAmount)
        data.append('payment_status', selectedPaymentStatus ? "1" : "0")
        data.append('payment_type', selectedPaymentMethod)
        data.append('order_status', selectedOrderStatusDropDown)
        data.append('shipping_type', "4")
        data.append('extra_discount', generalDiscount)
        data.append('products', JSON.stringify(rows))
        data.append('description', "")
        data.append('db_name', dbName)
        data.append('order_id', id.toString())
        data.append('address_id', sendingStatus === "send"? address : "0" )
        data.append('self_receive', sendingStatus === "send"? "0" : "1" )
        if(selectedPaymentStatus === true ) {
          if(paymentImageUpload.name)
          {
            data.append('avatar', paymentImageUpload)
            data.append("has_image", "0")
          }
          else {
            data.append("has_image", "1")
          }
        } 
        if((sendingStatus === "send" && address) || sendingStatus !== "send"){
          call_api({
            address_api: `${AppConstants.base_url_api}seller/order/${window.location.pathname.split("/")[2]}`,
            method_api: "POST",
            body: data,
            file: true,
          })
            .then(parseJSON)
            .then(([status, j]) => {
              this.setState({ errorsList: [] })
              if (status === 201) {
                toast.success(j?.message);
                this.setState({ isCreating: false, address:"",recieverName:"", recieverPhone:"", rows:[],
                 paymentImageUpload:"", addressError:false, selectedPaymentStatus:false, selectedPaymentMethod:'1'})
                 window.location.href = "/orders";
              } else if (status === 401) {
                this.setState({ isCreating: false })
                toast.error(j?.errors);
                localStorage.clear();
              } else {
                console.warn(status, j);
                this.setState({ isCreating: false })
                toast.error(j?.errors);
              }
            })
            .catch((error) => {
              console.error(error);
              toast.error(error?.errors?.message)
            });
          }
          else{
            this.setState({addressError : true, isCreating: false})
          }
      } catch (err: any) {
        this.setState({ isCreating: false, errorsList: err.inner  })
        
      }
    }
    }else{
      toast.error("حداقل یک محصول را انتخاب کنید");
    }
  }
  else {
    toast.error("لطفاً به مقادیر قیمت یا تخفیف دقت نمایید!")
  }
    
  };
  async getProfilesList(search?:string) {
    this.setState({ userLoading: true })
    let parameters = {
      search: search ? search.toString(): null,
      db_name:this.state.dreiverId?.value?.name,
    }
    let url = new URL(`${AppConstants.base_url_api}seller/users-all-no-paginate`)

    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}seller/users-all-no-paginate${url.search}` : `${AppConstants.base_url_api}seller/users-all-no-paginate`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          if(j.data){
          this.setState({profileListData: j?.data, userLoading: false})
          }
            this.getUsersAddress(j.data[0]?.id_select)
            this.setState({ userSelected: j.data[0] })
        } else if (status === 401) {
          this.setState({ userLoading: false })
          localStorage.clear();
        } else {
          this.setState({ userLoading: false })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({ userLoading: false })
      });
  };
  openProductModal = () => {
    this.setState({ productModal: true })
  }
  handleCloseProductModal = () => {
    this.setState({ productModal: false })
  }
  paginationSelectTimeOut: string | number | NodeJS.Timeout | undefined; 
  handlePaginationSelect=(event:any)=>{
    this.setState({
      rowCount: event.currentTarget.value,
      page:1
    })
    clearTimeout(this.paginationSelectTimeOut);
    this.paginationSelectTimeOut = setTimeout(() => {
      this.getProductList()
    }, 800);
    
  }
  paginationTimeOut: string | number | NodeJS.Timeout | undefined; 
  handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ page: value })
    clearTimeout(this.paginationTimeOut);
    this.paginationTimeOut = setTimeout(() => {
      this.getProductList()
    }, 800);
  }
  magnifyImage = ()=>{
    this.setState({openBillModal : true})
  }
  handleCloseBill = () => {
    this.setState({ openBillModal: false })
  }
  async getOrder() {
    call_api({
      address_api: `${AppConstants.base_url_api}seller/orders/${window.location.pathname.split("/")[2]}?has_relation=0`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.getProfilesList(j?.data?.user?.mobile)
          this.setState({ dreiverId:this.props.siteList?.filter((obj: any) => obj?.value?.name === j?.data?.db_name)[0] })
          this.setState({ recieverName: j?.data?.shipping?.receiver_name || "",
          dbName:j?.data?.db_name,
          id:j?.data?.id,
          recieverPhone:  j?.data?.shipping?.receiver_phone || "" ,
          step:j?.data?.shipping?.send_period === "10-13" ? 0 : j?.data?.shipping?.send_period === "13-16" ? 1 : 2  ,
          sendingStatus : Number(j?.data?.shipping?.self_receive) === 0 ? "send" : "inPerson" ,
          inPerson: Number(j?.data?.shipping?.self_receive) === 0 ? false : true,
          selectedPaymentStatus:j?.data?.payment_status?.name.toString() === "1" ? true : false , 
          selectedOrderStatusDropDown:j?.data?.order_status?.name.toString() , 
          selectedPaymentMethod:j?.data?.payment_type?.name.toString() , 
          date: moment(j?.data?.shipping?.send_date).format("jYYYY/jM/jD"),
          finalyDate: moment(j?.data?.shipping?.send_date).format("jYYYY/jM/jD"),
          shippingAmount:j?.data?.shipping?.price || 0,
          generalDiscount: j?.data?.extra_discount || 0,
          address: j?.data?.shipping?.address_id,
          uploadedImage: j?.data?.payment_image          ,
        })
        this.getProductList(j?.data?.db_name)
          const updateConversationInfo = [...this.state.rows]
          for (var i = 0; i < j.data.order_products.length; i++) {
        
            const found = updateConversationInfo.some(el => el.product_id ===  j.data.order_products[i].product_id);
              if (!found) {
              const obj = {
                product_id: j.data.order_products[i]?.product_id,
                product_name: j.data.order_products[i]?.product_name,
                count: j.data.order_products[i]?.count || 1,
                price: j.data.order_products[i]?.price_by_discount?.price,
                discount: j.data.order_products[i]?.price_by_discount?.discount,
                finalPrice: j.data.order_products[i]?.price_by_discount?.finalPrice
              }
              updateConversationInfo.push(obj);
            }
            this.setState({ rows: updateConversationInfo });
          }
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  handleOpenEditAddressModal = (data:any) =>{
    this.setState({openEditAdress: true, editAddressData: data});
      let parameters = {
       db_name: data?.db_name,
      };
      let url = new URL(
        `${AppConstants.base_url_api}seller/address-all/${data.id}`
      );
  
      for (const [key, value] of Object.entries(parameters)) {
        if (value) {
          url.searchParams.append(key, value);
        }
      }
      call_api({
        address_api: url.search
          ? `${AppConstants.base_url_api}seller/address-all/${data.id}${url.search}`
          : `${AppConstants.base_url_api}seller/address-all/${data.id}`,
        method_api: "GET",
      })      
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          this.setState({ addressData:{title:j?.data?.label, province:{id: j?.data?.province_id?.id, name: j?.data?.province_id?.name}, city:{id:j?.data?.city_id?.id, name:j?.data?.city_id?.name}, 
          region:{id:101, name:j?.data?.region, }, postal_code:j?.data?.postal_code, address_description:j?.data?.desc, full_address:j?.data?.address},
        })
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  handleCloseAddressEditModal = () => {
    this.setState({ openEditAdress: false })
  }
  handleEdit = (data:any)=>{
    this.setState({openEditAddressAlert: true, addressData: data})
  }
  async handleConfirmEdition() {
    const { editAddressData, addressData, userSelected } = this.state;
    const addressInfo = yup.object().shape({
      addressData:yup.object().shape({
      city: yup.object().required("شهر الزامی است."),
      full_address:yup.string().required("آدرس دقیق الزامی است."),
      province:yup.object().required("استان الزامی است."),
      title:yup.string().required("عنوان آدرس الزامی است."),
      postal_code: yup.string().notRequired().max(10,"کدپستی باید شامل ۱۰ عدد باشد.").min(10,"کدپستی باید شامل ۱۰ عدد باشد.").nullable().transform((value) => !!value ? value : null),
    })
  })   
  try{
   await addressInfo.validate({addressData}, { abortEarly: false });
    call_api({
      address_api: `${AppConstants.base_url_api}seller/address-all/${editAddressData?.id}`,
      method_api: "PATCH",
      body: JSON.stringify({
        user_id: userSelected?.id.toString(),
        label: addressData.title,
        db_name: editAddressData?.db_name,
        province_id: addressData.province?.id.toString(),
        address: addressData.full_address,
        city_id : addressData.city?.id.toString(),
        desc: addressData.address_description,
        postal_code: addressData.postal_code,
        region: addressData.region?.name
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        this.setState({ errorsList: [] })
        if (status === 200) {
          toast.success(j?.message);
          this.setState({openEditAdress: false, openEditAddressAlert: false,
            addressData:{title:"", province:{id: 8, name:"تهران"}, city:{id:117, name:"تهران"}, region:{id:101, name:"", }, postal_code:"", address_description:"", full_address:""},
          });
            this.getUsersAddress(userSelected?.id_select)

        } else if (status === 401) {
          toast.error(j?.errors);
          localStorage.clear();
        } else {
          
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error("catch api",error);
      });
   }
    catch(err: any) 
        { this.setState({ errorsList: err.inner })}
  }
}


export default UpdateOrderController;

