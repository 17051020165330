interface Props {
  title?: string;
  onClick?: any;
  isActive?: boolean;
  name?: string;
}
const Checkbox = ({ title, onClick, isActive , name}: Props) => {

  return (
    <div>
  <input className="form-check-input" name={name} onChange={onClick} type="checkbox" value="" checked ={isActive}/>
  <label className="form-check-label pe-2 " >
    {title}
  </label>
</div>
  );
};

export default Checkbox;
