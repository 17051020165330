import { Component } from "react";
import { call_api, parseJSON } from "../../../core/service";
import { AppConstants } from "../../../core/constants";
import { toast } from "react-toastify";
import { createRef } from "react";
import { alertButtonsState } from "../../../core/utils";
import * as yup from 'yup';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

interface IProps { 
    open?: boolean,
    dispatch?:any,
    siteList?:any,
    tagFilter?:any,
    brandFilter?:any,
    categoryFilter?:any,
    loadingSiteList?:any,
  }
  interface IState {
    buyPrice: string,
    description: string,
    productListData: any,
    categoryList: any;
    category: any,
    statusList: any;
    statusId: any;
    brandList: any;
    brandId: any,
    dreiverId: any;
    search: string;
    groupChangeOptions: any;
    groupChangeSelectedOption: any,
    loading: boolean;
    updateLoading?: boolean,
    price: string,
    toggleModal: any,
    openPriceModal: boolean,
    openBuyPriceModal: boolean,
    openGroupOperationModal: boolean,
    selectInputValue: any,
    column: Array<any>,
    rowData: any,
    selectedList: any,
    idSelectedList: any,
    statusSelectedList: any,
    submitLoading: boolean,
    paginate: number,
    fileType: string,
    fileExtension: string,
    errorsList?: any,
    weight: string
    inventory: string
    groupPrice: string
    groupDiscount: string;
    priceIncrease: string;
    priceReduction: string;
    page: number,
    selectedRow: any,
    priceList: any,
    total:number
    rowCount:any,
    rowCountList:any,
    allOption:any,
    showFilters:boolean,
    syncStatus:any,
    syncStatus2:any,
    showSyncFilter:any;
    openLinkModal:boolean;
    relationshipProducts:any;
    multiSelectedList: any;
    confirmUnlink:boolean;
    unlinkedItem:any;
    tag:any;
    tagList:any;
    tagLoading: boolean;
    writers:any;
    writersList:any;
    writersFilterLoading:boolean;
    toggleDetailPannel:string;
    detailPannelData:any;
    emptyList:Array<Object>,
    emptyId:any,
    chooseDbNameModal: boolean,
    dbNamesList:Array<any>,
    selectedDbName: any,
    toggleShowNotesModal:boolean,
    hideSync:boolean,
    selectedRowIndex:number,
    detailPannelLoading:boolean,

  
  }
 class ProductsListController extends Component<IProps, IState> {
    tableInstanceRef: React.RefObject<any>;
    constructor(props: any) {
      super(props);
      this.tableInstanceRef = createRef()

      const search = window.location.search;
      const params = new URLSearchParams(search);
      const tagParams = params.get('tag');
      const brandParams = params.get('brand');
      const categoryParams = params.get('category');
      const dbNameParams = params.get('db-name');
      this.state = {
        description: "",
        submitLoading: false,
        buyPrice: "",
        rowData: {},
        productListData: [],
        loading: false,
        brandId:brandParams ?  [this.props.brandFilter] : [],
        category: categoryParams ? [this.props.categoryFilter] : [],
        multiSelectedList: [],
        groupChangeOptions: ['تغییر ثابت',
          'تغییر درصدی افزایشی',
          'تغییر درصدی کاهشی',
        ],
        groupChangeSelectedOption: "تغییر ثابت",
        statusId: [],
        search: "",
        updateLoading: false,
        price: "",
        toggleModal: { status: false, buttonState: "" },
        openPriceModal: false,
        openGroupOperationModal: false,
        openBuyPriceModal: false,
        selectInputValue: "",
        column: [
          { title: "کد", field: "code" },
          { title: "نام محصول", field: "name" },
          {
            title: "موجودی و خرید",
            field: "leftOver",
          },
          { title: "قیمت", field: "price" },
          {
            title: "تخفیف",
            field: "offPrice"
          },
        ],
        categoryList: [],
        brandList: [],
        statusList: [ { title: "حذف شده", value: "deleted" },{ title:"در انتظار تایید" , value:"unconfirmed"}, { title:"تایید شده" , value:"confirmed"}],
        dreiverId: dbNameParams ? [this.props.siteList?.filter((obj: any) => obj?.value?.name === dbNameParams)[0]] : [],
        idSelectedList: [],
        statusSelectedList: [],
        paginate: 0,
        fileType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        fileExtension: ".xlsx",
        weight: "",
        inventory: "",
        groupPrice: "",
        groupDiscount: "",
        priceIncrease: "",
        priceReduction: "",
        errorsList: [],
        page: 1,
        selectedRow: "",
        priceList: [],
        total:0,
        selectedList: [],
        rowCount:100,
        rowCountList:[10, 25 , 50 , 100],
        allOption:{id:0 ,value:{
          persian_name: "محصولات لینک شده ",
          name: "*"
        }},
        showFilters:dbNameParams ? false : true,
        syncStatus:dbNameParams ? null : 1,
        syncStatus2: null,
        showSyncFilter:null,
        openLinkModal:false,
        relationshipProducts:[],
        confirmUnlink:false,
        unlinkedItem:null,
        tag:tagParams ? [this.props.tagFilter] : [],
        tagList:[],
        tagLoading: false,
        writers:[],
        writersList:[],
        writersFilterLoading:false,
        toggleDetailPannel:"",
        detailPannelData:null,
        emptyList: [ { title: "دسته بندی", value: "category_id" },{ title:"برند" , value:"brand_id"}, { title:"کتگوری" , value:"tag_id"}],
        emptyId:[],
        chooseDbNameModal: false,
        dbNamesList:[],
        selectedDbName:{db_name:"", id_select:null},
        toggleShowNotesModal:false,
        hideSync:dbNameParams ? true : false,
        selectedRowIndex:101,
        detailPannelLoading:false,

      }
    }
    handleChooseDbName = (dbNames:any) =>{
      this.setState({chooseDbNameModal: true, dbNamesList: [...dbNames?.relationship_products]  })

    }
    statusTimeout: string | number | NodeJS.Timeout | undefined;
    statusSelected = (choice: any) => {
      this.setState({ statusId: choice })
      clearTimeout(this.statusTimeout);
      this.statusTimeout = setTimeout(() => {
        this.getProductsList()
      }, 1000);
    }
    emptyTimeout: string | number | NodeJS.Timeout | undefined;
    emptySelected = (choice: any) => {
      this.setState({  emptyId: choice })
      clearTimeout(this.emptyTimeout);
      this.emptyTimeout = setTimeout(() => {
        this.getProductsList()
      }, 1000);
    }

    categryTimeout: string | number | NodeJS.Timeout | undefined;
    categorySelected = (choice: any) => {
      this.setState({ category: choice })
      clearTimeout(this.categryTimeout);
      this.categryTimeout = setTimeout(() => {
        this.getProductsList()
      }, 1000);
    }
    brandTimeout: string | number | NodeJS.Timeout | undefined;
    brandSelected = (choice: any) => {
      this.setState({ brandId: choice })
      clearTimeout(this.brandTimeout);
      this.brandTimeout = setTimeout(() => {
        this.getProductsList()
      }, 1000);
    }
    driverTimeout: string | number | NodeJS.Timeout | undefined;
    driverSelected = (selected:any, index?:any) : any=> {
      this.setState({page:1})
      if(index?.option?.id === 0 || selected.length === 0)
        {
          this.setState({
            syncStatus: 1 , 
            dreiverId:[],
            showSyncFilter:false
          })
  
        }
        else 
        this.setState({
          dreiverId: selected ,
            showSyncFilter:true,
            syncStatus: null,
        });
        clearTimeout(this.driverTimeout);
        this.driverTimeout = setTimeout(() => {
          this.setState({brandId:[], category:[], statusId:[], selectedList:[]})
          this.getProductsList()
          this.getBrandList()
          this.getCategoryList()
        }, 1000);
      };
     
    searchTimeout: string | number | NodeJS.Timeout | undefined;
    handleSearch = (event: any) => {
      this.setState({ search: event.currentTarget.value })
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.getProductsList()
      }, 1000);
    };
    writersTimeOut: string | number | NodeJS.Timeout | undefined;
    writerSelected = (event:any)=>{
      this.setState({writers: event});
      clearTimeout(this.writersTimeOut);
      this.writersTimeOut = setTimeout(() => {
        //get writers
      }, 1000);
    }
    handleSelect = (selected?: any) => {
      const {selectedList} = this.state;
      let list = [...selectedList];
      var found = false;
      for( var i = 0; i < selectedList.length ; i++){
        if(selectedList[i].id_select === selected?.id_select){
            found = true;
            list.splice(i,1)
            this.setState({ selectedList: list })
        }
      }
      if(!found){
        list.push(selected)
              this.setState({ selectedList: list })
      }
    }
    operationalGroup = () => {
      const { syncStatus, idSelectedList , selectedList} = this.state;
      if(selectedList.length > 0){
      let idList = [...idSelectedList];
      const selectedObj = [...this.state.multiSelectedList]
  
      
      if(Number(syncStatus === 1)){
    const siteSelectedCount = [];
        for (let i = 0; i < selectedList?.length; i++) {
          const dbNameLsit : string[] = []
          const obj : any = {
            mId: "",
            db_name: "",
          }
          obj.mId=selectedList[i]?.mId;
            for (let j = 0; j < selectedList[i]?.relationship_products?.length; j++) {
              if(selectedList[i]?.relationship_products[j]?.site_select === true){
                dbNameLsit.push(selectedList[i]?.relationship_products[j].db_name)
              siteSelectedCount.push(selectedList[i]?.relationship_products[j].db_name)
              idList.push(selectedList[i]?.relationship_products[j].id_select)
              }
            }
            obj.db_name = dbNameLsit.toString()
            selectedObj.push(obj);
          }
      if(siteSelectedCount.length>0){
        this.setState({ multiSelectedList: selectedObj });
        this.setState({ idSelectedList: idList });
  this.setState({ openGroupOperationModal: true })
      }else{
             toast.error("حداقل یک سایت را انتخاب کنید")
          }
    }else{
    this.setState({ openGroupOperationModal: true })
      for (let i = 0; i < selectedList?.length; i++) {
          if (!idList.includes(selectedList[i].id_select)) {
            idList.push(selectedList[i].id_select)
            this.setState({ idSelectedList: idList })
          }
        }
      }
      // 
    
    }
  else{
    toast.error("حداقل یک محصول را انتخاب کنید")
  }
    }
    priceOffTimeout: string | number | NodeJS.Timeout | undefined;
    handleChangeOffPrice = (event: any, item: any, index: number) => {
      const { name } = event.target;
      var value = (event.target.value).replace(/,/g, '');
      const newState = [...this.state.productListData];
  
      const re = /^[0-9\b]+$/;
  
      if (value === '' || re.test(value)) {
        newState[index] = {
          ...newState[index],
          [name]: value
        };
        this.setState({ productListData: newState })
        clearTimeout(this.priceOffTimeout);
        this.priceOffTimeout = setTimeout(() => {
          this.updateSingleDiscount(value, item)
        }, 1000);
      }
    }
    priceTimeout: string | number | NodeJS.Timeout | undefined;
    handleChangePrice = (event: any, item: any, index: number) => {
      const { name } = event.target;
      var value = (event.target.value).replace(/,/g, '');
      const newState = [...this.state.productListData];
  
      const re = /^[0-9\b]+$/;
  
      if (value === '' || re.test(value)) {
        newState[index] = {
          ...newState[index],
          [name]: value
        };
        this.setState({ productListData: newState })
        clearTimeout(this.priceTimeout);
        this.priceTimeout = setTimeout(() => {
  
          this.updateSinglePrice(value, item)
        }, 1000);
      }
    };
    paginationTimeOut: string | number | NodeJS.Timeout | undefined; 
    handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
      this.setState({ page: value })
      clearTimeout(this.paginationTimeOut);
      this.paginationTimeOut = setTimeout(() => {
        this.getProductsList()
      }, 800);
      
    }
    paginationSelectTimeOut: string | number | NodeJS.Timeout | undefined; 
    handlePaginationSelect=(event:any)=>{
      this.setState({
        rowCount: event.currentTarget.value,
        page:1
      })
      clearTimeout(this.paginationSelectTimeOut);
      this.paginationSelectTimeOut = setTimeout(() => {
        this.getProductsList()
      }, 800);
      
    }
    syncProductsTimeOut: string | number | NodeJS.Timeout | undefined; 
    handlesyncProducts=( event: React.MouseEvent<HTMLElement>,
      newAlignment: any)=>{
      this.setState({
        syncStatus2:newAlignment === "null" ? JSON.parse(newAlignment) : newAlignment,
      })
      clearTimeout(this.syncProductsTimeOut);
      this.paginationSelectTimeOut = setTimeout(() => {
        this.getProductsList()
        this.getBrandList()
        this.getCategoryList()
      }, 1000);
    }
    async updateSinglePrice(event: string, item: any) {
      call_api({
        address_api: `${AppConstants.base_url_api}admin/products/${item.id_select}`,
        method_api: "PUT",
        body: JSON.stringify({
          price: event
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            toast.success("با موفقیت انجام شد ");
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    async updateSingleDiscount(event: string, item: any) {
      call_api({
        address_api: `${AppConstants.base_url_api}admin/products/${item.id_select}`,
        method_api: "PUT",
        body: JSON.stringify({
          discount: event,
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            toast.success("با موفقیت انجام شد ");
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    handleOpenLinkModal = (selected: any,index:number) => {
      this.setState({ openLinkModal: true , relationshipProducts : selected?.relationship_products, selectedRow :  index})
    }
    handleClosePriceModal = () => {
      this.setState({ openPriceModal: false })
    }
    handleBuyPriceModal = (selected: any) => {
      this.setState({ openBuyPriceModal: true })
    }
    handleCloseBuyPriceModal = () => {
      this.setState({ openBuyPriceModal: false })
    }
    handleCloseGroupOperationModal = () => {
      this.setState({ openGroupOperationModal: false })
      this.setState({ idSelectedList: [] , multiSelectedList: []})
    }
    handleCloseLinkModal = () => {
      this.setState({ openLinkModal: false })
    }
    async handleGroupOperation() {
      const { priceIncrease, priceReduction, syncStatus , multiSelectedList, weight, idSelectedList, groupChangeSelectedOption, groupDiscount, groupPrice, inventory } = this.state;
      const data = new FormData()
      this.setState({ submitLoading: true })
      if(Number(syncStatus) === 1){
      data.append('mId', JSON.stringify(multiSelectedList))
      }else{
        data.append('id_select', idSelectedList.toString())
      }
      if (groupChangeSelectedOption === 'تغییر ثابت') {
        weight && data.append('weight', weight)
        groupDiscount && data.append('discount', groupDiscount)
        groupPrice && data.append('price', groupPrice)
        inventory && data.append('quantity', inventory)
        const otpFixedSchema = yup.object({
          weight: yup.number(),
          groupDiscount: yup.number(),
          groupPrice: yup.number(),
          inventory: yup.number()
        });
        try {
          this.setState({ submitLoading: true })
          await otpFixedSchema.validate({ priceReduction }, { abortEarly: false });
          if(Number(syncStatus)===1){
            this.updateMultipleSync(data)
          }else{
            this.updateMultiple(data)
          }
        } catch (err: any) {
          this.setState({ submitLoading: false, errorsList: err.inner })
        }
      }
      if (groupChangeSelectedOption === 'تغییر درصدی افزایشی') {
        priceIncrease && data.append('price', `+${priceIncrease}`)
        const otpPriceIncreaseSchema = yup.object({
          priceIncrease: yup.string().required(" قیمت الزامی می باشد  "),
        });
        try {
          this.setState({ submitLoading: true })
          await otpPriceIncreaseSchema.validate({ priceIncrease }, { abortEarly: false });
          if(Number(syncStatus)===1){
            this.updateMultipleSync(data)
          }else{
            this.updateMultiple(data)
          }
        } catch (err: any) {
          this.setState({ submitLoading: false, errorsList: err.inner })
        }
      }
      if (groupChangeSelectedOption === 'تغییر درصدی کاهشی') {
        priceReduction && data.append('price', `-${priceReduction}`)
        const otpPriceReductionSchema = yup.object({
          priceReduction: yup.string().required(" قیمت الزامی می باشد  "),
        });
        try {
          this.setState({ submitLoading: true })
          await otpPriceReductionSchema.validate({ priceReduction }, { abortEarly: false });
          if(Number(syncStatus)===1){
            this.updateMultipleSync(data)
          }else{
            this.updateMultiple(data)
          }
        } catch (err: any) {
          this.setState({ submitLoading: false, errorsList: err.inner })
        }
      }
    }
    tagTimeout: string | number | NodeJS.Timeout | undefined;
  tagSelected = (choice: any) => {
    this.setState({ tag: choice});
    clearTimeout(this.tagTimeout);
    this.tagTimeout = setTimeout(() => {
      this.getProductsList()
    }, 1000);
  }
  tagSearchTimeout: string | number | NodeJS.Timeout | undefined;

  handleTagSearch = (choice:any) =>{
    clearTimeout(this.tagSearchTimeout);
    if(choice.length > 2)
    this.tagSearchTimeout = setTimeout(() => {
      this.getTagSearch(choice)
    }, 1000);
  }
  async getTagSearch(search?:string) {
    this.setState({tagLoading: true})
    let parameters = {
      search: search ? search: null,
      is_search:"1",
      is_show: JSON.stringify(1)
    }
    let url = new URL(`${AppConstants.base_url_api}admin/tags`)
    for (const [key, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(key, value)
      }
    }
    call_api({
      address_api: url.search ? `${AppConstants.base_url_api}admin/tags${url.search}` : `${AppConstants.base_url_api}admin/tags`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(async ([status, j]) => {
        if (status === 200) {
          if (j.data) {
              
              this.setState({
                tagList: j.data?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null) , tagLoading: false
              })
          }
        } else if (status === 401) {
          this.setState({ tagLoading: false })
          localStorage.clear();
        } else {
          this.setState({tagLoading: false })
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

    async updateMultiple(data: any) {
      call_api({
        address_api: `${AppConstants.base_url_api}admin/product/update-multiple`,
        method_api: "POST",
        body: data,
        file: true
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            this.getProductsList();
            this.setState({
              groupChangeSelectedOption: "تغییر ثابت",
              priceIncrease: "",
              priceReduction: "",
              weight: "",
              groupDiscount: "",
              groupPrice: "",
              inventory: "",
              openGroupOperationModal: false,
              submitLoading: false, errorsList: []
            })
            toast.success("با موفقیت انجام شد ");
          } else if (status === 401) {
            this.setState({ submitLoading: false, errorsList: [] })
            localStorage.clear();
          } else {
            this.setState({ submitLoading: false, errorsList: [] })
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    async updateMultipleSync(data: any) {
      call_api({
        address_api: `${AppConstants.base_url_api}admin/sync-products/update-multiple`,
        method_api: "POST",
        body: data,
        file: true
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            this.getProductsList();
            this.setState({
              groupChangeSelectedOption: "تغییر ثابت",
              priceIncrease: "",
              priceReduction: "",
              weight: "",
              groupDiscount: "",
              groupPrice: "",
              inventory: "",
              openGroupOperationModal: false,
              submitLoading: false, errorsList: [] , multiSelectedList:[]
            })
            toast.success("با موفقیت انجام شد ");
          } else if (status === 401) {
            this.setState({ submitLoading: false, errorsList: [] })
            localStorage.clear();
          } else {
            this.setState({ submitLoading: false, errorsList: [] })
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    handleShowNotes = (item:any) =>{
      this.setState({toggleShowNotesModal: true})
        // let parameters = {
        //   is_search : JSON.stringify(1),
        //   db_name:item?.db_name ? item?.db_name : null,
        // }
        // let url = new URL(`${AppConstants.base_url_api}admin/notes`)
        // for (const [key, value] of Object.entries(parameters)) {
        //   if (value) {
        //     url.searchParams.append(key, value)
        //   }
        // }
        // call_api({
        //   address_api: url.search ? `${AppConstants.base_url_api}admin/notes${url.search}` : `${AppConstants.base_url_api}admin/notes`,
        //   method_api: "GET"
        // })
        //   .then(parseJSON)
        //   .then(([status, j]) => {
        //     if (status === 200) {
        //       if (j.data) {
                
        //       }
        //     } else if (status === 401) {
        //       localStorage.clear();
        //     } else {
        //       toast.error(j?.errors);
        //     }
        //   })
        //   .catch((error) => {
        //     console.error(error);
        //   });
        };
    async getProductsList() {
      const { category, tag,emptyId, dreiverId,page,syncStatus,syncStatus2,showSyncFilter ,brandId, search, statusId, rowCount , hideSync} = this.state;
      this.setState({ loading: true })
      const usersSelectedList = [];
      for (var a = 0; a < category?.length; a++) {
        usersSelectedList.push(category[a]?.id)
        // this.setState({ usersUuid: usersSelectedList })
      }
      const categoryNameList = [];
      for (var b = 0; b < category?.length; b++) {
        categoryNameList.push(category[b]?.english_name)
      }
      const driversSelectedList = [];
      for (var c = 0; c < dreiverId.length; c++) {
        driversSelectedList.push(dreiverId[c]?.value?.name)
        // this.setState({ usersUuid: driversSelectedList })
      }
      const brandList = [];
      for (var d = 0; d < brandId.length; d++) {
        brandList.push(brandId[d]?.id)
      }
      const brandNameList = [];
      for (var e = 0; e < brandId.length; e++) {
        brandNameList.push(brandId[e]?.english_name)
      }
      const statusSelectedList = [];
      for (var f = 0; f < statusId.length; f++) {
        statusSelectedList.push(statusId[f]?.value)
      }
      const emptySelectedList = [];
      for (var g = 0; g < emptyId.length; g++) {
        emptySelectedList.push(emptyId[g]?.value)
      }
      const tagsList = [];
      for (var h = 0; h < tag.length; h++) {
        tagsList.push(tag[h]?.id)
      }
      const tagsNameList = [];
      for (var i = 0; i < tag.length; i++) {
        tagsNameList.push(tag[i]?.english_name)
      }
  
      let parameters = {
        brand_id: ((Number(syncStatus2) === 1 || Number(syncStatus) === 1) && brandNameList.length > 0) ? brandNameList :   ((Number(syncStatus2) !== 1 || Number(syncStatus) !== 1) && brandList?.length > 0 )? brandList : null,
        search: search ? search : null,
        status: statusId?.length > 0 ? statusSelectedList : null,
        empty: emptyId?.length > 0 ? emptySelectedList : null,
        page: page ? page.toString() : null,
        paginate: rowCount,
        is_sync:(showSyncFilter && Number(syncStatus2) !== null) ? Number(syncStatus2) : (!showSyncFilter && Number(syncStatus) !== null) ? Number(syncStatus) : null,
        is_delete:1,
        hide_sync:hideSync,
        tag_id: ((Number(syncStatus2) === 1 || Number(syncStatus) === 1) && tagsNameList.length > 0) ? tagsNameList :   ((Number(syncStatus2) !== 1 || Number(syncStatus) !== 1) && tagsList?.length > 0 )? tagsList : null,
        category_id: ((Number(syncStatus2) === 1 || Number(syncStatus) === 1) && categoryNameList.length > 0) ? categoryNameList :   ((Number(syncStatus2) !== 1 || Number(syncStatus) !== 1) && usersSelectedList?.length > 0 ) ? usersSelectedList : null,
        db_name: dreiverId?.length > 0 ? driversSelectedList : null,
      }
      let url = new URL(`${AppConstants.base_url_api}admin/products`)
      for (const [key, value] of Object.entries(parameters)) {
        if (value) {
          url.searchParams.append(key, value.toString())
        }
      }
      call_api({
        address_api: url.search ? `${AppConstants.base_url_api}admin/products${url.search}` : `${AppConstants.base_url_api}admin/products`,
        method_api: "GET"
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            if(j.data){

              this.setState({
                productListData: j.data?.data, loading: false , total: j.data?.total
              })
            }
          } else if (status === 401) {
            this.setState({ loading: false })
            localStorage.clear();
          } else {
            this.setState({ loading: false })
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    async getCategoryList() {
          const { dreiverId , syncStatus2 , syncStatus} = this.state;
  
      const driversSelectedList = [];
      for (var i = 0; i < dreiverId.length; i++) {
        driversSelectedList.push(dreiverId[i]?.value?.name)
      }
      let parameters = {
        db_name: dreiverId?.length > 0 ? driversSelectedList : null,
        sync: (Number(syncStatus2) === 1 || Number(syncStatus) === 1) ? 1 : null
      }
       let url = new URL(`${AppConstants.base_url_api}admin/categories`)
      for (const [key, value] of Object.entries(parameters)) {
        if (value) {
          url.searchParams.append(key, value.toString())
        }
      }
      call_api({
        address_api: url.search ? `${AppConstants.base_url_api}admin/categories${url.search}` : `${AppConstants.base_url_api}admin/categories`,
        method_api: "GET"
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            if (j.data) {
              this.setState({
                categoryList: j.data?.data?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null),
              })
            }
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    async getBrandList() {
      const { dreiverId , syncStatus2 , syncStatus} = this.state;
  
      const driversSelectedList = [];
      for (var i = 0; i < dreiverId.length; i++) {
        driversSelectedList.push(dreiverId[i]?.value?.name)
      }
      let parameters = {
        db_name: dreiverId?.length > 0 ? driversSelectedList : null,
        sync: (Number(syncStatus2) === 1 || Number(syncStatus) === 1 ) ? 1 : null
      }
      let url = new URL(`${AppConstants.base_url_api}admin/brands`)
      for (const [key, value] of Object.entries(parameters)) {
        if (value) {
          url.searchParams.append(key, value.toString())
        }
      }
      call_api({
        address_api:  url.search ? `${AppConstants.base_url_api}admin/brands${url.search}` : `${AppConstants.base_url_api}admin/brands`,
        method_api: "GET"
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            this.setState({
              brandList: j?.data?.data?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null),
            })
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
     //Single
    async handleDeleteSingle() {
      const { rowData } = this.state;
      call_api({
        address_api: `${AppConstants.base_url_api}admin/products/${rowData.id_select}`,
        method_api: "DELETE"
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            this.setState({
              toggleModal: { status: !this.state.toggleModal.status, buttonClick: alertButtonsState.DELETE }
            })
            this.getProductsList();
    
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    
    async handleRecycleSubmit() {
      const { rowData } = this.state;
      call_api({
        address_api: `${AppConstants.base_url_api}admin/product/recovery/${rowData.id_select}`,
        method_api: "PATCH",
        body: JSON.stringify({
       
    
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            toast.success(j.message);
            this.getProductsList();
            this.setState({
              toggleModal: { status: !this.state.toggleModal.status, buttonClick: alertButtonsState.RECYCLE }
            })
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j.message);
            this.setState({
              toggleModal: { status: !this.state.toggleModal.status, buttonClick: alertButtonsState.RECYCLE }
            })
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    async handleConfirmSingle() {
      const { rowData } = this.state;
      call_api({
        address_api: `${AppConstants.base_url_api}admin/products/confirmed/${rowData.id_select}`,
        method_api: "PUT",
        body: JSON.stringify({
          
          confirmed: rowData.confirmed === 1 ? '0' : '1',
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            this.setState({
              toggleModal: { status: !this.state.toggleModal.status, buttonClick: alertButtonsState.NOTCONFIRMED }
            })
            this.getProductsList();
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    async handleChangeStatusSingle() {
      const { rowData } = this.state;
      call_api({
        address_api: `${AppConstants.base_url_api}admin/products/status/${rowData.id_select}`,
        method_api: "PUT",
        body: JSON.stringify({
          status: rowData?.dont_sell?.name === 1 ? "do_sell" : "dont_sell"
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            this.setState({
              toggleModal: { status: !this.state.toggleModal.status, buttonClick: alertButtonsState.NOSELLING }
            })
            this.getProductsList();
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    async handleChangeIndexSingle() {
      const { rowData } = this.state;
      call_api({
        address_api: `${AppConstants.base_url_api}admin/product/no-index/${rowData.id_select}`,
        method_api: "PUT",
        body: JSON.stringify({
          no_index: rowData?.no_index === 0 ? "1" : "0"
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            this.setState({
              toggleModal: { status: !this.state.toggleModal.status, buttonClick: alertButtonsState.NOINDEX }
            })
            this.getProductsList();
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    //Multiple 
    async handleDeleteMultiple() {
      const { idSelectedList } = this.state;
      call_api({
        address_api: `${AppConstants.base_url_api}admin/product/delete-multiple`,
        method_api: "POST",
        body: JSON.stringify({
          id_select:idSelectedList.toString(),
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            toast.success("با موفقیت انجام شد ");
            this.getProductsList();
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    async handleRecoveryMultiple() {
      const { idSelectedList } = this.state;
      call_api({
        address_api: `${AppConstants.base_url_api}admin/product/recovery-multiple`,
        method_api: "POST",
        body: JSON.stringify({
          id_select:idSelectedList.toString(),
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            toast.success("با موفقیت انجام شد ");
            this.getProductsList();
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    async handleConfirmeMultiple(id: number) {
      const { idSelectedList } = this.state;
      call_api({
        address_api: `${AppConstants.base_url_api}admin/product/confirmed-update-multiple`,
        method_api: "POST",
        body: JSON.stringify({
          id_select:idSelectedList.toString(),
          confirmed: id.toString()
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            toast.success("با موفقیت انجام شد ");
            this.getProductsList();
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    async handleChangeMultipleStatus(status: string) {
      const { idSelectedList } = this.state;
      call_api({
        address_api: `${AppConstants.base_url_api}admin/product/status-update-multiple`,
        method_api: "PUT",
        body: JSON.stringify({
          id_select:idSelectedList.toString(),
          status: status
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            toast.success("با موفقیت انجام شد ");
            this.getProductsList();
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    async handleChangeMultipleIndex(index:string) {
      const { idSelectedList } = this.state;
      call_api({
        address_api: `${AppConstants.base_url_api}admin/product/update-multiple/no-index`,
        method_api: "POST",
        body: JSON.stringify({
          id_select:idSelectedList.toString(),
          no_index:index
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            toast.success("با موفقیت انجام شد ");
            this.getProductsList();
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    //
  
    handlePagination = (event: any) => {
      this.setState({ paginate: event.currentTarget.textContent })
      this.getProductsList()
    }
    handleDelete = (event: any) => {
      this.setState({ rowData: event.item })
  
      this.setState({
        toggleModal: {
          status: !this.state.toggleModal.status,
          buttonClick: alertButtonsState.DELETE
  
        }
      })
    }
    handleRecycle = (event: any) => {
      this.setState({ rowData: event.item })
  
      this.setState({
        toggleModal: {
          status: !this.state.toggleModal.status,
          buttonClick: alertButtonsState.RECYCLE
  
        }
      })
    }
    handleConfirm = (event: any) => {
      this.setState({ rowData: event.item })
      this.setState({
        toggleModal: {
          status: !this.state.toggleModal.status,
          buttonClick: alertButtonsState.NOTCONFIRMED
        }
      })
    }
    handleUnLink = (event: any) => {
      this.setState({ rowData: event.item })
      this.setState({
        toggleModal: {
          status: !this.state.toggleModal.status,
          buttonClick: alertButtonsState.UNLINK
        }
      })
    }
    handleNoSelling = (event: any) => {
      this.setState({ rowData: event.item })
      this.setState({
        toggleModal: {
          status: !this.state.toggleModal.status,
          buttonClick: alertButtonsState.NOSELLING
        }
      })
    }
    handleNoIndex = (event: any) => {
      this.setState({ rowData: event.item })
      this.setState({
        toggleModal: {
          status: !this.state.toggleModal.status,
          buttonClick: alertButtonsState.NOINDEX
        }
      })
    }
    priceIncrease = (event: any) => {
      if (Number(event.currentTarget.value) > 100) {
        toast.error("عدد وارد شده باید بین 0 و 100 باشد ");
      } else {
        this.setState({ priceIncrease: event.currentTarget.value })
      }
    }
    priceReduction = (event: any) => {
      if (Number(event.currentTarget.value) > 100) {
        toast.error("عدد وارد شده باید بین 0 و 100 باشد ");
      } else {
        this.setState({ priceReduction: event.currentTarget.value })
      }
    }
    
    relationshipProductsTimeout: string | number | NodeJS.Timeout | undefined;
    changeRelationshipProductsPrice = (event: any, item: any, index: number) => {
  
      const { name } = event.target;
      var value = (event.target.value).replace(/,/g, '');
      const newState = [...this.state.relationshipProducts];
  
      const re = /^[0-9\b]+$/;
  
      if (value === '' || re.test(value)) {
        newState[index] = {
          ...newState[index],
          [name]: value
        };
        this.setState({ relationshipProducts: newState })
        clearTimeout(this.relationshipProductsTimeout);
        this.relationshipProductsTimeout = setTimeout(() => {
          if (Number(value) >= Number(newState[index].discount)) {
            this.updateSinglePrice2(value, item)
          }else{
            toast.error("قیمت نمی‌تواند کوچک‌تر از تخفیف باشد")
          }
        }, 1000);
      }
    };
    relationshipProductsDiscountTimeout: string | number | NodeJS.Timeout | undefined;
    changeRelationshipProductsDiscount = (event: any, item: any, index: number) => {
      const { name } = event.target;
      var value = (event.target.value).replace(/,/g, '');
      const newState = [...this.state.relationshipProducts];
  
      const re = /^[0-9\b]+$/;
  
      if (value === '' || re.test(value)) {
        newState[index] = {
          ...newState[index],
          [name]: value
        };
        this.setState({ relationshipProducts: newState })
        clearTimeout(this.relationshipProductsDiscountTimeout);
        this.relationshipProductsDiscountTimeout = setTimeout(() => {
          this.updateSingleDiscount2(value, item)
        }, 1000);
      }
    }
    async updateSinglePrice2(event: string, item: any) {
      call_api({
        address_api: `${AppConstants.base_url_api}admin/products/${item.id_select}`,
        method_api: "PUT",
        body: JSON.stringify({
          price: event
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            toast.success("با موفقیت انجام شد ");
      this.getProductsList();
  
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    async updateSingleDiscount2(event: string, item: any) {
      call_api({
        address_api: `${AppConstants.base_url_api}admin/products/${item.id_select}`,
        method_api: "PUT",
        body: JSON.stringify({
          discount: event,
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            toast.success("با موفقیت انجام شد ");
      this.getProductsList();
  
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    async handleUnLinkSingle(item:any) {
       this.setState({confirmUnlink: true, unlinkedItem: item})
       
     };
     confirmUnlinkSingle(){
       const {unlinkedItem} = this.state;
      call_api({
        address_api: `${AppConstants.base_url_api}admin/product/unsync`,
        method_api: "POST",
        body: JSON.stringify({
          id_select: unlinkedItem?.id_select,
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
           toast.success("با موفقیت انجام شد")
          this.setState({ openLinkModal:false, confirmUnlink: false})
          this.getProductsList()
  
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    handleSelectedSite = (item :any , index :number , index2:number) => {
      
      const newState = [...this.state.productListData];
      const selectedSite = [...item.relationship_products];
      if(selectedSite[index2].site_select === null  || selectedSite[index2].site_select === false){
        selectedSite[index2].site_select = true
      }else{
        selectedSite[index2].site_select = false
      }
        newState[index].relationship_products = selectedSite
        this.setState({ productListData: newState })
      }
  
      handleSelectedSiteModal = (index:number) => {
        const {selectedRow , productListData,relationshipProducts} = this.state;
        const newState = [...productListData];
        const selectedSite = [...relationshipProducts];
        if(selectedSite[index].site_select === null  || selectedSite[index].site_select === false){
          selectedSite[index].site_select = true
        }else{
          selectedSite[index].site_select = false
        }
          newState[selectedRow].relationship_products = selectedSite
          this.setState({ productListData: newState })
        
      }
    
      //Single sync
    siteSelectedCount = (rowData:any) => {
    const driversSelectedList = [];
    if(this.state.syncStatus === 1){
      for (var i = 0; i < rowData?.relationship_products?.length; i++) {
        if(rowData?.relationship_products[i]?.site_select === true)
        driversSelectedList.push(rowData?.relationship_products[i]?.db_name)
      }
      if(driversSelectedList.length>0){
        return true
      }else{
        return false
      }
    }else{
      return true
    }
    }
    async handleDeleteSingleSync() {
      const { rowData } = this.state;
      const driversSelectedList = [];
      for (var i = 0; i < rowData?.relationship_products?.length; i++) {
        if(rowData?.relationship_products[i]?.site_select === true)
        driversSelectedList.push(rowData?.relationship_products[i]?.db_name)
      }
      call_api({
        address_api: `${AppConstants.base_url_api}admin/sync-products/${rowData.id}`,
        method_api: "DELETE",
        body: JSON.stringify({
          mId: rowData.mId,
          db_name:driversSelectedList.toString(),
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            this.setState({
              toggleModal: { status: !this.state.toggleModal.status, buttonClick: alertButtonsState.DELETE }
            })
            this.getProductsList();
  
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    async handleUnLinkSingleSync() {
      const { rowData } = this.state;
      const driversSelectedList = [];
      for (var i = 0; i < rowData?.relationship_products?.length; i++) {
        if(rowData?.relationship_products[i]?.site_select === true)
        driversSelectedList.push(rowData?.relationship_products[i]?.db_name)
      }
      call_api({
        address_api: `${AppConstants.base_url_api}admin/sync-products/unsync-custom`,
        method_api: "POST",
        body: JSON.stringify({
          mId: rowData.mId,
          db_name:driversSelectedList.toString(),
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            this.setState({
              toggleModal: { status: !this.state.toggleModal.status, buttonClick: alertButtonsState.UNLINK }
            })
            this.getProductsList();
  
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
  
    async handleRecycleSubmitSync() {
      
      const { rowData } = this.state;
      const driversSelectedList = [];
      for (var i = 0; i < rowData?.relationship_products?.length; i++) {
        if(rowData?.relationship_products[i]?.site_select === true)
        driversSelectedList.push(rowData?.relationship_products[i]?.db_name)
      }
      call_api({
        address_api: `${AppConstants.base_url_api}admin/sync-products/recovery`,
        method_api: "POST",
        body: JSON.stringify({
          mId: rowData.mId,
          db_name:driversSelectedList.toString(),
  
  
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            toast.success(j.message);
            this.getProductsList();
            this.setState({
              toggleModal: { status: !this.state.toggleModal.status, buttonClick: alertButtonsState.RECYCLE }
            })
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j.message);
            this.setState({
              toggleModal: { status: !this.state.toggleModal.status, buttonClick: alertButtonsState.RECYCLE }
            })
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    async handleConfirmSingleSync() {
      const { rowData } = this.state;
      const driversSelectedList = [];
      for (var i = 0; i < rowData?.relationship_products?.length; i++) {
        if(rowData?.relationship_products[i]?.site_select === true)
        driversSelectedList.push(rowData?.relationship_products[i]?.db_name)
      }
      call_api({
        address_api: `${AppConstants.base_url_api}admin/sync-products/confirmed`,
        method_api: "PUT",
        body: JSON.stringify({
          db_name:driversSelectedList.toString(),
          confirmed: rowData.confirmed === 1 ? '0' : '1',
          mId: rowData.mId,
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            this.setState({
              toggleModal: { status: !this.state.toggleModal.status, buttonClick: alertButtonsState.NOTCONFIRMED }
            })
            this.getProductsList();
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    async handleChangeStatusSingleSync() {
      const { rowData } = this.state;
      const driversSelectedList = [];
      for (var i = 0; i < rowData?.relationship_products?.length; i++) {
        if(rowData?.relationship_products[i]?.site_select === true)
        driversSelectedList.push(rowData?.relationship_products[i]?.db_name)
      }
      call_api({
        address_api: `${AppConstants.base_url_api}admin/sync-products/status`,
        method_api: "POST",
        body: JSON.stringify({
            status: rowData?.dont_sell?.name === 1 ? "do_sell" : "dont_sell",
            mId: rowData.mId,
            db_name:driversSelectedList.toString(),
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            this.setState({
              toggleModal: { status: !this.state.toggleModal.status, buttonClick: alertButtonsState.NOSELLING }
            })
            this.getProductsList();
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    //Multiple sync
    async handleDeleteMultipleSync() {
      
      const { multiSelectedList } = this.state;
      call_api({
        address_api: `${AppConstants.base_url_api}admin/sync-products/delete-multiple`,
        method_api: "POST",
        body: JSON.stringify({
          mId:JSON.stringify(multiSelectedList)
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            toast.success("با موفقیت انجام شد ");
            this.getProductsList();
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    async handleRecoveryMultipleSync() {
    
      const { multiSelectedList } = this.state;
      call_api({
        address_api: `${AppConstants.base_url_api}admin/sync-products/recovery-multiple-sync`,
        method_api: "POST",
        body: JSON.stringify({
          mId:JSON.stringify(multiSelectedList)
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            toast.success("با موفقیت انجام شد ");
            this.getProductsList();
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    async handleConfirmeMultipleSync(id: number) {
      const { multiSelectedList } = this.state;
      call_api({
        address_api: `${AppConstants.base_url_api}admin/sync-products/confirmed-update-multiple`,
        method_api: "POST",
        body: JSON.stringify({
          mId:JSON.stringify(multiSelectedList),
          confirmed: id.toString()
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            toast.success("با موفقیت انجام شد ");
            this.getProductsList();
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    async handleChangeMultipleStatusSync(status: string) {
      const { multiSelectedList } = this.state;
      call_api({
        address_api: `${AppConstants.base_url_api}admin/sync-products/status-update-multiple`,
        method_api: "POST",
        body: JSON.stringify({
          mId:JSON.stringify(multiSelectedList),
          status: status
        })
      })
        .then(parseJSON)
        .then(([status, j]) => {
          if (status === 200) {
            toast.success("با موفقیت انجام شد ");
            this.getProductsList();
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  async handleChangeMultipleLinkSync() {
    const { multiSelectedList } = this.state;
    call_api({
      address_api: `${AppConstants.base_url_api}admin/product/unsync-multiple`,
      method_api: "POST",
      body: JSON.stringify({
        mId:JSON.stringify(multiSelectedList),
      })
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          toast.success("با موفقیت انجام شد ");
          this.getProductsList();
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
    exportToExcel = async (excelData: any, fileName: any) => {
      const ws = XLSX.utils.json_to_sheet(excelData);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: "array" });
      const data = new Blob([excelBuffer], { type: this.state.fileType });
      FileSaver.saveAs(data, fileName + this.state.fileExtension);
    }
   
    handleDetailPannel = (item:any, index:number) =>{
      this.setState({selectedRowIndex:index, detailPannelLoading: true})

        if(this.state.toggleDetailPannel !== item?.id_select){

          call_api({
            address_api: `${AppConstants.base_url_api}admin/products/list-details/${item?.id_select}`,
            method_api: "GET",
            
          })
            .then(parseJSON)
            .then(([status, j]) => {
              if (status === 200) {
                this.setState({detailPannelData: j?.data, toggleDetailPannel: item?.id_select, detailPannelLoading: false})
              } else if (status === 401) {
                localStorage.clear();
              } else {
                toast.error(j?.errors);
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
   
      
    }
  }


export default ProductsListController;