
import {UsersNoteController} from "../../../controllers";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {  AiOutlineFileExcel } from "react-icons/ai";
import {  Header } from "../../../components";
import { CustomButton, DropDownIcon, InputTextLabel, Option } from "../../../components";
import { createTheme, ThemeProvider } from "@mui/material";
import { BiShowAlt } from "react-icons/bi";
import Pagination from "@mui/material/Pagination";
import Tooltip from "@mui/material/Tooltip";
import Select, { CSSObjectWithLabel } from "react-select";
import moment from "moment-jalaali";
import { TfiFilter } from "react-icons/tfi";

class UsersNote extends UsersNoteController {

  componentDidMount() {
   window.scrollTo(0, 0);
   this.getList()

  }
  render() {
    const theme = createTheme({
        typography: {
          fontFamily: "Vazir",
        },
      });
    type ListOfData = {
        item?: any;
        row: number;
        user: string;
        product: any;
        time: string;
      }; 
      const columns = [
        {
          accessorKey: "driver",
          header: "سایت",
          Cell: ({ cell }) => (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {cell.getValue<string>()}
            </div>
          ),
          size: 10,
        },
        {
          accessorKey: "user",
          header: "کاربر",
          Cell: ({ cell }) => (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {cell.getValue<string>()}
            </div>
          ),
          size: 10,
        },
        {
          accessorKey: "product",
          header: "محصول",
          Cell: ({ cell }) => (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                whiteSpace:"nowrap"
              }}
            >
              {cell.getValue<string>()}
            </div>
          ),
          size: 10,
        },
        {
          accessorKey: "time",
          header: "زمان ارسال",
          Cell: ({ cell }) => (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign:"center",
                flexDirection:"row-reverse"
              }}
            >
             <span >
              {moment(cell.getValue<string>()).format("jYYYY/jM/jD")} 
                
              </span>
              <span style={{paddingLeft:"10px"}}>
              {moment(cell.getValue<string>()).format("HH:mm:ss ")}
              </span>
            </div>
          ),
        },
      ] as MRT_ColumnDef<ListOfData>[];
    const { siteList, loadingSiteList } = this.props; 
    const { rowCount, currentItem, page, rowCountList, showFilters, allOption, total, 
      toggleModal, dreiverId, search, notesListData, loading } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <div className="users-note price-list">
          <Helmet>
            <title>یادداشت‌های کاربران</title>
          </Helmet>
            <Modal
                open={toggleModal}
                onClose={this.handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    overflowY: "scroll",
                    width: {
                      xxs: "90%",
                      xs: "90%",
                      sm: "90%",
                      md: "80%",
                      lg: "70%",
                      xl: "70%",
                    },
                    height: "max-content",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    fontSize:"14px",
                    p: 4,
                  }}
                >
                  <Typography
                    id="modal-modal-title"
                    variant="subtitle1"
                    sx={{marginBottom:"10px"}}
                  >
              یادداشت‌ها                  
              </Typography>
                 {currentItem?.item?.message}
                </Box>
            </Modal>
          <Header isActive= "isUsersNotes" title="یادداشت‌های کاربران"/>
          <div className="custom-card mb-3">
            {showFilters &&
              <>
                    <div className="filter-select-container mb-2 pe-3">
                      <div className="filter-options-container">
                        <div className="column-50">
                          <Select
                            styles={{
                              control: (baseStyles: any, state: any): CSSObjectWithLabel => ({
                                ...baseStyles,
                                boxShadow:"rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",
                                borderWidth: "1px",
                                backgroundColor: "#fff",
                                display: "flex",
                                flexDirection: "row-reverse",
                                textAlign: "right",
                                borderRadius: "4px",
                                outline: "unset",
                              }) as CSSObjectWithLabel,
                          
                              
                            }}
                            onChange={this.driverSelected}
                            components={{ Option }}
                            value={dreiverId}
                            isMulti={true}
                            closeMenuOnSelect={true}
                            noOptionsMessage={() => 'این مورد وجود ندارد!'}
                            menuPortalTarget={document.body}
                            options={[allOption, ...siteList]}
                            getOptionLabel={(option: any) => option?.value?.persian_name}
                            getOptionValue={(option: any) => option?.value?.name}
                            placeholder="سایت"
                            isClearable
                            loadingMessage={() => 'در حال بارگذاری  ...'}
                            isLoading={loadingSiteList}
                          />
                        </div>                      
                        <div className="column-50">
                          <InputTextLabel
                            type={"text"}
                            value={search}
                            onChange={(event) => this.handleSearch(event)}
                            placeholder="جستجو..."
                          />
                        </div>
                      </div>
                    </div>
              </>
            }
            <div className="table-container">
              <MaterialReactTable
                muiTablePaperProps={{
                  sx: { m: '1.3rem', border:"1px solid #e5e5e5" }}}
                    columns={columns}
                    data={
                        notesListData &&
                      notesListData?.length > 0 &&
                      notesListData.map((item: any, index: number) => {
                          return {
                            item: item,
                            driver: <img className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo} alt="" />,
                            user: item?.profile,
                            product: item?.product_name,
                            time: item.created_at && item.created_at,

                          };
                        })
                    }
                    enableRowNumbers={true}
                    rowNumberMode="static"
                    renderTopToolbarCustomActions={({ table }) => (
                        <Box
                          sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap', justifyContent:"space-between", width:"100%" }}
                        >
                          <CustomButton
                            loading={false}
                            onClick={() => this.exportToExcel(notesListData, "Excel Export")}
                            title="اکسل"
                            myStyle="excel-btn"
                            icon={<AiOutlineFileExcel />}
                          />
                          <Tooltip title="نمایش فیلترها"><span> <TfiFilter className='filter-icon' onClick={() => this.setState(prevState => ({
                                      showFilters: !prevState.showFilters
                                    }))
                                  } /></span></Tooltip>
                          <div className='custom-pagination'>
                          <Pagination  page={page} count={total ? Math.ceil(total / rowCount) : 1} onChange={this.handleChangePage} />
                          <DropDownIcon
                          value={rowCount}
                          optionList={rowCountList}
                          title={""}
                          onChange={(event) =>
                            {
                              this.handlePaginationSelect(event)
                          }
                        }
                        />
                        </div>
                        </Box>
                      )}
                    state={{ isLoading: loading, showProgressBars: loading }}
                    enablePagination={false}
                    renderBottomToolbarCustomActions={({table})=>{
                      return (
                        <div className="custom-pagination w-100">
                          <Pagination  page={page} count={total ? Math.ceil(total / rowCount) : 1} onChange={this.handleChangePage} />
                          <DropDownIcon
                          value={rowCount}
                          optionList={rowCountList}
                          title={""}
                          onChange={(event) =>
                            {
                              this.handlePaginationSelect(event)
                          }
                        }
                        />
                        </div>
                      );
                    }}
                    positionExpandColumn={"last"}
                    enableGlobalFilter={false}
                    enableColumnFilters={false}
                    enableDensityToggle={false}
                    enableFullScreenToggle={false}
                    enableHiding={false}
                    sortDescFirst={true}
                    enableColumnActions={false}
                    enableSorting={true}
                    enableRowActions
                    positionActionsColumn="last"
                    initialState={{
                      pagination: { pageSize: 100, pageIndex: 0 },
                      sorting: [
                        {
                          id: "time",
                          desc: true
                        }
                      ],
                    }}
                    localization={{
                      noRecordsToDisplay: "داده‌ای برای نمایش وجود ندارد!",
                      actions:"عملیات"
                    }}
                    muiTableProps={{
                      sx: {
                        border: "1px solid #e5e5e5",
                      },
                    }}
                    muiTableHeadCellProps={{
                      sx: {
                        border: "1px solid #e5e5e5",
                        textAlign: "center",
                        justifyContent:"center"
                      },
                    }}
                    muiTableBodyCellProps={{
                      sx: {
                        border: "1px solid #e5e5e5",
                        height: "fit-content",
                        textAlign:"center"

                      },
                    }}
                    displayColumnDefOptions={{
                      'mrt-row-expand': {
                        muiTableHeadCellProps: {
                          align: 'center',
                        },
                      },
                    }}
                    muiTableBodyRowProps={( {row,staticRowIndex} ) => ({
                      sx:{
                        height: "fit-content",
                        backgroundColor: (staticRowIndex % 2 === 0 ) ? '#f3f3f3'  : "",
                    }})}
                    renderRowActions={({ row, table }) => [
                      <Grid key={row.original.item?.id} sx={{justifyContent:"center", alignItems:"center", display:"flex"}}>
                    

                        <Tooltip title="نمایش">
                          <span>
                              <BiShowAlt className="icon-blue" onClick={()=> this.setState({toggleModal: true, currentItem: row?.original})} />
                          </span>
                        </Tooltip>
                      </Grid>,
                    ]}
                tableInstanceRef={this.tableInstanceRef}  
              />
            </div>
            </div>
        </div>
      </ThemeProvider>
    );
  }
}
const mapStateToProps = (dispatch: any) => {
  return {
    dispatch,
    siteList: dispatch.general.siteList,
    loadingSiteList: dispatch.general.loadingSiteList,
  };
};
export default connect(mapStateToProps)(UsersNote);
