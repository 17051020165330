import { Component } from 'react';
import { call_api, parseJSON } from "../../../core/service";
import { AppConstants } from "../../../core/constants";
import { toast } from "react-toastify";
import * as yup from 'yup'

interface IProps {
dispatch?:any,
siteList?:any,
loadingSiteList?:any,
userData?:any
}
interface IState {
  loading?: boolean,
  errorsList:any;
  slider:any;
  mlider:any;
  banner:any;
  linkStatusOptions:Array<{value:string, name:string}>;
  toggleSliderModal:any;
  toggleMliderModal:any;
  toggleBannerModal:any;
}

 class BannerManagementController extends Component<IProps, IState> {
  state: IState = {
    loading: false,
    errorsList:[],
    slider:[],
    mlider:[],
    banner:[],
    linkStatusOptions:[{value:"_self" , name:"نمایش در همان صفحه"}, {value:"_blank" , name:"نمایش در صفحه جدید"}],
    toggleSliderModal:{status: false, index:null},
    toggleMliderModal:{status: false, index:null},
    toggleBannerModal:{status: false, index:null},
  };
  deleteSliderImage = (index:number) => {
    const {slider} = this.state;
    let ids = [...slider]
      ids[index].image = "";
      this.setState({
        slider: ids,
        toggleSliderModal:{status: false, index:null}
      })
  }
  handleDeleteSliderImage = (index:number) => {
    
      this.setState({
        toggleSliderModal: {
          status:true,
          index:index
        }
      })
  }
  deleteMliderImage = (index:number) => {
    const {mlider} = this.state;
    let ids = [...mlider]
      ids[index].image = "";
      this.setState({
        mlider: ids,
        toggleMliderModal:{status: false, index:null}
      })
  }
  handleDeleteMliderImage = (index:number) => {
    this.setState({
      toggleMliderModal: {
        status:true,
        index:index
      }
    })
}
deleteBannerImage = (index:number) => {
  const {banner} = this.state;
  let ids = [...banner]
    ids[index].image = "";
    this.setState({
      banner: ids,
      toggleBannerModal:{status: false, index:null}
    })
}
handleDeleteBannerImage = (index:number) => {
  this.setState({
    toggleBannerModal: {
      status:true,
      index:index
    }
  })
}
   getPageData=()=> {
    this.setState({loading: true})
      let parameters = {
        db_name: window.location.pathname.split("/")[3],
      }
      let url = new URL(`${AppConstants.base_url_api}admin/banners`)
      for (const [key, value] of Object.entries(parameters)) {
        if (value) {
          url.searchParams.append(key, value)
        }
      }
      call_api({
        address_api: url.search ? `${AppConstants.base_url_api}admin/banners${url.search}` : `${AppConstants.base_url_api}admin/banners`,
        method_api: "GET",
      })
        .then(parseJSON)
        .then(([status, j]) => {
          
          if (status === 200) {
            const idsBanner = j?.data.filter((entry:any) => entry?.code.includes("banner"));
            const idsSlider = j?.data.filter((entry:any) => entry?.code.includes("slider"));
            const idsMlider = j?.data.filter((entry:any) => entry?.code.includes("mlider"));
            this.setState({slider: idsSlider, mlider: idsMlider, banner: idsBanner, loading: false})
            
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    handleSlider = (event:any, index:number, name:string) =>{
      
      const {slider} = this.state;
      let ids = [...slider]
        ids[index][name] = event.currentTarget.value

      this.setState({
        slider: ids,
      })
    }
    handleMlider = (event:any, index:number, name:string) =>{
      const {mlider} = this.state;
      let ids = [...mlider]
        ids[index][name] = event.currentTarget.value

      this.setState({
        mlider: ids,
      })
    }

    handleBanner = (event:any, index:number, name:string) =>{
      const {banner} = this.state;
      let ids = [...banner]
        ids[index][name] = event.currentTarget.value

      this.setState({
        banner: ids,
      })
    }

     handleImageUploadSlider = (event: any, index:number) => {
      let news_image_file = null;
      news_image_file = event.target.files[0];
      const {slider} = this.state;
      let ids = [...slider] 
      const reader = new FileReader();
      reader.readAsDataURL(news_image_file);

      reader.onloadend = () => {
        ids[index].image = reader.result;
        this.setState({ slider: ids});
      }
      if (news_image_file) {
        ids[index].imageUpload = news_image_file;
        this.setState({ slider: ids});
      }
    }
     handleImageUploadMlider = (event: any, index:number) => {
      let news_image_file = null;
      news_image_file = event.target.files[0];
      const {mlider} = this.state;
      let ids = [...mlider];
      const reader = new FileReader();
      reader.readAsDataURL(news_image_file);
      
      reader.onloadend = () => {
        ids[index].image = reader.result;
        this.setState({ mlider: ids});
      }
      if (news_image_file) {
        ids[index].imageUpload = news_image_file;
        this.setState({ mlider: ids});
      } 
    }
     handleImageUploadBanner = (event: any, index:number) => {
      let news_image_file = null;
      news_image_file = event.target.files[0];
      const {banner} = this.state;
      let ids = [...banner]
      const reader = new FileReader();
      reader.readAsDataURL(news_image_file);
      reader.onloadend = () => {
        ids[index].image = reader.result;
        this.setState({ banner: ids});
      }
      if (news_image_file) {
        ids[index].imageUpload = news_image_file;
        this.setState({ banner: ids});
      } 
  }
    async handleUpdate(item:any, type:string) {
      const {slider, mlider, banner} = this.state;
      let arr = [];
      if(type === "slider"){
        arr = slider
      }
      if(type === "mlider"){
        arr = mlider
      }
      if( type === "banner"){
        arr = banner
      }
      let filteredObj = arr.filter((i:any) => i?.id === item?.id)[0];
      const bannerSchema = yup.object().shape({
      filteredObj: yup.object().shape({
      link_alt: yup.string().required("Alt بنر الزامی است."),
            
  })
})
    try {
      await bannerSchema.validate({ filteredObj}, { abortEarly: false });


      const data = new FormData()
      data.append('ink_url', item?.link_url)
      data.append('link_alt', item?.link_alt)
      item?.imageUpload && data.append('image', item?.imageUpload)
      data.append('link_target', item?.link_target)
      data.append('description', item?.description)
      data.append('position', item?.position)
      data.append('bg_color', item?.bg_color)
      data.append('code', item?.code)
      data.append('icon', item?.icon)
      let url = new URL(`${AppConstants.base_url_api}admin/banners/update/${item?.id_select.toString()}`)
      call_api({
        address_api: url.search ? `${AppConstants.base_url_api}admin/banners/update/${item?.id_select.toString()}${url.search}` : `${AppConstants.base_url_api}admin/banners/update/${item?.id_select.toString()}`,
        method_api: "POST",
        body:data,
        file:true
      })
        .then(parseJSON)
        .then(async ([status, j]) => {
          if (status === 200) {
            
            toast.success("بنر با موفقیت به‌روزرسانی شد.");
            this.getPageData();
          } else if (status === 401) {
            localStorage.clear();
          } else {
            toast.error(j?.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      }
      catch (err: any) {

        arr.filter((i:any) => i?.id === item?.id)[0].errorsList = err.inner;
        if(type === "slider"){
          this.setState({ slider: arr })      
        }
        if(type === "mlider"){
          this.setState({ mlider: arr })      
        }
        if( type === "banner"){
          this.setState({ banner: arr })      

        }
      }
  }
}

export default BannerManagementController;