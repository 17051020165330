
import { LoadingSpinner, Header, LableIcon, DropDownIcon, CustomButton, CustomContainer } from "../../../components";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { ContenSettingsController } from "../../../controllers";
import { BsStars } from "react-icons/bs";

class contenSettings extends ContenSettingsController {
  componentDidMount() {
    this.getDate()
  }

  render() {
    const { loading, productsCount,
    bestSellingProductsCount ,
    categoryProductsCount,
    popularProductsCount,
    latestProductsCount,
    brandProductsCount, countList , errorsList } = this.state;

    return (
      <>
        <Helmet>
          <title> تنطیمات محتوا</title>
        </Helmet>
        <Header title=" تنطیمات محتوا" />
        {loading ? <LoadingSpinner /> : <>
          <LableIcon title=" نمایش محصولات" icon={<BsStars />} />
          <CustomContainer>
            <div className="content">
              <div className="col-md-4 col-sm-12 pe-3">
              

                <DropDownIcon
                  value={productsCount}
                  optionList={countList}
                  title="تعداد نمایش محصولات در سایت"
                  inBox="تعداد نمایش محصولات در سایت"
                  type={"text"}
                  name={"productsCount"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "productsCount")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      productsCount: event.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="col-md-4 col-sm-12 pe-3">
                <DropDownIcon
                  optionList={countList}
                  value={popularProductsCount}
                  title="تعداد نمایش محصولات محبوب"
                  inBox="تعداد نمایش محصولات محبوب"
                  type={"text"}
                  name={"popularProductsCount"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "popularProductsCount")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      popularProductsCount: event.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="col-md-4 col-sm-12 pe-3">
                <DropDownIcon
                  optionList={countList}
                  value={bestSellingProductsCount}
                  title="تعداد نمایش محصولات پرفروش"
                  inBox="تعداد نمایش محصولات پرفروش"
                  type={"text"}
                  name={"bestSellingProductsCount"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "bestSellingProductsCount")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      bestSellingProductsCount: event.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="col-md-4 col-sm-12 pe-3">
                <DropDownIcon
                  optionList={countList}
                  value={latestProductsCount}
                  title="تعداد نمایش آخرین محصولات"
                  inBox="تعداد نمایش آخرین محصولات"
                  type={"text"}
                  name={"latestProductsCount"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "latestProductsCount")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      latestProductsCount: event.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="col-md-4 col-sm-12 pe-3">
                <DropDownIcon
                  optionList={countList}
                  value={categoryProductsCount}
                  title="تعداد نمایش محصولات دسته"
                  inBox="تعداد نمایش محصولات دسته"
                  type={"text"}
                  name={"categoryProductsCount"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "categoryProductsCount")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      categoryProductsCount: event.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="col-md-4 col-sm-12 pe-3">
                <DropDownIcon
                  optionList={countList}
                  value={brandProductsCount}
                  title="تعداد نمایش محصولات برند"
                  inBox="تعداد نمایش محصولات برند"
                  type={"text"}
                  name={"brandProductsCount"}
                  errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "brandProductsCount")[0]?.message}
                  onChange={(event) =>
                    this.setState({
                      brandProductsCount: event.currentTarget.value,
                    })
                  }
                />
              </div>
             
              <CustomButton
                myStyle="btn-confirm"
                loading={false}
                onClick={() => this.update()}
                title="بروزرسانی "
              />
            </div>

          </CustomContainer>
        </>
        }
      </>
    );
  }
}
const mapStateToProps = (dispatch: any) => {
  return {
    dispatch,
    siteList: dispatch.general.siteList,
    loadingSiteList: dispatch.general.loadingSiteList,
    userData: dispatch.general.userData,
  }
};
export default connect(mapStateToProps)(contenSettings);
