import { CustomButton, InputIcon , DropDownIcon, InputTextLabel, Header, Option, OrderDetails} from "../../../components";
import {UsersInfoController} from "../../../controllers";
import { Box, createTheme, FormControlLabel, Switch, ThemeProvider } from '@mui/material';
import { HiPlus } from "react-icons/hi";
import Modal from '@mui/material/Modal';
import { Link } from "react-router-dom";
import { FiAlertTriangle } from "react-icons/fi";
import { SubmissionAlert} from '../../../components';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { Typography } from '@mui/material';
import { AiOutlineFileExcel, AiOutlineShopping, AiOutlineEdit } from "react-icons/ai";
import { MdBlockFlipped } from "react-icons/md";
import {BsCheckSquare, BsWhatsapp} from "react-icons/bs";
import Pagination from '@mui/material/Pagination';
import Select, { CSSObjectWithLabel } from "react-select";
import moment from 'moment-jalaali';
import Tooltip from '@mui/material/Tooltip';
import { CiCircleMore } from "react-icons/ci";
import { connect } from "react-redux";
import {TfiFilter} from "react-icons/tfi";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Grid from "@mui/material/Grid";
import { numberWithCommas, exportToExcel } from "../../../../core/utils";
import { usersInfoColumns } from "../../../../core/column";

class UsersInfo extends UsersInfoController {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getUsersList();

  }

  render() {

    type ModalData = {
      item: any;
      code: number;
      price: number;
      discount: number;
      status: string;
    }

    const { search, showFilters, page, openOrdersModal,allOption, loading, dreiverId, openDetailModal, userOrdersList, detailData,
      selectedRowIndex, orderLoading, rowCount, rowCountList, openNewUserModal, firstName, loadingUpdateProfile,
      lastName ,userDreiverId, bannedUser, openBanModal, usersDataList, whatsapp, phone, mobile, errorsList} = this.state;
      const { siteList , loadingSiteList } = this.props;

      const theme = createTheme({
      direction: "ltr",
      typography: {
        fontFamily:
          'Vazir',
      }
    });    

    const modalColumns = [
      {
        accessorKey: 'row',
        header: 'ردیف',
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign:"center"
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        size:7
      },
      {
        accessorKey: 'driver',
        header: 'سایت',
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign:"center"
            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        size:7
      },
      {
        accessorKey: 'code',
        header: 'شماره سفارش',
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign:"center"

            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        size:7
      },
      {
        accessorKey: 'date',
        header: 'تاریخ ایجاد',
        Cell: ({ cell }) => (
          <div
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign:"center",
            flexDirection:"column-reverse"
          }}
        >
          <span>
          {moment(cell.getValue<string>()).format("jYYYY/jM/jD")}
            
          </span>
          <span>
          {moment(cell.getValue<string>()).format("HH:mm:ss ")}
          </span>
        </div>
        ),
        size:10
      },
      {
        accessorKey: 'price',
        header: 'قیمت نهایی',
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign:"center"

            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        size:10
      },
      {
        accessorKey: 'status',
        header: 'وضعیت ',
        Cell: ({ cell }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign:"center"

            }}
          >
            {cell.getValue<string>()}
          </div>
        ),
        size:7
      }

    ] as MRT_ColumnDef<ModalData>[]
    return (
      <HelmetProvider>
      <ThemeProvider theme={theme}>
        <div className="users-info">
        <Helmet>
                <title>لیست کاربران</title>
            </Helmet>
          <Header title="لیست کاربران"/>
          <div className="custom-card mb-3">

              {openBanModal && <SubmissionAlert isOpen={openBanModal} title="اخطار!"
                setClose={(open: boolean) => this.setState({ openBanModal: false })}
                onClick={() => this.handleSubmitBanUser()} icon={<FiAlertTriangle color="orange" fontSize="50" />}
                details={`${!bannedUser ? "از مسدودکردن این کاربر اطمینان دارید؟" : "از فعال کردن این کاربر اطمینان دارید؟"}`} />}
              {showFilters && 
                  <>
              <div className="filter-select-container mb-2 pe-3">
                <div className="filter-options-container">
                <div className="column-30"> 
                    <Select
                      styles={{
                        control: (baseStyles: any, state: any):CSSObjectWithLabel => ({
                          ...baseStyles,
                          boxShadow:"rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",
                          borderWidth: "1px",
                          backgroundColor: "#fff",
                          display: "flex",
                          flexDirection: "row-reverse",
                          textAlign: "right",
                          borderRadius: "4px",
                          outline: "unset",
                        }) as CSSObjectWithLabel,
                      }}
                      onChange={this.driverSelected}
                      components={{ Option }}
                      value={dreiverId}
                      isMulti={true}
                      closeMenuOnSelect={true}
                      noOptionsMessage={() => 'این مورد وجود ندارد!'}
                      menuPortalTarget={document.body}
                      options={[ allOption, ...siteList]}
                      getOptionLabel={(option: any) => option?.value?.persian_name}
                      getOptionValue={(option: any) => option?.value?.name}
                      placeholder="سایت"
                      isClearable
                      loadingMessage={() => 'در حال بارگذاری  ...'}
                      isLoading={loadingSiteList}
                    />

                    </div> 
                  <div className="column-30">
                <FormControlLabel sx={{direction:"ltr"}} control={<Switch
                        checked={bannedUser}
                        onChange={(event:any)=>this.handleChangeStatus(event) }
                        inputProps={{ 'aria-label': 'controlled' }}
                     />} 
                     label="مسدودشده‌ها" />
                  </div>
                  <div className="column-30">
                    <CustomButton
                      myStyle='add-btn'
                      loading={false}
                      onClick={ ()=> this.setState({ openNewUserModal: true })}
                      title={"افزودن کاربر"}
                      icon={<HiPlus />}

                    />
                </div>
                </div>
                <Modal
                      open={openNewUserModal}
                      onClose={this.handleCloseNewUserModal}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                     <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        width: {
                          xxs: "90%",
                          xs: "90%",
                          sm: "90%",
                          md: "80%",
                          lg: "70%",
                          xl: "70%"
                        },
                        height: 'max-content',
                        display: 'block'
                      }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                       ایجاد کاربر جدید                   
                        </Typography>
                        <Grid container sx={{display:"flex", justifyContent:"center"}}>
                    </Grid>
                        <Grid container sx={{display:"flex", justifyContent:"space-between", padding:"10px"}} >
                        <Grid item={true} sm={12} lg={3} sx={{paddingBottom:"25px"}}>
                  <Select
                    styles={{
                      control: (baseStyles, state):CSSObjectWithLabel => ({
                        ...baseStyles,
                        boxShadow: "rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",
                        borderWidth: "1px",
                        backgroundColor: "#fff",
                        display: "flex",
                        flexDirection: "row-reverse",
                        textAlign: "right",
                        borderRadius: "4px",
                        height:"42px",
                        outline: "unset",
                      }) as CSSObjectWithLabel,
                    }}
                    onChange={(choice:any) => this.userDdriverSelected(choice)}
                    components={{Option}}
                    value={userDreiverId}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    noOptionsMessage={() => 'این مورد وجود ندارد!'}
                    options={siteList}
                    getOptionLabel={(option: any) => option?.value?.persian_name}
                    getOptionValue={(option: any) => option?.value?.name}
                    placeholder="سایت"
                    isClearable
                    name="userDreiverId"
                    loadingMessage={() => 'در حال بارگذاری  ...'}
                    isLoading={loadingSiteList}
                  />
                  <span className="input-icon-error">
                    {errorsList && errorsList?.filter((obj: any) => obj.path === "userDreiverId")[0]?.message}
                  </span>
                </Grid>
                      <Grid item={true} sm={12} lg={3}>
                      <InputIcon
                                value={mobile}
                                title={"موبایل "}
                                inBox={"موبایل"}
                                type={"text"}
                                name={"mobile"}
                                errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "mobile")[0]?.message}
                                onChange={(event) =>
                                  this.setState({
                                    mobile: event.currentTarget.value,
                                  })
                                }
                              />
                      </Grid>
                      <Grid item={true} sm={12} lg={3}>
                      <InputIcon
                              value={firstName}
                              title={"نام "}
                              inBox={"نام  "}
                              type={"text"}
                              name="firstName"
                              errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "firstName")[0]?.message}
                              onChange={(event) =>
                                this.setState({
                                  firstName: event.currentTarget.value,
                                })
                              }
                            />

                      </Grid>
                   
                        </Grid>
                        <Grid container sx={{display:"flex", justifyContent:"space-between", padding:"10px"}} >
                        <Grid item={true} sm={12} lg={3}>
                      <InputIcon
                              value={lastName}
                              title={"نام خانوادگی  "}
                              inBox={"نام خانوادگی  "}
                              errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "lastName")[0]?.message}
                              type={"text"}
                              name="lastName"
                              onChange={(event) =>
                                this.setState({
                                  lastName: event.currentTarget.value,
                                })
                              }
                            />
                      </Grid>
                        <Grid item={true} sm={12} lg={3}>
                        <InputIcon
                          value={phone}
                          title={"تلفن "}
                          inBox={"تلفن "}
                          type={"text"}
                          name="phone"
                          errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "phone")[0]?.message}
                          onChange={(event) =>
                            this.setState({ phone: event.currentTarget.value })
                          }
                        />

                      </Grid>
                      <Grid item={true} sm={12} lg={3}>
                        <InputIcon
                            value={whatsapp}
                            title={"واتس اپ "}
                            errortext={errorsList && errorsList?.filter((obj: any) => obj.path === "whatsapp")[0]?.message}
                            inBox={<BsWhatsapp />}
                            type={"text"}
                            name="whatsapp"
                            onChange={(event) =>
                              this.setState({
                                whatsapp: event.currentTarget.value,
                              })
                            }
                          />

                      </Grid>
                        </Grid>
                        <div className="btn-container">

                        <CustomButton
                          myStyle="submit-btn"
                          loading={loadingUpdateProfile}
                          onClick={()=>this.createNewUser()}
                          title="ایجاد کاربر جدید "
                    />
                        </div>
                      </Box>
                  </Modal>
              </div>
              </> }
              <Modal
                  open={openDetailModal}
                  onClose={this.handleCloseDetailModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    overflowY:"scroll",
                    fontSize:"13px",
                    width: {
                      xxs: "90%",
                      xs: "90%",
                      sm: "90%",
                      md: "80%",
                      lg: "70%",
                      xl: "70%"
                    },
                    height: '70%',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                  }}>
                    <Typography id="modal-modal-title" variant="subtitle1">
                       جزییات سفارشات
                    </Typography>
                    
                    {detailData && detailData?.order_products ? detailData?.order_products?.length === 0 ?
                           <div className="error-text">داده ای برای نمایش وجود ندارد!</div>
                            :
                              <OrderDetails selectedOrder={detailData} />
                            : <div className="error-text">داده ای برای نمایش وجود ندارد!</div>
                    }
                  </Box>
                </Modal>
              <Modal
                open={openOrdersModal}
                onClose={this.handleCloseOrdersModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 4,
                  width: {
                    xxs: "90%",
                    xs: "80%",
                    sm: "70%",
                    md: "60%",
                    lg: "60%",
                    xl: "60%"
                  },
                  height: 'max-content',
                }}>
                  <Typography id="modal-modal-title" variant="subtitle1">
                    سفارشات
                  </Typography>
                  <MaterialReactTable
                    muiTablePaperProps={{
                      sx: {
                        mt:"1.5rem",
                        border: "1px solid #e5e5e5",
                      },
                    }}
                    muiTableContainerProps={{
                      sx:{
                        height:"200px"
                      }
                    }}
                    muiTableProps={{
                      sx: {
                        border: "1px solid #e5e5e5",
                      },
                    }}
                    muiTableHeadCellProps={{
                      sx: {
                        border: "1px solid #e5e5e5",
                        height: "fit-content",
                        textAlign:"center",
                        justifyContent:"center",
                      },
                    }}
                    muiTopToolbarProps={{
                      sx:{
                        display:"none"
                      }
                    }}
                    muiBottomToolbarProps={{
                      sx:{
                        display:"none"
                      }
                    }}
                    muiTableBodyRowProps={( {row,staticRowIndex} ) => ({
                      sx:{
                        backgroundColor: (staticRowIndex % 2 === 0 ) ? '#f3f3f3'  : "",
                    }})}
                    muiTableBodyCellProps={{
                      sx: {
                        border: "1px solid #e5e5e5",
                        justifyContent:"center",
                      },
                    }}
                    columns={modalColumns}
                    data={userOrdersList && userOrdersList?.length > 0 &&
                      userOrdersList.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null).map((item: any, index: number) => {
                        return ({
                          item: item,
                          row: index + 1,
                          driver:<img className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo} alt=""/> ,
                          code: item.id,
                          date: item.created_at && item.created_at,
                          price: numberWithCommas(item.finalPrice),
                          status: item.order_status?.value,
                        });
                      })}
                    enableRowVirtualization
                    rowVirtualizerInstanceRef={this.rowVirtualizerInstanceRef}
                    rowVirtualizerProps={{ overscan: 8 }} 
                    enablePagination={false}
                    enableGlobalFilter={false}
                    enableColumnFilters={false}
                    positionActionsColumn="last"
                    enableRowActions
                    localization={{
                      actions: 'عملیات',
                        noRecordsToDisplay:"داده‌ای برای نمایش وجود ندارد!",
                    }}
                    enableDensityToggle={false}
                    enableFullScreenToggle={false}
                    enableHiding={false}
                    enableColumnActions={false}
                    renderRowActions={({ row, table }) => [
                      <div className="actions">
                        <Tooltip title="مشاهده جزییات">
                        <span> <CiCircleMore className='icon-gray' onClick={() => this.showMoreDetails(row.original)} />
                        </span>
                      </Tooltip>
                      </div>
                      
                    ]}
                    state={{ isLoading: orderLoading, showProgressBars: orderLoading }}
                  />
                </Box>
              </Modal>
              <div className="table-container">
              {showFilters && 
                  
                <div className="users-info-search-input">
                  <InputTextLabel
                    type={"text"}
                    value={search}
                    onChange={(event) => this.handleSearch(event)}
                    placeholder="جستجو..."
                  />
                </div>
  }
                <MaterialReactTable
                  muiTablePaperProps={{
                    sx: { m: '1.3rem', border:"1px solid #e5e5e5" }}}
                  columns={usersInfoColumns}
                  muiTableProps={{
                    sx: {
                      border: "1px solid #e5e5e5",
                      "& .Mui-TableBodyCell-DetailPanel":{
                        width:"100%",
                      
                      },
                      
                    },
                  }}
                  muiExpandButtonProps={( cell:any ) => ({
                  
                    onClick: (event:any) => {
                        this.handleDetailPannel(cell?.row?.original?.item,cell?.row?.index)
                       
                    
                    }
                  })}
                  state={{ isLoading: loading, showProgressBars: loading, expanded:{[selectedRowIndex]:true} }}
                  muiTableHeadCellProps={{
                    sx: {
                      height:"40px",
                      border: "1px solid #e5e5e5",
                      textAlign:"center",
                      justifyContent:"center",
                    },
                  }}
                  muiTableHeadProps={{
                    sx:{
                      zIndex:1
                    }
                  }}
                  muiTableBodyRowProps={( {row,staticRowIndex} ) => ({
                    sx:{
                      height: "fit-content",
                      backgroundColor: (staticRowIndex % 2 === 0 ) ? '#f3f3f3'  : "",
                  }})}
                  muiTableBodyCellProps={{
                    sx: {
                      border: "1px solid #e5e5e5",
                      textAlign:"center",
                      height: "50px",
                      justifyContent:"center",
                      
                    },
                  }}
                  displayColumnDefOptions={{
                    'mrt-row-expand': {
                      muiTableHeadCellProps: {
                        align: 'center',
                      },

                    },
                  }}
                  data={usersDataList && usersDataList?.data?.length > 0 &&
                    usersDataList?.data?.filter((v: string | null | undefined) => v !== "" && v !== undefined && v !== null).map((item: any, index: number) =>
                     {
                      return ({
                        item: item,
                        id:item?.id,
                        driver: <img className="logo" src={siteList && siteList?.filter((obj: any) => obj?.value?.name === item?.db_name)[0]?.value?.logo} alt="..." />,
                        name: item?.profile?.full_name,
                        mobile: item.mobile,
                        role: item.roles?.persian_name
                      }
                      );
                    }
                    )}
                    enablePagination={false}
                renderBottomToolbarCustomActions={({table})=>{
                  return (
                    <div className="custom-pagination w-100">
                     <Pagination  page={page} count={usersDataList?.total ? Math.ceil(usersDataList?.total / rowCount) : 1} onChange={this.handleChangePage} />
                      <DropDownIcon
                      value={rowCount}
                      optionList={rowCountList}
                      title={""}
                      onChange={(event) =>
                        {
                          this.handlePaginationSelect(event)
                      }
                    }
                    />
                    </div>
                  );
                }}
                  positionActionsColumn={'last'}
                  positionExpandColumn={'first'}
                
                  enableGlobalFilter={false}
                  enableColumnFilters={false}
                  enableDensityToggle={false}
                  enableFullScreenToggle={false}
                  enableHiding={false}
                  renderTopToolbarCustomActions={({ table }) => (
                    <Box
                      sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap', justifyContent:"space-between", width:"100%" }}
                    >
                      <CustomButton
                        loading={false}
                        onClick={() => exportToExcel(usersDataList?.data, "Excel Export")}
                        title="اکسل"
                        myStyle="excel-btn"
                        icon={<AiOutlineFileExcel />}
                      />
                      <Tooltip title="نمایش فیلترها"><span> <TfiFilter className='filter-icon' onClick={() => this.setState(prevState => ({
                                  showFilters: !prevState.showFilters
                                }))
                              }/></span></Tooltip>
                      <div className='custom-pagination'>
                      <Pagination  page={page} count={usersDataList?.total ? Math.ceil(usersDataList?.total / rowCount) : 1} onChange={this.handleChangePage} />
                  <DropDownIcon
                  value={rowCount}
                  optionList={rowCountList}
                  title={""}
                  onChange={(event) =>
                    {
                      this.handlePaginationSelect(event)
                  }
                }
                />
                    </div>
                    </Box>
                  )}
                  enableColumnActions={false}
                  muiTableDetailPanelProps={{
                    sx:{
                      display:"inline-block"
                    }
                  }}
                  renderDetailPanel={({ row }) => (
                    <div key={row.original?.item?.id} className="detail-container">
                      {loading === false &&
                      <>
                      <div className="detail-col" >
                        <p className="detail-row">
                        ایمیل: {row.original?.item?.email ? row.original?.item?.email : "ثبت‌نشده"} 
                       </p>
                       {/* <p className="detail-row">
                          آخرین ویرایش: {moment(row.original?.item?.updated_at).format("HH:mm:ss jYYYY/jM/jD")}
                        </p> */}
                        <p className="detail-row">
                          عضویت: {moment(row.original?.item?.created_at).format("HH:mm:ss jYYYY/jM/jD")}
                        </p>
                      </div>
                      <div className="detail-col" >
                        
                        <p className="detail-row">
                          وضعیت: {row.original?.item?.status?.value}</p>
                        <p className="detail-row">
                          نمایه: {row.original?.item?.profile?.avatar ? <img alt="..." className="users-img" src={row.original?.item?.profile?.avatar} /> : "ثبت‌نشده"}
                        </p>
                        
                      </div>
                      </>
                      }
                    </div>
                  )}
                  enableRowActions
                  localization={{
                    actions: 'عملیات',
                    noRecordsToDisplay:"داده‌ای برای نمایش وجود ندارد!"
                  }}
                  enableRowNumbers
                  tableInstanceRef={this.rowVirtualizerInstanceRef}
                  enableRowVirtualization
                  renderRowActions={(row: any) => [
                    <div key={row.row.original?.item?.id} className='detail-container'>
                      {!bannedUser?
                        <Tooltip title="مسدودکردن"><span><MdBlockFlipped className='icon-red' onClick={() => this.handleBanUser(row.row.original)} /></span></Tooltip>
                        :
                        <Tooltip title="رفع مسدودیت"><span><BsCheckSquare className='icon-green' onClick={() => this.handleBanUser(row.row.original)} />
                        </span></Tooltip>

                      }
                      <Tooltip title="سفارشات کاربر"><span> <AiOutlineShopping className='icon-yellow' onClick={() => this.handleOrdersListByEachUser(row.row.original)} /></span></Tooltip>
                      <Link to={`/update-user-info/${row.row.original?.item?.id_select}`}>
                      <Tooltip title="ویرایش"><span><AiOutlineEdit className='icon-gray' /></span></Tooltip>
                        
                      </Link>
                    </div>
                  ]}
                  initialState={{
                    pagination : {pageSize:100, pageIndex:0}, sorting: [
                      {
                        id: "id",
                        desc: true
                      },
                    ],
                  }}
                />
              </div>
          </div>
        </div>
      </ThemeProvider>
      </HelmetProvider>
    );
  }
}
const mapStateToProps = (dispatch:any) => {
  return {
    dispatch,
    siteList:dispatch.general.siteList,
    loadingSiteList:dispatch.general.loadingSiteList,
  }
};
export default connect(mapStateToProps)(UsersInfo);