import { Component } from "react";
import { Outlet } from "react-router-dom"
import { LayoutController } from "../../../controllers";

class Layout extends LayoutController {
  componentDidMount() {
    window.scrollTo(0, 0);
    
  }
  render() {
    return (
      <main className="App">
        <Outlet />
      </main>
            )
  }
}
export default Layout;
