import { connect } from "react-redux";
import { CustomContainer, Header, LandingCard, Option } from "../../../components";
import { WebManagementController } from "../../../controllers";
import Select, { CSSObjectWithLabel, components } from "react-select";
import { Helmet } from "react-helmet";

class WebManagement extends WebManagementController {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {

    const {dreiverId } = this.state;
    const { siteList, loadingSiteList } = this.props;

    return (
      <>
        <Helmet>
          <title> مدیریت وب‌سایت </title>
        </Helmet>
        <Header isActive = "isWebsiteManagement" title=" مدیریت وب سایت " />
        <CustomContainer>
          <div className="w-50">
            <Select
              styles={{
                control: (baseStyles: any, state: any): CSSObjectWithLabel => ({
                  ...baseStyles,
                  boxShadow: "rgba(60, 64, 67, 0.5) 0px 2px 6px 2px",

                  borderWidth: "1px",
                  backgroundColor: "#fff",
                  display: "flex",
                  flexDirection: "row-reverse",
                  textAlign: "right",
                  borderRadius: "4px",
                  minHeight: "42px",
                  outline: "unset",
                }) as CSSObjectWithLabel,
              }}
              onChange={this.driverSelected}
              components={{ Option }}
              value={dreiverId}
              isMulti={false}
              closeMenuOnSelect={true}
              noOptionsMessage={() => 'این مورد وجود ندارد!'}
              menuPortalTarget={document.body}
              options={siteList}
              getOptionLabel={(option: any) => option?.value?.persian_name}
              getOptionValue={(option: any) => option?.value?.name}
              placeholder="سایت"
              isClearable
              loadingMessage={() => 'در حال بارگذاری  ...'}
              isLoading={loadingSiteList}
            />


          </div>
          <div className="landing">

            <div className="landing-container">

              <LandingCard
                myStyle={dreiverId ? "bg-light-green" : "bg-gray"}
                title="تنظیمات عمومی"
                route={dreiverId ? `/admin/general-settings/${dreiverId?.value?.name}` : ""} />
              <LandingCard
                myStyle={dreiverId ? "bg-hot-pink" : "bg-gray"}
                title="تنظیمات ظاهری "
                route={dreiverId ? `/admin/appearance-settings/${dreiverId?.value?.name}` : ""} />
              <LandingCard
                myStyle={dreiverId ? "bg-purple" : "bg-gray"}
                title="اطلاعات وب سایت"
                route={dreiverId ? `/admin/website_information/${dreiverId?.value?.name}` : ""} />
              <LandingCard
                myStyle={dreiverId ? "bg-yellow" : "bg-gray"}
                title="تنظیمات محتوا "
                route={dreiverId ? `/admin/content-settings/${dreiverId?.value?.name}` : ""} />
              <LandingCard
                myStyle={dreiverId ? "bg-orange" : "bg-gray"}
                title=" شعار های سایت "
                route={dreiverId ? `/admin/site-slogan/${dreiverId?.value?.name}` : ""} />
              <LandingCard
                myStyle={dreiverId ? "bg-hot-pink" : "bg-gray"}
                title=" مدیریت بنرها "
                route={dreiverId ? `/admin/banner-management/${dreiverId?.value?.name}` : ""} />
              <LandingCard
                myStyle={dreiverId ? "bg-red" : "bg-gray"}
                title="پرسش های متداول "
                route={dreiverId ? `/admin/commen-questions-list/${dreiverId?.value?.name}` : ""} />
                <LandingCard
                myStyle={dreiverId ? "bg-orange" : "bg-gray"}
                title="مدیریت صفحات"
                route={dreiverId ? `/admin/page/${dreiverId?.value?.name}` : ""} />
            </div>
          </div>
        </CustomContainer>
      </>

    );
  }
}
const mapStateToProps = (dispatch: any) => {
  return {
    dispatch,
    siteList: dispatch.general.siteList,
    loadingSiteList: dispatch.general.loadingSiteList,
  }
};
export default connect(mapStateToProps)(WebManagement);