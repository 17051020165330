
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import InputIcon from "./input_icon";
import Select, { CSSObjectWithLabel } from "react-select";
import { CustomButton } from ".";
import { call_api, parseJSON } from "../../core/service";
import { AppConstants } from "../../core/constants";
import { toast } from "react-toastify";

interface Props {
    addressDetail?:any;
    addressInputsOnChange?:any;
    addAddress:any;
}
const AddressInputs = ({ addressDetail, addressInputsOnChange, addAddress }: Props) => {
const [addressObj, setAddressObj] = useState(addressDetail !== undefined ? addressDetail : {title:"", province:{id: 8, name:"تهران"}, city:{id:117, name:"تهران"}, region:{id:101, name:"", }, postal_code:"", address_description:"", full_address:""});
const [neighbourhoodList, setNeighbourhoodList] = useState([]);
const [visited, setVisited] = useState(false);
const [citiesList, setCitiesList] = useState({city:[]});
const [provincesList, setProvincesList] = useState([]);
const [loadingCities, setLoadinCities] = useState(false);
const [loadingNeighborhood, setLoadingNeighbourhood] = useState(false);
const [loadingProvinces, setLoadinProvinces] = useState(false);
const neighborhoodSelect = (choice: any) =>{      
    setAddressObj((prevState: any) => ({
        ...prevState,
        region: choice,
      }))  
  }
  let fetchTimeout : string | number | NodeJS.Timeout | undefined;
  clearTimeout(fetchTimeout);
    fetchTimeout = setTimeout(() => {
      if(!visited){
        getProvincesAddress();
        setAddressObj(addressDetail)
        setVisited(true)
      }
    }, 500);
  const getProvincesAddress = (search?: number) => {
    setLoadinProvinces(true)
    call_api({
      address_api: `${AppConstants.base_url_api}seller/provinces`,
      method_api: "GET",
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
            setLoadinProvinces(false);
            setProvincesList(j.data);
            getCitiesAddress(addressDetail?.province !== undefined ? addressDetail?.province :  { id: 8, name: "تهران" });
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  let provinceTimeout: string | number | NodeJS.Timeout | undefined;
  const provinceSelected = (choice: any) => {
    setAddressObj((prevState: any) => ({
        ...prevState,
        province:choice,
      }))
   
    clearTimeout(provinceTimeout);
    provinceTimeout = setTimeout(() => {
      getCitiesAddress(choice);
      setLoadinCities(true);
    }, 1000);
  };
  let cityTimeout: string | number | NodeJS.Timeout | undefined;
  const citySelected = (choice: any) => {
    setAddressObj((prevState:any) => ({
        ...prevState,
        city:choice,
      }))

    clearTimeout(cityTimeout);
    cityTimeout = setTimeout(() => {
      getNeigborhoodLists(choice)
    }, 1000);
  };
  const getCitiesAddress = (choice?: any) => {
    call_api({
      address_api: `${AppConstants.base_url_api}seller/provinces/${choice?.id}`,
      method_api: "GET",
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
          setAddressObj((prevState: any) => ({
            ...prevState,
            city:  j.data.city[0],
          }))
          setLoadinCities(false);
          setCitiesList(j.data)
          getNeigborhoodLists( j.data.city[0])

        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  const getNeigborhoodLists = (choice:any) => { 
    setLoadingNeighbourhood(true)  
    call_api({
    address_api: `${AppConstants.base_url_api}seller/district/${choice?.id}`,
      method_api: "GET"
    })
      .then(parseJSON)
      .then(([status, j]) => {
        if (status === 200) {
           setNeighbourhoodList(j?.data);
           if(j?.data.length > 0 && addressDetail?.region?.name === '')
           {
            setAddressObj((prevState:any) => ({
              ...prevState,
              region: j?.data[0]
            }))
           }
           else if( addressDetail.region?.name !== ''){
            setAddressObj((prevState:any) => ({
              ...prevState,
              region: addressDetail.region
            }))
           }
           else{
            setAddressObj((prevState:any) => ({
              ...prevState,
              region: {id:101, name:""}
            }))
           }
           
          setLoadingNeighbourhood(false)
        } else if (status === 401) {
          localStorage.clear();
        } else {
          toast.error(j?.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
return(
    <Grid item sm={12} md={12} >
                              <InputIcon
                                  value={addressObj?.title}
                                  inBox={"عنوان آدرس"}
                                  type={"text"}
                                  placeholder={"عنوان آدرس"}
                                  onChange={(event) =>
                                    setAddressObj((prevState: any) => ({
                                        ...prevState,
                                        title:  event.target.value,
                                      }))
                                  }
                                  name={"addressTitle"}
                              />     
                      <Grid container sx={{marginTop:"5px", marginBottom:"10px"}}>
                        <Grid xs={12} sm={12} md={4} lg={4} xl={4} sx={{paddingBottom:"10px", paddingLeft:{md: "10px"}}} >      
                          <Select
                          styles={{
                            menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                          control: (baseStyles, state):CSSObjectWithLabel => ({
                            ...baseStyles,
                            boxShadow: state.isFocused
                              ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                              : "",
                            borderWidth: "1px",
                            backgroundColor: "#F3F3F3",
                            display: "flex",
                            flexDirection: "row-reverse",
                            textAlign: "right",
                            borderRadius: "4px",
                            height:"35px",
                            minHeight:"20px",
                            outline: "unset",
                          }) as CSSObjectWithLabel,
                        }}
                        onChange={(choice) => provinceSelected(choice)}
                        value={addressObj?.province}           
                        closeMenuOnSelect={true}
                        options={provincesList}
                        noOptionsMessage={() => 'این مورد وجود ندارد!'}
                        getOptionLabel={(option: any) => option.name}
                        getOptionValue={(option: any) => option.id}
                        placeholder="استان"
                        menuPortalTarget={document.body}
                        isClearable={false}
                        isLoading={loadingProvinces}
                        loadingMessage={() => 'در حال بارگذاری  ...'}
                      />
                        </Grid>
                        <Grid xs={12} sm={12} md={4} lg={4} xl={4} sx={{paddingBottom:"10px", paddingLeft:{md: "10px"}}}>
                          <Select
                          styles={{
                            menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                            control: (baseStyles, state):CSSObjectWithLabel => ({
                              ...baseStyles,
                              boxShadow: state.isFocused
                                ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                : "",
                              borderWidth: "1px",
                              backgroundColor: "#F3F3F3",
                              display: "flex",
                              flexDirection: "row-reverse",
                              textAlign: "right",
                              borderRadius: "4px",
                              height:"35px",
                              minHeight:"20px",
                              outline: "unset",
                            }) as CSSObjectWithLabel,
                          }}
                          menuPortalTarget={document.body}
                          onChange={(choice) => citySelected(choice)}
                          value={addressObj?.city}                   
                          closeMenuOnSelect={true}
                          options={citiesList?.city}
                          noOptionsMessage={() => 'این مورد وجود ندارد!'}
                          getOptionLabel={(option: any) => option.name}
                          getOptionValue={(option: any) => option.id}
                          placeholder="شهر"
                          isClearable={false}
                          isLoading={loadingCities}
                          loadingMessage={() => 'در حال بارگذاری  ...'}/>
                        </Grid>
                        <Grid xs={12} sm={12} md={4} lg={4} xl={4} sx={{paddingBottom:"10px"}}>
                        {neighbourhoodList.length > 0 ?
                          <Select
                          styles={{
                          control: (baseStyles, state):CSSObjectWithLabel => ({
                              ...baseStyles,
                              boxShadow: state.isFocused
                                ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                                : "",
                              borderWidth: "1px",
                              backgroundColor: "#F3F3F3",
                              display: "flex",
                              flexDirection: "row-reverse",
                              textAlign: "right",
                              borderRadius: "4px",
                              height:"35px",
                              minHeight:"20px",
                              outline: "unset",
                            }) as CSSObjectWithLabel,
                            menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                          }} 
                          onChange={(choice) => neighborhoodSelect(choice)}
                        value={addressObj?.region}
                        menuPortalTarget={document.body}
                        closeMenuOnSelect={true}
                        options={neighbourhoodList}
                        noOptionsMessage={() => 'این مورد وجود ندارد!'}
                        getOptionLabel={(option: any) => option.name}
                        getOptionValue={(option: any) => option.id}
                        placeholder="محله"
                        isClearable={false}
                        isLoading={loadingNeighborhood}
                        loadingMessage={() => 'در حال بارگذاری  ...'}
                      />
                      :
                      <InputIcon
                      value={addressObj?.region?.name}
                      inBox={"محله"}
                      type={"text"}
                      placeholder={"محله"}
                      onChange={(event) => setAddressObj((prevState:any) => ({
                        ...prevState,
                        region:  {name:event.target.value, id:101},
                      }))}
                      name={"neighborhood"}/>  
                    }
                        </Grid>
                      </Grid>   
                        <Grid container sx={{marginTop:"5px"}}>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{paddingLeft:{md: "10px"}}}>
                          <InputIcon
                            value={addressObj?.postal_code}
                            inBox={"کدپستی"}
                            type={"number"}
                            placeholder={"کدپستی"}
                            onChange={(event) => setAddressObj((prevState: any) => ({
                                ...prevState,
                                postal_code:  event.target.value,
                              }))}
                            name={"postalCode"}
                      />  
                      </Grid>  
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                          <InputIcon
                              value={addressObj?.address_description}
                              inBox={"توضیحات آدرس"}
                              type={"text"}
                              placeholder={"مثلاً از درب پارکینگ مراجعه نمایید..."}
                              onChange={(event) =>
                                setAddressObj((prevState:any) => ({
                                    ...prevState,
                                    address_description:  event.target.value,
                                  }))}
                          />     
                        </Grid>
                        </Grid>
                        <Grid>
                        <InputIcon
                            value={addressObj?.full_address}
                            inBox={"آدرس کامل"}
                            type={"text"}
                            placeholder={"آدرس دقیق به‌همراه کدپستی، پلاک، واحد و..."}
                            onChange={(event) => setAddressObj((prevState:any) => ({
                                ...prevState,
                                full_address:  event.target.value,
                              }))}
                            name={"fullAddress"}
                      /> 
                        </Grid>
                      <CustomButton
                            title={addressDetail ? "ویرایش آدرس" : "ثبت آدرس"}
                            myStyle="submit-address-btn"
                            loading={false}
                            onClick={()=>addAddress(addressObj)}
                          /> 
                    </Grid>
)
}
export default AddressInputs;