import { HTMLAttributes, ReactElement} from "react";
import { CustomButton } from ".";
interface Props extends HTMLAttributes<HTMLElement> {
    title?:string;
    link?:string;
    icon?:ReactElement;
    onClick?:any;
    children?:any;
    isVertical?:any
    hideBtn?:boolean
}
const notificationsList = ({title,link,icon,onClick,children,isVertical,hideBtn}: Props): ReactElement  => {

    return (
        <div className="notifications-list" >
        <div className="notifications-list-header">
          <span className="notifications-list-header-title">
              {title}
          </span>
          {!hideBtn && 
          <CustomButton myStyle="btn" textStyle="text" title={"مشاهده همه"} route={link} />
          }
        </div>
        <div className={`${isVertical ? "d-flex" : "" } notifications-list-children`}>
        {children}
        </div>
      </div>
    )
}
export default notificationsList;