import React, { useState } from 'react';
import { HTMLAttributes, ReactElement} from "react";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
interface Props extends HTMLAttributes<HTMLElement> {
    title:string;
    content:any;
}

const Accordion = ({ title, content }:  Props):ReactElement => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div className='title'>{title}</div>
        <div>{isActive ?  <IoIosArrowUp /> : <IoIosArrowDown />}</div>
      </div>
      {isActive && <div className="accordion-content">{content}</div>}
    </div>
  );
};

export default Accordion;